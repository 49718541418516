export default {
  relations: 'Анализ взаимодействий',
  search: 'Поиск',
  bi_app: 'Аналитика BI',
  bi_app_desc: 'Инструмент для визуализации и исследования данных',
  alarm_app: 'Тревожный монитор',
  alarm_app_desc: 'Уведомления о тревогах при их возникновении',
  search_desc: 'Поиск людей в картотеке или среди распознанных объектов по фотографии',
  search_with_cars_desc: 'Поиск людей или транспортных средств в картотеке или среди распознанных объектов по фотографии',
  relations_desc: 'Поиск взаимосвязей объекта наблюдения на основании автоматического анализа распознанных лиц',
  video_wall: 'Видеостена',
  video_wall_desc: 'Просмотр нескольких видеопотоков одновременно на одном экране',
  cibr: 'Происшествия',
  cibr_desc: 'Автоматизированная обработка видеоданных с мест происшествий',
  counters: 'Счетчики',
  counters_desc: 'Подсчёт посещаемости зоны наблюдения на основании автоматического анализа распознанных объектов',
  clusters: 'Кластеры',
  clusters_desc: 'Анализ уникальных посетителей на основании автоматической кластеризации схожих распознанных лиц',
  data_sources: 'Источники видео',
  data_sources_desc: 'Камеры, Видеофайлы, Внешние детекторы',
  episodes_and_events: 'Эпизоды и события',
  episodes_and_events_desc: 'Просмотр, анализ и мониторинг распознанных объектов в реальном времени',
  analytics: 'Анализ аудитории',
  analytics_desc: 'Автоматический анализ аудитории в зонах наблюдения',
  settings: 'Настройки',
  settings_desc: 'Настройки, роли, пользователи, справочники, лицензии, документация',
  cards: 'Картотека',
  cards_desc: 'В картотеке хранятся данные об объектах наблюдения — людях и/или транспортных средствах',
  cards_with_cars_desc: 'В картотеке хранятся данные об объектах наблюдения — людях и транспортных средствах',
  areas: 'Области',
  areas_desc: 'Мониторинг присутствия людей в определенных областях по заданным правилам и расписаниям',
  player: 'Видеоплеер',
  player_desc: 'Мощный видеоплеер с расширенным аппаратным ускорением и поддержкой субтитров',
  verify: 'Сравнение',
  verify_desc: 'Сравнение объектов с исчислением степени схожести между ними',
  audit_logs: 'Журнал действий',
  reports: 'Отчеты',
  reports_desc: 'Список отчётов, созданных по запросу пользователя',
  remote_monitoring_events: 'Удалённый мониторинг',
  remote_monitoring_events_desc: 'Уведомления об обнаружении объектов интереса удалёнными системами FindFace',
  external_search: 'Удалённый поиск',
  external_search_desc: 'Поиск объекта интереса среди объектов, распознанных удалёнными системами FindFace',
  lines: 'Пересечение линий',
  lines_desc: 'Здесь можно создать виртуальную линию, чтобы контролировать ее пересечение объектами'
};
