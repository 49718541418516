
import { Report } from '@/api';
import { ReportsFilter } from '@/api/models/ReportsFilter';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { defineComponent, ref } from 'vue';
import { ReportsPageDownloader, ReportsPageActions, ReportsPageTable } from './components';
import { ReportFormat } from './components/downloader/services';
import { useReportsPageViewModel } from './setup';
import { isEmpty } from './utils';

export default defineComponent({
  name: 'ReportsPage',
  components: {
    PageContentLayout,
    ReportsPageDownloader,
    ReportsPageActions,
    ReportsPageTable
  },
  setup() {
    const downloader = ref<InstanceType<typeof ReportsPageDownloader> | null>(null);
    const service = dataServiceFactory.getService<Report, ReportsFilter>('/reports/');
    return { downloader, ...useReportsPageViewModel(service) };
  },
  methods: {
    handleDownload(report: Report, format: ReportFormat): void {
      this.downloader?.download(report, format);
    },
    handleDownloadSelected(): void {
      const name = `reports_${Date.now()}`;
      const reports = this.getSelectedReadyReports();
      !isEmpty(reports) && this.downloader?.downloadAllInArchive(reports, name).then(this.clearSelections);
    }
  }
});
