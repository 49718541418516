import { ISelectItem } from './select/types';

export interface DocumentWithFullscreen extends HTMLDocument {
  exitFullscreen: any;
  fullscreenElement: any;
  msExitFullscreen: any;
  webkitExitFullscreen: any;
  webkitFullscreenElement: any;
}

export function asDefined<T>(value: T | null | undefined, message = 'The provided value is null or undefined'): T {
  return value ?? exception(message);
}

export function exception(message: string): never {
  throw new Error(message);
}

export function firstOf<T>(items: T[], message = 'The target array does not contain items.'): T {
  return items[0] ?? exception(message);
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value != undefined;
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isStringWithValue(value: unknown): value is string {
  return isString(value) && value !== '';
}

export function hasParentElementDataName(target: HTMLElement | null, name: string, depth: number = 5): boolean {
  let parent = (target as HTMLElement)?.parentElement;
  let result = false;
  for (let i = 0; i < depth; i++) {
    const parentName = parent?.dataset?.name;
    result = parentName === name;
    if (result) break;
    else parent = parent?.parentElement || null;
  }
  return result;
}

export function openFullscreen(element: any /* HTMLElement in different browsers */): Promise<void> {
  let result;
  if (element.requestFullscreen) {
    result = element.requestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    /* Safari */
    result = element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    /* IE11 */
    result = element.msRequestFullscreen();
  }
  return result || Promise.resolve();
}

export function closeFullscreen() {
  const typedDocument = document as DocumentWithFullscreen;
  if (typedDocument.exitFullscreen) {
    typedDocument.exitFullscreen();
  } else if (typedDocument.webkitExitFullscreen) {
    /* Safari */
    typedDocument.webkitExitFullscreen();
  } else if (typedDocument.msExitFullscreen) {
    /* IE11 */
    typedDocument.msExitFullscreen();
  }
}

function translateItems(items: ISelectItem[], $t: any, tOptions = 'f') {
  return items.map((item) => {
    if (item.i18n_label) {
      item.label = $t(item.i18n_label, tOptions);
    }
    return item;
  });
}

export function translateProps(props: Record<string, any> | undefined, $t: any, tOptions = 'f') {
  if (!props) {
    return props;
  }
  let translatedProps: Record<string, any> = {};
  Object.keys(props)
    .filter((key) => props[key] !== undefined)
    .forEach((key) => {
      if (key.startsWith('i18n_')) {
        translatedProps[key.substr(5)] = $t(props[key], tOptions);
      } else if (key === 'items') {
        translatedProps[key] = translateItems(props[key], $t, tOptions);
      } else {
        translatedProps[key] = props[key];
      }
    });
  return translatedProps;
}
