/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ExternalVmsCamerasFilter = {
  /**
   * If `true` - the camera is added to the FindFace Multi
   */
  added?: boolean;
  /**
   * Select objects with `created_date` **greater** than this value
   */
  created_date_gt?: string;
  /**
   * Select objects with `created_date` **greater than or equal** to this value
   */
  created_date_gte?: string;
  /**
   * Select objects with `created_date` in last N days
   */
  created_date_last_n_days?: number;
  /**
   * Select objects with `created_date` **less** than this value
   */
  created_date_lt?: string;
  /**
   * Select objects with `created_date` **less than or equal** to this value
   */
  created_date_lte?: string;
  /**
   * Select objects with `created_date` in last Nth week (including Sunday and Saturday)
   */
  created_date_nth_full_week?: number;
  /**
   * Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
   */
  created_date_nth_work_week?: number;
  /**
   * VMS camera id
   */
  id_in?: Array<string>;
  /**
   * Number of results to return per page.
   */
  limit?: string;
  /**
   * VMS camera name
   */
  name_contains?: string;
  /**
   * Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
   */
  ordering?: string;
  /**
   * Pagination cursor value.
   */
  page?: string;
  /**
   * Select external-vms entries that matched this `plugin_vms_id`
   */
  plugin_vms_id?: number;
  /**
   * Select external-vms entries with this `verbose_name`
   */
  verbose_name?: string;
  /**
   * Select external-vms entries with this `version`
   */
  version?: string;
  /**
   * Select external-vms entries with this `vms`
   */
  vms?: string;
}
export const EmptyExternalVmsCamerasFilter: ExternalVmsCamerasFilter = {
  added: undefined,
  created_date_gt: undefined,
  created_date_gte: undefined,
  created_date_last_n_days: undefined,
  created_date_lt: undefined,
  created_date_lte: undefined,
  created_date_nth_full_week: undefined,
  created_date_nth_work_week: undefined,
  id_in: [],
  limit: '10',
  name_contains: undefined,
  ordering: undefined,
  page: undefined,
  plugin_vms_id: undefined,
  verbose_name: undefined,
  version: undefined,
  vms: undefined,
}