import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsBar = _resolveComponent("SettingsBar")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_SettingsBar, {
        "create-label-i18-n": "common.user_create",
        "total-count": _ctx.module.count,
        "has-create": _ctx.modelAcl.add,
        onSearch: _ctx.handleSearch,
        onCreate: _ctx.handleCreate
      }, null, 8, ["total-count", "has-create", "onSearch", "onCreate"]),
      _createVNode(_component_NTable, {
        columns: _ctx.columns,
        content: _ctx.items,
        "selected-items-ids": _ctx.selectedItemIds,
        "onSelect:changes": _ctx.handleSelectChanges,
        disabled: !_ctx.modelAcl.update,
        onAction: _ctx.handleTableAction,
        onSort: _ctx.handleTableSort
      }, null, 8, ["columns", "content", "selected-items-ids", "onSelect:changes", "disabled", "onAction", "onSort"]),
      _createVNode(_component_InfiniteScroll, {
        onLoadMore: _cache[0] || (_cache[0] = () => _ctx.module.append()),
        "next-page": _ctx.module.next_page
      }, null, 8, ["next-page"])
    ]),
    _: 1
  }))
}