
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { FaceClusterEvent } from '@/api';
import WatchListGroup from '@/components/common/WatchListsGroup.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { loadDataModule } from '@/store/data/LoadDataModule';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'ClusterItem',
  components: { NButton, WatchListGroup, NThumbnail },
  emits: ['show-cluster-event-image']
})
export default class InteractionItem extends Vue {
  @Prop({ type: Array, required: true })
  items!: number[];

  @Prop({ type: Boolean, default: false })
  readonly videoEnabled!: boolean;

  firstClusterItem?: FaceClusterEvent | null = null;
  secondClusterItem?: FaceClusterEvent | null = null;

  async mounted() {
    await Promise.all([this.setFirstClusterItem(this.items[0]), this.setSecondClusterItem(this.items[1])]);
  }

  get fullCameraName(): string {
    const cameraName = this.camera?.name ?? '?';
    const cameraGroupName = this.cameraGroup?.name ?? '?';
    return cameraName + ' / ' + cameraGroupName;
  }

  get canPlayVideo() {
    return this.videoEnabled && this.camera;
  }

  get dataAssetsModule() {
    return dataAssetsModule;
  }

  get camera() {
    return this.firstClusterItem ? dataAssetsModule.getCameraById(this.firstClusterItem.camera) : undefined;
  }

  get cameraGroup() {
    return this.firstClusterItem ? dataAssetsModule.getCameraGroupById(this.firstClusterItem?.camera_groups?.[0]) : undefined;
  }

  @Watch('items[0]')
  async setFirstClusterItem(id?: number): Promise<void> {
    this.firstClusterItem = id ? await loadDataModule.getClusterEventsByID({ id, type: 'faces' }) : undefined;
  }

  @Watch('items[1]')
  async setSecondClusterItem(id?: number): Promise<void> {
    this.secondClusterItem = id ? await loadDataModule.getClusterEventsByID({ id, type: 'faces' }) : undefined;
  }

  showClusterEventImage(items: FaceClusterEvent[]) {
    this.$photoViewer.show(items);
  }

  playVideo() {
    const firstDate = new Date(this.firstClusterItem!.created_date);
    const secondDate = new Date(this.secondClusterItem!.created_date);
    const resultTime = Math.min(firstDate.getTime(), secondDate.getTime()) / 1000;
    this.$videoPlayer.playArchive(this.camera!.id, resultTime - 3);
  }
}
