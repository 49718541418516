export function filterItemsByName(items: any[], query: string) {
  const re = new RegExp(query, 'i');
  return items.filter((item) => re.test(item.name));
}

export function sortByField(fieldName: any) {
  let [field, direction] = fieldName.charAt(0) === '-' ? [fieldName.slice(1), -1] : [fieldName, 1];
  return function (a: any, b: any) {
    return String(a[field]).localeCompare(b[field]) * direction;
  };
}
