
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { dataModule } from '@/store/data';
import NSelect from '@/uikit/select/NSelect.vue';

@Options({
  name: 'CameraMultiple',
  components: { NSelect }
})
export default class CameraMultiple extends Vue {
  @Prop({ type: Array, required: true })
  readonly modelValue!: number[];

  @Prop({ type: Boolean, default: false })
  readonly accent?: boolean;

  @Prop({ type: Boolean, default: false })
  readonly readonly?: boolean;

  @Prop({ type: Array, default: () => [] })
  readonly cameraGroups!: number[];

  get items() {
    let items = dataModule.camerasModule.items;
    if (this.cameraGroups && this.cameraGroups.length) {
      items = items.filter((item) => this.cameraGroups.includes(item.group));
    }
    let result: any[] = items.map((item) => ({
      label: item.name,
      value: item.id
    }));
    return result;
  }

  updateValue(value: number) {
    this.$emit('update:modelValue', value);
  }

  get names() {
    const dataItems = dataModule.camerasModule.items;
    const names = dataItems.filter((item) => this.modelValue.includes(item.id)).map((item) => item.name);
    if (names.length) return names.join(', ');
    return '[cameras:not found]';
  }
}
