import { ITableColumn } from '@/uikit/table/NTable.vue';
import { languageModule } from '@/store/languages';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import GroupItem from './GroupItem.vue';
import { Group } from '@/api';
import ButtonModelCopy from '@/components/common/ButtonModelCopy.vue';

const t = languageModule.getTranslatedToken;

export const columns: ITableColumn[] = [
  {
    width: 'max-content',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.id'
      }
    },
    content: {
      path: 'id',
      component: ButtonModelCopy
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        label: t('common.column_name', 'f'),
        name: 'name'
      }
    },
    content: {
      path: 'name',
      component: GroupItem,
      props: (item: Group) => ({ name: item.name })
    }
  }
];
