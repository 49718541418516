
import { Options, Vue } from 'vue-property-decorator';
import { VideoWallLayout } from '../../types';
import { VideoWallLayoutButton } from '../VideoWallLayoutButton';

@Options({
  components: { VideoWallLayoutButton },
  emits: ['click']
})
export default class VideoWallLayoutButtonGroup extends Vue {
  readonly layouts: Readonly<VideoWallLayout[]> = [
    '1_camera',
    '4_cameras',
    '6_cameras',
    '9_cameras',
    '1_camera_and_episodes',
    '2_cameras_and_episodes',
    '3_cameras_and_episodes',
    '4_cameras_and_episodes'
  ];

  dispatchClickEvent(layout: VideoWallLayout): void {
    this.$emit('click', layout);
  }
}
