
import { defineComponent, ref, toRefs } from 'vue';
import { bool, oneOf } from 'vue-types';
import { useDropdownOnClickOutsideListener, useDropdownOnEscapeListener, useDropdownToggler } from './helpers/engines';
import { NDropdownCommand, placement } from './helpers/types';

export default defineComponent({
  name: 'NDropdown',
  props: {
    placement: oneOf(placement).def('bottom-start'),
    insertIntoBody: bool().def(true),
    disabled: bool().def(false)
  },
  emits: ['command'],
  setup: (props) => {
    const { placement, disabled } = toRefs(props);
    const root = ref<HTMLDivElement | null>(null);
    const body = ref<HTMLDivElement | null>(null);
    const { hide, toggle, isShown } = useDropdownToggler(root, body, placement, disabled);
    useDropdownOnClickOutsideListener(root, body, hide);
    useDropdownOnEscapeListener(hide);
    return { root, body, hide, toggle, isShown };
  },
  provide() {
    return { dispatchCommandEvent: this.dispatchCommandEvent };
  },
  computed: {
    isTeleportDisabled() {
      return !this.insertIntoBody;
    }
  },
  methods: {
    dispatchCommandEvent(command?: NDropdownCommand) {
      this.$emit('command', command);
      this.hide();
    }
  }
});
