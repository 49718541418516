import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "counter-record-info" }
const _hoisted_2 = { class: "counter-record-info__hoverable" }
const _hoisted_3 = { class: "counter-record-info__error_msg" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.item.error_msg)
      ? (_openBlock(), _createBlock(_component_NHint, { key: 0 }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.error_msg), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, "#" + _toDisplayString(_ctx.item.id), 1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, "#" + _toDisplayString(_ctx.item.id), 1)),
    _createElementVNode("div", null, _toDisplayString(_ctx.createdDateTime), 1)
  ]))
}