
import { computed, defineComponent, reactive, toRefs, watch, onMounted, ComponentPublicInstance, ref } from 'vue';
import dayjs, { Dayjs } from './dayjs';
import dateTimeMasker from './date-time-masker';
import { timeToSeconds } from './time-convert';
import NDropdown from '../dropdown/NDropdown.vue';
import NTimePicker from './NTimePicker.vue';
import NInput from '../input/NInput.vue';
import { IDateSource } from '../datetime/types';
import { setToLimits } from '../datetime/set-to-limits';

function convertToValidMinDate(date: Dayjs, secondsEnabled = false) {
  return date.get('second') === 0 ? date : date.add(1, secondsEnabled ? 'second' : 'minute');
}

export default defineComponent({
  components: { NDropdown, NTimePicker, NInput },
  props: {
    modelValue: { type: [Number, String, Date] },
    min: { type: [Number, String, Date] },
    max: { type: [Number, String, Date] },
    step: { type: String, default: '00:30' },
    name: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    plain: { type: Boolean },
    autofocus: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    secondsEnabled: { type: Boolean, default: true },
    accesskey: { type: String },
    dataQa: { type: String }
  },
  emits: ['blur', 'focus', 'update:modelValue'],
  setup(props, { emit }) {
    const state = reactive({
      date: null as Dayjs | null,
      userInput: '',
      nInput: null as ComponentPublicInstance<unknown, { input: HTMLInputElement }> | null,
      dropdown: null as ComponentPublicInstance<unknown, { input: HTMLInputElement; hide: () => void }> | null,
      autoInsertDelimiter: true
    });

    onMounted(() => state.userInput && setNativeValue(state.userInput));

    const inputElement = computed(() => (state.nInput ? state.nInput.input : null));
    const format = computed(() => (props.secondsEnabled ? 'HH:mm:ss' : 'HH:mm'));

    const minDate = computed(() => (isDefined(props.min) ? computeMinDate(props.min) : null));
    const maxDate = computed(() => (isDefined(props.max) ? computeMaxDate(props.max) : null));

    function computeMinDate(date: Date | number | string) {
      const min = dayjs(date);
      return state.date?.isAfter(min, 'd') ? midnight(state.date) : min;
    }

    function computeMaxDate(date: Date | number | string) {
      const max = dayjs(date);
      return state.date?.isBefore(max, 'd') ? evening(state.date) : max;
    }

    function midnight(date: Dayjs): Dayjs {
      return dayjs(date.toDate().setHours(0, 0, 0, 0));
    }

    function evening(date: Dayjs): Dayjs {
      return dayjs(date.toDate().setHours(23, 59, 0, 0));
    }

    const start = computed(() => {
      const defaultStart = props.secondsEnabled ? '00:00:00' : '00:00';
      return minDate.value ? convertToValidMinDate(minDate.value, props.secondsEnabled).format(format.value) : defaultStart;
    });

    const end = computed(() => {
      const defaultEnd = props.secondsEnabled ? '23:59:59' : '23:59';
      return maxDate.value ? maxDate.value.format(format.value) : defaultEnd;
    });

    const isValid = computed(() => {
      let timeEnteredValid = state.userInput.length === format.value.length && state.date && state.date.isValid(),
        timeEmptyValid = state.userInput === '' && state.date === null;
      return (timeEnteredValid && getIsInRange()) || timeEmptyValid;
    });

    watch(() => props.modelValue, valueHandler, { immediate: true });
    function valueHandler(v: IDateSource | undefined) {
      let userInput = '';
      if (v === undefined || v === '') {
        state.date = null;
      } else {
        let date = dayjs(v);
        if (date.isValid()) {
          state.date = date;
          userInput = state.date.format(format.value);
        }
      }
      state.userInput = userInput;
      setNativeValue(state.userInput);
    }

    const blurHandler = (e: Event) => emit('blur', e);
    const focusHandler = (e: Event) => emit('focus', e);
    const selectHandler = (time: string) => {
      let seconds = timeToSeconds(time);
      state.date = (state.date || dayjs()).startOf('day').second(seconds);
      state.userInput = time;
      emitChange();
    };
    const inputHandler = (v: string) => {
      if (v !== state.userInput) {
        let maskerResult = convertToTimeAndMask(v);
        setNativeValue(maskerResult.value, maskerResult.caretPosition);
        state.userInput = maskerResult.value;
        if (state.userInput && state.userInput.length === format.value.length) {
          let seconds = timeToSeconds(state.userInput); // todo: use 'end' & 'start' to limit value
          state.date = (state.date || dayjs()).startOf('day').second(seconds);
          emitChange();
        } else {
          state.date = null;
          emitChange();
        }
      }
    };

    function emitChange() {
      state.date = state.date ? setToLimits(state.date, minDate.value, maxDate.value) : null;
      isValid.value && emit('update:modelValue', state.date ? state.date.toDate() : null);
    }

    function convertToTimeAndMask(v: string) {
      let caretPosition = inputElement.value ? inputElement.value.selectionEnd : v.length;
      let format = props.secondsEnabled ? '00:00:00' : '00:00',
        time = dateTimeMasker(v, format, caretPosition, state.autoInsertDelimiter);
      return time;
    }

    function getIsInRange() {
      if (!state.date) return true;
      let isAfterMin = true;
      if (minDate.value) isAfterMin = state.date.isSame(minDate.value) || state.date.isAfter(minDate.value);
      let isBeforeMax = true;
      if (maxDate.value) isBeforeMax = state.date.isSame(maxDate.value) || state.date.isBefore(maxDate.value);

      return isAfterMin && isBeforeMax;
    }

    function setNativeValue(value: string, caretPosition: null | number = null) {
      if (inputElement.value) {
        inputElement.value.value = value;
        if (caretPosition !== null) {
          inputElement.value.setSelectionRange(caretPosition, caretPosition);
        }
      }
    }

    const hidePicker = ref<(() => void) | undefined>(undefined);
    onMounted(() => {
      hidePicker.value = state.dropdown?.hide;
    });

    return {
      ...toRefs(state),
      ...toRefs(props),
      start,
      end,
      format,
      blurHandler,
      focusHandler,
      selectHandler,
      inputHandler,
      hidePicker,
      isValid
    };
  }
});

function isDefined<T>(value: T | null | undefined): value is T {
  return value != null;
}
