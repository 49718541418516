
import { isDefined } from '@/common/utils';
import Confirm from '@/components/common/Confirm.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';
import { FeatureObject } from '@/components/common/types';
import { eventEpisodeAdapter } from '@/components/events/adapter';
import EventItem from '@/components/events/EventItem.vue';
import { EventOpenedItems, EventOrEpisode } from '@/components/events/types';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { getEventFiltersBuilder } from '@/pages/events/forms/EventFiltersBuilder';
import { getMockFilterByType } from '@/pages/events/forms/mockFilters';
import ListPage from '@/pages/ListPage.vue';
import { applicationModule } from '@/store/application';
import { EpisodesMultiToSingle, EpisodeTypesMap, ObjectsMultiToSingle } from '@/store/application/data.assets';
import { dataAssetsModule, DataAssetsModule } from '@/store/application/data.assets.module';
import { EventsPageState, PagePaths, PageState, PageTypes } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { PageViewModel } from '@/store/application/page.view.model';
import { workspaceModule } from '@/store/application/workspace';
import { actionHandler } from '@/store/data/ActionHandler';
import { satisfyEpisodeFilter } from '@/store/events/satisfy.episode.filter';
import { satisfyEventFilter } from '@/store/events/satisfy.event.filter';
import { FilterValue } from '@/components/common/filter/filter-manager';
import { globalEventModule, GlobalEventName } from '@/store/global-event';
import { GlobalEvent } from '@/store/global-event/types';
import { IntersectionResultItem } from '@/store/intersection/IntersectionModule';
import { multisidebarModule } from '@/store/multisidebar';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { websocketModule } from '@/store/ws/websocket.module';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import { EventDetails } from '@/uikit/thumbnail/helpers/enums';
import { cloneDeep, merge } from 'lodash';
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import DisplayTypes from '@/components/common/DisplayTypes.vue';
import { aclModule } from '@/store/acl';
import { EpisodeUpdatableProperties, fillExistsItem } from '@/pages/events/helpers';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import { ImageViewerActions } from '@/uikit/image-viewer/types';

@Options({
  name: 'EventsPage',
  components: {
    FilterSection,
    DisplayTypes,
    Confirm,
    NButton,
    NIcon,
    SortDropdown,
    ListPage,
    NButtonGroup,
    Statistics,
    EventItem
  }
})
export default class EventsPage extends Vue {
  @Prop({ type: String, required: true })
  readonly tab!: string;

  @Prop({ type: Object, required: false })
  readonly externalFilters!: any;

  isAcknowledgingConfirmVisible = false;
  pageVM: PageViewModel<any, any> = new PageViewModel({ tab: 'before', path: PagePaths.Events });
  intersectionResultItem?: IntersectionResultItem | null = null;

  get acknowledgeAllAllowed() {
    if (this.isEvents) {
      const type = ObjectsMultiToSingle[this.state.objectType];
      return aclModule.getAccess(`ffsecurity.change_${type}event`);
    } else {
      return this.state.episodeType === EpisodeTypesMap.Cars
        ? aclModule.getAccess('ffsecurity.change_carevent')
        : ['ffsecurity.change_faceevent', 'ffsecurity.change_bodyevent'].some((v) => aclModule.getAccess(v));
    }
  }

  get acknowledgeAllowed() {
    const { objectType, episodeType } = this.state;
    const type = this.isEvents ? ObjectsMultiToSingle[objectType] : EpisodesMultiToSingle[episodeType];
    const page = this.isEvents ? 'event' : 'episode';
    const permisssion = `ffsecurity.change_${type}${page}`;
    return aclModule.getAccess(permisssion);
  }

  get selectedItems() {
    return this.sidebarModule.getItemsByType(this.pageSidebarType);
  }

  get selectedCardItems() {
    return this.sidebarModule.getItemsByType(this.cardsSidebarType);
  }

  get pageSidebarType() {
    const { pageType, objectType, episodeType } = this.state;
    if (pageType === PageTypes.Events) {
      return `${pageType}_${objectType}`;
    }
    return `${pageType}_${episodeType}`;
  }

  get cardsSidebarType() {
    return `${PageTypes.Cards}_${this.cardType}`;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get dataAssetsModule(): DataAssetsModule {
    return dataAssetsModule;
  }

  get debug(): boolean {
    return applicationModule.settings.debug;
  }

  get state(): EventsPageState {
    return this.pageVM.state as any;
  }

  get module(): ListViewModel<any, any> {
    // TODO: Check & Refactor This
    this.pageVM.module.filter.force = this.externalFilters;
    return this.pageVM.module as ListViewModel<any, any>;
  }

  get smallFilterLayout() {
    const { pageType, objectType, episodeType } = this.state;
    return getEventFiltersBuilder({ small: true, cardType: this.cardType }).getFilterByType(pageType, objectType, episodeType);
  }

  get getBigFilterLayout() {
    const { pageType, objectType, episodeType } = this.state;
    return getEventFiltersBuilder({ small: false, cardType: this.cardType }).getFilterByType(pageType, objectType, episodeType);
  }

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get description() {
    return this.isEvents ? this.$t('events.all_events_acknowledged_confirm') : this.$t('events.all_episodes_acknowledged_confirm');
  }

  get classes(): Record<string, boolean> {
    return {
      'n-page__tiles': this.isShort,
      'n-page__tiles-compensation-5': this.isShort,
      'n-page__rows': !this.isShort
    };
  }

  get cardType() {
    return this.isEvents ? dataAssetsModule.getCardTypeByObjectType(this.state.objectType) : dataAssetsModule.getCardTypeByEpisodeType(this.state.episodeType);
  }

  get isEvents(): boolean {
    return this.state.pageType === PageTypes.Events;
  }

  get viewerItems() {
    return this.module.items.map((v: any) => eventEpisodeAdapter(v, this.state.objectType));
  }

  get websocketModule() {
    return websocketModule;
  }

  get hasCustomOrdering() {
    const ordering = this.module.filter.current.ordering;
    return ordering && ordering !== '-id' && ordering !== '-created_date';
  }

  get isCurrentTab() {
    return (this.state.tab || '').indexOf(workspaceModule.current || '') > -1;
  }

  get lastPageGlobalEvent() {
    return globalEventModule.current?.type === this.pageSidebarType ? globalEventModule.current : null;
  }

  get isShort() {
    return this.state.displayType === 'short';
  }

  doesItemExist(id: number) {
    return this.module.items.find((item: EventOrEpisode) => item.id === id) ?? null;
  }

  togglePlaying() {
    this.module.filter.current.created_date_lte = this.pageVM.state.playing ? new Date().toISOString() : undefined;
    this.pageVM.togglePlaying();
  }

  getOpenedTypeByItem(item: EventOrEpisode) {
    if (!this.currentSidebarItemId) return '';
    if (this.currentSidebarItemId === generateMultisidebarId(this.pageSidebarType, item.id)) return EventOpenedItems.Item;
    if (item.matched_card && this.currentSidebarItemId === generateMultisidebarId(this.cardsSidebarType, item.matched_card)) return EventOpenedItems.Card;
  }

  getIsEventOrEpisodeSelected(item: EventOrEpisode) {
    const msbId = generateMultisidebarId(this.pageSidebarType, item.id);
    return !!this.selectedItems.find((v) => v.id === msbId);
  }

  getIsCardSelected(id: number) {
    const msbId = generateMultisidebarId(this.cardsSidebarType, id);
    return !!this.selectedCardItems.find((v) => v.id === msbId);
  }

  computeEventsState(objectType: string): PageState {
    const result = pageModule.getPageStateByTab(PagePaths.Events, 'events');
    result.pageType = PageTypes.Events;
    result.objectType = objectType;
    return result;
  }

  computeEventsModule(objectType: string): ListViewModel<any, any> {
    const state = this.computeEventsState(objectType);
    return pageModule.getPageModule(state) as unknown as ListViewModel<any, any>;
  }

  navigateToCard(id: string | number) {
    const sidebarType = `${PageTypes.Cards}_${this.cardType}`;
    actionHandler.run(ItemsActionNames.ShowItem, { type: sidebarType, rawItem: id });
  }

  copyCommonFilters(from: any, to: any) {
    const items = [
      'acknowledged',
      'bs_type',
      'camera_groups',
      'cameras',
      'case_in',
      'episode',
      'has_case',
      'matched_card',
      'matched_lists',
      'no_match',
      'video_archive'
    ];
    items.forEach((name) => {
      to[name] = from[name];
    });
  }

  @Watch('externalFilters', { deep: true })
  changeExternalFilters(v: any) {
    this.module.filter.current = Object.assign(this.module.filter.current, v);
  }

  @Watch('module', { deep: false })
  changeModuleHandler(v: any, p: any) {
    if (p && v) this.copyCommonFilters(p.filter.current, v.filter.current);
    this.intersectionResultItem?.reset();
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(v: any, p: any) {
    if (!this.isCurrentTab) return;
    if (v?.page !== p?.page || v?.limit !== p?.limit) return;
    this.module.debouncedGet();
    this.state.filter = this.module.filter.currentClean;
  }

  @Watch('module.loading')
  loadingHandler(v: boolean) {
    if (!v) {
      this.intersectionResultItem?.reset();
      this.intersectionResultItem?.syncObservableTargetsNextTick();
    }
  }

  @Watch('module.appending')
  appendingHandler(v: boolean) {
    if (!v) this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  @Watch('websocketModule.event', { deep: true })
  websocketEventHandler(v: any) {
    const canAdd = this.state.playing && this.isEvents && !this.hasCustomOrdering;
    if (!canAdd) return;
    const type = ObjectsMultiToSingle[this.state.objectType];
    const result = v[type];
    if (!result) return;
    const satisfy = satisfyEventFilter(result, this.module.filter.current, this.state.objectType as FeatureObject);
    if (!satisfy) {
      console.warn('Event is not satisfied: ', result, this.module.filter.current);
      return;
    }

    const existing = this.doesItemExist(result.id);
    isDefined(existing) ? fillExistsItem(existing, result) : this.module.items.unshift(result);
    this.intersectionResultItem?.syncObservableTargetsNextTick();
    this.limitItemsCount();
  }

  @Watch('websocketModule.eventUpdated', { deep: true })
  websocketEventUpdateHandler(v: any) {
    const type = ObjectsMultiToSingle[this.state.objectType];
    const result = v[type];
    if (!result) return;
    const existing = this.doesItemExist(result.id);
    isDefined(existing) && fillExistsItem(existing, result);
  }

  @Watch('websocketModule.episode', { deep: true })
  websocketEpisodeHandler(v: any) {
    const canAdd = this.state.playing && !this.isEvents && !this.hasCustomOrdering;
    if (!canAdd) return;
    const type = EpisodesMultiToSingle[this.state.episodeType];
    const result = v[type];
    if (!result) return;

    const satisfy = satisfyEpisodeFilter(result, this.module.filter.current, this.state.episodeType);
    if (!satisfy) {
      console.warn('Episode is not satisfied: ', result, this.module.filter.current);
      return;
    }

    const existing = this.doesItemExist(result.id);
    isDefined(existing) ? fillExistsItem(existing, result) : this.module.items.unshift(result);
    this.intersectionResultItem?.syncObservableTargetsNextTick();
    this.limitItemsCount();
  }

  @Watch('websocketModule.episodeUpdated', { deep: true })
  websocketEpisodeUpdateHandler(v: any) {
    const type = EpisodesMultiToSingle[this.state.episodeType];
    const result = v[type];
    if (!result) return;
    const existing = this.doesItemExist(result.id);
    isDefined(existing) && fillExistsItem(existing, result, EpisodeUpdatableProperties);
  }

  @Watch('websocketModule.acknowledgedAll')
  handleAcknowledgedAll(v: boolean) {
    v && this.module.get();
  }

  @Watch('lastPageGlobalEvent')
  handleGlobalEvent(event: GlobalEvent) {
    if (!event) return;
    switch (event.name) {
      case GlobalEventName.Update:
        merge(
          this.module.items.find((v) => v.id === event.payload.id),
          event.payload
        );
        break;
    }
  }

  created() {
    const route = cloneDeep(this.$route);
    route.query.tab = this.tab;
    const page = pageModule.getPageViewModelDirect(PagePaths.Events, {}, this.tab);
    this.pageVM = reactive(page) as PageViewModel<any, any>;
    if (this.externalFilters) this.module.filter.current = Object.assign(this.module.filter.current, this.externalFilters);
  }

  mounted() {
    this.intersectionResultItem = new IntersectionResultItem({
      container: document.querySelector('.page-content') as HTMLElement,
      itemsQuerySelector: '.event-item-proxy',
      id: this.tab
    });
    this.intersectionResultItem?.reset();
    this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  beforeUnmount() {
    this.intersectionResultItem?.reset();
  }

  get defaultAction() {
    return EventDetails.ShowInfo;
  }

  get currentSidebarItemId() {
    return this.sidebarModule.currentItem?.id;
  }

  get displayItemsMap(): Record<string, boolean> | null {
    return this.intersectionResultItem?.displayItemsMap ?? null;
  }

  async actionHandler(id: string | number, action: string, payload?: any): Promise<void> {
    const event = payload?.best_face_event || payload?.best_body_event || payload?.best_car_event || payload;
    switch (action) {
      case ItemsActionNames.ShowInfo:
      case ItemsActionNames.ShowItem:
        await actionHandler.run(ItemsActionNames.ShowItem, { type: this.pageSidebarType, rawItem: id });
        break;
      case ItemsActionNames.ShowFullScreen:
        this.showItemFullscreen(payload);
        break;
      case ItemsActionNames.ShowPlayer:
        if (event) {
          const timeFrom = new Date(event.created_date).getTime() / 1000;
          this.$videoPlayer.playArchive(event.camera, timeFrom - 3);
        }
        break;
      case ItemsActionNames.Acknowledge:
        await this.acknowledgeHandler(id, payload);
        break;
      case ItemsActionNames.NavigateToCard:
        await this.navigateToCard(payload.matched_card);
        break;
      default:
        console.warn('Unsupported action ', id, action, payload);
        break;
    }
  }

  scrollBottomHandler(v: number | null) {
    if (!this.isCurrentTab) return;
    if (typeof v === 'number' && v < 200) {
      this.module.append();
    }
  }

  async acknowledgeHandler(id: string | number, payload: any): Promise<void> {
    const item = await this.module.update({ id: id, acknowledged: payload.value });
    const moduleItem = this.module.items.find((v) => v.id === id);
    let { acknowledged, acknowledged_by, acknowledged_date } = item;
    let changes = { id, acknowledged, acknowledged_by, acknowledged_date };
    moduleItem && Object.assign(moduleItem, changes);
  }

  async acknowledgeAllHandler(): Promise<void> {
    this.isAcknowledgingConfirmVisible = false;
    if (this.isEvents) {
      await this.module.dataService.createItemSomethingByAction('', 'acknowledge');
    } else {
      const { episodeType } = this.state;
      if (episodeType === 'cars') {
        const module = this.computeEventsModule('cars');
        dataAssetsModule.availableObjectsMap.car && (await module.dataService.createItemSomethingByAction('', 'acknowledge'));
      } else {
        const facesModule = this.computeEventsModule('faces');
        const bodiesModule = this.computeEventsModule('bodies');
        const hasFacePermission = aclModule.getAccess('ffsecurity.change_faceevent');
        const hasBodyPermission = aclModule.getAccess('ffsecurity.change_bodyevent');
        hasFacePermission && (await facesModule.dataService.createItemSomethingByAction('', 'acknowledge'));
        hasBodyPermission && dataAssetsModule.availableObjectsMap.body && (await bodiesModule.dataService.createItemSomethingByAction('', 'acknowledge'));
      }
    }
  }

  setTestData() {
    const { pageType, objectType, episodeType } = this.state;
    const testData = getMockFilterByType(pageType, objectType, episodeType);
    Object.assign(this.module.filter.current, testData);
  }

  selectFilter(v?: FilterValue) {
    this.module.filter.setCurrentByString(v?.value ?? '');
  }

  limitItemsCount() {
    const scrollTop = this.$refs.listPage?.getScrollTop();
    const limit = 100;
    const allowedScrollValue = scrollTop >= 0 && scrollTop <= 200;
    const isLimitExceeded = this.module.items.length > limit * 2;
    if (allowedScrollValue && isLimitExceeded) this.module.items.splice(limit, 1e6);
  }

  showItemFullscreen(item: EventOrEpisode) {
    const activeItemIndex = this.viewerItems.findIndex((v) => v.id == item.id);
    if (activeItemIndex !== -1) {
      this.viewerItems[activeItemIndex]['actions'] = [ImageViewerActions.Detect];
      this.$photoViewer.show(this.viewerItems, { activeItemIndex });
    }
  }
}
