import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createBlock(_component_NButton, {
    label: _ctx.label,
    onClick: _ctx.changeSystemTheme,
    class: "login-page-system-theme-changer",
    "icon-size": "16",
    "suffix-icon": _ctx.icon,
    type: "text"
  }, null, 8, ["label", "onClick", "suffix-icon"]))
}