
import ImageViewer from '@/components/image-viewer/ImageViewer.vue';
import ExternalSearchRequests from '@/pages/external-search/requests/ExternalSearchRequests.vue';
import ExternalSearchResults from '@/pages/external-search/results/ExternalSearchResults.vue';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NInput from '@/uikit/input/NInput.vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'ExternalSearchPage',
  components: {
    ExternalSearchRequests,
    ExternalSearchResults,
    NButton,
    ImageViewer,
    NInput,
    PageContentLayout
  }
})
export default class ExternalSearchPage extends Vue {
  name_contains = '';
  searchRequestId: number = -1;
  src: string | null = null;

  displayImage(src: string): void {
    this.src = src;
  }

  disposeEventImage(): void {
    this.src = null;
  }

  getSearchRequestIdFromQuery() {
    const id = this.$route.query.id;
    if (id && !Array.isArray(id)) this.searchRequestId = parseInt(id);
  }

  mounted() {
    this.getSearchRequestIdFromQuery();
  }
}
