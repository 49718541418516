import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicensePageTabs = _resolveComponent("LicensePageTabs")!
  const _component_LicensePageButtonDownload = _resolveComponent("LicensePageButtonDownload")!
  const _component_LicensePageButtonUpload = _resolveComponent("LicensePageButtonUpload")!
  const _component_LicensePageButtonUpdate = _resolveComponent("LicensePageButtonUpdate")!
  const _component_LicensePageHeader = _resolveComponent("LicensePageHeader")!
  const _component_LicensePageTabView = _resolveComponent("LicensePageTabView")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_LicensePageHeader, null, {
        tabs: _withCtx(() => [
          _createVNode(_component_LicensePageTabs, {
            tabs: _ctx.enabledTabs,
            modelValue: _ctx.tabName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tabName) = $event))
          }, null, 8, ["tabs", "modelValue"])
        ]),
        download: _withCtx(() => [
          _createVNode(_component_LicensePageButtonDownload, { onClick: _ctx.handleDownloadEvent }, null, 8, ["onClick"])
        ]),
        upload: _withCtx(() => [
          _createVNode(_component_LicensePageButtonUpload, { onClick: _ctx.handleUploadEvent }, null, 8, ["onClick"])
        ]),
        update: _withCtx(() => [
          _createVNode(_component_LicensePageButtonUpdate, { onClick: _ctx.handleUpdateEvent }, null, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_LicensePageTabView, {
        class: "license-page-tab-view",
        license: _ctx.license,
        "license-usage": _ctx.licenseUsage,
        "tab-name": _ctx.tabName
      }, null, 8, ["license", "license-usage", "tab-name"])
    ]),
    _: 1
  }))
}