
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
  WatchListItemMarkers,
  WatchListItemMarker,
  WatchListTextSizes,
  WatchListTextSize,
  WatchListItemTextTypes,
  WatchListItemTextType
} from './watch-list-types';

@Options({
  name: 'WatchListItem'
})
export default class WatchListItem extends Vue {
  @Prop({ type: String, required: true })
  readonly color!: string;

  @Prop({ type: String, required: true })
  readonly name!: string;

  @Prop({ type: String, default: WatchListItemMarkers.Stick })
  readonly marker!: WatchListItemMarker;

  @Prop({ type: String, default: WatchListItemTextTypes.Common })
  readonly textType!: WatchListItemTextType;

  @Prop({ type: String, required: false })
  readonly textColor?: string;

  @Prop({ type: String, default: WatchListTextSizes.Normal })
  readonly textSize!: WatchListTextSize;

  @Prop({ type: Boolean, default: false })
  readonly tagView!: boolean;

  handleClick() {
    this.$emit('update:modelValue', this.name); // todo: on link type only for now
  }

  get classes() {
    return {
      'watch-list-item': true,
      'watch-list-item_tag': this.tagView,
      [`watch-list-item_text-type-${this.textType}`]: true,
      [`watch-list-item_${this.marker}`]: true,
      [`watch-list-item_text-size-${this.textSize}`]: true,
      'label-m': this.textSize === WatchListTextSizes.Normal,
      'label-s': this.textSize === WatchListTextSizes.Medium,
      'label-2xs': this.textSize === WatchListTextSizes.Small
    };
  }
}
