import { ActionsModuleOptionName } from '@/store/data/ActionsModule';
import { ItemAclResult, ModelAclResult } from '@/store/acl/types';
import { WebsocketModule } from '@/store/ws/websocket.module';
import { DataService } from '@/definitions/services/data.services';

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type MultisidebarSharedState = {
  activeTab?: string;
};

export type MultisidebarGroup = MultisidebarGroupConfig & { items: MultisidebarGroupItem<unknown>[]; hasCurrent: boolean };

export type MultisidebarGroupConfig = {
  name: string;
  icon: string;
};

export type MultisidebarItem<T> = {
  id: string;
  type: MultisidebarItemType;
  model: T;
};

export type MultisidebarLocationState = {
  currentId: string;
  ids: string[];
};

export type ModelLoaderOptions = { aclPrefix?: string; aclModelName?: string; immediate?: boolean };

export type MultisidebarRawItemPayload = { type: MultisidebarItemType; rawItem: any };
export type MultisidebarRawItemsPayload = { type: MultisidebarItemType; rawItems: any[] };

export type MultisidebarGroupItem<T> = {
  item: MultisidebarItem<T>;
  config: MultisidebarItemConfig<T>;
};

export type MultisidebarThumbnailType = 'common' | 'episode' | 'camera';

export type MultisidebarItemAttributes = {
  label?: string;
  extraLabel?: string;
  thumbnailSrc?: string | (() => Promise<string>);
  thumbnailType?: MultisidebarThumbnailType;
  // todo: add background color, when new colorPicker will be ready with new colors contract
};

export type DefaultMultisidebarItemConfigKeys = 'itemLoader' | 'getModelAcl' | 'aclPrefix' | 'websocketUpdateHandler' | 'globalEventDelayTimeouts';

export type GlobalEventDelayTimeouts = {
  create: number;
  delete: number;
  update: number;
};

export type MultisidebarItemConfig<T> = {
  type: string;
  groupName: string;
  contentComponent: any;
  groupActionsOptions?: ActionsModuleOptionName[];
  itemLoader: (type: MultisidebarItemType, rawItemOrId: string | number | Record<any, any>, options?: ModelLoaderOptions) => Promise<MultisidebarItem<T>>;
  itemAttributesLoader: (item: MultisidebarItem<T>) => Promise<MultisidebarItemAttributes> | MultisidebarItemAttributes;
  aclPrefix: string;
  aclModelName: string;
  getModelAcl: (item: MultisidebarItem<T>) => ModelAclResult;
  getItemAcl?: (item: MultisidebarItem<T>, modelAcl: ModelAclResult) => ItemAclResult;
  websocketUpdateProperties?: (keyof WebsocketModule)[];
  websocketUpdateHandler?: (item: MultisidebarItem<T>, property: keyof WebsocketModule, data: Record<any, any>) => void;
  globalEventDelayTimeouts: GlobalEventDelayTimeouts;
};

export type CommonModel<T> = {
  item: T;
  loading: boolean;
  loaded: boolean;
  hasChanges: boolean;
  changedData: Partial<T>;
  isNew: boolean;
  loadError: any;
  dataService: DataService<T, any>;
  additionalData?: any;
  reset: () => void;
  get: (id: string | number) => Promise<boolean>;
  delete: (id: string | number) => Promise<boolean>;
  save: () => Promise<T | null>;
  update: (data: Partial<T> & { id: string | number }) => Promise<T | null>;
};

export type MultisidebarCommonItem = MultisidebarItem<CommonModel<Record<string, any>>>;

export type MultisidebarItemType = typeof MultisidebarItemTypes[keyof typeof MultisidebarItemTypes];
export const MultisidebarItemTypes = {
  AuditLogs: 'audit-logs',
  CameraGroups: 'camera-groups',
  Cameras: 'cameras',
  CardsCars: 'cards_cars',
  CardsHumans: 'cards_humans',
  ClustersBodies: 'clusters_bodies',
  ClustersCars: 'clusters_cars',
  ClustersFaces: 'clusters_faces',
  ClustersFacesEvents: 'clusters_faces_events',
  Counters: 'counters',
  CounterRecords: 'counter-records',
  EpisodesCars: 'episodes_cars',
  EpisodesHumans: 'episodes_humans',
  EventsBodies: 'events_bodies',
  EventsCars: 'events_cars',
  EventsFaces: 'events_faces',
  ExternalDetectors: 'external-detectors',
  ExternalVms: 'external-vms',
  Groups: 'groups',
  Lines: 'lines',
  Searches: 'searches',
  Users: 'users',
  Videos: 'videos',
  WatchLists: 'watch-lists',
  Webhooks: 'hooks',
  KYC: 'kyc'
} as const;

export const MultisidebarGroupNames = {
  AuditLogs: 'audit-logs',
  CameraGroups: 'cameragroups',
  Cameras: 'cameras',
  Cards: 'cards',
  Clusters: 'clusters',
  Counters: 'counters',
  Episode: 'episode',
  Events: 'events',
  ExternalVms: 'external-vms',
  Groups: 'groups',
  Lines: 'lines',
  Users: 'users',
  Videos: 'videos',
  WatchLists: 'watchlists',
  Webhooks: 'webhooks'
} as const;

export const MultisidebarGroupConfigs: MultisidebarGroupConfig[] = [
  { name: MultisidebarGroupNames.AuditLogs, icon: 'audit-log' },
  { name: MultisidebarGroupNames.CameraGroups, icon: 'camera-group' },
  { name: MultisidebarGroupNames.Cameras, icon: 'camera' },
  { name: MultisidebarGroupNames.Cards, icon: 'cards' },
  { name: MultisidebarGroupNames.Clusters, icon: 'clusters' },
  { name: MultisidebarGroupNames.Counters, icon: 'counters' },
  { name: MultisidebarGroupNames.Episode, icon: 'episodes' },
  { name: MultisidebarGroupNames.Events, icon: 'events' },
  { name: MultisidebarGroupNames.ExternalVms, icon: 'vms' },
  { name: MultisidebarGroupNames.Groups, icon: 'roles' },
  { name: MultisidebarGroupNames.Lines, icon: 'lines' },
  { name: MultisidebarGroupNames.Users, icon: 'users' },
  { name: MultisidebarGroupNames.Videos, icon: 'videos' },
  { name: MultisidebarGroupNames.WatchLists, icon: 'view-outline' },
  { name: MultisidebarGroupNames.Webhooks, icon: 'webhook' }
];
