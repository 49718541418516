import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "multisidebar-content-debug" }
const _hoisted_2 = {
  key: 0,
  class: "multisidebar-content-debug__extra-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.extraInfo)
      ? (_openBlock(), _createElementBlock("pre", _hoisted_2, _toDisplayString(_ctx.extraInfo), 1))
      : _createCommentVNode("", true),
    _createElementVNode("pre", null, "      " + _toDisplayString(_ctx.item) + "\n    ", 1),
    _createElementVNode("pre", null, "      Acl:\n      " + _toDisplayString(_ctx.itemAcl) + "\n    ", 1)
  ]))
}