/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CameraGroupsFilter = {
    /**
     * Number of results to return.
     */
    limit?: number;
    /**
     * Which field to use when ordering the results. Available fields: `id`, `created_date`, `modified_date`
     */
    ordering?: string;
}
export const EmptyCameraGroupsFilter: CameraGroupsFilter = {
    limit: 20,
    ordering: undefined,
}