/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { CounterStatus } from "./CounterStatus";
import { ProximityCalibrationStatusEnum } from "./ProximityCalibrationStatusEnum";
import { str_int_float_bool_object_array_null } from "./str_int_float_bool_object_array_null";

export type Counter = {
    readonly id: number;
    /**
     * Counter creation date
     */
    readonly created_date: string;
    /**
     * Counter modification date
     */
    readonly modified_date: string;
    active?: boolean;
    name: string;
    /**
     * `true` if face counting enabled
     */
    detect_faces?: boolean;
    /**
     * `true` if silhouette counting enabled
     */
    detect_silhouettes?: boolean;
    /**
     * `true` if cars counting enabled
     */
    detect_cars?: boolean;
    count_interval: number;
    /**
     * ROI XY coordinates for related cameras
     */
    roi?: Record<string, str_int_float_bool_object_array_null> | null;
    /**
     * Count entities from these cameras (provide either `cameras` or `camera_groups` argument)
     */
    cameras: Array<number>;
    /**
     * Counter is related to multiple cameras or a single camera
     */
    readonly multi_camera: boolean;
    readonly camera_groups: Array<number>;
    readonly health_status: CounterStatus;
    readonly now_faces: number;
    readonly now_cars: number;
    readonly now_bodies: number;
    /**
     * `true` if proximity detection enabled
     */
    detect_proximity?: boolean;
    /**
     * Calibration status
     */
    readonly proximity_calibration_status: ProximityCalibrationStatusEnum;
    /**
     * Calibration end date
     */
    readonly proximity_calibration_data_collection_end: string;
    readonly overall_proximity_min: number | null;
    readonly overall_proximity_max: number | null;
    readonly overall_proximity_avg: number | null;
    readonly now_proximity_min: number | null;
    readonly now_proximity_max: number | null;
    readonly now_proximity_avg: number | null;
    /**
     * Proximity calibration error description
     */
    readonly proximity_calibration_error: string;
};
export const EmptyCounter: Counter = {
    id: 0,
    /**
     * Counter creation date
     */
    created_date: '',
    /**
     * Counter modification date
     */
    modified_date: '',
    active: false,
    name: '',
    /**
     * `true` if face counting enabled
     */
    detect_faces: false,
    /**
     * `true` if silhouette counting enabled
     */
    detect_silhouettes: false,
    /**
     * `true` if cars counting enabled
     */
    detect_cars: false,
    count_interval: 1,
    /**
     * ROI XY coordinates for related cameras
     */
    roi: {},
    /**
     * Count entities from these cameras (provide either `cameras` or `camera_groups` argument)
     */
    cameras: [],
    /**
     * Counter is related to multiple cameras or a single camera
     */
    multi_camera: false,
    camera_groups: [],
    health_status: { } as any as CounterStatus,
    now_faces: 0,
    now_cars: 0,
    now_bodies: 0,
    /**
     * `true` if proximity detection enabled
     */
    detect_proximity: false,
    /**
     * Calibration status
     */
    proximity_calibration_status: ProximityCalibrationStatusEnum._1,
    /**
     * Calibration end date
     */
    proximity_calibration_data_collection_end: '',
    overall_proximity_min: 0,
    overall_proximity_max: 0,
    overall_proximity_avg: 0,
    now_proximity_min: 0,
    now_proximity_max: 0,
    now_proximity_avg: 0,
    /**
     * Proximity calibration error description
     */
    proximity_calibration_error: '',
};
