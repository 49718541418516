import {
  Exception,
  ExceptionCode,
  ExceptionDescription,
  ExceptionMeta,
  ExceptionTraceback,
  Optional,
  RequestException,
  RuntimeException,
  ServiceException
} from './types';
import { isNonEmptyString } from './utils';
import { languageModule } from '@/store/languages';
import { isNumber } from 'lodash';
import { HttpCodes } from '@/store/application/http.codes';

const t = languageModule.getTranslatedToken;

export class RequestWithoutResponseExceptionAdapter<T extends RequestException> implements Exception {
  constructor(private readonly exception: T) {}

  get code(): Optional<ExceptionCode> {
    return t('errors.server.no_response');
  }

  get description(): Optional<ExceptionDescription> {
    return t('errors.server.no_response_desc');
  }

  get meta(): Optional<ExceptionMeta> {
    return null;
  }

  get traceback(): Optional<ExceptionTraceback> {
    return null;
  }
}

export class RequestExceptionAdapter<T extends RequestException> implements Exception {
  constructor(private readonly exception: T) {}

  get code(): Optional<ExceptionCode> {
    if (this.isStatusNumeric) {
      return t(`errors.server.${this.getTokenByNumericStatus(this.response.status)}`);
    }
    return String(this.response.status);
  }

  get description(): Optional<ExceptionDescription> {
    if (this.isStatusNumeric) {
      return t(`errors.server.${this.getTokenByNumericStatus(this.response.status)}_desc`);
    }
    const statusText = this.response.statusText;
    return isNonEmptyString(statusText) ? statusText : null;
  }

  get meta(): Optional<ExceptionMeta> {
    return null;
  }

  get traceback(): Optional<ExceptionTraceback> {
    const stack = this.exception.stack;
    return isNonEmptyString(stack) ? stack : null;
  }

  private get isStatusNumeric() {
    return isNumber(this.response.status);
  }

  private getTokenByNumericStatus(status: number) {
    switch (status) {
      case HttpCodes.BadParam:
        return 'bad_request';
      case HttpCodes.NotFound:
        return 'notfound';
      case HttpCodes.Forbidden:
        return 'forbidden';
      case HttpCodes.ServerError:
      case HttpCodes.BadGateway:
        return 'internal';
      case HttpCodes.ServiceUnavailable:
        return 'unavailable';
      case HttpCodes.GatewayTimeout:
        return 'timeout';
    }

    if (status > HttpCodes.ServerError) {
      return 'internal';
    }
  }

  private get response(): T['response'] {
    return this.exception.response;
  }
}

export class RuntimeExceptionAdapter<T extends RuntimeException> implements Exception {
  constructor(private readonly exception: T) {}

  get code(): Optional<ExceptionCode> {
    const name = this.exception.name;
    return isNonEmptyString(name) ? name : null;
  }

  get description(): Optional<ExceptionDescription> {
    const message = this.exception.message;
    return isNonEmptyString(message) ? message : null;
  }

  get meta(): Optional<ExceptionMeta> {
    return null;
  }

  get traceback(): Optional<ExceptionTraceback> {
    const stack = this.exception.stack;
    return isNonEmptyString(stack) ? stack : null;
  }
}

export class ServiceExceptionAdapter<T extends ServiceException> implements Exception {
  constructor(private readonly exception: T) {}

  get code(): Optional<ExceptionCode> {
    const code = this.response.code;
    return isNonEmptyString(code) ? code : null;
  }

  get description(): Optional<ExceptionDescription> {
    const desc = this.response.desc;
    return isNonEmptyString(desc) ? desc : null;
  }

  get meta(): Optional<ExceptionMeta> {
    let meta: ExceptionMeta | null = null;

    const param = this.response.param;
    if (isNonEmptyString(param)) {
      Reflect.set(meta ?? (meta = {}), 'parameter', param);
    }

    const missing_permissions = this.response.missing_permissions;
    if (Array.isArray(missing_permissions)) {
      Reflect.set(meta ?? (meta = {}), 'missing_permissions', missing_permissions?.join());
    }

    return meta;
  }

  get traceback(): Optional<ExceptionTraceback> {
    const traceback = this.response.traceback;
    return isNonEmptyString(traceback) ? traceback : null;
  }

  private get response(): T['response']['data'] {
    return this.exception.response.data;
  }
}
