import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overlay-search" }
const _hoisted_2 = { class: "heading-3xl overlay-search__h1" }
const _hoisted_3 = { class: "heading-xl" }
const _hoisted_4 = { class: "label-m overlay-search__upload-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchFromSelect = _resolveComponent("SearchFromSelect")!
  const _component_NAttachments = _resolveComponent("NAttachments")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('search.define_search_source', 'f')), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('search.enter_search_objects', 'f')), 1),
    _createVNode(_component_SearchFromSelect, { "search-module": _ctx.searchModule }, null, 8, ["search-module"]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('search.upload_text')), 1),
    _createVNode(_component_NAttachments, {
      i18n: _ctx.attachmentsI18n,
      onAttach: _ctx.handleFileUpload,
      class: "video-upload-form__dnd",
      "disallow-attach": "",
      drag: "",
      "hide-amount": "",
      "hide-attachments": "",
      multiple: ""
    }, null, 8, ["i18n", "onAttach"])
  ]))
}