import { reactive } from 'vue';
import { CarModelsJsonService } from '@/api';

class CarModels {
  items: Array<string> = [];
  loaded = false;

  async get(): Promise<void> {
    if (this.loaded) return;
    await this.load();
    this.loaded = true;
  }

  private load(): Promise<void> {
    return CarModelsJsonService.carModelsJsonRetrieve().then((data: Record<string, Array<string>>) => {
      this.items = data.items;
    });
  }
}

export const carModels = reactive(new CarModels());
