import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video-upload-form" }
const _hoisted_2 = { class: "heading-2xl video-upload-form__header" }
const _hoisted_3 = { class: "video-upload-form__description control-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NAttachments = _resolveComponent("NAttachments")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('videos.file_urls', 'f')), 1),
    _createVNode(_component_NForm, {
      ref: "urlsForm",
      layout: _ctx.urlFormSchema,
      model: _ctx.urlsModel
    }, null, 8, ["layout", "model"]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('videos.file_select_description')), 1),
    (_openBlock(), _createBlock(_component_NAttachments, {
      i18n: _ctx.attachmentsI18n,
      key: 'drag-and-drop',
      class: "video-upload-form__dnd",
      "disallow-attach": "",
      drag: "",
      "hide-amount": "",
      multiple: "",
      attachments: _ctx.items,
      "onUpdate:attachments": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.items) = $event))
    }, null, 8, ["i18n", "attachments"])),
    _createVNode(_component_NButton, {
      class: "video-upload-form__next-button",
      size: "big",
      onClick: _ctx.clickHandler,
      disabled: !_ctx.isNextEnabled
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('videos.upload', 'f')), 1)
      ]),
      _: 1
    }, 8, ["onClick", "disabled"])
  ]))
}