import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "multisidebar-item__content" }
const _hoisted_2 = { class: "multisidebar-item__content-top" }
const _hoisted_3 = {
  key: 0,
  class: "multisidebar-item__changes-dot"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "multisidebar-item__labels" }
const _hoisted_6 = {
  key: 0,
  class: "multisidebar-item__extra-label label-2xs"
}
const _hoisted_7 = {
  key: 1,
  class: "multisidebar-item__label label-2xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['multisidebar-item', { 'multisidebar-item_selected': _ctx.selected }]),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
    ref: "item"
  }, [
    _createVNode(_component_NButton, {
      class: "multisidebar-item__close",
      icon: "close",
      "icon-size": "10",
      size: "small",
      round: "",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
      onAction: _ctx.remove
    }, null, 8, ["onAction"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.hasChanges)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("img", {
          class: _normalizeClass([`multisidebar-item__image multisidebar-item__image_${this.attributes.thumbnailType}`, { 'multisidebar-item__image_loading': this.loading }]),
          src: _ctx.thumbnailSrc,
          alt: _ctx.firstLetter
        }, null, 10, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.attributes.extraLabel)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.attributes.extraLabel), 1))
          : _createCommentVNode("", true),
        (_ctx.attributes.label)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.attributes.label), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}