/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ExternalVmsSendEventStatusFilter = {
            /**
            * Select send status entries from these camera
            */
        camera?: Array<number>;
            /**
            * Select objects with `created_date` **greater** than this value
            */
        created_date_gt?: string;
            /**
            * Select objects with `created_date` **greater than or equal** to this value
            */
        created_date_gte?: string;
            /**
            * Select objects with `created_date` **less** than this value
            */
        created_date_lt?: string;
            /**
            * Select objects with `created_date` **less than or equal** to this value
            */
        created_date_lte?: string;
            /**
            * Select only events with a sending error (`true`) or sent successfully (`false`) statuses
            */
        error?: boolean;
            /**
            * Select send status entries with this `event_id`
            */
        event_id?: string;
            /**
            * Select send status entries with this `event_type
            */
        event_type?: Array<'body' | 'car' | 'face'>;
            /**
            * Select send status entries from these external vms
            */
        external_vms?: Array<number>;
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
}
export const EmptyExternalVmsSendEventStatusFilter: ExternalVmsSendEventStatusFilter = {
        camera: [],
        created_date_gt: undefined,
        created_date_gte: undefined,
        created_date_lt: undefined,
        created_date_lte: undefined,
        error: undefined,
        event_id: undefined,
        event_type: [],
        external_vms: [],
        limit: '20',
        ordering: undefined,
        page: undefined,
}