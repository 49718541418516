import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "cluster-item__container" }
const _hoisted_2 = {
  key: 0,
  class: "cluster-item__license_plate_number label-mono-xs"
}
const _hoisted_3 = { class: "cluster-item__event" }
const _hoisted_4 = {
  key: 0,
  class: "cluster-item__confidence"
}
const _hoisted_5 = { class: "cluster-item__card-name" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "cluster-item__card-name_unknown"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_Confidence = _resolveComponent("Confidence")!
  const _component_WatchListLabelsShort = _resolveComponent("WatchListLabelsShort")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cluster-item", _ctx.clusterClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NThumbnail, {
        modelValue: _ctx.item.best_event.thumbnail,
        actions: _ctx.computedActions,
        defaultAction: _ctx.defaultAction,
        onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select'))),
        onAction: _cache[1] || (_cache[1] = (v) => _ctx.$emit('action', v))
      }, null, 8, ["modelValue", "actions", "defaultAction"]),
      (_ctx.item.license_plate_number)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.item.license_plate_number), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$tm('common.cluster_event_mf', { count: _ctx.item.events_count })), 1),
        (_ctx.objects === 'faces')
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 0,
              type: "link",
              icon: "relations",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('action', 'show-interactions', _ctx.item)))
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.item.match_confidence)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Confidence, {
            value: _ctx.item.match_confidence,
            objects: _ctx.objects
          }, null, 8, ["value", "objects"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["cluster-item__card", _ctx.cardClasses]),
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.selectCard && _ctx.selectCard(...args)))
    }, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.cardName)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.cardName), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('common.unknown', 'f')), 1))
      ]),
      (_ctx.item.matched_lists?.length)
        ? (_openBlock(), _createBlock(_component_WatchListLabelsShort, {
            key: 0,
            type: "short",
            items: _ctx.item.matched_lists
          }, null, 8, ["items"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}