import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video-wall-player" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoWallPlayerLoader = _resolveComponent("VideoWallPlayerLoader")!
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_VideoWallPlayerStatus = _resolveComponent("VideoWallPlayerStatus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isDefined(_ctx.camera))
      ? (_openBlock(), _createBlock(_component_VideoWallPlayerLoader, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_openBlock(), _createBlock(_component_VideoPlayer, {
            key: _ctx.camera.id,
            "camera-id": _ctx.camera.id,
            "show-bottom-bar": false,
            "show-controls": false,
            "show-timeline": false,
            "time-from": _ctx.getCurrentTimestampInSeconds(),
            class: "video-wall-player__player",
            "reconnect-on-close": "",
            "show-overlay": ""
          }, null, 8, ["camera-id", "time-from"])),
          _createVNode(_component_VideoWallPlayerStatus, {
            camera: _ctx.camera,
            class: "video-wall-player__status"
          }, null, 8, ["camera"])
        ], 64))
  ]))
}