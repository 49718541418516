
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { IDataSourceDevice } from '@/pages/data-sources/add-devices/DataSourcesDevices.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import NTable from '@/uikit/table/NTable.vue';
import { CameraRequest, CamerasService } from '@/api';
import NButton from '@/uikit/buttons/NButton.vue';
import { multisidebarModule } from '@/store/multisidebar';
import NForm, { IFormLayout } from '@/uikit/forms/NForm.vue';
import { formLayoutContent, formLayoutHeaders } from './device-form-schema';
import { globalEventModule } from '@/store/global-event';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';

@Options({
  name: 'DataSourcesDevicesNext',
  components: { NForm, NButton, NTable, NIcon }
})
export default class DataSourcesDevicesNext extends Vue {
  @Prop({ type: Array, required: true })
  readonly devices!: IDataSourceDevice[];

  get sidebar() {
    return multisidebarModule;
  }

  get deviceCount() {
    return this.devices.length;
  }

  get headers(): IFormLayout {
    return formLayoutHeaders;
  }
  get schema(): IFormLayout {
    return formLayoutContent;
  }

  isValid() {
    const { form } = this.$refs;
    const result = form.filter((item: any) => {
      !item.validate() && item.displayErrors();
      return item.validate();
    });

    return result.length === form.length;
  }

  async createCameras() {
    const requests = this.devices.map((cameraItem) => CamerasService.camerasCreate(cameraItem as CameraRequest));
    const cameras = await Promise.allSettled(requests);
    globalEventModule.sendCreate(MultisidebarItemTypes.Cameras);
    return cameras;
  }

  onlyCreateCameras() {
    if (this.isValid()) {
      this.createCameras().finally(() => {
        this.$emit('close');
      });
    }
  }

  createAndConfig() {
    if (this.isValid()) {
      this.createCameras()
        .then((res) => {
          const cameras = res.map((camera) => camera.status === 'fulfilled' && camera.value);
          this.sidebar.addItemsAndOpen('cameras', cameras);
        })
        .finally(() => {
          this.$emit('close');
        });
    }
  }
}
