import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  width: "100%",
  height: "100%",
  class: "n-bbox-distances"
}
const _hoisted_2 = ["x1", "y1", "x2", "y2"]
const _hoisted_3 = ["x", "y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.distances, ({ start_point, end_point, text_point, distance }, index) => {
      return (_openBlock(), _createElementBlock("g", { key: index }, [
        _createElementVNode("line", {
          x1: start_point.x * _ctx.scale,
          y1: start_point.y * _ctx.scale,
          x2: end_point.x * _ctx.scale,
          y2: end_point.y * _ctx.scale
        }, null, 8, _hoisted_2),
        _createElementVNode("text", {
          stroke: "black",
          "stroke-width": ".3",
          x: text_point.x * _ctx.scale,
          y: text_point.y * _ctx.scale,
          "font-family": "Verdana",
          "font-size": "16",
          fill: "white"
        }, _toDisplayString(distance), 9, _hoisted_3)
      ]))
    }), 128))
  ]))
}