import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video-wall-player-status" }
const _hoisted_2 = ["title", "textContent"]
const _hoisted_3 = ["title", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoWallCameraStatus = _resolveComponent("VideoWallCameraStatus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VideoWallCameraStatus, {
      status: _ctx.camera.health_status.code
    }, null, 8, ["status"]),
    _createElementVNode("div", {
      title: _ctx.camera.health_status.code_desc,
      class: "label-s video-wall-player-status__code",
      textContent: _toDisplayString(_ctx.camera.health_status.code_desc)
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      title: _ctx.camera.name,
      class: "label-s video-wall-player-status__name",
      textContent: _toDisplayString(_ctx.camera.name)
    }, null, 8, _hoisted_3)
  ]))
}