import _ from 'lodash';
import { createVideoStream, disposeVideoStream, recordVideo } from '../../components/common/media-devices-helpers';
import { authModule } from '@/store/auth';
import { IAuthConfig } from '@/definitions/config/auth';

const TokenRenewalAttempts = 1;
const TokenRenewalDurationInMs = 2e3;
const TokenRenewalVideoLengthInMs = 15e2;

export const TokenState = {
  Valid: 'Valid',
  Expired: 'Expired',
  ExpiresSoon: 'ExpiresSoon'
};

export const UserDirection = {
  In: 1,
  Out: 0
};

export function getUserDirection(id: number | undefined, previousId: number | undefined) {
  return id && !previousId ? UserDirection.In : UserDirection.Out;
}

export function detectTokenState(timestamp: number, expiresAt: number, intervalInMs: number, durationInMs: number) {
  const interval = expiresAt - timestamp;

  if (interval > intervalInMs + durationInMs) {
    return TokenState.Valid;
  }

  if (interval < durationInMs) {
    return TokenState.Expired;
  }

  return TokenState.ExpiresSoon;
}

export function recordTokenRenewalVideo() {
  return createAutoclosableVideoStream((stream: MediaStream) => {
    return recordVideo(stream, TokenRenewalVideoLengthInMs);
  });
}

export function computeSessionMonitorConfig(config: IAuthConfig) {
  const attempts = getTokenRenewalAttempts(config);
  const intervalInMs = getTokenRenewalIntervalInMs(config);
  const durationInMs = getTokenRenewalTotalDurationInMs(attempts);
  const expiresAt = getTokenExpirationTimestamp();
  const enabled = isTokenRenewalEnabled(config, intervalInMs);
  return { enabled, attempts, expiresAt, intervalInMs, durationInMs };
}

function isTokenRenewalEnabled(config: IAuthConfig, intervalInMs: number) {
  return intervalInMs > 0 && getValue(_.isBoolean, config.NEED_SESSION_RENEW, false);
}

function getTokenRenewalAttempts(config: IAuthConfig) {
  return getNumber(config.MAXIMUM_RENEW_ATTEMPTS, TokenRenewalAttempts);
}

function getTokenRenewalIntervalInMs(config: IAuthConfig) {
  return getNumber(config.RENEW_SESSION_INTERVAL, 0) * 1000;
}

function getTokenRenewalTotalDurationInMs(attempts: number) {
  return (TokenRenewalVideoLengthInMs + TokenRenewalDurationInMs) * attempts;
}

function getTokenExpirationTimestamp() {
  let date = new Date(authModule.token_expiration_datetime ?? Date());
  return date.valueOf();
}

function getNumber(candidate: any, defaultValue: number) {
  return Math.max(getValue(_.isNumber, candidate, defaultValue), defaultValue);
}

function getValue(predicate: (v: any) => boolean, candidate: any, defaultValue: any) {
  return predicate(candidate) ? candidate : defaultValue;
}

function createAutoclosableVideoStream(handler: (s: MediaStream) => Promise<Blob>) {
  let stream: null | MediaStream = null;
  return createVideoStream()
    .then((result) => handler((stream = result)))
    .finally(() => stream && disposeVideoStream(stream));
}
