import CalibrationForm from '@/components/counters/CalibrationForm.vue';
import CameraGroupMultiple from '@/components/counters/CameraGroupMultiple.vue';
import CameraMultiple from '@/components/counters/CameraMultiple.vue';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { IFormContext, IFormModel } from '@/uikit/forms/NForm.vue';
import { IFormLayoutBlocks } from '@/uikit/forms/NFormBlocks.vue';
import { getNumberValidator, getRequiredValidator, getStringLengthValidator } from '@/uikit/forms/validators';
import NInput from '@/uikit/input/NInput.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import { getItemCommonFields } from '@/components/common/get-item-common-fields';
import { NewItemIdStart } from '@/store/multisidebar';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';

const validateDetectors = {
  handler: function (context: IFormContext) {
    return context.model.detect_faces || context.model.detect_silhouettes || context.model.detect_cars;
  },
  i18n_message: 'ds.one_detector_must_be_on'
};

const formLayoutBlocks: IFormLayoutBlocks = [
  {
    layout: [
      {
        path: 'name',
        classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
        i18n_label: 'common.name',
        component: NInput,
        validators: [getRequiredValidator(), getStringLengthValidator({ required: true, min: 3, max: 100 })]
      },
      {
        path: 'comment',
        classes: 'n-form-w-6  control-m n-form-pad-10',
        i18n_label: 'common.description',
        component: NTextareaAutosize
      },
      {
        path: 'camera_groups',
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'common.camera_groups',
        component: CameraGroupMultiple,
        props: {
          i18n_placeholder: 'common.select_camera_group'
        },
        validators: [getRequiredValidator()]
      },
      {
        path: 'cameras',
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'common.cameras',
        component: CameraMultiple,
        props: (item: any) => ({ i18n_placeholder: 'common.select_camera', cameraGroups: item.camera_groups }),
        validators: [getRequiredValidator()]
      },
      {
        classes: 'n-form-w-6  n-form-pad-10',
        component: NTextDelimiter,
        props: { i18n_label: 'common.detectors' }
      },
      [
        {
          name: 'face',
          classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
          component: NSwitch,
          props: { i18n_label: 'common.faces' },
          encode: function (this: IFormContext, model: IFormModel, value: boolean) {
            this.model.detect_faces = value;
          },
          decode: function (this: IFormContext) {
            return !!this.model.detect_faces;
          },
          validators: [validateDetectors],
          hidden: () => !dataAssetsModule.isObjectAvailable('face')
        },
        {
          name: 'body',
          classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
          component: NSwitch,
          props: { i18n_label: 'common.bodies' },
          encode: function (this: IFormContext, model: IFormModel, value: boolean) {
            this.model.detect_silhouettes = value;
          },
          decode: function (this: IFormContext) {
            return !!this.model.detect_silhouettes;
          },
          hidden: () => !dataAssetsModule.isObjectAvailable('body')
        },
        {
          name: 'car',
          classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
          component: NSwitch,
          props: { i18n_label: 'common.cars' },
          encode: function (this: IFormContext, model: IFormModel, value: boolean) {
            this.model.detect_cars = value;
          },
          decode: function (this: IFormContext) {
            return !!this.model.detect_cars;
          },
          hidden: () => !dataAssetsModule.isObjectAvailable('car')
        }
      ],
      {
        path: 'count_interval',
        classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
        i18n_label: 'counters.count_within_interval_seconds',
        component: NInput,
        validators: [getNumberValidator({ required: true, min: 1, max: 1000 })]
      }
    ]
  },
  {
    layout: [
      {
        path: 'detect_proximity',
        classes: 'label-m n-form-w-4 n-form-vcenter n-form-pad-10',
        component: NCheckbox,
        props: { i18n_label: 'counters.distance_measurement' }
      },
      {
        classes: 'n-form-w-6  n-form-pad-10',
        component: NTextDelimiter,
        props: { i18n_label: 'common.status' }
      },
      {
        classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
        component: CalibrationForm,
        props: (item: any) => ({ id: item.id, status: item.proximity_calibration_status })
      }
    ],
    display: (model: any, state: any) => {
      return dataAssetsModule.isObjectAvailable('body') && model.detect_silhouettes && state.created;
    }
  },
  {
    layout: getItemCommonFields(),
    display: (model: any) => {
      return model.id > NewItemIdStart;
    }
  }
];

export default formLayoutBlocks;
