export default function getCssTokenValue(name: string) {
  const id = '__get-css-var-helper';
  let element = document.getElementById(id);
  if (!element) {
    element = document.createElement('div');
    element.id = id;
    document.body.appendChild(element);
  }
  element.style.color = 'var(' + name + ')';
  const style = window.getComputedStyle(element);
  return style.getPropertyValue('color');
}
