/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { ClusterLooksLike } from './ClusterLooksLike';
export type CarCluster = {
    readonly id: number;
    /**
     * Cluster tnt-id
     */
    readonly tnt_id: number;
    /**
     * Cluster creation date
     */
    readonly created_date: string;
    /**
     * Cluster events count
     */
    readonly events_count: number;
    /**
     * Average cluster events quality
     */
    readonly events_quality: number;
    /**
     * Cluster's cameras list
     */
    readonly cameras: Array<number>;
    /**
     * Cluster's camera groups list
     */
    readonly camera_groups: Array<number>;
    /**
     * Best cluster event
     */
    readonly best_event: number;
    /**
     * Best cluster event quality
     */
    readonly best_event_quality: number;
    /**
     * Related card
     */
    readonly card: number;
    /**
     * Card match confidence
     */
    readonly match_confidence: number;
    /**
     * Matched watch lists
     */
    readonly matched_lists: Array<number>;
    /**
     * Whether cluster is pinned or not
     */
    pinned?: boolean;
    readonly looks_like: ClusterLooksLike | null;
    readonly looks_like_confidence: number;
    /**
     * Related case
     */
    readonly case: number;
    /**
     * Car cluster color feature
     */
    readonly color: string;
    /**
     * Car cluster body feature
     */
    readonly body: string;
    /**
     * Car cluster glasses feature
     */
    readonly make: string;
    /**
     * Car cluster emotions feature
     */
    readonly model: string;
    /**
     * Car cluster special_vehicle_type feature
     */
    readonly special_vehicle_type: string;
    /**
     * Related car license plate
     */
    readonly license_plate_number: string;
};
export const EmptyCarCluster: CarCluster = {
    id: 0,
    /**
     * Cluster tnt-id
     */
    tnt_id: 0,
    /**
     * Cluster creation date
     */
    created_date: '',
    /**
     * Cluster events count
     */
    events_count: 0,
    /**
     * Average cluster events quality
     */
    events_quality: 0,
    /**
     * Cluster's cameras list
     */
    cameras: [],
    /**
     * Cluster's camera groups list
     */
    camera_groups: [],
    /**
     * Best cluster event
     */
    best_event: 0,
    /**
     * Best cluster event quality
     */
    best_event_quality: 0,
    /**
     * Related card
     */
    card: 0,
    /**
     * Card match confidence
     */
    match_confidence: 0,
    /**
     * Matched watch lists
     */
    matched_lists: [],
    /**
     * Whether cluster is pinned or not
     */
    pinned: false,
    looks_like: null,
    looks_like_confidence: 0,
    /**
     * Related case
     */
    case: 0,
    /**
     * Car cluster color feature
     */
    color: '',
    /**
     * Car cluster body feature
     */
    body: '',
    /**
     * Car cluster glasses feature
     */
    make: '',
    /**
     * Car cluster emotions feature
     */
    model: '',
    /**
     * Car cluster special_vehicle_type feature
     */
    special_vehicle_type: '',
    /**
     * Related car license plate
     */
    license_plate_number: '',
};
