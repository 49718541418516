import AuditLogsPage from '@/pages/audit-logs/AuditLogsPage.vue';
import BlocklistPage from '@/pages/blocklist/BlocklistPage.vue';
import CameraGroupsPage from '@/pages/camera-groups/CameraGroupsPage.vue';
import CardsPage from '@/pages/cards/CardsPage.vue';
import CasesPage from '@/pages/cases/CasesPage.vue';
import EditCasePage from '@/pages/cases/EditCasePage.vue';
import ClustersPage from '@/pages/clusters/ClustersPage.vue';
import CountersPage from '@/pages/counters/CountersPage.vue';
import DataSourcesPage from '@/pages/data-sources/DataSourcesPage.vue';
import EmptyPage from '@/pages/empty/EmptyPage.vue';
import EventsPage from '@/pages/events/EventsPage.vue';
import ExternalSearchPage from '@/pages/external-search/ExternalSearchPage.vue';
import ExternalVmsPage from '@/pages/external-vms/ExternalVmsPage.vue';
import InteractionsPage from '@/pages/interactions/InteractionsPage.vue';
import KycPage from '@/pages/kyc/KycPage.vue';
import { LicensePage } from '@/pages/license';
import RemoteMonitoringEventsPage from '@/pages/remote-monitoring/RemoteMonitoringEventsPage.vue';
import { ReportsPage } from '@/pages/reports';
import SearchPage from '@/pages/search/SearchPage.vue';
import SessionsPage from '@/pages/sessions/SessionsPage.vue';
import GroupsPage from '@/pages/groups/GroupsPage.vue';
import UsersPage from '@/pages/users/UsersPage.vue';
import { VerifyPage } from '@/pages/verify';
import { VideoWallPage } from '@/pages/video-wall';
import WatchListsPage from '@/pages/watch-lists/WatchListsPage.vue';
import WebhooksPage from '@/pages/webhooks/WebhooksPage.vue';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { Routes } from './enums';
import PageNotFound from '@/pages/PageNotFound.vue';
import InterfaceSettingsPage from '@/pages/interface-settings/InterfaceSettingsPage.vue';
import { PagePaths, PageRoutes } from '@/store/application/page.definitions';
import LinesPage from '@/pages/lines/LinesPage.vue';

const routes: Array<RouteRecordRaw> = [
  Routes.Main,
  Routes.Login,
  Routes.Settings,
  {
    name: PageRoutes.Cards,
    path: PagePaths.Cards,
    component: CardsPage
  },
  {
    name: PageRoutes.Events,
    path: PagePaths.Events,
    component: EventsPage
  },
  {
    name: PageRoutes.DataSources,
    path: PagePaths.DataSources,
    component: DataSourcesPage
  },
  {
    name: PageRoutes.Roles,
    path: PagePaths.Roles,
    component: GroupsPage
  },
  {
    name: PageRoutes.Users,
    path: PagePaths.Users,
    component: UsersPage
  },
  {
    name: PageRoutes.Sessions,
    path: PagePaths.Sessions,
    component: SessionsPage
  },
  {
    name: PageRoutes.BlockList,
    path: PagePaths.BlockList,
    component: BlocklistPage
  },
  {
    name: PageRoutes.WatchLists,
    path: PagePaths.WatchLists,
    component: WatchListsPage
  },
  {
    name: PageRoutes.CameraGroups,
    path: PagePaths.CameraGroups,
    component: CameraGroupsPage
  },
  {
    name: PageRoutes.Cases,
    path: PagePaths.Cases,
    component: CasesPage
  },
  {
    name: PageRoutes.Case,
    path: PagePaths.Case,
    component: EditCasePage
  },
  {
    name: PageRoutes.Clusters,
    path: PagePaths.Clusters,
    component: ClustersPage
  },
  {
    name: PageRoutes.Counters,
    path: PagePaths.Counters,
    component: CountersPage
  },
  {
    name: PageRoutes.Search,
    path: PagePaths.Search,
    component: SearchPage
  },
  {
    name: PageRoutes.ExternalSearch,
    path: PagePaths.ExternalSearch,
    component: ExternalSearchPage
  },
  {
    name: PageRoutes.RemoteMonitoringEvents,
    path: PagePaths.RemoteMonitoringEvents,
    component: RemoteMonitoringEventsPage
  },
  {
    name: PageRoutes.Areas,
    path: PagePaths.Areas,
    component: EmptyPage
  },
  {
    name: PageRoutes.VideoWall,
    path: PagePaths.VideoWall,
    component: VideoWallPage
  },
  {
    name: PageRoutes.Analytics,
    path: PagePaths.Analytics,
    component: KycPage
  },
  {
    name: PageRoutes.Verify,
    path: PagePaths.Verify,
    component: VerifyPage
  },
  {
    name: PageRoutes.License,
    path: PagePaths.License,
    component: LicensePage
  },
  {
    name: PageRoutes.AuditLogs,
    path: PagePaths.AuditLogs,
    component: AuditLogsPage
  },
  {
    name: PageRoutes.Reports,
    path: PagePaths.Reports,
    component: ReportsPage
  },
  {
    name: PageRoutes.ExternalVms,
    path: PagePaths.ExternalVms,
    component: ExternalVmsPage
  },
  {
    name: PageRoutes.Hooks,
    path: PagePaths.Hooks,
    component: WebhooksPage
  },
  {
    name: PageRoutes.Interactions,
    path: PagePaths.Interactions,
    component: InteractionsPage
  },
  {
    name: PageRoutes.Interface,
    path: PagePaths.Interface,
    component: InterfaceSettingsPage
  },
  {
    name: PageRoutes.Lines,
    path: PagePaths.Lines,
    component: LinesPage
  },
  {
    name: PageRoutes.NotFound,
    path: '/:pathMatch(.*)*',
    component: PageNotFound
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
