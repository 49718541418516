/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AreasFilter = {
    /**
     * Get areas associated with cameras from `camera_group`.
     */
    camera_groups?: Array<number>;
    /**
     * Get areas associated with `camera`. Pass comma separated values to select areas related to multiple `camera`s
     */
    cameras?: Array<number>;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select only enabled (`true`) or disabled (`false`) areas
     */
    enabled?: boolean;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select areas related to multiple cameras
     */
    multi_camera?: boolean;
    /**
     * Select counters where the SQL condition `name ILIKE name_contains` is true
     */
    name_contains?: string;
    /**
     * Which field to use when ordering the results. Available fields: `id`, `created_date`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
}
export const EmptyAreasFilter: AreasFilter = {
    camera_groups: [],
    cameras: [],
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    enabled: undefined,
    limit: '20',
    multi_camera: undefined,
    name_contains: undefined,
    ordering: undefined,
    page: undefined,
}