
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { dataModule } from '@/store/data';
import NSelect from '@/uikit/select/NSelect.vue';

@Options({
  name: 'CameraGroupMultiple',
  components: { NSelect }
})
export default class CameraGroupMultiple extends Vue {
  @Prop({ type: Array, required: true })
  readonly modelValue!: number[];

  @Prop({ type: Boolean, default: false })
  readonly accent?: boolean;

  @Prop({ type: Boolean, default: false })
  readonly readonly?: boolean;

  get items() {
    return dataModule.cameraGroupsWithoutVideoGroups.map((item) => ({
      label: item.name,
      value: item.id
    }));
  }

  updateValue(value: number) {
    this.$emit('update:modelValue', value);
  }

  get name() {
    const dataItems = dataModule.cameraGroupsModule.items;
    const names = dataItems.filter((item) => this.modelValue.includes(item.id)).map((item) => item.name);
    if (names.length) return names.join(', ');
    return '[cameraGroups:not found]';
  }
}
