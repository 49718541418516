/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Area } from '../models/Area';
import type { AreaRequest } from '../models/AreaRequest';
import type { CountResponse } from '../models/CountResponse';
import type { PaginatedAreaList } from '../models/PaginatedAreaList';
import type { PatchedAreaRequest } from '../models/PatchedAreaRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class AreasService {
    /**
     * List areas
     * This method retrieves the list of areas.
     * @param cameraGroups Get areas associated with cameras from `camera_group`.
     * @param cameras Get areas associated with `camera`. Pass comma separated values to select areas related to multiple `camera`s
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param enabled Select only enabled (`true`) or disabled (`false`) areas
     * @param limit Number of results to return per page.
     * @param multiCamera Select areas related to multiple cameras
     * @param nameContains Select counters where the SQL condition `name ILIKE name_contains` is true
     * @param ordering Which field to use when ordering the results. Available fields: `id`, `created_date`
     * @param page Pagination cursor value.
     * @returns PaginatedAreaList
     * @throws ApiError
     */
    public static areasList(
        cameraGroups?: Array<number>,
        cameras?: Array<number>,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        enabled?: boolean,
        limit?: string,
        multiCamera?: boolean,
        nameContains?: string,
        ordering?: string,
        page?: string,
    ): CancelablePromise<PaginatedAreaList> {
        return __request({
            method: 'GET',
            path: `/areas/`,
            query: {
                'camera_groups': cameraGroups,
                'cameras': cameras,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'enabled': enabled,
                'limit': limit,
                'multi_camera': multiCamera,
                'name_contains': nameContains,
                'ordering': ordering,
                'page': page,
            },
        });
    }
    /**
     * Add new area
     * This method adds a new area.
     * @param requestBody
     * @returns Area
     * @throws ApiError
     */
    public static areasCreate(
        requestBody: AreaRequest,
    ): CancelablePromise<Area> {
        return __request({
            method: 'POST',
            path: `/areas/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve area by ID
     * This method retrieves an area by its ID.
     * @param id A unique integer value identifying this Area.
     * @returns Area
     * @throws ApiError
     */
    public static areasRetrieve(
        id: number,
    ): CancelablePromise<Area> {
        return __request({
            method: 'GET',
            path: `/areas/${id}/`,
        });
    }
    /**
     * Update area properties
     * Use this method to update an area properties.
     * @param id A unique integer value identifying this Area.
     * @param requestBody
     * @returns Area
     * @throws ApiError
     */
    public static areasPartialUpdate(
        id: number,
        requestBody?: PatchedAreaRequest,
    ): CancelablePromise<Area> {
        return __request({
            method: 'PATCH',
            path: `/areas/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete area
     * Use this method to delete an area.
     * @param id A unique integer value identifying this Area.
     * @returns void
     * @throws ApiError
     */
    public static areasDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/areas/${id}/`,
        });
    }
    /**
     * Objects count
     * This method retrieves count of objects.
     * @param cameraGroups Get areas associated with cameras from `camera_group`.
     * @param cameras Get areas associated with `camera`. Pass comma separated values to select areas related to multiple `camera`s
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param enabled Select only enabled (`true`) or disabled (`false`) areas
     * @param multiCamera Select areas related to multiple cameras
     * @param nameContains Select counters where the SQL condition `name ILIKE name_contains` is true
     * @returns CountResponse
     * @throws ApiError
     */
    public static areasCountRetrieve(
        cameraGroups?: Array<number>,
        cameras?: Array<number>,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        enabled?: boolean,
        multiCamera?: boolean,
        nameContains?: string,
    ): CancelablePromise<CountResponse> {
        return __request({
            method: 'GET',
            path: `/areas/count/`,
            query: {
                'camera_groups': cameraGroups,
                'cameras': cameras,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'enabled': enabled,
                'multi_camera': multiCamera,
                'name_contains': nameContains,
            },
        });
    }
}