
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export enum SessionsItemTypes {
  Link = 'link',
  Short = 'short',
  Common = 'common', // todo: implement
  Small = 'small'
}

@Options({
  name: 'SessionsItem'
})
export default class SessionsItem extends Vue {
  @Prop({ type: String, required: true })
  color!: string;

  @Prop({ type: String, required: true })
  name!: string;

  @Prop({ type: String, default: SessionsItemTypes.Short })
  type!: SessionsItemTypes;

  get classes() {
    return {
      [`sessions-item_${this.type}`]: true,
      'control-m': this.type === SessionsItemTypes.Link,
      'label-m': this.type === SessionsItemTypes.Common,
      'label-xs': this.type === SessionsItemTypes.Short,
      'label-mono-2xs': this.type === SessionsItemTypes.Small
    };
  }
}
