export default {
  d: 'д',
  h: 'ч',
  m: 'м',
  s: 'с',
  second: '{ count, plural, one {# секунда} few {# секунды} other {# секунд}}',
  minute: '{ count, plural, one {# минута} few {# минуты} other {# минут}}',
  hour: '{ count, plural, one {# час} few {# часа} other {# часов}}',
  day: '{ count, plural, one {# день} few {# дня} other {# дней}}',
  month: '{ count, plural, one {# месяц} few {# месяца} other {# месяцев}}',
  year: '{ count, plural, one {# год} few {# года} other {# лет}}',
  mon: 'пн',
  tue: 'вт',
  wed: 'ср',
  thu: 'чт',
  fri: 'пт',
  sat: 'сб',
  sun: 'вс',
  monday: 'понедельник',
  tuesday: 'вторник',
  wednesday: 'среда',
  thursday: 'четверг',
  friday: 'пятница',
  saturday: 'суббота',
  sunday: 'воскресенье'
};
