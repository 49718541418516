import { EventBinding } from './types';

export class EventManager {
  protected store: EventBinding[] = [];

  public bind(
    target: Element,
    eventName: string,
    callback: (...args: any[]) => void,
    options?: boolean | AddEventListenerOptions,
    context?: any
  ): EventBinding {
    let binding = {
      target,
      eventName,
      callback: context ? callback.bind(context) : callback,
      options
    };
    target.addEventListener(eventName, binding.callback, options);
    this.store.push(binding);

    return binding;
  }

  public unbind(binding: EventBinding) {
    binding.target.removeEventListener(binding.eventName, binding.callback, binding.options);
  }

  public unbindAll() {
    this.store.forEach((binding) => {
      this.unbind(binding);
    });
  }
}
