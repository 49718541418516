import { FormValidatorNames, IFormContext, IFormLayout } from '@/uikit/forms/NForm.vue';
import NText from '@/uikit/text/NText.vue';
import { NInput } from '@/uikit';
import CameraGroupName from '@/components/data-source/CameraGroupName.vue';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { cameraResetModule } from '@/store/camera/camera.reset.module';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { getUrlValidator } from '@/uikit/forms/validators';

export const formLayoutHeaders: IFormLayout = [
  [
    {
      classes: 'n-form-pad-10',
      component: NText,
      props: {
        i18n_modelValue: 'common.name'
      }
    },
    {
      classes: 'n-form-pad-10',
      component: NText,
      props: {
        i18n_modelValue: 'common.camera_group'
      }
    },
    {
      classes: 'n-form-pad-10',
      component: NText,
      props: {
        i18n_modelValue: 'common.url'
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NText,
      props: {
        i18n_modelValue: 'common.faces',
        overflowType: 'none'
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('face');
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NText,
      props: {
        i18n_modelValue: 'common.bodies',
        overflowType: 'none'
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('body');
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NText,
      props: {
        i18n_modelValue: 'common.cars',
        overflowType: 'none'
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('car');
      }
    }
  ]
];
export const formLayoutContent: IFormLayout = [
  [
    {
      classes: 'n-form-pad-10',
      path: 'name',
      component: NInput,
      validators: [{ name: FormValidatorNames.Required }]
    },
    {
      classes: 'n-form-pad-10',
      path: 'group',
      component: CameraGroupName,
      validators: [{ name: FormValidatorNames.Required }]
    },
    {
      classes: ' n-form-pad-10',
      path: 'url',
      component: NInput,
      validators: [getUrlValidator(), { name: FormValidatorNames.Required }]
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NCheckbox,
      encode: function (this: IFormContext) {
        cameraResetModule.toggleDetector(this.model, 'face');
      },
      decode: function (this: IFormContext, model) {
        return !!this.model.stream_settings.detectors?.face;
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('face');
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NCheckbox,
      encode: function (this: any) {
        cameraResetModule.toggleDetector(this.model, 'body');
      },
      decode: function (this: any) {
        return !!this.model.stream_settings.detectors?.body;
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('body');
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NCheckbox,
      encode: function (this: any) {
        cameraResetModule.toggleDetector(this.model, 'car');
      },
      decode: function (this: any) {
        return !!this.model.stream_settings.detectors?.car;
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('car');
      }
    }
  ]
];
