/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Upload = {
    readonly id: number;
    /**
     * Object creation date
     */
    readonly created_date: string;
    /**
     * Object source photo up to 2048 characters
     */
    readonly source_photo: string;
    /**
     * Related human card id
     */
    readonly human_card_id: number;
    /**
     * Related car card id
     */
    readonly car_card_id: number;
    /**
     * Object response code
     */
    readonly resp_code: number;
    /**
     * Object error description up to 2048 characters
     */
    readonly error_desc: string;
    /**
     * Related upload list id
     */
    readonly upload_list: number;
};
export const EmptyUpload: Upload = {
    id: 0,
        /**
        * Object creation date
        */
    created_date: '',
        /**
        * Object source photo up to 2048 characters
        */
    source_photo: '',
        /**
        * Related human card id
        */
    human_card_id: 0,
        /**
        * Related car card id
        */
    car_card_id: 0,
        /**
        * Object response code
        */
    resp_code: 0,
        /**
        * Object error description up to 2048 characters
        */
    error_desc: '',
        /**
        * Related upload list id
        */
    upload_list: 0,
};