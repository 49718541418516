
import { defineComponent } from 'vue';
import { func } from 'vue-types';
import { LicensePageMetaItemCustomContent } from '../types';

export default defineComponent({
  name: 'LicensePageMetaContentCustom',
  props: {
    content: func<LicensePageMetaItemCustomContent['content']>().isRequired
  }
});
