import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsBar = _resolveComponent("SettingsBar")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_EntityBlank = _resolveComponent("EntityBlank")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_SettingsBar, {
        "create-label-i18-n": "webhooks.create_hooks",
        "total-count": _ctx.items.length,
        "has-create": _ctx.modelAcl.add,
        onSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchQuery = $event)),
        onCreate: _ctx.handleCreate
      }, null, 8, ["total-count", "has-create", "onCreate"]),
      (_ctx.module.items.length > 0 || _ctx.loading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_NLoadingCircle, { key: 0 }))
              : (_openBlock(), _createBlock(_component_NTable, {
                  key: 1,
                  columns: _ctx.columns,
                  content: _ctx.items,
                  "selected-items-ids": _ctx.selectedItemIds,
                  disabled: !_ctx.modelAcl.update,
                  "onSelect:changes": _ctx.handleSelectChanges,
                  onAction: _ctx.actionHandler,
                  onSort: _ctx.handleTableSort
                }, null, 8, ["columns", "content", "selected-items-ids", "disabled", "onSelect:changes", "onAction", "onSort"]))
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_EntityBlank, {
              "page-prefix": "webhooks",
              "page-type": _ctx.state.pageType,
              "is-empty": !_ctx.searchQuery.length,
              "has-create": _ctx.modelAcl.add,
              "create-icon": "webhook",
              onCreate: _ctx.handleCreate
            }, null, 8, ["page-type", "is-empty", "has-create", "onCreate"])
          ]))
    ]),
    _: 1
  }))
}