
import { isDefined } from '@/common/utils';
import { defineComponent } from 'vue';
import { object } from 'vue-types';
import { LicenseUsage } from '../../../types';
import { LicensePageMeta } from '../../meta';
import { formatLicenseUsageInterval } from './formatters/intervals';
import LicensePageTabViewIntervalsLayout from './LicensePageTabViewIntervalsLayout.vue';
import LicensePageTabViewIntervalsList from './LicensePageTabViewIntervalsList.vue';
import { LicensePageTabViewIntervalsListSection } from './types';

type LicensePageTabViewIntervalsState = {
  intervalIndex: number;
};

export default defineComponent({
  name: 'LicensePageTabViewIntervals',
  components: {
    LicensePageMeta,
    LicensePageTabViewIntervalsLayout,
    LicensePageTabViewIntervalsList
  },
  props: {
    licenseUsage: object<LicenseUsage>().isRequired
  },
  data: (): LicensePageTabViewIntervalsState => ({ intervalIndex: 0 }),
  computed: {
    items() {
      return formatLicenseUsageInterval(this.interval);
    },
    interval() {
      return this.intervals[this.intervalIndex];
    },
    intervals() {
      return this.sections.map(({ intervals }) => intervals).flat();
    },
    sections(): LicensePageTabViewIntervalsListSection[] {
      return [
        this.licenseUsage.active_limits && {
          header: this.$t('license.current'),
          intervals: [this.licenseUsage.active_limits]
        },
        { header: this.$t('license.all'), intervals: this.licenseUsage.by_interval }
      ].filter(isDefined);
    }
  }
});
