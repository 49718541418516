
import { defineComponent, PropType } from 'vue';
import { MenuItem } from '@/store/menu/menu';

export default defineComponent({
  name: 'SidebarMenu',
  props: {
    menuItems: {
      type: Array as PropType<MenuItem[]>,
      required: true
    }
  }
});
