
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  emits: ['dragend', 'dragstart', 'drop']
})
export default class VideoWallArea extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly draggable!: boolean;
  @Prop({ required: true, type: Number })
  readonly index!: number;

  enter: boolean = false;

  dragend(event: DragEvent): void {
    this.$emit('dragend', event, this.index);
  }

  dragenter(): void {
    this.enter = !this.enter;
  }

  dragleave(): void {
    this.enter = !this.enter;
  }

  dragstart(event: DragEvent): void {
    this.$emit('dragstart', event, this.index);
  }

  drop(event: DragEvent): void {
    this.$emit('drop', event, this.index);
    this.enter = !this.enter;
  }
}
