/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ClustersFacesContactsTracksFilter = {
            /**
            * Select clusters where feature `age` is **greater than or equal** than this value
            */
        age_gte?: number;
            /**
            * Select clusters where feature `age` is **lower than or equal** than this value
            */
        age_lte?: number;
            /**
            * Select clusters where `beard` feature takes one of these values
            */
        beard?: Array<'beard' | 'none' | null>;
            /**
            * Select clusters associated with the specified camera groups
            */
        camera_groups?: Array<number>;
            /**
            * Select clusters associated with the specified cameras
            */
        cameras?: Array<number>;
            /**
            * Select clusters that matched card
            */
        card?: number;
            /**
            * Select clusters related to these cases
            */
        case_in?: Array<number | null>;
            /**
            * Select objects with `created_date` **greater** than this value
            */
        created_date_gt?: string;
            /**
            * Select objects with `created_date` **greater than or equal** to this value
            */
        created_date_gte?: string;
            /**
            * Select objects with `created_date` in last N days
            */
        created_date_last_n_days?: number;
            /**
            * Select objects with `created_date` **less** than this value
            */
        created_date_lt?: string;
            /**
            * Select objects with `created_date` **less than or equal** to this value
            */
        created_date_lte?: string;
            /**
            * Select objects with `created_date` in last Nth week (including Sunday and Saturday)
            */
        created_date_nth_full_week?: number;
            /**
            * Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
            */
        created_date_nth_work_week?: number;
            /**
            * Select clusters where `emotions` feature takes one of these values
            */
        emotions?: Array<'angry' | 'disgust' | 'fear' | 'happy' | 'neutral' | 'sad' | 'surprise' | null>;
            /**
            * Select objects related to `episode` Pass comma separated values to select related to multiple `episode`s)
            */
        episodes?: Array<number>;
            /**
            * Select objects with `any` event date **greater** than this value
            */
        event_date_gt?: string;
            /**
            * Select objects with `any` event date **greater than or equal** to this value
            */
        event_date_gte?: string;
            /**
            * Select objects with `any` event date **less** than this value
            */
        event_date_lt?: string;
            /**
            * Select objects with `any` event date **less than or equal** to this value
            */
        event_date_lte?: string;
            /**
            * Select clusters where cluster events count is **greater or equal than or equal** to this value
            */
        events_count_gte?: number;
            /**
            * Select clusters where cluster events count is **less or equal than or equal** to this value
            */
        events_count_lte?: number;
            /**
            * Select clusters where average cluster event quality is **greater or equal than or equal** to this value
            */
        events_quality_gte?: number;
            /**
            * Select clusters where average cluster event quality is **less or equal than or equal** to this value
            */
        events_quality_lte?: number;
            /**
            * Select objects with `first` event date **greater** than this value
            */
        first_event_date_gt?: string;
            /**
            * Select objects with `first` event date **greater than or equal** to this value
            */
        first_event_date_gte?: string;
            /**
            * Select objects with `first` event date **less** than this value
            */
        first_event_date_lt?: string;
            /**
            * Select objects with `first` event date **less than or equal** to this value
            */
        first_event_date_lte?: string;
            /**
            * Select clusters gender `gender` feature takes one of these values
            */
        gender?: Array<'female' | 'male' | null>;
            /**
            * Select clusters where `glasses` feature takes one of these values
            */
        glasses?: Array<'eye' | 'none' | 'sun' | null>;
            /**
            * `true` - select only clusters with a card. `false` - select only clusters without a card.
            */
        has_card?: boolean;
            /**
            * `true` - select only clusters relates to case. `false` - select only clusters without case.
            */
        has_case?: boolean;
            /**
            * `true` - select only clusters with related caseparticipant. `false` - select only clusters without related caseparticipant.
            */
        has_caseparticipants?: boolean;
            /**
            * Select clusters with provided ids
            */
        id_in?: Array<number>;
            /**
            * Parameter that determines track intersection threshold in seconds.
            */
        intersection_threshold?: number;
        limit: number;
            /**
            * Select clusters where `liveness` feature takes one of these values
            */
        liveness?: Array<'fake' | 'real' | null>;
            /**
            * Select clusters where `look` feature takes one of these values
            */
        look?: Array<string | null>;
            /**
            * Select clusters that contain object similar to: (`detection:<detection id>`, `{face, body or car}event:<event id>`, `{face, body or car}object:<object id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`)
            */
        looks_like?: string;
            /**
            * Select clusters that matched a card included in these watch lists
            */
        matched_lists?: Array<number>;
            /**
            * Select clusters where `medmask` feature takes one of these values
            */
        medmask?: Array<'correct' | 'incorrect' | 'none' | null>;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`, `looks_like_confidence`
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
            /**
            * Select only pinned clusters
            */
        pinned?: boolean;
            /**
            * Similarity threshold for `looks_like`
            */
        threshold?: number;
            /**
            * Select clusters with provided tnt ids
            */
        tnt_id_in?: Array<number | null>;
}
export const EmptyClustersFacesContactsTracksFilter: ClustersFacesContactsTracksFilter = {
        age_gte: undefined,
        age_lte: undefined,
        beard: [],
        camera_groups: [],
        cameras: [],
        card: undefined,
        case_in: [],
        created_date_gt: undefined,
        created_date_gte: undefined,
        created_date_last_n_days: undefined,
        created_date_lt: undefined,
        created_date_lte: undefined,
        created_date_nth_full_week: undefined,
        created_date_nth_work_week: undefined,
        emotions: [],
        episodes: [],
        event_date_gt: undefined,
        event_date_gte: undefined,
        event_date_lt: undefined,
        event_date_lte: undefined,
        events_count_gte: undefined,
        events_count_lte: undefined,
        events_quality_gte: undefined,
        events_quality_lte: undefined,
        first_event_date_gt: undefined,
        first_event_date_gte: undefined,
        first_event_date_lt: undefined,
        first_event_date_lte: undefined,
        gender: [],
        glasses: [],
        has_card: undefined,
        has_case: undefined,
        has_caseparticipants: undefined,
        id_in: [],
        intersection_threshold: undefined,
        limit: 20,
        liveness: [],
        look: [],
        looks_like: undefined,
        matched_lists: [],
        medmask: [],
        ordering: undefined,
        page: undefined,
        pinned: undefined,
        threshold: undefined,
        tnt_id_in: [],
}