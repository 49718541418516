
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';

const StartPageIndex = 1;
const MinPagesAmount = 1;

@Options({
  name: 'ChartPager',
  components: { NButton },
  emits: ['change']
})
export default class ChartPager extends Vue {
  @Prop({ type: Number, required: true })
  readonly pageSize!: number;

  @Prop({ type: Number, required: true })
  readonly total!: number;

  public page = StartPageIndex;

  get isPrevPageButtonDisabled() {
    return this.page === StartPageIndex;
  }

  get isNextPageButtonDisabled() {
    return this.page === this.pages;
  }

  get pages() {
    return Math.ceil(this.total / this.pageSize) || MinPagesAmount;
  }

  @Watch('pages')
  handlePagesAmountChange() {
    this.page = StartPageIndex;
  }

  @Watch('page', { immediate: true })
  handlePageChange() {
    const begin = (this.page - 1) * this.pageSize;
    this.$emit('change', [begin, begin + this.pageSize]);
  }

  gotoPrevPage() {
    this.page--;
  }

  gotoNextPage() {
    this.page++;
  }
}
