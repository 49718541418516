
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import CardConfidence from '@/components/cards/CardConfidence.vue';

@Options({
  name: 'CardGroupRow',
  components: { CardConfidence }
})
export default class CardGroupRow extends Vue {}
