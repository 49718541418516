import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "video-wall-camera-list-group" }
const _hoisted_2 = {
  key: 0,
  class: "video-wall-camera-list-group__cameras"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoWallCameraListGroupHeader = _resolveComponent("VideoWallCameraListGroupHeader")!
  const _component_VideoWallCameraListGroupItem = _resolveComponent("VideoWallCameraListGroupItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VideoWallCameraListGroupHeader, {
      label: _ctx.group.name,
      expanded: _ctx.expanded,
      "onUpdate:expanded": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expanded) = $event))
    }, null, 8, ["label", "expanded"]),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cameras, (camera) => {
            return (_openBlock(), _createBlock(_component_VideoWallCameraListGroupItem, {
              key: camera.id,
              camera: camera
            }, null, 8, ["camera"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}