export default {
  name: 'Название',
  ip_address: 'IP адрес',
  version: 'Версия',
  status: 'Статус',
  created: 'Дата добавления',
  create_new: 'Новая VMS',
  info: 'Сведения',
  list_cameras: 'Камеры',
  receive_events: 'Полученные сообщения',
  send_events: 'Отправка сообщений',
  vms: 'VMS',
  vms_select: 'ID внешней VMS',
  camera_select: 'ID камеры внешней VMS',
  stream_select: 'URL потока',
  configure: 'Настроить',
  add_selected_cameras: 'Добавить выбранные камеры',
  added: 'Добавлена',
  any_camera_group: 'Любая группа камер',
  any_status: 'Любой статус',
  not_added: 'Не добавлена',
  select_all_streams: 'Выбрать все потоки'
};
