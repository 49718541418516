/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum WeekdaysEnum {
    MON = 'Mon',
    TUE = 'Tue',
    WED = 'Wed',
    THU = 'Thu',
    FRI = 'Fri',
    SAT = 'Sat',
    SUN = 'Sun',
}