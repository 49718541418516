import { Dayjs } from 'dayjs';

export function setToLimits(date: Dayjs, min?: Dayjs | null, max?: Dayjs | null): Dayjs {
  if (min && date.isBefore(min)) {
    return min.add(1, 'seconds');
  }

  if (max && date.isAfter(max)) {
    return date.subtract(1, 'seconds');
  }

  return date;
}
