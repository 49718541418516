
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import { formatConfidence } from '@/common/filters';

@Options({
  name: 'ClusterWizardItem',
  components: { NButton }
})
export default class ClusterWizardItem extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly confidence!: number;

  @Prop({ type: Boolean, default: true })
  readonly canAdd!: boolean;

  get formatedConfidence() {
    return formatConfidence(this.confidence);
  }
}
