import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTable = _resolveComponent("NTable")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NTable, {
      columns: _ctx.columns,
      "body-decorators": _ctx.bodyDecorators,
      content: _ctx.module.items,
      selected: _ctx.selectedItems,
      "onUpdate:selectedChanges": _ctx.handleSelect,
      hoverable: "",
      selections: ""
    }, null, 8, ["columns", "body-decorators", "content", "selected", "onUpdate:selectedChanges"]),
    _createVNode(_component_InfiniteScroll, {
      filters: _ctx.module.filter.current,
      "next-page": _ctx.module.next_page,
      onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.module.append()))
    }, null, 8, ["filters", "next-page"])
  ], 64))
}