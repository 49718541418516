import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "video-player-line-layer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NImageViewerLineLayer = _resolveComponent("NImageViewerLineLayer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lines, (line) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: line.id
      }, [
        (line.points.length && _ctx.scale && _ctx.cameraWidth && _ctx.cameraHeight)
          ? (_openBlock(), _createBlock(_component_NImageViewerLineLayer, {
              key: 0,
              class: "video-player-line-layer__component",
              track: [],
              line: line.points,
              size: { width: _ctx.cameraWidth, height: _ctx.cameraHeight },
              offset: [0, 0],
              scale: _ctx.scale
            }, null, 8, ["line", "size", "scale"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}