export interface BodyAgeGroupFeature extends Feature {
  name: BodyAgeGroupFeatureValues;
}

export interface BodyBagBackFeature extends Feature {
  name: BodyBagBackFeatureValues;
}

export interface BodyBagGroundFeature extends Feature {
  name: BodyBagGroundFeatureValues;
}

export interface BodyBagHandFeature extends Feature {
  name: BodyBagHandFeatureValues;
}

export interface BodyBottomColorFeature extends Feature {
  name: BodyBottomColorFeatureValues;
}

export interface BodyDetailedUpperClothesFeature extends Feature {
  name: BodyDetailedUpperClothesFeatureValues;
}

export interface BodyGenderFeature extends Feature {
  name: BodyGenderFeatureValues;
}

export interface BodyHeadwearFeature extends Feature {
  name: BodyHeadwearFeatureValues;
}

export interface BodyHelmetTypeFeature extends Feature {
  name: BodyHelmetTypeFeatureValues;
}

export interface BodyHelmetTypeScoreFeature extends Feature {
  name: BodyHelmetTypeScoreFeatureValues;
}

export interface BodyLowerClothesFeature extends Feature {
  name: BodyLowerClothesFeatureValues;
}

export interface BodyTopColorFeature extends Feature {
  name: BodyTopColorFeatureValues;
}

export interface BodyUpperClothesFeature extends Feature {
  name: BodyUpperClothesFeatureValues;
}

export interface BodyVestTypeFeature extends Feature {
  name: BodyVestTypeFeatureValues;
}

export interface BodyVestTypeScoreFeature extends Feature {
  name: BodyVestTypeScoreFeatureValues;
}

export interface CarBodyFeature extends Feature {
  name: CarBodyFeatureValues;
}

export interface CarCategoryFeature extends Feature {
  name: CarCategoryFeatureValues;
}

export interface CarColorFeature extends Feature {
  name: CarColorFeatureValues;
}

export interface CarLicensePlateCountryFeature extends Feature {
  name: CarLicensePlateCountryFeatureValues;
}

export interface CarLicensePlateNumberColorFeature extends Feature {
  name: CarLicensePlateNumberColorFeatureValues;
}

export interface CarLicensePlateNumberFeature extends Feature {
  bbox: CarLicensePlateNumberBbox;
  name: CarLicensePlateNumberFeatureValues;
}

export interface CarLicensePlateNumberBbox {
  bottom: number;
  left: number;
  right: number;
  top: number;
}

export interface CarLicensePlateRegionFeature extends Feature {
  name: CarLicensePlateRegionFeatureValues;
}

export interface CarMakeFeature extends Feature {
  name: CarMakeFeatureValues;
}

export interface CarModelFeature extends Feature {
  name: CarModelFeatureValues;
}

export interface CarOrientationFeature extends Feature {
  name: CarOrientationFeatureValues;
}

export interface CarSpecialVehicleTypeFeature extends Feature {
  name: CarSpecialVehicleTypeFeatureValues;
}

export interface CarWeightTypeFeature extends Feature {
  name: CarWeightTypeFeatureValues;
}

export interface FaceAgeFeature extends Feature {
  name: FaceAgeFeatureValues;
}

export interface FaceBeardFeature extends Feature {
  name: FaceBeardFeatureValues;
}

export interface FaceEmotionsFeature extends Feature {
  name: FaceEmotionsFeatureValues;
}

export interface FaceGenderFeature extends Feature {
  name: FaceGenderFeatureValues;
}

export interface FaceGlassesFeature extends Feature {
  name: FaceGlassesFeatureValues;
}

export interface FaceHeadposePitchFeature extends Feature {
  name: FaceHeadposePitchFeatureValues;
}

export interface FaceHeadposeYawFeature extends Feature {
  name: FaceHeadposeYawFeatureValues;
}

export interface FaceLivenessFeature extends Feature {
  name: FaceLivenessFeatureValues;
}

export interface FaceLookFeature extends Feature {
  name: FaceLookFeatureValues;
}

export interface FaceMedmaskFeature extends Feature {
  name: FaceMedmaskFeatureValues;
}

export interface Feature {
  confidence: number;
}

export interface BodyUnknownFeature extends Feature {
  [key: string]: unknown;
}

export interface CarUnknownFeature extends Feature {
  [key: string]: unknown;
}

export interface FaceUnknownFeature extends Feature {
  [key: string]: unknown;
}

export type BodyUnknownFeatureName = string;

export type CarUnknownFeatureName = string;

export type FaceUnknownFeatureName = string;

export type BodyAgeGroupFeatureValues = '0-16' | '17-35' | '36-50' | '50+';

export type BodyBagBackFeatureValues = 'back' | 'none';

export type BodyBagGroundFeatureValues = 'ground' | 'none';

export type BodyBagHandFeatureValues = 'hand' | 'none';

export type BodyBottomColorFeatureValues =
  | 'beige'
  | 'black'
  | 'blue'
  | 'brown'
  | 'green'
  | 'grey'
  | 'lightblue'
  | 'orange'
  | 'pink'
  | 'purple'
  | 'red'
  | 'violet'
  | 'white'
  | 'yellow';

export type BodyDetailedUpperClothesFeatureValues = 'coat' | 'dress' | 'jacket' | 'shirt' | 'sleeveless' | 'sweatshirt' | 't-shirt';

export type BodyFeature<TFeatureName extends BodyFeatureNames> = BodyFeatures[TFeatureName];

export type BodyFeatureFormatter<TFeatureName extends BodyFeatureNames> = (
  name: TFeatureName,
  feature: BodyFeature<TFeatureName>,
  i18n: FeatureI18n
) => FormattedFeature;

export type BodyUnknownFeatureFormatter = (name: BodyUnknownFeatureName, feature: BodyUnknownFeature, i18n: FeatureI18n) => FormattedFeature;

export type BodyFeatureFormatters = {
  [TFeatureName in BodyFeatureNames]: BodyFeatureFormatter<TFeatureName>;
};

export type BodyFeatureNames = keyof BodyFeatures;

export type BodyFeatures = {
  age_group: BodyAgeGroupFeature;
  bag_back: BodyBagBackFeature;
  /* see FFSEC-3321, FFSEC-862
   bag_ground: BodyBagGroundFeature;
   */
  bag_hand: BodyBagHandFeature;
  bottom_color: BodyBottomColorFeature;
  detailed_upper_clothes: BodyDetailedUpperClothesFeature;
  gender: BodyGenderFeature;
  headwear: BodyHeadwearFeature;
  helmet_type_score: BodyHelmetTypeScoreFeature;
  helmet_type: BodyHelmetTypeFeature;
  lower_clothes: BodyLowerClothesFeature;
  top_color: BodyTopColorFeature;
  upper_clothes: BodyUpperClothesFeature;
  vest_type_score: BodyVestTypeScoreFeature;
  vest_type: BodyVestTypeFeature;
};

export type BodyFeaturesFormatter = (features: Partial<BodyFeatures>, i18n: FeatureI18n) => Partial<BodyFormattedFeatures>;

export type BodyFeaturesFormatterFactory = (formatters: BodyFeatureFormatters, unknown: BodyUnknownFeatureFormatter) => BodyFeaturesFormatter;

export type BodyFormattedFeatures = { [TFeatureName in BodyFeatureNames]: FormattedFeature };

export type BodyGenderFeatureValues = 'female' | 'male';

export type BodyHeadwearFeatureValues = 'hat' | 'hood' | 'none';

export type BodyHelmetTypeFeatureValues = 'none' | 'not_visible' | 'other' | 'red/orange' | 'white';

export type BodyHelmetTypeScoreFeatureValues = unknown;

export type BodyLowerClothesFeatureValues = 'obscured' | 'pants' | 'shorts' | 'skirt';

export type BodyTopColorFeatureValues =
  | 'beige'
  | 'black'
  | 'blue'
  | 'brown'
  | 'green'
  | 'grey'
  | 'lightblue'
  | 'orange'
  | 'pink'
  | 'purple'
  | 'red'
  | 'violet'
  | 'white'
  | 'yellow';

export type BodyUpperClothesFeatureValues = 'long_sleeves' | 'short_sleeves' | 'without_sleeves';

export type BodyVestTypeFeatureValues = 'green/yellow' | 'none' | 'not_visible' | 'orange';

export type BodyVestTypeScoreFeatureValues = unknown;

export type CarBodyFeatureValues = 'cab' | 'convertible' | 'coupe' | 'crossover' | 'limousine' | 'minibus' | 'minivan' | 'sedan' | 'suv' | 'wagon';

export type CarCategoryFeatureValues = 'A' | 'B' | 'BE' | 'C' | 'CE' | 'D' | 'DE' | 'other' | 'unknown';

export type CarColorFeatureValues =
  | 'beige'
  | 'black'
  | 'blue'
  | 'brown'
  | 'cyan'
  | 'gold'
  | 'green'
  | 'grey'
  | 'orange'
  | 'pink'
  | 'purple'
  | 'red'
  | 'silver'
  | 'violet'
  | 'white'
  | 'yellow';

export type CarFeature<TFeatureName extends CarFeatureNames> = CarFeatures[TFeatureName];

export type CarFeatureFormatter<TFeatureName extends CarFeatureNames> = (
  name: TFeatureName,
  feature: CarFeature<TFeatureName>,
  i18n: FeatureI18n
) => FormattedFeature;

export type CarUnknownFeatureFormatter = (name: CarUnknownFeatureName, feature: CarUnknownFeature, i18n: FeatureI18n) => FormattedFeature;

export type CarFeatureFormatters = {
  [TFeatureName in CarFeatureNames]: CarFeatureFormatter<TFeatureName>;
};

export type CarFeatureNames = keyof CarFeatures;

export type CarFeatures = {
  body: CarBodyFeature;
  category: CarCategoryFeature;
  color: CarColorFeature;
  license_plate_country: CarLicensePlateCountryFeature;
  license_plate_number_color: CarLicensePlateNumberColorFeature;
  license_plate_number: CarLicensePlateNumberFeature;
  license_plate_region: CarLicensePlateRegionFeature;
  make: CarMakeFeature;
  model: CarModelFeature;
  orientation: CarOrientationFeature;
  special_vehicle_type: CarSpecialVehicleTypeFeature;
  weight_type: CarWeightTypeFeature;
};

export type CarFeaturesFormatter = (features: Partial<CarFeatures>, i18n: FeatureI18n) => Partial<CarFormattedFeatures>;

export type CarFeaturesFormatterFactory = (formatters: CarFeatureFormatters, unknown: CarUnknownFeatureFormatter) => CarFeaturesFormatter;

export type CarFormattedFeatures = { [TFeatureName in CarFeatureNames]: FormattedFeature };

export type CarLicensePlateCountryFeatureValues =
  | 'AE'
  | 'AM'
  | 'AR'
  | 'AZ'
  | 'BR'
  | 'BY'
  | 'CZ'
  | 'Country_1'
  | 'Country_2'
  | 'Country_3'
  | 'Country_4'
  | 'Country_5'
  | 'EST'
  | 'FIN'
  | 'GE'
  | 'IN'
  | 'KG'
  | 'KZ'
  | 'LT'
  | 'LV'
  | 'MD'
  | 'MX'
  | 'PK'
  | 'RU'
  | 'SA'
  | 'SRB'
  | 'TH'
  | 'TJ'
  | 'TM'
  | 'UA'
  | 'UZ'
  | 'VN'
  | 'unknown';

export type CarLicensePlateNumberColorFeatureValues = 'black' | 'blue' | 'green' | 'grey' | 'unknown' | 'white' | 'yellow';

export type CarLicensePlateNumberFeatureValues = string;

export type CarLicensePlateRegionFeatureValues = 'ADH' | 'AJM' | 'DXB' | 'FUJ' | 'RAK' | 'SHJ' | 'UAK' | 'unknown';

export type CarMakeFeatureValues = string;

export type CarModelFeatureValues = string;

export type CarOrientationFeatureValues = 'back' | 'front' | 'side';

export type CarSpecialVehicleTypeFeatureValues =
  | 'ambulance'
  | 'car_sharing'
  | 'gas_service'
  | 'military'
  | 'not_special'
  | 'other_special'
  | 'police'
  | 'rescue_service'
  | 'road_service'
  | 'route_transport'
  | 'taxi';

export type CarWeightTypeFeatureValues = 'B_heavy' | 'B_light' | 'C_heavy' | 'C_light' | 'D_light' | 'D_long' | 'other';

export type FaceAgeFeatureValues = number;

export type FaceBeardFeatureValues = 'beard' | 'none';

export type FaceEmotionsFeatureValues = 'angry' | 'disgust' | 'fear' | 'happy' | 'neutral' | 'sad' | 'surprise';

export type FaceFeature<TFeatureName extends FaceFeatureNames> = FaceFeatures[TFeatureName];

export type FaceFeatureFormatter<TFeatureName extends FaceFeatureNames> = (
  name: TFeatureName,
  feature: FaceFeature<TFeatureName>,
  i18n: FeatureI18n
) => FormattedFeature;

export type FaceUnknownFeatureFormatter = (name: FaceUnknownFeatureName, feature: FaceUnknownFeature, i18n: FeatureI18n) => FormattedFeature;

export type FaceFeatureFormatters = {
  [TFeatureName in FaceFeatureNames]: FaceFeatureFormatter<TFeatureName>;
};

export type FaceFeatureNames = keyof FaceFeatures;

export type FaceFeatures = {
  age: FaceAgeFeature;
  beard: FaceBeardFeature;
  emotions: FaceEmotionsFeature;
  gender: FaceGenderFeature;
  glasses: FaceGlassesFeature;
  headpose_pitch: FaceHeadposePitchFeature;
  headpose_yaw: FaceHeadposeYawFeature;
  liveness: FaceLivenessFeature;
  look: FaceLookFeature;
  medmask: FaceMedmaskFeature;
};

export type FaceFeaturesFormatter = (features: Partial<FaceFeatures>, i18n: FeatureI18n) => Partial<FaceFormattedFeatures>;

export type FaceFeaturesFormatterFactory = (formatters: FaceFeatureFormatters, unknown: FaceUnknownFeatureFormatter) => FaceFeaturesFormatter;

export type FaceFormattedFeatures = { [TFeatureName in FaceFeatureNames]: FormattedFeature };

export type FaceGenderFeatureValues = 'female' | 'male';

export type FaceGlassesFeatureValues = 'eye' | 'none' | 'sun';

export type FaceHeadposePitchFeatureValues = number;

export type FaceHeadposeYawFeatureValues = number;

export type FaceLivenessFeatureValues = 'fake' | 'real';

export type FaceLookFeatureValues = 'asian' | 'black' | 'indian' | 'white';

export type FaceMedmaskFeatureValues = 'correct' | 'incorrect' | 'none';

export type FeatureI18n = {
  readonly t: FeatureI18nToken;
  readonly tm: FeatureI18nMessage;
};

export type FeatureI18nMessage = (value: string, data: Record<string, unknown>) => string;

export type FeatureI18nToken = (token: string, options?: string) => string;

export type FeatureObject = 'bodies' | 'cars' | 'faces';

export type FormattedFeature = {
  confidence?: string;
  content: string;
};

export type LineAttributePayload = {
  line: number;
  line_crossing_direction: LineDirection;
  backward_line_crossing: boolean;
};

export type LineDirection = typeof LineDirections[keyof typeof LineDirections];

export const LineDirections = {
  None: 'none',
  BA: 'BA',
  AB: 'AB'
} as const;
