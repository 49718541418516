import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys, normalizeStyle as _normalizeStyle, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "n-modal-window_container" }
const _hoisted_2 = { class: "stack" }
const _hoisted_3 = {
  key: 0,
  class: "n-modal-window_header"
}
const _hoisted_4 = { class: "heading-xl" }
const _hoisted_5 = {
  key: 1,
  class: "n-modal-window_content"
}
const _hoisted_6 = { class: "n-modal-window_footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createBlock(_Teleport, {
    to: "body",
    disabled: !_ctx.insertIntoBody
  }, [
    _createElementVNode("div", {
      ref: "modalWindow",
      class: _normalizeClass(_ctx.cssClass),
      style: _normalizeStyle({ zIndex: _ctx.zIndex })
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.stackPlugs)
          ? _renderSlot(_ctx.$slots, "stack", { key: 0 }, () => [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stackPlugs, (plug) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["stack_item", 'stack_item_' + plug]),
                    key: plug
                  }, null, 2))
                }), 128))
              ])
            ])
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "n-modal-window_body",
          ref: "modal_window",
          tabindex: "0",
          onKeyup: _cache[0] || (_cache[0] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.closeHandler && _ctx.closeHandler(...args)), ["stop"]), ["esc"])),
          style: _normalizeStyle(_ctx.customSizeCss)
        }, [
          (_ctx.headerDisplay)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "header", {}, () => [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.label), 1),
                  (_ctx.closeIcon)
                    ? (_openBlock(), _createBlock(_component_NButton, {
                        key: 0,
                        icon: "close",
                        type: "secondary",
                        round: "",
                        class: "n-modal-window__close-button",
                        onClick: _ctx.closeHandler
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.contentDisplay)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "default"),
                _renderSlot(_ctx.$slots, "content"),
                (!_ctx.headerDisplay && _ctx.closeIcon)
                  ? (_openBlock(), _createBlock(_component_NButton, {
                      key: 0,
                      icon: "close",
                      type: "secondary",
                      round: "",
                      class: "n-modal-window__close-button",
                      onClick: _ctx.closeHandler
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "footer")
          ])
        ], 36)
      ])
    ], 6)
  ], 8, ["disabled"]))
}