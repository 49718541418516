import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video-wall-episode-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventsPage = _resolveComponent("EventsPage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EventsPage, {
      tab: this.tab + '-events',
      externalFilters: _ctx.filters
    }, null, 8, ["tab", "externalFilters"])
  ]))
}