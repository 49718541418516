
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { LineAttributePayload, LineDirections } from '@/components/common/types';
import { dataServiceRepository } from '@/api/common';
import { Line } from '@/api/models/Line';

@Options({
  name: 'AttributeLineCross',
  emits: []
})
export default class AttributeLineCross extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: LineAttributePayload;

  @Prop({ type: String, required: true })
  readonly classes!: string;

  private line: Line | null = null;

  @Watch('item.line', { immediate: true })
  async loadLine() {
    this.line = await dataServiceRepository.LinesService.get(this.item.line);
  }

  get directionLetter() {
    return this.item.line_crossing_direction === LineDirections.AB ? 'AB' : 'BA';
  }

  get backwardDirectionLetter() {
    return this.item.line_crossing_direction === LineDirections.AB ? 'BA' : 'AB';
  }

  get computedClasses() {
    return {
      'attribute-line-cross': true,
      [this.classes]: true
    };
  }
}
