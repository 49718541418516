import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_ctx.modelValue <= _ctx.options.default)
    ? (_openBlock(), _createBlock(_component_NButton, {
        key: 0,
        icon: "zoom-in",
        type: "secondary-alt",
        round: "",
        onClick: _ctx.zoomIn
      }, null, 8, ["onClick"]))
    : (_openBlock(), _createBlock(_component_NButton, {
        key: 1,
        icon: "zoom-out",
        type: "secondary-alt",
        round: "",
        onClick: _ctx.zoomOut
      }, null, 8, ["onClick"]))
}