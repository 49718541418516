interface ElementType extends HTMLElement {
  scrollHorizontally(e: WheelEvent): void;
}

export default {
  beforeMount(el: ElementType) {
    el.scrollHorizontally = (e: WheelEvent) => {
      const oldScrollLeft = el.scrollLeft;
      el.scrollLeft += e.deltaY;
      if (el.scrollLeft !== oldScrollLeft) {
        e.preventDefault();
      }
    };
  },
  mounted(el: ElementType) {
    el.addEventListener('wheel', el.scrollHorizontally);
  },
  unmounted(el: ElementType) {
    el.removeEventListener('wheel', el.scrollHorizontally);
  }
};
