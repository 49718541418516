
import SearchFromSelect from '@/pages/search/SearchFromSelect.vue';
import { SearchModule } from '@/pages/search/searchModule';
import { SearchFromMap } from '@/store/application/data.assets';
import { NAttachment, NAttachments, NAttachmentsI18n } from '@/uikit/attachments';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  name: 'SearchOverlay',
  components: {
    NAttachments,
    SearchFromSelect
  },
  emits: ['search']
})
export default class SearchOverlay extends Vue {
  @Prop({ type: Object, required: true })
  searchModule!: SearchModule;

  get state() {
    return this.searchModule.state;
  }

  get loading() {
    return this.searchModule.loading;
  }

  get loaded() {
    return this.searchModule.loaded;
  }

  get attachmentsI18n(): NAttachmentsI18n {
    return {
      droparea_label: this.$t('search.drag_and_drop_files', 'f'),
      droparea_link_label: this.$t('search.select_files')
    };
  }

  @Watch('loading')
  closeOnItemFound() {
    !this.loading && this.loaded && (this.state.showOverlay = false);
  }

  handleFileUpload([v]: NAttachment[]) {
    v.status = 'done';
    this.searchModule.attachment = v;
    this.state.searchFrom = SearchFromMap.File;
    this.state.showOverlay = false;
  }
}
