import { vModelText as _vModelText, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "autofocus"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("textarea", {
    class: _normalizeClass(['n-textarea-autosize', { 'label-mono-m': _ctx.mono, 'label-m': !_ctx.mono }]),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.areaValue) = $event)),
    placeholder: _ctx.placeholder,
    autofocus: _ctx.autofocus,
    ref: "area",
    rows: "1",
    style: _normalizeStyle({ height: _ctx.height ? `${_ctx.height}px` : 'auto' })
  }, null, 14, _hoisted_1)), [
    [_vModelText, _ctx.areaValue]
  ])
}