
import NButton from '@/uikit/buttons/NButton.vue';
import { NDropdown, NDropdownItem } from '@/uikit/dropdown';
import { defineComponent, PropType } from 'vue';
import { ReportFormat } from '../../../types';

export default defineComponent({
  name: 'ReportsPageTableColumnStatusDownload',
  components: {
    NButton,
    NDropdown,
    NDropdownItem
  },
  props: {
    formats: {
      required: true,
      type: Array as PropType<ReportFormat[]>
    }
  },
  emits: ['download'],
  methods: {
    i18nReportFormat(format: ReportFormat): string {
      return this.$t(`reports.${format}`);
    },
    dispatchDownloadEvent(format: ReportFormat): void {
      this.$emit('download', format);
    }
  }
});
