
import { isDefined } from '../utils';
import NButton from '../buttons/NButton.vue';
import { reactive } from '@vue/reactivity';
import without from 'lodash/without';
import { Options, Prop, Vue } from 'vue-property-decorator';
import { DefaultI18n } from './const';
import NAttachmentsAmount from './NAttachmentsAmount.vue';
import NAttachmentsDropArea from './NAttachmentsDropArea.vue';
import NAttachmentsList from './NAttachmentsList.vue';
import NAttachmentsListItem from './NAttachmentsListItem.vue';
import NAttachmentsPicker from './NAttachmentsPicker.vue';
import type { NAttachment, NAttachmentAttachHandler, NAttachmentFileBlob, NAttachmentRemoveHandler, NAttachmentsI18n, Nullable } from './types';
import { fromBlob, fromFs } from './utils';

@Options({
  components: {
    NAttachmentsAmount,
    NAttachmentsDropArea,
    NAttachmentsList,
    NAttachmentsListItem,
    NAttachmentsPicker,
    NButton
  },
  emits: ['attach', 'remove', 'update:attachments']
})
export default class NAttachments extends Vue {
  @Prop({ default: '*/*', type: String })
  readonly accept!: string;
  @Prop({ default: true, type: Boolean })
  readonly askBeforeRemove!: boolean;
  @Prop({ default: () => [], type: Array })
  readonly attachments!: ReadonlyArray<Readonly<NAttachment>>;
  @Prop({ default: false, type: Boolean })
  readonly disallowAttach!: boolean;
  @Prop({ default: false, type: Boolean })
  readonly disallowRemove!: boolean;
  @Prop({ default: false, type: Boolean })
  readonly drag!: boolean;
  @Prop({ default: null, type: Function })
  readonly handleAttach!: Nullable<NAttachmentAttachHandler>;
  @Prop({ default: null, type: Function })
  readonly handleRemove!: Nullable<NAttachmentRemoveHandler>;
  @Prop({ default: false, type: Boolean })
  readonly hideAmount!: boolean;
  @Prop({ default: false, type: Boolean })
  readonly hideAttachments!: boolean;
  @Prop({ default: () => ({}), type: Object })
  readonly i18n!: Readonly<NAttachmentsI18n>;
  @Prop({ default: false, type: Boolean })
  readonly multiple!: boolean;
  @Prop({ default: false, type: Boolean })
  readonly disabled!: boolean;

  get computedI18n(): Readonly<Required<NAttachmentsI18n>> {
    return { ...DefaultI18n, ...this.i18n };
  }

  get isAttachmentsListVisible(): boolean {
    return !this.hideAttachments && this.attachments.length > 0;
  }

  get isHeaderVisible(): boolean {
    return !this.hideAmount || !this.disallowAttach;
  }

  async attachFromFs(): Promise<void> {
    if (this.disabled) {
      return;
    }
    await this.handleAttachEvent(await fromFs(this.accept, this.multiple));
  }

  async handleAttachEvent(files: ReadonlyArray<NAttachmentFileBlob>): Promise<void> {
    const attachments = files.map(fromBlob).map(reactive);
    this.$emit('update:attachments', [...this.attachments, ...attachments]);
    await this.$nextTick();
    await Promise.all(attachments.map(this.attach));
    this.$emit('attach', attachments);
  }

  async handleRemoveEvent(attachment: Readonly<NAttachment>): Promise<void> {
    await this.remove(attachment);
    this.$emit('update:attachments', without(this.attachments, attachment));
    this.$emit('remove', attachment);
  }

  private attach(attachment: Readonly<NAttachment>): Promise<void> {
    return new Promise((resolve) => (isDefined(this.handleAttach) ? this.handleAttach(attachment, resolve) : resolve()));
  }

  private remove(attachment: Readonly<NAttachment>): Promise<void> {
    return new Promise((resolve) => (isDefined(this.handleRemove) ? this.handleRemove(attachment, resolve) : resolve()));
  }
}
