
import { Options, Prop, Vue } from 'vue-property-decorator';
import type { VideoWallPreset } from '../../types';
import { VideoWallLayoutButton } from '../VideoWallLayoutButton';
import VideoWallPresetSelectItemDeleteButton from './VideoWallPresetSelectItemDeleteButton.vue';

@Options({
  components: {
    VideoWallLayoutButton,
    VideoWallPresetSelectItemDeleteButton
  },
  emits: ['click', 'delete']
})
export default class VideoWallPresetSelectItem extends Vue {
  @Prop({ required: true, type: Object })
  readonly preset!: VideoWallPreset;

  dispatchClickEvent(): void {
    this.$emit('click');
  }

  dispatchDeleteEvent(): void {
    this.$emit('delete');
  }
}
