import { FaceEvent, BodyEvent, CarEvent, HumanEpisode, CarEpisode } from '@/api';
import isNull from 'lodash/isNull';

export type EventView = {
  id: string;
  thumbnail: string;
  license_plate_thumbnail?: string;
  fullframe: string;
  confidence: number;
  open: boolean;
  created_date: string;
  closed_date?: string;
  cameras: number[];
  camera_groups: number[];
  video_archive: number;
  matched_card?: number;
  matched_object?: string;
  matched_lists?: number[];
  features?: Record<string, any>;
  faces_features?: Record<string, any>;
  bodies_features?: Record<string, any>;
  cars_features?: Record<string, any>;
  objectsType?: string;
  actions?: string[];
};

export function episodeAdapter(episode: HumanEpisode | CarEpisode): EventView {
  const source = episode as HumanEpisode | CarEpisode as any;
  const humanEpisode = episode as HumanEpisode;
  const carEpisode = episode as CarEpisode;
  const best_event = humanEpisode.best_face_event || humanEpisode.best_body_event || carEpisode.best_car_event;
  const last_event = humanEpisode.last_face_event || humanEpisode.last_body_event || carEpisode.last_car_event;
  const matched_event = humanEpisode.matched_face_event || humanEpisode.matched_body_event || carEpisode.matched_car_event;
  let object = humanEpisode.last_face_event ? 'faces' : '';
  object = humanEpisode.last_body_event ? 'bodies' : object;
  object = carEpisode.last_car_event ? 'cars' : object;

  const result = {
    ...last_event,
    ...best_event,
    ...matched_event,
    open: source.open,
    created_date: source.created_date,
    closed_date: source.closed_date,
    cameras: source.cameras,
    camera_groups: source.camera_groups,
    video_archive: source.video_archive,
    faces_features: humanEpisode.face_features,
    bodies_features: humanEpisode.body_features,
    cars_features: carEpisode.car_features,
    id: String(source.id),
    bast_face_event: humanEpisode.best_face_event,
    best_body_event: humanEpisode.best_body_event,
    best_car_event: carEpisode.best_car_event,
    matched_card: source.matched_card ?? matched_event?.matched_card,
    matched_lists: source.matched_lists,
    fullframe: matched_event?.fullframe || best_event?.fullframe || last_event?.fullframe,
    thumbnail: matched_event?.thumbnail || best_event?.thumbnail || last_event?.thumbnail,
    confidence: matched_event?.confidence,
    license_plate_thumbnail: best_event.license_plate_thumbnail,
    objectsType: object,
    lines: source.lines
  };
  // console.log('-------- Epsiode LAST', last_event, 'BEST', best_event, 'MATCH', matched_event, 'EPI', episode);
  return result;
}

export function eventAdapter(event: FaceEvent | BodyEvent | CarEvent, objects: string): EventView {
  const result = event as FaceEvent | BodyEvent | CarEvent as any;
  return {
    ...result,
    open: false,
    cameras: isNull(result.camera) ? [] : [result.camera],
    camera_groups: isNull(result.camera_group) ? [] : [result.camera_group],
    video_archive: result.video_archive,
    [`${objects}_features`]: result.features
  };
}

export function eventEpisodeAdapter(v: any, objects: string): EventView {
  return v.open !== undefined ? episodeAdapter(v) : eventAdapter(v, objects);
}
