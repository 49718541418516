export default {
  create_hooks: 'Новый вебхук',
  hooks_no_items: 'У вас нет ни одного вебхука',
  webhooks: 'вебхуки',
  webhook_title: 'Заголовок вебхука',
  batch_size: 'Количество сообщений в пакете',
  send_attempts: 'Количество попыток отправки',
  send_attempts_tip: '0 - не ограничено',
  filters: 'Фильтры',
  edit_mode_visual: 'визуальный',
  edit_mode_json: 'код',
  section_counters: 'записи счетчиков',
  section_car_episodes: 'эпизоды с ТС',
  section_human_episodes: 'эпизоды с людьми',
  section_car_events: 'события с ТС',
  section_body_events: 'события с силуэтами',
  section_face_events: 'события с лицами',
  section_selected: 'Выбрано. Вы получите все',
  enable_visual: 'настроить',
  filter_default: 'Пока не выбран ни один фильтр, будут отправляться все события',
  reset_question: 'Вы действительно хотите сбросить фильтры? Все выбранные фильтры будут сброшены.',
  reset_confirm: 'Да, сбросить',
  active: 'Активный'
};
