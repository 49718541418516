
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

@Options({
  name: 'CardDate'
})
export default class CardDate extends Vue {
  @Prop({ type: Date, required: true })
  readonly modelValue!: any;

  get date(): string {
    return this.$filters.formatDateTime(this.modelValue);
  }
}
