
import { Report } from '@/api';
import { defineComponent, PropType } from 'vue';
import { ReportsPageTableColumnLabel } from './common';

export default defineComponent({
  name: 'ReportsPageTableColumnType',
  components: { ReportsPageTableColumnLabel },
  props: {
    type: {
      required: true,
      type: String as PropType<Report['type']>
    }
  },
  computed: {
    content() {
      return this.$t(`reports.${this.type}`, 'f');
    }
  }
});
