import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  class: "n-input-color__wrapper",
  tabindex: "0"
}
const _hoisted_3 = { class: "n-input-color__units label-mono-m" }
const _hoisted_4 = ["disabled", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_color_picker = _resolveComponent("n-color-picker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'n-input-color',
      'control-m',
      {
        'n-input-color--disabled': _ctx.disabled,
        [_ctx.itemClass]: _ctx.itemClass
      }
    ])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "n-input-color__label",
          for: _ctx.modelValue
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_n_color_picker, {
        modelValue: _ctx.currentColor,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentColor) = $event)),
        "default-color": _ctx.color,
        disabled: _ctx.disabled,
        "color-format": _ctx.colorFormat,
        onConfirm: _ctx.handleConfirm,
        onChange: _ctx.handleChange,
        onClear: _ctx.handleClear
      }, null, 8, ["modelValue", "default-color", "disabled", "color-format", "onConfirm", "onChange", "onClear"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.format), 1),
      _withDirectives(_createElementVNode("input", {
        class: "n-input-color__input label-mono-m",
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder,
        type: "text",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentColor) = $event)),
        autocomplete: "off",
        maxlength: "6",
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)))
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.currentColor]
      ])
    ])
  ], 2))
}