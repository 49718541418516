import { viewModelRepository } from '@/api/common';
import { SearchResult } from '@/api/models/SearchResult';
import enrichCards, { EnrichedCard } from '@/components/cards/enrich-cards';

export interface EnrichedSearchResult extends SearchResult {
  cardObject?: EnrichedCard;
}

export default async function enrichSearchResults(
  searchResults: SearchResult[],
  oldEnrichedSearchResults: EnrichedSearchResult[] = []
): Promise<EnrichedSearchResult[]> {
  // fill from old
  const enrichedSearchResults = searchResults.map((searchResult) => {
    return {
      ...searchResult,
      cardObject: oldEnrichedSearchResults.find((item) => item.card === searchResult.card)?.cardObject
    };
  });

  // get new
  let cardIds = enrichedSearchResults
    .filter((enrichedSearchResult) => enrichedSearchResult.card && !enrichedSearchResult.cardObject)
    .map((enrichedSearchResults) => enrichedSearchResults.card)
    .map((str) => parseInt(str)); // TODO: fucking string
  cardIds = [...new Set(cardIds)];
  const cardModule = viewModelRepository.getCardsHumansListViewModel();
  if (cardIds.length) {
    cardModule.filter.current.id_in = cardIds as number[];
    cardModule.filter.current.limit = '1000';
    await cardModule.get();
  }

  // enrich child
  const oldEnrichedCards = enrichedSearchResults.map((item) => item.cardObject).filter((item) => item) as EnrichedCard[];
  const enrichedCards = await enrichCards(cardModule.items, oldEnrichedCards);

  // fill from new
  enrichedSearchResults.forEach((enrichedSearchResult) => {
    const cardObject = enrichedCards.find((item) => item.id === parseInt(enrichedSearchResult.card)); // TODO: fucking string
    if (cardObject) enrichedSearchResult.cardObject = cardObject;
  });

  return enrichedSearchResults;
}
