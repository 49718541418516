import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "n-input control-m" }
const _hoisted_2 = ["disabled", "placeholder", "readonly", "type", "autofocus", "accesskey", "data-qa"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NInputEye = _resolveComponent("NInputEye")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: _normalizeClass(_ctx.classes),
      disabled: _ctx.disabled,
      placeholder: _ctx.placeholder,
      readonly: _ctx.readonly,
      type: _ctx.type,
      autofocus: _ctx.autofocus,
      accesskey: _ctx.accesskey,
      "data-qa": `${_ctx.dataQa}.input`,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dispatchChangeEvent && _ctx.dispatchChangeEvent(...args))),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.eventProxyHandler && _ctx.eventProxyHandler(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.eventProxyHandler && _ctx.eventProxyHandler(...args))),
      ref: "input",
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model) = $event))
    }, null, 42, _hoisted_2), [
      [_vModelDynamic, _ctx.model]
    ]),
    (_ctx.suffixIcon)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 0,
          class: "n-input__suffix-icon",
          name: _ctx.suffixIcon,
          width: "16",
          height: "16"
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    (_ctx.clearable && _ctx.model)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 1,
          class: "n-input__postfix-icon",
          name: "close_small",
          width: "8",
          height: "20",
          onClick: _ctx.clear
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.password)
      ? (_openBlock(), _createBlock(_component_NInputEye, {
          key: 2,
          class: "n-input__eye",
          modelValue: _ctx.isPasswordVisible,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isPasswordVisible) = $event))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}