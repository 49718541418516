export default {
  looks_like_confidence_desc: 'Most similar first',
  looks_like_confidence_asc: 'Least similar first',
  id_desc: 'ID in descending order',
  id_asc: 'ID in ascending order',
  created_date_desc: 'Newest first',
  created_date_asc: 'Oldest first',
  external_timestamp_desc: 'Newest first',
  external_timestamp_asc: 'Oldest first',
  modified_date_desc: 'Newest changes first',
  modified_date_asc: 'Oldest changes first',
  name_desc: 'By name from Z to A',
  name_asc: 'By name from A to Z',
  confidence_desc: 'Most similar first',
  confidence_asc: 'Least similar first'
};
