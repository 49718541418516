
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NInputNumber from '@/uikit/input-number/NInputNumber.vue';

@Options({
  name: 'FilterConfidence',
  components: { NInputNumber },
  emits: ['update:modelValue']
})
export default class FilterConfidence extends Vue {
  @Prop({ type: [Number, String] })
  readonly modelValue?: number | string;

  handleUpdate(v: number | null) {
    this.$emit('update:modelValue', v === null ? undefined : v);
  }
}
