import labelsStepByScale from './utils/labels-step-by-scale';
import { formatDate, formatTime, toHHMMSS } from '@/common/filters';
import getCssTokenValue from './utils/get-css-token-value';
import roundRect from './utils/round-rect';
import { ObjectItemType } from '@/components/video-player/objects/timeline-object';

const exportBorderLeft = new Image();
const exportBorderRight = new Image();
exportBorderLeft.src = require('@/assets/icons/player/export-border-left.svg');
exportBorderRight.src = require('@/assets/icons/player/export-border-right.svg');

export default class VideoPlayerTimelineRender {
  ctx!: CanvasRenderingContext2D;
  canvasWidth!: number;
  canvasHeight!: number;
  pixelsToTime!: number;
  timeOffset!: number;
  textColor = getCssTokenValue('--color-primary-100');
  backgroundColor = getCssTokenValue('--color-level-200');

  clearCanvas() {
    if (!this.ctx) {
      console.log('no ctx:', this.ctx);
    }
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
  }

  drawTimeLabels() {
    const labelStep = labelsStepByScale(this.pixelsToTime);
    const firstLabelTime = Math.floor(this.timeOffset / labelStep) * labelStep;
    const firstLabelOffsetPx = (this.timeOffset % labelStep) / this.pixelsToTime;
    const labelCount = (this.canvasWidth * this.pixelsToTime) / labelStep;
    let lastDay = '';

    for (let i = 0; i < labelCount + 1; i++) {
      const labelX = -firstLabelOffsetPx + i * (labelStep / this.pixelsToTime) + 1;
      const d = new Date((firstLabelTime + i * labelStep) * 1000);
      const day = formatDate(d, 'DD.MM.YYYY');
      const time = formatTime(d, 'HH:mm');
      const mt = Math.ceil(this.ctx.measureText(time).width);

      this.ctx.strokeStyle = 'rgba(255, 255, 255, 0.2)';
      this.ctx.beginPath();
      this.ctx.moveTo(labelX, 0);
      this.ctx.lineTo(labelX, this.canvasHeight);
      this.ctx.stroke();

      this.ctx.font = '12px serif';
      this.ctx.fillStyle = this.textColor;
      this.ctx.fillText(time, labelX - mt / 2, 13);

      if (i && day !== lastDay) {
        this.ctx.fillText(day, labelX - mt / 2, 24);
      }
      lastDay = day;
    }
  }

  drawObject(timeStart: number, timeEnd: number, itemType: number, itemLine: number, itemColor: string) {
    let pixelStart = (timeStart - this.timeOffset) / this.pixelsToTime;
    let pixelEnd = (timeEnd - this.timeOffset) / this.pixelsToTime;

    if (pixelStart === pixelEnd) {
      pixelStart = pixelStart - 3; // -3px
      pixelEnd = pixelEnd + 3; // +3px
    }

    if (pixelEnd < 0 || pixelStart >= this.canvasWidth) {
      return;
    }

    this.ctx.fillStyle = itemColor;

    if (itemType === ObjectItemType.Chunk) {
      let y = 0;
      let h = 18;
      this.ctx.fillRect(pixelStart, y, pixelEnd - pixelStart, h);
    }

    if (itemType === ObjectItemType.Event) {
      let y = 30;
      let h = this.canvasHeight - y;
      roundRect(this.ctx, pixelStart, y, pixelEnd - pixelStart, h, 5, true, false);
    }

    if (itemType === ObjectItemType.Episode) {
      const size = pixelEnd - pixelStart;
      if (size < 10) {
        const expansion = 5 - size / 2;
        pixelStart -= expansion;
        pixelEnd += expansion;
      }
      const y = 10 + itemLine * 10;
      const h = 9;
      roundRect(this.ctx, pixelStart, y, pixelEnd - pixelStart, h, 5, true, false);
    }

    if (itemType === ObjectItemType.Export) {
      this.ctx.fillRect(pixelStart, 0, pixelEnd - pixelStart, this.canvasHeight);
    }
  }

  drawExportBorder(time: number, direction: number) {
    const pixelStart = (time - this.timeOffset) / this.pixelsToTime;
    if (pixelStart < 0 || pixelStart >= this.canvasWidth) {
      return;
    }
    this.ctx.fillStyle = 'rgba(51, 121, 217, 0.9)';
    this.ctx.fillRect(pixelStart - 1, 0, 2, this.canvasHeight);
    if (direction > 0) {
      this.ctx.drawImage(exportBorderLeft, pixelStart - 8, this.canvasHeight / 2 - 15);
    } else {
      this.ctx.drawImage(exportBorderRight, pixelStart - 7, this.canvasHeight / 2 - 15);
    }
  }

  drawTimeLabel(time: number, color: string, posY: number) {
    this.ctx.font = '12px serif';
    const hhmmss = toHHMMSS(time);
    const bgWidth = Math.ceil(this.ctx.measureText(hhmmss).width) + 10;
    const bgHeight = 18;

    let x = (time - this.timeOffset) / this.pixelsToTime;
    if (x > -bgWidth && x < this.canvasWidth) {
      this.ctx.strokeStyle = color;
      this.ctx.beginPath();
      this.ctx.moveTo(x, 0);
      this.ctx.lineTo(x, this.canvasHeight);
      this.ctx.stroke();

      if (posY) {
        this.ctx.beginPath();
        this.ctx.moveTo(x - 4, posY);
        this.ctx.lineTo(x + 4, posY);
        this.ctx.stroke();
      }

      if (x > this.canvasWidth - bgWidth * 2) {
        x -= bgWidth;
      }

      const y = 0;

      this.ctx.fillStyle = color;
      this.ctx.fillRect(x, y, bgWidth, bgHeight);

      this.ctx.fillStyle = '#000000';
      this.ctx.fillText(hhmmss, x + 4, y + 13);
    }
  }

  drawEventHelper(text: string) {
    this.ctx.font =
      '14px BlinkMacSystemFont, \'-apple-system\', BlinkMacSystemFont, \'Segoe UI\', ' +
      'Roboto, Oxygen, Ubuntu, Cantarell, \'Open Sans\', \'Helvetica Neue\', Helvetica, Arial, sans-serif';
    const metrics = this.ctx.measureText(text);
    const bgWidth = Math.ceil(metrics.width) + 8;
    const bgHeight = 24;
    const x = this.canvasWidth / 2 - bgWidth / 2;
    const y = 20 + bgHeight / 2;

    this.ctx.fillStyle = this.backgroundColor;
    this.ctx.fillRect(x, y, bgWidth, bgHeight);

    this.ctx.fillStyle = this.textColor;
    this.ctx.fillText(text, x + 4, y + 17);
  }
}
