export default {
  line: 'line',
  information: 'information',
  create_lines: 'Create line',
  delete: 'Delete',
  deactivate: 'Deactivate',
  filter_events: 'Filter events',
  edit: 'Edit line',
  lines_no_items: 'Create your first line',
  id: 'ID',
  name: 'Name',
  name_placeholder: 'Enter name',
  no_camera: 'Camera not selected',
  description: 'Description',
  data_source_group: 'Data Source Group',
  data_source: 'Data Source',
  camera: 'Camera',
  video_archive: 'Video archive',
  video: 'Video',
  cameras: 'Cameras',
  videos: 'Videos',
  detectors: 'Detectors',
  bbox_intersection_point: 'Bounding box intersection point',
  not_found_items: 'Lines not found',
  has_backward_line_crossing: 'Has reverse crossing',
  with_backward_line_crossing: 'With reverse crossing',
  without_backward_line_crossing: 'Without reverse crossing',
  line_crossing_direction: 'Line crossing direction',
  line_crossing_direction_a_b: 'A→B',
  line_crossing_direction_b_a: 'B→A',
  line_crossing_direction_none: 'None',
  line_id: 'Line ID',
  enter_line_name: 'Enter line name',
  data_source_settings_warning:
    'Line crossing will not work because sending a track history is not enabled on the camera. Track history must be enabled for all enabled detectors',
  data_source_line_warning: 'Line crossing will not work without drawing a line in the "Line" tab',
  data_source_detectors_settings: 'You can change it in camera settings',
  bi: 'BI Analytics',
  line_attributes: 'Line crossing attributes',
  line_attributes_desc: 'The line label will be used to filter and group data in the BI system.',
  entrance: 'Entrance',
  entrance_name_desc: 'The name will be shown on the graphics in the BI system: "Line name", "Entrance name"',
  entrance_attributes: 'Attributes',
  entrance_attributes_desc: 'Attribute will be used to filter and group data in the BI system.',
  exit: 'Exit',
  exit_attributes: 'Attributes',
  exit_name_desc: 'The name will be shown on the graphics in the BI system: "Line name", "Exit name"',
  exit_attributes_desc: 'Attribute will be used to filter and group data in the BI system.',
  attributes_placeholder: 'Select attributes',
  roi_desc_1: 'To change direction, click on Ⓐ or Ⓑ on the picture.',
  roi_desc_2:
    'If you need to count people, vehicles inside the perimeter and work with data in the BI system, it is important to use the A→B direction for Entry and the B→A direction for Exit.'
};
