import { authModule } from '@/store/auth';

export const AuthType = {
  FACE: 'face',
  PASSWORD: 'password',
  FACE_OR_PASSWORD: 'face_or_password',
  FACE_AND_PASSWORD: 'face_and_password'
};

export const AuthState = {
  USER_FACE_VIDEO_RECORDING: 'user_face_video_recording',
  USER_FACE_IDENTIFICATION: 'user_face_identification',
  USER_FACE_IDENTIFIED: 'user_face_identified',
  IDLE: 'idle'
};

export function getAuthRequirements(authType: string) {
  return {
    faceRequired: [AuthType.FACE, AuthType.FACE_AND_PASSWORD].includes(authType),
    faceAllowed: [AuthType.FACE, AuthType.FACE_OR_PASSWORD, AuthType.FACE_AND_PASSWORD].includes(authType),
    faceEnough: [AuthType.FACE, AuthType.FACE_OR_PASSWORD].includes(authType),
    passwordEnough: [AuthType.PASSWORD, AuthType.FACE_OR_PASSWORD].includes(authType),
    passwordRequired: [AuthType.PASSWORD, AuthType.FACE_AND_PASSWORD].includes(authType),
    passwordAllowed: [AuthType.PASSWORD, AuthType.FACE_OR_PASSWORD, AuthType.FACE_AND_PASSWORD].includes(authType)
  };
}

export function hasSecureConnection() {
  return location.hostname === 'localhost' || location.protocol === 'https:';
}

export function getDefaultAuthPayload() {
  return { uuid: authModule.uuid, mobile: false, device_info: { user_agent: window.navigator.userAgent } };
}
