
import { NButton } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import { languageModule } from '@/store/languages';
import { alertModule } from '@/store/application/alert.module';
const t = languageModule.getTranslatedToken;

@Options({
  name: 'AlertNotification',
  components: {
    NButton
  }
})
export default class AlertNotification extends Vue {
  clickHandler() {
    alertModule.play();
  }

  tr(val: string): string {
    return t(val);
  }
}
