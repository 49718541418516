
import { defineComponent, toRefs } from 'vue';
import { arrayOf, bool, func, object } from 'vue-types';
import { NTableSection } from './NTableSection';
import { useTableViewModel } from './setup';
import {
  NTableBodyCell,
  NTableCell,
  NTableColumn,
  NTableContent,
  NTableEvent,
  NTableFootCell,
  NTableHeadCell,
  NTableSectionSchemaDecorator,
  NTableSelectionColumnFactory
} from './types';
import { createCommonTableSelectionColumn } from './utils';

export default defineComponent({
  name: 'NTable',
  components: { NTableSection },
  props: {
    columns: defineColumnsProp(),
    content: defineContentProp(),
    fixedFoot: defineFixedSectionProp(),
    fixedHead: defineFixedSectionProp(),
    bodyDecorators: defineSectionDecoratorsProp<NTableBodyCell<NTableContent>>(),
    footDecorators: defineSectionDecoratorsProp<NTableFootCell>(),
    headDecorators: defineSectionDecoratorsProp<NTableHeadCell>(),
    hoverable: defineHoverableProp(),
    selected: defineSelectedProp(),
    selections: defineSelectionsProp(),
    selectionsSchema: defineSelectionsSchemaProp()
  },
  emits: defineEmits(),
  setup: (props, { emit }) => useTableViewModel(toRefs(props), emit)
});

function defineColumnsProp() {
  return arrayOf(object<NTableColumn<NTableContent>>()).isRequired;
}

function defineContentProp() {
  return arrayOf(object<NTableContent>()).isRequired;
}

function defineEmits(): NTableEvent[] {
  return ['body-cell-click', 'foot-cell-click', 'head-cell-click', 'row-click', 'update:selected', 'update:selectedChanges'];
}

function defineFixedSectionProp() {
  return bool().def(false);
}

function defineSectionDecoratorsProp<C extends NTableCell>() {
  return arrayOf(func<NTableSectionSchemaDecorator<C>>()).def(() => []);
}

function defineHoverableProp() {
  return bool().def(false);
}

function defineSelectedProp() {
  return arrayOf(object<NTableContent>()).def(() => []);
}

function defineSelectionsProp() {
  return bool().def(false);
}

function defineSelectionsSchemaProp() {
  return func<NTableSelectionColumnFactory<NTableContent>>().def(createCommonTableSelectionColumn);
}
