import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cluster-item__content" }
const _hoisted_2 = { class: "cluster-item-tile__name label-s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cluster-item-tile", { selected: _ctx.selected }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('select')), ["stop"]))
  }, [
    (_ctx.thumbnail)
      ? (_openBlock(), _createBlock(_component_NThumbnail, {
          key: 0,
          modelValue: _ctx.thumbnail
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1)
    ])
  ], 2))
}