
// todo: controls click non block blur emit
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NIcon from '../icons/NIcon.vue';
import { debounce } from 'lodash';

type IResultNumber = number | null;

export enum NNumberSeparator {
  Comma = 'comma',
  Point = 'point'
}

export enum NInputNumberTextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

enum Events {
  Change = 'change',
  Input = 'update:modelValue'
}

function getLongestFractionalPartCount(items: (IResultNumber | string)[]) {
  let fractionalCounts = items.filter((v) => v !== null).map((v) => String(v).split('.')[1]?.length || 0);
  return Math.max(...fractionalCounts);
}

function getAllowedNumberChars(value: string, separator = '.') {
  let chars: string[] = [];
  let floatFound = false;
  let len = value.length;
  for (let i = len; i > 0; i--) {
    let char = value.charAt(i - 1);
    let isNegative = i === 1 && char === '-';
    let isDigit = char >= '0' && char <= '9';
    let isSeparator = char === ',' || char === '.';
    if (isNegative || isDigit) {
      chars.push(char);
    } else if (!floatFound && isSeparator) {
      floatFound = true;
      chars.push(separator);
    }
  }

  return chars.reverse();
}

@Options({
  name: 'NInputNumber',
  components: { NIcon }
})
export default class NInputNumber extends Vue {
  @Prop({ type: [Number, String] })
  readonly modelValue?: number | string | null;

  @Prop({ type: [Number, String], default: Number.MIN_SAFE_INTEGER })
  readonly min!: number | string;

  @Prop({ type: [Number, String], default: Number.MAX_SAFE_INTEGER })
  readonly max!: number | string;

  @Prop({ type: Boolean, default: false })
  readonly plain!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly clearable!: boolean;

  @Prop({
    type: [Number, String],
    default: 1,
    validator(value: number | string) {
      return +value > 0;
    }
  })
  readonly step!: number | string;

  @Prop({ type: [Number, String] })
  readonly precision?: number | string;

  @Prop({ type: String, default: '' })
  readonly units!: string;

  @Prop({ type: String, default: '' })
  readonly placeholder!: string;

  @Prop({ type: String, default: '' })
  readonly name!: string;

  @Prop({ type: Boolean, default: true })
  readonly controls!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly autofocus!: boolean;

  @Prop({ type: String })
  readonly accesskey?: string;

  @Prop({ type: String, default: NInputNumberTextAlign.Left })
  readonly textAlign!: NInputNumberTextAlign;

  @Prop({ type: Boolean, default: true })
  readonly selectOnFocus!: boolean;

  @Prop({ type: String, default: NNumberSeparator.Point })
  readonly separator!: NNumberSeparator;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  @Prop({ type: Number, default: 600 })
  readonly debounceTimeout!: number;

  userInput = '';
  currentValue: IResultNumber = null;
  eventProxyEnabled = true;

  changeTimeout = 0;
  changeInterval = 0;
  changeValue = 0;

  get isModelValueDefined() {
    return this.modelValue !== undefined && this.modelValue !== null;
  }

  get inputModelValue() {
    return this.userInput;
  }

  set inputModelValue(value) {
    this.userInput = value;
    this.emitDebounce(Events.Input, true);
  }

  get cssClass() {
    let base = 'n-input-number';
    return {
      [base]: true,
      [`${base}__pl_high`]: this.controls,
      [`${base}__pr_low`]: this.units,
      [`${base}_disabled`]: this.disabled,
      [`${base}_postfix`]: this.clearable,
      [`${base}_plain`]: this.plain
    };
  }

  get inputElement(): HTMLInputElement | null {
    return (this.$refs.input as HTMLInputElement) || null;
  }

  get separatorChar() {
    return this.separator === NNumberSeparator.Comma ? ',' : '.';
  }

  get stepNumber() {
    let v = Number(this.step);
    if (v === 0 || Number.isNaN(v)) {
      throw new Error(`Step value '${this.step}' has wrong format. Expect valid non zero number.`);
    }
    return v;
  }

  get maxNumber() {
    const max = Number(this.max);
    return Number.isNaN(max) ? Number.MAX_SAFE_INTEGER : Math.min(max, Number.MAX_SAFE_INTEGER);
  }

  get minNumber() {
    const min = Number(this.min);
    return Number.isNaN(min) ? Number.MIN_SAFE_INTEGER : Math.max(min, Number.MIN_SAFE_INTEGER);
  }

  get increaseDisabled() {
    return this.currentValue === this.maxNumber;
  }

  get decreaseDisabled() {
    return this.currentValue === this.minNumber;
  }

  get userInputClear() {
    return getAllowedNumberChars(this.userInput, this.separatorChar).join('');
  }

  get userInputAsNumber(): IResultNumber {
    if (this.userInput === '' || this.userInput === '-') return null;

    const chars = getAllowedNumberChars(this.userInput);
    const hasOnlyDot = chars[0] === '.' && chars.length === 1;
    return chars.length > 0 && !hasOnlyDot ? Number(chars.join('')) : null;
  }

  get stepSignificantCount() {
    return getLongestFractionalPartCount([this.step, this.currentValue]);
  }

  @Watch('currentValue')
  currentValueWatcher() {
    this.emit(Events.Input);
  }

  @Watch('modelValue', { immediate: true })
  initValue(v: number | string | undefined | null) {
    if (v === undefined || v === null) {
      this.inputModelValue = '';
      return;
    }

    let numberValue = Number(v);
    if (Number.isNaN(numberValue)) {
      throw new Error(`Passed value '${v}' is not a number!`);
    }
    numberValue = this.castToRange(numberValue);

    this.inputModelValue = numberValue.toString();
    this.currentValue = numberValue;
  }

  beforeUnmount() {
    clearTimeout(this.changeTimeout);
    clearInterval(this.changeInterval);
  }

  eventProxyHandler(e: Event) {
    this.eventProxyEnabled && this.$emit(e.type, e);
  }

  focus() {
    this.selectOnFocus ? this.inputElement?.select() : this.inputElement?.focus();
  }

  changeHandler() {
    this.emit(Events.Change, true);
  }

  controlsChangeStart(increase = true) {
    if (this.disabled || this.changeInterval > 0) return;
    if (increase ? this.increaseDisabled : this.decreaseDisabled) return;
    this.eventProxyEnabled = false;

    this.emitControlsChange(increase);

    if (this.changeTimeout) return;
    this.changeTimeout = window.setTimeout(() => {
      if (this.changeInterval) return;
      this.changeInterval = window.setInterval(this.emitControlsChange.bind(this, increase), 100);
    }, 600);
  }

  emitControlsChange(increase: boolean) {
    let current = this.currentValue === null ? 0 : this.currentValue;
    let delta = this.stepNumber * ++this.changeValue;
    this.userInput = (current + (increase ? delta : -delta)).toFixed(this.stepSignificantCount).toString();
    this.emit(Events.Input);
  }

  controlsChangeStop() {
    if (this.changeInterval || this.changeTimeout) {
      clearTimeout(this.changeTimeout);
      clearInterval(this.changeInterval);
      this.changeTimeout = this.changeInterval = this.changeValue = 0;
      this.emit(Events.Input);
      this.emit(Events.Change, true);
      this.focus();
      this.eventProxyEnabled = true;
    }
  }

  emitDebounce = debounce(this.emit, this.debounceTimeout);

  emit(type: Events = Events.Change, skipUniqueCheck = false) {
    if (this.disabled) return;
    let userInput = this.userInputClear;
    let v = this.userInputAsNumber;
    if (v !== null && this.isOutOfRange(v)) {
      v = this.castToRange(v);
      userInput = v.toString();
    }

    const isUniqueValue = v !== this.currentValue;
    if (skipUniqueCheck || isUniqueValue) {
      this.currentValue = v;
      this.$emit(type, v);
    }

    this.userInput = userInput;
  }

  isOutOfRange(v: number) {
    return v > this.maxNumber || v < this.minNumber;
  }

  castToRange(v: number) {
    if (v > this.maxNumber) return this.maxNumber;
    if (v < this.minNumber) return this.minNumber;
    return v;
  }

  clear() {
    this.$emit('update:modelValue', null);
    this.$emit('change', null);
  }
}
