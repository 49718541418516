
import { Camera } from '@/api';
import NHint from '@/uikit/hint/NHint.vue';
import { defineComponent } from 'vue';
import { object } from 'vue-types';
import CameraStatusBrief from './CameraStatusBrief.vue';
import CameraStatusDetails from '@/components/common/CameraStatusDetails.vue';
import type { CameraStatusDetail } from './types';
import {
  computeCameraStatusDetailFromFramesDropped,
  computeCameraStatusDetailFromJobStarts,
  computeCameraStatusDetailFromObjectsNotPosted,
  computeCameraStatusDetailFromObjectsPosted,
  computeCameraStatusDetailFromProcessDuration,
  computeCameraStatusDetailFromStatus
} from './utils';

export default defineComponent({
  name: 'CameraStatus',
  components: {
    CameraStatusBrief,
    CameraStatusDetails,
    NHint
  },
  props: {
    camera: object<Camera>().isRequired
  },
  computed: {
    details(): CameraStatusDetail[] {
      return [
        { label: this.$t('ds.status'), value: computeCameraStatusDetailFromStatus(this.camera.health_status) },
        {
          label: this.$t('ds.process_duration'),
          value: computeCameraStatusDetailFromProcessDuration(this.camera.health_status)
        },
        {
          label: this.$t('ds.objects_posted'),
          value: computeCameraStatusDetailFromObjectsPosted(this.camera.health_status)
        },
        {
          label: this.$t('ds.objects_not_posted'),
          value: computeCameraStatusDetailFromObjectsNotPosted(this.camera.health_status)
        },
        {
          label: this.$t('ds.frames_dropped'),
          value: computeCameraStatusDetailFromFramesDropped(this.camera.health_status)
        },
        { label: this.$t('ds.job_starts'), value: computeCameraStatusDetailFromJobStarts(this.camera.health_status) }
      ];
    },
    statusDescription() {
      return this.camera.health_status.code_desc;
    }
  }
});
