
import { Report } from '@/api';
import { defineComponent, PropType } from 'vue';
import { ReportsPageTableColumnLabelMono } from './common';
import { formatReportModifiedDate } from './utils';

export default defineComponent({
  name: 'ReportsPageTableColumnModifiedDate',
  components: { ReportsPageTableColumnLabelMono },
  props: {
    modifiedDate: {
      required: true,
      type: String as PropType<Report['modified_date']>
    }
  },
  computed: {
    content() {
      return formatReportModifiedDate(this.parsedModifiedDate);
    },
    parsedModifiedDate() {
      return new Date(this.modifiedDate);
    }
  }
});
