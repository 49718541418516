import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "entity-blank" }
const _hoisted_2 = { class: "label-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThemeImage = _resolveComponent("NThemeImage")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NThemeImage, { "css-var": _ctx.imageToken }, null, 8, ["css-var"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1),
    (_ctx.isEmpty && _ctx.hasCreate)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 0,
          class: "entity-blank__add",
          onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('create'))),
          icon: _ctx.createIcon,
          size: "big"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.createLabel), 1)
          ]),
          _: 1
        }, 8, ["icon"]))
      : _createCommentVNode("", true)
  ]))
}