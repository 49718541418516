import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "label-m user-menu__avatar" }
const _hoisted_2 = { class: "user-menu__user" }
const _hoisted_3 = {
  key: 0,
  class: "label-m user-menu__avatar"
}
const _hoisted_4 = { class: "heading-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createBlock(_component_NDropdown, { placement: "bottom-end" }, {
    toggle: _withCtx(({ toggle }) => [
      _createVNode(_component_NButton, {
        round: "",
        type: "text",
        class: "user-menu__toggle-button",
        onClick: toggle
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.firstNameCharacter), 1)
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("li", _hoisted_2, [
        (_ctx.firstNameCharacter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.firstNameCharacter), 1))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currentUser), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, ({ label, name, icon }) => {
        return (_openBlock(), _createBlock(_component_NDropdownItem, {
          key: name,
          icon: icon,
          onClick: () => _ctx.actionHandler(name)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(label), 1)
          ]),
          _: 2
        }, 1032, ["icon", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}