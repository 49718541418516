import { BodyEvent, CarEpisode, CarEvent, FaceEvent, HumanEpisode } from '@/api';

export type EventOrEpisode = AnyEpisode | AnyEvent;
export type AnyEpisode = HumanEpisode | CarEpisode;
export type AnyEvent = FaceEvent | BodyEvent | CarEvent;

export function isHumanEpisode(item: EventOrEpisode): item is HumanEpisode {
  return 'best_face_event' in item || 'best_body_event' in item;
}

export function isCarEpisode(item: EventOrEpisode): item is CarEpisode {
  return 'best_car_event' in item;
}

export type EventOpenedItem = typeof EventOpenedItems[keyof typeof EventOpenedItems];

export const EventOpenedItems = {
  Card: 'card',
  Item: 'item',
  NotSelected: ''
} as const;

export interface ListItem {
  label: string;
  name: string;
  icon?: string;
}
