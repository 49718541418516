
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NTable from '@/uikit/table-v2/NTable.vue';
import { VideoArchive } from '@/api';
import { NTableColumn } from '@/uikit/table-v2';
import NText from '@/uikit/text/NText.vue';
import { actionHandler, convertToMsbItem } from '@/store/data/ActionHandler';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { createIdTableSelectionColumn } from '@/uikit/table-v2/utils';
import { websocketModule } from '@/store/ws/websocket.module';
import { get, merge } from 'lodash';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import { Action, NButton } from '@/uikit';
import { actionsModule } from '@/store/data/ActionsModule';
import { ModelAclResult } from '@/store/acl/types';
import { formatFileSize } from '@/common/filters';
import NTableProgressCell from '@/uikit/table/NTableProgressCell.vue';
import { configModule } from '@/store/config';
import { ObjectsType, ObjectsTypesMap, ObjectType } from '@/store/application/data.assets';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';
import { RouterModule } from '@/store/router';
import { globalEventModule } from '@/store/global-event';
import { dataSourceVideosModule } from '@/store/videos';
import { HealthStatus } from '@/definitions/app/health-status';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import NButtonModel from '@/uikit/buttons/NButtonModel.vue';

@Options({
  name: 'DataSourcesVideosTable',
  components: { NTable, NButton, NTableProgressCell },
  emits: ['select']
})
export default class DataSourcesVideosTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly items!: VideoArchive[];

  @Prop({ type: Array, default: () => [] })
  readonly selectedItems!: number[];

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  createIdTableSelectionColumn = createIdTableSelectionColumn;

  get displayFaceClusters() {
    return configModule.config.extra_options?.includes('display_face_clusters_in_videos_table') ?? false;
  }

  get availableObjectsMap() {
    return dataAssetsModule.availableObjectsMap;
  }

  get columns(): NTableColumn<VideoArchive>[] {
    const baseColumns: NTableColumn<VideoArchive>[] = [
      {
        width: 'max-content',
        head: this.$t('ds.id'),
        body: ({ model }) => model.id
      },
      {
        width: 120,
        head: this.$t('ds.image'),
        body: ({ model }) => {
          const props = { width: 100, displayWidth: '100px', modelValue: model.screenshot, hasPlay: false };
          return <CameraScreenshot {...props} />;
        }
      },
      {
        width: 'minmax(160px, 1fr)',
        head: this.$t('ds.name'),
        body: ({ model }) => {
          const nameProps = {
            modelValue: model.name,
            class: 'datasource-videos-table__description-name',
            onClick: this.handleNameClick.bind(this, model)
          };
          return (
            <div class="datasource-videos-table__description">
              <NText {...nameProps} />
              <NText>{formatFileSize(model.file_size)}</NText>
            </div>
          );
        }
      }
    ];

    const progressColumn: NTableColumn<VideoArchive> = {
      width: 180,
      head: this.$t('ds.status'),
      body: ({ model }) => <NTableProgressCell {...this.statusPropsHandler(model)}></NTableProgressCell>
    };

    const faceClusterStats: NTableColumn<VideoArchive> = {
      width: 80,
      head: this.$t('ds.faces', 'f'),
      body: ({ model }) => <NText>{model.face_cluster_count}</NText>
    };

    const faceStats: NTableColumn<VideoArchive> = {
      width: 80,
      head: this.$t('ds.faces', 'f'),
      body: ({ model }) => {
        const props = {
          modelValue: model.health_status?.statistic?.objects?.face?.posted ?? 0,
          'onUpdate:modelValue': this.navigateToEvents.bind(this, model, ObjectsTypesMap.Faces),
          type: 'link'
        };

        return this.availableObjectsMap.face ? <NButtonModel {...props} /> : <NText {...props} />;
      }
    };

    const bodyStats: NTableColumn<VideoArchive> = {
      width: 80,
      head: this.$t('ds.bodies', 'f'),
      body: ({ model }) => {
        const props = {
          modelValue: model.health_status?.statistic?.objects?.body?.posted ?? 0,
          'onUpdate:modelValue': this.navigateToEvents.bind(this, model, ObjectsTypesMap.Bodies),
          type: 'link'
        };

        return this.availableObjectsMap.body ? <NButtonModel {...props} /> : <NText {...props} />;
      }
    };

    const carsStats: NTableColumn<VideoArchive> = {
      width: 80,
      head: this.$t('ds.cars', 'f'),
      body: ({ model }) => {
        const props = {
          modelValue: model.health_status?.statistic?.objects?.car?.posted ?? 0,
          'onUpdate:modelValue': this.navigateToEvents.bind(this, model, ObjectsTypesMap.Cars),
          type: 'link'
        };

        return this.availableObjectsMap.car ? <NButtonModel {...props} /> : <NText {...props} />;
      }
    };

    const actionsColumn: NTableColumn<VideoArchive> = {
      width: 80,
      head: '',
      body: ({ model }) => {
        const dropdownProps = {
          actions: this.getActions(model),
          placement: 'left-start',
          onAction: (action: ItemsActionName) => this.actionHandler(action, model)
        };
        return (
          <div class="datasource-videos-table__actions-cell">
            <ActionsDropdown class="datasource-videos-table__actions" {...dropdownProps} />
          </div>
        );
      }
    };

    const statColumns = this.displayFaceClusters ? [faceClusterStats] : [faceStats, bodyStats, carsStats];

    return [...baseColumns, progressColumn, ...statColumns, actionsColumn];
  }

  get websocketModule() {
    return websocketModule;
  }

  @Watch('websocketModule.videoUpdated')
  updateVideoArchive(updatedItem: any) {
    const item = this.items.find((v) => v.id === updatedItem.video_archive_id) as VideoArchive;
    const finished = updatedItem?.health_status?.status === HealthStatus.Completed;
    if (item) {
      const localItem = { ...updatedItem, finished };
      const remoteItem = { id: item.id, health_status: item.health_status, finished };
      console.log('Setup new video item', localItem, remoteItem);
      merge(item, localItem);
      globalEventModule.sendUpdate(MultisidebarItemTypes.Videos, remoteItem);
    }
  }

  statusPropsHandler(item: any) {
    let result: any = dataSourceVideosModule.getStatusInfo(get(item, 'health_status.status'), item.progress, item.__upload_progress, item.finished);
    if (result.i18n_label) result.label = this.$t(result.i18n_label, 'f');
    return result;
  }

  handleNameClick(model: VideoArchive) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Videos, rawItem: model });
  }

  dispatchSelect(selected: VideoArchive[]) {
    this.$emit('select', selected);
  }

  async actionHandler(actionName: ItemsActionName, item: VideoArchive) {
    const requiredSaveActionItems: ItemsActionName[] = [
      ItemsActionNames.CameraResetAll,
      ItemsActionNames.CameraResetFull,
      ItemsActionNames.CameraResetAdvanced,
      ItemsActionNames.CameraResetMap,
      ItemsActionNames.CameraResetDetectors,
      ItemsActionNames.CameraResetZone
    ];

    const payload = await convertToMsbItem({ type: MultisidebarItemTypes.Videos, rawItem: item });
    const executeSaveAction = requiredSaveActionItems.includes(actionName);
    await actionHandler.run(actionName, payload);
    if (executeSaveAction) await actionHandler.run(ItemsActionNames.Save, payload);
  }

  getActions(item: VideoArchive): Action[] {
    return actionsModule
      .getItemActions(this.modelAcl, null, {
        hasDeleteFile: !!item.file_size,
        hasDelete: true,
        hasVideoReset: true,
        hasProcess: item?.health_status?.status !== HealthStatus.InProgress,
        hasStopProcess: item?.health_status?.status !== HealthStatus.Disabled
      })
      .map(actionsModule.computeActionByName);
  }

  navigateToEvents(video: VideoArchive, objectsType: ObjectsType) {
    RouterModule.navigateToEvents(objectsType, { video_archive: video.id });
  }
}
