import { get, set } from 'lodash';
import { getLogger } from 'loglevel';

export interface ILocalStorageSyncItem {
  instance: any;
  tokens: string[];
  name: string;
}

export const LocalStorage = window.localStorage;
const DefaultKey = 'default';
const LastKeyName = 'lastKey';

const logger = getLogger('[local-storage]');
logger.setLevel('warn');

export class LocalStorageModule {
  protected key: string = DefaultKey;
  protected items: ILocalStorageSyncItem[] = [];

  setLastKey() {
    this.setKey(LocalStorage[LastKeyName] || DefaultKey);
  }

  setKey(value?: string): boolean {
    const result = value ? value : DefaultKey;
    if (result === this.key) return false;
    this.key = result;
    LocalStorage[LastKeyName] = result;
    return true;
  }

  getKey() {
    return this.key || DefaultKey;
  }

  getStorageName(value: string): string {
    return this.getKey() + '__' + value;
  }

  registerInstance(item: ILocalStorageSyncItem, sync = true) {
    this.items.push(item);
    if (sync) this.syncFromStorage(item);
  }

  syncToStorageByName(name: string) {
    logger.log('Sync to storage ', this.getStorageName(name), name);
    const item = this.items.find((v) => v.name === name);
    if (item) this.syncToStorage(item);
    else logger.warn(`[syncToStorage] instance byName ${name} not found`);
  }

  syncFromStorageByName(name: string) {
    const item = this.items.find((v) => v.name === name);
    if (item) this.syncFromStorage(item);
    else logger.warn(`[syncFromStorage(...)] instance byName ${name} not found`);
  }

  syncToStorage(item: ILocalStorageSyncItem) {
    if (!LocalStorage) return console.warn('[syncToLocalStorage(...)] localStorage not found');
    logger.log('Sync tokens', item.name, item.tokens);
    const r = item.tokens.reduce((m: Record<string, any>, v: string) => {
      m[v] = get(item.instance, v);
      return m;
    }, {});
    const storageName = this.getStorageName(item.name);
    logger.debug('Sync tokens data', storageName, r);
    LocalStorage[storageName] = JSON.stringify(r);
  }

  syncFromStorage(item: ILocalStorageSyncItem) {
    const storageName = this.getStorageName(item.name);
    logger.log('Sync from storage ', storageName, LocalStorage[storageName]);
    if (!(LocalStorage && LocalStorage[storageName])) return console.warn('[syncToLocalStorage] localStorage not found or not set');
    let localStorageObject = JSON.parse(LocalStorage[storageName]);
    Object.keys(localStorageObject).forEach((key: string) => {
      set(item.instance, key, localStorageObject[key]);
    });
  }

  syncAllFromStorage() {
    logger.log('Sync all from storage');
    this.items.forEach((value) => this.syncFromStorage(value));
  }
}

export const localStorageModule = new LocalStorageModule();
