import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInputNumber = _resolveComponent("NInputNumber")!

  return (_openBlock(), _createBlock(_component_NInputNumber, {
    class: "filter-confidence",
    clearable: "",
    "onUpdate:modelValue": _ctx.handleUpdate,
    "model-value": _ctx.modelValue,
    step: 0.02,
    min: 0,
    max: 1,
    "text-align": "right"
  }, null, 8, ["onUpdate:modelValue", "model-value", "step"]))
}