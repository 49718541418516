import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "card-or-unmatched__unmatched heading-3xl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfidenceDelimiter = _resolveComponent("ConfidenceDelimiter")!
  const _component_CardItemRow = _resolveComponent("CardItemRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-or-unmatched", { deleted: _ctx.deleted }])
  }, [
    (_ctx.item)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.confidence)
            ? (_openBlock(), _createBlock(_component_ConfidenceDelimiter, {
                key: 0,
                confidence: _ctx.confidence
              }, null, 8, ["confidence"]))
            : _createCommentVNode("", true),
          _createVNode(_component_CardItemRow, {
            type: "humans",
            item: _ctx.item,
            confidence: _ctx.confidence,
            closeable: false,
            onAction: _ctx.handleAction,
            "card-navigate-active": "",
            "show-fullframe-active": ""
          }, null, 8, ["item", "confidence", "onAction"])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('external_search.unmatched', 'u')), 1))
  ], 2))
}