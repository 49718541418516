export default {
  looks_like_confidence_desc: 'По убыванию степени схожести',
  looks_like_confidence_asc: 'По возрастанию степени схожести',
  id_desc: 'По убыванию ID',
  id_asc: 'По возрастанию ID',
  created_date_desc: 'Сначала самые новые',
  created_date_asc: 'Сначала самые старые',
  external_timestamp_desc: 'Сначала самые новые',
  external_timestamp_asc: 'Сначала самые старые',
  modified_date_desc: 'Сначала самые новые изменения',
  modified_date_asc: 'Сначала самые старые изменения',
  name_desc: 'По имени от Я до А',
  name_asc: 'По имени от А до Я',
  confidence_desc: 'По убыванию степени схожести',
  confidence_asc: 'По возрастанию степени схожести'
};
