import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!

  return (_ctx.optionalModelValue)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.doubleThumbnailClasses)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.classes)
        }, [
          _createVNode(_component_NThumbnail, {
            "model-value": _ctx.modelValue,
            size: _ctx.size,
            actions: _ctx.actions,
            defaultAction: _ctx.defaultAction,
            onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select'))),
            onAction: _ctx.emitAction,
            onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hovered = true)),
            onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hovered = false))
          }, null, 8, ["model-value", "size", "actions", "defaultAction", "onAction"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.optionalClasses)
        }, [
          _createVNode(_component_NThumbnail, {
            "model-value": _ctx.optionalModelValue,
            size: _ctx.size,
            fit: "contain",
            onMouseenter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.optionalHovered = true)),
            onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.optionalHovered = false))
          }, null, 8, ["model-value", "size"])
        ], 2)
      ], 2))
    : (_openBlock(), _createBlock(_component_NThumbnail, {
        key: 1,
        "model-value": _ctx.modelValue,
        size: _ctx.size,
        actions: _ctx.actions,
        defaultAction: _ctx.defaultAction,
        onAction: _ctx.emitAction,
        onSelect: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('select')))
      }, null, 8, ["model-value", "size", "actions", "defaultAction", "onAction"]))
}