import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!

  return (_openBlock(), _createBlock(_component_NButtonGroup, {
    items: _ctx.dataAssetsModule.displayTypes,
    modelValue: _ctx.displayType,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayType) = $event)),
    "extra-paddings": ""
  }, null, 8, ["items", "modelValue"]))
}