import { License } from '@/pages/license';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { reactive } from 'vue';

export const LicenseFeatures = {
  LineCrossing: 'line-crossing',
  BiAnalytics: 'bi-analytics'
} as const;

export type LicenseFeatureType = typeof LicenseFeatures[keyof typeof LicenseFeatures];

export class LicenseModule {
  static Paths = {
    License: '/ntls/license.json'
  } as const;

  item: License | null = null;
  error?: Error;

  get features(): Record<LicenseFeatureType, boolean> {
    return {
      [LicenseFeatures.LineCrossing]: !!this.item?.products?.multi?.extra?.[LicenseFeatures.LineCrossing],
      [LicenseFeatures.BiAnalytics]: !!this.item?.products?.multi?.extra?.[LicenseFeatures.BiAnalytics],
    };
  }

  async load() {
    const axios = dataServiceFactory.getAxiosInstance();
    try {
      const result = await axios.get(LicenseModule.Paths.License);
      this.item = result.data as License;
    } catch (e: unknown) {
      this.error = e as Error;
    }
  }
}

export const licenseModule = reactive(new LicenseModule());
