import get from 'lodash/get';
import { CommonCluster } from '@/store/clusters/types';

export const facesFeatures = ['emotions', 'gender', 'glasses', 'beard', 'liveness', 'look', 'medmask'];
export const bodyFeatures = [
  'headwear',
  'upper_clothes',
  'detailed_upper_clothes',
  'top_color',
  'lower_clothes',
  'bottom_color',
  'bag_back',
  'bag_hand',
  'bag_ground',
  'vest_type',
  'helmet_type',
  'gender',
  'age_group'
];

export const carsFeatures = [
  'color',
  'body',
  'make',
  'model',
  'license_plate_number',
  'license_plate_country',
  'license_plate_number_color',
  'license_plate_region',
  'special_vehicle_type',
  'category',
  'weight_type',
  'orientation'
];

export function getSatisfyEventFeatures(event: any, features: string[], filter: any): boolean {
  return features.reduce<boolean>((m, featureFilterName) => {
    const filterValue = filter[featureFilterName],
      isArrayFilterValue = Array.isArray(filterValue),
      hasFilterValue = filterValue && filterValue.length;
    if (!m || !hasFilterValue) return m;
    const featureValue = get(event, `features.${featureFilterName}.name`);
    return isArrayFilterValue ? !!filterValue.find((i: string) => i === featureValue) : featureValue.indexOf(filterValue) > -1;
  }, true);
}

export function getSatisfyClusterFeatures(cluster: CommonCluster, features: string[], filter: any) {
  return features.reduce<boolean>((m, featureFilterName) => {
    const filterValue = filter[featureFilterName],
      isArrayFilterValue = Array.isArray(filterValue),
      hasFilterValue = filterValue && filterValue.length;
    if (!m || !hasFilterValue) return m;
    const featureValue = get(cluster, featureFilterName);
    return isArrayFilterValue ? !!filterValue.find((i: string) => i === featureValue) : featureValue.indexOf(filterValue) > -1;
  }, true);
}
