import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "verify__dnd"
}
const _hoisted_2 = {
  key: 1,
  class: "verify__images"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NAlertBadge = _resolveComponent("NAlertBadge")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NAttachments = _resolveComponent("NAttachments")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_VerifyImages = _resolveComponent("VerifyImages")!
  const _component_VerifyResults = _resolveComponent("VerifyResults")!
  const _component_VerifyLayout = _resolveComponent("VerifyLayout")!

  return (_openBlock(), _createBlock(_component_VerifyLayout, {
    class: _normalizeClass({ 'verify-layout_without-result': !_ctx.canShowResultBlock })
  }, {
    form: _withCtx(() => [
      (!_ctx.hasDetectorConfig)
        ? (_openBlock(), _createBlock(_component_NAlertBadge, {
            key: 0,
            class: "verify__detect-config-error",
            type: "negative"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('verify.detect_config_error')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_NForm, {
        layout: _ctx.schema,
        model: _ctx.model,
        "inline-form": "",
        class: "verify__form",
        onChangeFirstFile: _ctx.setFirstFile,
        onChangeSecondFile: _ctx.setSecondFile,
        onFirstEventUrl: _ctx.firstEventHandler,
        onSecondEventUrl: _ctx.secondEventHandler
      }, null, 8, ["layout", "model", "onChangeFirstFile", "onChangeSecondFile", "onFirstEventUrl", "onSecondEventUrl"])
    ]),
    images: _withCtx(() => [
      (_ctx.isEmptyFiles)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_NAttachments, {
              i18n: _ctx.attachmentsI18n,
              onAttach: _ctx.uploadHandler,
              "disallow-attach": "",
              drag: "",
              "hide-amount": "",
              "hide-attachments": "",
              multiple: ""
            }, null, 8, ["i18n", "onAttach"]),
            (_ctx.module.loading)
              ? (_openBlock(), _createBlock(_component_NLoadingCircle, {
                  key: 0,
                  class: "verify__loading"
                }))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_VerifyImages, {
              key: "first",
              "source-file": _ctx.model[0].file,
              onSelectBbox: _ctx.selectFirstBbox,
              loading: _ctx.module.loading
            }, null, 8, ["source-file", "onSelectBbox", "loading"]),
            _createVNode(_component_VerifyImages, {
              key: "second",
              "source-file": _ctx.model[1].file,
              onSelectBbox: _ctx.selectSecondBbox,
              loading: _ctx.module.loading
            }, null, 8, ["source-file", "onSelectBbox", "loading"])
          ]))
    ]),
    results: _withCtx(() => [
      (_ctx.canShowResultBlock)
        ? (_openBlock(), _createBlock(_component_VerifyResults, {
            key: 0,
            confidence: _ctx.results.confidence,
            object: _ctx.results.object,
            "can-verify": _ctx.module.canVerify
          }, null, 8, ["confidence", "object", "can-verify"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}