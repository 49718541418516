export default {
  male: 'мужчин',
  female: 'женщин',
  male_pf: '{ count, plural, one {# мужчина} other {# мужчин} few {# мужчин}}',
  female_pf: '{ count, plural, one {# женщина} other {# женщины} few {# женщин}}',
  returning: 'вернувшихся',
  new: 'новых',
  records_pf: '{ count, plural, one {# запись} other {# записи} few {# записей}}',
  age_pf: '{ count, plural, one {# год} other {# года} few {# лет}}',
  visitors_pf: '{ count, plural, one {# посетитель} other {# посетителя} few {# посетителей}}',
  visits_pf: '{ count, plural, one {# посещение} other {# посещения} few {# посещений}}',
  new_visits_pf: '{ count, plural, one {# новое посещение} other {# новых посещений} few {# новых посещений}}',
  returning_visits_pf: '{ count, plural, one {# повторное посещение} other {# повторных посещений} few {# повторных посещений}}',
  precision: 'шаг шкалы (масштаб)',
  return_interval: 'минимальное время между посещениями',
  returns_number: 'количество возвращений',
  visitors: 'посетители',
  visits: 'посещения',
  enable: 'включить',
  gender: 'Пол',
  average_age: 'Средний возраст',
  cameras: 'камеры',
  total: 'всего',
  unique: 'уникальные'
};
