
import { Camera } from '@/api';
import { Options, Prop, Vue } from 'vue-property-decorator';
import { dragCameraId } from '../../utils';
import { VideoWallCameraStatus } from '../VideoWallCameraStatus';

@Options({
  components: { VideoWallCameraStatus }
})
export default class VideoWallCameraListGroupItem extends Vue {
  @Prop({ required: true, type: Object })
  readonly camera!: Camera;

  handleDragStartEvent(event: DragEvent): void {
    dragCameraId(event, this.camera.id);
  }
}
