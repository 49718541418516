/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { LanguageEnum } from './LanguageEnum';
export type User = {
    readonly id: number;
    active: boolean;
    readonly created_date: string | null
    groups?: Array<number>;
    /**
     * Object modification date
     */
    readonly modified_date: string | null;
    readonly permissions: Array<string>;
    real_name: string;
    name: string;
    /**
     * Extended description of the user, up to 2048 chars
     */
    comment?: string;
    readonly camera_group_permissions: Record<string, string>;
    readonly watch_list_permissions: Record<string, string>;
    readonly group_permissions: Record<string, string>;
    primary_group: number;
    /**
     * Backend message language for the user
     */
    language?: LanguageEnum;
    /**
     * User photo is provided
     */
    readonly has_face: boolean;
    face_cover?: string | null;
    readonly ad_user: boolean;
};
export const EmptyUser: User = {
    id: 0,
    active: true,
    created_date: null,
    groups: [],
    /**
     * Object modification date
     */
    modified_date: null,
    permissions: [],
    real_name: '',
    name: '',
    /**
     * Extended description of the user, up to 2048 chars
     */
    comment: '',
    camera_group_permissions: {},
    watch_list_permissions: {},
    group_permissions: {},
    primary_group: 0,
    /**
     * Backend message language for the user
     */
    language: undefined,
    /**
     * User photo is provided
     */
    has_face: false,
    face_cover: null,
    ad_user: false,
};
