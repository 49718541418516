
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'GroupItem'
})
export default class GroupItem extends Vue {
  @Prop({ type: String, required: true })
  name!: string;

  handleClick() {
    this.$emit('update:modelValue', this.name);
  }
}
