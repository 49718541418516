/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ClusterizationJob = {
    readonly id: number;
    /**
     * Clusterization creation date
     */
    created_date?: string | null;
    /**
     * Clusterization completion date
     */
    finished_date?: string | null;
    /**
     * Clusterization status
     */
    status: string;
    /**
     * Traceback of exception; if job failed
     */
    traceback?: string | null;
};
export const EmptyClusterizationJob: ClusterizationJob = {
    id: 0,
    /**
     * Clusterization creation date
     */
    created_date: '',
    /**
     * Clusterization completion date
     */
    finished_date: '',
    /**
     * Clusterization status
     */
    status: '',
    /**
     * Traceback of exception; if job failed
     */
    traceback: '',
};
