export default class Stopwatch {
  active = false;
  paused = false;
  startTime = 0;
  pauseTime = 0;
  start() {
    if (!this.active) {
      this.startTime = new Date().getTime() / 1000;
      this.active = true;
    }
    if (this.paused) {
      const nowTime = new Date().getTime() / 1000;
      this.startTime += nowTime - this.pauseTime;
      this.paused = false;
    }
  }
  pause() {
    this.pauseTime = new Date().getTime() / 1000;
    this.paused = true;
  }
  elapsed() {
    const nowTime = new Date().getTime() / 1000;
    return nowTime - this.startTime;
  }
}
