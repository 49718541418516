import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "video-player-export" }
const _hoisted_2 = { class: "video-player-export__info" }
const _hoisted_3 = { class: "video-player-export__info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isDownloading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('video_player.downloaded')) + ": " + _toDisplayString(_ctx.loadedBytesFormated), 1),
          _createVNode(_component_NButton, {
            type: "secondary",
            onClick: _ctx.cancelDownload
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('video_player.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('video_player.time_range')) + ": " + _toDisplayString(_ctx.formatedTimeFrom) + " - " + _toDisplayString(_ctx.formatedTimeTo) + " (" + _toDisplayString(_ctx.formatedTotalTime) + ")", 1),
          _createVNode(_component_NButton, { onClick: _ctx.download }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('video_player.download')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_NButton, {
            type: "secondary",
            onClick: _ctx.cancelPanel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('video_player.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 64))
  ]))
}