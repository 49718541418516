/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Counter } from '../models/Counter';
import type { CounterRequest } from '../models/CounterRequest';
import type { CountResponse } from '../models/CountResponse';
import type { PaginatedCounterList } from '../models/PaginatedCounterList';
import type { PatchedCounterRequest } from '../models/PatchedCounterRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class CountersService {
    /**
     * List counters
     * This method retrieves the list of counters.
     * @param active Select only active counters (`true`) or inactive (`false`) counters
     * @param calibrationStatus Select counters with this `proximity_calibration_status`
     * @param cameraGroups Get counters that are associated with cameras from `camera_group`.
     * @param cameras Get counters that are associated with `camera`. Pass comma separated values to select counters related to multiple `camera`s
     * @param countIntervalGte Select counters with `count_interval` **greater than or equal** to this value
     * @param countIntervalLte Select counters with `count_interval` **less than or equal** to this value
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param detectCars Select counters that count cars
     * @param detectFaces Select counters that count faces
     * @param detectProximity Select only counters with (`true`) or without (`false`) proximity detection
     * @param detectSilhouettes Select counters that count silhouettes
     * @param idIn Select counters with provided ids
     * @param limit Number of results to return per page.
     * @param multiCamera Select counters related to multiple cameras
     * @param nameContains Select counters where the SQL condition `name ILIKE name_contains` is true
     * @param ordering Which field to use when ordering the results. Available fields: `created_date`, `id`
     * @param page Pagination cursor value.
     * @returns PaginatedCounterList
     * @throws ApiError
     */
    public static countersList(
        active?: boolean,
        calibrationStatus?: 'AWAITING_PROXIMITY_MODEL' | 'AWAITING_SYNC' | 'CALIBRATED' | 'COLLECTING_CALIBRATION_DATA' | 'NOT_CALIBRATED',
        cameraGroups?: Array<number>,
        cameras?: Array<number>,
        countIntervalGte?: number,
        countIntervalLte?: number,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        detectCars?: boolean,
        detectFaces?: boolean,
        detectProximity?: boolean,
        detectSilhouettes?: boolean,
        idIn?: Array<number>,
        limit?: string,
        multiCamera?: boolean,
        nameContains?: string,
        ordering?: string,
        page?: string,
    ): CancelablePromise<PaginatedCounterList> {
        return __request({
            method: 'GET',
            path: `/counters/`,
            query: {
                'active': active,
                'calibration_status': calibrationStatus,
                'camera_groups': cameraGroups,
                'cameras': cameras,
                'count_interval_gte': countIntervalGte,
                'count_interval_lte': countIntervalLte,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'detect_cars': detectCars,
                'detect_faces': detectFaces,
                'detect_proximity': detectProximity,
                'detect_silhouettes': detectSilhouettes,
                'id_in': idIn,
                'limit': limit,
                'multi_camera': multiCamera,
                'name_contains': nameContains,
                'ordering': ordering,
                'page': page,
            },
        });
    }
    /**
     * Add new counter
     * This method adds a new counter.
     * @param requestBody
     * @returns Counter
     * @throws ApiError
     */
    public static countersCreate(
        requestBody: CounterRequest,
    ): CancelablePromise<Counter> {
        return __request({
            method: 'POST',
            path: `/counters/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve counter by ID
     * This method retrieves a counter by its ID.
     * @param id A unique integer value identifying this Counter.
     * @returns Counter
     * @throws ApiError
     */
    public static countersRetrieve(
        id: number,
    ): CancelablePromise<Counter> {
        return __request({
            method: 'GET',
            path: `/counters/${id}/`,
        });
    }
    /**
     * Update counter properties
     * Use this method to update a counter properties.
     * @param id A unique integer value identifying this Counter.
     * @param requestBody
     * @returns Counter
     * @throws ApiError
     */
    public static countersPartialUpdate(
        id: number,
        requestBody?: PatchedCounterRequest,
    ): CancelablePromise<Counter> {
        return __request({
            method: 'PATCH',
            path: `/counters/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete counter
     * Use this method to delete a counter.
     * @param id A unique integer value identifying this Counter.
     * @returns void
     * @throws ApiError
     */
    public static countersDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/counters/${id}/`,
        });
    }
    /**
     * Start or restart counter proximity calibration
     * @param collectTrackDataSeconds Collect calibration track data for this time (in seconds).
     * @param id A unique integer value identifying this Counter.
     * @param requestBody
     * @param keepOldTrackData Do not delete existing track data on recalibration
     * @returns any No response body
     * @throws ApiError
     */
    public static countersProximityCalibrationCreate(
        collectTrackDataSeconds: number,
        id: number,
        requestBody: CounterRequest,
        keepOldTrackData: boolean = false,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/counters/${id}/proximity_calibration/`,
            query: {
                'collect_track_data_seconds': collectTrackDataSeconds,
                'keep_old_track_data': keepOldTrackData,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete counter's proximity calibration data
     * @param id A unique integer value identifying this Counter.
     * @returns any No response body
     * @throws ApiError
     */
    public static countersProximityCalibrationDestroy(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/counters/${id}/proximity_calibration/`,
        });
    }
    /**
     * Objects count
     * This method retrieves count of objects.
     * @param active Select only active counters (`true`) or inactive (`false`) counters
     * @param calibrationStatus Select counters with this `proximity_calibration_status`
     * @param cameraGroups Get counters that are associated with cameras from `camera_group`.
     * @param cameras Get counters that are associated with `camera`. Pass comma separated values to select counters related to multiple `camera`s
     * @param countIntervalGte Select counters with `count_interval` **greater than or equal** to this value
     * @param countIntervalLte Select counters with `count_interval` **less than or equal** to this value
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param detectCars Select counters that count cars
     * @param detectFaces Select counters that count faces
     * @param detectProximity Select only counters with (`true`) or without (`false`) proximity detection
     * @param detectSilhouettes Select counters that count silhouettes
     * @param idIn Select counters with provided ids
     * @param multiCamera Select counters related to multiple cameras
     * @param nameContains Select counters where the SQL condition `name ILIKE name_contains` is true
     * @returns CountResponse
     * @throws ApiError
     */
    public static countersCountRetrieve(
        active?: boolean,
        calibrationStatus?: 'AWAITING_PROXIMITY_MODEL' | 'AWAITING_SYNC' | 'CALIBRATED' | 'COLLECTING_CALIBRATION_DATA' | 'NOT_CALIBRATED',
        cameraGroups?: Array<number>,
        cameras?: Array<number>,
        countIntervalGte?: number,
        countIntervalLte?: number,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        detectCars?: boolean,
        detectFaces?: boolean,
        detectProximity?: boolean,
        detectSilhouettes?: boolean,
        idIn?: Array<number>,
        multiCamera?: boolean,
        nameContains?: string,
    ): CancelablePromise<CountResponse> {
        return __request({
            method: 'GET',
            path: `/counters/count/`,
            query: {
                'active': active,
                'calibration_status': calibrationStatus,
                'camera_groups': cameraGroups,
                'cameras': cameras,
                'count_interval_gte': countIntervalGte,
                'count_interval_lte': countIntervalLte,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'detect_cars': detectCars,
                'detect_faces': detectFaces,
                'detect_proximity': detectProximity,
                'detect_silhouettes': detectSilhouettes,
                'id_in': idIn,
                'multi_camera': multiCamera,
                'name_contains': nameContains,
            },
        });
    }
}