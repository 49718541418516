
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';
import FullscreenButton from '@/components/video-player/FullscreenButton.vue';

@Options({
  name: 'VideoPlayerControls',
  components: { FullscreenButton, NIcon }
})
export default class VideoPlayerControls extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly isLive!: boolean;

  @Prop({ type: Boolean, required: true })
  readonly isPause!: boolean;

  @Prop({ type: Boolean, required: true })
  readonly ptzButton!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showRewinds!: boolean;

  @Prop({ type: Number, required: true })
  readonly currentTime!: number;

  @Prop({ type: Object, required: true })
  readonly fullscreenElement!: Element;

  fullscreen = false;

  play() {
    this.$emit('play');
  }
  pause() {
    this.$emit('pause');
  }
  movePosition(delta: number) {
    this.$emit('timeChange', this.currentTime + delta);
  }
  goToLive() {
    this.$emit('timeChange', new Date().getTime() / 1000 + 1);
  }
  toggleExportMode() {
    this.$emit('toggleExportMode');
  }
}
