import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-face-invitation" }
const _hoisted_2 = { class: "login-face-invitation-message heading-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('common.activate_webcam_for_face_recognition')), 1),
    _createVNode(_component_NButton, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit('invite'))),
      size: "big",
      disabled: !_ctx.hasCamera
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('common.activate_webcam')), 1)
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}