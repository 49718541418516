import { NotificationOptions } from './types';
import { h, render } from 'vue';

export function removeElement(el: HTMLElement) {
  if (typeof el.remove !== 'undefined') {
    el.remove();
  } else {
    el.parentNode?.removeChild(el);
  }
}

export function createComponent(component: any, props: NotificationOptions, slots = {}) {
  const vNode = h(component, props, slots);
  const container = document.createElement('div');
  render(vNode, container);

  return vNode.component;
}

export function getOrCreateContainer(placement: string): HTMLDivElement {
  let container = document.querySelector<HTMLDivElement>(`.n-notifications.n-notifications_${placement}`);

  if (!container) {
    container = document.body.appendChild(document.createElement('div'));
    container.className = `n-notifications n-notifications_${placement}`;
  }

  return container;
}
