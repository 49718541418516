/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { AreaCameraLink } from './AreaCameraLink';
import { Calendar } from './Calendar';
export type Area = {
    readonly id: number;
    /**
     * Area creation date
     */
    readonly created_date: string;
    /**
     * Area modification date
     */
    readonly modified_date: string;
    /**
     * Area name, up to 256 characters
     */
    name: string;
    /**
     * `true` if the area is enabled
     */
    enabled?: boolean;
    /**
     * `gt` people on a frame are required for trigger activation
     */
    trigger_count_gt?: number | null;
    /**
     * `lt` people on a frame are required for trigger activation
     */
    trigger_count_lt?: number | null;
    /**
     * Minimum time in seconds conditions should be met to activate triggers
     */
    trigger_time: number;
    /**
     * Area activation calendar during which an area trigger can activate. Area trigger might remain active beyond calendar intervals if the trigger activation condition is still satisfied
     */
    trigger_calendar?: Array<Calendar> | null;
    camera_roi: Array<AreaCameraLink>;
    readonly scheduled_now: boolean;
    readonly camera_groups: Array<number>;
    /**
     * Monitor area for these cameras
     */
    readonly cameras: Array<number>;
    readonly multi_camera: boolean;
};
export const EmptyArea: Area = {
    id: 0,
    /**
     * Area creation date
     */
    created_date: '',
    /**
     * Area modification date
     */
    modified_date: '',
    /**
     * Area name, up to 256 characters
     */
    name: '',
    /**
     * `true` if the area is enabled
     */
    enabled: false,
    /**
     * `gt` people on a frame are required for trigger activation
     */
    trigger_count_gt: 0,
    /**
     * `lt` people on a frame are required for trigger activation
     */
    trigger_count_lt: 0,
    /**
     * Minimum time in seconds conditions should be met to activate triggers
     */
    trigger_time: 0,
    /**
     * Area activation calendar during which an area trigger can activate. Area trigger might remain active beyond calendar intervals if the trigger activation condition is still satisfied
     */
    trigger_calendar: [],
    camera_roi: [],
    scheduled_now: false,
    camera_groups: [],
    /**
     * Monitor area for these cameras
     */
    cameras: [],
    multi_camera: false,
};
