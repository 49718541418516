import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "video-player-overlay",
  ref: "bbox-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayerOverlayMeta = _resolveComponent("VideoPlayerOverlayMeta")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bboxes, (bbox) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: bbox.trackId
      }, [
        _createElementVNode("div", {
          style: _normalizeStyle(bbox.style),
          class: "video-player-overlay__bbox-item"
        }, null, 4),
        (bbox.isInfo)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              style: _normalizeStyle(bbox.metaInfoStyle),
              class: "video-player-overlay__bbox-meta"
            }, [
              _createVNode(_component_VideoPlayerOverlayMeta, {
                event: bbox.event
              }, null, 8, ["event"])
            ], 4))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ], 512))
}