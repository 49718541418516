import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'video-wall-preset-editor_editing': _ctx.editing,
      'video-wall-preset-editor': true
    })
  }, [
    (_ctx.editing)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_NInput, {
            placeholder: _ctx.$t('videowall.enter_preset_name'),
            modelValue: _ctx.temporaryName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.temporaryName) = $event))
          }, null, 8, ["placeholder", "modelValue"]),
          _createVNode(_component_NButton, {
            onClick: _ctx.dispatchSaveEvent,
            label: _ctx.$t('videowall.save'),
            type: "primary"
          }, null, 8, ["onClick", "label"]),
          _createVNode(_component_NButton, {
            onClick: _ctx.dispatchCancelEvent,
            label: _ctx.$t('videowall.cancel'),
            type: "secondary"
          }, null, 8, ["onClick", "label"])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("h1", {
            class: "heading-l video-wall-preset-editor__name",
            textContent: _toDisplayString(_ctx.name)
          }, null, 8, _hoisted_1),
          _createVNode(_component_NButton, {
            onClick: _ctx.toggleEditorMode,
            "icon-size": "13",
            icon: "edit",
            type: "link"
          }, null, 8, ["onClick"])
        ], 64))
  ], 2))
}