/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { BboxIntersectionPointEnum } from './BboxIntersectionPointEnum';
export type Line = {
    readonly id: number;
    /**
     * Object creation date
     */
    readonly created_date: string;
    /**
     * Object modification date
     */
    readonly modified_date: string;
    /**
     * Short name, up to 256 characters
     */
    name: string;
    /**
     * Extended description, up to 2048 characters
     */
    comment?: string;
    /**
     * Line activity status
     */
    active?: boolean;
    /**
     * Bbox intersection type
     */
    bbox_intersection_point?: BboxIntersectionPointEnum;
    /**
     * Array of the line x,y coordinates
     */
    points: Array<Array<number>>;
    /**
     * Related camera
     */
    camera?: number | null;
    /**
     * Related video archive
     */
    video_archive?: number | null;
    /**
     * line attributes each up to 256 characters
     */
    attributes?: Array<string> | null;
    /**
     * AB direction name, up to 256 characters
     */
    ab_name?: string | null;
    /**
     * AB direction attributes each up to 256 characters
     */
    ab_attributes?: Array<string> | null;
    /**
     * BA direction name, up to 256 characters
     */
    ba_name?: string | null;
    /**
     * BA direction attributes each up to 256 characters
     */
    ba_attributes?: Array<string> | null;
};
export const EmptyLine: Line = {
    id: 0,
        /**
        * Object creation date
        */
    created_date: '',
        /**
        * Object modification date
        */
    modified_date: '',
        /**
        * Short name, up to 256 characters
        */
    name: '',
        /**
        * Extended description, up to 2048 characters
        */
    comment: '',
        /**
        * Line activity status
        */
    active: true,
        /**
        * Bbox intersection type
        */
    bbox_intersection_point: BboxIntersectionPointEnum.MIDDLE_CENTER,
        /**
        * Array of the line x,y coordinates
        */
    points: [],
        /**
        * Related camera
        */
    camera: null,
        /**
        * Related video archive
        */
    video_archive: null,
        /**
        * line attributes each up to 256 characters
        */
    attributes: [],
        /**
        * AB direction name, up to 256 characters
        */
    ab_name: null,
        /**
        * AB direction attributes each up to 256 characters
        */
    ab_attributes: [],
        /**
        * BA direction name, up to 256 characters
        */
    ba_name: null,
        /**
        * BA direction attributes each up to 256 characters
        */
    ba_attributes: [],
};
