import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "data-sources-page__content"
}
const _hoisted_2 = {
  key: 1,
  class: "data-sources-page__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_DataSourcesDevices = _resolveComponent("DataSourcesDevices")!
  const _component_DataSourcesOnvifDiscovery = _resolveComponent("DataSourcesOnvifDiscovery")!
  const _component_SelectVideoForm = _resolveComponent("SelectVideoForm")!
  const _component_DataSourcesExternalDetectors = _resolveComponent("DataSourcesExternalDetectors")!
  const _component_DataSourcesDevicesNext = _resolveComponent("DataSourcesDevicesNext")!
  const _component_DataSourcesOnvifNext = _resolveComponent("DataSourcesOnvifNext")!
  const _component_DataSourcesUploadNext = _resolveComponent("DataSourcesUploadNext")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    "has-margins": false,
    layout: "",
    customSize: "90vw",
    "class-name": "data-sources-page__modal"
  }, {
    header: _withCtx(() => [
      (_ctx.currentStep === 1)
        ? (_openBlock(), _createBlock(_component_NButtonGroup, {
            key: 0,
            items: _ctx.tabItems,
            modelValue: _ctx.currentTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event))
          }, null, 8, ["items", "modelValue"]))
        : (_openBlock(), _createBlock(_component_NButton, {
            key: 1,
            onClick: _ctx.prevStep,
            icon: "ds-back",
            type: "link",
            size: "big"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('ds.back', 'f')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])),
      _createVNode(_component_NButton, {
        icon: "close",
        type: "secondary",
        round: "",
        class: "n-modal-window__close-button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      })
    ]),
    content: _withCtx(() => [
      (_ctx.currentStep === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.currentTab === _ctx.tabNames.Cameras)
              ? (_openBlock(), _createBlock(_component_DataSourcesDevices, {
                  key: 0,
                  onNextStep: _ctx.nextStep
                }, null, 8, ["onNextStep"]))
              : _createCommentVNode("", true),
            (_ctx.currentTab === _ctx.tabNames.OnvifDiscovery)
              ? (_openBlock(), _createBlock(_component_DataSourcesOnvifDiscovery, {
                  key: 1,
                  onNextStep: _ctx.nextStep
                }, null, 8, ["onNextStep"]))
              : _createCommentVNode("", true),
            (_ctx.currentTab === _ctx.tabNames.Videos)
              ? (_openBlock(), _createBlock(_component_SelectVideoForm, {
                  key: 2,
                  onUpload: _ctx.nextStep
                }, null, 8, ["onUpload"]))
              : _createCommentVNode("", true),
            (_ctx.currentTab === _ctx.tabNames.ExternalDetectors)
              ? (_openBlock(), _createBlock(_component_DataSourcesExternalDetectors, {
                  key: 3,
                  onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
                }))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.currentTab === 'cameras' && _ctx.currentStep === 2)
              ? (_openBlock(), _createBlock(_component_DataSourcesDevicesNext, {
                  key: 0,
                  onPrevStep: _ctx.prevStep,
                  devices: _ctx.currentData,
                  onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
                }, null, 8, ["onPrevStep", "devices"]))
              : _createCommentVNode("", true),
            (_ctx.currentTab === _ctx.tabNames.OnvifDiscovery && _ctx.currentStep === 2)
              ? (_openBlock(), _createBlock(_component_DataSourcesOnvifNext, {
                  key: 1,
                  onPrevStep: _ctx.prevStep,
                  devices: _ctx.currentData,
                  onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
                }, null, 8, ["onPrevStep", "devices"]))
              : _createCommentVNode("", true),
            (_ctx.currentTab === _ctx.tabNames.Videos && _ctx.currentStep === 2)
              ? (_openBlock(), _createBlock(_component_DataSourcesUploadNext, {
                  key: 2,
                  files: _ctx.currentData,
                  onUpload: _ctx.uploadHandler
                }, null, 8, ["files", "onUpload"]))
              : _createCommentVNode("", true)
          ]))
    ]),
    _: 1
  }))
}