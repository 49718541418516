
import { Prop } from 'vue-property-decorator';
import { NHint } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import { configModule } from '@/store/config';
import { languageModule } from '@/store/languages';

export type ConfidenceDisplayItem = {
  label: Record<string, string>;
  confidence: number;
  color: string;
};

@Options({
  name: 'ConfidenceLabel',
  components: { NHint }
})
export default class ConfidenceLabel extends Vue {
  @Prop({ type: String, default: 'faces' })
  objects!: string;

  @Prop({ required: true, type: Number, default: 0 })
  value!: number;

  @Prop({ type: String, default: 'horizontal' })
  direction!: string;

  get label() {
    const labelLocales = this.item?.label || { en: 'not set' };
    const text = labelLocales[languageModule.locale] || labelLocales.en;
    return `${text} ${this.value.toFixed(2)}`;
  }

  get count() {
    return this.item ? this.itemIndex + 1 : 1;
  }

  get color() {
    return this.item?.color || '#000';
  }

  get items(): ConfidenceDisplayItem[] {
    return (configModule.config.objects[this.objects]?.confidence_display as unknown as ConfidenceDisplayItem[]) ?? [];
  }

  get classes(): Record<string, boolean> {
    return {
      'confidence-label_horizontal': this.direction !== 'vertical',
      'confidence-label_vertical': this.direction === 'vertical'
    };
  }

  get itemIndex(): number {
    const reversedItems = [...this.items].reverse();
    const reverseIndex = reversedItems.findIndex((v) => this.value > v.confidence);
    return reverseIndex > -1 ? this.items.length - 1 - reverseIndex : -1;
  }

  get item(): ConfidenceDisplayItem | null {
    return this.itemIndex > -1 ? this.items[this.itemIndex] : null;
  }
}
