/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DailySearchEvent = {
    readonly id: number;
    /**
     * Daily search event card
     */
    readonly card: number;
    /**
     * External face ID
     */
    readonly external_face_id: string;
    /**
     * Daily search event face object
     */
    readonly face_object_id: string;
    /**
     * Daily search event puppet ID
     */
    readonly puppet_id: string;
    /**
     * Daily search event camera
     */
    readonly camera: string;
    /**
     * Daily search event matched threshold
     */
    readonly confidence: number;
    /**
     * Daily search event bbox
     */
    readonly bbox: Record<string, any>;
    /**
     * Daily search event labels
     */
    readonly labels: Record<string, any>;
    /**
     * Daily search event timestamp
     */
    readonly external_timestamp: string;
    /**
     * Daily search event created date
     */
    readonly created_date: string;
    /**
     * Daily search event fullframe image
     */
    readonly fullframe: string;
    /**
     * Daily search event thumbnail image
     */
    readonly thumbnail: string;
};
export const EmptyDailySearchEvent: DailySearchEvent = {
    id: 0,
    /**
     * Daily search event card
     */
    card: 0,
    /**
     * External face ID
     */
    external_face_id: '',
    /**
     * Daily search event face object
     */
    face_object_id: '',
    /**
     * Daily search event puppet ID
     */
    puppet_id: '',
    /**
     * Daily search event camera
     */
    camera: '',
    /**
     * Daily search event matched threshold
     */
    confidence: 0,
    /**
     * Daily search event bbox
     */
    bbox: {},
    /**
     * Daily search event labels
     */
    labels: {},
    /**
     * Daily search event timestamp
     */
    external_timestamp: '',
    /**
     * Daily search event created date
     */
    created_date: '',
    /**
     * Daily search event fullframe image
     */
    fullframe: '',
    /**
     * Daily search event thumbnail image
     */
    thumbnail: '',
};
