import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "video-wall-layout-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoWallLayoutButton = _resolveComponent("VideoWallLayoutButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layouts, (layout) => {
      return (_openBlock(), _createBlock(_component_VideoWallLayoutButton, {
        key: layout,
        layout: layout,
        onClick: ($event: any) => (_ctx.dispatchClickEvent(layout))
      }, null, 8, ["layout", "onClick"]))
    }), 128))
  ]))
}