export default {
  create_hooks: 'Add new webhook',
  hooks_no_items: 'You haven\'t added any webhooks',
  webhooks: 'webhooks',
  webhook_title: 'Webhook header',
  batch_size: 'Number of notifications in webhook batch',
  send_attempts: 'Number of attempts to send',
  send_attempts_tip: '0 - unlimited',
  filters: 'filters',
  edit_mode_visual: 'visual',
  edit_mode_json: 'code',
  section_counters: 'counter records',
  section_car_episodes: 'vehicle episodes',
  section_human_episodes: 'individuals episodes',
  section_car_events: 'vehicle events',
  section_body_events: 'body events',
  section_face_events: 'face events',
  section_selected: 'Selected. You will receive all',
  enable_visual: 'configure',
  filter_default: 'All events will be sent until you have selected a filter',
  reset_question: 'Are you sure you want to reset all filters? All the filters you\'ve selected will be reset.',
  reset_confirm: 'Yes, reset',
  active: 'Active',
};
