import {
  isLicenseBooleanLimit,
  isLicenseNumberLimit,
  isLicenseTimeLimit,
  LicenseBooleanLimit,
  LicenseLimit,
  LicenseNumberLimit,
  LicenseTimeLimit
} from '@/pages/license/types';
import startCase from 'lodash/startCase';
import { LicensePageMetaItem } from '../../../meta';
import { i18n } from './types';
import { formatDate, formatNumber } from './utils';

export function formatLicenseLimit(limit: LicenseLimit, i18n: (token: string) => string): LicensePageMetaItem {
  if (isLicenseBooleanLimit(limit)) {
    return formatLicenseBooleanLimit(limit, i18n);
  }

  if (isLicenseNumberLimit(limit)) {
    return formatLicenseNumberLimit(limit, i18n);
  }

  if (isLicenseTimeLimit(limit)) {
    return formatLicenseTimeLimit(limit, i18n);
  }

  throw new Error('Unsupported license limit format.');
}

function formatLicenseBooleanLimit(limit: LicenseBooleanLimit, i18n: (token: string) => string): LicensePageMetaItem {
  return {
    label: startCase(limit.name),
    content: {
      type: 'indicator',
      content: {
        disabled: !limit.value,
        label: i18n(`license.${limit.value ? 'enabled' : 'disabled'}`)
      }
    }
  };
}

function formatLicenseNumberLimit(limit: LicenseNumberLimit, i18n: i18n): LicensePageMetaItem {
  const remains = `${i18n('license.left')}: ${limit.value - limit.current}`;
  const content = `${formatNumber(limit.current)}/${formatNumber(limit.value)} (${remains})`;
  return { label: startCase(limit.name), content: { type: 'monospaced', content } };
}

function formatLicenseTimeLimit(limit: LicenseTimeLimit, _i18n: i18n): LicensePageMetaItem {
  return { label: startCase(limit.name), content: { type: 'monospaced', content: formatDate(new Date(limit.value * 1000)) } };
}
