/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { ClusterLooksLike } from './ClusterLooksLike';
export type BodyCluster = {
    readonly id: number;
    /**
     * Cluster tnt-id
     */
    readonly tnt_id: number;
    /**
     * Cluster creation date
     */
    readonly created_date: string;
    /**
     * Cluster events count
     */
    readonly events_count: number;
    /**
     * Average cluster events quality
     */
    readonly events_quality: number;
    /**
     * Cluster's cameras list
     */
    readonly cameras: Array<number>;
    /**
     * Cluster's camera groups list
     */
    readonly camera_groups: Array<number>;
    /**
     * Best cluster event
     */
    readonly best_event: number;
    /**
     * Best cluster event quality
     */
    readonly best_event_quality: number;
    /**
     * Related card
     */
    readonly card: number;
    /**
     * Card match confidence
     */
    readonly match_confidence: number;
    /**
     * Matched watch lists
     */
    readonly matched_lists: Array<number>;
    /**
     * Whether cluster is pinned or not
     */
    pinned?: boolean;
    readonly looks_like: ClusterLooksLike | null;
    readonly looks_like_confidence: number;
    /**
     * Related case
     */
    readonly case: number;
    /**
     * Cluster bottom color feature
     */
    readonly bottom_color: string;
    /**
     * Cluster top color feature
     */
    readonly top_color: string;
    /**
     * Cluster detailed upper clothes feature
     */
    readonly detailed_upper_clothes: string;
    /**
     * Cluster headwear feature
     */
    readonly headwear: string;
    /**
     * Cluster lower clothes feature
     */
    readonly lower_clothes: string;
    /**
     * Cluster upper clothes feature
     */
    readonly upper_clothes: string;
};
export const EmptyBodyCluster: BodyCluster = {
    id: 0,
    /**
     * Cluster tnt-id
     */
    tnt_id: 0,
    /**
     * Cluster creation date
     */
    created_date: '',
    /**
     * Cluster events count
     */
    events_count: 0,
    /**
     * Average cluster events quality
     */
    events_quality: 0,
    /**
     * Cluster's cameras list
     */
    cameras: [],
    /**
     * Cluster's camera groups list
     */
    camera_groups: [],
    /**
     * Best cluster event
     */
    best_event: 0,
    /**
     * Best cluster event quality
     */
    best_event_quality: 0,
    /**
     * Related card
     */
    card: 0,
    /**
     * Card match confidence
     */
    match_confidence: 0,
    /**
     * Matched watch lists
     */
    matched_lists: [],
    /**
     * Whether cluster is pinned or not
     */
    pinned: false,
    looks_like: null,
    looks_like_confidence: 0,
    /**
     * Related case
     */
    case: 0,
    /**
     * Cluster bottom color feature
     */
    bottom_color: '',
    /**
     * Cluster top color feature
     */
    top_color: '',
    /**
     * Cluster detailed upper clothes feature
     */
    detailed_upper_clothes: '',
    /**
     * Cluster headwear feature
     */
    headwear: '',
    /**
     * Cluster lower clothes feature
     */
    lower_clothes: '',
    /**
     * Cluster upper clothes feature
     */
    upper_clothes: '',
};
