
import NIcon from '@/uikit/icons/NIcon.vue';
import { Options, Prop, Vue } from 'vue-property-decorator';
import type { VideoWallLayout } from '../../types';

@Options({
  components: { NIcon },
  emits: ['click']
})
export default class VideoWallLayoutButton extends Vue {
  @Prop({ required: true, type: String })
  readonly layout!: VideoWallLayout;

  dispatchClickEvent(): void {
    this.$emit('click');
  }
}
