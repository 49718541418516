
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NInputInterval } from '@/uikit';
import NSelect from '@/uikit/select/NSelect.vue';

const MaxValue = 100;

@Options({
  name: 'NFilterAgeRange',
  components: {
    NSelect,
    NInputInterval
  }
})
export default class NFilterAgeRange extends Vue {
  @Prop({ type: Object, required: true })
  model!: any;

  @Prop({ type: Number, default: MaxValue })
  maxValue!: number;

  @Prop({ type: Boolean })
  plain?: boolean;

  private ageGte = 0;
  private ageLte = 0;

  created() {
    const { age_gte, age_lte } = this.model;
    this.ageGte = age_gte;
    this.ageLte = age_lte;
  }

  get minItems() {
    return Array(this.maxValue)
      .fill(0)
      .map((v, index) => {
        return { label: String(index + 1), value: index + 1 };
      });
  }

  get maxItems() {
    const arrayLength = this.ageGte ? this.minItems.length - this.ageGte : this.maxValue;
    return Array(arrayLength)
      .fill(0)
      .map((v, index) => {
        if (this.ageGte) {
          return { label: String(this.ageGte + index + 1), value: this.ageGte + index + 1 };
        }
        return { label: String(index + 1), value: index + 1 };
      });
  }

  changeHandlerGte(age: any) {
    this.ageGte = age;
    Object.assign(this.model, { age_gte: age });
  }
  changeHandlerLte(age: any) {
    this.ageLte = age;
    Object.assign(this.model, { age_lte: age });
  }
}
