import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "n-input-base" }
const _hoisted_2 = ["value", "readonly", "disabled", "placeholder", "data-qa"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      ref: "input",
      class: "n-input-base__input",
      value: _ctx.model,
      onInput: _cache[0] || (_cache[0] = (e) => (_ctx.model = e.target.value)),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focusHandler && _ctx.focusHandler(...args))),
      onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.keydownHandler && _ctx.keydownHandler(...args))),
      readonly: _ctx.readonly,
      disabled: _ctx.disabled,
      placeholder: _ctx.placeholder,
      "data-qa": _ctx.dataQa
    }, null, 40, _hoisted_2)
  ]))
}