import { reactive } from 'vue';
import { viewModelRepository } from '@/api/common';
import { configModule } from '@/store/config';
import { RemoteMonitoringRecordRequest } from '@/api/models/RemoteMonitoringRecordRequest';

class MonitoringModule {
  public state = viewModelRepository.getPuppeteerRemoteMonitoringListViewModel();

  public get isPuppeteerEnabled() {
    return !!configModule.config.plugins?.puppeteer;
  }

  public get loaded() {
    return this.state.loaded;
  }

  public async create(item: RemoteMonitoringRecordRequest) {
    await this.state.create(item);
  }

  public async load() {
    if (!this.isPuppeteerEnabled) return;
    this.state.filter.current.limit = String(10e6);
    await this.state.get({ resetState: false });
  }

  public getItemsByCardId(cardId: number) {
    return this.state.items.filter((item) => item.card === cardId && !item.is_deleted);
  }

  public async deleteByCards(cardIds: number[]) {
    let cardIdsMap = Object.fromEntries(cardIds.map((id) => [id, true]));
    for (let item of this.state.items) {
      if (!item.is_deleted && item.card && cardIdsMap[item.card]) await this.deleteItem(item.id);
    }
    await this.load();
  }

  protected async deleteItem(id: number) {
    await this.state.delete(id);
  }

  public getCardIds() {
    return this.state.items.map((item) => item.card);
  }

  public getReasonsByCardId(cardId: number) {
    const reasons = [];
    for (let item of this.state.items) {
      if (!item.is_deleted && item.card === cardId) {
        reasons.push(item.reason);
      }
    }
    return reasons.join(', ');
  }
}

export const monitoringModule = reactive(new MonitoringModule());
