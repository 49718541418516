import { computePosition, flip, offset, shift } from '@floating-ui/dom';
import { nextTick, onBeforeUnmount, onMounted, Ref, ref, unref } from 'vue';
import { NDropdownPlacement } from '..';
import { asNonNull } from './common';

export function useDropdownToggler(root: Ref<HTMLElement | null>, body: Ref<HTMLElement | null>, placement: Ref<NDropdownPlacement>, disabled?: Ref<boolean>) {
  const isShown = ref(false);

  onMounted(() => addEventListener('resize', handleResizeEvent));

  onBeforeUnmount(() => removeEventListener('resize', handleResizeEvent));

  function show() {
    if (disabled?.value) return;
    isShown.value = true;
    return nextTick(refreshBodyStyle);
  }

  function hide() {
    if (disabled?.value) return;
    isShown.value = false;
  }

  async function toggle() {
    return unref(isShown) ? hide() : await show();
  }

  function refreshBodyStyle() {
    return computeBodyStyle().then((style) => Object.assign(unrefBody().style, style));
  }

  function computeBodyStyle() {
    return computePosition(unrefRoot(), unrefBody(), {
      placement: unref(placement),
      middleware: [shift(), offset(2), flip()]
    }).then(({ x, y }) => ({ left: `${x}px`, top: `${y}px` }));
  }

  function unrefRoot() {
    return asNonNull(unref(root), 'HTMLElement "root" is null.');
  }

  function unrefBody() {
    return asNonNull(unref(body), 'HTMLElement "body" is null.');
  }

  function handleResizeEvent() {
    unref(isShown) && refreshBodyStyle();
  }

  return { show, hide, toggle, isShown };
}

export function useDropdownOnClickOutsideListener(rootRef: Ref<HTMLElement | null>, bodyRef: Ref<HTMLElement | null>, listener: () => void) {
  onMounted(() => document.addEventListener('click', handleMouseEvent));

  onBeforeUnmount(() => document.removeEventListener('click', handleMouseEvent));

  function handleMouseEvent(this: Document, event: MouseEvent): void {
    const target = event.target as HTMLElement | null;
    shouldListenerBeInvoked(target) && listener();
  }

  function shouldListenerBeInvoked(target: HTMLElement | null) {
    const root = unref(rootRef);
    const body = unref(bodyRef);
    return !(root?.contains(target) || (!root?.contains(body) && body?.contains(target)));
  }
}

export function useDropdownOnEscapeListener(listener: () => void) {
  onMounted(() => document.addEventListener('keydown', handleKeyboardEvent));

  onBeforeUnmount(() => document.removeEventListener('keydown', handleKeyboardEvent));

  function handleKeyboardEvent(this: Document, event: KeyboardEvent): void {
    event.key === 'Escape' && listener();
  }
}
