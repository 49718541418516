const isDarkSystemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

export enum Themes {
  Light = 'light',
  Dark = 'dark',
  System = ''
}

const THEME_ATTR = 'data-theme';
export const THEME_VARS_CONTAINER_ID = 'theme-vars-container';

function initStyleContainer() {
  const styleEl = document.createElement('style');
  styleEl.setAttribute('id', THEME_VARS_CONTAINER_ID);
  document.head.append(styleEl);
  return styleEl;
}

export function getContainer() {
  return document.getElementById(THEME_VARS_CONTAINER_ID) || initStyleContainer();
}

export function getActiveTheme() {
  const styleContainer = getContainer();
  return styleContainer.getAttribute(THEME_ATTR) || '';
}

export async function setTheme(theme: Themes, vars?: unknown) {
  const styles = await import(`!to-string-loader!css-loader!stylus-loader!@/css/themes/${theme}/vars.styl`);
  let styleTextContent = styles.default;
  const styleContainer = getContainer();
  styleContainer.setAttribute(THEME_ATTR, theme);
  if (vars) styleTextContent = replaceStyleTokens(styleTextContent, vars as any);
  styleContainer.textContent = styleTextContent;
}

export function replaceStyleTokens(value: string, tokens: Record<string, string>): string {
  let result = value;
  Object.entries(tokens).forEach(([name, value]) => {
    const regex = new RegExp(`(.*${name}: )(.*)(;.*)`, 'gi');
    result = result.replace(regex, `$1${value}$3`);
  });
  return result;
}

export function getSystemTheme(): Themes {
  return isDarkSystemTheme ? Themes.Dark : Themes.Light;
}
