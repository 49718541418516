/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { ReportSizeInBytes } from '@/pages/reports/types';
import { PicturesEnum } from './PicturesEnum';
import { ReportFormatEnum } from './ReportFormatEnum';
import { ReportMeta } from './ReportMeta';
import { ReportStatusEnum } from './ReportStatusEnum';
import { str_int_float_bool_object_array_null } from './str_int_float_bool_object_array_null';
import { TypeEnum } from './TypeEnum';

export type Report = {
  readonly id: number;
  /**
   * Cluster event creation date
   */
  readonly created_date: string;
  /**
   * Short name, up to 256 characters
   */
  name?: string;
  /**
   * Cluster event modification date
   */
  readonly modified_date: string;
  /**
   * Processing status
   */
  readonly status: ReportStatusEnum;
  type: TypeEnum;
  pictures: PicturesEnum;
  /**
   * Number of records in report
   */
  readonly records_number: number;
  /**
   * Report formats
   */
  report_format: Array<ReportFormatEnum>;
  filters: Record<string, str_int_float_bool_object_array_null>;
  readonly report_file_csv: string | null;
  readonly report_file_json: string | null;
  readonly report_file_xls: string | null;
  /**
   * User request origin
   */
  readonly origin: string;
  readonly owner: number;
  readonly size: ReportSizeInBytes | null;
  meta?: ReportMeta;
};
export const EmptyReport: Report = {
  id: 0,
  /**
   * Cluster event creation date
   */
  created_date: '',
  /**
   * Short name, up to 256 characters
   */
  name: '',
  /**
   * Cluster event modification date
   */
  modified_date: '',
  /**
   * Processing status
   */
  status: ReportStatusEnum.PROCESSING,
  type: TypeEnum.FaceEvents,
  pictures: PicturesEnum.LINK,
  /**
   * Number of records in report
   */
  records_number: 0,
  /**
   * Report formats
   */
  report_format: [],
  report_file_xls: '',
  report_file_json: '',
  report_file_csv: '',
  filters: {},
  /**
   * User request origin
   */
  origin: '',
  owner: 0,
  meta: undefined,
  size: null
};
