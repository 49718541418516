/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BodyObjectRequest } from '../models/BodyObjectRequest';
import type { BodyObjectUpdate } from '../models/BodyObjectUpdate';
import type { CarObjectRequest } from '../models/CarObjectRequest';
import type { CarObjectUpdate } from '../models/CarObjectUpdate';
import type { FaceObjectRequest } from '../models/FaceObjectRequest';
import type { FaceObjectUpdate } from '../models/FaceObjectUpdate';
import type { PaginatedBodyObjectUpdateList } from '../models/PaginatedBodyObjectUpdateList';
import type { PaginatedCarObjectUpdateList } from '../models/PaginatedCarObjectUpdateList';
import type { PaginatedFaceObjectUpdateList } from '../models/PaginatedFaceObjectUpdateList';
import type { PatchedBodyObjectUpdateRequest } from '../models/PatchedBodyObjectUpdateRequest';
import type { PatchedCarObjectUpdateRequest } from '../models/PatchedCarObjectUpdateRequest';
import type { PatchedFaceObjectUpdateRequest } from '../models/PatchedFaceObjectUpdateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class ObjectsService {
    /**
     * List body objects
     * This method lists body objects.
     * **Body object** is an object that contains source photo, thumbnail, and other attributes
     * @param active Select only active objects (`true`) or inactive (`false`)
     * @param card Get body objects from these `cards`
     * @param idIn Select objects with provided ids
     * @param limit Number of results to return per page.
     * @param ordering Which field to use when ordering the results. Available fields: `id`
     * @param page Pagination cursor value.
     * @returns PaginatedBodyObjectUpdateList
     * @throws ApiError
     */
    public static objectsBodiesList(
        active?: boolean,
        card?: Array<number>,
        idIn?: Array<string>,
        limit?: number,
        ordering?: string,
        page?: string,
    ): CancelablePromise<PaginatedBodyObjectUpdateList> {
        return __request({
            method: 'GET',
            path: `/objects/bodies/`,
            query: {
                'active': active,
                'card': card,
                'id_in': idIn,
                'limit': limit,
                'ordering': ordering,
                'page': page,
            },
        });
    }
    /**
     * Add body object
     * This method creates new body object.
     * **Body object** is an object that contains source photo, thumbnail, and other attributes
     * @param formData
     * @returns BodyObjectUpdate
     * @throws ApiError
     */
    public static objectsBodiesCreate(
        formData: BodyObjectRequest,
    ): CancelablePromise<BodyObjectUpdate> {
        return __request({
            method: 'POST',
            path: `/objects/bodies/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Retrieve body object by ID
     * This method retrieve body object by id.
     * **Body object** is an object that contains source photo, thumbnail, and other attributes
     * @param id
     * @returns BodyObjectUpdate
     * @throws ApiError
     */
    public static objectsBodiesRetrieve(
        id: string,
    ): CancelablePromise<BodyObjectUpdate> {
        return __request({
            method: 'GET',
            path: `/objects/bodies/${id}/`,
        });
    }
    /**
     * Update body object properties
     * This method updates a body object properties.
     * @param id
     * @param formData
     * @returns BodyObjectUpdate
     * @throws ApiError
     */
    public static objectsBodiesPartialUpdate(
        id: string,
        formData?: PatchedBodyObjectUpdateRequest,
    ): CancelablePromise<BodyObjectUpdate> {
        return __request({
            method: 'PATCH',
            path: `/objects/bodies/${id}/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Delete body object
     * Use this method to delete a body object.
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static objectsBodiesDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/objects/bodies/${id}/`,
        });
    }
    /**
     * List car objects
     * This method lists car objects.
     * **Car object** is an object that contains source photo, thumbnail, and other attributes
     * @param active Select only active objects (`true`) or inactive (`false`)
     * @param card Get car objects from these `cards`
     * @param idIn Select objects with provided ids
     * @param limit Number of results to return per page.
     * @param ordering Which field to use when ordering the results. Available fields: `id`
     * @param page Pagination cursor value.
     * @returns PaginatedCarObjectUpdateList
     * @throws ApiError
     */
    public static objectsCarsList(
        active?: boolean,
        card?: Array<number>,
        idIn?: Array<string>,
        limit?: number,
        ordering?: string,
        page?: string,
    ): CancelablePromise<PaginatedCarObjectUpdateList> {
        return __request({
            method: 'GET',
            path: `/objects/cars/`,
            query: {
                'active': active,
                'card': card,
                'id_in': idIn,
                'limit': limit,
                'ordering': ordering,
                'page': page,
            },
        });
    }
    /**
     * Add car object
     * This method creates new car object.
     * **Car object** is an object that contains source photo, thumbnail, and other attributes
     * @param formData
     * @returns CarObjectUpdate
     * @throws ApiError
     */
    public static objectsCarsCreate(
        formData: CarObjectRequest,
    ): CancelablePromise<CarObjectUpdate> {
        return __request({
            method: 'POST',
            path: `/objects/cars/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Retrieve car object by ID
     * This method retrieve car object by id.
     * **Car object** is an object that contains source photo, thumbnail, and other attributes
     * @param id
     * @returns CarObjectUpdate
     * @throws ApiError
     */
    public static objectsCarsRetrieve(
        id: string,
    ): CancelablePromise<CarObjectUpdate> {
        return __request({
            method: 'GET',
            path: `/objects/cars/${id}/`,
        });
    }
    /**
     * Update car object properties
     * This method updates a car object properties.
     * @param id
     * @param formData
     * @returns CarObjectUpdate
     * @throws ApiError
     */
    public static objectsCarsPartialUpdate(
        id: string,
        formData?: PatchedCarObjectUpdateRequest,
    ): CancelablePromise<CarObjectUpdate> {
        return __request({
            method: 'PATCH',
            path: `/objects/cars/${id}/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Delete car object
     * Use this method to delete a car object.
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static objectsCarsDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/objects/cars/${id}/`,
        });
    }
    /**
     * List face objects
     * This method lists face objects.
     * **Face object** is an object that contains source photo, thumbnail, and other attributes
     * @param active Select only active objects (`true`) or inactive (`false`)
     * @param card Get face objects from these `cards`
     * @param idIn Select objects with provided ids
     * @param limit Number of results to return per page.
     * @param ordering Which field to use when ordering the results. Available fields: `id`
     * @param page Pagination cursor value.
     * @returns PaginatedFaceObjectUpdateList
     * @throws ApiError
     */
    public static objectsFacesList(
        active?: boolean,
        card?: Array<number>,
        idIn?: Array<string>,
        limit?: number,
        ordering?: string,
        page?: string,
    ): CancelablePromise<PaginatedFaceObjectUpdateList> {
        return __request({
            method: 'GET',
            path: `/objects/faces/`,
            query: {
                'active': active,
                'card': card,
                'id_in': idIn,
                'limit': limit,
                'ordering': ordering,
                'page': page,
            },
        });
    }
    /**
     * Add face object
     * This method creates new face object.
     * **Face object** is an object that contains source photo, thumbnail, and other attributes
     * @param formData
     * @returns FaceObjectUpdate
     * @throws ApiError
     */
    public static objectsFacesCreate(
        formData: FaceObjectRequest,
    ): CancelablePromise<FaceObjectUpdate> {
        return __request({
            method: 'POST',
            path: `/objects/faces/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Retrieve face object by ID
     * This method retrieve face object by id.
     * **Face object** is an object that contains source photo, thumbnail, and other attributes
     * @param id
     * @returns FaceObjectUpdate
     * @throws ApiError
     */
    public static objectsFacesRetrieve(
        id: string,
    ): CancelablePromise<FaceObjectUpdate> {
        return __request({
            method: 'GET',
            path: `/objects/faces/${id}/`,
        });
    }
    /**
     * Update face object properties
     * This method updates a face object properties.
     * @param id
     * @param formData
     * @returns FaceObjectUpdate
     * @throws ApiError
     */
    public static objectsFacesPartialUpdate(
        id: string,
        formData?: PatchedFaceObjectUpdateRequest,
    ): CancelablePromise<FaceObjectUpdate> {
        return __request({
            method: 'PATCH',
            path: `/objects/faces/${id}/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Delete face object
     * Use this method to delete a face object.
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static objectsFacesDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/objects/faces/${id}/`,
        });
    }
}