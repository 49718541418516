/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MetaDictionaryValue = {
    readonly id: number;
    dictionary: number;
    /**
     * Dictionary value
     */
    value: string;
};
export const EmptyMetaDictionaryValue: MetaDictionaryValue = {
    id: 0,
    dictionary: 0,
    /**
     * Dictionary value
     */
    value: '',
};
