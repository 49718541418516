import { isDefined } from '@/common/utils';
import { License, LicenseUsageInterval, LicenseUsageIntervalCounter, LicenseUsageIntervalCounterRelativeLimitSpending, Nullable } from './types';

const RELATIVE_LIMIT_SPENDING_APPROACHING_THRESHOLD = 0.99;
const RELATIVE_LIMIT_SPENDING_NOT_REACHED_THRESHOLD = 0.8;
const RELATIVE_LIMIT_SPENDING_REACHED_THRESHOLD = 1;

export const LICENSE_UPDATE_URL = 'https://findface.pro';

export function computeLicenseUsageIntervalCounterLimitSpending({ available, used }: LicenseUsageIntervalCounter): Nullable<number> {
  return isDefined(available) ? used / available : null;
}

export function computeLicenseUsageIntervalCounterRelativeLimitSpending(spending: number): LicenseUsageIntervalCounterRelativeLimitSpending {
  return lte(spending, RELATIVE_LIMIT_SPENDING_NOT_REACHED_THRESHOLD)
    ? 'not-reached'
    : lte(spending, RELATIVE_LIMIT_SPENDING_APPROACHING_THRESHOLD)
    ? 'approaching'
    : spending > RELATIVE_LIMIT_SPENDING_REACHED_THRESHOLD
    ? 'exceed'
    : 'reached';
}

export function computeLicenseUsageIntervalLimitSpending(interval: LicenseUsageInterval): Nullable<number> {
  const spendings = Object.values(interval.counters).map(computeLicenseUsageIntervalCounterLimitSpending).filter(isDefined);
  return spendings.length > 0 ? Math.max(...spendings) : null;
}

export function isSoftwareOfflineLicense(license: License): boolean {
  return /offline/i.test(license.type ?? '');
}

function lte(numberA: number, numberB: number): boolean {
  return numberA < numberB || Math.abs(numberA - numberB) < Number.EPSILON;
}
