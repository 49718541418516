
import { Options, Vue } from 'vue-class-component';
import NForm, { FormValidatorNames } from '@/uikit/forms/NForm.vue';
import { NInput } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import CameraGroupName from '@/components/data-source/CameraGroupName.vue';
import { CameraRequest, CamerasService } from '@/api';
import { multisidebarModule } from '@/store/multisidebar';
import { globalEventModule } from '@/store/global-event';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';

@Options({
  name: 'DataSourcesExternalDetectors',
  components: { NButton, NForm }
})
export default class DataSourcesExternalDetectors extends Vue {
  private model = {
    group: NaN,
    name: '',
    external_detector: true,
    comment: ''
  };

  private layout = [
    [
      {
        path: 'name',
        classes: 'n-form-w-100p n-form-pad-10',
        i18n_label: 'ds.external_detector_name',
        component: NInput,
        props: { i18n_placeholder: 'ds.enter_external_detector_name' },
        validators: [{ name: FormValidatorNames.Required }]
      },
      {
        path: 'group',
        classes: 'n-form-w-100p n-form-pad-10',
        i18n_label: 'ds.external_detector_camera_group',
        component: CameraGroupName,
        validators: [{ name: FormValidatorNames.Required }],
        props: { i18n_placeholder: 'ds.select_external_detector_camera_group' }
      }
    ],
    {
      path: 'comment',
      classes: 'n-form-w-100p n-form-pad-10',
      i18n_label: 'ds.description_optional',
      component: NInput,
      props: { i18n_placeholder: 'ds.enter_description_optional' }
    }
  ];

  get sidebar() {
    return multisidebarModule;
  }

  isValid() {
    const result = this.$refs.form.validate();
    if (!result) this.$refs.form.displayErrors();
    return result;
  }

  async createCamera() {
    const result = await CamerasService.camerasCreate(this.model as CameraRequest);
    globalEventModule.sendCreate(MultisidebarItemTypes.Cameras);
    return result;
  }

  onlyCreateCameras() {
    if (this.isValid()) {
      this.createCamera().finally(() => {
        this.$emit('close');
      });
    }
  }

  createAndConfig() {
    if (this.isValid()) {
      this.createCamera()
        .then((camera) => {
          this.sidebar.addItemAndOpen('cameras', camera);
        })
        .finally(() => {
          this.$emit('close');
        });
    }
  }
}
