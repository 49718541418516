
import { Vue, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NInputNumber from '../input-number/NInputNumber.vue';
import NHint from '../hint/NHint.vue';
import { round } from 'lodash';

@Options({
  name: 'NSlider',
  components: { NInputNumber, NHint }
})
export default class NSlider extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly min!: number;

  @Prop({ type: Number, default: 10 })
  readonly max!: number;

  @Prop({ type: Number })
  readonly step?: number;

  @Prop({ type: Number, default: 0 })
  readonly modelValue!: number;

  @Prop({ type: Number, default: 3 })
  readonly precision!: number;

  @Prop({ type: String, default: '' })
  readonly header?: '';

  @Prop({ type: Boolean, default: false })
  readonly controls!: false;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  @Prop({ type: Boolean, default: true })
  readonly autofocus!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasInput!: boolean;

  left = 0;
  drag = false;
  barStyle: Record<string, string> = { left: '0px' };

  mounted() {
    this.syncBarStyle();
  }

  @Watch('modelValue')
  changeModelValue(v: number) {
    this.syncBarStyle();
  }

  get tooltip() {
    let v = Number(this.modelValue);
    return Number.isNaN(v) ? '' : v.toString();
  }

  get computedStep() {
    const defaultStep = (this.max - this.min) / 20;
    return this.step || defaultStep;
  }

  get cssClass() {
    let base = 'n-slider';
    return {
      [base]: true,
      [`${base}_disabled`]: this.disabled
    };
  }

  getBarStyle(): Record<string, string> {
    const barRect = this.$refs.track?.getBoundingClientRect() || { width: 0, x: 0 };
    const buttonRect = this.$refs.thunk?.getBoundingClientRect() || { width: 0, x: 0 };
    const position = buttonRect.width + (barRect.width - buttonRect.width) * ((this.modelValue - this.min) / (this.max - this.min));
    return { left: `${position}px` };
  }

  syncBarStyle() {
    this.barStyle = this.getBarStyle();
  }

  clickHandler(e: MouseEvent) {
    if (this.disabled) return;
    this.setMousePosition(e);
  }

  setMousePosition(e: MouseEvent) {
    const barRect = this.$refs.track?.getBoundingClientRect() || { width: 0, x: 0 };
    const buttonRect = this.$refs.thunk?.getBoundingClientRect() || { width: 0, x: 0 };
    const absValue = (e.pageX - barRect.x - buttonRect.width / 2) / (barRect.width - buttonRect.width);
    let relValue = this.min + absValue * (this.max - this.min);
    this.setValue(relValue);
  }

  mouseDownHandler() {
    if (this.disabled) return;
    this.startListenMove();
  }

  mouseUpHandler(e: MouseEvent) {
    this.finishListenMove();
  }

  moveMoveHandler(e: MouseEvent) {
    this.setMousePosition(e);
  }

  focusHandler() {
    if (this.disabled) return;
  }

  blurHandler() {
    if (this.disabled) return;
  }

  keydownRightHandler() {
    if (this.disabled) return;
    this.increase();
  }

  keydownLeftHandler() {
    if (this.disabled) return;
    this.decrease();
  }

  increase() {
    const newValue = this.modelValue + this.computedStep;
    this.setValue(newValue);
  }

  decrease() {
    const newValue = this.modelValue - this.computedStep;
    this.setValue(newValue);
  }

  setValue(value: number) {
    if (this.step) {
      value = Math.round(value / this.step) / (1 / this.step);
    }
    value = value < this.min ? this.min : value;
    value = value > this.max ? this.max : value;

    value = round(value, this.precision);

    this.$emit('update:modelValue', value);
  }

  increaseHandler() {
    this.increase();
  }

  decreaseHandler() {
    this.decrease();
  }

  startListenMove() {
    this.drag = true;
    window.addEventListener('mouseup', this.mouseUpHandler);
    window.addEventListener('mousemove', this.moveMoveHandler);
  }

  finishListenMove() {
    this.drag = false;
    window.removeEventListener('mouseup', this.mouseUpHandler);
    window.removeEventListener('mousemove', this.moveMoveHandler);
  }
}
