import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicenseError = _resolveComponent("LicenseError")!
  const _component_LicensePageMeta = _resolveComponent("LicensePageMeta")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LicenseError, { license: _ctx.license }, null, 8, ["license"]),
    (_ctx.license?.source)
      ? (_openBlock(), _createBlock(_component_LicensePageMeta, {
          key: 0,
          items: _ctx.items
        }, null, 8, ["items"]))
      : _createCommentVNode("", true)
  ], 64))
}