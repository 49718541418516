import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "reports-form__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    layout: true,
    size: _ctx.size,
    label: _ctx.$t('common.create_report', 'f'),
    onClose: _ctx.closeHandler
  }, {
    content: _withCtx(() => [
      _createVNode(_component_NForm, {
        ref: "infoForm",
        layout: _ctx.layout,
        model: _ctx.model,
        class: "reports-form__form"
      }, null, 8, ["layout", "model"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_NButton, { onClick: _ctx.createHandler }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.create', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["size", "label", "onClose"]))
}