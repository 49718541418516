import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "settings__vms-cleanup-form__desc label-m"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFormBlocks = _resolveComponent("NFormBlocks")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NFormBlocks, {
      class: "settings__vms-cleanup-form",
      "data-qa": `${_ctx.type}.vms-cleanup-form`,
      ref: "form",
      blocks: _ctx.formLayout,
      model: _ctx.modelValue
    }, null, 8, ["data-qa", "blocks", "model"]),
    (!_ctx.hasVmsCleanup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('settings.vms_cleaner_desc')), 1))
      : _createCommentVNode("", true)
  ], 64))
}