import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "timeline",
  class: "video-player-timeline"
}
const _hoisted_2 = { class: "video-player-nav-left__btn" }
const _hoisted_3 = { class: "video-player-nav-right__btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", {
      ref: "canvas",
      class: "video-player-timeline__canvas",
      onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mousedown && _ctx.mousedown(...args))),
      onWheel: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.mousewheel && _ctx.mousewheel(...args)), ["prevent"]))
    }, null, 544),
    _createElementVNode("div", {
      class: "video-player-nav-left",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.moveTimeline(-1 / 5)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NIcon, {
          name: "scroll-left",
          width: "100%",
          height: "100%"
        })
      ])
    ]),
    _createElementVNode("div", {
      class: "video-player-nav-right",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.moveTimeline(1 / 5)))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NIcon, {
          name: "scroll-right",
          width: "100%",
          height: "100%"
        })
      ])
    ])
  ], 512))
}