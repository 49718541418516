import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoWallArea = _resolveComponent("VideoWallArea")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([`video-wall_grid_${_ctx.cameraIds.length}`, "video-wall"]),
    ref: "wall"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cameraIds, (id, index) => {
      return (_openBlock(), _createBlock(_component_VideoWallArea, {
        key: index,
        class: _normalizeClass(`video-wall__area-${index}`),
        draggable: _ctx.isDefined(id),
        index: index,
        onDragend: _ctx.dragend,
        onDragstart: _ctx.dragstart,
        onDrop: _ctx.drop
      }, {
        default: _withCtx(() => [
          (_ctx.isDefined(id))
            ? _renderSlot(_ctx.$slots, "camera", {
                key: 0,
                id: id
              })
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["class", "draggable", "index", "onDragend", "onDragstart", "onDrop"]))
    }), 128))
  ], 2))
}