/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AuditLogsFilter = {
    /**
     * Select audit-log entries with this `action_type`
     */
    action_type?: Array<'acknowledge' | 'auth_upload' | 'basic_auth' | 'close_multiple_sessions' | 'create' | 'destroy' | 'list' | 'merge' | 'partial_update' | 'purge' | 'purge_all' | 'restart' | 'search' | 'update' | 'upload' | 'verify' | 'video_auth' | 'video_auth_renew'>;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select audit-log entries with this `device_uid`
     */
    device_uid?: string;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select audit-log entries with this `object_id`
     */
    object_id?: number;
    /**
     * Select audit-log entries with this `object_type`
     */
    object_type?: Array<'area' | 'body and body' | 'body and car' | 'body and face' | 'bodycluster' | 'bodyevent' | 'bodyobject' | 'camera' | 'cameragroup' | 'car and body' | 'car and car' | 'car and face' | 'carcard' | 'carcardattachment' | 'carcluster' | 'carepisode' | 'carevent' | 'carobject' | 'case' | 'caseattachment' | 'caseparticipant' | 'caseparticipantattachment' | 'counter' | 'deviceblacklistrecord' | 'face and body' | 'face and car' | 'face and face' | 'facecluster' | 'faceevent' | 'faceobject' | 'ffsecauthsession' | 'group' | 'humancard' | 'humancardattachment' | 'humanepisode' | 'license' | 'report' | 'settings' | 'user' | 'videoarchive' | 'watchlist' | 'webhook'>;
    /**
     * Which field to use when ordering the results. Available fields: `id`, `created_date`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select audit-log entries with this `request_ip`
     */
    request_ip?: string;
    /**
     * Select audit-log entries that matched this `user_id`
     */
    user_id?: number;
    /**
     * Select audit-log entries with this `user_login`
     */
    user_login?: string;
}
export const EmptyAuditLogsFilter: AuditLogsFilter = {
    action_type: [],
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    device_uid: undefined,
    limit: '20',
    object_id: undefined,
    object_type: [],
    ordering: undefined,
    page: undefined,
    request_ip: undefined,
    user_id: undefined,
    user_login: undefined,
}