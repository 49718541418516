/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UserFaceFilter = {
    /**
     * Select only active objects (`true`) or inactive (`false`)
     */
    active?: boolean;
    /**
     * Select objects with provided ids
     */
    id_in?: Array<string>;
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * Which field to use when ordering the results. Available fields: `id`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select user faces for these `users`
     */
    user?: Array<number>;
}
export const EmptyUserFaceFilter: UserFaceFilter = {
    active: undefined,
    id_in: [],
    limit: 20,
    ordering: undefined,
    page: undefined,
    user: [],
}