import { ITableColumn } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import NTextWithLabel from '@/uikit/text/NTextWithLabel.vue';
import { formatDateTime } from '@/common/filters';
import { NButton, NButtonTypes } from '@/uikit';
import { dataModule } from '@/store/data';

type ITextFieldOptions = {
  width?: string;
  i18n_label: string;
  path: string;
  align?: string;
  formatter?: (v: any) => string;
  translate?: boolean;
};

function getTextColumn(options: ITextFieldOptions): ITableColumn {
  const formatter = options.formatter || ((v) => v);
  const alignClass = 'align-' + (options.align || 'left');
  const classes = ['bottom-border', alignClass];
  const labelFieldName = options.translate ? 'i18n_label' : 'label';
  const prefix = options.translate ? 'cases.filter_' : '';

  return {
    width: options.width,
    header: {
      classes,
      component: NTableColumnCell,
      props: {
        i18n_label: options.i18n_label
      }
    },
    content: {
      classes: [alignClass],
      component: NTextWithLabel,
      props: (item: any) => ({ [labelFieldName]: prefix + String(formatter(item[options.path])) })
    }
  };
}

function getLinkColumn(options: ITextFieldOptions): ITableColumn {
  const formatter = options.formatter || ((v) => v);
  const alignClass = 'align-' + (options.align || 'left');
  const classes = ['bottom-border', alignClass];

  return {
    width: options.width,
    header: {
      classes,
      component: NTableColumnCell,
      props: {
        i18n_label: options.i18n_label
      }
    },
    content: {
      path: options.path,
      component: NButton,
      classes: ['cases-table__name-column'],
      props: (item: any) => {
        const itemValue = item[options.path];
        const formattedItemValue = formatter(itemValue);

        return {
          label: formattedItemValue,
          type: NButtonTypes.Link
        };
      }
    }
  };
}

export const columns: ITableColumn[] = [
  getLinkColumn({ i18n_label: 'common.column_name', path: 'name' }),
  getLinkColumn({ width: '120px', i18n_label: 'forms.creator', path: 'author', formatter: (v) => dataModule.getUserNameById(v) }),
  getTextColumn({ width: '100px', i18n_label: 'forms.created_date', path: 'created_date', align: 'center', formatter: formatDateTime }),
  getTextColumn({ width: '100px', i18n_label: 'forms.updated_date', path: 'modified_date', align: 'center', formatter: formatDateTime }),
  getTextColumn({ width: '80px', i18n_label: 'forms.videos_count', path: 'videos_count', align: 'center' }),
  getTextColumn({ width: '92px', i18n_label: 'forms.participants_count', path: 'participants_count', align: 'center' }),
  getTextColumn({ width: '60px', i18n_label: 'forms.status', path: 'status', align: 'center', translate: true })
];
