import JSZip from 'jszip';
import { ReportArchive, ReportArchiveFile, ReportArchiveFileName, ReportArchiver } from './archiver';
import { ReportFile } from './types';

export class ReportArchiveImpl implements ReportArchive {
  private readonly archive = new JSZip();

  static create(name: ReportArchiveFileName): ReportArchiveImpl {
    return new this(name);
  }

  constructor(private readonly name: ReportArchiveFileName) {}

  append(report: ReportFile): this {
    return this.archive.file(report.name, report), this;
  }

  async compress(): Promise<ReportArchiveFile> {
    return new File([await this.archive.generateAsync({ type: 'blob' })], `${this.name}.zip`);
  }
}

export class ReportArchiverImpl implements ReportArchiver {
  static create(): ReportArchiverImpl {
    return new this();
  }

  private constructor() {}

  createArchive(name: string): ReportArchive {
    return new ReportArchiveImpl(name);
  }
}
