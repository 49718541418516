
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class NInputEye extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly modelValue!: boolean;

  get classes() {
    const root = 'n-input-eye';
    return { [root]: true, [`${root}_closed`]: !this.modelValue };
  }

  private dispatchInputEvent(): void {
    this.$emit('update:modelValue', !this.modelValue);
  }
}
