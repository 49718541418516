import { workspaceModule } from '@/store/application/workspace';
import { reactive } from 'vue';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { PagePath, PagePaths, PageState, PageType, PageTypes } from '@/store/application/page.definitions';
import { startCase } from '@/common/filters';
import { viewModelRepository } from '@/api/common';
import { RouteLocation } from 'vue-router';
import { PageViewModel, PageViewModelOptions, parseQueryObjectToPageOptions } from '@/store/application/page.view.model';
import { getPageStateByPath } from '@/store/application/page.state';

export class PageModule {
  pagesByTabMap: Record<string, PageViewModel<any, any>> = {};
  modulesByKeyMap: Record<string, any> = {};

  constructor() { }

  getPageStateByTab(path: PagePath, tab: string): PageState {
    const newState = getPageStateByPath(path);
    let state = workspaceModule.getItemByTab(tab) || newState;
    state.tab = tab;

    //@todo: Try to refactor settings
    if (state.pagePath !== path) {
      state.pagePath = newState.pagePath;
      state.pageType = newState.pageType;
    }
    return state;
  }

  getModelsKey(state: PageState): string {
    let result = '';

    if (state.pageType === PageTypes.Events) {
      result = `${state.pageType}_${state.objectType}_list`;
    } else if (state.pageType === PageTypes.Episodes) {
      result = `${state.pageType}_${state.episodeType}_list`;
    } else if (state.pageType === PageTypes.Cards) {
      result = `${state.pageType}_${state.cardType}_list`;
    } else if (state.pageType === PageTypes.Clusters) {
      result = `${state.pageType}_${state.objectType}_list`;
    } else if (state.pageType === PageTypes.BlockList) {
      result = 'device_blacklist_records_list';
    } else if (state.pageType === PageTypes.ExternalVmsCameras || state.pageType === PageTypes.ExternalDetectors) {
      result = 'cameras_list';
    } else if (state.pagePath === PagePaths.RemoteMonitoringEvents) {
      result = 'puppeteer_remote_monitoring_events_list';
    } else {
      result = `${state.pageType}_list`;
      console.warn(`[pageModule] Use default getter module ${result} for ${state}`);
    }
    return startCase(result).replace(/ /g, '');
  }

  getCacheID(state: PageState) {
    return state.tab + '__' + this.getModelsKey(state);
  }

  getPageModule(state: PageState, msbType = ''): ListViewModel<any, any> | ItemViewModel<any> {
    const modelName = this.getModelsKey(state);
    const cacheId = this.getCacheID(state);
    let module = this.modulesByKeyMap[cacheId] as unknown as ListViewModel<any, any>;
    // console.log(`>> [getPageModule] cache-id ${cacheId}, model ${modelName}, from cache ${!!module}`);

    if (!module) {
      const modelGetterName = `get${modelName}ViewModel`;
      try {
        module = (viewModelRepository as any)[modelGetterName]();
        this.setModuleOrdering(module, state.pageType);
        this.modulesByKeyMap[cacheId] = module;
      } catch (e) {
        console.warn(`[error] createLVM Cant create new model by viewModelRepository[${modelGetterName}]...`, e);
      }
    }
    return module;
  }

  setModuleOrdering(module: ListViewModel<any, any>, pageType: PageType) {
    if (([PageTypes.Events, PageTypes.Episodes, PageTypes.Cards] as PageType[]).includes(pageType)) {
      module.filter.current.ordering = '-id';
    }
    if (([PageTypes.Clusters, PageTypes.Lines, PageTypes.ExternalVms] as PageType[]).includes(pageType)) {
      module.filter.current.ordering = '-created_date';
    }
  }

  getPageViewModel(route: RouteLocation) {
    const tab = String(route.query.tab);
    const page = this.pagesByTabMap[tab] || this.createPageViewModel(route);
    this.pagesByTabMap[tab] = page;
    return page;
  }

  createPageViewModel(route: RouteLocation) {
    const options = parseQueryObjectToPageOptions(route.query);
    options.path = route.path as PagePath;
    options.pageState = workspaceModule.getItemByTab(options.tab);
    return new PageViewModel<any, any>(options as PageViewModelOptions);
  }

  getPageViewModelDirect(path: PagePath, pageState = {}, tab: string) {
    const options: PageViewModelOptions = { path, pageState, tab };
    const page = new PageViewModel<any, any>(options);
    this.pagesByTabMap[tab] = page;
    return page;
  }
}

export const pageModule = reactive<PageModule>(new PageModule());
