import { withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-color-picker__panel"
}
const _hoisted_2 = { class: "n-color-picker__canvas" }
const _hoisted_3 = ["id", "width", "height"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "n-color-picker__control" }
const _hoisted_7 = { class: "n-color-picker__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_stick = _resolveDirective("stick")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'n-color-picker',
      {
        'n-color-picker--empty': _ctx.displayedColor === 'transparent'
      }
    ]),
    onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (!_ctx.disabled), ["stop"]))
  }, [
    _createElementVNode("div", {
      class: "n-color-picker__block",
      ref: "target",
      style: _normalizeStyle({ backgroundColor: _ctx.displayedColor }),
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShowPanel && _ctx.toggleShowPanel(...args)), ["stop"]))
    }, null, 4),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.picker.showPanel)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("canvas", {
                ref: _ctx.initCanvas,
                id: _ctx.ids.canvas,
                width: _ctx.picker.width,
                height: _ctx.picker.height,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCanvasClick && _ctx.onCanvasClick(...args))),
                onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCanvasMousedown && _ctx.onCanvasMousedown(...args))),
                onMouseup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onCanvasMouseUp && _ctx.onCanvasMouseUp(...args)))
              }, null, 40, _hoisted_3),
              _createElementVNode("em", {
                class: "n-color-picker__cur",
                id: _ctx.ids.cur,
                onMousedown: _cache[4] || (_cache[4] = (e) => _ctx.onCanvasMousedown(e, 'cur'))
              }, null, 40, _hoisted_4),
              _createElementVNode("em", {
                class: "n-color-picker__bar",
                id: _ctx.ids.bar,
                onMousedown: _cache[5] || (_cache[5] = (e) => _ctx.onCanvasMousedown(e, 'bar'))
              }, null, 40, _hoisted_5)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  class: "n-color-picker__btn-clear",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)))
                }, _toDisplayString(_ctx.$t('common.clear', 'f')), 1),
                _createElementVNode("div", {
                  class: "n-color-picker__btn-confirm",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
                }, _toDisplayString(_ctx.$t('common.save', 'f')), 1)
              ])
            ])
          ])), [
            [_directive_stick, _ctx.$refs.target],
            [_directive_click_outside, _ctx.closeByOutside]
          ])
        : _createCommentVNode("", true)
    ]))
  ], 2))
}