import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "external-search-result-info" }
const _hoisted_2 = { class: "external-search-result-info__id" }
const _hoisted_3 = { class: "external-search-result-info__date" }
const _hoisted_4 = { class: "external-search-result-info__camera" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDateTimeLabel = _resolveComponent("NDateTimeLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, "#" + _toDisplayString(_ctx.item.id), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NDateTimeLabel, {
        size: "m",
        "model-value": _ctx.item.external_timestamp
      }, null, 8, ["model-value"]),
      _createTextVNode(" #" + _toDisplayString(_ctx.item.external_face_id), 1)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.item.camera) + " #" + _toDisplayString(_ctx.item.puppet_id), 1)
  ]))
}