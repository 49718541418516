import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CenterLayout = _resolveComponent("CenterLayout")!
  const _component_SessionMonitor = _resolveComponent("SessionMonitor")!
  const _component_WorkspaceLayout = _resolveComponent("WorkspaceLayout")!
  const _component_PhotoViewer = _resolveComponent("PhotoViewer")!
  const _component_ReportsForm = _resolveComponent("ReportsForm")!
  const _component_VideoPlayerDialog = _resolveComponent("VideoPlayerDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoginPage)
      ? (_openBlock(), _createBlock(_component_CenterLayout, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_WorkspaceLayout, {
          key: _ctx.languageModule.locale
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component, route }) => [
                (_openBlock(), _createBlock(_KeepAlive, null, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getProxyComponent(Component, route.query.tab)), {
                    tab: route.query.tab,
                    key: route.path + '_' + route.query.tab
                  }, null, 8, ["tab"]))
                ], 1024))
              ]),
              _: 1
            }),
            _createVNode(_component_SessionMonitor)
          ]),
          _: 1
        })),
    (_ctx.$photoViewer.options.visible)
      ? (_openBlock(), _createBlock(_component_PhotoViewer, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.$reportsForm.active.value)
      ? (_openBlock(), _createBlock(_component_ReportsForm, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.$videoPlayer.visible.value)
      ? (_openBlock(), _createBlock(_component_VideoPlayerDialog, { key: 4 }))
      : _createCommentVNode("", true)
  ], 64))
}