/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EpisodesCarsFilter = {
    /**
     * Select only acknowledged episodes (`true`) or unacknowledged (`false`) episodes
     */
    acknowledged?: boolean;
    /**
     * Select episodes acknowledged by user
     */
    acknowledged_by?: number;
    /**
     * Select only episodes with this acknowledgment reaction
     */
    acknowledged_reaction?: string;
    /**
     * Select episodes where feature `body` takes one of these values
     */
    body?: Array<string>;
    /**
     * Select episodes associated with the specified camera groups
     */
    camera_groups?: Array<number>;
    /**
     * Select episodes associated with the specified cameras
     */
    cameras?: Array<number>;
    /**
     * Select episodes with `closed_date` **greater than or equal** to this value
     */
    closed_date_gte?: string;
    /**
     * Select episodes where feature `color` takes one of these values
     */
    color?: Array<string>;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select episodes with number of events is **greater or equal than or equal** to this value
     */
    events_count_gte?: number;
    /**
     * Select episodes with provided ids
     */
    id_in?: Array<number>;
    /**
     * Select only the episode with the last car event of the given id
     */
    last_car_event?: string;
    /**
     * Select episodes with `last_car_event_date` **greater than or equal** to this value
     */
    last_car_event_date_gte?: string;
    /**
     * Select episodes where feature `license_plate_country` takes one of these values
     */
    license_plate_country?: Array<string>;
    /**
     * Select episodes where feature `license_plate_number` contains this value (case-insensitive)
     */
    license_plate_number?: Array<string>;
    /**
     * Select episodes where feature `license_plate_region` takes one of these values
     */
    license_plate_region?: Array<string>;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select episodes where feature `make` takes one of these values
     */
    make?: Array<string>;
    /**
     * Select episodes that match this card
     */
    matched_card?: number;
    /**
     * Select episodes associated with the specified watch lists
     */
    matched_lists?: Array<number>;
    /**
     * Select episodes where feature `model` takes one of these values
     */
    model?: Array<string>;
    /**
     * Select only episodes without matched car (`true`) or with matched car (`false`)
     */
    no_car_match?: string | null;
    /**
     * Select only open episodes (`true`) or closed (`false`) episodes
     */
    open?: boolean;
    /**
     * Which field to use when ordering the results. Available fields: `created_date`, `id`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select episodes where feature `special_vehicle_type` takes one of these values
     */
    special_vehicle_type?: Array<string>;
    /**
     * Select episodes associated with the specified video archives
     */
    video_archive?: Array<number>;
}
export const EmptyEpisodesCarsFilter: EpisodesCarsFilter = {
    acknowledged: undefined,
    acknowledged_by: undefined,
    acknowledged_reaction: undefined,
    body: [],
    camera_groups: [],
    cameras: [],
    closed_date_gte: undefined,
    color: [],
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    events_count_gte: undefined,
    id_in: [],
    last_car_event: undefined,
    last_car_event_date_gte: undefined,
    license_plate_country: [],
    license_plate_number: [],
    license_plate_region: [],
    limit: '20',
    make: [],
    matched_card: undefined,
    matched_lists: [],
    model: [],
    no_car_match: undefined,
    open: undefined,
    ordering: undefined,
    page: undefined,
    special_vehicle_type: [],
    video_archive: [],
}