
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { aclModule } from '@/store/acl';
import { NButton, NInput, NLoadingCircle } from '@/uikit';
import NForm from '@/uikit/forms/NForm.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCellChangePayload } from '@/uikit/table/NTableCell.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import { merge } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { columns } from './groups-columns';
import HeaderBar from '@/components/common/HeaderBar.vue';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { globalEventModule, GlobalEventName } from '@/store/global-event';
import { multisidebarModule } from '@/store/multisidebar';
import { GlobalEvent } from '@/store/global-event/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { dataModule } from '@/store/data';

const AclModelName = 'group';
const AclItemPropertyName = 'group_permissions';
const ActionPathNames = {
  Name: 'name',
  Active: 'active'
};

const SidebarType = MultisidebarItemTypes.Groups;

@Options({
  components: {
    HeaderBar,
    NForm,
    NTabs,
    NInput,
    NTable,
    NButton,
    NLoadingCircle,
    SettingsPageLayout
  }
})
export default class GroupsPage extends Vue {
  searchText = '';

  get module() {
    const module = dataModule.groupsModule;
    module.aclPrefix = 'auth';
    module.aclModelName = AclModelName;
    module.filter.current.limit = 1000;
    return module;
  }

  get selectedItemsIds() {
    return this.sidebarModule.getItemsByType(SidebarType).map((v) => v.model.item.id);
  }

  get disabledItemIds() {
    return this.itemsAcl.filter(({ acl }) => !acl.update).map(({ itemId }) => itemId);
  }

  get itemsAcl() {
    let maxAcl = { view: this.modelAcl.view, update: this.modelAcl.update, delete: this.modelAcl.delete };
    return this.items.map((item) => ({
      itemId: item.id,
      acl: aclModule.getItemAclByUserProperty(item.id, AclItemPropertyName, maxAcl)
    }));
  }

  get modelAcl() {
    return aclModule.getModelAcl(this.module);
  }

  get columns() {
    return columns;
  }

  get loading() {
    return this.module.loading;
  }

  get loaded() {
    return this.module.loaded;
  }

  get items() {
    return this.module.items;
  }

  get lastPageEvent() {
    return globalEventModule.current?.type === SidebarType ? globalEventModule.current : null;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  @Watch('lastPageEvent')
  handleGlobalEvent(event: GlobalEvent) {
    if (!event) return;
    switch (event.name) {
      case GlobalEventName.Update:
        merge(
          this.module.items.find((v) => v.id === event.payload.id),
          event.payload
        );
        break;
    }
  }

  get roles() {
    return this.items.filter((role: any) => role.name.toLowerCase().includes(this.searchText.toLowerCase()));
  }

  handleSelectChanges(changesSelectionIds: number[]) {
    this.module.items
      .filter((v) => changesSelectionIds.includes(v.id))
      .forEach((v) => {
        actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: SidebarType, rawItem: v });
      });
  }

  handleTableAction(payload: ITableCellChangePayload) {
    if (payload.type === 'change') {
      switch (payload.path) {
        case ActionPathNames.Active:
          actionHandler.run(ItemsActionNames.UpdateActiveField, { type: SidebarType, rawItem: payload.row });
          break;
        case ActionPathNames.Name:
          actionHandler.run(ItemsActionNames.ShowItem, { type: SidebarType, rawItem: payload.row });
      }
    }
  }

  addNewGroup() {
    this.sidebarModule.addNewItemByType(SidebarType);
  }
}
