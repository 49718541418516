
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { FaceCluster } from '@/api';

@Options({
  name: 'ClusterItemRow',
  components: { NThumbnail }
})
export default class ClusterItemRow extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: FaceCluster;

  @Prop({ type: Boolean, default: false })
  readonly canClose!: boolean;

  get thumbnail() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.item.best_event.thumbnail;
  }
}
