import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonModel = _resolveComponent("NButtonModel")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_ctx.hint)
    ? (_openBlock(), _createBlock(_component_NHint, {
        key: 0,
        placement: _ctx.hintPlacement,
        content: _ctx.hint
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NButtonModel, _mergeProps({
            class: `n-button-model-copy ${_ctx.contentClass}`
          }, _ctx.computedProps, {
            "onUpdate:modelValue": _ctx.copyToClipboard,
            "suffix-icon": _ctx.icon
          }), null, 16, ["class", "onUpdate:modelValue", "suffix-icon"])
        ]),
        _: 1
      }, 8, ["placement", "content"]))
    : (_openBlock(), _createBlock(_component_NButtonModel, _mergeProps({
        key: 1,
        class: `n-button-model-copy ${_ctx.contentClass}`
      }, _ctx.computedProps, {
        "onUpdate:modelValue": _ctx.copyToClipboard,
        "suffix-icon": _ctx.icon
      }), null, 16, ["class", "onUpdate:modelValue", "suffix-icon"]))
}