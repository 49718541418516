
import { defineComponent } from 'vue';
import { object } from 'vue-types';
import { LicensePageMetaItemIndicatorContent } from '../types';
import LicensePageMetaContentRegular from './LicensePageMetaContentRegular.vue';

type Content = LicensePageMetaItemIndicatorContent['content'];

export default defineComponent({
  name: 'LicensePageMetaContentIndicator',
  components: { LicensePageMetaContentRegular },
  props: {
    content: object<Content>().isRequired
  },
  computed: {
    classes() {
      const root = 'license-page-meta-content-indicator';
      return {
        [root]: true,
        [`${root}__disabled`]: this.content.disabled
      };
    }
  }
});
