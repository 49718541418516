
import { Options, Vue } from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
import WorkspaceTabs from '../launcher/WorkspaceTabs.vue';
import Launchpad from '@/components/launcher/Launchpad.vue';
import ClickOutside from '@/uikit/directives/click-outside';
import { workspaceModule } from '@/store/application/workspace';
import { authModule } from '@/store/auth';
import NButton from '@/uikit/buttons/NButton.vue';
import UserMenu from '@/components/common/UserMenu.vue';
import { formatMenuItems, getItems } from '@/store/menu';
import { settingsItemModule } from '@/store/data/SettingsItemModule';
import LicenseAgreement from '@/components/common/LicenseAgreement.vue';
import { MenuItem } from '@/store/menu/menu';
import { PagePath, PagePaths, PageState } from '@/store/application/page.definitions';
import { RouterModule } from '@/store/router';
import NIcon from '@/uikit/icons/NIcon.vue';
import { applicationModule } from '@/store/application';
import LanguageChanger from '@/components/launcher/LanguageChanger.vue';
import { alertModule } from '@/store/application/alert.module';
import NotificationHeader from '@/components/notification-header/NotificationHeader.vue';
import { PuppeteerService } from '@/api/services/PuppeteerService';
import { languageModule } from '@/store/languages';
import { multisidebarModule } from '@/store/multisidebar';
import MultisidebarContent from '@/components/multisidebar/MultisidebarContent.vue';
import MultisidebarMenu from '@/components/multisidebar/MultisidebarMenu.vue';
import DataModal from '@/components/common/DataModal.vue';
import { configModule } from '@/store/config';
import { WorkspaceTab } from '@/components/launcher/types';
import ThemeChanger from '@/components/launcher/ThemeChanger.vue';

@Options({
  name: 'WorkspaceLayout',
  components: {
    ThemeChanger,
    DataModal,
    MultisidebarMenu,
    MultisidebarContent,
    NotificationHeader,
    LanguageChanger,
    NIcon,
    UserMenu,
    NButton,
    Launchpad,
    WorkspaceTabs,
    LicenseAgreement
  },
  directives: {
    ClickOutside
  }
})
export default class WorkspaceLayout extends Vue {
  tabKey = 'launcher';

  launchpad = false;
  launchpadLeft = 0;
  intViewportWidth = 0;
  showLicense = false;

  toggleLaunchpad() {
    const nv = !this.launchpad;
    setTimeout(() => {
      this.launchpad = nv;
    }, 100);
  }

  closeLauncher() {
    this.launchpad = false;
  }

  updateLaunchpadLeft(value: number) {
    this.intViewportWidth = window.innerWidth;
    this.launchpadLeft = value;
  }

  get contentDecreased() {
    return this.sidebarModule.active && this.sidebarModule.currentItem && !this.sidebarModule.contentOverlap;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get workspaceModule() {
    return workspaceModule;
  }

  get applicationModule() {
    return applicationModule;
  }

  get configModule() {
    return configModule;
  }

  get isLauncherPage(): boolean {
    return this.tabKey !== 'launcher';
  }

  get alertModule() {
    return alertModule;
  }

  async logout() {
    try {
      await authModule.logout();
    } catch (e) {
      console.warn('Error on logout', e);
    }

    try {
      workspaceModule.reset();
      this.$router.push({ path: PagePaths.Login });
    } catch (e) {
      console.warn('Error on logout', e);
    }
  }

  //@todo: refactor
  get launchpadPos() {
    const delta = 217;
    let maxLeft = this.intViewportWidth - 230;
    let arrowLeft = delta - (maxLeft - this.launchpadLeft);
    if (maxLeft > this.launchpadLeft) {
      maxLeft = this.launchpadLeft;
      arrowLeft = delta;
    }
    if (this.launchpadLeft < 200) {
      maxLeft = 250;
      arrowLeft = 100;
    }
    return {
      containerLeft: maxLeft,
      arrowLeft: arrowLeft
    };
  }

  get items(): MenuItem[] {
    return getItems();
  }
  get formattedItems() {
    return formatMenuItems(this.$t, this.items);
  }

  @Ref('tab')
  tabsComponent!: WorkspaceTabs;

  openNewTab(path: PagePath) {
    workspaceModule.addItem(path);
    this.launchpad = false;
  }

  removeTab(i: number) {
    workspaceModule.removeItem(i);
  }

  @Watch('workspaceModule.currentItem', { deep: true })
  workspaceCurrentWatcher(value?: PageState) {
    if (value) {
      const to = { path: value.pagePath, query: RouterModule.pageStateToQuery(value) };
      this.$router.push(to);
    }
    if (workspaceModule.current === 'launcher') {
      const to = { path: PagePaths.Launcher, query: RouterModule.pageStateToQuery({}) };
      this.$router.push(to);
    }
  }

  @Watch('$route.query')
  changeRouteQuery() {
    workspaceModule.changeRouteHandler(this.$route);
  }

  get module() {
    return settingsItemModule;
  }

  @Watch('module.item.license_accepted', { immediate: true })
  checkLicenseAccept(v: boolean) {
    this.showLicense = Boolean(settingsItemModule.loaded && !v);
  }

  async proceedHandler() {
    if (!this.module.item) return;
    try {
      await settingsItemModule.saveFields({ license_accepted: true });
    } finally {
      this.module.item.license_accepted = true;
    }
  }

  handleContextClick(e: Event, tab: WorkspaceTab) {}

  handleLogoClick() {
    workspaceModule.current = 'launcher';
  }

  async acknowledgeAll() {
    await PuppeteerService.puppeteerRemoteMonitoringEventsAcknowledgeAllCreate();
  }

  navigateToEventsPage() {
    const eventPage = applicationModule.isDefaultProduct ? PagePaths.Events : PagePaths.RemoteMonitoringEvents;
    this.$router.push(RouterModule.getRouteLocation({ path: eventPage, pageState: { filter: { acknowledged: 'False' } } }));
  }

  exportPot() {
    languageModule.convertJsToPot();
  }

  @Watch('workspaceModule.current', { immediate: true })
  handleWorkspaceTabChange(current: string): void {
    document.title = this.findWorkspaceTabById(current)?.label ?? configModule.defaultTitle;
  }

  findWorkspaceTabById(tab: string) {
    return this.workspaceModule.getTabItemByTab(tab);
  }
}
