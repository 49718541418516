
import { defineComponent } from 'vue';
import { arrayOf, object } from 'vue-types';
import { LicenseLimit } from '../../../types';
import { LicensePageMeta } from '../../meta';
import { formatLicenseLimit } from './formatters/limits';

export default defineComponent({
  name: 'LicensePageTabViewLimits',
  components: { LicensePageMeta },
  props: {
    limits: arrayOf(object<LicenseLimit>()).isRequired
  },
  computed: {
    items() {
      return this.limits.map((limit) => formatLicenseLimit(limit, this.$t));
    }
  }
});
