import { Camera, CameraGroup, User, VideoArchive, WatchList } from '@/api';
import { viewModelRepository } from '@/api/common';
import { CameraGroupsFilter } from '@/api/models/CameraGroupsFilter';
import { WatchListsFilter } from '@/api/models/WatchListsFilter';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { ListViewModel } from '@/definitions/view-models';
import { configModule } from '@/store/config';
import { filterManagerModule } from '@/components/common/filter/filter-manager';
import { settingsItemModule } from './SettingsItemModule';
import { localStorageModule } from '@/store/application/local.storage';
import { userStorageModule } from '@/store/application/user.storage';
import { applicationModule } from '@/store/application';
import { languageModule } from '@/store/languages';
import { workspaceModule } from '@/store/application/workspace';
import { smallFilterManagerModule } from '@/components/common/filter/filter-manager/SmallFiltersModule';
import { watch } from 'vue';
import { globalEventModule } from '@/store/global-event';
import { handleGlobalEventForDataModule } from '@/store/data/handleGlobalEventForDataModule';
import { LicenseFeatures, licenseModule } from '@/store/config/LicenseModule';

export const DictsUrl = 'dicts.json';

export class DataState {
  watchListsModule: ListViewModel<WatchList, WatchListsFilter> = viewModelRepository.getWatchListsListViewModel();
  cameraGroupsModule: ListViewModel<CameraGroup, CameraGroupsFilter> = viewModelRepository.getCameraGroupsListViewModel();
  camerasModule = viewModelRepository.getCamerasListViewModel();
  videosModule = viewModelRepository.getVideosListViewModel();
  currentUserModule = viewModelRepository.getUsersItemViewModel();
  groupsModule = viewModelRepository.getGroupsListViewModel();
  usersModule = viewModelRepository.getUsersListViewModel();
  linesModule = viewModelRepository.getLinesListViewModel();
  dicts: Record<string, any> = {};

  get cameraGroupsLabels(): string[] {
    const items = this.cameraGroupsModule.items;
    const labelsMap = items.reduce<Record<string, string>>((m, v) => {
      Object.assign(m, v.labels);
      return m;
    }, {});
    return Object.keys(labelsMap);
  }

  get watchListsWithoutUnknown() {
    return this.watchListsModule.items.filter((v) => v.id !== -1);
  }

  get editableWatchListsByCurrentUser() {
    const watchListPermissions = this.currentUserModule.item?.watch_list_permissions;
    return this.watchListsModule.items.filter((v) => watchListPermissions?.[String(v.id)] === 'edit');
  }

  getIsEditableWatchList(id: number) {
    const watchListPermissions = this.currentUserModule.item?.watch_list_permissions;
    return watchListPermissions?.[String(id)] === 'edit';
  }

  get cameraGroupsWithoutVideoGroups() {
    return this.cameraGroupsModule.items.filter((v) => v.id >= 1);
  }

  get isCurrentUserAdmin() {
    let user = this.currentUserModule.item;
    return user && (user.primary_group === 1 || user.groups?.includes(1));
  }

  async load(options = { cameras: true }) {
    await this.loadCurrentUserModule();
    this.setLocalStorageUserKey();

    await userStorageModule.getUserData();
    await userStorageModule.syncAllFromStorage();
    await smallFilterManagerModule.loadUserFilters();
    await settingsItemModule.get();
    this.setCurrentUserLocale();
    await applicationModule.initTheme();

    await licenseModule.load();
    workspaceModule.removeItemsWithoutViewRights();
    await this.loadGroups();
    await this.loadCameraGroups();
    await this.loadCameras();
    await this.loadVideos();
    await this.loadWatchLists();
    await this.loadUsers();
    await this.loadLines();

    try {
      await filterManagerModule.getUsersSavedData();
    } catch (e: any) {
      console.warn('[user-me-data] load error', e);
    }
    await this.loadDicts();
  }

  setLocalStorageUserKey() {
    try {
      const newKey = localStorageModule.setKey(this.currentUserModule.item!.name);
      if (newKey) localStorageModule.syncAllFromStorage();
    } catch (e) {
      console.warn('[setLocalStorageUserKey] error ', e);
    }
  }

  async loadCurrentUserModule() {
    await this.currentUserModule.get('me');
  }

  async loadDicts() {
    if (configModule.config.dicts) {
      this.dicts = configModule.config.dicts; //@for-test
      return;
    }

    try {
      await dataServiceFactory
        .getAxiosInstance()
        .get(DictsUrl)
        .then((v) => {
          this.dicts = v.data;
        });
    } catch (e: any) {
      console.warn('[dicts] load error', e);
    }
  }

  getUserById(id: number): User | undefined {
    return this.usersModule.items.find((v) => v.id === id);
  }

  getUserNameById(id: number): string {
    const user = this.getUserById(id);
    return user?.real_name || String(id);
  }

  setCurrentUserLocale() {
    const userLocale = this.currentUserModule.item?.language;
    if (userLocale && configModule.canLanguageSelect) {
      languageModule.setLocale(userLocale);
    }
  }

  syncCurrentUserLocale() {
    const userLocale = dataModule.currentUserModule.item!.language;
    if (userLocale === languageModule.locale) return;
    dataModule.currentUserModule.item!.language = languageModule.locale as any;
    dataModule.currentUserModule.save();
  }

  async loadWatchLists() {
    try {
      this.watchListsModule.filter.current.limit = 1000;
      await this.watchListsModule.get();
    } catch (e: any) {
      console.warn('[watch-lists] load error', e);
    }
  }

  async updateWatchList(item: WatchList) {
    const updatedItem = this.watchListsModule.items.find((v) => v.id === item.id);
    if (updatedItem) {
      Object.assign(updatedItem, item);
    }
  }

  async loadCameraGroups() {
    try {
      this.cameraGroupsModule.filter.current.limit = 1000;
      await this.cameraGroupsModule.get();
    } catch (e: any) {
      console.warn('[cg] load error', e);
    }
  }

  async updateCameraGroup(item: CameraGroup) {
    const updatedItem = this.cameraGroupsModule.items.find((v) => v.id === item.id);
    if (updatedItem) {
      Object.assign(updatedItem, item);
    }
  }

  async loadCameras() {
    try {
      this.camerasModule.filter.current.limit = '1000';
      await this.camerasModule.get();
    } catch (e: any) {
      console.warn('[cameras] load error', e);
    }
  }

  async loadVideos() {
    try {
      this.videosModule.filter.current.limit = '1000';
      await this.videosModule.get();
    } catch (e: any) {
      console.warn('[videos] load error', e);
    }
  }

  async updateCamera(item: Camera) {
    const updatedItem = this.camerasModule.items.find((v) => v.id === item.id);
    if (updatedItem) {
      Object.assign(updatedItem, item);
    }
  }

  async updateVideo(item: VideoArchive) {
    const updatedItem = this.videosModule.items.find((v) => v.id === item.id);
    if (updatedItem) {
      Object.assign(updatedItem, item);
    }
  }

  async loadGroups() {
    try {
      this.groupsModule.filter.current.limit = 1000;
      await this.groupsModule.get();
    } catch (e: any) {
      console.warn('[groups] load error', e);
    }
  }
  async loadUsers() {
    try {
      this.usersModule.filter.current.limit = '1000';
      await this.usersModule.get();
    } catch (e: any) {
      console.warn('[roles] load error', e);
    }
  }

  async loadLines() {
    if (!licenseModule.features[LicenseFeatures.LineCrossing]) return;
    try {
      this.linesModule.filter.current.limit = '1000';
      await this.linesModule.get();
    } catch (e: any) {
      console.warn('[lines] load error', e);
    }
  }

}

export const dataModule: DataState = new DataState();
watch(() => globalEventModule.current, handleGlobalEventForDataModule.bind(null, dataModule));
