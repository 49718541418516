/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { str_int_float_bool_object_array_null } from './str_int_float_bool_object_array_null';
export type CarClusterEvent = {
    readonly id: number;
    readonly attributes: Record<string, str_int_float_bool_object_array_null>;
    cluster: number;
    /**
     * Cluster event creation date
     */
    readonly created_date: string;
    /**
     * Cluster event modification date
     */
    readonly modified_date: string;
    /**
     * Event score; Used during clusterization
     */
    readonly score: number;
    /**
     * Cluster event face bbox
     */
    readonly bbox: Array<number>;
    /**
     * Cluster event thumbnail
     */
    readonly thumbnail: string;
    /**
     * Cluster event full frame
     */
    readonly fullframe: string;
    /**
     * End date of the related episode
     */
    readonly episode_last_event_date: string;
    /**
     * Face bbox of the episode last event
     */
    readonly episode_last_event_bbox: Array<number>;
    /**
     * Thumbnail of the episode last event
     */
    readonly episode_last_event_thumbnail: string;
    /**
     * Full frame of the episode last event
     */
    readonly episode_last_event_fullframe: string;
    /**
     * Card match confidence
     */
    readonly match_confidence: number;
    /**
     * Event's camera
     */
    readonly camera: number;
    /**
     * Related case
     */
    readonly case: number;
    /**
     * Related episode
     */
    readonly episode: number;
    /**
     * Matched card
     */
    readonly matched_card: number;
    /**
     * Cluster event camera groups list
     */
    readonly camera_groups: Array<number>;
    /**
     * Matched watch lists
     */
    readonly matched_lists: Array<number>;
};
export const EmptyCarClusterEvent: CarClusterEvent = {
    id: 0,
    attributes: {},
    cluster: 0,
    /**
     * Cluster event creation date
     */
    created_date: '',
    /**
     * Cluster event modification date
     */
    modified_date: '',
    /**
     * Event score; Used during clusterization
     */
    score: 0,
    /**
     * Cluster event face bbox
     */
    bbox: [],
    /**
     * Cluster event thumbnail
     */
    thumbnail: '',
    /**
     * Cluster event full frame
     */
    fullframe: '',
    /**
     * End date of the related episode
     */
    episode_last_event_date: '',
    /**
     * Face bbox of the episode last event
     */
    episode_last_event_bbox: [],
    /**
     * Thumbnail of the episode last event
     */
    episode_last_event_thumbnail: '',
    /**
     * Full frame of the episode last event
     */
    episode_last_event_fullframe: '',
    /**
     * Card match confidence
     */
    match_confidence: 0,
    /**
     * Event's camera
     */
    camera: 0,
    /**
     * Related case
     */
    case: 0,
    /**
     * Related episode
     */
    episode: 0,
    /**
     * Matched card
     */
    matched_card: 0,
    /**
     * Cluster event camera groups list
     */
    camera_groups: [],
    /**
     * Matched watch lists
     */
    matched_lists: [],
};
