import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "heading-l" }
const _hoisted_2 = {
  key: 0,
  class: "heading-l"
}
const _hoisted_3 = {
  key: 2,
  class: "heading-3xl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartGraph = _resolveComponent("ChartGraph")!
  const _component_ChartStatistic = _resolveComponent("ChartStatistic")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createBlock(_component_Chart, {
    loading: _ctx.item.loading,
    active: _ctx.item.active,
    ref: "root"
  }, {
    graph: _withCtx(() => [
      _createVNode(_component_ChartGraph, { options: _ctx.options }, null, 8, ["options"])
    ]),
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.item.i18n_label, 'f')), 1)
    ]),
    subheader: _withCtx(() => [
      (_ctx.full)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.precisionHeader), 1))
        : (_ctx.statistics)
          ? (_openBlock(), _createBlock(_component_ChartStatistic, {
              key: 1,
              statistics: _ctx.statistics
            }, null, 8, ["statistics"]))
          : (_ctx.isSummaryExists)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.summary), 1))
            : _createCommentVNode("", true)
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('download', _ctx.$refs.root.$el))),
        icon: "download",
        type: "text"
      }),
      _createVNode(_component_NButton, {
        onAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('show-fullscreen'))),
        icon: _ctx.full ? 'fullscreen-exit' : 'fullscreen',
        type: "text"
      }, null, 8, ["icon"])
    ]),
    placeholder: _withCtx(() => [
      _createVNode(_component_NButton, {
        type: "secondary",
        onAction: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('activate')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('kyc.enable', 'f')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading", "active"]))
}