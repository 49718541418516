import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "n-select-tags-count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NCounter = _resolveComponent("NCounter")!
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NCounter, {
      class: "n-select-tags-count__count",
      value: _ctx.counterValue,
      size: "S"
    }, null, 8, ["value"]),
    _createVNode(_component_NIcon, {
      name: "more",
      width: "20",
      height: "20"
    })
  ]))
}