export default {
  all: 'Все',
  current: 'Текущий',
  buy_or_update_license: 'Купить или обновить лицензию',
  common: 'Общее',
  disabled: 'ОТКЛЮЧЕНО',
  download_c2v: 'Скачать C2V',
  enabled: 'Включено',
  generated: 'Сгенерирован',
  intervals: 'Интервалы',
  last_updated: 'Последнее обновление',
  left: 'осталось',
  license_id: 'ID лицензии',
  limits: 'Ограничения',
  no: 'Нет',
  second_ago_pf: 'секунд назад',
  services: 'Службы',
  source: 'Файл',
  type: 'Тип лицензии',
  upload_new_license_file: 'Загрузить новый файл лицензии',
  valid: 'Действующая',
  yes: 'Да'
};
