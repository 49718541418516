import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedMetaFields, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "card__meta-field control-m",
        key: item.name
      }, _toDisplayString(item.label) + ": " + _toDisplayString(item.value), 1))
    }), 128))
  ]))
}