import NInput from '@/uikit/input/NInput.vue';
import { FormValidatorNames } from '@/uikit/forms/NForm.vue';
import ParticipantRole from './ParticipantRole.vue';
import ParticipantAttachments from './ParticipantAttachments.vue';
import { CaseParticipant } from '@/api';

export const informationLayout = [
  [
    {
      path: 'name',
      classes: 'n-form-w-4 n-form-pad-10',
      i18n_label: 'cases.name',
      component: NInput,
      validators: [{ name: FormValidatorNames.Required }]
    },
    {
      path: 'role',
      classes: 'n-form-w-2 n-form-pad-10',
      i18n_label: 'cases.type',
      component: ParticipantRole,
      validators: [{ name: FormValidatorNames.Required }]
    }
  ],
  {
    path: 'comment',
    classes: 'n-form-w-6 n-form-pad-10',
    i18n_label: 'cases.comment',
    component: NInput
  }
];

export const attachmentsLayout = [
  {
    classes: 'n-form-w-6 n-form-block-start',
    component: ParticipantAttachments,
    props: function (item: CaseParticipant) {
      return { id: item.id };
    }
  }
];
