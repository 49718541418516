
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ThumbnailItem from '@/components/common/ThumbnailItem.vue';
import SearchSidebarCard from './SearchSidebarCard.vue';
import { SearchFromMap } from '@/store/application/data.assets';
import { SearchModule } from '@/pages/search/searchModule';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'SearchSidebar',
  components: { NButton, NLoadingCircle, ThumbnailItem, SearchSidebarCard },
  emits: ['showDetectDialog']
})
export default class SearchSidebar extends Vue {
  @Prop({ type: Object, required: true })
  searchModule!: SearchModule;

  private get state() {
    return this.searchModule.state;
  }

  get isFile() {
    return this.state.searchFrom === SearchFromMap.File;
  }

  get isCard() {
    return this.state.searchFrom === SearchFromMap.Cards;
  }

  get itemThumbnailAndName() {
    const item = this.searchModule.item;
    switch (this.state.searchFrom) {
      case SearchFromMap.Events:
        return { thumbnail: item.thumbnail, name: item.id };
      case SearchFromMap.Clusters:
        return { thumbnail: item.best_event.thumbnail, name: item.id };
      case SearchFromMap.File:
        return { thumbnail: this.searchModule.bboxThumbnail, name: this.state.id };
      default:
        return {};
    }
  }
}
