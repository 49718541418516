import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "login-plugins" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginLogo = _resolveComponent("LoginLogo")!
  const _component_LoginFaceForm = _resolveComponent("LoginFaceForm")!
  const _component_LoginFaceInvitation = _resolveComponent("LoginFaceInvitation")!
  const _component_LoginWaitFaceAuth = _resolveComponent("LoginWaitFaceAuth")!
  const _component_LoginPasswordForm = _resolveComponent("LoginPasswordForm")!
  const _component_LoginCryptoProForm = _resolveComponent("LoginCryptoProForm")!
  const _component_LoginADForm = _resolveComponent("LoginADForm")!
  const _component_LanguageChanger = _resolveComponent("LanguageChanger")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_LoginLayout = _resolveComponent("LoginLayout")!

  return (_openBlock(), _createBlock(_component_LoginLayout, {
    "end-center": _ctx.isWaitingFaceAuth,
    key: _ctx.languageModule.locale,
    "start-wide": _ctx.isStreamActive
  }, {
    start: _withCtx(() => [
      _createVNode(_component_LoginLogo, { small: _ctx.isStreamActive }, null, 8, ["small"]),
      (_ctx.isStreamActive)
        ? (_openBlock(), _createBlock(_component_LoginFaceForm, {
            key: 0,
            stream: _ctx.stream,
            onLogin: _ctx.handleFaceLogin
          }, null, 8, ["stream", "onLogin"]))
        : (_ctx.isFaceInvitationVisible)
          ? (_openBlock(), _createBlock(_component_LoginFaceInvitation, {
              key: 1,
              onInvite: _ctx.tryCreateVideoStream
            }, null, 8, ["onInvite"]))
          : _createCommentVNode("", true)
    ]),
    end: _withCtx(() => [
      _withDirectives(_createVNode(_component_LoginWaitFaceAuth, null, null, 512), [
        [_vShow, _ctx.isWaitingFaceAuth]
      ]),
      _withDirectives(_createVNode(_component_LoginPasswordForm, {
        onSubmit: _ctx.handlePasswordFormSubmit,
        "has-credentials-error": _ctx.hasCredentialsError,
        "is-submit-button-active": _ctx.isSubmitButtonActive,
        "is-loading": _ctx.isLoading
      }, null, 8, ["onSubmit", "has-credentials-error", "is-submit-button-active", "is-loading"]), [
        [_vShow, !_ctx.isWaitingFaceAuth]
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.cryptoProEnabled)
          ? (_openBlock(), _createBlock(_component_LoginCryptoProForm, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.activeDirectoryEnabled)
          ? (_openBlock(), _createBlock(_component_LoginADForm, {
              key: 1,
              onLogin: _ctx.handleSuccessLogin
            }, null, 8, ["onLogin"]))
          : _createCommentVNode("", true)
      ])
    ]),
    "action-bar": _withCtx(() => [
      (_ctx.configModule.canLanguageSelect)
        ? (_openBlock(), _createBlock(_component_LanguageChanger, {
            key: 0,
            "sync-current-user-locale": false,
            "has-label": ""
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ThemeChanger, { "has-label": "" })
    ]),
    _: 1
  }, 8, ["end-center", "start-wide"]))
}