import { OptionalIFormLayout } from '@/components/common/filter/filters/types';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { PageType, PageTypes } from '@/store/application/page.definitions';
import { dataModule } from '@/store/data';

export function getDataSourceCamerasFilter(options: { small: boolean; pageType?: PageType }): OptionalIFormLayout[] {
  const items: (OptionalIFormLayout | null)[] = [commonFilterSchemaModule.getNameContains(options)];

  switch (options.pageType) {
    case PageTypes.Videos:
      items.push(commonFilterSchemaModule.getCameraGroups(options));
      items.push(commonFilterSchemaModule.getCreatedDateTimeRange(options));
      break;
    case PageTypes.Cameras:
      items.push(commonFilterSchemaModule.getCameraGroups({ ...options, items: dataModule.cameraGroupsWithoutVideoGroups }));
      items.push(commonFilterSchemaModule.getActiveStatus(options), commonFilterSchemaModule.getCameraStatus(options));
      break;
    case PageTypes.ExternalDetectors:
      items.push(commonFilterSchemaModule.getCameraGroups({ ...options, items: dataModule.cameraGroupsWithoutVideoGroups }));
      break;
  }
  return items.filter((v) => !!v) as OptionalIFormLayout[];
}
