
import { Options, Prop, Vue } from 'vue-property-decorator';
import type { VideoWallPreset } from '../../types';
import VideoWallPresetSelectItem from './VideoWallPresetSelectItem.vue';

@Options({
  components: { VideoWallPresetSelectItem },
  emits: ['delete', 'select']
})
export default class VideoWallPresetSelect extends Vue {
  @Prop({ required: true, type: Array })
  readonly presets!: VideoWallPreset[];

  dispatchDeleteEvent(preset: VideoWallPreset): void {
    this.$emit('delete', preset);
  }

  dispatchSelectEvent(preset: VideoWallPreset): void {
    this.$emit('select', preset);
  }
}
