import { VideoWallPageState } from '@/pages/video-wall/types';
import { EventDetails } from '@/uikit/thumbnail/helpers/enums';

export enum DisplayType { // todo:
  Short = 'short',
  Full = 'full',
  Table = 'table'
}

export type SearchFrom = 'events' | 'cards' | 'clusters' | 'cases' | 'file' | ''; // todo:

export const PageTypes = {
  Analytics: 'analytics',
  AreaActivations: 'area-activations',
  AreaRecords: 'area-records',
  Areas: 'areas',
  AuditLogs: 'audit-logs',
  BlockList: 'block-list',
  CameraGroups: 'camera-groups',
  Cameras: 'cameras',
  Cards: 'cards',
  Case: 'case',
  Cases: 'cases',
  ClusterEvents: 'cluster-events',
  Clusters: 'clusters',
  CounterRecords: 'counter-records',
  Counters: 'counters',
  Episodes: 'episodes',
  Events: 'events',
  ExternalDetectors: 'external-detectors',
  ExternalSearch: 'external-search',
  ExternalVms: 'external-vms',
  ExternalVmsCameras: 'external-vms-cameras',
  Hooks: 'hooks',
  Interactions: 'interactions',
  Interface: 'interface',
  License: 'license',
  Lines: 'lines',
  None: 'none',
  Participants: 'participants',
  RemoteMonitoringEvents: 'remote-monitoring-events',
  Reports: 'reports',
  Roles: 'roles',
  Search: 'search',
  Sessions: 'sessions',
  Users: 'users',
  Verify: 'verify',
  VideoWall: 'video-wall',
  Videos: 'videos',
  WatchLists: 'watch-lists'
} as const;

export const PageRoutes = {
  Analytics: PageTypes.Analytics,
  Areas: PageTypes.Areas,
  AuditLogs: PageTypes.AuditLogs,
  BlockList: PageTypes.BlockList,
  CameraGroups: PageTypes.CameraGroups,
  Cards: PageTypes.Cards,
  Case: PageTypes.Case,
  Cases: PageTypes.Cases,
  Clusters: PageTypes.Clusters,
  Counters: PageTypes.Counters,
  DataSources: 'data-sources',
  Events: PageTypes.Events,
  ExternalSearch: PageTypes.ExternalSearch,
  ExternalVms: PageTypes.ExternalVms,
  Hooks: PageTypes.Hooks,
  Interactions: PageTypes.Interactions,
  Interface: PageTypes.Interface,
  Launcher: 'launcher',
  License: PageTypes.License,
  Lines: PageTypes.Lines,
  Login: 'login',
  NotFound: 'not-found',
  Participants: PageTypes.Participants,
  RemoteMonitoringEvents: PageTypes.RemoteMonitoringEvents,
  Reports: PageTypes.Reports,
  Roles: PageTypes.Roles,
  Search: PageTypes.Search,
  Sessions: PageTypes.Sessions,
  Settings: 'settings',
  Users: PageTypes.Users,
  Verify: PageTypes.Verify,
  VideoWall: PageTypes.VideoWall,
  WatchLists: PageTypes.WatchLists
} as const;

function generatePathsFromRoutes(routes: any): PagePathsMap {
  const pathsMap: any = { Launcher: '/' };
  for (const key in routes) {
    if (key !== 'Launcher' && key !== 'NotFound') {
      pathsMap[key] = `/${routes[key]}`;
    }
  }

  return pathsMap;
}

type PagePathsMap = Omit<{ [K in keyof typeof PageRoutes]: `/${typeof PageRoutes[K]}` }, 'NotFound' | 'Launcher'> & { Launcher: '/' };
export type PagePath = PagePathsMap[keyof PagePathsMap];
export const PagePaths: PagePathsMap = generatePathsFromRoutes(PageRoutes);

export type PageRoute = typeof PageRoutes[keyof typeof PageRoutes];
export type PageType = typeof PageTypes[keyof typeof PageTypes];

export type PageState = {
  tab: string;
  pagePath: PagePath; // /data-sources
  pageType: PageType; // cameras | videos | external-detectors
  cardType?: string;
  objectType?: string;
  episodeType?: string;
  displayType?: DisplayType;
  id?: string | number;
  filter?: Record<string, any>;
  defaultAction?: string;
  showLeftBar?: boolean;
  showOverlay?: boolean;
  showMainHeader?: boolean;
  playing?: boolean;
  searchFrom?: SearchFrom;
  subPage?: string;
  additionalId?: number;
};

export type SearchPageState = PageState & {
  cardType: string;
  objectType: string;
  episodeType: string;
  detectionId?: string;
  cardLooksLike?: string[];
};

export type EventsPageState = PageState & {
  objectType: string;
  episodeType: string;
  displayType: DisplayType;
};

export const EmptyDefaultPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Launcher,
  pageType: PageTypes.None
};

export const EmptyLinesPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Lines,
  pageType: PageTypes.Lines
};

export const EmptyDatasourcePageState: PageState = {
  tab: '',
  pagePath: PagePaths.DataSources,
  pageType: PageTypes.Cameras
};

export const EmptyEventPageState: EventsPageState = {
  tab: '',
  pagePath: PagePaths.Events,
  pageType: PageTypes.Episodes,
  objectType: 'faces',
  episodeType: 'humans',
  displayType: DisplayType.Short,
  defaultAction: EventDetails.ShowInfo,
  playing: true
};

export const EmptyCardPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Cards,
  pageType: PageTypes.Cards,
  cardType: 'humans',
  displayType: DisplayType.Short,
  defaultAction: EventDetails.ShowInfo
};

export const EmptyClusterPageState: PageState = {
  tab: '',
  playing: true,
  pagePath: PagePaths.Clusters,
  pageType: PageTypes.Clusters,
  objectType: 'faces',
  defaultAction: EventDetails.ShowInfo
};

export const EmptyParticipantsPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Participants,
  pageType: PageTypes.Participants,
  objectType: 'faces',
  defaultAction: EventDetails.ShowInfo
};

export const EmptyCasesPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Cases,
  pageType: PageTypes.Cases,
  showOverlay: false
};

export const EmptyAuditLogsPageState: PageState = {
  tab: '',
  pagePath: PagePaths.AuditLogs,
  pageType: PageTypes.AuditLogs,
  displayType: DisplayType.Short
};

export const EmptySessionPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Sessions,
  pageType: PageTypes.Sessions,
  showLeftBar: true,
  showOverlay: false
};

export const EmptyBlockListPageState: PageState = {
  tab: '',
  pagePath: PagePaths.BlockList,
  pageType: PageTypes.BlockList,
  defaultAction: EventDetails.ShowInfo,
  showLeftBar: true,
  showOverlay: false
};

export const EmptySearchPageState: SearchPageState = {
  tab: '',
  pagePath: PagePaths.Search,
  pageType: PageTypes.Cards,
  cardType: 'humans',
  objectType: 'faces',
  episodeType: 'humans',
  displayType: DisplayType.Short,
  defaultAction: EventDetails.ShowInfo,
  showOverlay: false,
  detectionId: '',
  searchFrom: ''
};

export const EmptyExternalMonitoringEventsPage: PageState = {
  tab: '',
  pagePath: PagePaths.RemoteMonitoringEvents,
  pageType: PageTypes.None,
  playing: true
};

export const EmptyExternalVMSPageState: PageState = {
  tab: '',
  pagePath: PagePaths.ExternalVms,
  pageType: PageTypes.ExternalVms,
  showLeftBar: true,
  showOverlay: false
};

export const EmptyWebhooksPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Hooks,
  pageType: PageTypes.Hooks,
  playing: true
};

export const EmptyVideoWallPageState: VideoWallPageState = {
  layout: 'videowall-and-sidebar',
  pagePath: PagePaths.VideoWall,
  pageType: PageTypes.VideoWall,
  preset: {
    cameraIds: [null],
    id: null,
    layout: '1_camera',
    name: ''
  },
  tab: ''
};
