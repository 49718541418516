
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NIcon from '../icons/NIcon.vue';
import NCounter from '../counters/NCounter.vue';

@Options({
  name: 'NSelectTagsCount',
  components: { NCounter, NIcon }
})
export default class NSelectTagsCount extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly counterValue!: number;
}
