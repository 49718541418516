/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HooksFilter = {
    /**
     * Number of results to return.
     */
    limit?: number;
    /**
     * Which field to use when ordering the results. Available fields: `id`
     */
    ordering?: string;
}
export const EmptyHooksFilter: HooksFilter = {
    limit: 20,
    ordering: undefined,
}