import { dataModule } from '@/store/data';
import { intersection } from 'lodash';
import { configAclModule } from '@/store/config/acl';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { User } from '@/api';
import { ItemAclResult, ModelAclResult, PermissionsMap } from '@/store/acl/types';

export const DefaultModelAclResult = {
  add: true,
  view: true,
  update: true,
  delete: true
};

export const DefaultItemAclResult = {
  view: true,
  update: true,
  delete: true
};

export const AclPuppeteerModelNames = {
  ExternalSearch: 'searchrequest'
};

export const AclModelNames = {
  FaceObject: 'faceobject',
  BodyObject: 'bodyobject',
  CarObject: 'carobject',
  FaceCluster: 'facecluster',
  BodyCluster: 'bodycluster',
  CarCluster: 'carcluster'
};

export const AclPrefixNames = {
  Puppeteer: 'ffsecurity_puppeteer'
};

const Conditions = {
  And: 'and',
  Or: 'or'
};

const AdminGroupId = 1;

function downgradeToMaxAcl(result: ItemAclResult, maxAcl: ItemAclResult) {
  (Object.keys(maxAcl) as (keyof ItemAclResult)[]).forEach((permission) => {
    if (typeof result[permission] !== 'undefined' && !maxAcl[permission]) {
      result[permission] = false;
    }
  });

  return result;
}

export class AclModule {
  getAccess(permissionNames: string | string[] | undefined, condition = 'and') {
    const skipChecking = !permissionNames || !configAclModule.getEnabledAcl();
    if (skipChecking) return true;
    const permissionsArray = Array.isArray(permissionNames) ? permissionNames : [permissionNames];
    const permissionsList = dataModule.currentUserModule.item?.permissions || [];
    const permissionInUserPermissionsList = intersection(permissionsArray, permissionsList);
    const resultLength = permissionInUserPermissionsList.length;
    const hasAccess = condition === Conditions.And ? permissionsArray.length === resultLength : resultLength > 0;
    return hasAccess;
  }

  get maxAcl(): ModelAclResult {
    return {
      add: true,
      view: true,
      update: true,
      delete: true
    };
  }

  getModelAclByName(modelName: string, prefix: string = 'ffsecurity'): ModelAclResult {
    if (dataModule.isCurrentUserAdmin) return this.maxAcl;

    const permissionsList = dataModule.currentUserModule.item?.permissions;
    return {
      add: permissionsList?.includes(`${prefix}.add_${modelName}`) ?? false,
      view: permissionsList?.includes(`${prefix}.view_${modelName}`) ?? false,
      update: permissionsList?.includes(`${prefix}.change_${modelName}`) ?? false,
      delete: permissionsList?.includes(`${prefix}.delete_${modelName}`) ?? false
    };
  }

  getModelAcl<T, F>(model: ListViewModel<T, F> | ItemViewModel<T>): ModelAclResult {
    if (!model.hasAcl) throw new Error("Passed model hasn't ACL name!");
    const permissionsList = dataModule.currentUserModule.item?.permissions;
    return {
      add: permissionsList?.includes(model.aclAddPermissionName) ?? false,
      view: permissionsList?.includes(model.aclViewPermissionName) ?? false,
      update: permissionsList?.includes(model.aclUpdatePermissionName) ?? false,
      delete: permissionsList?.includes(model.aclDeletePermissionName) ?? false
    };
  }

  getItemAclByUserProperty(itemId: number, permissionProperty: keyof User, maxAcl: ItemAclResult): ItemAclResult {
    const result: ItemAclResult = { view: true, update: false, delete: false };
    const user = dataModule.currentUserModule.item;
    const isSuperUser = user?.primary_group === AdminGroupId || user?.groups?.includes(AdminGroupId);
    const isNewItem = itemId <= -1000;
    if (!user) throw new Error("Can't get user!");

    if (isSuperUser) {
      result.view = result.update = result.delete = true;
      return result;
    } else if (isNewItem) {
      return { ...maxAcl };
    }

    const permissionsMap = user[permissionProperty] as PermissionsMap;
    if (!permissionsMap) throw new Error(`Can't get permissions for property "${permissionProperty}" in current user data!`);

    switch (permissionsMap[itemId]) {
      case 'edit':
        result.view = result.update = result.delete = true;
        break;
      case 'view':
        result.view = true;
        break;
    }
    return downgradeToMaxAcl(result, maxAcl);
  }

  getItemAclByUserGroupMap(groupPermissionsMap: PermissionsMap, maxAcl: ItemAclResult): ItemAclResult {
    const result: ItemAclResult = { view: false, update: false, delete: false };

    const user = dataModule.currentUserModule.item;
    if (!user) throw new Error("Can't get user!");

    let userGroups = user.groups ? [user.primary_group, ...user.groups] : [user.primary_group];

    if (userGroups.includes(AdminGroupId)) {
      result.view = result.update = result.delete = true;
      return result;
    }

    userGroups.forEach((group) => {
      if (groupPermissionsMap[group]) {
        switch (groupPermissionsMap[group]) {
          case 'edit':
            result.view = result.update = result.delete = true;
            break;
          case 'view':
            result.view = true;
            break;
        }
      }
    });

    return downgradeToMaxAcl(result, maxAcl);
  }

  getCaseItemAclByUserGroupMap(groupPermissionsMap: PermissionsMap, maxAcl: ItemAclResult): ItemAclResult {
    const result: ItemAclResult = { view: false, update: false, delete: false };
    const user = dataModule.currentUserModule.item;

    if (!user) throw new Error("Can't get user!");

    let userGroups = user.groups ? [user.primary_group, ...user.groups] : [user.primary_group];

    if (userGroups.includes(AdminGroupId)) {
      result.view = result.update = result.delete = true;
      return result;
    }

    userGroups.forEach((group) => {
      if (groupPermissionsMap[group]) {
        switch (groupPermissionsMap[group]) {
          case 'delete':
            result.view = result.update = result.delete = true;
            break;
          case 'edit':
            result.view = result.update = true;
            break;
          case 'view':
            result.view = true;
            break;
        }
      }
    });

    return downgradeToMaxAcl(result, maxAcl);
  }

  mergeAcl(first: ModelAclResult, second: ModelAclResult): ModelAclResult {
    return {
      view: first.view && second.view,
      update: first.update && second.update,
      add: first.add && second.add,
      delete: first.delete && second.delete
    };
  }
}

export const aclModule: AclModule = new AclModule();
