
import { defineComponent, toRefs } from 'vue';
import { NButton, NInput, NBaseBar } from '@/uikit';

export default defineComponent({
  name: 'SettingsBar',
  components: { NInput, NButton, NBaseBar },
  props: {
    totalCount: { type: [Number, String] },
    searchQuery: { type: String, default: '' },
    createLabelI18N: { type: String, required: true },
    hasCreate: { type: Boolean, default: true }
  },
  emits: ['search', 'create'],
  setup(props, { emit }) {
    return {
      ...toRefs(props),
      emit
    };
  }
});
