
import { defineComponent, PropType, toRefs } from 'vue';
import { BboxDistance } from './types';

export default defineComponent({
  name: 'NBboxDistances',
  components: {},
  props: {
    distances: { type: Array as PropType<BboxDistance[]>, required: true },
    scale: { type: Number, default: 1 }
  },
  setup(props) {
    return {
      ...toRefs(props)
    };
  }
});
