import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelect = _resolveComponent("NSelect")!

  return (_ctx.readonly)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["camera-group-name", { 'camera-group-name__accent': _ctx.accent }])
      }, _toDisplayString(_ctx.cameraGroupName), 3))
    : (_openBlock(), _createBlock(_component_NSelect, {
        key: 1,
        items: _ctx.items,
        modelValue: _ctx.modelValue,
        "onUpdate:modelValue": _ctx.updateValue
      }, null, 8, ["items", "modelValue", "onUpdate:modelValue"]))
}