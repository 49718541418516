import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["value", "disabled", "id", "name"]
const _hoisted_2 = {
  class: "n-checkbox__icon",
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_3 = {
  key: 0,
  class: "n-checkbox__icon-background",
  width: "20",
  height: "20",
  rx: "5"
}
const _hoisted_4 = {
  key: 1,
  class: "n-checkbox__icon-check",
  d: "M14.7 7.3C14.3 6.9 13.7 6.9 13.3 7.3L9 11.6L6.7 9.3C6.3 8.9 5.7 8.9 5.3 9.3C4.9 9.7 4.9 10.3 5.3 10.7L8.3 13.7C8.5 13.9 8.7 14 9 14C9.3 14 9.5 13.9 9.7 13.7L14.7 8.7C15.1 8.3 15.1 7.7 14.7 7.3Z"
}
const _hoisted_5 = {
  key: 3,
  class: "n-checkbox__icon-indeterminate",
  x: "6",
  y: "9",
  width: "8",
  height: "2",
  rx: "1"
}
const _hoisted_6 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'n-checkbox',
      {
        'n-checkbox_checked': _ctx.modelValue,
        'n-checkbox_indeterminate': _ctx.isIndeterminated,
        'n-checkbox_disabled': _ctx.disabled,
        'n-checkbox_focused': _ctx.focused
      }
    ]),
    onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)), ["prevent"]))
  }, [
    _createElementVNode("input", {
      value: _ctx.modelValue,
      class: "n-checkbox__input",
      type: "checkbox",
      disabled: _ctx.disabled,
      id: `checkbox-${_ctx.label}`,
      name: `checkbox-${_ctx.label}`,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focused = true)),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focused = false))
    }, null, 40, _hoisted_1),
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      (_ctx.modelValue || _ctx.disabled)
        ? (_openBlock(), _createElementBlock("rect", _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.modelValue)
        ? (_openBlock(), _createElementBlock("path", _hoisted_4))
        : _createCommentVNode("", true),
      (!_ctx.modelValue && !_ctx.disabled)
        ? (_openBlock(), _createElementBlock("rect", {
            key: 2,
            x: "1",
            y: "1",
            class: _normalizeClass(_ctx.borderClass),
            width: "18",
            height: "18",
            rx: "4",
            "stroke-width": "2"
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.isIndeterminated)
        ? (_openBlock(), _createElementBlock("rect", _hoisted_5))
        : _createCommentVNode("", true)
    ])),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("label", {
        for: `checkbox-${_ctx.label}`,
        class: "n-checkbox__label label-m"
      }, _toDisplayString(_ctx.label), 9, _hoisted_6)
    ])
  ], 2))
}