import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["confidence-label", _ctx.classes])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.count, (index) => {
      return (_openBlock(), _createElementBlock("span", {
        class: "confidence-label__item",
        key: index,
        style: _normalizeStyle({ backgroundColor: _ctx.color })
      }, null, 4))
    }), 128))
  ], 2))
}