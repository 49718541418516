
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NButtonSize, NButtonSizes, NButtonType, NButtonTypes } from './types';
import NButton from './NButton.vue';
import NButtonsDoubleGroup from './NButtonsDoubleGroup.vue';
import ClickOutside from '../directives/click-outside';

@Options({
  name: 'NDoubleButton',
  components: { NButtonsDoubleGroup, NButton },
  directives: {
    ClickOutside
  }
})
export default class NDoubleButton extends Vue {
  @Prop({ type: String, default: NButtonTypes.Primary })
  type?: NButtonType;

  @Prop({ type: String, default: NButtonSizes.Normal })
  size?: NButtonSize;

  @Prop({ type: [Number, String] })
  readonly counter?: number | string;

  dropdownStatus = false;

  triggerDropdown() {
    this.dropdownStatus = !this.dropdownStatus;
    this.$emit('toggle', this.dropdownStatus);
  }

  closeDropdown() {
    this.dropdownStatus = false;
  }
}
