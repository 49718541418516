/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginRequestRequest } from '../models/LoginRequestRequest';
import type { LoginResult } from '../models/LoginResult';
import type { VideoAuthRenewRequestRequest } from '../models/VideoAuthRenewRequestRequest';
import type { VideoAuthRenewResponse } from '../models/VideoAuthRenewResponse';
import type { VideoAuthRequestRequest } from '../models/VideoAuthRequestRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class AuthService {
    /**
     * Create authorization token from username and password
     * Use this method to obtain an authorization token that you can use in all other methods.
     *
     * To use method you should provide basic authorization credentials:
     *
     * - `Username` - username of your FF.Multi account
     * - `Password` - password for your FF.Multi account
     *
     * This method returns a structure containing an authorization `token`.
     *
     * Copy its value, prepend it with "Token" and paste the entire thing into the **API Key** field
     * in the authorization form of this documentation viewer.
     *
     * If the system uses face AND password authorization -
     * together with the Basic authorization header,
     * additionally pass the token received during face authorization in the `video_auth_token` field.
     *
     * @param requestBody
     * @returns LoginResult
     * @throws ApiError
     */
    public static authLoginCreate(
        requestBody: LoginRequestRequest,
    ): CancelablePromise<LoginResult> {
        return __request({
            method: 'POST',
            path: `/auth/login/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Invalidate currently used token
     * This method invalidates the token that is currently in use.
     * @returns void
     * @throws ApiError
     */
    public static authLogoutCreate(): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/auth/logout/`,
        });
    }
    /**
     * Create auth token from user face
     * Use this operation to obtain an authorization token which could be used in all other operations.
     *
     * To use this handler, send a video file that shows the face of the user being logged in.
     *
     * This method returns a structure containing authorization `token`.
     *
     * Copy its value, prepend it with "Token" and paste the entire thing into the **API Key** field
     * in the authorization form of this documentation viewer.
     *
     * If the system uses face and password authentication, save the token received during face authentication
     * (it will be inactive until the correct password is entered) and pass it
     * along with basic authorization to the `/auth/login/` handler.
     *
     * @param formData
     * @returns LoginResult
     * @throws ApiError
     */
    public static authVideoAuthCreate(
        formData: VideoAuthRequestRequest,
    ): CancelablePromise<LoginResult> {
        return __request({
            method: 'POST',
            path: `/auth/video-auth/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Renew auth token from user face
     * Use this operation to renew an authorization token
     * @param formData
     * @returns VideoAuthRenewResponse
     * @throws ApiError
     */
    public static authVideoAuthRenewCreate(
        formData: VideoAuthRenewRequestRequest,
    ): CancelablePromise<VideoAuthRenewResponse> {
        return __request({
            method: 'POST',
            path: `/auth/video-auth-renew/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
}