
import { defineComponent } from 'vue';
import { arrayOf, object } from 'vue-types';
import { LicensePageMetaContent, LicensePageMetaLabel } from './components';
import { LicensePageMetaItem } from './types';

export default defineComponent({
  name: 'LicensePageMeta',
  components: {
    LicensePageMetaContent,
    LicensePageMetaLabel
  },
  props: {
    items: arrayOf(object<LicensePageMetaItem>()).isRequired
  }
});
