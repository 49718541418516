import { resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-qa"]
const _hoisted_2 = {
  key: 2,
  class: "control-m",
  style: {"margin-left":"2px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["n-table-cell", _ctx.classes]),
    "data-qa": _ctx.dataQa
  }, [
    (_ctx.isUseModel)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps({
          key: 0,
          modelValue: _ctx.modelValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event))
        }, _ctx.props, {
          onSort: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('sort', $event)))
        }, _toHandlers(_ctx.handlers)), null, 16, ["modelValue"]))
      : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps({ key: 1 }, _ctx.props, {
          onSort: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('sort', $event)))
        }, _toHandlers(_ctx.handlers)), null, 16)),
    (_ctx.sort)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.sort), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}