
import { defineComponent, PropType } from 'vue';
import { bool } from 'vue-types';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import { ListViewModel } from '@/definitions/view-models';
import { DisplayType, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import { ReportTypesByPageTypeArray } from '@/components/reports-form/helper';

export default defineComponent({
  name: 'ListPage',
  components: { NLoadingCircle, PageContentLayout },
  props: {
    state: {
      required: true,
      type: Object as PropType<PageState>
    },
    statistics: bool().def(true),
    firstGet: bool().def(true)
  },
  data() {
    return {
      formFilter: []
    };
  },
  computed: {
    classes(): Record<string, boolean> {
      const displayType = this.state.displayType || DisplayType.Full;
      return {
        [`page-items__container_${displayType}`]: true
      };
    },
    module: function (): ListViewModel<any, any> {
      return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
    },
    hasReport: function (): boolean {
      return ReportTypesByPageTypeArray.includes(this.state.pageType);
    }
  },
  watch: {
    'state.pageType': function () {
      this.resetViewState();
    },
    'state.cardType': function () {
      this.resetViewState();
    },
    'state.objectType': function () {
      this.resetViewState();
    },
    'state.episodeType': function () {
      this.resetViewState();
    },
    'state.displayType': function () {
      this.resetScrollTop();
    },
    $route: {
      deep: true,
      handler: function (to, from) {
        const leaveTab = from?.query?.tab;
        const enterTab = to?.query?.tab;
        const currentTab = this.state.tab;

        if (!currentTab || leaveTab === enterTab) return;

        if (currentTab === leaveTab) {
          this.module?.stopStatisticsSync();
        } else if (currentTab === enterTab) {
          this.startStatisticSync();
        }
      }
    },
    module: function moduleChangeHandler(v, p) {
      if (v !== p && !!p) p.stopStatisticsSync();
      this.startStatisticSync();
    }
  },
  created() {
    if (!this.module.loaded) this.resetViewState();
    this.startStatisticSync();
  },
  beforeUnmount() {
    this.statistics && this.module.stopStatisticsSync();
  },
  methods: {
    startStatisticSync() {
      this.statistics && this.module.startStatisticsSync();
    },
    getScrollTop(): number {
      return this.$refs.pageContent?.getScrollTop() ?? 0;
    },
    resetScrollTop() {
      this.$refs.pageContent?.resetScrollTop();
    },
    resetViewState() {
      this.resetScrollTop();
      this.$nextTick(async () => {
        this.module.reset();
        this.firstGet && (await this.firstModuleGet());
        try {
          this.statistics && (await this.module.getStatistics());
        } catch (e) {
          console.warn(e);
        }
      });
    },
    async firstModuleGet() {
      const prevLimit = this.module.filter.current.limit;
      this.module.filter.current.limit = prevLimit ?? 50;
      await this.module.debouncedGet();
    }
  }
});
