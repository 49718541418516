/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UploadList = {
    readonly id: number;
    /**
     * Object creation date
     */
    readonly created_date: string;
    /**
     * Object name up to 2048 characters
     */
    name?: string;
    /**
     * Object successful uploads counter
     */
    readonly success_count: number;
    /**
     * Object failed uploads counter
     */
    readonly fail_count: number;
    /**
     * Object security key up to 2048 characters
     */
    readonly secret_key: string;
};
export const EmptyUploadList: UploadList = {
    id: 0,
        /**
        * Object creation date
        */
    created_date: '',
        /**
        * Object name up to 2048 characters
        */
    name: '',
        /**
        * Object successful uploads counter
        */
    success_count: 0,
        /**
        * Object failed uploads counter
        */
    fail_count: 0,
        /**
        * Object security key up to 2048 characters
        */
    secret_key: '',
};