/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MetaDictionaryValuesFilter = {
    /**
     * Select values belonging to this `dictionary`
     */
    dictionary?: string;
    /**
     * Number of results to return.
     */
    limit?: number;
    /**
     * Which field to use when ordering the results. Available fields: `id`
     */
    ordering?: string;
    /**
     * Select elements with `value`
     */
    value?: string;
    /**
     * Select elements where the SQL condition `value ILIKE value_contains` is true
     */
    value_contains?: string;
}
export const EmptyMetaDictionaryValuesFilter: MetaDictionaryValuesFilter = {
    dictionary: undefined,
    limit: 20,
    ordering: undefined,
    value: undefined,
    value_contains: undefined,
}