export type DetectionItem = {
  detectionId: string;
  object: string;
};

export type VerifyItem = {
  event_url?: string;
  file: File | null;
  detection_source: DetectionItem | null;
};

export type VerifyModel = VerifyItem[];

export enum Position {
  First = 0,
  Second = 1
}
