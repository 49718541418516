/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { CardLooksLike } from './CardLooksLike';
import { RelationHumanCardLink } from './RelationHumanCardLink';
import { Schedule } from './Schedule';
import { str_int_float_bool_object_array_null } from './str_int_float_bool_object_array_null';
export type HumanCard = {
    readonly id: number;
    /**
     * `true` if the object is enabled
     */
    active?: boolean;
    /**
     * `true` if card is filled
     */
    readonly filled: boolean;
    /**
     * Object creation date
     */
    readonly created_date: string;
    /**
     * Object modification date
     */
    readonly modified_date: string;
    /**
     * Card name, up to 256 characters
     */
    name: string;
    /**
     * Extended description, up to 2048 characters
     */
    comment?: string;
    watch_lists: Array<number>;
    readonly looks_like: CardLooksLike | null;
    meta?: Record<string, str_int_float_bool_object_array_null>;
    readonly looks_like_confidence: number;
    active_after?: string | null;
    active_before?: string | null;
    disable_schedule?: Schedule;
    readonly recount_schedule_on: string;
    /**
     * Linked face objects counter
     */
    readonly face_objects: number;
    /**
     * Linked body objects counter
     */
    readonly body_objects: number;
    readonly face_cluster: number;
    readonly body_cluster: number;
    readonly links_to_relations: Array<RelationHumanCardLink>;
};
export const EmptyHumanCard: HumanCard = {
    id: 0,
    /**
     * `true` if the object is enabled
     */
    active: true,
    /**
     * `true` if card is filled
     */
    filled: false,
    /**
     * Object creation date
     */
    created_date: '',
    /**
     * Object modification date
     */
    modified_date: '',
    /**
     * Card name, up to 256 characters
     */
    name: '',
    /**
     * Extended description, up to 2048 characters
     */
    comment: '',
    watch_lists: [],
    looks_like: null,
    meta: {},
    looks_like_confidence: 0,
    active_after: null,
    active_before: null,
    disable_schedule: undefined,
    recount_schedule_on: '',
    /**
     * Linked face objects counter
     */
    face_objects: 0,
    /**
     * Linked body objects counter
     */
    body_objects: 0,
    face_cluster: 0,
    body_cluster: 0,
    links_to_relations: [],
};
