
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ButtonModelCopy from '@/components/common/ButtonModelCopy.vue';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import { NewItemIdStart } from '@/store/multisidebar';

@Options({
  name: 'ItemCommonFields',
  components: { NDateTimeLabel, ButtonModelCopy }
})
export default class ItemCommonFields extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Record<any, any>;

  get isNew() {
    return this.item.id <= NewItemIdStart;
  }
}
