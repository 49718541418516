export type PageTab = {
  label: string;
  name: string;
  enabled: boolean;
};

export const Tabs = {
  sessions: 'sessions',
  blocklist_records: 'blocklist_records'
};

export const AclModelNames = {
  sessions: 'all_own_sessions',
  blocklist_records: 'deviceblacklistrecord'
};
