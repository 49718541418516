import { IDateTimeMaskerResult } from './types';

const dateTimeMasker = (time: string, format = '00:00:00', caretPosition: null | number = null, autoInsertDelimiter = true): IDateTimeMaskerResult => {
  let caretDelta = 0;
  let digits: string[] = [];
  for (let i = 0; i < time.length; i++) {
    let char = time.charAt(i);
    if (char >= '0' && char <= '9') {
      digits.push(char);
    } else if (caretPosition !== null && caretPosition > i) {
      caretDelta--;
    }
  }

  let value = '';
  let delimitersCount = 0;
  for (let i = 0; i < format.length && i - delimitersCount < digits.length; i++) {
    let formatChar = format.charAt(i);
    if (formatChar !== '0') {
      delimitersCount++;
      value += formatChar;
      if (caretPosition !== null && caretPosition > i) {
        caretDelta++;
      }
      continue;
    }
    value += digits[i - delimitersCount];
  }

  if (caretPosition !== null) {
    caretPosition += caretDelta;
  }

  return autoInsertDelimiter ? appendDelimiter(format, value, caretPosition) : { value, caretPosition };
};

function appendDelimiter(format: string, value: string, caretPosition: number | null) {
  let len = value.length;
  let nextChar = format.charAt(len);
  if (len && nextChar !== '0') {
    value += nextChar;
    caretPosition !== null && caretPosition++;
  }
  return { value, caretPosition };
}

export default dateTimeMasker;
