/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Camera } from '../models/Camera';
import type { CameraRequest } from '../models/CameraRequest';
import type { CountResponse } from '../models/CountResponse';
import type { InlineStreamSettings } from '../models/InlineStreamSettings';
import type { OnvifGetPresetsRequestRequest } from '../models/OnvifGetPresetsRequestRequest';
import type { OnvifGetPresetsResponse } from '../models/OnvifGetPresetsResponse';
import type { OnvifPresetRequest } from '../models/OnvifPresetRequest';
import type { OnvifPTZMoveRequest } from '../models/OnvifPTZMoveRequest';
import type { OnvifSetPresetRequestRequest } from '../models/OnvifSetPresetRequestRequest';
import type { OnvifSetPresetResponse } from '../models/OnvifSetPresetResponse';
import type { PaginatedCameraList } from '../models/PaginatedCameraList';
import type { PatchedCameraRequest } from '../models/PatchedCameraRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class CamerasService {
    /**
     * List cameras
     * This method retrieves the list of cameras.
     * @param active Select only active cameras (`true`) or inactive cameras (`false`)
     * @param cameraGroups Select cameras included in these camera groups
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param externalDetector Select only external detectors (`true`) or cameras (`false`)
     * @param externalVms Select cameras included in these `external_vms`
     * @param externalVmsCameraIdContains Select camera exact with this `external_vms_camera_id`
     * @param fromExternalVms `true` - select all cameras from external_vms. `false` - select all cameras not from external_vms.
     * @param limit Number of results to return per page.
     * @param nameContains Select cameras where the SQL condition `name ILIKE name_contains` is true
     * @param objectType Select cameras that support a given type of object: face, body or car
     * @param ordering Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
     * @param page Pagination cursor value.
     * @param stateColor Select cameras by status (`gray`, `red`, `yellow` or `green`)
     * @returns PaginatedCameraList 
     * @throws ApiError
     */
    public static camerasList(
active?: boolean,
cameraGroups?: Array<number>,
createdDateGt?: string,
createdDateGte?: string,
createdDateLastNDays?: number,
createdDateLt?: string,
createdDateLte?: string,
createdDateNthFullWeek?: number,
createdDateNthWorkWeek?: number,
externalDetector?: boolean,
externalVms?: Array<number>,
externalVmsCameraIdContains?: string,
fromExternalVms?: boolean,
limit?: string,
nameContains?: string,
objectType?: string,
ordering?: string,
page?: string,
stateColor?: string,
): CancelablePromise<PaginatedCameraList> {
        return __request({
            method: 'GET',
            path: `/cameras/`,
            query: {
                'active': active,
                'camera_groups': cameraGroups,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'external_detector': externalDetector,
                'external_vms': externalVms,
                'external_vms_camera_id_contains': externalVmsCameraIdContains,
                'from_external_vms': fromExternalVms,
                'limit': limit,
                'name_contains': nameContains,
                'object_type': objectType,
                'ordering': ordering,
                'page': page,
                'state_color': stateColor,
            },
        });
    }
    /**
     * Add new camera
     * Use this method to add a new camera.
     * @param requestBody 
     * @returns Camera 
     * @throws ApiError
     */
    public static camerasCreate(
requestBody: CameraRequest,
): CancelablePromise<Camera> {
        return __request({
            method: 'POST',
            path: `/cameras/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve camera by ID
     * This method retrieves a camera by its ID.
     * @param id A unique integer value identifying this Камера.
     * @returns Camera 
     * @throws ApiError
     */
    public static camerasRetrieve(
id: number,
): CancelablePromise<Camera> {
        return __request({
            method: 'GET',
            path: `/cameras/${id}/`,
        });
    }
    /**
     * Update camera properties
     * Use this method to update a camera properties.
     * @param id A unique integer value identifying this Камера.
     * @param requestBody 
     * @returns Camera 
     * @throws ApiError
     */
    public static camerasUpdate(
id: number,
requestBody: CameraRequest,
): CancelablePromise<Camera> {
        return __request({
            method: 'PUT',
            path: `/cameras/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update camera properties
     * Use this method to update a camera properties.
     * @param id A unique integer value identifying this Камера.
     * @param requestBody 
     * @returns Camera 
     * @throws ApiError
     */
    public static camerasPartialUpdate(
id: number,
requestBody?: PatchedCameraRequest,
): CancelablePromise<Camera> {
        return __request({
            method: 'PATCH',
            path: `/cameras/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete camera
     * Use this method to delete a camera.
     * @param id A unique integer value identifying this Камера.
     * @returns void 
     * @throws ApiError
     */
    public static camerasDestroy(
id: number,
): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/cameras/${id}/`,
        });
    }
    /**
     * Move ONVIF device to PTZ preset
     * Use this method to move ONVIF device to PTZ preset.
     * @param id A unique integer value identifying this Камера.
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public static camerasGotoPresetCreate(
id: number,
requestBody: OnvifPresetRequest,
): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/cameras/${id}/goto-preset/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Move ONVIF device via PTZ
     * Use this method to move ONVIF device via pinch/tilt/zoom actions.
     * @param id A unique integer value identifying this Камера.
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public static camerasPtzCreate(
id: number,
requestBody: OnvifPTZMoveRequest,
): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/cameras/${id}/ptz/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List ONVIF device PTZ presets for media profile
     * Use this method to list ONVIF device PTZ presets for media profile.
     * @param id A unique integer value identifying this Камера.
     * @param requestBody 
     * @returns OnvifGetPresetsResponse 
     * @throws ApiError
     */
    public static camerasPtzPresetsCreate(
id: number,
requestBody: OnvifGetPresetsRequestRequest,
): CancelablePromise<OnvifGetPresetsResponse> {
        return __request({
            method: 'POST',
            path: `/cameras/${id}/ptz-presets/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Remove ONVIF device PTZ preset
     * Use this method to remove ONVIF device PTZ preset.
     * @param id A unique integer value identifying this Камера.
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public static camerasRemovePresetCreate(
id: number,
requestBody: OnvifPresetRequest,
): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/cameras/${id}/remove-preset/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Restart camera processing
     * @param id A unique integer value identifying this Камера.
     * @returns Camera 
     * @throws ApiError
     */
    public static camerasRestartCreate(
id: number,
): CancelablePromise<Camera> {
        return __request({
            method: 'POST',
            path: `/cameras/${id}/restart/`,
        });
    }
    /**
     * Retrieve a screenshot from camera stream (can be cached)
     * Use this method to retrieve a screenshot from a camera video stream (can be cached).
     * @param id A unique integer value identifying this Камера.
     * @param height Screenshot height
     * @param quality Screenshot quality (the value can be ignored if the stream is not processed by the findface-video-worker)
     * @param rot Either request screenshot with ROT or without
     * @param width Screenshot width
     * @returns binary 
     * @throws ApiError
     */
    public static camerasScreenshotRetrieve(
id: number,
height?: number,
quality?: number,
rot: boolean = true,
width?: number,
): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/cameras/${id}/screenshot/`,
            query: {
                'height': height,
                'quality': quality,
                'rot': rot,
                'width': width,
            },
        });
    }
    /**
     * Retrieve a screenshot of the camera stream (invalidates cache)
     * This method retrieves a screenshot from a camera video stream (cache invalidated).
     * @param id A unique integer value identifying this Камера.
     * @param height Screenshot height
     * @param quality Screenshot quality (the value can be ignored if the stream is not processed by the findface-video-worker)
     * @param rot Either request screenshot with ROT or without
     * @param width Screenshot width
     * @returns binary 
     * @throws ApiError
     */
    public static camerasScreenshotCreate(
id: number,
height?: number,
quality?: number,
rot: boolean = true,
width?: number,
): CancelablePromise<Blob> {
        return __request({
            method: 'POST',
            path: `/cameras/${id}/screenshot/`,
            query: {
                'height': height,
                'quality': quality,
                'rot': rot,
                'width': width,
            },
        });
    }
    /**
     * Create ONVIF device PTZ preset based on current position
     * Use this method to create ONVIF device PTZ preset based on current position.
     * @param id A unique integer value identifying this Камера.
     * @param requestBody 
     * @returns OnvifSetPresetResponse 
     * @throws ApiError
     */
    public static camerasSetPresetCreate(
id: number,
requestBody: OnvifSetPresetRequestRequest,
): CancelablePromise<OnvifSetPresetResponse> {
        return __request({
            method: 'POST',
            path: `/cameras/${id}/set-preset/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Objects count
     * This method retrieves count of objects.
     * @param active Select only active cameras (`true`) or inactive cameras (`false`)
     * @param cameraGroups Select cameras included in these camera groups
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param externalDetector Select only external detectors (`true`) or cameras (`false`)
     * @param externalVms Select cameras included in these `external_vms`
     * @param externalVmsCameraIdContains Select camera exact with this `external_vms_camera_id`
     * @param fromExternalVms `true` - select all cameras from external_vms. `false` - select all cameras not from external_vms.
     * @param nameContains Select cameras where the SQL condition `name ILIKE name_contains` is true
     * @param objectType Select cameras that support a given type of object: face, body or car
     * @param stateColor Select cameras by status (`gray`, `red`, `yellow` or `green`)
     * @returns CountResponse 
     * @throws ApiError
     */
    public static camerasCountRetrieve(
active?: boolean,
cameraGroups?: Array<number>,
createdDateGt?: string,
createdDateGte?: string,
createdDateLastNDays?: number,
createdDateLt?: string,
createdDateLte?: string,
createdDateNthFullWeek?: number,
createdDateNthWorkWeek?: number,
externalDetector?: boolean,
externalVms?: Array<number>,
externalVmsCameraIdContains?: string,
fromExternalVms?: boolean,
nameContains?: string,
objectType?: string,
stateColor?: string,
): CancelablePromise<CountResponse> {
        return __request({
            method: 'GET',
            path: `/cameras/count/`,
            query: {
                'active': active,
                'camera_groups': cameraGroups,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'external_detector': externalDetector,
                'external_vms': externalVms,
                'external_vms_camera_id_contains': externalVmsCameraIdContains,
                'from_external_vms': fromExternalVms,
                'name_contains': nameContains,
                'object_type': objectType,
                'state_color': stateColor,
            },
        });
    }
    /**
     * Retrieve default values of `stream_settings` parameters
     * This method retrieves default values of the `stream_settings` parameters.
     * @returns InlineStreamSettings 
     * @throws ApiError
     */
    public static camerasDefaultParametersRetrieve(): CancelablePromise<InlineStreamSettings> {
        return __request({
            method: 'GET',
            path: `/cameras/default_parameters/`,
        });
    }
}