
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import NInputNumber from '../input-number/NInputNumber.vue';
import NIcon from '../icons/NIcon.vue';
import NDropdownInput from '../dropdown-input/NDropdownInput.vue';

export enum ControlType {
  Dropdown = 'chevron-down',
  None = ''
}

export enum NNumberSeparator {
  Comma = 'comma',
  Point = 'point'
}

export enum NInputNumberTextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

@Options({
  name: 'NInputInterval',
  components: { NDropdownInput, NInputNumber, NIcon }
})
export default class NInputInterval extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly min!: number;

  @Prop({ type: Number, default: 10 })
  readonly max!: number;

  @Prop({
    type: Number,
    default: 1,
    validator(value: number | string) {
      return +value > 0;
    }
  })
  readonly step!: number;

  @Prop({
    type: Number
  })
  readonly minValue?: number;

  @Prop({
    type: Number
  })
  readonly maxValue?: number;

  @Prop({ type: String, default: NNumberSeparator.Point })
  readonly separator!: NNumberSeparator;

  @Prop({ type: String, default: ControlType.Dropdown })
  readonly controlType!: ControlType;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly!: boolean;

  currentMinValue: string | number | null = null;
  currentMaxValue: string | number | null = null;

  displayMinDropdown = false;
  displayMaxDropdown = false;

  created() {
    this.currentMinValue = this.minValue || this.min;
    this.currentMaxValue = this.maxValue || this.max;
  }

  get minElement(): HTMLInputElement | null {
    return (this.$refs.min as HTMLInputElement) || null;
  }

  get maxElement(): HTMLInputElement | null {
    return (this.$refs.max as HTMLInputElement) || null;
  }

  get isCommaSeparator() {
    return this.separator === NNumberSeparator.Comma;
  }

  get values() {
    let numbers = [];
    for (let i = this.min; i <= this.max; i += this.step) {
      numbers.push(i);
    }
    return numbers;
  }

  getModifiedValues(checkedValue: string | number | null) {
    return this.values.map((value) => {
      return {
        value,
        label: this.isCommaSeparator ? this.modify(+value) : value,
        checkedClass: checkedValue === value
      };
    });
  }

  modify(value: number): string {
    return value.toString().replace('.', ',');
  }

  focusOnMinElement() {
    this.$nextTick(() => this.minElement?.focus());
  }

  focusOnMaxElement() {
    this.$nextTick(() => this.maxElement?.focus());
  }

  minValueChangeHandler(v: string | number | null): void {
    this.currentMinValue = v;
    this.displayMinDropdown = false;
    this.$emit('change:minValue', v);
    this.$emit('change', { minValue: v });
    this.focusOnMinElement();
  }
  maxValueChangeHandler(v: string | number | null): void {
    this.currentMaxValue = v;
    this.displayMaxDropdown = false;
    this.$emit('change:maxValue', v);
    this.$emit('change', { maxValue: v });
    this.focusOnMaxElement();
  }
  toggleDisplayMinDropdown(): void {
    this.displayMinDropdown = !this.displayMinDropdown;
  }
  toggleDisplayMaxDropdown(): void {
    this.displayMaxDropdown = !this.displayMaxDropdown;
  }
  minValueEscKeyupHandler(): void {
    this.displayMinDropdown = false;
    this.focusOnMinElement();
  }
  maxValueEscKeyupHandler(): void {
    this.displayMaxDropdown = false;
    this.focusOnMaxElement();
  }
}
