
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: { type: String, required: true },
    width: { type: [Number, String], default: 20 },
    height: { type: [Number, String], default: 20 }
  },
  computed: {
    style(): Record<string, string> {
      return { minWidth: `${this.width}px` };
    }
  }
});
