import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "watch-lists__rows-overflow-count label-2xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WatchListItem = _resolveComponent("WatchListItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["watch-lists", _ctx.classes])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.watchLists, (item) => {
      return (_openBlock(), _createBlock(_component_WatchListItem, {
        key: item.id,
        class: "watch-list-item",
        name: item.name,
        color: '#' + item.color,
        "text-color": _ctx.textColor,
        "text-size": _ctx.textSize,
        "tag-view": _ctx.tagView,
        marker: _ctx.marker
      }, null, 8, ["name", "color", "text-color", "text-size", "tag-view", "marker"]))
    }), 128)),
    (_ctx.showRowsOverflowCount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "+" + _toDisplayString(_ctx.rowsOverflowCount), 1))
      : _createCommentVNode("", true)
  ], 2))
}