
import { Options } from 'vue-class-component';
import CardItem from '@/components/cards/CardItem';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { NButton } from '@/uikit';
import WatchListsGroup from '@/components/common/WatchListsGroup.vue';

@Options({
  name: 'CardItemTile',
  components: { WatchListsGroup, NThumbnail, NButton }
})
export default class CardItemTile extends CardItem {
  get classes() {
    return {
      'card-item-tile': true,
      'card-item-tile__selected': this.selected,
      'card-item-tile__opened': this.opened
    };
  }
}
