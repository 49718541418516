import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "participant-item-row" }
const _hoisted_2 = { class: "participant-item-row__thumbnail" }
const _hoisted_3 = { class: "participant-item-row__information" }
const _hoisted_4 = { class: "participant-item-row__information-content" }
const _hoisted_5 = { class: "participant-item-row__name heading-s" }
const _hoisted_6 = { class: "participant-item-row__role" }
const _hoisted_7 = {
  key: 0,
  class: "participant-item-row__close"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_WatchListItem = _resolveComponent("WatchListItem")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NThumbnail, {
        modelValue: _ctx.thumbnail,
        size: "small",
        corners: "circle",
        onAction: _cache[0] || (_cache[0] = (v) => _ctx.$emit('action', v))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.item.name), 1),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_WatchListItem, {
            name: _ctx.computeName(_ctx.item.role),
            color: _ctx.computeColor(_ctx.item.role)
          }, null, 8, ["name", "color"])
        ])
      ])
    ]),
    (_ctx.closeable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_NButton, {
            icon: "close",
            size: "small",
            "icon-size": "12",
            type: "secondary",
            round: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action', 'close')))
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}