
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';

@Options({
  name: 'Chart',
  components: { NLoadingCircle }
})
export default class Chart extends Vue {
  @Prop({ type: Boolean, default: true })
  readonly active!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;
}
