export default {
  d: 'd',
  h: 'h',
  m: 'm',
  s: 's',
  second: '{ count, plural, one {# second} other {# seconds}}',
  minute: '{ count, plural, one {# minute} other {# minutes}}',
  hour: '{ count, plural, one {# hour} other {# hours}}',
  day: '{ count, plural, one {# day} other {# days}}',
  month: '{ count, plural, one {# month} other {# months}}',
  year: '{ count, plural, one {# year} other {# years}}',
  mon: 'mon',
  tue: 'tue',
  wed: 'wed',
  thu: 'thu',
  fri: 'fri',
  sat: 'sat',
  sun: 'sun',
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturday: 'saturday',
  sunday: 'sunday'
};
