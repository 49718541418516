import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "n-exception" }
const _hoisted_2 = {
  key: 0,
  class: "n-exception__code"
}
const _hoisted_3 = { class: "n-exception__description" }
const _hoisted_4 = {
  key: 1,
  class: "n-exception__traceback"
}
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.exception.code)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.i18n('exceptions.code')) + ": " + _toDisplayString(_ctx.exception.code), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.i18n('exceptions.description')) + ": " + _toDisplayString(_ctx.exception.description), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exception.meta, (meta, name) => {
      return (_openBlock(), _createElementBlock("div", {
        key: name,
        class: "n-exception__meta"
      }, _toDisplayString(_ctx.i18n(`exceptions.${name}`)) + ": " + _toDisplayString(meta), 1))
    }), 128)),
    (_ctx.exception.traceback && !_ctx.traceDisabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.i18n('exceptions.traceback')) + ":", 1),
          _createElementVNode("div", {
            class: "n-exception__traceback-content label-xs",
            textContent: _toDisplayString(_ctx.exception.traceback)
          }, null, 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true)
  ]))
}