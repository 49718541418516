
import NIcon from '@/uikit/icons/NIcon.vue';
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  components: { NIcon },
  emits: ['update:expanded']
})
export default class VideoWallCameraListGroupHeader extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly expanded!: boolean;
  @Prop({ required: true, type: String })
  readonly label!: string;

  get chevron(): string {
    return this.expanded ? 'chevron-down' : 'chevron-right';
  }

  handleClickEvent(): void {
    this.$emit('update:expanded', !this.expanded);
  }
}
