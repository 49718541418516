import { ITableColumn } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import NText from '@/uikit/text/NText.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import NButtonModel from '@/uikit/buttons/NButtonModel.vue';
import ButtonModelCopy from '@/components/common/ButtonModelCopy.vue';

export const webhooksTableSchema: ITableColumn[] = [
  {
    width: 'max-content',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.id',
        name: 'id',
        sortable: true
      }
    },
    content: {
      path: 'id',
      component: ButtonModelCopy
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.name',
        name: 'name',
        sortable: true
      }
    },
    content: {
      path: 'name',
      component: NButtonModel,
      props: { type: 'link', align: 'left' }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.url',
        name: 'url',
        sortable: true
      }
    },
    content: {
      path: 'url',
      component: NText,
      props: {
        class: 'label-xs'
      }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.active',
        name: 'active',
        sortable: true
      }
    },
    content: {
      path: 'active',
      classes: ['align-right'],
      component: NSwitch
    }
  }
];
