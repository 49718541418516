
import { computed, defineComponent, toRefs } from 'vue';
import NButton from '../buttons/NButton.vue';

export default defineComponent({
  name: 'NTableColumnCell',
  components: { NButton },
  props: {
    label: { type: String },
    name: { type: String },
    sortable: { type: Boolean, default: false },
    align: { type: String, default: '' }
  },
  emits: ['sort'],
  setup(props, { emit }) {
    function sort() {
      emit('sort', props.name);
    }

    const classes = computed(() => ({
      'n-table-column-cell': true,
      'n-table-column-cell_sortable_off': !props.sortable,
      'control-m': !props.sortable,
      [`n-table-column-cell__align-${props.align}`]: props.align
    }));

    return {
      ...toRefs(props),
      classes,
      sort
    };
  }
});
