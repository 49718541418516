
import { viewModelRepository } from '@/api/common';
import { SearchResult } from '@/api/models/SearchResult';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import FilterDateRange from '@/components/common/filter/controls/FilterDateRange.vue';
import enrichSearchResults, { EnrichedSearchResult } from '@/pages/external-search/results/enrich-search-results';
import NButton from '@/uikit/buttons/NButton.vue';
import NInput from '@/uikit/input/NInput.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ExternalSearchResultsTable from './ExternalSearchResultsTable.vue';
import { dataAssetsModule } from '@/store/application/data.assets.module';

@Options({
  name: 'ExternalSearchResults',
  components: {
    SortDropdown,
    ExternalSearchResultsTable,
    FilterDateRange,
    NButton,
    InfiniteScroll,
    NInput
  }
})
export default class ExternalSearchResults extends Vue {
  @Prop({ type: Number, required: true })
  readonly searchRequestId!: number;

  enrichedSearchResults: EnrichedSearchResult[] = [];

  get module() {
    return viewModelRepository.getPuppeteerSearchEventsListViewModel();
  }

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ id: true, external_timestamp: true, confidence: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  @Watch('module.filter.current', { deep: true })
  async reloadList() {
    await this.module.get();
  }

  @Watch('module.items')
  async moduleItemsWatcher(items: SearchResult[]) {
    this.enrichedSearchResults = await enrichSearchResults(items, this.enrichedSearchResults);
  }

  async mounted() {
    this.module.filter.force.request = this.searchRequestId;
    await this.reloadList();
  }

  dispatchDisplayEventImageEvent(src: string): void {
    this.$emit('display-event-image', src);
  }

  dispatchDisplayCardImageEvent(src: string): void {
    this.$emit('display-card-image', src);
  }
}
