
import { NInput } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import NDoubleButton from '@/uikit/buttons/NDoubleButton.vue';
import NForm, { IFormContext, IFormLayout, IFormLayoutItem, IFormLayoutRow } from '@/uikit/forms/NForm.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { aclModule } from '@/store/acl';
import { PageState } from '@/store/application/page.definitions';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import { filterRecursive } from '@/uikit/helpers';

function clearHandler(this: IFormContext) {
  const { layout, model }: { layout: any; model: any } = this;
  const item: any = this.item;
  if (item) {
    const indexToDelete = layout.findIndex((layoutItem: any) => layoutItem.path === item.path);
    const emptyValue = Array.isArray(model[item.path]) ? [] : '';
    set(model, item.path, emptyValue);
    layout.splice(indexToDelete, 1);
  }
}

@Options({
  name: 'FiltersSmall',
  components: {
    NButton,
    NDoubleButton,
    NForm,
    NInput
  },
  emits: ['report', 'resetFilters']
})
export default class FiltersSmall extends Vue {
  @Prop({ type: Array, required: true })
  readonly formLayout!: IFormLayout;

  @Prop({ type: Object, required: true })
  readonly modelValue: any;

  @Prop({ type: [Object, Array], default: () => ({}) })
  readonly state?: PageState;

  @Prop({ type: Boolean, default: false })
  readonly supportsSearch!: boolean;

  @Prop({ type: String, default: 'name_contains' })
  readonly searchModelProp: string = 'name_contains';

  @Prop({ type: Boolean, default: false })
  readonly hasFilterManager!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasChanges!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hasReport!: boolean;

  @Prop({ type: String })
  readonly dataQa?: string;

  @Prop({ type: [Array] })
  readonly enabledFields?: string[];

  get model() {
    return this.modelValue;
  }

  set model(data: any) {
    this.$emit('update:modelValue', data);
  }

  get searchValue() {
    const result = this.model[this.searchModelProp];
    return Array.isArray(result) ? result.join(',') : result;
  }

  set searchValue(value: string) {
    const shouldConvertValueToArray = this.searchModelProp.indexOf('_in') > -1;
    let result: string[] | string = '';
    if (shouldConvertValueToArray) {
      result = value ? (value || '').split(',').map((i) => i.trim()) : [];
    } else {
      result = (value || '').trim();
    }
    set(this.model, this.searchModelProp, result);
  }

  get canCreateReport() {
    const canAddReport = aclModule.getAccess('ffsecurity.add_report');
    return this.hasReport && canAddReport;
  }

  get layout(): IFormLayout {
    let result = this.addClearHandlerToLayout(this.formLayout);
    result = this.getAvailableFields(result);
    return result;
  }

  addClearHandlerToLayout(formLayout: IFormLayout) {
    return formLayout.map((item) => {
      if (Array.isArray(item)) {
        this.addClearHandlerToLayout(item);
      } else {
        item.on = { ...item.on, close: clearHandler };
      }
      return item;
    });
  }

  isEmptyValue(value: any): boolean {
    if (value instanceof Object) {
      return isEmpty(value);
    } else {
      return value === false || value === 0 ? false : !value;
    }
  }

  getAvailableFields(layout: IFormLayout) {
    const enabledItems: string[] = this.enabledFields || [];
    return filterRecursive(layout, (item: IFormLayoutItem) => {
      const { path, name } = item;
      const isEmptyByMethod: boolean = item.checkForEmpty ? item.checkForEmpty(this.model) : true;
      const isEmptyByPath = path ? this.isEmptyValue(this.model[path]) : true;
      const enabled = enabledItems.includes(path || name || '');
      return enabled || !isEmptyByMethod || !isEmptyByPath;
    });
  }

  showReportForm() {
    this.$emit('report');
  }
}
