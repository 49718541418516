import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "data-sources_next-content" }
const _hoisted_2 = { class: "data-sources-devices-next__title" }
const _hoisted_3 = { class: "data-sources_next-button data-sources-devices-next_buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('ds.you_are_adding')) + " " + _toDisplayString(_ctx.$tm('ds.devices_pf', { count: _ctx.deviceCount })), 1),
    _createVNode(_component_NForm, {
      layout: _ctx.headers,
      class: "form-table-devices"
    }, null, 8, ["layout"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devices, (item, i) => {
      return (_openBlock(), _createBlock(_component_NForm, {
        layout: _ctx.schema,
        model: item,
        key: `device_${i}`,
        "inline-form": "",
        ref_for: true,
        ref: "form",
        class: "form-table-devices"
      }, null, 8, ["layout", "model"]))
    }), 128)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NButton, {
        type: "primary",
        suffixIcon: "data-sources-next",
        onClick: _ctx.createAndConfig
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('ds.add_and_config')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_NButton, {
        type: "secondary",
        suffixIcon: "data-sources-next",
        onClick: _ctx.onlyCreateCameras
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('ds.add')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}