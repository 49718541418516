
export const TabItemsMap = {
  Info: 'info',
  DepricatedGeneral: 'general',

  /* cards, participants */
  FaceObjects: 'face_objects',
  FaceClusterEvents: 'face_cluster_events',
  BodyObjects: 'body_objects',
  BodyClusterEvents: 'body_cluster_events',
  CarObjects: 'car_objects',
  CarClusterEvents: 'car_cluster_events',
  HumanParticipants: 'human_participants',
  CaseHumanCards: 'case_human_cards',
  Connections: 'connections',

  /* vns */
  Location: 'location'
};
