
import { Options, Prop, Vue } from 'vue-property-decorator';
import type { NAttachmentsI18n } from './types';

@Options({})
export default class NAttachmentsAmount extends Vue {
  @Prop({ required: true, type: Number })
  readonly amount!: number;
  @Prop({ required: true, type: Object })
  readonly i18n!: Readonly<Required<NAttachmentsI18n>>;

  get amountText() {
    return this.amount ? this.i18n.files : this.i18n.no_files;
  }
}
