import { viewModelRepository } from '@/api/common';
import { SearchRequest } from '@/api/models/SearchRequest';
import enrichCards, { EnrichedCard } from '@/components/cards/enrich-cards';

export interface EnrichedSearchRequest extends SearchRequest {
  cardObject?: EnrichedCard;
}

export default async function enrichSearchRequests(
  searchRequests: SearchRequest[],
  oldEnrichedSearchRequests: EnrichedSearchRequest[] = []
): Promise<EnrichedSearchRequest[]> {
  // fill from old
  const enrichedSearchRequests = searchRequests.map((searchRequest) => {
    return {
      ...searchRequest,
      cardObject: oldEnrichedSearchRequests.find((item) => item.card === searchRequest.card)?.cardObject
    };
  });

  // get new
  let cardIds = enrichedSearchRequests
    .filter((enrichedSearchRequest) => enrichedSearchRequest.card && !enrichedSearchRequest.cardObject)
    .map((enrichedSearchRequests) => enrichedSearchRequests.card);
  cardIds = [...new Set(cardIds)];
  const cardModule = viewModelRepository.getCardsHumansListViewModel();
  if (cardIds.length) {
    cardModule.filter.current.id_in = cardIds as number[];
    cardModule.filter.current.limit = '1000';
    await cardModule.get();
  }

  // enrich child
  const oldEnrichedCards = enrichedSearchRequests.map((item) => item.cardObject).filter((item) => item) as EnrichedCard[];
  const enrichedCards = await enrichCards(cardModule.items, oldEnrichedCards);

  // fill from new
  enrichedSearchRequests.forEach((enrichedSearchRequest) => {
    const cardObject = enrichedCards.find((item) => item.id === enrichedSearchRequest.card);
    if (cardObject) enrichedSearchRequest.cardObject = cardObject;
  });

  return enrichedSearchRequests;
}
