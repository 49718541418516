import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (`${_ctx.model}${_ctx.cameraId}`)
    ? (_openBlock(), _createBlock(_component_NModalWindow, {
        key: 0,
        customSize: "90vw",
        onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
        "close-disabled": true,
        "close-icon": false,
        showHeader: false,
        "has-margins": false
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_VideoPlayer, {
            key: `${_ctx.model}${_ctx.cameraId}`,
            model: _ctx.$videoPlayer.model,
            cameraId: _ctx.$videoPlayer.cameraId,
            timeFrom: _ctx.$videoPlayer.timeFrom,
            showControls: _ctx.$videoPlayer.showControls,
            showRewinds: _ctx.$videoPlayer.showRewinds,
            showTimeline: _ctx.$videoPlayer.showTimeline,
            showBottomBar: _ctx.$videoPlayer.showBottomBar,
            closeable: "",
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$videoPlayer.visible.value = false))
          }, null, 8, ["model", "cameraId", "timeFrom", "showControls", "showRewinds", "showTimeline", "showBottomBar"]))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}