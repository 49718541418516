export default {
  date_multiselect_warning: 'You cannot sort by date if multiple objects are selected.',
  drag_and_drop_files: 'Drag and drop file to upload or',
  select_files: 'select file',
  search: 'search',
  cases: 'case',
  cards: 'record',
  events: 'event',
  clusters: 'cluster',
  define_search_source: 'Define a search source',
  enter_search_objects: 'Enter a photo URL on the Internet or an internal entity ID/URL',
  enter_search_objects_holder: 'enter ID or URL',
  upload_text: 'or upload a media file. Supported formats are JPG, PNG, GIF',
  input_error: 'Matches are not found',
  search_all: 'Search all',
  faces: 'Faces',
  bodies: 'Bodies',
  cars: 'Vehicles',
  humans: 'Individuals',
  include_cluster: 'Search across cluster events',
  select_all: 'Select all',
  deselect_all: 'Deselect all',
  apply: 'Apply',
  cluster_letter: 'C',
  upload: 'upload',
  select_another_object: 'select another object'
};
