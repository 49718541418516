import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "reports-page__actions-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportsPageDownloader = _resolveComponent("ReportsPageDownloader")!
  const _component_ReportsPageActions = _resolveComponent("ReportsPageActions")!
  const _component_ReportsPageTable = _resolveComponent("ReportsPageTable")!
  const _component_PageContentLayout = _resolveComponent("PageContentLayout")!

  return (_openBlock(), _createBlock(_component_PageContentLayout, { onFetch: _ctx.loadNextPage }, {
    default: _withCtx(() => [
      _createVNode(_component_ReportsPageDownloader, { ref: "downloader" }, null, 512),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.selectedIds.length)
          ? (_openBlock(), _createBlock(_component_ReportsPageActions, {
              key: 0,
              onDelete: _ctx.deleteSelected,
              onDownload: _ctx.handleDownloadSelected,
              onUpdate: _ctx.updateSelected
            }, null, 8, ["onDelete", "onDownload", "onUpdate"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ReportsPageTable, {
        items: _ctx.reports,
        "selected-ids": _ctx.selectedIds,
        onDownload: _ctx.handleDownload,
        onSort: _ctx.sort
      }, null, 8, ["items", "selected-ids", "onDownload", "onSort"])
    ]),
    _: 1
  }, 8, ["onFetch"]))
}