import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTableSection = _resolveComponent("NTableSection")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.template),
    class: "n-table"
  }, [
    (_ctx.isHeadVisible)
      ? (_openBlock(), _createBlock(_component_NTableSection, {
          key: 0,
          rows: _ctx.headRows,
          schemas: _ctx.headSchemas,
          onCellClick: _ctx.dispatchHeadCellClickEvent
        }, null, 8, ["rows", "schemas", "onCellClick"]))
      : _createCommentVNode("", true),
    _createVNode(_component_NTableSection, {
      rows: _ctx.bodyRows,
      schemas: _ctx.bodySchemas,
      onCellClick: _ctx.dispatchBodyCellClickEvent
    }, null, 8, ["rows", "schemas", "onCellClick"]),
    (_ctx.isFootVisible)
      ? (_openBlock(), _createBlock(_component_NTableSection, {
          key: 1,
          rows: _ctx.footRows,
          schemas: _ctx.footSchemas,
          onCellClick: _ctx.dispatchFootCellClickEvent
        }, null, 8, ["rows", "schemas", "onCellClick"]))
      : _createCommentVNode("", true)
  ], 4))
}