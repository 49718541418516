import { reactive } from 'vue';
import { GlobalEvent } from './types';

export const GlobalEventName = {
  Create: 'create',
  Delete: 'delete',
  Update: 'update'
} as const;

class GlobalEventModule {
  public historyLimit = 100;
  private history_: GlobalEvent[] = [];
  private delayTimersByEventName: Record<string, number> = {};

  public static create() {
    return reactive(new this());
  }

  public get history() {
    return [...this.history_];
  }

  public get current() {
    return this.history_.length > 0 ? this.history_[this.history_.length - 1] : null;
  }

  public send(event: GlobalEvent, eventDelayMs = -1) {
    if (this.delayTimersByEventName[event.name]) window.clearTimeout(this.delayTimersByEventName[event.name]);
    if (eventDelayMs > -1) {
      this.delayTimersByEventName[event.name] = window.setTimeout(this.plainSend.bind(this, event), eventDelayMs);
    } else {
      this.plainSend(event);
    }
  }

  public sendCreate(type: string, payload?: any, eventDelayMs = -1) {
    this.send({ name: GlobalEventName.Create, type, payload }, eventDelayMs);
  }

  public sendDelete(type: string, payload?: any, eventDelayMs = -1) {
    this.send({ name: GlobalEventName.Delete, type, payload }, eventDelayMs);
  }

  public sendUpdate(type: string, payload?: any, eventDelayMs = -1) {
    this.send({ name: GlobalEventName.Update, type, payload }, eventDelayMs);
  }

  private plainSend(event: GlobalEvent) {
    this.history_ = [...this.history_.slice(0, this.historyLimit - 1), event];
  }
}

export const globalEventModule = GlobalEventModule.create();
