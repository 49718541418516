import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_VideoWallPresetEditor = _resolveComponent("VideoWallPresetEditor")!

  return (!_ctx.editing)
    ? (_openBlock(), _createBlock(_component_NButton, {
        key: 0,
        onClick: _ctx.toggleEditingMode,
        label: _ctx.$t('videowall.save_preset'),
        type: "secondary"
      }, null, 8, ["onClick", "label"]))
    : (_openBlock(), _createBlock(_component_VideoWallPresetEditor, {
        key: 1,
        name: _ctx.name,
        onCancel: _ctx.toggleEditingMode,
        onSave: _ctx.dispatchSaveEvent,
        "start-from-editor": ""
      }, null, 8, ["name", "onCancel", "onSave"]))
}