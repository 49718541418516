
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';
import DragHelper from './DragHelper';
import { Camera, CamerasService } from '@/api';
import * as _ from 'lodash';
import { toHHMMSS } from '@/common/filters';
import SimpleTimer from '@/components/video-player/utils/simple-timer';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { dataModule } from '@/store/data';

@Options({
  name: 'VideoPlayerInfoPanel',
  components: { NIcon },
  directives: { DragHelper }
})
export default class VideoPlayerInfoPanel extends Vue {
  @Prop({ type: Number, required: true })
  readonly cameraId!: number;

  timer = new SimpleTimer();
  camera?: Camera;

  get availableObjects() {
    return dataAssetsModule.availableObjects;
  }
  get faceAvailable() {
    return this.availableObjects.includes('face');
  }
  get bodyAvailable() {
    return this.availableObjects.includes('body');
  }
  get carAvailable() {
    return this.availableObjects.includes('car');
  }

  getCameraValue(path: string, defaultValue = '-') {
    return _.get(this.camera, path, defaultValue);
  }

  getStatisticValue(path: string, defaultValue = '-') {
    return _.get(this.camera, `health_status.statistic.${path}`, defaultValue);
  }

  formatTime(value: number) {
    return toHHMMSS(value);
  }

  formatDuration(value: number) {
    return toHHMMSS(value);
  }

  async update() {
    this.camera = dataModule.camerasModule.items.find((camera) => camera.id === this.cameraId);
    this.$forceUpdate();
  }

  mounted() {
    this.update();
    this.timer.setInterval(this.update, 10000);
  }

  beforeUnmount() {
    this.timer.clear();
  }
}
