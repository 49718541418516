import { MenuItem } from '@/store/menu/menu';
import { PagePaths } from '@/store/application/page.definitions';

export const launcherItems: MenuItem[] = [
  {
    icon: 'search-alt',
    name: 'search',
    i18n: 'menu.search',
    i18n_description: 'menu.search_desc',
    path: PagePaths.Search,
    meta: {
      permissions: [
        'ffsecurity.view_faceevent',
        'ffsecurity.view_bodyevent',
        'ffsecurity.view_carevent',
        'ffsecurity.view_humancard',
        'ffsecurity.view_carcard'
      ]
    }
  },
  {
    icon: 'relations',
    name: 'relations',
    i18n: 'menu.relations',
    i18n_description: 'menu.relations_desc',
    path: PagePaths.Interactions,
    meta: {
      permissions: ['ffsecurity.view_facecluster'],
      services: {
        clusters: true
      }
    }
  },
  {
    icon: 'video-wall',
    name: 'video_wall',
    i18n: 'menu.video_wall',
    i18n_description: 'menu.video_wall_desc',
    path: PagePaths.VideoWall,
    meta: {
      permissions: ['ffsecurity.view_camera']
    }
  },
  {
    icon: 'cibr',
    name: 'cases',
    i18n: 'menu.cibr',
    i18n_description: 'menu.cibr_desc',
    path: PagePaths.Cases,
    meta: {
      permissions: ['ffsecurity.view_case']
    }
  },
  {
    icon: 'external-search',
    name: 'external-search',
    i18n: 'menu.external_search',
    i18n_description: 'menu.external_search_desc',
    path: PagePaths.ExternalSearch,
    meta: {
      permissions: ['ffsecurity_puppeteer.view_searchrequest'],
      plugins: {
        puppeteer: true
      }
    }
  },
  {
    icon: 'eye-open',
    name: 'remote_monitoring_events',
    i18n: 'menu.remote_monitoring_events',
    i18n_description: 'menu.remote_monitoring_events_desc',
    path: PagePaths.RemoteMonitoringEvents,
    meta: {
      permissions: ['ffsecurity_puppeteer.view_remotemonitoringrecord'],
      plugins: {
        puppeteer: true
      }
    }
  },
  {
    icon: 'counters',
    name: 'counters',
    i18n: 'menu.counters',
    i18n_description: 'menu.counters_desc',
    path: PagePaths.Counters,
    meta: {
      permissions: ['ffsecurity.view_counter']
    }
  },
  {
    icon: 'lines',
    name: 'lines',
    i18n: 'menu.lines',
    i18n_description: 'menu.lines_desc',
    path: PagePaths.Lines,
    meta: {
      permissions: ['ffsecurity.view_line'],
      licensePath: 'products.multi.extra.line-crossing'
    }
  },
  {
    icon: 'camera',
    name: 'data_sources',
    i18n: 'menu.data_sources',
    i18n_description: 'menu.data_sources_desc',
    path: PagePaths.DataSources,
    meta: {
      permissions: ['ffsecurity.view_camera', 'ffsecurity.view_videoarchive']
    }
  },
  {
    icon: 'episodes',
    name: 'events_episodes',
    i18n: 'menu.episodes_and_events',
    i18n_description: 'menu.episodes_and_events_desc',
    path: PagePaths.Events,
    meta: {
      permissions: [
        'ffsecurity.view_humanepisode',
        'ffsecurity.view_carepisode',
        'ffsecurity.view_faceevent',
        'ffsecurity.view_bodyevent',
        'ffsecurity.view_carevent'
      ]
    }
  },
  {
    icon: 'clusters',
    name: 'clusters',
    i18n: 'menu.clusters',
    i18n_description: 'menu.clusters_desc',
    path: PagePaths.Clusters,
    meta: {
      permissions: ['ffsecurity.view_facecluster', 'ffsecurity.view_bodycluster', 'ffsecurity.view_carcluster'],
      services: {
        clusters: true
      }
    }
  },
  {
    icon: 'statistics',
    name: 'analytics',
    i18n: 'menu.analytics',
    i18n_description: 'menu.analytics_desc',
    path: PagePaths.Analytics,
    meta: {
      permissions: ['ffsecurity.view_faceevent']
    }
  },
  {
    icon: 'vp-settings',
    name: 'settings',
    i18n: 'menu.settings',
    i18n_description: 'menu.settings_desc',
    path: PagePaths.Settings,
    meta: {}
  },
  {
    icon: 'cards',
    name: 'cards',
    i18n: 'menu.cards',
    i18n_description: 'menu.cards_desc',
    path: PagePaths.Cards,
    meta: {
      permissions: ['ffsecurity.view_humancard', 'ffsecurity.view_carcard']
    }
  },
  {
    icon: 'areas',
    name: 'areas',
    i18n: 'menu.areas',
    i18n_description: 'menu.areas_desc',
    path: PagePaths.Areas,
    meta: {
      permissions: ['ffsecurity.view_area']
    }
  },
  {
    icon: 'done-all',
    name: 'verify',
    i18n: 'menu.verify',
    i18n_description: 'menu.verify_desc',
    path: PagePaths.Verify,
    meta: {}
  },
  {
    icon: 'audit-log',
    name: 'audit-logs',
    i18n: 'menu.audit_logs',
    i18n_description: 'menu.audit_logs',
    path: PagePaths.AuditLogs,
    meta: {
      permissions: ['ffsecurity.view_auditlog']
    }
  },
  {
    icon: 'create-report',
    name: 'reports',
    i18n: 'menu.reports',
    i18n_description: 'menu.reports_desc',
    path: PagePaths.Reports,
    meta: {
      permissions: ['ffsecurity.view_report']
    }
  },
  {
    icon: 'bi-analitycs',
    name: 'bi_app',
    i18n: 'menu.bi_app',
    i18n_description: 'menu.bi_app_desc',
    meta: {
      configPath: 'bi_url',
      licensePath: 'products.multi.extra.bi-analytics'
    }
  },
  {
    icon: 'alarm-screen',
    name: 'alarm_app',
    i18n: 'menu.alarm_app',
    i18n_description: 'menu.alarm_app_desc',
    meta: {
      configPath: 'alarm_app_url'
    }
  }
];

export const additionalItems: MenuItem[] = [
  {
    icon: 'cibr',
    name: 'case',
    path: PagePaths.Case,
    i18n: 'menu.cibr',
    i18n_description: 'menu.cibr_description',
    meta: {}
  }
];
