import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createBlock(_component_NDropdown, null, {
    toggle: _withCtx(({ toggle }) => [
      _createVNode(_component_NButton, {
        type: "link",
        onClick: toggle,
        icon: "arrows-up-down"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(_ctx.button_i18n_label, 'f')), 1)
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    body: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, ({ i18n_label, value }) => {
        return (_openBlock(), _createBlock(_component_NDropdownItem, {
          selected: _ctx.modelValue === value,
          key: value,
          select: "",
          onClick: ($event: any) => (_ctx.selectHandler(value))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(i18n_label)), 1)
          ]),
          _: 2
        }, 1032, ["selected", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}