import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_ActionsDropdown = _resolveComponent("ActionsDropdown", true)!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_ctx.actions.length)
    ? (_openBlock(), _createBlock(_component_NDropdown, { key: _ctx.actionsCount }, {
        toggle: _withCtx(({ toggle: parentToggle }) => [
          _renderSlot(_ctx.$slots, "toggle", { toggle: parentToggle }, () => [
            _createVNode(_component_NButton, {
              type: "text",
              onAction: parentToggle,
              icon: "more-vertical"
            }, null, 8, ["onAction"])
          ])
        ]),
        body: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: action.name
            }, [
              (action.children)
                ? (_openBlock(), _createBlock(_component_ActionsDropdown, {
                    key: 0,
                    actions: action.children,
                    onAction: _ctx.emitAction
                  }, {
                    toggle: _withCtx(({ toggle: childToggle }) => [
                      _createVNode(_component_NDropdownItem, {
                        class: "action-dropdown__nested-item",
                        icon: action.icon,
                        expand: "",
                        onClick: childToggle
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(action.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["icon", "onClick"])
                    ]),
                    _: 2
                  }, 1032, ["actions", "onAction"]))
                : (_openBlock(), _createBlock(_component_NDropdownItem, {
                    key: 1,
                    icon: action.icon,
                    onClick: ($event: any) => (_ctx.emitAction(action.name))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getActionName(action)), 1)
                    ]),
                    _: 2
                  }, 1032, ["icon", "onClick"]))
            ], 64))
          }), 128))
        ]),
        _: 3
      }))
    : _createCommentVNode("", true)
}