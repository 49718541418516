import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-password-form__group" }
const _hoisted_2 = { class: "login-password-form__label label-m" }
const _hoisted_3 = { class: "login-password-form__group" }
const _hoisted_4 = { class: "login-password-form__label label-m" }
const _hoisted_5 = { class: "login-password-form__group login-password-form__group_space_top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createElementBlock("form", {
    class: "login-password-form",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"])),
    onKeyup: _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('common.login')), 1),
      _createVNode(_component_NInput, {
        autofocus: "",
        placeholder: _ctx.$t('common.login_enter'),
        modelValue: _ctx.login,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.login) = $event)),
        "data-qa": 'login.login'
      }, null, 8, ["placeholder", "modelValue", "data-qa"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('common.password')), 1),
      _createVNode(_component_NInput, {
        placeholder: _ctx.$t('common.password_enter'),
        modelValue: _ctx.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
        password: "",
        "data-qa": 'login.password'
      }, null, 8, ["placeholder", "modelValue", "data-qa"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_NHint, {
        placement: "bottom",
        content: _ctx.$t(_ctx.error),
        "visible-mode": "manual",
        "is-visible": !!_ctx.error
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NButton, {
            "native-type": "submit",
            size: "big",
            loading: _ctx.isLoading,
            type: _ctx.submitButtonType,
            class: "login-password-form__submit-button",
            "data-qa": 'login.submit'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.login_with_password')), 1)
            ]),
            _: 1
          }, 8, ["loading", "type", "data-qa"])
        ]),
        _: 1
      }, 8, ["content", "is-visible"])
    ])
  ], 32))
}