/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Track = {
    readonly id: number;
    /**
     * Track creation date
     */
    readonly created_date: string;
    /**
     * Track start date
     */
    readonly track_start: string;
    /**
     * Track end date
     */
    readonly track_end: string;
    /**
     * Track id
     */
    readonly track_id: string;
    /**
     * Track camera
     */
    readonly camera: number;
    /**
     * Track video archive
     */
    readonly video_archive: number;
    /**
     * Track bbox history
     */
    readonly bbox_history: Record<string, any>;
    /**
     * Line points
     */
    readonly line_points: Record<string, any>;
    /**
     * Track points by line intersection points
     */
    readonly track_points: Record<string, any>;
    /**
     * Event id
     */
    readonly event_id: string;
};
export const EmptyTrack: Track = {
    id: 0,
        /**
        * Track creation date
        */
    created_date: '',
        /**
        * Track start date
        */
    track_start: '',
        /**
        * Track end date
        */
    track_end: '',
        /**
        * Track id
        */
    track_id: '',
        /**
        * Track camera
        */
    camera: 0,
        /**
        * Track video archive
        */
    video_archive: 0,
        /**
        * Track bbox history
        */
    bbox_history: {},
        /**
        * Line points
        */
    line_points: {},
        /**
        * Track points by line intersection points
        */
    track_points: {},
        /**
        * Event id
        */
    event_id: '',
};