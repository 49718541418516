import { CameraGroupsService, CamerasService, EpisodesService } from '@/api';
import { isStringWithValue } from '@/common/utils';
import type { CameraId, Nullable, VideoWallCameraGroupSource, VideoWallCameraSource, VideoWallHumanEpisodeSource } from './types';

const VIDEO_ARCHIVE_CAMERA_GROUP_ID = -1;

export const fetchCameraGroups: VideoWallCameraGroupSource = () =>
  CameraGroupsService.cameraGroupsList().then(({ results }) => (results ?? []).filter(({ id }) => id !== VIDEO_ARCHIVE_CAMERA_GROUP_ID));

export const fetchCameras: VideoWallCameraSource = () =>
  fetchPages((page) =>
    CamerasService.camerasList(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      page,
      undefined
    )
  );

export const fetchHumanEpisodes: VideoWallHumanEpisodeSource = (cameraIds) =>
  EpisodesService.episodesHumansList(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    cameraIds,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  ).then(({ results }) => results ?? []);

export function dragCameraId(event: DragEvent, cameraId: CameraId): void {
  event.dataTransfer?.setData('text/plain', String(cameraId));
}

export function dropCameraId(event: DragEvent): Nullable<CameraId> {
  const cameraId = event.dataTransfer?.getData('text/plain');
  return isStringWithValue(cameraId) && Number.isFinite(+cameraId) ? +cameraId : null;
}

type FetchResponsePage<T> = {
  next_page: Nullable<FetchResponseNextCursor>;
  prev_page: Nullable<FetchResponsePrevCursor>;
  results: T[];
};

type FetchResponseNextCursor = string;

type FetchResponsePrevCursor = string;

async function fetchPages<T>(source: (page: FetchResponseNextCursor | undefined) => Promise<FetchResponsePage<T>>): Promise<T[]> {
  async function fetch(results: T[], page: FetchResponseNextCursor | undefined): Promise<T[]> {
    const { next_page, results: items } = await source(page);
    const cursor = computeNextPageCursor(next_page ?? '');
    return isStringWithValue(cursor) ? await fetch([...results, ...items], cursor) : [...results, ...items];
  }

  return await fetch([], undefined);
}

function computeNextPageCursor(url: string): Nullable<string> {
  const [, cursor] = /page=(\w+)/i.exec(url) ?? [];
  return isStringWithValue(cursor) ? cursor : null;
}
