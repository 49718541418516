import { onActivated, onBeforeUnmount, onDeactivated, onMounted, ref } from 'vue';
import { Repeater } from '../reports/repeater';
import { sleep } from './components/tab-view/utils';
import { LicenseService } from './service/types';
import { License, LicenseUsage } from './types';
import { licenseModule } from '@/store/config/LicenseModule';

const LICENSE_REFRESH_TIMEOUT_IN_MS = 5e3;
const LICENSE_WAITING_TIMEOUT_IN_MS = 5e3;

export function useLicensePageViewModel(service: LicenseService) {
  const license = ref<License | null>(null);
  const licenseUsage = ref<LicenseUsage | null>(null);
  const repeater = new Repeater(LICENSE_REFRESH_TIMEOUT_IN_MS, fetchLicense);

  onActivated(start);
  onBeforeUnmount(pause);
  onDeactivated(pause);
  onMounted(start);

  function pause(): void {
    repeater.pause();
  }

  function start(): void {
    repeater.start();
  }

  async function fetchLicense(): Promise<void> {
    license.value = await service.fetchLicense();
    licenseUsage.value = await service.fetchLicenseUsage();
  }

  async function importLicense(license: File): Promise<void> {
    try {
      pause();
      await service.importLicense(license);
      await sleep(LICENSE_WAITING_TIMEOUT_IN_MS);
      await licenseModule.load();
    } catch (e) {
      console.error(e);
    } finally {
      start();
    }
  }

  function downloadC2V(): Promise<string> {
    return service.downloadC2V();
  }

  return {
    importLicense,
    license: license,
    licenseUsage: licenseUsage,
    downloadC2V
  };
}
