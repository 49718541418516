/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VMSArchiveResponse } from '../models/VMSArchiveResponse';
import type { VMSTimelineResponse } from '../models/VMSTimelineResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class VmsService {
    /**
     * Retrieve video archive stream link
     * Use this method to retrieve video archive stream link
     * @param timeFrom Video archive start date (UTC)
     * @param camera Related camera
     * @param maxHeight Maximum frame height
     * @param maxWidth Maximum frame width
     * @param quality Video quality
     * @param speed Video speed(1.0 - normal speed and it is the only speed supported now)
     * @param videoArchive Related video archive
     * @returns VMSArchiveResponse
     * @throws ApiError
     */
    public static vmsArchiveRetrieve(
        timeFrom: string,
        camera?: number | null,
        maxHeight: number = 1080,
        maxWidth: number = 1920,
        quality: number = 100,
        speed: number = 1,
        videoArchive?: number | null,
    ): CancelablePromise<VMSArchiveResponse> {
        return __request({
            method: 'GET',
            path: `/vms/archive/`,
            query: {
                'camera': camera,
                'max_height': maxHeight,
                'max_width': maxWidth,
                'quality': quality,
                'speed': speed,
                'time_from': timeFrom,
                'video_archive': videoArchive,
            },
        });
    }
    /**
     * Delete all chunks from interval
     * Use this method to delete all chunks from interval
     * @param timeTo Delete chunks until this date (UTC)
     * @param camera Related camera
     * @param timeFrom Delete chunks from this date (UTC) or all chunks before `time_to` if not passed
     * @param videoArchive Related video archive
     * @returns any No response body
     * @throws ApiError
     */
    public static vmsCleanupCreate(
        timeTo: string,
        camera?: number | null,
        timeFrom?: string,
        videoArchive?: number | null,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/vms/cleanup/`,
            query: {
                'camera': camera,
                'time_from': timeFrom,
                'time_to': timeTo,
                'video_archive': videoArchive,
            },
        });
    }
    /**
     * Download all chunks from interval
     * Use this method to download all chunks from interval
     * @param timeFrom Download video from this date (UTC)
     * @param timeTo Download video until this date (UTC)
     * @param camera Related camera
     * @param format
     * @param videoArchive Related video archive
     * @returns binary
     * @throws ApiError
     */
    public static vmsDownloadRetrieve(
        timeFrom: string,
        timeTo: string,
        camera?: number | null,
        format?: 'bytes' | 'json',
        videoArchive?: number | null,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/vms/download/`,
            query: {
                'camera': camera,
                'format': format,
                'time_from': timeFrom,
                'time_to': timeTo,
                'video_archive': videoArchive,
            },
        });
    }
    /**
     * Retrieve timeline info
     * Use this method to retrieve timeline info
     * @param timeFrom Timeline start date (UTC)
     * @param timeTo Timeline end date (UTC)
     * @param camera Related camera
     * @param gapThreshold Max gap threshold between intervals (format: 3s, 1m, 3h, 1h3m10s).
     * @param videoArchive Related video archive
     * @returns VMSTimelineResponse
     * @throws ApiError
     */
    public static vmsTimelineRetrieve(
        timeFrom: string,
        timeTo: string,
        camera?: number | null,
        gapThreshold: string = '1s',
        videoArchive?: number | null,
    ): CancelablePromise<VMSTimelineResponse> {
        return __request({
            method: 'GET',
            path: `/vms/timeline/`,
            query: {
                'camera': camera,
                'gap_threshold': gapThreshold,
                'time_from': timeFrom,
                'time_to': timeTo,
                'video_archive': videoArchive,
            },
        });
    }
}