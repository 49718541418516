import type { NAttachmentsI18n } from './types';

export const DefaultI18n: Required<NAttachmentsI18n> = {
  attach_first_one: 'Attach First One',
  attach_more: 'Attach More',
  b: 'b',
  cancel: 'Cancel',
  droparea_label: 'Drag and drop files to upload or',
  droparea_link_label: 'Select files',
  files: 'Files',
  gb: 'Gb',
  kb: 'Kb',
  mb: 'Mb',
  no_files: 'No Files',
  removing_confirmation: 'You want to delete this file?',
  yes: 'Yes'
};
