// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { convertPOTextToJSObject } from '@/common/po';
import { ILanguage } from '@/definitions/config/languages';
import { configModule } from '@/store/config/index';
import axios from 'axios';
import { reactive } from 'vue';

export interface ILanguageData {
  name: string;
  data: Record<string, any>;
}

class ConfigLanguageModule {
  loading = false;

  loadLanguages(): Promise<ILanguageData[]> {
    const languages = configModule.config.languages?.items;
    const fileDefinition = Array.isArray(languages) ? languages as ILanguage[] : [];
    const loadPromises = fileDefinition.map((value) => this.loadLanguage(value));

    this.loading = true;
    return Promise.all(loadPromises)
      .then((results) => {
        return results.filter((i) => !!i) as ILanguageData[];
      })
      .catch((e) => {
        console.warn('[languages] load error: ', e);
        return [];
      })
      .finally(() => {
        this.loading = false;
      });
  }

  loadLanguage({ name, url }: ILanguage): Promise<ILanguageData | null> {
    const loadUrl = `${url}?r=${Math.random()}`;
    return axios({ url: loadUrl, timeout: 30000 })
      .then((v) => {
        return convertPOTextToJSObject(v.data);
      })
      .then((v) => {
        v.name = name;
        return v;
      })
      .catch((e) => {
        console.warn(`[language] load error ${url}: ${e}`);
        return null;
      });
  }
}

export const configLanguageModule = reactive(new ConfigLanguageModule());

function getLangTokens(def: any = {}, current: any = {}, stats: any): any {
  const keys = [...new Set([...Object.keys(def), ...Object.keys(current)])];
  const r = keys.reduce(reduceHandler, {});

  function reduceHandler(m: any, v: any): any {
    const value = def[v] || current[v],
      currentValue = current[v] || '',
      valueIsObject = value && typeof value === 'object';

    if (valueIsObject) {
      stats.objectKeys++;
    } else {
      const currentValueLC = currentValue.toLowerCase().trim();
      stats.stringKeys++;
      if (currentValue) stats.dublicateKeys[currentValueLC] = stats.dublicateKeys[currentValueLC] > -1 ? stats.dublicateKeys[currentValueLC] + 1 : 0;
      if (!currentValue) {
        stats.emptyItems.push(`${v}`);
      } else if (def[v] === currentValue) {
        stats.equalItems.push(currentValue);
      } else stats.translated++;
    }

    m[v] = valueIsObject ? getLangTokens(def[v], current[v], stats) : (current && current[v]) || '';
    return m;
  }

  return r;
}

export function getCleanLangObject(lang: string, defaultObject: any, fromObject: any) {
  const stats = { stringKeys: 0, objectKeys: 0, emptyItems: [], equalItems: [], dublicateKeys: {}, translated: 0 },
    result = getLangTokens(defaultObject, fromObject, stats);
  console.log('Clean object', result, stats);
  return result;
}
