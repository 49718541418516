/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Case } from '../models/Case';
import type { CaseRequest } from '../models/CaseRequest';
import type { PaginatedCaseList } from '../models/PaginatedCaseList';
import type { PatchedCaseRequest } from '../models/PatchedCaseRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class CasesService {
    /**
     * List cases
     * This method retrieves the list of cases.
     * @param authors Select cases created by these authors
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param idIn Select cases with provided ids
     * @param incidentDateGt Select cases with `incident_date` **greater** than this value
     * @param incidentDateGte Select cases with `incident_date` **greater than or equal** to this value
     * @param incidentDateLt Select cases with `incident_date` **less** than this value
     * @param incidentDateLte Select cases with `incident_date` **less than or equal** to this value
     * @param limit Number of results to return per page.
     * @param nameContains Select cases where the SQL condition `name ILIKE name_contains` is true
     * @param ordering Which field to use when ordering the results.
     * @param page Pagination cursor value.
     * @param recordCreatedDateGt Select cases with `record_created_date` **greater** than this value
     * @param recordCreatedDateGte Select cases with `record_created_date` **greater than or equal** to this value
     * @param recordCreatedDateLt Select cases with `record_created_date` **less** than this value
     * @param recordCreatedDateLte Select cases with `record_created_date` **less than or equal** to this value
     * @param recordId Select cases where `record_id` exact this value
     * @param status Select cases where `status` takes one of these values
     * @returns PaginatedCaseList
     * @throws ApiError
     */
    public static casesList(
        authors?: Array<number>,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        idIn?: Array<number>,
        incidentDateGt?: string,
        incidentDateGte?: string,
        incidentDateLt?: string,
        incidentDateLte?: string,
        limit?: string,
        nameContains?: string,
        ordering?: string,
        page?: string,
        recordCreatedDateGt?: string,
        recordCreatedDateGte?: string,
        recordCreatedDateLt?: string,
        recordCreatedDateLte?: string,
        recordId?: string,
        status?: 'archived' | 'open',
    ): CancelablePromise<PaginatedCaseList> {
        return __request({
            method: 'GET',
            path: `/cases/`,
            query: {
                'authors': authors,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'id_in': idIn,
                'incident_date_gt': incidentDateGt,
                'incident_date_gte': incidentDateGte,
                'incident_date_lt': incidentDateLt,
                'incident_date_lte': incidentDateLte,
                'limit': limit,
                'name_contains': nameContains,
                'ordering': ordering,
                'page': page,
                'record_created_date_gt': recordCreatedDateGt,
                'record_created_date_gte': recordCreatedDateGte,
                'record_created_date_lt': recordCreatedDateLt,
                'record_created_date_lte': recordCreatedDateLte,
                'record_id': recordId,
                'status': status,
            },
        });
    }
    /**
     * Add new case
     * Use this method to add a new case.
     * @param requestBody
     * @returns Case
     * @throws ApiError
     */
    public static casesCreate(
        requestBody: CaseRequest,
    ): CancelablePromise<Case> {
        return __request({
            method: 'POST',
            path: `/cases/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve case by ID
     * This method retrieves a case by its ID.
     * @param id A unique integer value identifying this case.
     * @returns Case
     * @throws ApiError
     */
    public static casesRetrieve(
        id: number,
    ): CancelablePromise<Case> {
        return __request({
            method: 'GET',
            path: `/cases/${id}/`,
        });
    }
    /**
     * Update case properties
     * Use this method to update a case properties.
     * @param id A unique integer value identifying this case.
     * @param requestBody
     * @returns Case
     * @throws ApiError
     */
    public static casesUpdate(
        id: number,
        requestBody: CaseRequest,
    ): CancelablePromise<Case> {
        return __request({
            method: 'PUT',
            path: `/cases/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update case properties
     * Use this method to update a case properties.
     * @param id A unique integer value identifying this case.
     * @param requestBody
     * @returns Case
     * @throws ApiError
     */
    public static casesPartialUpdate(
        id: number,
        requestBody?: PatchedCaseRequest,
    ): CancelablePromise<Case> {
        return __request({
            method: 'PATCH',
            path: `/cases/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete case
     * Use this method to delete a case.
     * @param id A unique integer value identifying this case.
     * @returns void
     * @throws ApiError
     */
    public static casesDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/cases/${id}/`,
        });
    }
}