import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "external-search-request-create" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExternalSearchRequestForm = _resolveComponent("ExternalSearchRequestForm")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.itemUpdated)
      ? (_openBlock(), _createBlock(_component_NModalWindow, {
          key: 0,
          layout: true,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ExternalSearchRequestForm, {
              card: _ctx.itemUpdated,
              onClose: _ctx.closeForm
            }, null, 8, ["card", "onClose"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}