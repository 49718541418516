import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "viewer" }
const _hoisted_2 = {
  key: 0,
  class: "viewer__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_ImageViewer = _resolveComponent("ImageViewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading || _ctx.convertationInProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_NLoadingCircle)
        ]))
      : (_openBlock(), _createBlock(_component_ImageViewer, {
          key: _ctx.detectionModule.imageUrl,
          src: _ctx.detectionModule.imageUrl,
          bboxes: _ctx.selectableBboxes,
          bboxSelectable: true,
          actions: _ctx.actions,
          onSelectBbox: _ctx.selectBbox,
          onClose: _ctx.closeHandler
        }, null, 8, ["src", "bboxes", "actions", "onSelectBbox", "onClose"]))
  ]))
}