import { IFormContext } from '../forms/NForm.vue';
import { get } from 'lodash';

export const AllowedVideoProtocols = ['file:', 'http:', 'https:', 'rtmp:', 'rtsp:', 'rtp:'];
type DateFunction = () => Date;

export function getASCIIValidator() {
  const handler = (context: IFormContext) => {
    const value = context.item?.path && get(context.model, context.item.path);
    return (value || '').replace(/[^\x20-\x7F]/g, '').length === value?.length;
  };

  return {
    i18n_message: 'errors.string.should_be_ascii',
    handler
  };
}

export function getNotOnlyDigitsValidator() {
  const handler = (context: IFormContext) => {
    const value = context.item?.path && get(context.model, context.item.path);
    return (value || '').replace(/\d+/g, '').length !== 0;
  };

  return {
    i18n_message: 'errors.string.shouldnt_contain_only_digits',
    handler
  };
}

function getRequiredValidator() {
  const handler = (context: IFormContext) => {
    const value = context.item?.path && get(context.model, context.item.path);
    return Boolean(Array.isArray(value) ? value.length : value);
  };

  return {
    i18n_message: 'errors.required.field',
    handler
  };
}

function getNumberValidator({ required, min, max, float }: { required?: boolean; min?: number; max?: number; float?: boolean }) {
  let i18n_message;
  if (min !== undefined && max !== undefined) {
    i18n_message = float ? 'errors.number.float_between' : 'errors.number.between';
  } else if (min !== undefined) {
    i18n_message = float ? 'errors.number.float_from_min' : 'errors.number.from_min';
  } else if (max !== undefined) {
    i18n_message = float ? 'errors.number.float_to_max' : 'errors.number.to_max';
  } else {
    i18n_message = 'errors.number.validator_error';
  }

  return {
    i18n_message,
    replaceArray: [
      ['{{min}}', min],
      ['{{max}}', max]
    ],
    handler: function (context: IFormContext) {
      let value = context.item?.path && get(context.model, context.item.path);

      if (required && (value === '' || value === null || value === undefined)) {
        return false;
      }

      value = Number(value);
      const validMin = min !== undefined ? value >= min : true;
      const validMax = max !== undefined ? value <= max : true;
      const validFloat = !float ? value % 1 === 0 : true;
      const result = validMin && validMax && validFloat;
      return result;
    }
  };
}

function getUrlValidator(required = true) {
  return {
    i18n_message: 'errors.field.url',
    handler: function (context: IFormContext) {
      const value = context.item?.path && get(context.model, context.item.path);
      if (!value && !required) return true;
      try {
        let url = new URL(value);
        return AllowedVideoProtocols.includes(url.protocol);
      } catch (e) {
        return false;
      }
    }
  };
}

function getMultipleVideoUrlValidator() {
  return {
    i18n_message: 'errors.field.url',
    handler: function (context: IFormContext) {
      const values: string[] = String(context.item?.path && get(context.model, context.item.path))
        .split(/\n/)
        .filter((v) => !!v);
      const validator = (v: string) => {
        try {
          let url = new URL(v);
          return AllowedVideoProtocols.includes(url.protocol);
        } catch (e) {
          return false;
        }
      };

      return values.reduce((m, v) => {
        return m ? validator(v) : m;
      }, true);
    }
  };
}

function getStringLengthValidator({ required, min, max }: { required?: boolean; min?: number; max?: number }) {
  let i18n_message;
  if (min !== undefined && max !== undefined) {
    i18n_message = 'errors.string.between';
  } else if (min !== undefined) {
    i18n_message = 'errors.string.from_min';
  } else if (max !== undefined) {
    i18n_message = 'errors.string.to_max';
  } else {
    i18n_message = 'errors.number.validator_error';
  }

  return {
    i18n_message,
    replaceArray: [
      ['{{min}}', min],
      ['{{max}}', max]
    ],
    handler: function (context: IFormContext) {
      let value = context.item?.path && get(context.model, context.item.path);

      if (!required && value === '') {
        return true;
      }
      value = String(value).length;
      const validMin = min !== undefined ? value >= min : true;
      const validMax = max !== undefined ? value <= max : true;
      return validMin && validMax;
    }
  };
}

function getDateValidator({ min, max, i18n_message }: { min?: Date | DateFunction; max?: Date | DateFunction; i18n_message: string }) {
  const handler = (context: IFormContext) => {
    if (!context.item?.path) {
      return true;
    }

    const value = context.item?.path && get(context.model, context.item.path);
    if (!value) return true;

    const date = new Date(value);
    const minDate = min instanceof Function ? min() : min;
    const maxDate = max instanceof Function ? max() : max;

    const validMin = minDate !== undefined && date ? date > minDate : true;
    const validMax = maxDate !== undefined && date ? date < maxDate : true;
    return validMin && validMax;
  };

  return {
    i18n_message,
    handler
  };
}

export { getRequiredValidator, getNumberValidator, getUrlValidator, getMultipleVideoUrlValidator, getStringLengthValidator, getDateValidator };
