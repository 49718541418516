
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { dataModule } from '@/store/data';
import NTooltip from '@/uikit/hint/NTooltip.vue';
import NHint from '@/uikit/hint/NHint.vue';

@Options({
  name: 'DetectorsInfo',
  components: { NHint, NTooltip }
})
export default class DetectorsInfo extends Vue {
  @Prop({ type: String, required: true })
  readonly sourceType!: string;

  @Prop({ type: Number, required: true })
  readonly sourceId!: number;

  reference: any = null;

  get names() {
    let items: any[] = [];
    if (this.sourceType === 'camera') {
      items = dataModule.camerasModule.items;
    }
    if (this.sourceType === 'video_archive') {
      items = dataModule.videosModule.items;
    }
    const item = items.find((item) => item.id === this.sourceId);
    const detectors = item?.stream_settings?.detectors;
    let names = [];
    if (detectors?.face) {
      names.push(this.$t('common.faces'));
    }
    if (detectors?.body) {
      names.push(this.$t('common.bodies'));
    }
    if (detectors?.car) {
      names.push(this.$t('common.cars'));
    }
    if (names.length) {
      return names.join(', ');
    }
    return this.$t('common.none');
  }
}
