import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "groups control-m" }
const _hoisted_2 = { class: "groups__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.items)
          ? (_openBlock(), _createBlock(_component_HeaderBar, {
              key: 0,
              total: _ctx.items.length,
              "current-count": _ctx.roles.length,
              "add-button": _ctx.modelAcl.add,
              "search-text": _ctx.searchText,
              "onUpdate:search-text": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
              onAdd: _ctx.addNewGroup
            }, null, 8, ["total", "current-count", "add-button", "search-text", "onAdd"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_NLoadingCircle, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.loaded)
            ? (_openBlock(), _createBlock(_component_NTable, {
                key: 1,
                class: "groups__table",
                columns: _ctx.columns,
                content: _ctx.roles,
                "selected-items-ids": _ctx.selectedItemsIds,
                "disabled-items-ids": _ctx.disabledItemIds,
                onAction: _ctx.handleTableAction,
                "onSelect:changes": _ctx.handleSelectChanges
              }, null, 8, ["columns", "content", "selected-items-ids", "disabled-items-ids", "onAction", "onSelect:changes"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}