/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type KycFilter = {
  chart_types?: KycChartType;
  precision: string;
  return_interval: string;
  returns_number: number;
  created_date_lte?: string;
  created_date_gte?: string;
  timezone?: string;
  age_gte?: number;
  age_lte?: number;
  gender?: 'male' | 'female';
  cameras?: number[];
  watch_lists?: number[];
};

export type KycChartType = 'visitors' | 'gender' | 'age' | 'cameras' | 'visits';
export type KycAreaChartType = Exclude<KycChartType, 'cameras'>;
export type KycBarChartType = 'cameras';

export const EmptyKycFilter: KycFilter = {
  chart_types: undefined,
  precision: '1d',
  return_interval: '1h',
  returns_number: 1,
  created_date_gte: '',
  created_date_lte: '',
  timezone: '',
  age_gte: undefined,
  age_lte: undefined,
  gender: undefined,
  cameras: [],
  watch_lists: []
};
