export default {
  create_first_case: 'Add case file',
  case: 'case file',
  new_case: 'new case file',
  new_case_description: 'You are opening a new case file. Be sure to specify its name and number (ID), and the incident date',
  incident_date: 'incident date',
  record_id: 'Case ID in registry',
  record_id_short: 'Registry',
  record_date: 'Case date',
  set_role_permissions: 'Set access permissions',
  filter_all: 'all',
  filter_open: 'open',
  filter_archived: 'archived',
  name: 'name',
  type: 'type',
  connections: 'connections',
  information: 'information',
  cluster: 'cluster',
  witness: 'Witness',
  victim: 'Victim',
  suspect: 'Suspect',
  irrelevant: 'Not relevant',
  comment: 'comment',
  related_cards: 'Related individuals records',
  related_participants: 'Related participants',
  clusters: 'Detected people',
  participants: 'Participants',
  participants_in_current_case: 'Participants of current case',
  participants_in_other_cases: 'Participants of other cases',
  card_index: 'Record index',
  wizard_help_text:
    'This is the individual who was automatically recognized while processing case file footage. If they are relevant to the case, mark them as a participant. If you are sure that there is a match with the other recognized individuals, or with the index records, or with the participants of other cases, establish a link by clicking the Add button',
  create_new_participant: 'Mark as participant',
  add: 'Add',
  save_and_done: 'Save and close',
  save_participant: 'Save',
  case_id: 'Case ID',
  enter_case_id: 'Enter case ID',
  participant_name: 'Participant name',
  enter_participant_name: 'Enter participant name',
  role: 'Role',
  date_time_participant: 'Date and time of participant creation'
};
