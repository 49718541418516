import { ITableColumn } from '@/uikit/table/NTable.vue';
import { DeviceBlacklistRecord } from '@/api';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import BlocklistItem, { BlocklistItemTypes } from '@/components/common/BlocklistItem.vue';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';

export const columns: ITableColumn[] = [
  {
    width: '40px',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.id',
        name: 'id'
      }
    },
    content: {
      path: 'id',
      component: BlocklistItem,
      props: (item: DeviceBlacklistRecord) => ({ name: item.id, type: BlocklistItemTypes.Small })
    }
  },
  {
    width: '260px',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.uuid',
        name: 'uuid'
      }
    },
    content: {
      path: 'uuid',
      classes: [],
      component: BlocklistItem,
      props: (item: DeviceBlacklistRecord) => ({ name: item.uuid, type: BlocklistItemTypes.Small })
    }
  },
  {
    header: {
      classes: ['align-lft', 'bottom-border'],
      component: NTableColumnCell,
      props: { i18n_label: 'common.reason', name: 'reason' }
    },
    content: {
      path: 'reason',
      classes: [],
      component: BlocklistItem,
      props: (item: DeviceBlacklistRecord) => ({ name: item.reason, type: BlocklistItemTypes.Small })
    }
  },
  {
    width: '110px',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: { i18n_label: 'common.created', name: 'created_date', sortable: true }
    },
    content: {
      path: 'created_date',
      classes: [],
      component: NDateTimeLabel,
      props: {
        multiline: true,
        size: 'xs'
      }
    }
  },
  {
    width: '110px',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: { i18n_label: 'common.expires', name: 'expire_date' }
    },
    content: {
      path: 'expire_date',
      classes: [],
      component: NDateTimeLabel,
      props: {
        multiline: true,
        size: 'xs'
      }
    }
  }
];
