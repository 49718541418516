import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "reports-page-table-column-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportsPageTableColumnLabel = _resolveComponent("ReportsPageTableColumnLabel")!
  const _component_ReportsPageTableColumnStatusDownload = _resolveComponent("ReportsPageTableColumnStatusDownload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ReportsPageTableColumnLabel, {
      class: _normalizeClass(_ctx.classes)
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.content), 1)
      ]),
      _: 1
    }, 8, ["class"]),
    (_ctx.isDownloadAvailable)
      ? (_openBlock(), _createBlock(_component_ReportsPageTableColumnStatusDownload, {
          key: 0,
          formats: _ctx.formats,
          onDownload: _ctx.dispatchDownloadEvent
        }, null, 8, ["formats", "onDownload"]))
      : _createCommentVNode("", true)
  ]))
}