/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PermissionsCameraGroupsFilter = {
            /**
            * Number of results to return.
            */
        limit?: number;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `i`, `d`
            */
        ordering?: string;
}
export const EmptyPermissionsCameraGroupsFilter: PermissionsCameraGroupsFilter = {
        limit: 20,
        ordering: undefined,
}