
import { Prop, Watch } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';

export type DropdownItem = {
  value: [string, number];
  label: [string, number];
};

@Options({
  name: 'NDropdownInput'
})
export default class NDropdownInput extends Vue {
  @Prop({ type: Array, required: true })
  readonly items!: DropdownItem[];

  @Prop({
    type: [String, Number]
  })
  readonly value?: string | number;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  currentValue: any = null;
  currentIndex = 0;

  created() {
    this.setCurrent();
    this.$nextTick(() => this.computeCurrentIndex());
  }

  mounted() {
    this.focus();
  }

  get dropdownItems(): Array<HTMLLIElement> | null {
    return (this.$refs.dropdown as Array<HTMLLIElement>) || null;
  }

  get cssClass() {
    let base = 'n-input-dropdown';
    return {
      [base]: true,
      [`${base}_disabled`]: this.disabled
    };
  }

  @Watch('currentIndex')
  currentWatcher(index: number) {
    this.currentValue = this.dropdownItems?.[index].getAttribute('name');
    this.focus();
  }

  clickHandler(value: string | number) {
    if (this.disabled) return;
    this.currentValue = value;
    this.$emit('change', value);
    this.computeCurrentIndex();
  }

  keyupEnterHandler(): void {
    if (this.disabled) return;
    this.$emit('change', this.currentValue);
  }

  focus(): void {
    this.dropdownItems?.[this.currentIndex].focus();
  }

  setCurrent(): void {
    this.currentValue = this.value || this.items[0].value;
  }

  computeCurrentIndex(): void {
    this.items.forEach((item, index) => {
      if (item.value == this.currentValue) {
        this.currentIndex = index;
      }
    });
  }

  increase(): void {
    if (this.disabled) return;
    if (this.currentIndex < this.items.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = this.items.length - 1;
    }
  }

  decrease(): void {
    if (this.disabled) return;
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = 0;
    }
  }

  keyupUpHandler(): void {
    this.decrease();
  }

  keyupDownHandler(): void {
    this.increase();
  }

  keyupLeftHandler(): void {
    this.decrease();
  }

  keyupRightHandler(): void {
    this.increase();
  }
}
