import { VideoArchive, VideoArchiveRequest } from '@/api';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { ListViewModel } from '@/definitions/view-models';
import { NAttachment } from '@/uikit/attachments/types';
import { cloneDeep } from 'lodash';
import { reactive } from 'vue';
import { getDelay } from '@/definitions/common/base';
import { globalEventModule } from '@/store/global-event';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';

export class UploadModule {
  clients: Record<string, any> = {};
  progressStates: Record<string, any> = {};

  registerClient(clientId: string, module: ListViewModel<any, any>) {
    this.clients[clientId] = module;
  }

  syncClient(clientId: string) {
    const progressState = this.getProgressState(clientId);
    const module = this.getModule(clientId);

    Object.values(progressState).forEach((v: any) => {
      const moduleItem = module.items.find((i) => i.id === v.item.id);
      if (moduleItem) moduleItem.__upload_progress = v.upload_progress;
    });
  }

  unregisterClient(clientId: string) {
    delete this.clients[clientId];
  }

  getProgressState(clientId: string) {
    if (!this.progressStates[clientId]) this.progressStates[clientId] = reactive({});
    return this.progressStates[clientId];
  }

  getModule(clientId: string): ListViewModel<any, any> {
    return this.clients[clientId];
  }

  getVideoItemByUrl(url: string, options: any, params: any): VideoArchiveRequest {
    const video = { name: '', url: '', case: undefined, camera_group: null, stream_settings: { detectors: { face: null, body: null, car: null } } };
    if (options.id) video.case = options.id;
    video.name = url;
    video.url = url;
    video.camera_group = options.camera_group;
    video.stream_settings = cloneDeep(params.stream_settings);
    if (!options.detect_faces) {
      video.stream_settings.detectors.face = null;
    }
    if (!options.detect_bodies) {
      video.stream_settings.detectors.body = null;
    }
    if (!options.detect_cars) {
      video.stream_settings.detectors.car = null;
    }
    return video as any;
  }

  getVideoItem(attachement: NAttachment, options: any, params: any): VideoArchiveRequest {
    const video: Partial<VideoArchive> = {
      name: '',
      camera_group: -1,
      stream_settings: {
        detectors: { face: null, body: null, car: null },
        start_stream_timestamp: 0,
        use_stream_timestamp: false
      }
    };
    if (options.id) video.case = options.id;
    video.name = attachement.name;
    video.camera_group = options.camera_group;
    video.stream_settings = cloneDeep(params.stream_settings);
    if (!options.face_detector) {
      video.stream_settings!.detectors!.face = null;
    }
    if (!options.body_detector) {
      video.stream_settings!.detectors!.body = null;
    }
    if (!options.car_detector) {
      video.stream_settings!.detectors!.car = null;
    }
    if (options.start_stream_timestamp) {
      video.stream_settings!.start_stream_timestamp = options.start_stream_timestamp;
      video.stream_settings!.use_stream_timestamp = true;
    }
    return video as any;
  }

  async createVideos(clientId: string, urls: string[], options: any, params: any): Promise<VideoArchive[]> {
    const result: VideoArchive[] = [];
    for (let i in urls) {
      const attachment = urls[i];
      const videoArchive = this.getVideoItemByUrl(attachment, options, params);
      const item = await this.getModule(clientId).create(videoArchive);
      result.push(item);
      await this.getModule(clientId).get();
      this.syncClient(clientId);
      const uploadProgressHandler = ({ loaded, total }: ProgressEvent | any) => {
        const result = Math.round((loaded / total) * 100);
        this.getProgressState(clientId)[item.id] = { item, upload_progress: result };
        const moduleItem = this.getModule(clientId).items.find((v) => v.id === item.id);
        if (moduleItem) moduleItem.__upload_progress = result;
      };
      uploadProgressHandler({ loaded: 1, total: 1 });
      this.syncClient(clientId);

      await getDelay(2000);
      await this.getModule(clientId).get();
    }
    globalEventModule.sendCreate(MultisidebarItemTypes.Videos, null, 1000);
    return result;
  }

  async uploadVideos(clientId: string, attachments: NAttachment[], options: any, params: any): Promise<VideoArchive[]> {
    const result: VideoArchive[] = [];
    for (let i in attachments) {
      const attachment = attachments[i];
      const videoArchive = this.getVideoItem(attachment, options, params);
      const axios = dataServiceFactory.getAxiosInstance();
      const item = await this.getModule(clientId).create(videoArchive);
      result.push(item);
      await this.getModule(clientId).get();
      this.syncClient(clientId);

      const uploadProgressHandler = ({ loaded, total }: ProgressEvent | any) => {
        const result = Math.round((loaded / total) * 100);
        this.getProgressState(clientId)[item.id] = { item, upload_progress: result };
        const moduleItem = this.getModule(clientId).items.find((v) => v.id === item.id);
        if (moduleItem) moduleItem.__upload_progress = result;
      };

      try {
        uploadProgressHandler({ loaded: 0, total: 1 });
        await axios.put(`videos/${item.id}/upload/source_file/`, attachment.file, { onUploadProgress: uploadProgressHandler });
        uploadProgressHandler({ loaded: 1, total: 1 });
      } catch (e) {
        console.warn('[attachment] error: ', e);
      }

      this.syncClient(clientId);
      await getDelay(2000);
      await this.getModule(clientId).get(); // Slow status updating
    }
    return result;
  }
}

export const uploadModule = reactive(new UploadModule());
