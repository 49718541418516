import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "label-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.camerasIds.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.camerasIds, (cameraId) => {
          return (_openBlock(), _createElementBlock("div", {
            key: cameraId,
            class: "camera-labels__camera-link"
          }, [
            (_ctx.hasPlay && cameraId)
              ? (_openBlock(), _createBlock(_component_NButton, {
                  key: 0,
                  onAction: ($event: any) => (_ctx.emitPlayAction(cameraId)),
                  icon: "play",
                  type: "link"
                }, null, 8, ["onAction"]))
              : _createCommentVNode("", true),
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.cameraLabelsComponent), {
              type: "link",
              class: "camera-label-item",
              onClick: _withModifiers(($event: any) => (_ctx.emitActionShowCamera(cameraId)), ["stop","prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getCameraGroupByCameraId(cameraId)) + _toDisplayString(_ctx.formattedSeparator) + _toDisplayString(_ctx.getCameraName(cameraId)), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          ]))
        }), 128))
      : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.cameraLabelsComponent), {
          key: 1,
          type: "link",
          class: "camera-label-item",
          onClick: _withModifiers(_ctx.emitActionShowVideo, ["stop","prevent"])
        }, {
          default: _withCtx(() => [
            (_ctx.cameraGroupsIds.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.cameraGroupsIds, (cameraGroupId) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: cameraGroupId }, [
                    _createTextVNode(_toDisplayString(`${_ctx.getCameraGroupName(cameraGroupId)}${!_ctx.isVideoArchiveCameraGroup(cameraGroupId) ? `${_ctx.formattedSeparator}${_ctx.formattedCameraNotFound}` : ''}`), 1)
                  ], 64))
                }), 128))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.formattedCameraGroupNotFound + _ctx.formattedSeparator + _ctx.formattedCameraNotFound), 1)
                ], 64))
          ]),
          _: 1
        }, 8, ["onClick"]))
  ]))
}