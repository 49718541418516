import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "vp-controls" }
const _hoisted_2 = { class: "vp-controls__left" }
const _hoisted_3 = { class: "vp-controls__center" }
const _hoisted_4 = { class: "vp-controls__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_FullscreenButton = _resolveComponent("FullscreenButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["vp-controls__button vp-controls__button-live", { 'vp-controls__button-live-enabled': _ctx.isLive }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToLive && _ctx.goToLive(...args)))
      }, [
        _createVNode(_component_NIcon, {
          name: "vp_live",
          width: "32",
          height: "20"
        })
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.showRewinds)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", {
              class: "vp-controls__button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.movePosition(-60)))
            }, [
              _createVNode(_component_NIcon, {
                name: "vp_backward_60",
                width: "100%",
                height: "100%"
              })
            ]),
            _createElementVNode("div", {
              class: "vp-controls__button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.movePosition(-15)))
            }, [
              _createVNode(_component_NIcon, {
                name: "vp_backward_15",
                width: "100%",
                height: "100%"
              })
            ])
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.isPause)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "vp-controls__button vp-controls__button-play",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.play && _ctx.play(...args)))
          }, [
            _createVNode(_component_NIcon, {
              name: "vp_play",
              width: "100%",
              height: "100%"
            })
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "vp-controls__button vp-controls__button-pause",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.pause && _ctx.pause(...args)))
          }, [
            _createVNode(_component_NIcon, {
              name: "vp_pause",
              width: "100%",
              height: "100%"
            })
          ])),
      (_ctx.showRewinds)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
            _createElementVNode("div", {
              class: "vp-controls__button",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.movePosition(15)))
            }, [
              _createVNode(_component_NIcon, {
                name: "vp_forward_15",
                width: "100%",
                height: "100%"
              })
            ]),
            _createElementVNode("div", {
              class: "vp-controls__button",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.movePosition(60)))
            }, [
              _createVNode(_component_NIcon, {
                name: "vp_forward_60",
                width: "100%",
                height: "100%"
              })
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "vp-controls__button",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('toggleInfoPanel')))
      }, [
        _createVNode(_component_NIcon, {
          name: "vp-info",
          width: "100%",
          height: "100%"
        })
      ]),
      (_ctx.isLive && _ctx.ptzButton)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "vp-controls__button",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('togglePtzPanel')))
          }, [
            _createVNode(_component_NIcon, {
              name: "vp-ptz",
              width: "100%",
              height: "100%"
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "vp-controls__button",
        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('toggleSettingsPanel')))
      }, [
        _createVNode(_component_NIcon, {
          name: "vp-settings",
          width: "100%",
          height: "100%"
        })
      ]),
      (_ctx.showRewinds && !_ctx.fullscreen)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "vp-controls__button",
            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.toggleExportMode && _ctx.toggleExportMode(...args)))
          }, [
            _createVNode(_component_NIcon, {
              name: "vp_download",
              width: "100%",
              height: "100%"
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_FullscreenButton, {
        class: "vp-controls__button",
        element: _ctx.fullscreenElement,
        resizeEvent: true,
        onFullscreenChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.fullscreen = $event))
      }, null, 8, ["element"])
    ])
  ]))
}