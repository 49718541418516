import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "n-attachments-drop-area-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'n-attachments-drop-area_enter': _ctx.enter,
      'n-attachments-drop-area': true
    }),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dispatchAttachEvent && _ctx.dispatchAttachEvent(...args)), ["stop"])),
    onDragenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragenter && _ctx.dragenter(...args))),
    onDragleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dragleave && _ctx.dragleave(...args))),
    onDragover: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
    onDrop: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.drop && _ctx.drop(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NIcon, {
        class: "n-attachments-drop-area-content__icon",
        name: "vector"
      }),
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 34))
}