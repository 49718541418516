
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { KycTimeToken, KycItem } from '@/store/kyc/types';
import {
  AreaColorsByType,
  AxisPointerOpacity,
  DefaultColors,
  PrecisionFormatters,
  SeriesNameToValueToken,
  StatisticsDescriptions,
  LineColorToken,
  TextColorToken,
  getThemeTokenValue,
  TooltipBackgroundColorToken,
  TooltipColorToken
} from './chart-helpers';
import { KycAreaChartType, KycFilter } from '@/api/models/KycFilter';
import Chart from './Chart.vue';
import { TimeTokenToI18n } from '@/store/kyc/helpers';
import ChartStatistic from '@/pages/kyc/components/ChartStatistic.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import echarts from 'echarts';
import ChartGraph from '@/pages/kyc/components/ChartGraph.vue';

@Options({
  name: 'ChartArea',
  components: { ChartGraph, NButton, ChartStatistic, Chart },
  emits: ['activate', 'show-fullscreen', 'download']
})
export default class ChartArea extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: KycItem;

  @Prop({ type: Object, required: true })
  readonly filter!: KycFilter;

  @Prop({ type: Boolean, default: false })
  readonly full!: boolean;

  get precisionHeader() {
    const precisionLabel = this.$t('kyc.precision', 'f');
    const precisionValue = this.$tm(this.precisionLocaleStrings[0], { count: this.precisionLocaleStrings[1] });
    return `${precisionLabel}: ${precisionValue}`;
  }

  get type() {
    return this.item.type as KycAreaChartType;
  }

  get statistics() {
    return this.item.data.overall?.map((percentage: any, index: number) => ({
      percentage,
      description: this.descriptions?.[index]
    }));
  }

  get summary() {
    return this.item.data.total || this.item.data.average;
  }

  get precisionLocaleStrings() {
    return TimeTokenToI18n[this.filter.precision as KycTimeToken];
  }

  get isSummaryExists() {
    return Number.isFinite(this.summary);
  }

  get colors() {
    return AreaColorsByType[this.type] || DefaultColors;
  }

  get descriptions() {
    return StatisticsDescriptions[this.type]?.map((v) => this.$t(v, 'f'));
  }

  get precision() {
    return this.filter.precision;
  }

  get options() {
    return {
      title: {
        show: false
      },
      tooltip: {
        show: this.full,
        formatter: this.getFormatTooltip,
        axisPointer: {
          type: 'cross',
          lineStyle: {
            opacity: AxisPointerOpacity
          },
          label: {
            backgroundColor: getThemeTokenValue(TooltipBackgroundColorToken),
            shadowBlur: 0,
            fontSize: 10,
            formatter: this.getFormatAxisLabel
          },
          padding: 10,
          textStyle: {
            color: getThemeTokenValue(TooltipColorToken)
          }
        },
        trigger: 'axis'
      },
      xAxis: {
        data: this.item.data.xAxisData,
        type: 'time',
        axisLine: {
          lineStyle: {
            color: getThemeTokenValue(LineColorToken)
          }
        },
        splitLine: {
          show: this.full
        },
        axisLabel: {
          show: true,
          interval: 0,
          overflow: false,
          color: getThemeTokenValue(TextColorToken),
          fontSize: 10,
          formatter: (v: any) => this.getFormatDate(v, { splitTimeLine: true })
        }
      },
      yAxis: {
        show: this.full,
        padding: 0,
        type: 'value',
        axisLine: {
          lineStyle: {
            color: getThemeTokenValue(LineColorToken)
          }
        },
        splitLine: {
          show: this.full
        },
        axisLabel: {
          show: true,
          interval: 0,
          overflow: false,
          color: getThemeTokenValue(TextColorToken),
          fontSize: 10,
          formatter: this.getFormatValue
        }
      },
      grid: {
        show: false,
        top: '80px',
        left: this.full ? '32px' : 0,
        right: this.full ? '32px' : 0,
        bottom: '32px',
        containLabel: this.full
      },
      series: this.item.data.yAxisData?.map((data: any, i: number) => ({
        data: this.getYAxisData(data),
        name: this.item.data.yAxisNames && this.item.data.yAxisNames[i],
        type: 'line',
        showSymbol: false,
        showAllSymbol: false,
        color: this.colors[i],
        areaStyle: {
          color: this.getAreaColor(this.colors[i]),
          opacity: this.item.opacity || 1
        },
        lineStyle: {
          opacity: 0,
          color: this.colors[i]
        }
      }))
    };
  }

  getFormatAxisLabel(v: any) {
    let r = v.value;

    if (v.axisDimension === 'x') {
      r = this.getFormatDate(v.value);
    } else if (v.axisDimension === 'y') {
      r = this.getFormatValue(v.value);
    }

    return r;
  }

  getFormatTooltip(v: any) {
    let r = '';
    const { counterData } = this.item.data;
    v.forEach((v: any, k: number) => {
      try {
        if (k === 0) r += v.axisValueLabel;
        const count = counterData && counterData[k][v.dataIndex];
        const counterLabel = counterData ? ` (${this.$tm('kyc.records_pf', { count })})` : '';
        r += `<br/><span style="color:${v.color};">&#x25cf;</span> <span>${this.getFormatValue(v.value[1], v.seriesName)}${counterLabel}</span>`;
      } catch (e) {
        r += `<br/> [value-error] ${k}`;
      }
    });
    return r;
  }

  getFormatDate(v: any, { splitTimeLine }: any = {}) {
    const date = new Date(v),
      { precision } = this.filter;

    let r = this.$filters.formatDateTime(date, PrecisionFormatters[precision as KycTimeToken]);
    return splitTimeLine ? r.split(' ').join('\n') : r;
  }

  getFormatValue(v: number, seriesName: string = '') {
    let formattedValue = Math.round(v),
      valueToken = SeriesNameToValueToken[seriesName] || '',
      isPluralValueToken = !!valueToken.match(/^.*_pf$/),
      translatedToken = isPluralValueToken ? this.$tm(valueToken, { count: v }) : this.$t(valueToken, 'f');

    return valueToken ? translatedToken : formattedValue;
  }

  getYAxisData(data: any) {
    return data.map((v: any, i: number) => [this.item.data.xAxisData[i], v]);
  }

  getAreaColor(v: any) {
    let color = Array.isArray(v)
      ? new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          {
            offset: 0,
            color: v[0]
          },
          {
            offset: 1,
            color: v[1]
          }
        ])
      : v;
    return color;
  }
}
