
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import NDropdown from '@/uikit/dropdown/NDropdown.vue';
import NDropdownItem from '@/uikit/dropdown/NDropdownItem.vue';
import { DefaultItemAclResult, DefaultModelAclResult } from '@/store/acl';
import { ItemAclResult, ModelAclResult } from '@/store/acl/types';
import MultisidebarHeaderActions from '@/components/multisidebar/MultisidebarHeaderActions.vue';
import { ActionI18N } from '@/uikit';
import NTag from '@/uikit/tag/NTag.vue';

@Options({
  name: 'MultisidebarHeader',
  components: { NTag, MultisidebarHeaderActions, NDropdownItem, NDropdown, NButton },
  emits: ['action']
})
export default class MultisidebarHeader extends Vue {
  @Prop({ type: String, default: '' })
  readonly label!: string;

  @Prop({ type: String, default: '' })
  readonly subLabel!: string;

  @Prop({ type: Boolean, default: false })
  readonly changed!: boolean;

  @Prop({ type: Array, default: () => [] })
  readonly actions!: ActionI18N[];

  @Prop({ type: Boolean, default: false })
  readonly inactivated!: boolean;

  @Prop({ type: String, default: 'common.inactivated' })
  readonly i18nInactivatedLabel!: string;

  @Prop({ type: Object, default: () => ({ ...DefaultItemAclResult }) })
  readonly currentItemAcl!: ItemAclResult;

  @Prop({ type: Object, default: () => ({ ...DefaultModelAclResult }) })
  readonly modelAcl!: ModelAclResult;

  @Prop({ type: Boolean, default: true })
  readonly hasDeselect!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasToggle!: boolean;

  actionHandler(action: string) {
    this.$emit('action', action);
  }
}
