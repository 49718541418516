/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Notification = {
    readonly id: number;
    /**
     * Notification type
     */
    readonly type: string;
    /**
     * Notification creation date
     */
    readonly created_date: string;
    /**
     * Notification acknowledgement date
     */
    readonly acknowledged_date: string;
    /**
     * Notification acknowledgement reaction
     */
    acknowledged_reaction?: string;
    /**
     * Notification acknowledgement reaction
     */
    readonly active: boolean;
    /**
     * Users subscribed to the notification
     */
    readonly subscribed_users: Array<number>;
    /**
     * Event id
     */
    readonly event_id: string;
    /**
     * Event object type
     */
    readonly object_type: string;
    /**
     * User that acknowledged notification
     */
    readonly acknowledged_by: number;
};
export const EmptyNotification: Notification = {
    id: 0,
    /**
     * Notification type
     */
    type: '',
    /**
     * Notification creation date
     */
    created_date: '',
    /**
     * Notification acknowledgement date
     */
    acknowledged_date: '',
    /**
     * Notification acknowledgement reaction
     */
    acknowledged_reaction: '',
    /**
     * Notification acknowledgement reaction
     */
    active: false,
    /**
     * Users subscribed to the notification
     */
    subscribed_users: [],
    /**
     * Event id
     */
    event_id: '',
    /**
     * Event object type
     */
    object_type: '',
    /**
     * User that acknowledged notification
     */
    acknowledged_by: 0,
};
