
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import ExternalSearchRequestForm from '@/pages/external-search/requests/ExternalSearchRequestForm.vue';
import { CarCard, HumanCard } from '@/api';
import enrichCards, { EnrichedCard } from '@/components/cards/enrich-cards';
import { RouterModule } from "@/store/router";
import { EnrichedSearchRequest } from "@/pages/external-search/requests/enrich-search-requests";

@Options({
  name: 'ExternalSearchRequestCreate',
  components: { ExternalSearchRequestForm, NModalWindow, NButton }
})
export default class ExternalSearchRequestCreate extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: HumanCard;

  itemUpdated: EnrichedCard | null = null;

  async mounted() {
    [this.itemUpdated] = await enrichCards([this.item]);
  }

  closeForm(item: EnrichedSearchRequest) {
    this.$emit('close');
    RouterModule.navigateToExternalSearch(item.id);
  }
}
