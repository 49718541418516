import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_SessionsAndBlocklistTabs = _resolveComponent("SessionsAndBlocklistTabs")!
  const _component_FilterSection = _resolveComponent("FilterSection")!
  const _component_EditBlocklistForm = _resolveComponent("EditBlocklistForm")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!
  const _component_SettingsMenu = _resolveComponent("SettingsMenu")!
  const _component_ListPage = _resolveComponent("ListPage")!

  return (_openBlock(), _createBlock(_component_ListPage, {
    state: _ctx.state,
    statistics: false,
    "onUpdate:scrollBottom": _ctx.scrollBottomHandler,
    class: "blocklist-page",
    ref: "listPage"
  }, {
    filters: _withCtx(() => [
      _createVNode(_component_SessionsAndBlocklistTabs, null, {
        default: _withCtx(() => [
          (_ctx.deleteAvailable && _ctx.selectedItems.length)
            ? (_openBlock(), _createBlock(_component_NButton, {
                key: 0,
                onAction: _ctx.deleteAll,
                label: _ctx.$t('common.delete'),
                icon: "trash",
                type: "secondary"
              }, null, 8, ["onAction", "label"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_FilterSection, {
        filter: _ctx.module.filter,
        "scheme-small": _ctx.getSmallFilterLayout,
        "has-pinned-fields": ""
      }, null, 8, ["filter", "scheme-small"])
    ]),
    items: _withCtx(() => [
      (_ctx.showModalWindow && _ctx.modelAcl.update)
        ? (_openBlock(), _createBlock(_component_EditBlocklistForm, {
            key: 0,
            model: _ctx.currentItem,
            onClose: _ctx.closeHandler,
            onSave: _ctx.saveHandler
          }, null, 8, ["model", "onClose", "onSave"]))
        : _createCommentVNode("", true),
      (_ctx.module.loaded && _ctx.items.length)
        ? (_openBlock(), _createBlock(_component_NTable, {
            key: 1,
            columns: _ctx.columns,
            content: _ctx.items,
            "selected-items-ids": _ctx.selectedItemIds,
            onAction: _ctx.handleTableAction,
            onSort: _ctx.handleTableSort
          }, null, 8, ["columns", "content", "selected-items-ids", "onAction", "onSort"]))
        : _createCommentVNode("", true),
      _createVNode(_component_InfiniteScroll, {
        filters: _ctx.module.filter.current,
        "next-page": _ctx.module.next_page,
        onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.module.append()))
      }, null, 8, ["filters", "next-page"])
    ]),
    "left-bar": _withCtx(() => [
      _createVNode(_component_SettingsMenu)
    ]),
    _: 1
  }, 8, ["state", "onUpdate:scrollBottom"]))
}