/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OnvifAuthRequest } from '../models/OnvifAuthRequest';
import type { OnvifCamera } from '../models/OnvifCamera';
import type { PaginatedOnvifCameraList } from '../models/PaginatedOnvifCameraList';
import type { PatchedOnvifSetImagingSettingsRequest } from '../models/PatchedOnvifSetImagingSettingsRequest';
import type { PatchedOnvifSetVideoEncoderRequest } from '../models/PatchedOnvifSetVideoEncoderRequest';
import type { PatchedOnvifSetVideoSourceRequest } from '../models/PatchedOnvifSetVideoSourceRequest';
import type { PatchedOnvifUpdateRequest } from '../models/PatchedOnvifUpdateRequest';
import type { ProfileTokenRequest } from '../models/ProfileTokenRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class OnvifCamerasService {
    /**
     * List ONVIF cameras
     * @param limit Number of results to return per page.
     * @param ordering Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
     * @param page Pagination cursor value.
     * @returns PaginatedOnvifCameraList 
     * @throws ApiError
     */
    public static onvifCamerasList(
limit?: string,
ordering?: string,
page?: string,
): CancelablePromise<PaginatedOnvifCameraList> {
        return __request({
            method: 'GET',
            path: `/onvif-cameras/`,
            query: {
                'limit': limit,
                'ordering': ordering,
                'page': page,
            },
        });
    }
    /**
     * Retrieve ONVIF camera by ID
     * @param id A unique integer value identifying this Камера Onvif.
     * @returns OnvifCamera 
     * @throws ApiError
     */
    public static onvifCamerasRetrieve(
id: number,
): CancelablePromise<OnvifCamera> {
        return __request({
            method: 'GET',
            path: `/onvif-cameras/${id}/`,
        });
    }
    /**
     * Update ONVIF Camera properties
     * Use this method to update ONVIF Camera properties.
     * @param id A unique integer value identifying this Камера Onvif.
     * @param requestBody 
     * @returns OnvifCamera 
     * @throws ApiError
     */
    public static onvifCamerasPartialUpdate(
id: number,
requestBody?: PatchedOnvifUpdateRequest,
): CancelablePromise<OnvifCamera> {
        return __request({
            method: 'PATCH',
            path: `/onvif-cameras/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Auth in ONVIF device
     * Use this method to auth in ONVIF device.
     * @param id A unique integer value identifying this Камера Onvif.
     * @param requestBody 
     * @returns OnvifCamera 
     * @throws ApiError
     */
    public static onvifCamerasAuthCreate(
id: number,
requestBody: OnvifAuthRequest,
): CancelablePromise<OnvifCamera> {
        return __request({
            method: 'POST',
            path: `/onvif-cameras/${id}/auth/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get compatible video encoder configurations of ONVIF device
     * This operation lists all the video encoder configurations of ONVIF device that are compatible with a certain media profile.
     * @param id A unique integer value identifying this Камера Onvif.
     * @param profileToken ONVIF media profile token
     * @returns any No response body
     * @throws ApiError
     */
    public static onvifCamerasCompatibleVideoEncoderConfigurationsRetrieve(
id: number,
profileToken?: string,
): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/onvif-cameras/${id}/compatible-video-encoder-configurations/`,
            query: {
                'profile_token': profileToken,
            },
        });
    }
    /**
     * Get compatible video source configurations for profile of ONVIF device
     * This operation requests all the video source configurations of the ONVIF device that are compatible with a certain media profile.
     * @param id A unique integer value identifying this Камера Onvif.
     * @param profileToken ONVIF media profile token
     * @returns any No response body
     * @throws ApiError
     */
    public static onvifCamerasCompatibleVideoSourceConfigurationsRetrieve(
id: number,
profileToken?: string,
): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/onvif-cameras/${id}/compatible-video-source-configurations/`,
            query: {
                'profile_token': profileToken,
            },
        });
    }
    /**
     * Get image-settings of ONVIF device
     * This operation requests the imaging setting for a video source on the device. A device implementing the imaging service shall support this command.
     * @param id A unique integer value identifying this Камера Onvif.
     * @returns any No response body
     * @throws ApiError
     */
    public static onvifCamerasImagingSettingsRetrieve(
id: number,
): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/onvif-cameras/${id}/imaging-settings/`,
        });
    }
    /**
     * Update image-settings of ONVIF device
     * This operation sets the imaging settings for a video source on a device. A device implementing the imaging service shall support this command.
     * @param id A unique integer value identifying this Камера Onvif.
     * @param requestBody 
     * @returns any No response body
     * @throws ApiError
     */
    public static onvifCamerasImagingSettingsPartialUpdate(
id: number,
requestBody?: PatchedOnvifSetImagingSettingsRequest,
): CancelablePromise<any> {
        return __request({
            method: 'PATCH',
            path: `/onvif-cameras/${id}/imaging-settings/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get image-settings options of ONVIF device
     * @param id A unique integer value identifying this Камера Onvif.
     * @param videoSourceToken ONVIF video source configuration token
     * @returns any No response body
     * @throws ApiError
     */
    public static onvifCamerasImagingSettingsOptionsRetrieve(
id: number,
videoSourceToken?: string,
): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/onvif-cameras/${id}/imaging-settings-options/`,
            query: {
                'video_source_token': videoSourceToken,
            },
        });
    }
    /**
     * Reboot ONVIF device
     * Use this method to reboot ONVIF device.
     * @param id A unique integer value identifying this Камера Onvif.
     * @returns void 
     * @throws ApiError
     */
    public static onvifCamerasRebootCreate(
id: number,
): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/onvif-cameras/${id}/reboot/`,
        });
    }
    /**
     * Refresh ONVIF device info
     * Use this method to refresh info of ONVIF device.
     * @param id A unique integer value identifying this Камера Onvif.
     * @returns OnvifCamera 
     * @throws ApiError
     */
    public static onvifCamerasRefreshCreate(
id: number,
): CancelablePromise<OnvifCamera> {
        return __request({
            method: 'POST',
            path: `/onvif-cameras/${id}/refresh/`,
        });
    }
    /**
     * Start streaming on ONVIF device
     * Use this method to start streaming on ONVIF device.
     * @param id A unique integer value identifying this Камера Onvif.
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public static onvifCamerasStartStreamingCreate(
id: number,
requestBody: ProfileTokenRequest,
): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/onvif-cameras/${id}/start-streaming/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Stop streaming on ONVIF device
     * Use this method to stop streaming on ONVIF device.
     * @param id A unique integer value identifying this Камера Onvif.
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public static onvifCamerasStopStreamingCreate(
id: number,
requestBody: ProfileTokenRequest,
): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/onvif-cameras/${id}/stop-streaming/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get stream URI from ONVIF device
     * Use this method get stream URI from ONVIF device.
     * @param id A unique integer value identifying this Камера Onvif.
     * @param profileToken ONVIF media profile token
     * @returns any 
     * @throws ApiError
     */
    public static onvifCamerasStreamUriRetrieve(
id: number,
profileToken?: string,
): CancelablePromise<Record<string, any>> {
        return __request({
            method: 'GET',
            path: `/onvif-cameras/${id}/stream-uri/`,
            query: {
                'profile_token': profileToken,
            },
        });
    }
    /**
     * Get video encoder configurations of ONVIF device
     * This operation lists all existing video encoder configurations of ONVIF device.
     * @param id A unique integer value identifying this Камера Onvif.
     * @returns any No response body
     * @throws ApiError
     */
    public static onvifCamerasVideoEncoderConfigurationsRetrieve(
id: number,
): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/onvif-cameras/${id}/video-encoder-configurations/`,
        });
    }
    /**
     * Update video encoder configurations of ONVIF device
     * This operation modifies a video encoder configuration.
     * @param id A unique integer value identifying this Камера Onvif.
     * @param requestBody 
     * @returns any No response body
     * @throws ApiError
     */
    public static onvifCamerasVideoEncoderConfigurationsPartialUpdate(
id: number,
requestBody?: PatchedOnvifSetVideoEncoderRequest,
): CancelablePromise<any> {
        return __request({
            method: 'PATCH',
            path: `/onvif-cameras/${id}/video-encoder-configurations/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get video encoder configurations options of ONVIF device
     * This operation returns the available options when the video encoder parameters are reconfigured.
     * @param id A unique integer value identifying this Камера Onvif.
     * @param configurationToken ONVIF video encoder configuration token
     * @param profileToken ONVIF media profile token
     * @returns any No response body
     * @throws ApiError
     */
    public static onvifCamerasVideoEncoderConfigurationsOptionsRetrieve(
id: number,
configurationToken?: string,
profileToken?: string,
): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/onvif-cameras/${id}/video-encoder-configurations-options/`,
            query: {
                'configuration_token': configurationToken,
                'profile_token': profileToken,
            },
        });
    }
    /**
     * Get video source configurations of ONVIF device
     * This operation lists all existing video source configurations for ONVIF device
     * @param id A unique integer value identifying this Камера Onvif.
     * @returns any No response body
     * @throws ApiError
     */
    public static onvifCamerasVideoSourceConfigurationsRetrieve(
id: number,
): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/onvif-cameras/${id}/video-source-configurations/`,
        });
    }
    /**
     * Update video source configurations of ONVIF device
     * This operation modifies a video source configuration.
     * @param id A unique integer value identifying this Камера Onvif.
     * @param requestBody 
     * @returns any No response body
     * @throws ApiError
     */
    public static onvifCamerasVideoSourceConfigurationsPartialUpdate(
id: number,
requestBody?: PatchedOnvifSetVideoSourceRequest,
): CancelablePromise<any> {
        return __request({
            method: 'PATCH',
            path: `/onvif-cameras/${id}/video-source-configurations/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get available options for video sources configurations of ONVIF device
     * This operation returns the available options when the video source parameters are reconfigured
     * @param id A unique integer value identifying this Камера Onvif.
     * @param configurationToken ONVIF video source configuration token
     * @param profileToken ONVIF media profile token
     * @returns OnvifCamera 
     * @throws ApiError
     */
    public static onvifCamerasVideoSourceConfigurationsOptionsRetrieve(
id: number,
configurationToken?: string,
profileToken?: string,
): CancelablePromise<OnvifCamera> {
        return __request({
            method: 'GET',
            path: `/onvif-cameras/${id}/video-source-configurations-options/`,
            query: {
                'configuration_token': configurationToken,
                'profile_token': profileToken,
            },
        });
    }
}