import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-wait-video-auth" }
const _hoisted_2 = { class: "login-wait-video-auth__text heading-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NIcon, {
      class: "login-wait-video-auth__icon",
      name: "face-square",
      width: "36",
      height: "36"
    }),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('common.wait_face_verification')), 1)
  ]))
}