
import { defineComponent } from 'vue';
import { object } from 'vue-types';
import { License } from '../../../types';
import { LicensePageMeta } from '../../meta';
import { formatLicense } from './formatters/common';
import NAlertBadge from '@/uikit/alert-badge/NAlertBadge.vue';
import LicenseError from '@/pages/license/components/tab-view/components/LicenseErrors.vue';

export default defineComponent({
  name: 'LicensePageTabViewCommon',
  components: { LicenseError, LicensePageMeta },
  props: {
    license: object<License>().isRequired
  },
  computed: {
    items() {
      return formatLicense(this.license, this.$t).filter(Boolean);
    }
  }
});
