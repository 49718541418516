/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AreaTriggerActivationsFilter = {
    /**
     * Select only active (`true`) or inactive (`false`) area trigger activations
     */
    active?: boolean;
    /**
     * Select area trigger activations related to one or multiple `area`s
     */
    area?: Array<number>;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select area trigger activations with `duration` **greater than or equal** to this value
     */
    duration_gte?: number;
    /**
     * Select area trigger activations with `duration` **less than or equal** to this value
     */
    duration_lte?: number;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select area trigger activations with `max_body_count` **greater than or equal** to this value
     */
    max_body_count_gte?: number;
    /**
     * Select area trigger activations with `max_body_count` **less than or equal** to this value
     */
    max_body_count_lte?: number;
    /**
     * Which field to use when ordering the results. Available fields: `id`, `created_date`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
}
export const EmptyAreaTriggerActivationsFilter: AreaTriggerActivationsFilter = {
    active: undefined,
    area: [],
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    duration_gte: undefined,
    duration_lte: undefined,
    limit: '20',
    max_body_count_gte: undefined,
    max_body_count_lte: undefined,
    ordering: undefined,
    page: undefined,
}