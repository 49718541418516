import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-group-tile" }
const _hoisted_2 = { class: "card-group-tile__confidence" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardConfidence = _resolveComponent("CardConfidence")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CardConfidence)
    ]),
    _renderSlot(_ctx.$slots, "card")
  ]))
}