import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "interactions-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InteractionSection = _resolveComponent("InteractionSection")!
  const _component_FiltersBig = _resolveComponent("FiltersBig")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InteractionSection, {
      module: _ctx.interactionModule.clustersModule,
      "current-item": _ctx.selectedItems[0],
      onSelectCurrent: _cache[0] || (_cache[0] = (item) => _ctx.toggleCurrent(0, item)),
      onDisplayFilters: _ctx.displayFilters
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('clusters.interactions_analyzis', 'f')), 1)
      ]),
      _: 1
    }, 8, ["module", "current-item", "onDisplayFilters"]),
    (_ctx.interactionModule.clusterContactsModule1)
      ? (_openBlock(), _createBlock(_component_InteractionSection, {
          key: 0,
          module: _ctx.interactionModule.clusterContactsModule1,
          "current-item": _ctx.selectedItems[1],
          onSelectCurrent: _cache[1] || (_cache[1] = (item) => _ctx.toggleCurrent(1, item)),
          onDisplayFilters: _ctx.displayFilters1
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('clusters.circle', 'f')) + " 1", 1)
          ]),
          _: 1
        }, 8, ["module", "current-item", "onDisplayFilters"]))
      : _createCommentVNode("", true),
    (_ctx.interactionModule.clusterContactsModule2)
      ? (_openBlock(), _createBlock(_component_InteractionSection, {
          key: 1,
          module: _ctx.interactionModule.clusterContactsModule2,
          "current-item": _ctx.selectedItems[2],
          onSelectCurrent: _cache[2] || (_cache[2] = (item) => _ctx.toggleCurrent(2, item)),
          onDisplayFilters: _ctx.displayFilters2
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('clusters.circle', 'f')) + " 2", 1)
          ]),
          _: 1
        }, 8, ["module", "current-item", "onDisplayFilters"]))
      : _createCommentVNode("", true),
    (_ctx.filtersVisible)
      ? (_openBlock(), _createBlock(_component_FiltersBig, {
          key: 2,
          "header-text": _ctx.$t('clusters.interactions_analyzis', 'f'),
          "form-layout": _ctx.clusterBigFilterLayout,
          modelValue: _ctx.interactionModule.clustersModule.filter.current,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.interactionModule.clustersModule.filter.current) = $event)),
          onResetFilters: _cache[4] || (_cache[4] = ($event: any) => (_ctx.interactionModule.clustersModule.resetFilters())),
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.filtersVisible = false))
        }, null, 8, ["header-text", "form-layout", "modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.filtersVisible1)
      ? (_openBlock(), _createBlock(_component_FiltersBig, {
          key: 3,
          "header-text": _ctx.$t('clusters.circle', 'f') + ' 1',
          "form-layout": _ctx.clusterContactsBigFilterLayout,
          modelValue: _ctx.interactionModule.clusterContactsModule1.filter.current,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.interactionModule.clusterContactsModule1.filter.current) = $event)),
          onResetFilters: _cache[7] || (_cache[7] = ($event: any) => (_ctx.interactionModule.clusterContactsModule1.resetFilters())),
          onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.filtersVisible1 = false))
        }, null, 8, ["header-text", "form-layout", "modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.filtersVisible2)
      ? (_openBlock(), _createBlock(_component_FiltersBig, {
          key: 4,
          "header-text": _ctx.$t('clusters.circle', 'f') + ' 2',
          "form-layout": _ctx.clusterContactsBigFilterLayout,
          modelValue: _ctx.interactionModule.clusterContactsModule2.filter.current,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.interactionModule.clusterContactsModule2.filter.current) = $event)),
          onResetFilters: _cache[10] || (_cache[10] = ($event: any) => (_ctx.interactionModule.clusterContactsModule2.resetFilters())),
          onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.filtersVisible2 = false))
        }, null, 8, ["header-text", "form-layout", "modelValue"]))
      : _createCommentVNode("", true)
  ]))
}