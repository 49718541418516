
import { Options, Vue } from 'vue-class-component';
import PageContentLayout from '../PageContentLayout.vue';
import { getKycFilters } from '@/store/kyc/filters';
import { KycChartTypes, setFilterTodayAndTwoWeeksAgoDates } from '@/store/kyc/helpers';
import { KycItem } from '@/store/kyc/types';
import AreaChart from './components/AreaChart.vue';
import BarChart from './components/BarChart.vue';
import { Watch } from 'vue-property-decorator';
import { applicationModule } from '@/store/application';
import { KycModule } from '@/store/kyc';
import { createGraphImage, downloadGraphImageByObjectUrl } from '@/pages/kyc/components/chart-helpers';
import FilterSection from '@/components/common/filter/FilterSection.vue';

@Options({
  name: 'KycPage',
  components: { FilterSection, BarChart, AreaChart, PageContentLayout }
})
export default class KycPage extends Vue {
  public fullscreenItemType: string = '';

  protected module = KycModule.create();
  protected filterRefreshTimeoutId = 0;
  protected state = { pageType: 'kyc' };

  get theme() {
    return applicationModule.settings.theme;
  }

  get smallFilterLayout() {
    return getKycFilters({ small: true });
  }

  get bigFilterLayout() {
    return getKycFilters({ small: false });
  }

  get items() {
    return this.module.items.filter((v) => (this.fullscreenItemType ? v.type === this.fullscreenItemType : true));
  }

  @Watch('module.filter.current', { deep: true })
  reload() {
    clearTimeout(this.filterRefreshTimeoutId);
    this.filterRefreshTimeoutId = window.setTimeout(() => this.module.load());
  }

  activateItem(item: KycItem) {
    item.active = true;
    this.module.load(item.type);
  }

  async downloadChartImage(item: KycItem, itemEl: HTMLElement) {
    let objectUrl;
    try {
      objectUrl = await createGraphImage(itemEl);
      const imageName = `${item.type}_${Date.now()}.png`;
      downloadGraphImageByObjectUrl(objectUrl, imageName);
    } catch (e) {
      console.warn(e);
    } finally {
      objectUrl && URL.revokeObjectURL(objectUrl);
    }
  }

  fullscreenToggle(item: KycItem) {
    this.fullscreenItemType = this.fullscreenItemType ? '' : item.type;
  }

  getComponentNameByItem(item: KycItem) {
    return item.type === KycChartTypes.Cameras ? 'bar-chart' : 'area-chart';
  }

  initFilterDates() {
    const { created_date_gte, created_date_lte } = this.module.filter.current;
    if (!created_date_gte && !created_date_lte) {
      setFilterTodayAndTwoWeeksAgoDates(this.module.filter.current);
      setFilterTodayAndTwoWeeksAgoDates(this.module.filter.empty);
    }
  }

  created() {
    this.initFilterDates();
    this.module.load();
  }
}
