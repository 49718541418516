/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PermissionsFilter = {
    /**
     * Number of results to return.
     */
    limit?: number;
}
export const EmptyPermissionsFilter: PermissionsFilter = {
    limit: 20,
}