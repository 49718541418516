export default {
  add_new: 'Новая карточка',
  duplicate_card: 'по-видимому, карточка уже существует',
  ignore_this_warning: 'игнорировать это предупреждение',
  add_photo_to_existing_card: 'добавить в карточку',
  duplicate: 'дубль',
  unknown: 'Неизвестен',
  autocards: 'Автокарточки',
  face_objects: 'Лица',
  face_clusters: 'Кластеры лиц',
  face_cluster_events: 'События кластеров лиц',
  body_objects: 'Силуэты',
  body_clusters: 'Кластеры силуэтов',
  body_cluster_events: 'События кластеров силуэтов',
  car_objects: 'Транспортные средства',
  car_clusters: 'Кластеры ТС',
  car_cluster_events: 'События кластеров ТС',
  connections: 'Связи',
  location: 'Местоположения',
  info: 'Сведения',
  human_participants: 'Происшествия',
  license_plate: 'Номер',
  case_human_cards: 'Связанные карточки',
  upload_photo: 'Загрузить фото',
  select_files: 'выберите файлы',
  select_folder: 'выберите папку',
  or: 'или',
  no_objects: 'не найден объект на фотографии',
  success: 'успешно',
  detection_error: 'ошибка обнаружения',
  in_queue: 'в очереди',
  prefix_name: 'Префикс имени',
  suffix_name: 'Постфикс имени',
  filename_as_name: 'Использовать имя файла',
  prefix_comment: 'префикс комментария',
  suffix_comment: 'постфикс комментария',
  photo_group: 'Групповое фото',
  parallel_upload: 'Параллельная загрузка',
  selected: 'Выбрано',
  uploaded: 'Загружено',
  errors: 'Ошибок',
  reject: 'Отклонить',
  biggest: 'Сохранить больший объект',
  all: 'Все',
  batch_cards_upload__after_try: 'пакетную загрузку карточек',
  do_you_have_many_cards: 'Добавляете много карточек? Попробуйте',
  upload: 'загрузить',
  start: 'начать',
  pause: 'пауза',
  continue: 'продолжить',
  name: 'название карточки',
  active: 'карточка активна',
  create_card_from_event: 'Создать карточку из события',
  create_new_card: 'Создать новую карточку',
  similar_cards: 'Похожие карточки'
};
