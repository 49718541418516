import {
  isLicensePageMetaItemCustomContent,
  isLicensePageMetaItemIndicatorContent,
  isLicensePageMetaItemMonospacedContent,
  isLicensePageMetaItemRegularContent,
  LicensePageMetaItemContent
} from '../types';
import LicensePageMetaContentCustom from './LicensePageMetaContentCustom.vue';
import LicensePageMetaContentIndicator from './LicensePageMetaContentIndicator.vue';
import LicensePageMetaContentMonospaced from './LicensePageMetaContentMonospaced.vue';
import LicensePageMetaContentRegular from './LicensePageMetaContentRegular.vue';
import { LicensePageMetaContentView } from './types';

export function selectLicensePageMetaContentView(content: LicensePageMetaItemContent): LicensePageMetaContentView {
  if (isLicensePageMetaItemCustomContent(content)) {
    return LicensePageMetaContentCustom;
  }

  if (isLicensePageMetaItemIndicatorContent(content)) {
    return LicensePageMetaContentIndicator;
  }

  if (isLicensePageMetaItemMonospacedContent(content)) {
    return LicensePageMetaContentMonospaced;
  }

  if (isLicensePageMetaItemRegularContent(content)) {
    return LicensePageMetaContentRegular;
  }

  throw new Error('Unsupported license page content.');
}
