
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export enum NCounterSize {
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL'
}

export enum NCounterType {
  Primary = 'primary',
  Secondary = 'secondary'
}

@Options({
  name: 'NCounter'
})
export default class NCounter extends Vue {
  @Prop({ type: [Number, String], required: true })
  value!: number | string;

  @Prop({ type: String, default: NCounterSize.M })
  size?: NCounterSize;

  @Prop({ type: String, default: NCounterType.Primary })
  type?: NCounterType;

  get classes() {
    const root = 'n-counter';
    return {
      [root]: true,
      [`label-mono-l ${root}__size_xl`]: this.size === NCounterSize.XL,
      [`label-mono-m ${root}__size_l`]: this.size === NCounterSize.L,
      [`label-mono-s ${root}__size_m`]: this.size === NCounterSize.M,
      [`label-mono-xs ${root}__size_s`]: this.size === NCounterSize.S,
      [`${root}__type_primary`]: this.type === NCounterType.Primary,
      [`${root}__type_secondary`]: this.type === NCounterType.Secondary
    };
  }
}
