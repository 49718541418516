/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ClustersBodiesContactsTracksFilter = {
            /**
            * Select clusters where `age_group` feature takes one of these values
            */
        age_group?: Array<'0-16' | '17-35' | '36-50' | '50+' | null>;
            /**
            * Select clusters where `gender` feature takes one of these values
            */
        body_gender?: Array<'female' | 'male' | null>;
            /**
            * Select clusters where `bottom_color` feature takes one of these values
            */
        bottom_color?: Array<'beige' | 'black' | 'blue' | 'brown' | 'green' | 'grey' | 'lightblue' | 'orange' | 'pink' | 'purple' | 'red' | 'violet' | 'white' | 'yellow' | null>;
            /**
            * Select clusters associated with the specified camera groups
            */
        camera_groups?: Array<number>;
            /**
            * Select clusters associated with the specified cameras
            */
        cameras?: Array<number>;
            /**
            * Select clusters that matched card
            */
        card?: number;
            /**
            * Select clusters related to these cases
            */
        case_in?: Array<number | null>;
            /**
            * Select objects with `created_date` **greater** than this value
            */
        created_date_gt?: string;
            /**
            * Select objects with `created_date` **greater than or equal** to this value
            */
        created_date_gte?: string;
            /**
            * Select objects with `created_date` in last N days
            */
        created_date_last_n_days?: number;
            /**
            * Select objects with `created_date` **less** than this value
            */
        created_date_lt?: string;
            /**
            * Select objects with `created_date` **less than or equal** to this value
            */
        created_date_lte?: string;
            /**
            * Select objects with `created_date` in last Nth week (including Sunday and Saturday)
            */
        created_date_nth_full_week?: number;
            /**
            * Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
            */
        created_date_nth_work_week?: number;
            /**
            * Select clusters where `detailed_upper_clothes` feature takes one of these values
            */
        detailed_upper_clothes?: Array<'coat' | 'dress' | 'jacket' | 'shirt' | 'sleeveless' | 'sweatshirt' | 't-shirt' | null>;
            /**
            * Select objects related to `episode` Pass comma separated values to select related to multiple `episode`s)
            */
        episodes?: Array<number>;
            /**
            * Select objects with `any` event date **greater** than this value
            */
        event_date_gt?: string;
            /**
            * Select objects with `any` event date **greater than or equal** to this value
            */
        event_date_gte?: string;
            /**
            * Select objects with `any` event date **less** than this value
            */
        event_date_lt?: string;
            /**
            * Select objects with `any` event date **less than or equal** to this value
            */
        event_date_lte?: string;
            /**
            * Select clusters where cluster events count is **greater or equal than or equal** to this value
            */
        events_count_gte?: number;
            /**
            * Select clusters where cluster events count is **less or equal than or equal** to this value
            */
        events_count_lte?: number;
            /**
            * Select clusters where average cluster event quality is **greater or equal than or equal** to this value
            */
        events_quality_gte?: number;
            /**
            * Select clusters where average cluster event quality is **less or equal than or equal** to this value
            */
        events_quality_lte?: number;
            /**
            * Select objects with `first` event date **greater** than this value
            */
        first_event_date_gt?: string;
            /**
            * Select objects with `first` event date **greater than or equal** to this value
            */
        first_event_date_gte?: string;
            /**
            * Select objects with `first` event date **less** than this value
            */
        first_event_date_lt?: string;
            /**
            * Select objects with `first` event date **less than or equal** to this value
            */
        first_event_date_lte?: string;
            /**
            * `true` - select only clusters with a card. `false` - select only clusters without a card.
            */
        has_card?: boolean;
            /**
            * `true` - select only clusters relates to case. `false` - select only clusters without case.
            */
        has_case?: boolean;
            /**
            * Select clusters where `headwear` feature takes one of these values
            */
        headwear?: Array<'hat' | 'hood' | 'none' | null>;
            /**
            * Select clusters where `helmet_type` feature takes one of these values
            */
        helmet_type?: Array<'none' | 'not_visible' | 'other' | 'red/orange' | 'white' | null>;
            /**
            * Select clusters with provided ids
            */
        id_in?: Array<number>;
            /**
            * Parameter that determines track intersection threshold in seconds.
            */
        intersection_threshold?: number;
        limit: number;
            /**
            * Select clusters that contain object similar to: (`detection:<detection id>`, `{face, body or car}event:<event id>`, `{face, body or car}object:<object id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`)
            */
        looks_like?: string;
            /**
            * Select clusters where `lower_clothes` feature takes one of these values
            */
        lower_clothes?: Array<'obscured' | 'pants' | 'shorts' | 'skirt' | null>;
            /**
            * Select clusters that matched a card included in these watch lists
            */
        matched_lists?: Array<number>;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`, `looks_like_confidence`
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
            /**
            * Select only pinned clusters
            */
        pinned?: boolean;
            /**
            * Similarity threshold for `looks_like`
            */
        threshold?: number;
            /**
            * Select clusters with provided tnt ids
            */
        tnt_id_in?: Array<number | null>;
            /**
            * Select clusters where `top_color` feature takes one of these values
            */
        top_color?: Array<'beige' | 'black' | 'blue' | 'brown' | 'green' | 'grey' | 'lightblue' | 'orange' | 'pink' | 'purple' | 'red' | 'violet' | 'white' | 'yellow' | null>;
            /**
            * Select clusters where `upper_clothes` feature takes one of these values
            */
        upper_clothes?: Array<'long_sleeves' | 'short_sleeves' | 'without_sleeves' | null>;
            /**
            * Select clusters where `vest_type` feature takes one of these values
            */
        vest_type?: Array<'green/yellow' | 'none' | 'not_visible' | 'orange' | null>;
}
export const EmptyClustersBodiesContactsTracksFilter: ClustersBodiesContactsTracksFilter = {
        age_group: [],
        body_gender: [],
        bottom_color: [],
        camera_groups: [],
        cameras: [],
        card: undefined,
        case_in: [],
        created_date_gt: undefined,
        created_date_gte: undefined,
        created_date_last_n_days: undefined,
        created_date_lt: undefined,
        created_date_lte: undefined,
        created_date_nth_full_week: undefined,
        created_date_nth_work_week: undefined,
        detailed_upper_clothes: [],
        episodes: [],
        event_date_gt: undefined,
        event_date_gte: undefined,
        event_date_lt: undefined,
        event_date_lte: undefined,
        events_count_gte: undefined,
        events_count_lte: undefined,
        events_quality_gte: undefined,
        events_quality_lte: undefined,
        first_event_date_gt: undefined,
        first_event_date_gte: undefined,
        first_event_date_lt: undefined,
        first_event_date_lte: undefined,
        has_card: undefined,
        has_case: undefined,
        headwear: [],
        helmet_type: [],
        id_in: [],
        intersection_threshold: undefined,
        limit: 20,
        looks_like: undefined,
        lower_clothes: [],
        matched_lists: [],
        ordering: undefined,
        page: undefined,
        pinned: undefined,
        threshold: undefined,
        tnt_id_in: [],
        top_color: [],
        upper_clothes: [],
        vest_type: [],
}