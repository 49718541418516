/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EventsBodiesFilter = {
    /**
     * `true` - select only acknowledged events (do not require acknowledgement), `false` - select only unacknowledged events
     */
    acknowledged?: boolean;
    /**
     * Select events where `features.age_group.name` takes one of these values
     */
    age_group?: Array<'0-16' | '17-35' | '36-50' | '50+'>;
    /**
     * Select events where `features.age_group.confidence` is **greater than or equal** to this value
     */
    age_group_score_gte?: number;
    /**
     * Select events where `features.age_group.confidence` is **less than or equal** to this value
     */
    age_group_score_lte?: number;
    /**
     * `true` - select only events with backward intersected line. `false` - select only events intersected line in one direction.
     */
    backward_line_crossing?: boolean;
    /**
     * Select events where `features.bag_back.name` takes one of these values
     */
    bag_back?: Array<'back' | 'none'>;
    /**
     * Select events where `features.bag_back.confidence` is **greater than or equal** to this value
     */
    bag_back_score_gte?: number;
    /**
     * Select events where `features.bag_back.confidence` is **less than or equal** to this value
     */
    bag_back_score_lte?: number;
    /**
     * Select events where `features.bag_ground.name` takes one of these values
     */
    bag_ground?: Array<'ground' | 'none'>;
    /**
     * Select events where `features.bag_ground.confidence` is **greater than or equal** to this value
     */
    bag_ground_score_gte?: number;
    /**
     * Select events where `features.bag_ground.confidence` is **less than or equal** to this value
     */
    bag_ground_score_lte?: number;
    /**
     * Select events where `features.bag_hand.name` takes one of these values
     */
    bag_hand?: Array<'hand' | 'none'>;
    /**
     * Select events where `features.bag_hand.confidence` is **greater than or equal** to this value
     */
    bag_hand_score_gte?: number;
    /**
     * Select events where `features.bag_hand.confidence` is **less than or equal** to this value
     */
    bag_hand_score_lte?: number;
    /**
     * Select events where `features.gender.name` takes one of these values
     */
    body_gender?: Array<'female' | 'male'>;
    /**
     * Select events where `features.top_color.name` takes one of these values
     */
    bottom_color?: Array<'beige' | 'black' | 'blue' | 'brown' | 'green' | 'grey' | 'lightblue' | 'orange' | 'pink' | 'purple' | 'red' | 'violet' | 'white' | 'yellow'>;
    /**
     * Select events where `features.bottom_color.confidence` is **greater than or equal** to this value
     */
    bottom_color_score_gte?: number;
    /**
     * Select events where `features.bottom_color.confidence` is **less than or equal** to this value
     */
    bottom_color_score_lte?: number;
    /**
     * Select objects where `bs_type` takes one of these values
     */
    bs_type?: 'overall' | 'realtime';
    /**
     * Get events from these `camera groups`
     */
    camera_groups?: Array<number>;
    /**
     * Get events from these `cameras`
     */
    cameras?: Array<number>;
    /**
     * Select events related to these `cases`
     */
    case_in?: Array<number>;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select events where `features.detailed_upper_clothes.name` takes one of these values
     */
    detailed_upper_clothes?: Array<'coat' | 'dress' | 'jacket' | 'shirt' | 'sleeveless' | 'sweatshirt' | 't-shirt'>;
    /**
     * Select events where `features.detailed_upper_clothes.confidence` is **greater than or equal** to this value
     */
    detailed_upper_clothes_score_gte?: number;
    /**
     * Select events where `features.detailed_upper_clothes.confidence` is **less than or equal** to this value
     */
    detailed_upper_clothes_score_lte?: number;
    /**
     * Select events that matched this episode
     */
    episode?: number;
    /**
     * Select events that created by external detectors
     */
    external_detector?: boolean;
    /**
     * Select events where `features.gender.confidence` is **greater than or equal** to this value
     */
    gender_score_gte?: number;
    /**
     * Select events where `features.gender.confidence` is **less than or equal** to this value
     */
    gender_score_lte?: number;
    /**
     * `true` - select only events relates to case. `false` - select only events without case.
     */
    has_case?: boolean;
    /**
     * Select events where `features.headwear.name` takes one of these values
     */
    headwear?: Array<'hat' | 'hood' | 'none'>;
    /**
     * Select events where `features.headwear.confidence` is **greater than or equal** to this value
     */
    headwear_score_gte?: number;
    /**
     * Select events where `features.headwear.confidence` is **less than or equal** to this value
     */
    headwear_score_lte?: number;
    /**
     * Select events where `features.helmet_type.name` takes one of these values
     */
    helmet_type?: Array<'none' | 'not_visible' | 'other' | 'red/orange' | 'white'>;
    /**
     * Select events where `features.helmet_type.confidence` is **greater than or equal** to this value
     */
    helmet_type_score_gte?: number;
    /**
     * Select events where `features.helmet_type.confidence` is **less than or equal** to this value
     */
    helmet_type_score_lte?: number;
    /**
     * Exact filter by ID
     */
    id?: number;
    /**
     * Select objects with `id` **greater than or equal** to this value
     */
    id_gte?: number;
    /**
     * Select objects with provided ids
     */
    id_in?: Array<string>;
    /**
     * Select objects with `id` **less than or equal** to this value
     */
    id_lte?: number;
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * Select events that intersect line in this direction
     */
    line_crossing_direction?: Array<'AB' | 'BA'>;
    /**
     * Select events that intersects with this lines
     */
    line?: Array<number>;
    /**
     * Select events with faces similar to: (`detection:<detection id>`, `{face, body or car}object:<object id>`, `{face, body or car}event:<event id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`). You can pass several search sources to search for. Pagination works if there is only one face to search for and if the sorting order is id. In all other cases, only the first page of results is returned
     */
    looks_like?: string;
    /**
     * Select events where `features.lower_clothes.name` takes one of these values
     */
    lower_clothes?: Array<'obscured' | 'pants' | 'shorts' | 'skirt'>;
    /**
     * Select events where `features.lower_clothes.confidence` is **greater than or equal** to this value
     */
    lower_clothes_score_gte?: number;
    /**
     * Select events where `features.lower_clothes.confidence` is **less than or equal** to this value
     */
    lower_clothes_score_lte?: number;
    /**
     * Select events that matched this card
     */
    matched_card?: number;
    /**
     * Select events that match a card included in these watch lists
     */
    matched_lists?: Array<number>;
    /**
     * `true` - select only events without matches. `false` - select events only with matches.
     */
    no_match?: boolean;
    /**
     * Which field to use for ordering. Available values: id, -id. Available values for looks_like: -confidence, -looks_like_confidence, id, -id, confidence and looks_like_confidence ordering are equivalents
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Similarity threshold for `looks_like`
     */
    threshold?: number;
    /**
     * Select events where `features.top_color.name` takes one of these values
     */
    top_color?: Array<'beige' | 'black' | 'blue' | 'brown' | 'green' | 'grey' | 'lightblue' | 'orange' | 'pink' | 'purple' | 'red' | 'violet' | 'white' | 'yellow'>;
    /**
     * Select events where `features.top_color.confidence` is **greater than or equal** to this value
     */
    top_color_score_gte?: number;
    /**
     * Select events where `features.top_color.confidence` is **less than or equal** to this value
     */
    top_color_score_lte?: number;
    /**
     * Select events where `features.upper_clothes.name` takes one of these values
     */
    upper_clothes?: Array<'long_sleeves' | 'short_sleeves' | 'without_sleeves'>;
    /**
     * Select events where `features.upper_clothes.confidence` is **greater than or equal** to this value
     */
    upper_clothes_score_gte?: number;
    /**
     * Select events where `features.upper_clothes.confidence` is **less than or equal** to this value
     */
    upper_clothes_score_lte?: number;
    /**
     * Select events where `features.vest_type.name` takes one of these values
     */
    vest_type?: Array<'green/yellow' | 'none' | 'not_visible' | 'orange'>;
    /**
     * Select events where `features.vest_type.confidence` is **greater than or equal** to this value
     */
    vest_type_score_gte?: number;
    /**
     * Select events where `features.vest_type.confidence` is **less than or equal** to this value
     */
    vest_type_score_lte?: number;
    /**
     * Get events from these video archives
     */
    video_archive?: Array<number>;
}
export const EmptyEventsBodiesFilter: EventsBodiesFilter = {
    acknowledged: undefined,
    age_group: [],
    age_group_score_gte: undefined,
    age_group_score_lte: undefined,
    backward_line_crossing: undefined,
    bag_back: [],
    bag_back_score_gte: undefined,
    bag_back_score_lte: undefined,
    bag_ground: [],
    bag_ground_score_gte: undefined,
    bag_ground_score_lte: undefined,
    bag_hand: [],
    bag_hand_score_gte: undefined,
    bag_hand_score_lte: undefined,
    body_gender: [],
    bottom_color: [],
    bottom_color_score_gte: undefined,
    bottom_color_score_lte: undefined,
    bs_type: undefined,
    camera_groups: [],
    cameras: [],
    case_in: [],
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    detailed_upper_clothes: [],
    detailed_upper_clothes_score_gte: undefined,
    detailed_upper_clothes_score_lte: undefined,
    episode: undefined,
    external_detector: undefined,
    gender_score_gte: undefined,
    gender_score_lte: undefined,
    has_case: undefined,
    headwear: [],
    headwear_score_gte: undefined,
    headwear_score_lte: undefined,
    helmet_type: [],
    helmet_type_score_gte: undefined,
    helmet_type_score_lte: undefined,
    id: undefined,
    id_gte: undefined,
    id_in: [],
    id_lte: undefined,
    limit: 20,
    line_crossing_direction: undefined,
    line: [],
    looks_like: undefined,
    lower_clothes: [],
    lower_clothes_score_gte: undefined,
    lower_clothes_score_lte: undefined,
    matched_card: undefined,
    matched_lists: [],
    no_match: undefined,
    ordering: undefined,
    page: undefined,
    threshold: undefined,
    top_color: [],
    top_color_score_gte: undefined,
    top_color_score_lte: undefined,
    upper_clothes: [],
    upper_clothes_score_gte: undefined,
    upper_clothes_score_lte: undefined,
    vest_type: [],
    vest_type_score_gte: undefined,
    vest_type_score_lte: undefined,
    video_archive: [],
}