import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["selectable-item", {selected: _ctx.selected}])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_NButton, {
      class: "selectable-item__select",
      type: _ctx.buttonType,
      icon: _ctx.buttonIcon,
      round: "",
      onClick: _withModifiers(_ctx.toggleSelect, ["stop"])
    }, null, 8, ["type", "icon", "onClick"])
  ], 2))
}