
import { Options } from 'vue-class-component';
import CardItem from '@/components/cards/CardItem';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import CardDate from '@/components/cards/CardDate.vue';
import CardAttachments from '@/components/cards/CardAttachments.vue';
import CardMetafields from '@/components/cards/CardMetafields.vue';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import { Action } from '@/uikit';
import { actionsModule } from '@/store/data/ActionsModule';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { hasParentElementDataName } from '@/common/utils';
import WatchListsGroup from '@/components/common/WatchListsGroup.vue';

const CommentMaxLength = 1500;

@Options({
  name: 'CardItemRow',
  components: { WatchListsGroup, ActionsDropdown, CardMetafields, CardAttachments, CardDate, NThumbnail }
})
export default class CardItemRow extends CardItem {
  get cardActions(): Action[] {
    return actionsModule
      .getItemActions(this.modelAcl, null, {
        hasSearch: true,
        hasCardFilterEvent: true,
        hasActive: true,
        hasDelete: true,
        currentActive: this.item.active
      })
      .map(actionsModule.computeActionByName);
  }

  get formattedName() {
    return this.$filters.shortString(this.name, 125);
  }

  get formattedComment() {
    return this.$filters.shortString(this.comment, CommentMaxLength);
  }

  get classes() {
    return {
      'card-item-row': true,
      'card-item-row__selected': this.selected,
      'card-item-row__opened': this.opened
    };
  }

  actionHandler(v: ItemsActionName) {
    actionHandler.run(v, { type: `cards_${this.type}`, rawItem: this.item });
  }

  clickHandler(e: Event) {
    let isActionClick = hasParentElementDataName(e.target as HTMLElement, 'actions');
    if (!isActionClick) this.$emit('select');
  }
}
