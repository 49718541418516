
import NIcon from '../icons/NIcon.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ISelectItem } from './types';

@Options({
  name: 'NSelectItems',
  components: { NIcon }
})
export default class NSelectItems extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly items!: ISelectItem[];

  @Prop({ type: Boolean, default: false })
  readonly multiple!: false;

  @Prop({ type: String })
  readonly dataQa?: string;

  @Prop({ type: Boolean, default: false })
  readonly capitalizeLabels!: boolean;

  getClasses(item: ISelectItem) {
    return {
      'n-select-items__capitalize': this.capitalizeLabels,
      'n-select-items__item_disabled': item.disabled
    };
  }

  handleClick(item: ISelectItem) {
    if (item.disabled) return;

    if (this.multiple) {
      item.checked = !item.checked;
    }
    this.$emit('select', item);
  }
}
