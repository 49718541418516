
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import { toHHMMSS, formatFileSize } from '@/common/filters';
// @ts-ignore
import { saveAs } from 'file-saver';
import { request as __request } from '@/api/core/request';
import axios from 'axios';

@Options({
  name: 'VideoPlayerExport',
  components: { NButton }
})
export default class VideoPlayerExport extends Vue {
  @Prop({ type: Number, required: true })
  readonly cameraId!: number;

  @Prop({ type: Number, required: true })
  readonly timeFrom!: number;

  @Prop({ type: Number, required: true })
  readonly timeTo!: number;

  isDownloading = false;
  bytesCompleted = 0;
  cancelTokenSource: any;

  get formatedTimeFrom() {
    return toHHMMSS(this.timeFrom);
  }

  get formatedTimeTo() {
    return toHHMMSS(this.timeTo);
  }

  get formatedTotalTime() {
    return toHHMMSS(this.timeTo - this.timeFrom, true, false);
  }

  get loadedBytesFormated() {
    return formatFileSize(this.bytesCompleted);
  }

  async download() {
    // Use direct download when the backend is ready
    const timeFrom = new Date(this.timeFrom * 1000);
    const timeTo = new Date(this.timeTo * 1000);
    try {
      this.isDownloading = true;
      this.bytesCompleted = 0;
      this.cancelTokenSource = axios.CancelToken.source();
      const data = await __request({
        method: 'GET',
        path: '/vms/download',
        headers: { accept: 'video/x-matroska' },
        responseType: 'arraybuffer',
        query: {
          camera: this.cameraId,
          time_from: timeFrom.toISOString(),
          time_to: timeTo.toISOString()
        },
        onDownloadProgress: (e) => {
          this.bytesCompleted = e.loaded;
        },
        cancelToken: this.cancelTokenSource.token
      });
      saveAs(new Blob([data as ArrayBuffer]), `${timeFrom.toISOString()}.mkv`);
    } catch (e) {
      // if (e.toString().startsWith('Cancel')) {
      //   this.$notify({ type: 'success', message: this.$tf('export_canceled') });
      // } else {
      //   this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      // }
    } finally {
      this.isDownloading = false;
    }
  }
  cancelDownload() {
    if (this.cancelTokenSource && this.cancelTokenSource.cancel) {
      this.cancelTokenSource.cancel();
    }
  }
  cancelPanel() {
    this.$emit('cancel');
  }
  beforeUnmount() {
    this.cancelDownload();
  }
}
