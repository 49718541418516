
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NInput from '@/uikit/input/NInput.vue';
import { number } from 'vue-types';

@Options({
  name: 'InputIds',
  components: { NInput }
})
export default class InputIds extends Vue {
  @Prop({ type: Array, required: true })
  readonly modelValue!: number[];

  get inputValue() {
    if (this.modelValue instanceof Array) {
      return this.modelValue.join(', ');
    }
    return '';
  }

  set inputValue(value: string) {
    const ids = [...new Set(value.split(/[\s,;]+/).filter((item) => item.trim().match(/^\d+$/)))];
    this.$emit(
      'update:modelValue',
      ids.map((id) => Number(id))
    );
  }
}
