
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'NTextWithLabel'
})
export default class NTextWithLabel extends Vue {
  @Prop({ type: String, default: '' })
  readonly label!: string;

  @Prop({ type: String, default: '' })
  readonly classes!: string;

  @Prop({ type: String, default: '' })
  readonly value!: string;

  @Prop({ type: String, default: '' })
  readonly suffixLabel!: string;
}
