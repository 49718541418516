/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ExternalVmsFilter = {
            /**
            * Select objects with `created_date` **greater** than this value
            */
        created_date_gt?: string;
            /**
            * Select objects with `created_date` **greater than or equal** to this value
            */
        created_date_gte?: string;
            /**
            * Select objects with `created_date` **less** than this value
            */
        created_date_lt?: string;
            /**
            * Select objects with `created_date` **less than or equal** to this value
            */
        created_date_lte?: string;
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
            /**
            * Select external-vms entries that matched this `plugin_vms_id`
            */
        plugin_vms_id?: number;
            /**
            * Select external-vms entries with this `version`
            */
        version?: string;
            /**
            * Select external-vms entries with this `vms`
            */
        vms?: string;
}
export const EmptyExternalVmsFilter: ExternalVmsFilter = {
        created_date_gt: undefined,
        created_date_gte: undefined,
        created_date_lt: undefined,
        created_date_lte: undefined,
        limit: '20',
        ordering: undefined,
        page: undefined,
        plugin_vms_id: undefined,
        version: undefined,
        vms: undefined,
}