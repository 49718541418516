import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTable = _resolveComponent("NTable")!

  return (_openBlock(), _createBlock(_component_NTable, {
    class: "reports-page-table",
    columns: _ctx.columns,
    content: _ctx.items,
    "selected-items-ids": _ctx.selectedIds,
    onSort: _ctx.dispatchSortEvent
  }, null, 8, ["columns", "content", "selected-items-ids", "onSort"]))
}