const commonFilters = {
  card: '', //
  matched_lists: [30],
  acknowledged: 'False',
  camera_groups: [1],
  cameras: [10],
  // start
  // end
  video_archive: 1,
  episode: 1
};

const commonEventsFitlers = {
  ...commonFilters,
  no_match: 'True',
  id: 1
};

const commonFacesFilter = {
  gender: ['female'],
  age_gte: 2,
  age_lte: 6,
  emotions: ['fear', 'sad', 'happy'],
  glasses: ['sun'],
  beard: ['beard'],
  liveness: ['real'],
  medmask: ['incorrect']
};

const commonBodiesFilter = {
  top_color: ['white', 'black', 'grey'],
  bottom_color: ['green'],
  detailed_upper_clothes: ['sleeveless'],
  headwear: ['hood'],
  lower_clothes: ['obscured'],
  upper_clothes: ['short_sleeves']
};

const commonCarsFilter = {
  color: ['black'],
  body: ['crossover'],
  make: ['am general'],
  license_plate_number: ['dasdas'],
  license_plate_country: ['BY'],
  license_plate_region: 'FUJ', // check
  special_vehicle_type: ['rescue_service']
};

const facesEventsFilters = {
  ...commonEventsFitlers,
  bs_type: 'overall',
  ...commonFacesFilter
};
const bodiesEventsFilters = {
  ...commonEventsFitlers,
  ...commonBodiesFilter
};
const carsEventsFilters = {
  ...commonEventsFitlers,
  ...commonCarsFilter
};
const humanEpisodesFilters = {
  ...commonFilters,
  no_face_match: 'True',
  no_body_match: 'True',
  events_count_gte: 1,
  ...commonFacesFilter,
  ...commonBodiesFilter
};
const carsEpisodesFilters = {
  ...commonFilters,
  events_count_gte: 1,
  ...commonCarsFilter
};

export function getMockFilterByType(pageType: string, objectType: string, episodeType: string) {
  let filterSchema = {};
  if (pageType === 'events') {
    switch (objectType) {
      case 'faces':
        filterSchema = facesEventsFilters;
        break;
      case 'bodies':
        filterSchema = bodiesEventsFilters;
        break;
      case 'cars':
        filterSchema = carsEventsFilters;
        break;
      // eslint-disable-next-line no-empty
      default: {
      }
    }
  }
  if (pageType === 'episodes') {
    switch (episodeType) {
      case 'humans':
        filterSchema = humanEpisodesFilters;
        break;
      case 'cars':
        filterSchema = carsEpisodesFilters;
        break;
      // eslint-disable-next-line no-empty
      default: {
      }
    }
  }
  return filterSchema;
}
