
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';

@Options({
  name: 'NTextDelimiter'
})
export default class NTextDelimiter extends Vue {
  @Prop({ type: String, required: true })
  readonly label!: string;

  @Prop({ type: Boolean, default: false })
  readonly applicable!: boolean;
}
