
import { Options, Vue } from 'vue-class-component';
import SidebarMenu from './SidebarMenu.vue';
import { applicationModule } from '@/store/application';
import { getSettingsItems } from '@/store/menu';

@Options({
  name: 'SettingsMenu',
  components: { SidebarMenu }
})
export default class SettingsMenu extends Vue {
  get applicationModule() {
    return applicationModule;
  }

  get menuItems() {
    return getSettingsItems().map((item) => {
      return {
        title: this.$t(item.i18n, 'f'),
        ...item
      };
    });
  }
}
