/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedUserFaceUpdateList } from '../models/PaginatedUserFaceUpdateList';
import type { PatchedUserFaceUpdateRequest } from '../models/PatchedUserFaceUpdateRequest';
import type { UserFaceRequest } from '../models/UserFaceRequest';
import type { UserFaceUpdate } from '../models/UserFaceUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class UserFaceService {
    /**
     * List user-faces
     * This method allows to get list of user-face objects.
     * **User-face** is an object that contains source photo, thumbnail, etc
     * @param active Select only active objects (`true`) or inactive (`false`)
     * @param idIn Select objects with provided ids
     * @param limit Number of results to return per page.
     * @param ordering Which field to use when ordering the results. Available fields: `id`
     * @param page Pagination cursor value.
     * @param user Select user faces for these `users`
     * @returns PaginatedUserFaceUpdateList
     * @throws ApiError
     */
    public static userFaceList(
        active?: boolean,
        idIn?: Array<string>,
        limit?: number,
        ordering?: string,
        page?: string,
        user?: Array<number>,
    ): CancelablePromise<PaginatedUserFaceUpdateList> {
        return __request({
            method: 'GET',
            path: `/user-face/`,
            query: {
                'active': active,
                'id_in': idIn,
                'limit': limit,
                'ordering': ordering,
                'page': page,
                'user': user,
            },
        });
    }
    /**
     * Add a user-face
     * This method allows to create a user-face object.
     * **User-face** is an object that contains source photo, thumbnail, etc
     * @param formData
     * @returns UserFaceUpdate
     * @throws ApiError
     */
    public static userFaceCreate(
        formData: UserFaceRequest,
    ): CancelablePromise<UserFaceUpdate> {
        return __request({
            method: 'POST',
            path: `/user-face/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Retrieve user-face by ID
     * This method allows to get a user-face object by id.
     * **User-face** is an object that contains source photo, thumbnail, etc
     * @param id
     * @returns UserFaceUpdate
     * @throws ApiError
     */
    public static userFaceRetrieve(
        id: string,
    ): CancelablePromise<UserFaceUpdate> {
        return __request({
            method: 'GET',
            path: `/user-face/${id}/`,
        });
    }
    /**
     * Update user-face properties
     * @param id
     * @param formData
     * @returns UserFaceUpdate
     * @throws ApiError
     */
    public static userFacePartialUpdate(
        id: string,
        formData?: PatchedUserFaceUpdateRequest,
    ): CancelablePromise<UserFaceUpdate> {
        return __request({
            method: 'PATCH',
            path: `/user-face/${id}/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Delete user-face
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static userFaceDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/user-face/${id}/`,
        });
    }
}