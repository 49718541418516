import { MenuItem, MenuItemMeta } from '@/store/menu/menu';
import { aclModule } from '@/store/acl';
import { configAclModule } from '@/store/config/acl';
import { configModule } from '@/store/config';
import { additionalItems, launcherItems } from '@/store/menu/launcher.items';
import { settingsItems } from '@/store/menu/settings.items';
import { RouterModule } from '@/store/router';
import { PagePath, PagePaths } from '@/store/application/page.definitions';
import router from '@/router';
import { workspaceModule } from '@/store/application/workspace';
import get from 'lodash/get';
import { licenseModule } from '@/store/config/LicenseModule';

export function fillQuery(menuItem: MenuItem) {
  const settingsLocation = RouterModule.getRouteLocation({ path: PagePaths.Settings, tab: router.currentRoute?.value?.query.tab });
  if (typeof settingsLocation !== 'string') {
    settingsLocation.query && (menuItem.query = settingsLocation.query);
  }
  return menuItem;
}

function fillServerPath(menuItem: MenuItem) {
  const serverUrl = configModule.config.server?.url ?? '/';
  if (menuItem.serverPath && !menuItem.serverPath.startsWith(serverUrl)) {
    menuItem.serverPath = serverUrl + menuItem.serverPath;
  }
  return menuItem;
}

function enabledByConfigPath(menuItem: MenuItem) {
  const configPath = menuItem?.meta?.configPath;
  if (configPath) {
    const url = (configModule.config as any)[configPath];
    return !!url;
  }
  return true;
}

function enabledByLicensePath(menuItem: MenuItem) {
  const path = menuItem?.meta?.licensePath;
  if (path) {
    const enabled: boolean = get(licenseModule.item, path, false);
    return enabled;
  }
  return true;
}


export function canShowMenuItem(menuItem: MenuItem): boolean {
  const { meta, name, enabled } = menuItem;
  return (
    aclModule.getAccess(meta.permissions, 'or') &&
    configAclModule.hasPlugins(meta.plugins) &&
    configAclModule.hasServices(meta.services) &&
    !configAclModule.isDisabledMenuItem(name) &&
    enabledByConfigPath(menuItem) &&
    enabledByLicensePath(menuItem) &&
    computeEnabled(enabled)
  );
}

function computeEnabled(enabled?: boolean | (() => boolean)) {
  if (enabled === undefined) return true;
  return typeof enabled === 'function' ? enabled() : enabled;
}

export function getItems(includeAdditional = false, includeSettings = false) {
  const menuItems = [...launcherItems];
  includeSettings && menuItems.push(...settingsItems);
  includeAdditional && menuItems.push(...additionalItems);
  return menuItems.filter(canShowMenuItem).map(fillServerPath);
}

export function getSettingsItems() {
  return settingsItems.filter(canShowMenuItem).map(fillServerPath).map(fillQuery);
}

export function formatMenuItems($t: any, items: MenuItem[]) {
  return items.map((item: MenuItem) => {
    return {
      ...item,
      title: $t(item.i18n),
      description: item.i18n_description && $t(item.i18n_description)
    };
  });
}

export function launchMenuItem(item: MenuItem) {
  const configPath = item?.meta?.configPath;
  if (configPath) {
    const url = (configModule.config as any)[configPath];
    if (url) {
      window.open(url);
      return;
    }
  }
  if (item.path) {
    workspaceModule.addItem(item.path);
    return;
  }
  console.warn('Empty menu item url!');
}
