/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ClusterEventsCarsFilter = {
    /**
     * Select objects where feature `body` takes one of these values
     */
    body?: Array<string>;
    /**
     * Select objects related to this `camera group`
     */
    camera_groups?: Array<number>;
    /**
     * Select objects related to this `camera`.
     */
    cameras?: Array<number>;
    /**
     * Select objects where the `cluster` id takes one of these values
     */
    cluster_in?: Array<number>;
    /**
     * Select objects where feature `color` takes one of these values
     */
    color?: Array<string>;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select objects related to this `episode`
     */
    episodes?: Array<number>;
    /**
     * `true` - select only cluster events with matches. `false` - select only cluster events without matches.
     */
    has_matches?: number;
    /**
     * Select cluster events with provided ids
     */
    id_in?: Array<number>;
    /**
     * Select objects where feature `license_plate_country` takes one of these values
     */
    license_plate_country?: Array<string>;
    /**
     * Select objects where feature `license_plate_number` contains this substring (case insensitive)
     */
    license_plate_number?: Array<string>;
    /**
     * Select objects where feature `license_plate_region` takes one of these values
     */
    license_plate_region?: Array<string>;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select objects where feature `make` takes one of these values
     */
    make?: Array<string>;
    /**
     * Select objects that match this card
     */
    matched_card?: number;
    /**
     * Select objects that match a card included in these watch lists
     */
    matched_lists?: Array<number>;
    /**
     * Select objects where feature `model` takes one of these values
     */
    model?: Array<string>;
    /**
     * Which field to use when ordering the results. Available fields: `id`, `created_date`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select objects where feature `special_vehicle_type` takes one of these values
     */
    special_vehicle_type?: Array<string>;
}
export const EmptyClusterEventsCarsFilter: ClusterEventsCarsFilter = {
    body: [],
    camera_groups: [],
    cameras: [],
    cluster_in: [],
    color: [],
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    episodes: [],
    has_matches: undefined,
    id_in: [],
    license_plate_country: [],
    license_plate_number: [],
    license_plate_region: [],
    limit: '20',
    make: [],
    matched_card: undefined,
    matched_lists: [],
    model: [],
    ordering: undefined,
    page: undefined,
    special_vehicle_type: [],
}