/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CameraGroupPermissions = {
    readonly id: number;
    /**
     * Short group name, up to 256 characters
     */
    readonly name: string;
    /**
     * Permissions on this camera group
     */
    permissions?: Record<string, string>;
};
export const EmptyCameraGroupPermissions: CameraGroupPermissions = {
    id: 0,
        /**
        * Short group name, up to 256 characters
        */
    name: '',
        /**
        * Permissions on this camera group
        */
    permissions: {},
};