/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CaseParticipantAttachment = {
    readonly id: number;
    /**
     * Attachment name, up to 256 characters
     */
    name?: string;
    /**
     * Extended description, up to 2048 characters
     */
    comment?: string;
    file: string;
    /**
     * File size
     */
    readonly size: number;
    /**
     * Object creation date
     */
    readonly created_date: string;
    case_participant: number;
};
export const EmptyCaseParticipantAttachment: CaseParticipantAttachment = {
    id: 0,
    /**
     * Attachment name, up to 256 characters
     */
    name: '',
    /**
     * Extended description, up to 2048 characters
     */
    comment: '',
    file: '',
    /**
     * File size
     */
    size: 0,
    /**
     * Object creation date
     */
    created_date: '',
    case_participant: 0,
};
