import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "license-page-tab-view-intervals-list-item__dates" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicensePageTabViewIntervalsListItemIndicator = _resolveComponent("LicensePageTabViewIntervalsListItemIndicator")!
  const _component_LicensePageTabViewIntervalsListItemDate = _resolveComponent("LicensePageTabViewIntervalsListItemDate")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dispatchClickEvent && _ctx.dispatchClickEvent(...args)), ["stop"]))
  }, [
    _createVNode(_component_LicensePageTabViewIntervalsListItemIndicator, { spending: _ctx.spending }, null, 8, ["spending"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.since)
        ? (_openBlock(), _createBlock(_component_LicensePageTabViewIntervalsListItemDate, {
            key: 0,
            date: _ctx.since
          }, null, 8, ["date"]))
        : _createCommentVNode("", true),
      (_ctx.till)
        ? (_openBlock(), _createBlock(_component_LicensePageTabViewIntervalsListItemDate, {
            key: 1,
            date: _ctx.till
          }, null, 8, ["date"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}