
import { Options, Vue } from 'vue-class-component';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import NThemeImage from '@/uikit/image/NThemeImage.vue';

@Options({
  name: 'PageNotFound',
  components: { NThemeImage, PageContentLayout }
})
export default class PageNotFound extends Vue {}
