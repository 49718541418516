import CardOrUnmatched from '@/pages/external-search/CardOrUnmatched.vue';
import ExternalSearchRequestActions from '@/pages/external-search/requests/ExternalSearchRequestActions.vue';
import ExternalSearchRequestInfo from '@/pages/external-search/requests/ExternalSearchRequestInfo.vue';
import NText from '@/uikit/text/NText.vue';
import { ITableColumn } from '@/uikit/table/NTable.vue';

export const externalSearchRequestsTableSchema: ITableColumn[] = [
  {
    header: {},
    content: {
      component: ExternalSearchRequestInfo,
      props: (item: any) => ({ item: item }),
      on: {
        action: function (this: any, action: string) {
          const item = this.item;
          this.tableCell.$emit('action', { item, action });
        }
      }
    }
  },
  {
    header: {},
    content: {
      component: CardOrUnmatched,
      props: (item: any) => ({ item: item.cardObject, deleted: item.is_deleted }),
      on: {
        'display-card-image'(this: any, src: string) {
          this.tableCell.$emit('action', { item: this.item, action: 'fullframe', src });
        }
      }
    }
  },
  {
    header: {},
    content: {
      path: 'reason',
      component: NText,
      props: {
        class: 'label-xs'
      }
    }
  },
  {
    header: {},
    content: {
      component: ExternalSearchRequestActions,
      props: (item: any) => ({ item: item }),
      on: {
        action: function (this: any, action: string) {
          const item = this.item;
          this.tableCell.$emit('action', { item, action });
        }
      }
    }
  }
];
