
import { nextTick, reactive, unref } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

import { ComputePositionConfig } from '@floating-ui/core';
import { arrow, computePosition, autoUpdate, flip, offset, shift, size, Placement, autoPlacement } from '@floating-ui/dom';

export type TooltipMode = 'manual' | 'hover';

@Options({
  name: 'NTooltip'
})
export default class NTooltip extends Vue {
  @Prop({ type: Object, required: true })
  readonly reference!: HTMLElement;

  @Prop({ type: String, default: 'top' })
  readonly placement!: Placement;

  @Prop({ type: Boolean, default: false })
  readonly autoPlacement: boolean = false;

  @Prop({ type: Array })
  readonly allowedAutoPlacement?: string[];

  @Prop({ type: String, default: 'manual' })
  readonly mode!: TooltipMode;

  protected wrapStyles: any = {};
  protected arrowStyles: any = {};
  protected cleanup: (() => void) | null = null;
  protected hovered = false;
  protected zIndex = 1000;

  protected get shown() {
    return this.mode === 'manual' ? true : this.hovered;
  }

  protected async updatePosition() {
    if (!this.reference) {
      console.warn('reference is undefined!', this.reference);
      return;
    }

    const options: Partial<ComputePositionConfig> = {
      middleware: [offset(8), arrow({ element: unref(this.$refs.arrow), padding: 10 })]
    };

    if (this.autoPlacement) {
      const autoPlacementMiddleware = autoPlacement({ allowedPlacements: this.allowedAutoPlacement as Placement[] });
      options.middleware!.unshift(autoPlacementMiddleware);
    } else {
      options.placement = this.placement as Placement;
    }

    const { x, y, placement, middlewareData } = await computePosition(unref(this.reference), unref(this.$refs.floating), options);

    this.wrapStyles = {
      left: `${x}px`,
      top: `${y}px`,
      zIndex: this.zIndex
    };
    const arrowData = middlewareData.arrow;
    if (arrowData) {
      this.arrowStyles = {
        top: arrowData.y != null ? `${arrowData.y}px` : '',
        left: arrowData.x != null ? `${arrowData.x}px` : '',
        right: '',
        bottom: ''
      };
      switch (placement.split('-')[0]) {
        case 'top':
          this.arrowStyles.bottom = '-4px';
          break;
        case 'left':
          this.arrowStyles.right = '-4px';
          break;
        case 'right':
          this.arrowStyles.left = '-4px';
          break;
        case 'bottom':
          this.arrowStyles.top = '-4px';
          break;
      }
    }
  }

  protected initAutoUpdate() {
    this.clearAutoUpdate();
    this.cleanup = autoUpdate(unref(this.reference), unref(this.$refs.floating), () => {
      this.shown && this.updatePosition();
    });
  }

  protected clearAutoUpdate() {
    if (this.cleanup) {
      this.cleanup();
    }
  }

  @Watch('reference')
  protected referenceWatcher() {
    nextTick(() => {
      this.initAutoUpdate();
    });
  }

  protected updateZIndex() {
    // TODO: Warning. When changing this function pay attention to NModalWindow:updateZIndex()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.zIndex = window.__modal_window_z_index || 1000 /* + 1 */;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__modal_window_z_index = this.zIndex;
  }

  protected hoveredOn() {
    this.hovered = true;
  }

  protected hoveredOff() {
    this.hovered = false;
  }

  initHoverMode() {
    if (this.reference) {
      this.reference.addEventListener('mouseenter', this.hoveredOn);
      this.reference.addEventListener('mouseleave', this.hoveredOff);
      this.reference.addEventListener('focusin', this.hoveredOn);
      this.reference.addEventListener('focusout', this.hoveredOff);
    } else {
      console.warn('[tooltip]: reference element undefined');
    }
  }

  revokeHoverMode() {
    if (this.reference) {
      this.reference.removeEventListener('mouseenter', this.hoveredOn);
      this.reference.removeEventListener('mouseleave', this.hoveredOff);
      this.reference.removeEventListener('focusin', this.hoveredOn);
      this.reference.removeEventListener('focusout', this.hoveredOff);
    }
  }

  mounted() {
    this.initAutoUpdate();
    this.updateZIndex();
    this.mode === 'hover' && this.initHoverMode();
  }

  beforeUnmount() {
    this.clearAutoUpdate();
    this.mode === 'hover' && this.revokeHoverMode();
  }
}
