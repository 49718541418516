
import { computed, defineComponent, PropType } from 'vue';
import { DateTimeFormats } from '../datetime/types';
import dayjs from './dayjs';

export const NDateTimeLabelSize = {
  xl: 'label-mono-xl',
  l: 'label-mono-l',
  m: 'label-mono-m',
  s: 'label-mono-s',
  xs: 'label-mono-xs',
  '2xs': 'label-mono-2xs'
};

export const NDateTimeLabelMode = {
  time: 'time',
  date: 'date',
  datetime: 'datetime'
};

export default defineComponent({
  name: 'NDateTimeLabel',
  props: {
    mode: { type: String as PropType<keyof typeof NDateTimeLabelMode>, default: NDateTimeLabelMode.datetime },
    modelValue: { type: [Number, String, Date], default: '' },
    multiline: { type: Boolean, default: false },
    dateFormat: { type: String, default: DateTimeFormats.date },
    timeFormat: { type: String, default: DateTimeFormats.timeFull },
    size: { type: String as PropType<keyof typeof NDateTimeLabelSize>, default: 'xs' }
  },
  setup(props) {
    const cssClass = computed(() => ({
      'n-date-time-label': true,
      [NDateTimeLabelSize[props.size]]: true,
      'n-date-time-label_multiline': props.multiline
    }));

    const date = computed(() => {
      let date = dayjs(props.modelValue);
      if (date.isValid()) return date;
      if (props.modelValue !== '' || props.modelValue !== null) {
        console.warn(`[[DateTimeLabel:InvalidFormat]]: Can't parse datetime from "${props.modelValue}" input!`);
      }
      return false;
    });

    const dateLabel = computed(() => (date.value ? date.value.format(props.dateFormat) : ''));
    const timeLabel = computed(() => (date.value ? date.value.format(props.timeFormat) : ''));
    const hasDate = computed(() => props.mode === NDateTimeLabelMode.date || props.mode === NDateTimeLabelMode.datetime);
    const hasTime = computed(() => props.mode === NDateTimeLabelMode.time || props.mode === NDateTimeLabelMode.datetime);

    return {
      hasDate,
      hasTime,
      dateLabel,
      timeLabel,
      cssClass
    };
  }
});
