
import { defineComponent } from 'vue';
import { string } from 'vue-types';
import { LicensePageMetaItemMonospacedContent } from '../types';
import LicensePageMetaContentRegular from './LicensePageMetaContentRegular.vue';

export default defineComponent({
  name: 'LicensePageMetaContentMonospaced',
  components: { LicensePageMetaContentRegular },
  props: {
    content: string<LicensePageMetaItemMonospacedContent['content']>().isRequired
  }
});
