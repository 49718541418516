
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { BodyObjectRequest, CardsService, CarObjectRequest, FaceCluster, FaceObjectRequest, ObjectsService } from '@/api';
import ConfidenceLabel from '@/components/common/ConfidenceLabel.vue';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import { ClusterView } from '@/components/clusters/adapter';
import EpisodeEventsPage from '@/pages/events/EpisodeEventsPage.vue';
import { ItemViewModel } from '@/definitions/view-models';
import { CardTypesMap, ObjectsMultiToSingle, ObjectsTypesMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import ClusterSidebarContent from '@/pages/clusters/ClustersSidebarContent.vue';
import Statistics from '@/components/common/Statistics.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { ModelAclResult } from '@/store/acl/types';
import { multisidebarModule } from '@/store/multisidebar';
import { ObjectType } from '@/components/detection/types';
import { RouterModule } from '@/store/router';
import NConfirm from '@/uikit/modal-window/NConfirm.vue';

@Options({
  name: 'ClustersSidebar',
  components: {
    NConfirm,
    PageContentLayout,
    MultisidebarHeader,
    EpisodeEventsPage,
    ConfidenceLabel,
    ClusterSidebarContent,
    Statistics,
    SortDropdown
  }
})
export default class ClustersSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<any>>;

  @Prop({ type: Object })
  readonly modelAcl!: ModelAclResult;

  private showMergeConfirm = false;
  private mergePayload = {};

  get headerLabel() {
    return this.$t(this.currentClusterItem?.card ? 'common.matched_cluster' : 'common.unmatched_cluster', 'f');
  }

  get currentItem(): FaceCluster | null {
    return this.module.item;
  }

  get module(): ItemViewModel<any> {
    return this.sidebarItem.model;
  }

  get currentClusterItem(): ClusterView | null {
    return this.currentItem ?? null;
  }

  get objectType() {
    return this.sidebarItem.type.split('_')[1];
  }

  get cardType() {
    return dataAssetsModule.getCardTypeByObjectType(this.objectType);
  }

  get actions() {
    return actionsModule
      .getItemActions(this.modelAcl, null, {
        hasSearch: this.objectType !== ObjectsTypesMap.Cars,
        /* hasCreateCard: true, @todo: FFSEC-3403, Not implemented by backend */
        hasClusterMerge: this.openedClustersCount > 1 && this.objectType !== ObjectsTypesMap.Cars,
        hasInteractions: this.objectType === ObjectsTypesMap.Faces,
        hasDelete: true
      })
      .map(actionsModule.computeActionByName);
  }

  get openedClustersCount() {
    return multisidebarModule.getItemsByType(this.sidebarItem.type).length;
  }

  actionHandler(action: ItemsActionName, payload: any) {
    if (action === ItemsActionNames.ClusterMergeAll) {
      this.mergePayload = payload;
      this.showMergeConfirm = true;
    } else if (action === ItemsActionNames.CreateCard) {
      this.createCardFromClusterEvent(payload);
    } else if (action === ItemsActionNames.ShowInteractions) {
      this.showInteractions(payload);
    } else {
      actionHandler.run(action, payload);
    }
  }

  async showInteractions(item: MultisidebarItem<ItemViewModel<FaceCluster>>) {
    RouterModule.navigateToInteractions({ id_in: item.model.item!.id });
  }

  async mergeClusters(item: MultisidebarItem<ItemViewModel<FaceCluster>>) {
    const items = multisidebarModule.getItemsByType(item.type);
    const mergedItems = items.filter((v) => v.id !== item.id);
    const mergedItemIds = mergedItems.map((v) => v.model.item.id);
    if (mergedItemIds.length) {
      await item.model.dataService.createItemSomethingByAction(item.model.item!.id, 'merge', null, { clusters: mergedItemIds });
      mergedItems.forEach((v) => multisidebarModule.fakeDeleteItem(v));
      this.$refs.content?.load();
    } else {
      console.warn('[cluster.merge] Nothing to merge');
    }
  }

  async createCardFromClusterEvent(item: MultisidebarItem<ItemViewModel<FaceCluster>>) {
    const objectsType = item.type.split('_')[1];
    const type = dataAssetsModule.getCardTypeByObjectType(objectsType);
    const clusterEvent = item.model.item!.best_event as any;
    console.log('Create item ', item, clusterEvent);

    try {
      const card = await this.createCard(clusterEvent, type);
      const detectionObjectType = ObjectsMultiToSingle[objectsType];
      const request = {
        create_from: `${detectionObjectType}clusterevent:${clusterEvent.id}`,
        card: card!.id
      };
      await this.addObjectToCard(request, detectionObjectType);
      await actionHandler.run(ItemsActionNames.ShowItem, { type: `cards_${type}`, rawItem: card?.id });
    } catch (e) {
      throw new Error(e);
    }
  }

  async addObjectToCard(form: FaceObjectRequest | BodyObjectRequest | CarObjectRequest, detectionObjectType: string) {
    switch (detectionObjectType) {
      case ObjectType.Face:
        return ObjectsService.objectsFacesCreate(form);
      case ObjectType.Body:
        return ObjectsService.objectsBodiesCreate(form);
      case ObjectType.Car:
        return ObjectsService.objectsCarsCreate(form);
    }
  }

  async createCard(eventItem: any, type: string) {
    const cleanMatchedLists = eventItem.matched_lists.filter((i: number) => i > 0);
    const requestBody = {
      name: eventItem.id,
      watch_lists: cleanMatchedLists.length > 0 ? cleanMatchedLists : [1]
    };
    switch (type) {
      case CardTypesMap.Humans:
        return CardsService.cardsHumansCreate(requestBody);
      case CardTypesMap.Cars:
        this.extractPlaceNumber(eventItem) && Object.assign(requestBody, { license_plate_number: this.extractPlaceNumber(eventItem) });
        return CardsService.cardsCarsCreate(requestBody);
    }
  }

  extractPlaceNumber(eventItem: any) {
    return eventItem.features.license_plate_number?.name;
  }

  async mergeConfirmHandler(res: any) {
    await this.mergeClusters(this.mergePayload as MultisidebarItem<ItemViewModel<FaceCluster>>);
    this.mergePayload = {};
    this.showMergeConfirm = res;
  }

  mergeCancelHandler(res: any) {
    this.mergePayload = {};
    this.showMergeConfirm = res;
  }
}
