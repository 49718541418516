
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import { getParticipantDataModule } from '@/pages/cases/data';
import { CarClusterEvent, CaseParticipant, FaceClusterEvent } from '@/api';
import { aclModule } from '@/store/acl';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import ParticipantItem from '@/components/participants/ParticipantItem.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import enrichParticipants, { EnrichedParticipant } from '@/components/participants/enrich-participants';
import { PagePaths, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import SelectableItem from '@/components/common/SelectableItem.vue';
import Statistics from '@/components/common/Statistics.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import { getRandomId } from '@/store/router';
import { ListViewModel } from '@/definitions/view-models';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { ModelAclResult } from '@/store/acl/types';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { getClusterFiltersBuilder } from '@/pages/clusters/forms/ClusterFiltersBuilder';
import { getParticipantFilters } from '@/components/participants/forms/ParticipantFilters';
import { getEventFiltersBuilder } from '@/pages/events/forms/EventFiltersBuilder';

@Options({
  name: 'CaseParticipantsPage',
  components: {
    SelectableItem,
    InfiniteScroll,
    ParticipantItem,
    FilterSection,
    PageContentLayout,
    Statistics,
    SortDropdown
  }
})
export default class CaseParticipantsPage extends Vue {
  @Prop({ type: Object, required: true })
  readonly item: any;

  @Prop({ required: false })
  externalAcl?: ModelAclResult;

  hasSidebar = false;
  items: EnrichedParticipant[] = [];

  get participantDataModule() {
    return getParticipantDataModule();
  }

  get module() {
    return this.participantDataModule.participantsModel as ListViewModel<any, any>;
  }

  get thumbnailsMap() {
    let map: Record<number, string> = {};
    this.items.forEach((richItem) => {
      if (richItem.faceClusterObject) {
        map[richItem.id] = (richItem.faceClusterObject.best_event as unknown as FaceClusterEvent).thumbnail;
      } else if (richItem.carClusterObject) {
        map[richItem.id] = (richItem.carClusterObject.best_event as unknown as CarClusterEvent).thumbnail;
      }
    });
    return map;
  }

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  // TODO: refactor this, replace state to filter schema
  get state(): PageState {
    const s = pageModule.getPageStateByTab(PagePaths.Participants, 'participant_' + getRandomId());
    return {
      ...s,
      objectType: 'faces',
      episodeType: ''
    };
  }

  get bigFilterLayout() {
    const options = { small: false };
    return getParticipantFilters(options);
  }

  get smallFilterLayout() {
    const options = { small: true };
    return getParticipantFilters(options);
  }

  get selectedItems() {
    return [];
    // return this.module.selectedItems.map((v) => v.item);
  }

  get currentItem() {
    return {}; // todo: replace on MSB
    // return this.module.currentItem?.item;
  }

  get currentItemClusterEvent() {
    return this.participantDataModule.clusterEvents;
  }

  get currentItemRelatedParticipants() {
    return this.participantDataModule.relatedParticipants;
  }

  get currentItemRelatedCards() {
    return this.participantDataModule.relatedCards;
  }

  get form() {
    return this.$refs.sidebar?.$refs.itemForm;
  }

  get modelAcl() {
    const currentModelAcl = aclModule.getModelAcl(this.module);
    return this.externalAcl ? aclModule.mergeAcl(currentModelAcl, this.externalAcl) : currentModelAcl;
  }

  get itemAcl() {
    const maxAcl = { view: this.modelAcl.view, update: this.modelAcl.update, delete: this.modelAcl.delete };
    return aclModule.getItemAclByUserGroupMap(this.item.permissions, maxAcl);
  }

  @Watch('module.items')
  async moduleItemsWatcher(items: CaseParticipant[]) {
    this.items = await enrichParticipants(items, this.items);
  }

  @Watch('currentItem.id', { immediate: true })
  loadCurrentItemData(v: number) {
    if (!v) return;
    this.participantDataModule.loadClusterEvents();
    this.participantDataModule.loadRelatedCards();
    this.participantDataModule.loadRelatedParticipants();
  }

  // @Watch('selectedItems')
  // selectedItemsHandler(v: any[]) {
  //   if (!v.length) this.hasSidebar = false;
  // }

  @Watch('module.filter.current', { deep: true })
  filterWatcher() {
    this.module.get();
  }

  created() {
    this.participantDataModule.init(this.item.id);
    const { subPage, additionalId } = JSON.parse(this.$route.query?.pageState as string);
    if (subPage) {
      // this.module.setCurrentItem(additionalId);
      this.$nextTick(() => {
        this.hasSidebar = true;
      });
    }
  }

  getIsSelectedItem(item: number) {
    // todo: wait MSB
    return true;
  }

  toggleSelectedItem(item: number) {
    // todo: wait MSB
    return true;
  }

  async refreshCurrent() {
    // if (this.currentItem) {
    // await this.module.currentItem?.get(this.currentItem.id);
    // this.loadCurrentItemData(this.currentItem.id);
    // }
  }

  getIsItemFormValid(): boolean {
    const result = this.form ? this.form.validate() : true;
    if (!result) {
      this.form.displayErrors();
    }
    return result;
  }

  setCurrentAndShow(v: CaseParticipant) {
    this.setCurrent(v);
    // this.hasSidebar = this.module.hasSelectedItems;
  }

  setCurrent(v: CaseParticipant) {
    // this.module.setCurrentItem(v.id);
  }

  async handleAction(actionName: string) {
    switch (actionName) {
      case ItemsActionNames.SelectAll:
        // this.module.selectAllItems();
        break;

      case ItemsActionNames.DeselectAll:
        // this.module.deselectAllItems();
        this.hasSidebar = false;
        break;

      case ItemsActionNames.Save:
        if (!this.getIsItemFormValid()) return;
        // await this.module.saveCurrentItem();
        await this.module.get({ resetState: false });
        break;

      case ItemsActionNames.SaveAll:
        if (!this.getIsItemFormValid()) return;
        // await this.module.saveAllSelectedItems();
        await this.module.get({ resetState: false });
        break;

      case ItemsActionNames.ToggleCurrent:
        this.hasSidebar = !this.hasSidebar;
        // if (this.hasSidebar && !this.currentItem) this.module.setCurrentItem(this.module.selectedItems[0].item?.id);
        break;

      case ItemsActionNames.DeleteAll:
        // await this.module.deleteAllSelectedItems();
        await this.module.get({ resetState: false });
        break;

      case ItemsActionNames.Delete:
        // await this.module.deleteCurrentItem();
        await this.module.get({ resetState: false });
        break;

      case ItemsActionNames.Reset:
        // await this.module.resetCurrentItem();
        break;

      case ItemsActionNames.Deselect:
        // this.module.deselectCurrentItem();
        break;

      case ItemsActionNames.Close:
        // await this.module.deleteCurrentItem();
        break;
    }
  }
}
