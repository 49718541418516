import attentionSoundUrl from '@/assets/sounds/attention.mp3';
import { reactive } from 'vue';
import notify from '@/uikit/notification/helpers/notification';
import { h } from '@vue/runtime-core';
import AlertNotification from '@/components/notification-header/AlertNotification.vue';

const attentionSound = new Audio(attentionSoundUrl);

if (attentionSound) {
  attentionSound.volume = 1;
}

export class AlertModule {
  isPlaying: boolean = false;
  isMuting: boolean = true;

  play() {
    this.isPlaying = true;
    const alert = attentionSound.play();
    if (alert !== undefined) {
      alert
        .then(() => {
          attentionSound.loop = true;
          this.isMuting = false;
        })
        .catch((e) => {
          this.isMuting = true;
          return notify({ content: () => h(AlertNotification), showClose: true, duration: 1e4 });
        });
    } else {
      this.isMuting = true;
    }
  }

  mute() {
    this.isMuting = true;
    attentionSound.pause();
  }

  stop() {
    this.isPlaying = false;
    this.mute();
  }

  toggleMute() {
    this.isMuting = !this.isMuting;
    if (this.isMuting) {
      this.mute();
    } else {
      this.play();
    }
  }
}

export const alertModule = reactive(new AlertModule());
