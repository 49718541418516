import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "n-input-dropdown__content label-mono-m" }
const _hoisted_2 = ["name", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "n-input-dropdown" }, {
    default: _withCtx(() => [
      (_ctx.items.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.cssClass)
          }, [
            _createElementVNode("ul", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(["n-input-dropdown__item", { 'n-input-dropdown__item_checked': item.value == _ctx.currentValue }]),
                  key: item.label,
                  name: item.value,
                  onClick: _withModifiers(($event: any) => (_ctx.clickHandler(item.value)), ["stop"]),
                  onKeyup: [
                    _cache[0] || (_cache[0] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.keyupEnterHandler && _ctx.keyupEnterHandler(...args)), ["stop"]), ["enter"])),
                    _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.keyupUpHandler && _ctx.keyupUpHandler(...args)), ["up"])),
                    _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.keyupDownHandler && _ctx.keyupDownHandler(...args)), ["down"])),
                    _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.keyupRightHandler && _ctx.keyupRightHandler(...args)), ["right"])),
                    _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.keyupLeftHandler && _ctx.keyupLeftHandler(...args)), ["left"]))
                  ],
                  tabindex: "0",
                  ref_for: true,
                  ref: "dropdown"
                }, _toDisplayString(item.label), 43, _hoisted_2))
              }), 128))
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}