
import { Case } from '@/api';
import Permissions from '@/components/permissions/deprecated/Permissions.vue';
import { ListViewModel } from '@/definitions/view-models';
import { columns } from '@/pages/cases/case-table-schema';
import EditCasePage from '@/pages/cases/EditCasePage.vue';
import EmptyCasePage from '@/pages/cases/EmptyCasePage.vue';
import ListPage from '@/pages/ListPage.vue';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import { aclModule } from '@/store/acl';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { PagePaths, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { dataModule } from '@/store/data';
import { RouterModule } from '@/store/router';
import { NLoadingCircle } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCell } from '@/uikit/table/NTableCell.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import { EmptyCase } from '@/api/models/Case';

@Options({
  components: {
    EditCasePage,
    EmptyCasePage,
    ListPage,
    NButton,
    NButtonGroup,
    NForm,
    NInput,
    NLoadingCircle,
    NTable,
    PageContentLayout,
    Permissions
  }
})
export default class CasesPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;
  selectedItemIds: number[] = [];
  newItem: Case | null = null;

  get columns() {
    return columns;
  }

  get state(): PageState {
    const tab = this.tab;
    const state = pageModule.getPageStateByTab(PagePaths.Cases, tab);
    return state;
  }

  get dataAssetsModule() {
    return dataAssetsModule;
  }

  get module() {
    const state = this.state;
    const module = pageModule.getPageModule(state) as unknown as ListViewModel<any, any>;
    module.aclModelName = 'case';
    return module;
  }

  get selectedItems() {
    return this.module.items.filter((v) => this.selectedItemIds.includes(v.id));
  }

  get showNewCase() {
    return this.module.loaded && !this.module.items.length && !this.module.filter.current?.status && !this.module.filter.current?.name_contains;
  }

  get statusFilterItems() {
    const items = [
      { name: undefined, i18n_label: 'cases.filter_all' },
      { name: 'open', i18n_label: 'cases.filter_open' },
      { name: 'archived', i18n_label: 'cases.filter_archived' }
    ];
    return items.map((v) => ({ name: v.name, label: this.$t(v.i18n_label, 'f') }));
  }

  get modelAcl() {
    return aclModule.getModelAcl(this.module);
  }

  @Watch('module.filter.current.status')
  statusHandler() {
    this.module.get();
  }

  @Watch('module.filter.current.name_contains')
  searchHandler() {
    this.module.get();
  }

  mounted() {
    this.module.get();
  }

  addNew() {
    this.newItem = cloneDeep(EmptyCase);
    this.state.showOverlay = true;
  }

  async saveNew() {
    if (this.newItem && dataModule.currentUserModule.item?.id) {
      this.newItem.author = dataModule.currentUserModule.item.id;
    }
    await this.module.saveItem(this.newItem);
    await this.module.get({ resetState: false });
    this.clearNewItem();
  }

  clearNewItem() {
    this.newItem = null;
    this.state.showOverlay = false;
  }

  cellClickHandler(row: Case, cell: ITableCell) {
    if (cell.path === 'name') RouterModule.navigateToCase(row.id);
    else if (cell.path === 'author') RouterModule.navigateToUser(row.author);
  }

  async updateStatusOfSelectedItems(v: 'archived' | 'open') {
    for (const item of this.selectedItems) {
      await this.module.saveItem({ id: item.id, status: v });
    }
  }

  async openAll() {
    await this.updateStatusOfSelectedItems('open');
    await this.module.get({ resetState: false });
  }

  async archiveAll() {
    await this.updateStatusOfSelectedItems('archived');
    await this.module.get({ resetState: false });
  }

  async deleteAll() {
    for (let item of this.selectedItems) {
      await this.module.delete(item.id);
    }
    await this.module.get({ resetState: false });
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      this.module.append();
    }
  }

  handleTableSort(columnName: string) {
    if (columnName === 'name') {
      this.module.filter.current.ordering = this.module.filter.current.ordering === columnName ? `-${columnName}` : columnName;
      this.module.get();
    }
  }
}
