export default {
  all: 'All',
  current: 'Current',
  buy_or_update_license: 'Buy or update license',
  common: 'Common',
  disabled: 'DISABLED',
  download_c2v: 'Download C2V',
  enabled: 'Enabled',
  generated: 'Generated',
  intervals: 'Intervals',
  last_updated: 'Last updated',
  left: 'left',
  license_id: 'License ID',
  limits: 'Limits',
  no: 'No',
  second_ago_pf: 'seconds ago',
  services: 'Services',
  source: 'File',
  type: 'Type of license',
  upload_new_license_file: 'Upload new license file',
  valid: 'Valid',
  yes: 'Yes'
};
