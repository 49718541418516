
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'NTableProgressCell'
})
export default class NTableProgressCell extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly floatValue = 0; // 0 - 1

  @Prop({ type: String, default: '' })
  readonly label = '';

  get percentValue() {
    return Math.round(this.floatValue * 100);
  }
}
