export default {
  body: 'Bodies',
  car: 'Vehicles',
  eventId: 'Event ID',
  face: 'Faces',
  no_faces_on_photo: 'No faces in the photo',
  or: 'or',
  result: 'Result',
  select_file: 'Select file',
  source: 'Source {index}',
  submit: 'Verify',
  compare_error: 'Unable to compare objects of different types',
  matched_score: 'Match probability',
  first_source: 'First source',
  second_source: 'Second source',
  upload: 'Upload',
  load: 'Load',
  width: 'Width',
  height: 'Height',
  quality: 'Quality',
  first_source_placeholder: 'Enter the event URL for the first source',
  second_source_placeholder: 'Enter the event URL for the second source',
  dnd_placeholder: 'Drag and drop one or two files to compare',
  detect_config_error: 'Add the `detect_config` block to the UI configuration to recognize object attributes.'
};
