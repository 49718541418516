import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-time-picker"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!

  return (_ctx.items.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_component_NDropdownItem, {
            mono: "",
            onClick: ($event: any) => (_ctx.selectHandler(item)),
            key: item,
            command: item
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item), 1)
            ]),
            _: 2
          }, 1032, ["onClick", "command"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}