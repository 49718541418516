import { reactive } from 'vue';

export class DataModalModule {
  visible = false;
  data: any;

  show(data: unknown) {
    this.data = data;
    this.visible = true;
    console.log('Show ', data);
  }
}

export const dataModalController = reactive(new DataModalModule());
