import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "kyc-chart-layout" }
const _hoisted_2 = { class: "kyc-chart-layout__wrapper" }
const _hoisted_3 = { class: "kyc-chart-header" }
const _hoisted_4 = { class: "kyc-chart-header__title" }
const _hoisted_5 = {
  key: 0,
  class: "kyc-chart-actions"
}
const _hoisted_6 = {
  key: 0,
  class: "kyc-chart-actions__paging"
}
const _hoisted_7 = {
  key: 1,
  class: "kyc-chart-layout__placeholder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "title")
        ]),
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.$slots.paging)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _renderSlot(_ctx.$slots, "paging")
                  ]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "actions")
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.active)
        ? _renderSlot(_ctx.$slots, "subheader", { key: 0 })
        : _createCommentVNode("", true)
    ]),
    (_ctx.active && !_ctx.loading)
      ? _renderSlot(_ctx.$slots, "graph", { key: 0 })
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_NLoadingCircle, { key: 0 }))
            : _renderSlot(_ctx.$slots, "placeholder", { key: 1 })
        ]))
  ]))
}