
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NDropdown from '@/uikit/dropdown/NDropdown.vue';
import NDropdownItem from '@/uikit/dropdown/NDropdownItem.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { Action } from '@/uikit';

@Options({
  name: 'ActionsDropdown',
  components: {
    NButton,
    NDropdownItem,
    NDropdown
  },
  emits: ['action']
})
export default class ActionsDropdown extends Vue {
  @Prop({ type: Array, required: true })
  readonly actions!: Action[];

  actionsCount = 0;

  private emitAction(action: string) {
    this.$emit('action', action);
    this.actionsCount++;
  }

  private getActionName(action: any) {
    return (action.i18n_label && this.$t(action.i18n_label, 'f')) || (this.$t(action.label, 'f') && action.label);
  }
}
