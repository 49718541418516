/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ClustersCarsContactsFilter = {
    /**
     * Parameter that determines how many seconds have passed in both directions since contact.
     */
    contact_threshold_seconds: number;
    /**
     * Select clusters where `body` feature takes one of these values
     */
    body?: Array<string | null>;
    /**
     * Select clusters associated with the specified camera groups
     */
    camera_groups?: Array<number>;
    /**
     * Select clusters associated with the specified cameras
     */
    cameras?: Array<number>;
    /**
     * Select clusters that matched card
     */
    card?: number;
    /**
     * Select clusters related to these cases
     */
    case_in?: Array<number | null>;
    /**
     * Select clusters where `color` feature takes one of these values
     */
    color?: Array<string | null>;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select objects related to `episode` Pass comma separated values to select related to multiple `episode`s)
     */
    episodes?: Array<number>;
    /**
     * Select clusters where cluster events count is **greater or equal than or equal** to this value
     */
    events_count_gte?: number;
    /**
     * Select clusters where cluster events count is **less or equal than or equal** to this value
     */
    events_count_lte?: number;
    /**
     * Select clusters where average cluster event quality is **greater or equal than or equal** to this value
     */
    events_quality_gte?: number;
    /**
     * Select clusters where average cluster event quality is **less or equal than or equal** to this value
     */
    events_quality_lte?: number;
    /**
     * Select objects with `first_event_date` **greater** than this value
     */
    first_event_date_gt?: string;
    /**
     * Select objects with `first_event_date` **greater than or equal** to this value
     */
    first_event_date_gte?: string;
    /**
     * Select objects with `first_event_date` **less** than this value
     */
    first_event_date_lt?: string;
    /**
     * Select objects with `first_event_date` **less than or equal** to this value
     */
    first_event_date_lte?: string;
    /**
     * `true` - select only clusters with a card. `false` - select only clusters without a card.
     */
    has_card?: number;
    /**
     * Select clusters with provided ids
     */
    id_in?: Array<number>;
    /**
     * Select cluster where the SQL condition `license_plate_number ILIKE license_plate_number_contains` is true
     */
    license_plate_number_contains?: string;
    limit: number;
    /**
     * Select clusters that contain object similar to: (`detection:<detection id>`, `{face, body or car}event:<event id>`, `{face, body or car}object:<object id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`)
     */
    looks_like?: string;
    /**
     * Select clusters where `make` feature takes one of these values
     */
    make?: Array<string | null>;
    /**
     * Select clusters that matched a card included in these watch lists
     */
    matched_lists?: Array<number>;
    /**
     * Select clusters where `model` feature takes one of these values
     */
    model?: Array<string | null>;
    /**
     * Which field to use when ordering the results. Available fields: `id`, `created_date`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select only pinned clusters
     */
    pinned?: boolean;
    /**
     * Select clusters where `special_vehicle_type` feature takes one of these values
     */
    special_vehicle_type?: Array<string | null>;
    /**
     * Similarity threshold for `looks_like`
     */
    threshold?: number;
    /**
     * Select clusters with provided tnt ids
     */
    tnt_id_in?: Array<number | null>;
    /**
     * Use the last event for search
     */
    use_last_event: boolean;
}
export const EmptyClustersCarsContactsFilter: ClustersCarsContactsFilter = {
    contact_threshold_seconds: 10,
    body: [],
    camera_groups: [],
    cameras: [],
    card: undefined,
    case_in: [],
    color: [],
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    episodes: [],
    events_count_gte: undefined,
    events_count_lte: undefined,
    events_quality_gte: undefined,
    events_quality_lte: undefined,
    first_event_date_gt: undefined,
    first_event_date_gte: undefined,
    first_event_date_lt: undefined,
    first_event_date_lte: undefined,
    has_card: undefined,
    id_in: [],
    license_plate_number_contains: undefined,
    limit: 20,
    looks_like: undefined,
    make: [],
    matched_lists: [],
    model: [],
    ordering: undefined,
    page: undefined,
    pinned: undefined,
    special_vehicle_type: [],
    threshold: undefined,
    tnt_id_in: [],
    use_last_event: true,
}
