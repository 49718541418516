import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicensePageTabViewIntervalsList = _resolveComponent("LicensePageTabViewIntervalsList")!
  const _component_LicensePageMeta = _resolveComponent("LicensePageMeta")!
  const _component_LicensePageTabViewIntervalsLayout = _resolveComponent("LicensePageTabViewIntervalsLayout")!

  return (_openBlock(), _createBlock(_component_LicensePageTabViewIntervalsLayout, null, {
    list: _withCtx(() => [
      _createVNode(_component_LicensePageTabViewIntervalsList, {
        sections: _ctx.sections,
        modelValue: _ctx.intervalIndex,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.intervalIndex) = $event))
      }, null, 8, ["sections", "modelValue"])
    ]),
    "list-item": _withCtx(() => [
      _createVNode(_component_LicensePageMeta, { items: _ctx.items }, null, 8, ["items"])
    ]),
    _: 1
  }))
}