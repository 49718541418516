/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ClusterizationJobsFilter = {
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Which field to use when ordering the results. Available fields: `id`, `created_date`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
}
export const EmptyClusterizationJobsFilter: ClusterizationJobsFilter = {
    limit: '20',
    ordering: undefined,
    page: undefined,
}