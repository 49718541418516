
import { Options, Vue } from 'vue-class-component';
import { NButton, NDropdown, NDropdownItem } from '@/uikit';
import { Prop } from 'vue-property-decorator';

export type CommonItem = {
  i18n_label: string;
  value: string;
};

@Options({
  name: 'SortDropdown',
  components: { NDropdown, NButton, NDropdownItem }
})
export default class SortDropdown extends Vue {
  @Prop({ type: Array, required: true })
  readonly items!: Array<CommonItem>;

  @Prop({ type: String })
  readonly selected: string = '';

  @Prop({ type: String })
  modelValue = '';

  get button_i18n_label() {
    return this.selectedItem?.i18n_label || 'common.any';
  }

  get selectedItem() {
    return this.items.find(({ value }) => value === this.modelValue);
  }

  selectHandler(v: string) {
    this.$emit('update:modelValue', v);
  }
}
