import { ReportArchiveFile } from './archiver';
import { ReportSaver } from './saver';
import { ReportFile } from './types';

export class ReportSaverImpl implements ReportSaver {
  private readonly anchor = createHTMLAnchorElement();

  static create(): ReportSaverImpl {
    return new this();
  }

  private constructor() {}

  saveAs(archive: ReportArchiveFile): void;
  saveAs(report: ReportFile): void;
  saveAs(file: ReportArchiveFile | ReportFile): void {
    const [dataUrl, dispose] = computeFileDataUrl(file);
    this.configureAnchor(dataUrl, file.name).click();
    dispose();
  }

  private configureAnchor(dataUrl: string, name: string): HTMLAnchorElement {
    this.anchor.download = name;
    this.anchor.href = dataUrl;
    return this.anchor;
  }
}

function createHTMLAnchorElement(): HTMLAnchorElement {
  return document.createElement('a');
}

function computeFileDataUrl(file: ReportArchiveFile | ReportFile): [string, () => void] {
  const dataUrl = URL.createObjectURL(file);
  return [dataUrl, () => URL.revokeObjectURL(dataUrl)];
}
