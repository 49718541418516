export default class AutoOffsetTimer {
  public isEnabledAutoScrollTimeline = true; // включена ли авто прокрутка timeOffset в данный момент
  private timer: ReturnType<typeof setTimeout> | null = null;

  stopForTimeout(timeout = 25000) {
    this.clear();
    this.isEnabledAutoScrollTimeline = false;
    this.timer = setTimeout(() => {
      this.isEnabledAutoScrollTimeline = true;
    }, timeout);
  }

  clear() {
    this.timer && clearTimeout(this.timer);
  }
}
