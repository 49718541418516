import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createBlock(_component_NDropdown, { onCommand: _ctx.dispatchDownloadEvent }, {
    toggle: _withCtx(({ toggle }) => [
      _createVNode(_component_NButton, {
        class: "reports-page-table-column-status-download",
        onClick: toggle,
        icon: "download",
        type: "text"
      }, null, 8, ["onClick"])
    ]),
    body: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formats, (format) => {
        return (_openBlock(), _createBlock(_component_NDropdownItem, {
          command: format,
          key: format
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.i18nReportFormat(format)), 1)
          ]),
          _: 2
        }, 1032, ["command"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["onCommand"]))
}