import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterSection = _resolveComponent("FilterSection")!
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_SortDropdown = _resolveComponent("SortDropdown")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NBaseBar = _resolveComponent("NBaseBar")!
  const _component_LinesTable = _resolveComponent("LinesTable")!
  const _component_EntityBlank = _resolveComponent("EntityBlank")!
  const _component_ListPage = _resolveComponent("ListPage")!

  return (_openBlock(), _createBlock(_component_ListPage, {
    state: _ctx.state,
    statistics: ""
  }, {
    filters: _withCtx(() => [
      _createVNode(_component_FilterSection, {
        filter: _ctx.module.filter,
        "scheme-small": _ctx.smallFilterLayout,
        "scheme-large": _ctx.bigFilterLayout,
        "has-report": "",
        "has-pinned-fields": ""
      }, null, 8, ["filter", "scheme-small", "scheme-large"])
    ]),
    statistic: _withCtx(() => [
      _createVNode(_component_NBaseBar, null, {
        start: _withCtx(() => [
          _createVNode(_component_Statistics, { module: _ctx.module }, null, 8, ["module"]),
          _createVNode(_component_SortDropdown, {
            items: _ctx.sortTypes,
            modelValue: _ctx.module.filter.current.ordering,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.module.filter.current.ordering) = $event))
          }, null, 8, ["items", "modelValue"])
        ]),
        end: _withCtx(() => [
          (_ctx.modelAcl.add)
            ? (_openBlock(), _createBlock(_component_NButton, {
                key: 0,
                onAction: _ctx.handleCreate,
                icon: "add",
                type: "secondary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('lines.create_lines')), 1)
                ]),
                _: 1
              }, 8, ["onAction"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    items: _withCtx(() => [
      (_ctx.module.items.length)
        ? (_openBlock(), _createBlock(_component_LinesTable, {
            key: 0,
            module: _ctx.module,
            "selected-item-ids": _ctx.selectedItemIds,
            "model-acl": _ctx.modelAcl
          }, null, 8, ["module", "selected-item-ids", "model-acl"]))
        : _createCommentVNode("", true)
    ]),
    "items-empty": _withCtx(() => [
      (_ctx.module.loaded && !_ctx.module.loading && _ctx.module.items.length === 0)
        ? (_openBlock(), _createBlock(_component_EntityBlank, {
            key: 0,
            "page-prefix": "lines",
            "page-type": _ctx.state.pageType,
            "create-icon": "lines",
            "has-create": _ctx.modelAcl.add,
            "is-empty": !_ctx.module.filter.hasChanges,
            onCreate: _ctx.handleCreate
          }, null, 8, ["page-type", "has-create", "is-empty", "onCreate"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["state"]))
}