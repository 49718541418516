import { nextTick, reactive, watch } from 'vue';
import { getLogger } from 'loglevel';
const logger = getLogger('[intersection]');
logger.setLevel('warn');

export type IntersectionSourceType = {
  id: string;
  container: HTMLElement;
  itemsQuerySelector: string;
};

export type IntersectionResultType = {
  source: IntersectionSourceType;
  displayItemsMap: Record<string, boolean>;
  observer: IntersectionObserver;
};

export class IntersectionResultItem implements IntersectionResultType {
  source: IntersectionSourceType;
  displayItemsMap: Record<string, boolean>;
  observer: IntersectionObserver;

  constructor(source: IntersectionSourceType) {
    const observer = new IntersectionObserver(this.intersectionHandler.bind(this), { root: source.container, threshold: 0.01 });
    this.source = source;
    this.observer = observer;
    this.displayItemsMap = reactive({});
    logger.info('Create intersection from', source);
  }

  syncObservableTargetsNextTick() {
    return nextTick(() => {
      this.syncObservableTargets();
    });
  }

  reset() {
    logger.info('Observer disconnect: ', Object.values(this.displayItemsMap).length);
    this.observer?.disconnect();
    for (let key in this.displayItemsMap) {
      delete this.displayItemsMap[key];
    }
  }

  syncObservableTargets() {
    const elements = Array.from(this.source.container.querySelectorAll(this.source.itemsQuerySelector).values()) as any as HTMLElement[];
    logger.info('Sync all elements ', elements.length);
    elements.forEach((v) => {
      const id = String(v.dataset['id']);
      const hasObserver = this.displayItemsMap[id] !== undefined;
      if (!hasObserver) {
        this.displayItemsMap[id] = false;
        this.observer?.observe(v);
        logger.info('Add observer for ', id, v);
      }
    });
  }

  intersectionHandler(entries: IntersectionObserverEntry[], target: IntersectionObserver) {
    logger.info('Intersection change of ', entries);
    entries.forEach((v) => {
      const id = String((v.target as HTMLElement).dataset['id']);
      this.displayItemsMap[id] = v.isIntersecting;
    });
  }
}
