import Hammer from 'hammerjs';
import { Point } from '../bbox/types';
import { EventManager } from './EventManager';

const LeftButton = 0;
export class Mover {
  public start: Point = { x: 0, y: 0 };
  protected active = false;
  protected eventManager: EventManager;
  protected hammer: HammerManager;

  constructor(protected content: Element, protected container: Element, protected offset: Point) {
    this.eventManager = new EventManager();
    this.hammer = new Hammer(this.content as HTMLElement);
    this.hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });
    this.bindHandlers();
  }

  protected bindHandlers() {
    this.hammer.on('panmove', this.panmove.bind(this));
    this.eventManager.bind(this.content, 'mousedown', this.startMove, undefined, this);
    this.eventManager.bind(this.container, 'mouseup', this.endMove, undefined, this);
    this.eventManager.bind(this.container, 'mousemove', this.move, undefined, this);
    this.eventManager.bind(this.container, 'mouseleave', this.endMove, undefined, this);
    this.eventManager.bind(this.container, 'dblclick', this.reset, undefined, this);
  }

  public reset() {
    this.active = false;
    this.resetPoint(this.start);
    this.resetPoint(this.offset);
  }

  public destruct() {
    this.reset();
    this.hammer.destroy();
    this.eventManager.unbindAll();
  }

  protected resetPoint(point: Point) {
    point.x = 0;
    point.y = 0;
  }

  protected panmove(e: HammerInput) {
    this.offset.x = e.deltaX;
    this.offset.y = e.deltaY;
  }

  protected startMove(e: MouseEvent) {
    if (e.button === LeftButton) {
      e.preventDefault();
      this.active = true;
      this.start = { x: e.pageX - this.offset.x, y: e.pageY - this.offset.y };
    }
  }

  // todo: restrict movement by container & content edges
  protected move(e: MouseEvent) {
    if (this.active) {
      this.offset.x = e.pageX - this.start.x;
      this.offset.y = e.pageY - this.start.y;
    }
  }

  protected endMove() {
    this.active = false;
  }
}
