import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "external-search-request-info__id" }
const _hoisted_2 = { class: "external-search-request-info__count heading-m" }
const _hoisted_3 = { class: "external-search-request-info__date" }
const _hoisted_4 = { class: "external-search-request-info__author" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDateTimeLabel = _resolveComponent("NDateTimeLabel")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "external-search-request-info",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showResults && _ctx.showResults(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, "#" + _toDisplayString(_ctx.item?.id), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$tm('external_search.event_mf', { count: _ctx.item?.last_results_count })), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NDateTimeLabel, {
        size: "m",
        "model-value": _ctx.item?.last_search_date
      }, null, 8, ["model-value"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createTextVNode(_toDisplayString(_ctx.$t('common.created_by_user', 'f')) + " ", 1),
      (_ctx.dataModule.getUserById(_ctx.item?.created_by))
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            type: "text",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.navigateToUser(_ctx.item?.created_by)), ["stop"])),
            class: "external-search-request-info__author-link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.dataModule.getUserNameById(_ctx.item?.created_by)), 1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('common.id' )) + ":" + _toDisplayString(_ctx.item?.created_by), 1)
          ], 64))
    ])
  ]))
}