/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CarCard } from '../models/CarCard';
import type { CarCardRequest } from '../models/CarCardRequest';
import type { CountResponse } from '../models/CountResponse';
import type { HumanCard } from '../models/HumanCard';
import type { HumanCardRequest } from '../models/HumanCardRequest';
import type { PaginatedCarCardList } from '../models/PaginatedCarCardList';
import type { PaginatedHumanCardList } from '../models/PaginatedHumanCardList';
import type { PatchedCarCardRequest } from '../models/PatchedCarCardRequest';
import type { PatchedHumanCardRequest } from '../models/PatchedHumanCardRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class CardsService {
    /**
     * List car cards
     * Use this method to list car cards.
     * @param active Select only active (`true`) or disabled (`false`) cards
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param filled Select only filled (`true`) or infilled (`false`) cards
     * @param hasCarObjects Select only cards with linked car objects (`true`) or without them (`false`)
     * @param idIn Select cards with provided ids
     * @param licensePlateNumberContains Select cards where the SQL condition `license_plate_number ILIKE license_plate_number_contains` is true
     * @param limit Number of results to return per page.
     * @param looksLike Select cards that contain object similar to: (`detection:<detection id>`, `{face, body, or car}event:<event id>` `{face, body, or car}object:<object id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`)
     * @param nameContains Select cards where the SQL condition `name ILIKE name_contains` is true
     * @param ordering Which field to use when ordering the results. Available fields: `created_date`, `id`, `looks_like_confidence`
     * @param page Pagination cursor value.
     * @param relation Select cards included in these relations
     * @param threshold Similarity threshold for `looks_like`
     * @param watchLists Select cards included in these watch lists
     * @returns PaginatedCarCardList
     * @throws ApiError
     */
    public static cardsCarsList(
        active?: boolean,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        filled?: boolean,
        hasCarObjects?: string,
        idIn?: Array<number>,
        licensePlateNumberContains?: string,
        limit?: string,
        looksLike?: string,
        nameContains?: string,
        ordering?: string,
        page?: string,
        relation?: Array<number>,
        threshold?: number,
        watchLists?: Array<number>,
    ): CancelablePromise<PaginatedCarCardList> {
        return __request({
            method: 'GET',
            path: `/cards/cars/`,
            query: {
                'active': active,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'filled': filled,
                'has_car_objects': hasCarObjects,
                'id_in': idIn,
                'license_plate_number_contains': licensePlateNumberContains,
                'limit': limit,
                'looks_like': looksLike,
                'name_contains': nameContains,
                'ordering': ordering,
                'page': page,
                'relation': relation,
                'threshold': threshold,
                'watch_lists': watchLists,
            },
        });
    }
    /**
     * Add new car card
     * Use this method to add a new car card.
     * @param requestBody
     * @returns CarCard
     * @throws ApiError
     */
    public static cardsCarsCreate(
        requestBody: CarCardRequest,
    ): CancelablePromise<CarCard> {
        return __request({
            method: 'POST',
            path: `/cards/cars/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve car card by ID
     * Use this method to retrieve a car card by its ID.
     * @param id A unique integer value identifying this Car card.
     * @returns CarCard
     * @throws ApiError
     */
    public static cardsCarsRetrieve(
        id: number,
    ): CancelablePromise<CarCard> {
        return __request({
            method: 'GET',
            path: `/cards/cars/${id}/`,
        });
    }
    /**
     * Update car card properties
     * Use this method to update a car card properties.
     * @param id A unique integer value identifying this Car card.
     * @param requestBody
     * @returns CarCard
     * @throws ApiError
     */
    public static cardsCarsPartialUpdate(
        id: number,
        requestBody?: PatchedCarCardRequest,
    ): CancelablePromise<CarCard> {
        return __request({
            method: 'PATCH',
            path: `/cards/cars/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete car card
     * Use this method to delete a car card.
     * @param id A unique integer value identifying this Car card.
     * @returns void
     * @throws ApiError
     */
    public static cardsCarsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/cards/cars/${id}/`,
        });
    }
    /**
     * Car cards count
     * This method retrieves a count of car cards.
     * @param active Select only active (`true`) or disabled (`false`) cards
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param filled Select only filled (`true`) or infilled (`false`) cards
     * @param hasCarObjects Select only cards with linked car objects (`true`) or without them (`false`)
     * @param idIn Select cards with provided ids
     * @param licensePlateNumberContains Select cards where the SQL condition `license_plate_number ILIKE license_plate_number_contains` is true
     * @param nameContains Select cards where the SQL condition `name ILIKE name_contains` is true
     * @param relation Select cards included in these relations
     * @param watchLists Select cards included in these watch lists
     * @returns CountResponse
     * @throws ApiError
     */
    public static cardsCarsCountRetrieve(
        active?: boolean,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        filled?: boolean,
        hasCarObjects?: string,
        idIn?: Array<number>,
        licensePlateNumberContains?: string,
        nameContains?: string,
        relation?: Array<number>,
        watchLists?: Array<number>,
    ): CancelablePromise<CountResponse> {
        return __request({
            method: 'GET',
            path: `/cards/cars/count/`,
            query: {
                'active': active,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'filled': filled,
                'has_car_objects': hasCarObjects,
                'id_in': idIn,
                'license_plate_number_contains': licensePlateNumberContains,
                'name_contains': nameContains,
                'relation': relation,
                'watch_lists': watchLists,
            },
        });
    }
    /**
     * List human cards
     * Use this method to list human cards.
     * @param active Select only active (`true`) or disabled (`false`) cards
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param filled Select only filled (`true`) or infilled (`false`) cards
     * @param hasBodyObjects Select only cards with linked body objects (`true`) or without them (`false`)
     * @param hasFaceObjects Select only cards with linked face objects (`true`) or without them (`false`)
     * @param idIn Select cards with provided ids
     * @param limit Number of results to return per page.
     * @param looksLike Select cards that contain object similar to: (`detection:<detection id>`, `{face, body, or car}event:<event id>` `{face, body, or car}object:<object id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`)
     * @param nameContains Select cards where the SQL condition `name ILIKE name_contains` is true
     * @param ordering Which field to use when ordering the results. Available fields: `created_date`, `id`, `looks_like_confidence`
     * @param page Pagination cursor value.
     * @param relation Select cards included in these relations
     * @param threshold Similarity threshold for `looks_like`
     * @param watchLists Select cards included in these watch lists
     * @returns PaginatedHumanCardList
     * @throws ApiError
     */
    public static cardsHumansList(
        active?: boolean,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        filled?: boolean,
        hasBodyObjects?: string,
        hasFaceObjects?: string,
        idIn?: Array<number>,
        limit?: string,
        looksLike?: string,
        nameContains?: string,
        ordering?: string,
        page?: string,
        relation?: Array<number>,
        threshold?: number,
        watchLists?: Array<number>,
    ): CancelablePromise<PaginatedHumanCardList> {
        return __request({
            method: 'GET',
            path: `/cards/humans/`,
            query: {
                'active': active,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'filled': filled,
                'has_body_objects': hasBodyObjects,
                'has_face_objects': hasFaceObjects,
                'id_in': idIn,
                'limit': limit,
                'looks_like': looksLike,
                'name_contains': nameContains,
                'ordering': ordering,
                'page': page,
                'relation': relation,
                'threshold': threshold,
                'watch_lists': watchLists,
            },
        });
    }
    /**
     * Add new human card
     * Use this method to add a new human card.
     * @param requestBody
     * @returns HumanCard
     * @throws ApiError
     */
    public static cardsHumansCreate(
        requestBody: HumanCardRequest,
    ): CancelablePromise<HumanCard> {
        return __request({
            method: 'POST',
            path: `/cards/humans/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve human card by ID
     * Use this method to retrieve a human card by its ID.
     * @param id A unique integer value identifying this Human card.
     * @returns HumanCard
     * @throws ApiError
     */
    public static cardsHumansRetrieve(
        id: number,
    ): CancelablePromise<HumanCard> {
        return __request({
            method: 'GET',
            path: `/cards/humans/${id}/`,
        });
    }
    /**
     * Update human card properties
     * Use this method to update a human card properties.
     * @param id A unique integer value identifying this Human card.
     * @param requestBody
     * @returns HumanCard
     * @throws ApiError
     */
    public static cardsHumansPartialUpdate(
        id: number,
        requestBody?: PatchedHumanCardRequest,
    ): CancelablePromise<HumanCard> {
        return __request({
            method: 'PATCH',
            path: `/cards/humans/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete human card
     * Use this method to delete a human card.
     * @param id A unique integer value identifying this Human card.
     * @returns void
     * @throws ApiError
     */
    public static cardsHumansDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/cards/humans/${id}/`,
        });
    }
    /**
     * Human cards count
     * This method retrieves a count of human cards.
     * @param active Select only active (`true`) or disabled (`false`) cards
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param filled Select only filled (`true`) or infilled (`false`) cards
     * @param hasBodyObjects Select only cards with linked body objects (`true`) or without them (`false`)
     * @param hasFaceObjects Select only cards with linked face objects (`true`) or without them (`false`)
     * @param idIn Select cards with provided ids
     * @param nameContains Select cards where the SQL condition `name ILIKE name_contains` is true
     * @param relation Select cards included in these relations
     * @param watchLists Select cards included in these watch lists
     * @returns CountResponse
     * @throws ApiError
     */
    public static cardsHumansCountRetrieve(
        active?: boolean,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        filled?: boolean,
        hasBodyObjects?: string,
        hasFaceObjects?: string,
        idIn?: Array<number>,
        nameContains?: string,
        relation?: Array<number>,
        watchLists?: Array<number>,
    ): CancelablePromise<CountResponse> {
        return __request({
            method: 'GET',
            path: `/cards/humans/count/`,
            query: {
                'active': active,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'filled': filled,
                'has_body_objects': hasBodyObjects,
                'has_face_objects': hasFaceObjects,
                'id_in': idIn,
                'name_contains': nameContains,
                'relation': relation,
                'watch_lists': watchLists,
            },
        });
    }
}