import {
  Color,
  Colors,
  CommonStyleColor,
  FigureStyleOptions,
  isSegmentGradientColor,
  PointAsArray,
  PolyLine,
  PolyLineStyleColor,
  PolyLineStyleOptions,
  RGBColorArray,
  Size,
  Sizes,
  TextStyleOptions
} from './types';
import { getLineDistance, getPointsDistance, interpolateColor } from '@/uikit/draw/math-utils';

export function drawLine(ctx: CanvasRenderingContext2D, a: PointAsArray, b: PointAsArray, options: FigureStyleOptions) {
  ctx.beginPath();
  ctx.lineWidth = options.lineWidth ?? Sizes.LineNormalWidth;
  ctx.moveTo(a[0], a[1]);
  ctx.lineTo(b[0], b[1]);
  if (options.lineDash?.length) {
    ctx.setLineDash(options.lineDash);
  }
  if (options.lineCap) {
    ctx.lineCap = options.lineCap;
  }
  if (options.strokeStyle) {
    ctx.strokeStyle = options.strokeStyle;
    ctx.stroke();
  }
  if (options.fillStyle) {
    ctx.fillStyle = options.fillStyle;
    ctx.fill();
  }
  ctx.closePath();
}

export function* getNextPolyLineGradientSegment(
  ctx: CanvasRenderingContext2D,
  line: PolyLine,
  colorOrGradient: PolyLineStyleColor
): Generator<CommonStyleColor> {
  if (isSegmentGradientColor(colorOrGradient)) {
    const [baseColorA, baseColorB] = colorOrGradient;
    let passedDistance = 0;
    const totalDistance = getLineDistance(line);
    let lastGradientColor: RGBColorArray = [...baseColorA];
    for (let i = 0; i < line.length - 1; i++) {
      const a = line[i];
      const b = line[i + 1];
      passedDistance += getPointsDistance(a, b);
      const coefficient = passedDistance / totalDistance;
      const nextGradientColor = interpolateColor(baseColorA, baseColorB, coefficient);
      const gradient = ctx.createLinearGradient(a[0], a[1], b[0], b[1]);
      gradient.addColorStop(0, rgbArrayToString(lastGradientColor));
      gradient.addColorStop(1, rgbArrayToString(nextGradientColor));
      lastGradientColor = nextGradientColor;
      yield gradient;
    }
  } else {
    for (let i = 0; i < line.length - 1; i++) {
      yield colorOrGradient;
    }
  }
}

export function drawPolyLine(ctx: CanvasRenderingContext2D, line: PolyLine, options: PolyLineStyleOptions) {
  const getFillStyle = options.fillStyle ? getNextPolyLineGradientSegment(ctx, line, options.fillStyle) : null;
  const getStrokeStyle = options.strokeStyle ? getNextPolyLineGradientSegment(ctx, line, options.strokeStyle) : null;
  const { dotsSize, ...figureOptions } = options;

  let fillStyle = null;
  let strokeStyle = null;
  for (let i = 0; i < line.length - 1; i++) {
    fillStyle = getFillStyle?.next().value;
    strokeStyle = getStrokeStyle?.next().value;
    drawLine(ctx, line[i], line[i + 1], { ...figureOptions, strokeStyle, fillStyle });
    dotsSize && drawPointCircle(ctx, line[i], dotsSize, { fillStyle, strokeStyle });
  }
  dotsSize && drawPointCircle(ctx, line[line.length - 1], dotsSize, { fillStyle, strokeStyle });
}

export function rgbArrayToString(rgb: RGBColorArray) {
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
}

export function drawPath(points: PolyLine, path2d: Path2D | null = null) {
  const polygonPath = path2d || new Path2D();
  const startPoint = points[0];
  polygonPath.moveTo(startPoint[0], startPoint[1]);
  for (let i = 1; i < points.length; i++) {
    polygonPath.lineTo(points[i][0], points[i][1]);
  }
  polygonPath.lineTo(startPoint[0], startPoint[1]);
  polygonPath.closePath();
  return polygonPath;
}

export function drawPointSquare(
  ctx: CanvasRenderingContext2D,
  point: PointAsArray,
  size: Size,
  strokeColor: Color = Colors.PointBorder,
  fillColor: Color = Colors.White90
) {
  const x = point[0] - size / 2;
  const y = point[1] - size / 2;

  if (!ctx) return;
  ctx.beginPath();
  ctx.fillStyle = fillColor;
  ctx.setLineDash([]);
  ctx.strokeStyle = strokeColor;
  ctx.lineWidth = 1;
  ctx.rect(x, y, size, size);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
}

export function drawText(ctx: CanvasRenderingContext2D, text: string, point: PointAsArray, size: Size = Sizes.PointTextBig, options: TextStyleOptions) {
  ctx.font = `${size}px sans-serif`;
  ctx.textAlign = options.textAlign ?? 'center';
  ctx.textBaseline = options.textBaseline ?? 'middle';
  if (options.fillStyle) {
    ctx.fillStyle = options.fillStyle;
    ctx.fillText(text, point[0], point[1] + 1);
  }

  if (options.strokeStyle) {
    ctx.strokeStyle = options.strokeStyle;
    ctx.strokeText(text, point[0], point[1] + 1);
  }
}

export function drawPointCircle(ctx: CanvasRenderingContext2D, point: PointAsArray, size: number, options: FigureStyleOptions) {
  ctx.beginPath();
  ctx.arc(point[0], point[1], size / 2, 0, 2 * Math.PI);
  ctx.lineWidth = options.lineWidth ?? Sizes.LineThinWidth;
  if (options.fillStyle) {
    ctx.fillStyle = options.fillStyle;
    ctx.fill();
  }
  if (options.strokeStyle) {
    ctx.strokeStyle = options.strokeStyle;
    ctx.stroke();
  }
}

export function drawArrowHead(ctx: CanvasRenderingContext2D, a: PointAsArray, b: PointAsArray, options: FigureStyleOptions) {
  const headSize = 5;
  const angle = Math.atan2(b[1] - a[1], b[0] - a[0]);
  const xNegativeOffset = headSize * Math.cos(angle - Math.PI / 7);
  const xPositiveOffset = headSize * Math.cos(angle + Math.PI / 7);
  const yNegativeOffset = headSize * Math.sin(angle - Math.PI / 7);
  const yPositiveOffset = headSize * Math.sin(angle + Math.PI / 7);

  ctx.beginPath();
  ctx.moveTo(b[0], b[1]);

  ctx.lineTo(b[0] - xNegativeOffset, b[1] - yNegativeOffset);
  ctx.lineTo(b[0] - xPositiveOffset, b[1] - yPositiveOffset);
  ctx.lineTo(b[0], b[1]);
  ctx.lineTo(b[0] - xNegativeOffset, b[1] - yNegativeOffset);

  if (options.strokeStyle) {
    ctx.strokeStyle = options.strokeStyle;
    ctx.stroke();
  }

  if (options.fillStyle) {
    ctx.fillStyle = options.fillStyle;
    ctx.fill();
  }
}
