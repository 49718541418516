/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CasesFilter = {
    /**
     * Select cases created by these authors
     */
    authors?: Array<number>;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select cases with provided ids
     */
    id_in?: Array<number>;
    /**
     * Select cases with `incident_date` **greater** than this value
     */
    incident_date_gt?: string;
    /**
     * Select cases with `incident_date` **greater than or equal** to this value
     */
    incident_date_gte?: string;
    /**
     * Select cases with `incident_date` **less** than this value
     */
    incident_date_lt?: string;
    /**
     * Select cases with `incident_date` **less than or equal** to this value
     */
    incident_date_lte?: string;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select cases where the SQL condition `name ILIKE name_contains` is true
     */
    name_contains?: string;
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select cases with `record_created_date` **greater** than this value
     */
    record_created_date_gt?: string;
    /**
     * Select cases with `record_created_date` **greater than or equal** to this value
     */
    record_created_date_gte?: string;
    /**
     * Select cases with `record_created_date` **less** than this value
     */
    record_created_date_lt?: string;
    /**
     * Select cases with `record_created_date` **less than or equal** to this value
     */
    record_created_date_lte?: string;
    /**
     * Select cases where `record_id` exact this value
     */
    record_id?: string;
    /**
     * Select cases where `status` takes one of these values
     */
    status?: 'archived' | 'open';
}
export const EmptyCasesFilter: CasesFilter = {
    authors: [],
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    id_in: [],
    incident_date_gt: undefined,
    incident_date_gte: undefined,
    incident_date_lt: undefined,
    incident_date_lte: undefined,
    limit: '20',
    name_contains: undefined,
    ordering: undefined,
    page: undefined,
    record_created_date_gt: undefined,
    record_created_date_gte: undefined,
    record_created_date_lt: undefined,
    record_created_date_lte: undefined,
    record_id: undefined,
    status: undefined,
}