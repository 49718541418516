/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TracksFilter = {
            /**
            * Select objects with `created_date` **greater** than this value
            */
        created_date_gt?: string;
            /**
            * Select objects with `created_date` **greater than or equal** to this value
            */
        created_date_gte?: string;
            /**
            * Select objects with `created_date` in last N days
            */
        created_date_last_n_days?: number;
            /**
            * Select objects with `created_date` **less** than this value
            */
        created_date_lt?: string;
            /**
            * Select objects with `created_date` **less than or equal** to this value
            */
        created_date_lte?: string;
            /**
            * Select objects with `created_date` in last Nth week (including Sunday and Saturday)
            */
        created_date_nth_full_week?: number;
            /**
            * Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
            */
        created_date_nth_work_week?: number;
            /**
            * Select only the track of event with provided id
            */
        event_id?: string;
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
}
export const EmptyTracksFilter: TracksFilter = {
        created_date_gt: undefined,
        created_date_gte: undefined,
        created_date_last_n_days: undefined,
        created_date_lt: undefined,
        created_date_lte: undefined,
        created_date_nth_full_week: undefined,
        created_date_nth_work_week: undefined,
        event_id: undefined,
        limit: '20',
        ordering: undefined,
        page: undefined,
}