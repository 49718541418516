import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 1,
  class: "n-attachments-list-item__name"
}
const _hoisted_3 = { class: "n-attachments-list-item__size" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NAttachmentsListItemRemovingPopover = _resolveComponent("NAttachmentsListItemRemovingPopover")!

  return (_openBlock(), _createBlock(_component_NAttachmentsListItemRemovingPopover, {
    i18n: _ctx.i18n,
    visible: _ctx.isPopoverVisible,
    onCancel: _ctx.hidePopover,
    onRemove: _ctx.dispatchRemoveEvent
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.classes, "control-m n-attachments-list-item"]),
        style: _normalizeStyle(_ctx.styles)
      }, [
        _createVNode(_component_NIcon, {
          height: 17.14,
          width: 14.29,
          class: "n-attachments-list-item__icon",
          name: "file"
        }, null, 8, ["height", "width"]),
        (_ctx.attachment.file)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.attachment.file,
              class: "n-attachments-list-item__name",
              target: "_blank"
            }, _toDisplayString(_ctx.attachment.name), 9, _hoisted_1))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.attachment.name), 1)),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formattedSize), 1),
        _createVNode(_component_NButton, {
          class: _normalizeClass([_ctx.deleteClasses, "n-attachments-list-item__delete"]),
          disabled: !_ctx.isRemovable,
          onClick: _ctx.handleRemoveEvent,
          icon: "close",
          type: "text"
        }, null, 8, ["class", "disabled", "onClick"])
      ], 6)
    ]),
    _: 1
  }, 8, ["i18n", "visible", "onCancel", "onRemove"]))
}