import { h } from 'vue';
import { LicensePageTabName } from '../tabs';
import { LicensePageTabViewCommon, LicensePageTabViewIntervals, LicensePageTabViewLimits, LicensePageTabViewServices } from './components';
import { LicensePageTabViewRenderer } from './types';

const renderLicensePageTabViewCommon: LicensePageTabViewRenderer = (license) => {
  return h(LicensePageTabViewCommon, { license, key: Date.now() });
};

const renderLicensePageTabViewIntervals: LicensePageTabViewRenderer = (_license, licenseUsage) => {
  return h(LicensePageTabViewIntervals, { licenseUsage, key: Date.now() });
};

const renderLicensePageTabViewLimits: LicensePageTabViewRenderer = (license) => {
  return h(LicensePageTabViewLimits, { limits: license.limits ?? [], key: Date.now() });
};

const renderLicensePageTabViewServices: LicensePageTabViewRenderer = (license) => {
  return h(LicensePageTabViewServices, { services: license.services, key: Date.now() });
};

export function findLicensePageTabViewRendererByName(tabName: LicensePageTabName): LicensePageTabViewRenderer {
  switch (tabName) {
    case 'common':
      return renderLicensePageTabViewCommon;
    case 'intervals':
      return renderLicensePageTabViewIntervals;
    case 'limits':
      return renderLicensePageTabViewLimits;
    case 'services':
      return renderLicensePageTabViewServices;
    default:
      throwError(`Unable to render a view for the tab "${tabName}".`);
  }
}

export function sleep(delayInMs: number): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(resolve, delayInMs));
}

function throwError(message: string): never {
  throw new Error(message);
}
