export async function fileDownload(src: string, name = '') {
  const image = await fetch(src);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement('a');
  link.href = imageURL;
  link.download = normalizeFileName(name || src);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(imageURL);
}

export async function copyTextToClipboard(text: string): Promise<boolean> {
  if (!navigator.clipboard && !window.isSecureContext) {
    return fallbackTextCopyToClipboard(text);
  }

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (e) {
    console.warn(e);
    return false;
  }
}

function fallbackTextCopyToClipboard(text: string): boolean {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.top = '-1000px';
  textArea.style.left = '-1000px';
  textArea.style.position = 'fixed';

  try {
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    return true;
  } catch (e) {
    console.warn(e);
    return false;
  }
}

export function normalizeFileName(fileName: string) {
  const MaxFileNameLength = 200;
  const cleanFileName = fileName.replace(/\\\/:\*\?"<>\|/g, '');

  if (cleanFileName.length > MaxFileNameLength) {
    const extension = cleanFileName.indexOf('.') !== -1 ? (cleanFileName.split('.').pop() as string) : '';
    return cleanFileName.substring(0, MaxFileNameLength - extension.length) + (extension ? `.${extension}` : '');
  }
  return cleanFileName;
}

export const hashCode = (str: string) => str.split('').reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);

export function filterRecursive(list: any[], predicate: (v: any) => boolean): any {
  let r = [];
  for (let i = 0; i < list.length; i++) {
    if (Array.isArray(list[i])) {
      let item = filterRecursive(list[i], predicate);
      if (item.length) r.push(item);
    } else if (predicate(list[i])) {
      r.push(list[i]);
    }
  }

  return r;
}
