
import { NIcon } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';

@Options({
  components: {
    NCheckbox,
    NIcon
  },
  emits: ['close', 'update:modelValue']
})
export default class FilterSmallCheckbox extends Vue {
  @Prop({ type: [Boolean], required: true })
  readonly modelValue!: boolean;

  @Prop({ type: String })
  readonly label?: string;

  @Prop({ type: String })
  readonly placeholder?: string;

  toggleModel(): void {
    this.model = !this.model;
  }

  get model() {
    return this.modelValue;
  }

  set model(value: boolean) {
    this.$emit('update:modelValue', value);
  }
}
