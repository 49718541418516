import TimelineChunks from '@/components/video-player/objects/vms/chunks';
import { Camera, CameraGroup } from '@/api';
import { dataModule } from '@/store/data';

export default class VideoPlayerState {
  resolution = '800x600';
  resolutions = ['2560x1440', '1920x1080', '1280x720', '1024x576', '800x600', '640x480'];

  videoQuality = 80;
  brightness = 50;
  contrast = 50;
  saturation = 50;
  bboxObjects = { faces: true, bodies: true, cars: true };

  canChangeQuality = true;
  canChangeObjects = true;

  timelineChunks = new TimelineChunks();

  cameraId = -1;
  model = ''; // cameras | videos
  streamProps: any = {};

  camera: Camera | undefined;
  cameraGroup: CameraGroup | undefined;

  isOnvifCamera = false;
  isCameraRemoved = false;

  onWsOpened: any = null;

  get cameraName() {
    return (this.camera && this.camera.name) || '[Camera not found]';
  }
  get cameraGroupName() {
    return (this.cameraGroup && this.cameraGroup.name) || '[Group not found]';
  }

  async fillCameraInfoById() {
    if (this.camera || this.cameraId === -1) {
      console.log('Camera no set or already filled!');
      return;
    }
    await dataModule.camerasModule.firstLoadingPromise;
    await dataModule.cameraGroupsModule.firstLoadingPromise;
    this.camera = dataModule.camerasModule.items.find((camera) => camera.id === this.cameraId);
    this.cameraGroup = dataModule.cameraGroupsModule.items.find((group) => group.id === this.camera?.group);
    if (this.camera) {
      this.isOnvifCamera = this.camera.onvif_camera !== null;
    } else {
      if (this.model === 'cameras') {
        this.isCameraRemoved = true;
      }
    }
  }

  isTimeInChunks(time: number) {
    return this.timelineChunks.items.reduce((value, item) => {
      return value || (time > item.timeFrom && time < item.timeTo);
    }, false);
  }
}
