
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import CardConfidence from '@/components/cards/CardConfidence.vue';

@Options({
  name: 'CardGroupTile',
  components: { CardConfidence }
})
export default class CardGroupTile extends Vue {}
