import { reactive } from 'vue';
import { localStorageModule } from '@/store/application/local.storage';
import { getActiveTheme, getSystemTheme, setTheme, Themes } from '@/common/theme-load';
import { configModule } from '@/store/config';
import { ITheme, ThemeVars } from '@/definitions/config/theme';
import { get } from 'lodash';
import { userStorageModule } from '@/store/application/user.storage';
import { DisplayType } from '@/store/application/page.definitions';

export class ApplicationModule {
  static Name = 'ApplicationModule';
  static StoredItems = ['settings.sound.enabled', 'settings.theme', 'settings.ui.list_view'];
  static DefaultProduct = 'multi';

  build_version = process.env.VUE_APP_BUILD_VERSION || '';
  build_date = process.env.VUE_APP_BUILD_DATE || new Date().toISOString();
  product = process.env.VUE_APP_PRODUCT || '';

  settings = {
    theme: Themes.System,
    sound: {
      enabled: false
    },
    debug: false,
    presaleDebug: false,
    ui: {
      list_view: DisplayType.Short
    }
  };

  menu = {
    parent: 'one',
    child: null
  };

  get isDefaultProduct() {
    return this.product === ApplicationModule.DefaultProduct;
  }

  getThemeVars(theme: string): ThemeVars | undefined {
    const themeConfig: ITheme | undefined = get(configModule.config, 'themes.items', []).find((v: ITheme) => v.name === theme);
    return themeConfig?.vars;
  }

  toggleDebug() {
    this.settings.debug = !this.settings.debug;
  }

  togglePresaleDebug() {
    this.settings.presaleDebug = !this.settings.presaleDebug;
  }

  get nextTheme() {
    const activeTheme = getActiveTheme();
    const theme = activeTheme === Themes.Dark ? Themes.Light : Themes.Dark;
    return theme;
  }

  async toggleTheme() {
    const theme = this.nextTheme;
    const vars = this.getThemeVars(theme);
    await setTheme(theme, vars);
    this.settings.theme = theme;
    localStorageModule.syncToStorageByName(ApplicationModule.Name);
    userStorageModule.syncToStorageByName(ApplicationModule.Name);
  }

  async initTheme() {
    const theme = this.settings.theme || configModule?.config?.themes?.theme || getSystemTheme();
    const vars = this.getThemeVars(theme);
    await setTheme(theme as Themes, vars);
    this.settings.theme = theme as Themes;
  }

  async toggleListView(displayType: DisplayType) {
    this.settings.ui.list_view = displayType;
    localStorageModule.syncToStorageByName(ApplicationModule.Name);
    userStorageModule.syncToStorageByName(ApplicationModule.Name);
  }
}

export const applicationModule: ApplicationModule = reactive(new ApplicationModule());

export function initApplicationModule() {
  localStorageModule.registerInstance({ instance: applicationModule, name: ApplicationModule.Name, tokens: ApplicationModule.StoredItems });
  localStorageModule.syncFromStorageByName(ApplicationModule.Name);
  localStorageModule.syncToStorageByName(ApplicationModule.Name);

  userStorageModule.registerInstance({ instance: applicationModule, name: ApplicationModule.Name, tokens: ApplicationModule.StoredItems });
  userStorageModule.syncFromStorageByName(ApplicationModule.Name);
  userStorageModule.syncToStorageByName(ApplicationModule.Name);

  applicationModule.initTheme();
}
