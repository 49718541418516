import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-attachments" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NAttachments = _resolveComponent("NAttachments")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.attachments.length)
      ? (_openBlock(), _createBlock(_component_NAttachments, {
          key: 0,
          attachments: _ctx.attachments,
          class: "card-item__upload",
          "disallow-attach": "",
          "disallow-remove": "",
          "hide-amount": ""
        }, null, 8, ["attachments"]))
      : _createCommentVNode("", true)
  ]))
}