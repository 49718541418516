import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "case-header" }
const _hoisted_2 = { class: "case-header__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_EditCasePage = _resolveComponent("EditCasePage")!
  const _component_EmptyCasePage = _resolveComponent("EmptyCasePage")!
  const _component_ListPage = _resolveComponent("ListPage")!

  return (_openBlock(), _createBlock(_component_ListPage, {
    state: _ctx.state,
    "onUpdate:scrollBottom": _ctx.scrollBottomHandler,
    statistics: false
  }, {
    filters: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_NButtonGroup, {
          items: _ctx.statusFilterItems,
          modelValue: _ctx.module.filter.current.status,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.module.filter.current.status) = $event))
        }, null, 8, ["items", "modelValue"]),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.selectedItems.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.modelAcl.update)
                  ? (_openBlock(), _createBlock(_component_NButton, {
                      key: 0,
                      label: _ctx.$t('common.open_all_selected', 'f'),
                      onAction: _ctx.openAll,
                      type: "secondary"
                    }, null, 8, ["label", "onAction"]))
                  : _createCommentVNode("", true),
                (_ctx.modelAcl.update)
                  ? (_openBlock(), _createBlock(_component_NButton, {
                      key: 1,
                      label: _ctx.$t('common.archive_all_selected', 'f'),
                      onAction: _ctx.archiveAll,
                      type: "secondary"
                    }, null, 8, ["label", "onAction"]))
                  : _createCommentVNode("", true),
                (_ctx.modelAcl.update)
                  ? (_openBlock(), _createBlock(_component_NButton, {
                      key: 2,
                      label: _ctx.$t('common.delete_all_selected', 'f'),
                      onAction: _ctx.deleteAll,
                      type: "secondary"
                    }, null, 8, ["label", "onAction"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_component_NInput, {
            class: "case-header__search",
            placeholder: _ctx.$t('common.search', 'f'),
            modelValue: _ctx.module.filter.current.name_contains,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.module.filter.current.name_contains) = $event)),
            clearable: ""
          }, null, 8, ["placeholder", "modelValue"]),
          _createVNode(_component_NButton, {
            icon: "add",
            onClick: _ctx.addNew,
            disabled: !_ctx.modelAcl.add
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('cases.new_case', 'f')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ])
      ])
    ]),
    items: _withCtx(() => [
      _createVNode(_component_NTable, {
        class: "cases-table",
        columns: _ctx.columns,
        content: _ctx.module.items,
        "selected-items-ids": _ctx.selectedItemIds,
        onSort: _ctx.handleTableSort,
        onCellClick: _ctx.cellClickHandler
      }, null, 8, ["columns", "content", "selected-items-ids", "onSort", "onCellClick"])
    ]),
    overlay: _withCtx(() => [
      (_ctx.newItem)
        ? (_openBlock(), _createBlock(_component_EditCasePage, {
            key: 0,
            saving: _ctx.module.saving,
            item: _ctx.newItem,
            onSave: _ctx.saveNew,
            onClose: _ctx.clearNewItem
          }, null, 8, ["saving", "item", "onSave", "onClose"]))
        : (_ctx.showNewCase)
          ? (_openBlock(), _createBlock(_component_EmptyCasePage, {
              key: 1,
              onCreate: _ctx.addNew
            }, null, 8, ["onCreate"]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["state", "onUpdate:scrollBottom"]))
}