import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cluster-event-item__container" }
const _hoisted_2 = { class: "cluster-event-item__info" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "label-mono-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_CameraLabels = _resolveComponent("CameraLabels")!
  const _component_Features = _resolveComponent("Features")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NThumbnail, {
      class: "cluster-event-item__image",
      modelValue: _ctx.item.thumbnail,
      actions: _ctx.actions,
      defaultAction: _ctx.defaultAction,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.$emit('action', v, _ctx.item))
    }, null, 8, ["modelValue", "actions", "defaultAction"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.id')) + ": " + _toDisplayString(_ctx.item.id), 1),
      (_ctx.item.episode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.$t('common.episode_id', 'f')) + ": ", 1),
            _createVNode(_component_NButton, {
              type: "link",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showEpisode(_ctx.item.episode)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.episode), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_CameraLabels, {
        "camera-groups-ids": _ctx.cameraGroupsIds,
        "cameras-ids": _ctx.camerasIds,
        "video-archive-id": _ctx.item.video_archive,
        "has-play": _ctx.hasPlay,
        onAction: _cache[2] || (_cache[2] = (v, payload) => _ctx.handleCameraAction(v, payload))
      }, null, 8, ["camera-groups-ids", "cameras-ids", "video-archive-id", "has-play"]),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$filters.formatDateTime(_ctx.item.created_date)), 1)
      ])
    ]),
    (_ctx.item.attributes)
      ? (_openBlock(), _createBlock(_component_Features, {
          key: 0,
          class: "cluster-event-item__attributes",
          objects: _ctx.objectType,
          features: _ctx.item.attributes
        }, null, 8, ["objects", "features"]))
      : _createCommentVNode("", true)
  ]))
}