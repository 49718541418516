import NFilterAgeRange from '@/components/common/filter/controls/FilterAgeRange.vue';
import FilterAngleRange from '@/components/common/filter/controls/FilterAngleRange.vue';
import FilterCarModel from '@/components/common/filter/controls/FilterCarModel.vue';
import NFilterDateRange from '@/components/common/filter/controls/FilterDateRange.vue';
import NFilterFormDivider from '@/components/common/filter/controls/FilterFormDivider.vue';
import NFilterRange from '@/components/common/filter/controls/FilterRange.vue';
import FilterReturnIntervalSelect from '@/components/common/filter/controls/FilterReturnIntervalSelect.vue';
import FilterSmallInput from '@/components/common/filter/controls/FilterSmallInput.vue';
import FilterSmallRange from '@/components/common/filter/controls/FilterSmallRange.vue';
import InputIds from '@/components/counters/InputIds.vue';
import CardSelect from '@/pages/external-search/requests/CardSelect.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { AnyFunction, IFormContext, IFormLayoutItem, IFormModel } from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';
import NSelect from '@/uikit/select/NSelect.vue';
import FilterSmallDataRange from '@/components/common/filter/controls/FilterSmallDataRange.vue';
import FilterSmallAgeRange from '@/components/common/filter/controls/FilterSmallAgeRange.vue';
import { NButtonTypes, NDateInput } from '@/uikit';
import NFilterSmallDateTime from '@/components/common/filter/controls/FilterSmallDataTime.vue';
import FilterSmallCheckbox from '@/components/common/filter/controls/FilterSmallCheckbox.vue';
import { formatDate, formatTimeHHMM } from '@/common/filters';
import FilterSmallAngleRange from '@/components/common/filter/controls/FilterSmallAngleRange.vue';
import { dataModule } from '@/store/data';
import { mapObjectToSelectItem } from '@/common/utilsFilterSchema';
import { Camera } from '@/api';
import { NButtonGroupTypes } from '@/uikit/buttons/types';
import FilterConfidence from '@/components/common/filter/controls/FilterConfidence.vue';
import { encodeFormItemName } from '@/components/common/filter/filters/utils';
import { DateTimeFormats, EmitTypes } from '@/uikit/datetime/types';

export type OptionalIFormLayout = IFormLayoutItem[] | IFormLayoutItem | undefined;

export type SimpleFilterOptions = {
  small?: boolean;
  labeled?: boolean;
  readonly?: boolean;
  path?: string;

  name?: string;
  classes?: string;
  items?: any[];
  closeIcon?: boolean;
  clearIcon?: boolean;
  label?: string;
  i18n_label?: string;
  i18n_placeholder?: string;
  i18n_tooltip?: string;

  disabled?: boolean;
  multiple?: boolean;
  anyItem?: boolean;
  cardType?: string;

  checkForEmpty?: AnyFunction;
};

export type LteOrGteObject = {
  fieldName: string;
  min: number;
  max: number;
};

export function configSelectSmall(options: SimpleFilterOptions, loadItems?: any, encode?: any, decode?: any): IFormLayoutItem {
  return {
    name: options.name,
    component: NSelect,
    classes: options.classes,
    path: options.path,
    checkForEmpty: options.checkForEmpty,
    encode,
    decode,
    props: {
      hideChevron: true,
      closeIcon: false,
      clearIcon: options.clearIcon ?? true,
      i18n_label: options.labeled ? options.i18n_label : undefined,
      label: options.label,
      selectonly: true,
      checkCurrent: true,
      i18n_placeholder: options.i18n_placeholder,
      items: options.anyItem ? addAnyItemToArray(options.items!) : options.items,
      readonly: options.readonly,
      disabled: options.disabled,
      multiple: options.multiple,
      loadItems
    }
  };
}

export function configSelect(options: SimpleFilterOptions, loadItems?: any): IFormLayoutItem {
  return {
    component: NSelect,
    classes: options.classes || 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    path: options.path,
    label: options.label,
    i18n_label: options.i18n_label,
    props: {
      selectonly: true,
      i18n_placeholder: options.i18n_placeholder,
      items: options.items,
      readonly: options.readonly,
      disabled: options.disabled,
      multiple: options.multiple,
      loadItems
    }
  };
}

export function configSelectAsTagsSmall(options: SimpleFilterOptions, loadItems?: any, handlers?: any): IFormLayoutItem {
  return {
    component: NSelect,
    name: options.name,
    path: options.path,
    on: handlers,
    classes: options.classes,
    checkForEmpty: options.checkForEmpty,
    props: {
      handlers,
      hideChevron: true,
      clearIcon: true,
      closeIcon: false,
      readonly: options.readonly ?? false,
      multiple: true,
      i18n_label: options.i18n_label,
      items: options.items,
      loadItems
    }
  };
}

export function configSelectAsTags(options: SimpleFilterOptions, loadItems?: any, handlers?: any): IFormLayoutItem {
  return {
    component: NSelect,
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    path: options.path,
    i18n_label: options.i18n_label,
    on: handlers,
    props: {
      multiple: true,
      multiselect: true,
      multiline: true,
      i18n_placeholder: options.i18n_placeholder,
      items: options.items,
      loadItems
    }
  };
}

export function configSelectAsTagsForCameras(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    component: NSelect,
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    path: options.path,
    i18n_label: options.i18n_label,
    props: function (this: IFormContext, model: IFormModel) {
      const cameraGroups = this.model.camera_groups || [];
      const items = getCamerasByCameraGroups(cameraGroups);
      const result = {
        multiple: true,
        multiselect: true,
        multiline: true,
        i18n_placeholder: options.i18n_placeholder,
        items: mapObjectToSelectItem(items)
      };
      return result;
    }
  };
}

export function configSelectAsTagsSmallForCameras(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    component: NSelect,
    name: options.name,
    path: options.path,
    props: function (this: IFormContext, model: IFormModel) {
      const cameraGroups = this.model.camera_groups || [];
      const items = getCamerasByCameraGroups(cameraGroups);
      const result = {
        hideChevron: true,
        clearIcon: true,
        closeIcon: false,
        readonly: options.readonly ?? false,
        multiple: true,
        i18n_label: options.i18n_label,
        items: mapObjectToSelectItem(items)
      };
      return result;
    }
  };
}

export function configButtonGroup(options: SimpleFilterOptions, encode?: any, decode?: any): IFormLayoutItem {
  return {
    name: options.name,
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    component: NButtonGroup,
    path: options.path,
    i18n_label: options.i18n_label,
    encode,
    decode,
    props: {
      type: NButtonGroupTypes.Separated,
      buttonType: NButtonTypes.Outline,
      multiple: options.multiple,
      multiline: true,
      showAnyButton: options.anyItem,
      items: options.items
    }
  };
}

export function configCheckbox(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    classes: options.classes || 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    component: NCheckbox,
    path: options.path,
    label: options.label,
    i18n_label: options.i18n_label,
    props: {}
  };
}

export function configDateTime(options: SimpleFilterOptions, timeEnabled: boolean = true): IFormLayoutItem {
  return {
    name: options.name,
    path: options.path,
    classes: options.classes || 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    component: NDateInput,
    i18n_label: options.i18n_label,
    label: options.label,
    props: {
      timeEnabled,
      emitType: timeEnabled ? EmitTypes.DateObject : EmitTypes.String,
      emitStringFormat: timeEnabled ? DateTimeFormats.ISO : DateTimeFormats.dashedDate,
      datePlaceholder: formatDate(new Date(0), 'DD.MM.YYYY'),
      timePlaceholder: formatTimeHHMM(new Date(0)),
      disabled: options.disabled
    }
  };
}

export function configSmallDateTime(options: SimpleFilterOptions, timeEnabled: boolean = true): IFormLayoutItem {
  return {
    name: options.name,
    path: options.path,
    component: NFilterSmallDateTime,
    props: {
      timeEnabled,
      emitType: timeEnabled ? EmitTypes.DateObject : EmitTypes.String,
      emitStringFormat: timeEnabled ? DateTimeFormats.ISO : DateTimeFormats.dashedDate,
      i18n_label: options.i18n_label,
      label: options.label,
      disabled: options.disabled
    }
  };
}

export function configSmallCheckbox(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    name: options.name,
    path: options.path,
    component: FilterSmallCheckbox,
    props: {
      i18n_label: options.i18n_label,
      label: options.label,
      disabled: options.disabled
    }
  };
}

export function configDateTimeRangeSmall(options: SimpleFilterOptions, gteProp?: string, lteProp?: string): IFormLayoutItem {
  lteProp = lteProp ?? 'created_date_lte';
  gteProp = gteProp ?? 'created_date_gte';

  return {
    name: options?.name ?? encodeFormItemName([gteProp, lteProp, 'dateTimeRange']),
    dataQa: 'date-time-range',
    component: FilterSmallDataRange,
    checkForEmpty: (model: IFormModel) => !model[gteProp as string] && !model[lteProp as string],
    props: function (model: IFormModel) {
      return {
        ...options,
        i18n_label: options.i18n_label,
        model,
        gteProp,
        lteProp
      };
    }
  };
}

export function configDateTimeRange(options: SimpleFilterOptions, gteProp?: string, lteProp?: string): IFormLayoutItem {
  lteProp = lteProp ?? 'created_date_lte';
  gteProp = gteProp ?? 'created_date_gte';

  return {
    name: options?.name ?? encodeFormItemName([gteProp, lteProp, 'dateTimeRange']),
    dataQa: 'date-time-range',
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    component: NFilterDateRange,
    i18n_label: options.i18n_label,
    props: function (model: IFormModel) {
      return {
        model,
        gteProp,
        lteProp
      };
    }
  };
}

export function configInput(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    component: NInput,
    classes: options.classes || 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    path: options.path,
    label: options.label,
    i18n_label: options.i18n_label,
    i18n_tooltip: options.i18n_tooltip,
    encode: function (this: IFormContext, model: IFormModel, value: string) {
      const result = options.multiple
        ? value
            .split(',')
            .map((v) => v && v.trim())
            .filter((v) => !!v)
        : value;
      this.model[options.path!] = result;
    },
    decode: function (this: IFormContext) {
      const value = this.model[options.path!] ?? '';
      return Array.isArray(value) ? value.join(', ') : value;
    },
    props: {
      i18n_placeholder: options.i18n_placeholder
    }
  };
}

export function configLicensePlateInputSmall(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    component: FilterSmallInput,
    path: options.path,
    encode: function (this: IFormContext, model: IFormModel, value: string) {
      this.model[options.path!] = (value ?? '').replaceAll('?', '%');
    },
    decode: function (this: IFormContext) {
      const value = this.model[options.path!] ?? '';
      return Array.isArray(value) ? value.join(', ').replaceAll('%', '?') : value.replaceAll('%', '?');
    },
    props: {
      i18n_label: options.i18n_label,
      i18n_placeholder: options.i18n_placeholder
    }
  };
}

export function configLicensePlateInput(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    component: NInput,
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    path: options.path,
    i18n_label: options.i18n_label,
    i18n_tooltip: options.i18n_tooltip,
    encode: function (this: IFormContext, model: IFormModel, value: string) {
      this.model[options.path!] = (value ?? '').replaceAll('?', '%');
    },
    decode: function (this: IFormContext) {
      const value = this.model[options.path!] ?? '';
      return Array.isArray(value) ? value.join(', ').replaceAll('%', '?') : value.replaceAll('%', '?');
    },
    props: {
      i18n_placeholder: options.i18n_placeholder
    }
  };
}

export function configInputIds(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    component: InputIds,
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    path: options.path,
    i18n_label: options.i18n_label,
    props: {
      i18n_placeholder: options.i18n_placeholder
    }
  };
}

export function configSmallInput(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    component: FilterSmallInput,
    path: options.path,
    props: {
      i18n_label: options.i18n_label,
      label: options.label
    }
  };
}

export function configMultipleInputSmall(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    component: FilterSmallInput,
    path: options.path,
    encode: function (this: IFormContext, model: IFormModel, value: string) {
      const result = options.multiple
        ? value
            .split(',')
            .map((v) => v && v.trim())
            .filter((v) => !!v)
        : value;
      this.model[options.path!] = result;
    },
    decode: function (this: IFormContext) {
      const value = this.model[options.path!] ?? '';
      return Array.isArray(value) ? value.join(', ') : value;
    },
    props: {
      i18n_label: options.i18n_label
    }
  };
}

export function configCardSelectSmall(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    classes: 'heading-m n-form-item_fill ',
    component: CardSelect,
    path: options.path,
    props: {
      i18n_label: options.i18n_label,
      i18n_placeholder: options.i18n_placeholder,
      cardType: options.cardType,
      multiple: options.multiple
    }
  };
}

export function configCardSelect(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    component: CardSelect,
    path: options.path,
    i18n_label: options.i18n_label,
    props: {
      i18n_placeholder: options.i18n_placeholder,
      cardType: options.cardType,
      multiple: options.multiple
    }
  };
}

export function configCardInput(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    component: NInput,
    path: options.path,
    i18n_label: options.i18n_label,
    props: {
      i18n_placeholder: options.i18n_placeholder,
      cardType: options.cardType
    }
  };
}

export function configureDivider(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    name: options.name || '',
    classes: 'n-form-divider ' + (options.classes || 'heading-xl'),
    component: NFilterFormDivider,
    i18n_label: options.i18n_label,
    checkForEmpty: options.checkForEmpty
  };
}

export function configureAge(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    name: 'age',
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    component: NFilterAgeRange,
    i18n_label: options.i18n_label,
    props: function (model: IFormModel) {
      return {
        model
      };
    }
  };
}

export function configureSmallAge(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    name: 'age',
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-label-text-align-right',
    component: FilterSmallAgeRange,
    checkForEmpty: (model: IFormModel) => !model['age_gte'] && !model['age_lte'],
    props: function (model: IFormModel) {
      return {
        i18n_label: options.i18n_label,
        model,
        gteFieldName: 'age_gte',
        lteFieldName: 'age_lte'
      };
    }
  };
}

export function configureAngle(options: SimpleFilterOptions, gteFieldName: string, lteFieldName: string): IFormLayoutItem {
  return {
    name: options.name,
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    component: FilterAngleRange,
    i18n_label: options.i18n_label,
    props: function (model: IFormModel) {
      return {
        model,
        gteFieldName,
        lteFieldName
      };
    },
    validators: [
      {
        handler: (context: any) => {
          const gte = context.model[gteFieldName];
          const lte = context.model[lteFieldName];
          return Number.isFinite(gte) && Number.isFinite(lte) ? gte <= lte : true;
        },
        i18n_message: 'events.angle_range_is_invalid'
      }
    ]
  };
}

export function configureSmallAngle(options: SimpleFilterOptions, gteFieldName: string, lteFieldName: string): IFormLayoutItem {
  return {
    name: options.name,
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-label-text-align-right',
    component: FilterSmallAngleRange,
    checkForEmpty: (model: IFormModel) => !model[gteFieldName] && !model[lteFieldName],
    props: function (model: IFormModel) {
      return {
        i18n_label: options.i18n_label,
        model,
        gteFieldName,
        lteFieldName,
        validators: [
          {
            handler: (context: any) => {
              const gte = context.model[gteFieldName];
              const lte = context.model[lteFieldName];
              return Number.isFinite(gte) && Number.isFinite(lte) ? gte <= lte : true;
            },
            i18n_message: 'events.angle_range_is_invalid'
          }
        ]
      };
    }
  };
}

export function configureRange(
  options: SimpleFilterOptions,
  gteObject: LteOrGteObject,
  lteObject: LteOrGteObject,
  decode?: (v: any) => any,
  entcode?: (v: any) => any
): IFormLayoutItem {
  return {
    name: encodeFormItemName([gteObject.fieldName, 'Range']),
    classes: options.small ? '' : 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    component: options.small ? FilterSmallRange : NFilterRange,
    i18n_label: options.small ? undefined : options.i18n_label,
    checkForEmpty: (model: IFormModel) => !model[gteObject.fieldName] && !model[lteObject.fieldName],
    props: function (model: IFormModel) {
      return {
        i18n_label: options.small ? options.i18n_label : undefined,
        model,
        gteObject,
        lteObject,
        decode,
        entcode
      };
    }
  };
}

export function configureConfidence(options: SimpleFilterOptions) {
  return {
    path: options.path || 'confidence_gte',
    classes: options.classes,
    component: FilterConfidence,
    i18n_label: options.i18n_label,
    checkForEmpty: options.checkForEmpty,
    i18n_tooltip: 'common.confidence_filters_desc',
    props: {
      i18n_placeholder: options.i18n_placeholder
    }
  };
}

export function configureConfidenceRange(
  options: SimpleFilterOptions,
  gteObject: LteOrGteObject,
  lteObject: LteOrGteObject,
  decode?: (v: any) => any,
  entcode?: (v: any) => any
): IFormLayoutItem {
  return {
    name: options.name ?? encodeFormItemName([gteObject.fieldName, lteObject.fieldName, 'ConfidenceRange']),
    classes: options.classes,
    component: options.small ? FilterSmallRange : NFilterRange,
    checkForEmpty: (model: IFormModel) => !model[gteObject.fieldName] && !model[lteObject.fieldName] && options.checkForEmpty?.(model),
    i18n_tooltip: 'common.confidence_filters_desc',
    props: function (model: IFormModel) {
      return {
        i18n_label: options.small ? options.i18n_label : undefined,
        model,
        gteObject,
        lteObject,
        decode,
        entcode
      };
    }
  };
}

export function configCarModel(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    component: FilterCarModel,
    path: options.path,
    name: options.name,
    i18n_label: options.i18n_label,
    props: function (model: IFormModel) {
      return {
        i18n_placeholder: options.i18n_placeholder,
        currentModel: model
      };
    }
  };
}

export function configSmallCarModel(options: SimpleFilterOptions): IFormLayoutItem {
  return {
    component: FilterCarModel,
    path: options.path,
    name: options.name,
    props: function (model: IFormModel) {
      return {
        i18n_label: options.i18n_label,
        i18n_placeholder: options.i18n_placeholder,
        currentModel: model,
        hideChevron: true,
        clearIcon: true,
        closeIcon: false,
        readonly: options.readonly ?? false,
        multiple: true
      };
    }
  };
}

export function configReturnIntervalSelect(options: SimpleFilterOptions) {
  return {
    classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
    component: FilterReturnIntervalSelect,
    path: options.path,
    i18n_label: options.i18n_label,
    props: function (model: IFormModel) {
      return {
        edgeValue: model.precision,
        selectonly: true,
        i18n_placeholder: options.i18n_placeholder,
        items: options.items,
        readonly: options.readonly
      };
    }
  };
}

export function configReturnIntervalSelectSmall(options: SimpleFilterOptions) {
  return {
    classes: options.classes,
    component: FilterReturnIntervalSelect,
    path: options.path,
    props: function (model: IFormModel) {
      return {
        edgeValue: model.precision,
        selectonly: true,
        hideChevron: true,
        i18n_label: options.labeled && options.i18n_label,
        i18n_placeholder: options.i18n_placeholder,
        items: options.items,
        readonly: options.readonly
      };
    }
  };
}

function addAnyItemToArray(items: any[]) {
  return [{ value: undefined, i18n_label: 'common.any' }, ...items];
}

function getCamerasByCameraGroups(cameraGroups: number[]): Camera[] {
  return cameraGroups.length ? dataModule.camerasModule.items.filter((v) => cameraGroups.includes(v.group)) : dataModule.camerasModule.items;
}
