/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CameraGroup } from '../models/CameraGroup';
import type { CameraGroupRequest } from '../models/CameraGroupRequest';
import type { CountResponse } from '../models/CountResponse';
import type { PaginatedCameraGroupList } from '../models/PaginatedCameraGroupList';
import type { PatchedCameraGroupRequest } from '../models/PatchedCameraGroupRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class CameraGroupsService {
    /**
     * List camera groups
     * Use this method to list camera groups.
     * @param limit Number of results to return.
     * @param ordering Which field to use when ordering the results. Available fields: `id`, `created_date`, `modified_date`
     * @returns PaginatedCameraGroupList
     * @throws ApiError
     */
    public static cameraGroupsList(
        limit?: number,
        ordering?: string,
    ): CancelablePromise<PaginatedCameraGroupList> {
        return __request({
            method: 'GET',
            path: `/camera-groups/`,
            query: {
                'limit': limit,
                'ordering': ordering,
            },
        });
    }
    /**
     * Add a new camera group
     * @param requestBody
     * @returns CameraGroup
     * @throws ApiError
     */
    public static cameraGroupsCreate(
        requestBody: CameraGroupRequest,
    ): CancelablePromise<CameraGroup> {
        return __request({
            method: 'POST',
            path: `/camera-groups/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve camera group by ID
     * @param id A unique integer value identifying this Camera groups.
     * @returns CameraGroup
     * @throws ApiError
     */
    public static cameraGroupsRetrieve(
        id: number,
    ): CancelablePromise<CameraGroup> {
        return __request({
            method: 'GET',
            path: `/camera-groups/${id}/`,
        });
    }
    /**
     * Update camera group properties
     * @param id A unique integer value identifying this Camera groups.
     * @param requestBody
     * @returns CameraGroup
     * @throws ApiError
     */
    public static cameraGroupsPartialUpdate(
        id: number,
        requestBody?: PatchedCameraGroupRequest,
    ): CancelablePromise<CameraGroup> {
        return __request({
            method: 'PATCH',
            path: `/camera-groups/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete camera group
     * @param id A unique integer value identifying this Camera groups.
     * @returns void
     * @throws ApiError
     */
    public static cameraGroupsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/camera-groups/${id}/`,
        });
    }
    /**
     * Objects count
     * This method retrieves count of objects.
     * @returns CountResponse
     * @throws ApiError
     */
    public static cameraGroupsCountRetrieve(): CancelablePromise<CountResponse> {
        return __request({
            method: 'GET',
            path: `/camera-groups/count/`,
        });
    }
}