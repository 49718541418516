import { throwError } from '@/uikit/dropdown/helpers/common';
import { AxiosError, AxiosInstance } from 'axios';
import { License, LicenseUsage } from '../types';
import { LicenseImportResult, LicenseService } from './types';

export class LicenseServiceImpl implements LicenseService {
  constructor(private readonly axios: AxiosInstance) {}

  fetchLicense(): Promise<License> {
    return this.axios.get<License>('ntls/license.json').then(({ data }) => data);
  }

  fetchLicenseUsage(): Promise<LicenseUsage> {
    return this.axios.get<LicenseUsage>('ntls/usage-report.json').then(({ data }) => data);
  }

  async importLicense(license: File) {
    const buf = await license.arrayBuffer();
    return this.axios
      .post<LicenseImportResult>('ntls/import/', buf, {
        headers: {
          'Content-Length': buf.byteLength,
          'Content-Type': 'application/octet-stream'
        }
      })
      .then(({ data }) => data)
      .catch((e: AxiosError) => e.response?.data ?? throwError('Unable to import the license file.'));
  }

  downloadC2V(): Promise<string> {
    return this.axios.get<string>('ntls/c2v').then(({ data }) => {
      const content = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);
      const link = document.createElement('a');
      link.setAttribute('href', content);
      link.setAttribute('download', 'license.c2v');
      document.body.appendChild(link);
      link.click();
      return data;
    });
  }
}
