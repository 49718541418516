import { PatchedSettingsRequest, Settings, SettingsService } from '@/api';
import { EmptySettings } from '@/api/models/Settings';
import cloneDeep from 'lodash/cloneDeep';
import { reactive } from 'vue';

export class SettingsItemModule {
  item?: Settings;
  loaded = false;

  async get(): Promise<void> {
    try {
      this.item = await SettingsService.settingsRetrieve();
    } catch {
      this.item = retrieveDefaultSettings();
    } finally {
      this.loaded = true;
    }
  }

  async save() {
    this.item = await SettingsService.settingsPartialUpdate(this.item);
  }

  async saveFields(fields: Partial<PatchedSettingsRequest>) {
    this.item = await SettingsService.settingsPartialUpdate(fields);
  }
}

function retrieveDefaultSettings(): Settings {
  return cloneDeep(EmptySettings);
}

export const settingsItemModule = reactive<SettingsItemModule>(new SettingsItemModule());
