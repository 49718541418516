
import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, reactive, unref, watch } from 'vue';
import NImageViewer from '../image-viewer/NImageViewer.vue';
import NButton from '../buttons/NButton.vue';
import { ArrowType, ArrowTypes, ImageGalleryItem } from '../image-viewer/types';

export default defineComponent({
  name: 'NImageGallery',
  components: { NImageViewer, NButton },
  emits: ['close', 'show-items'],
  props: {
    items: { type: Array as PropType<ImageGalleryItem[]>, required: true },
    activeItemIndex: { type: Number, default: 0 },
    zoomable: { type: Boolean, default: true },
    draggable: { type: Boolean, default: true },
    bboxSelectable: { type: Boolean, default: true },
    arrowsType: { type: String as PropType<ArrowType>, default: ArrowTypes.Middle },
    itemsButton: { type: Boolean, default: false },
    errorMessage: { type: String },
    actions: { type: Array as PropType<string[]> }
  },
  setup(props, { emit }) {
    const state = reactive({
      activeItemIndex: props.activeItemIndex ?? 0
    });
    const currentItem = computed(() => props.items[state.activeItemIndex]);
    const hasArrows = computed(() => props.items.length > 1);
    const hasLeftTopArrows = computed(() => unref(hasArrows) && props.arrowsType === ArrowTypes.TopLeft);
    const hasMiddleArrows = computed(() => unref(hasArrows) && props.arrowsType === ArrowTypes.Middle);

    watch(
      () => props.activeItemIndex,
      () => (state.activeItemIndex = props.activeItemIndex)
    );

    function next() {
      state.activeItemIndex = state.activeItemIndex === props.items.length - 1 ? 0 : state.activeItemIndex + 1;
    }

    function prev() {
      state.activeItemIndex = state.activeItemIndex === 0 ? props.items.length - 1 : state.activeItemIndex - 1;
    }

    onMounted(bindArrowsHandler);
    function bindArrowsHandler() {
      document.addEventListener('keyup', handleArrow);
    }

    onBeforeUnmount(unbindArrowsHandler);
    function unbindArrowsHandler() {
      document.removeEventListener('keyup', handleArrow);
    }

    function handleArrow(e: KeyboardEvent) {
      if (e.key === 'ArrowLeft' && unref(hasArrows)) prev();
      if (e.key === 'ArrowRight' && unref(hasArrows)) next();
    }

    return {
      ...props,
      emit,
      currentItem,
      hasMiddleArrows,
      hasLeftTopArrows,
      prev,
      next
    };
  }
});
