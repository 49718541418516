import { CarCard, HumanCard } from '@/api';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { error } from 'loglevel';

type Card = HumanCard;

export interface EnrichedCard extends Card {
  object?: any;
  faceObject?: any;
}

export default async function enrichCards(cards: Card[], previous: EnrichedCard[] = [], objectType: string = 'faces'): Promise<EnrichedCard[]> {
  const ids = cards.map((card) => card.id).filter((id) => id);
  const qs = ids.map((id) => `card=${id}`).join('&');

  let objects: any = [];
  try {
    const response = ids.length ? await dataServiceFactory.getService('/objects/' + objectType).get(`?${qs}&limit=1000#fix`) : { results: [] };
    objects = response.results;
  } catch (e) {
    console.error('[enrich cards]: ' + e);
  }

  return cards.map((card) => {
    const object = objects.find((item: any) => item.card === card.id);
    return {
      ...card,
      faceObject: object,
      object: object
    };
  });
}
