import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoWallCameraStatus = _resolveComponent("VideoWallCameraStatus")!

  return (_openBlock(), _createElementBlock("li", {
    onDragstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDragStartEvent && _ctx.handleDragStartEvent(...args))),
    class: "label-m video-wall-camera-list-group-item",
    draggable: "true"
  }, [
    _createVNode(_component_VideoWallCameraStatus, {
      status: _ctx.camera.health_status.code
    }, null, 8, ["status"]),
    _createElementVNode("div", {
      class: "video-wall-camera-list-group-item__name",
      textContent: _toDisplayString(_ctx.camera.name)
    }, null, 8, _hoisted_1)
  ], 32))
}