export type ICredentials = { login: string; password: string };

export function isObject(candidate: unknown) {
  return candidate != null && typeof candidate === 'object';
}

export function isString(candidate: unknown) {
  return candidate != null && typeof candidate === 'string';
}

export function isFunction(candidate: unknown) {
  return candidate != null && typeof candidate === 'function';
}

export function isApiError(e: Error) {
  return e && e.name === 'ApiError';
}
