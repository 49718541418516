
import { defineComponent, toRefs } from 'vue';
import { arrayOf, func, number, object, oneOfType, string } from 'vue-types';
import type { NTableCell, NTableSectionSchema } from '../types';
import { useTableSectionViewModel } from './setup';

export default defineComponent({
  name: 'NTableSection',
  props: {
    rows: defineRowsProp(),
    schemas: defineSchemasProp()
  },
  emits: ['cell-click'],
  setup: (props, { emit }) => {
    const { rows, schemas } = toRefs(props);
    return useTableSectionViewModel(rows, schemas, (cell) => emit('cell-click', cell));
  }
});

function defineRowsProp() {
  return arrayOf(arrayOf(object<NTableCell>())).isRequired;
}

function defineSchemasProp() {
  return arrayOf(oneOfType<NTableSectionSchema<NTableCell>>([func(), number(), object(), string()])).isRequired;
}
