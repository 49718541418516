export type NSelectItemName = string | number | null | undefined;
export type NSelectModelValue = ISelectItem | ISelectItem[] | NSelectItemName | NSelectItemName[] | string | string[] | undefined;

export interface ISelectItem {
  value: NSelectItemName;
  label: string;
  icon?: string;
  color?: string;
  checked?: boolean;
  disabled?: boolean;
  i18n_label?: string;
}

export type NSelectItemsWidth = 'auto' | 'target' | number;

export const NSelectItemsWidths = {
  Auto: 'auto',
  Target: 'target'
};
