/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedUploadListList } from '../models/PaginatedUploadListList';
import type { PatchedUploadListRequest } from '../models/PatchedUploadListRequest';
import type { UploadList } from '../models/UploadList';
import type { UploadListRequest } from '../models/UploadListRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class BatchUploadService {
    /**
     * List upload lists
     * Use this method to list uploads.
     * @param limit Number of results to return per page.
     * @param page Pagination cursor value.
     * @returns PaginatedUploadListList 
     * @throws ApiError
     */
    public static batchUploadList(
limit?: string,
page?: string,
): CancelablePromise<PaginatedUploadListList> {
        return __request({
            method: 'GET',
            path: `/batch-upload/`,
            query: {
                'limit': limit,
                'page': page,
            },
        });
    }
    /**
     * Add a new upload list
     * Use this method to add a new upload list.
     * @param requestBody 
     * @returns UploadList 
     * @throws ApiError
     */
    public static batchUploadCreate(
requestBody?: UploadListRequest,
): CancelablePromise<UploadList> {
        return __request({
            method: 'POST',
            path: `/batch-upload/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve upload list by ID
     * Use this method to retrieve an upload list by its ID.
     * @param id A unique integer value identifying this Список выгрузок.
     * @returns UploadList 
     * @throws ApiError
     */
    public static batchUploadRetrieve(
id: number,
): CancelablePromise<UploadList> {
        return __request({
            method: 'GET',
            path: `/batch-upload/${id}/`,
        });
    }
    /**
     * Update upload list properties
     * Use this method to update an upload list properties.
     * @param id A unique integer value identifying this Список выгрузок.
     * @param requestBody 
     * @returns UploadList 
     * @throws ApiError
     */
    public static batchUploadPartialUpdate(
id: number,
requestBody?: PatchedUploadListRequest,
): CancelablePromise<UploadList> {
        return __request({
            method: 'PATCH',
            path: `/batch-upload/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete upload list
     * Use this method to delete an upload list.
     * @param id A unique integer value identifying this Список выгрузок.
     * @returns void 
     * @throws ApiError
     */
    public static batchUploadDestroy(
id: number,
): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/batch-upload/${id}/`,
        });
    }
    /**
     * Download upload list as csv.
     * Use this method to download upload list as csv..
     * @param id A unique integer value identifying this Список выгрузок.
     * @param secretKey Secret key for this upload list
     * @returns string 
     * @throws ApiError
     */
    public static batchUploadCsvRetrieve(
id: number,
secretKey: string,
): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/batch-upload/${id}/csv/`,
            query: {
                'secret_key': secretKey,
            },
        });
    }
}