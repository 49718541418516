import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video-wall-page-sidebar-section" }
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", {
      class: "heading-l video-wall-page-sidebar-section__header",
      textContent: _toDisplayString(_ctx.header)
    }, null, 8, _hoisted_2),
    _renderSlot(_ctx.$slots, "default")
  ]))
}