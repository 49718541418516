/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ClusterEventsBodiesFilter = {
    /**
     * Select objects where feature `bag_back` takes one of these values
     */
    bag_back?: Array<string>;
    /**
     * Select objects where feature `bag_ground` takes one of these values
     */
    bag_ground?: Array<string>;
    /**
     * Select objects where feature `bag_hand` takes one of these values
     */
    bag_hand?: Array<string>;
    /**
     * Select objects where feature `color` takes one of these values
     */
    bottom_color?: Array<string>;
    /**
     * Select objects related to this `camera group`
     */
    camera_groups?: Array<number>;
    /**
     * Select objects related to this `camera`.
     */
    cameras?: Array<number>;
    /**
     * Select objects where the `cluster` id takes one of these values
     */
    cluster_in?: Array<number>;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select objects where feature `detailed_upper_clothes` takes one of these values
     */
    detailed_upper_clothes?: Array<string>;
    /**
     * Select objects related to this `episode`
     */
    episodes?: Array<number>;
    /**
     * `true` - select only cluster events with matches. `false` - select only cluster events without matches.
     */
    has_matches?: number;
    /**
     * Select objects where feature `headwear` takes one of these values
     */
    headwear?: Array<string>;
    /**
     * Select cluster events with provided ids
     */
    id_in?: Array<number>;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select objects where feature `lower_clothes` takes one of these values
     */
    lower_clothes?: Array<string>;
    /**
     * Select objects that match this card
     */
    matched_card?: number;
    /**
     * Select objects that match a card included in these watch lists
     */
    matched_lists?: Array<number>;
    /**
     * Which field to use when ordering the results. Available fields: `id`, `created_date`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select objects where feature `top_color` takes one of these values
     */
    top_color?: Array<string>;
    /**
     * Select objects where feature `upper_clothes` takes one of these values
     */
    upper_clothes?: Array<string>;
}
export const EmptyClusterEventsBodiesFilter: ClusterEventsBodiesFilter = {
    bag_back: [],
    bag_ground: [],
    bag_hand: [],
    bottom_color: [],
    camera_groups: [],
    cameras: [],
    cluster_in: [],
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    detailed_upper_clothes: [],
    episodes: [],
    has_matches: undefined,
    headwear: [],
    id_in: [],
    limit: '20',
    lower_clothes: [],
    matched_card: undefined,
    matched_lists: [],
    ordering: undefined,
    page: undefined,
    top_color: [],
    upper_clothes: [],
}