
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NIcon from '../icons/NIcon.vue';

export const NTagType = {
  Common: 'common',
  Focus: 'focus',
  Pink: 'pink'
};

export const NTagLabelSize = {
  M: 'm',
  XS2: '2xs'
};

@Options({
  name: 'NTag',
  components: { NIcon },
  emits: ['action', 'close']
})
export default class NTag extends Vue {
  @Prop({ type: String, default: NTagType.Common })
  readonly type!: string;

  @Prop({ type: String, default: '' })
  readonly label!: string;

  @Prop({ type: String, default: NTagLabelSize.M })
  readonly labelSize!: string;

  @Prop({ type: String, default: '' })
  readonly color!: string;

  @Prop({ type: Boolean, default: false })
  readonly closeable?: boolean;

  @Prop({ type: String })
  readonly dataQa?: string;

  handleClick() {
    this.$emit('action');
  }

  handleClose() {
    this.$emit('close');
  }
}
