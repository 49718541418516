/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedDailySearchEventList } from '../models/PaginatedDailySearchEventList';
import type { PaginatedRemoteMonitoringEventList } from '../models/PaginatedRemoteMonitoringEventList';
import type { PaginatedRemoteMonitoringRecordList } from '../models/PaginatedRemoteMonitoringRecordList';
import type { PaginatedSearchRequestList } from '../models/PaginatedSearchRequestList';
import type { PaginatedSearchResultList } from '../models/PaginatedSearchResultList';
import type { PatchedPatchSearchRequestRequest } from '../models/PatchedPatchSearchRequestRequest';
import type { PatchSearchRequest } from '../models/PatchSearchRequest';
import type { RemoteMonitoringEvent } from '../models/RemoteMonitoringEvent';
import type { RemoteMonitoringRecord } from '../models/RemoteMonitoringRecord';
import type { RemoteMonitoringRecordRequest } from '../models/RemoteMonitoringRecordRequest';
import type { SearchRequest } from '../models/SearchRequest';
import type { SearchRequestRequest } from '../models/SearchRequestRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class PuppeteerService {
    /**
     * List daily search events
     * @param camera Select only events with exact camera
     * @param card Select daily search events associated with the specified card
     * @param confidenceGte Select events with `confidence` **greater than or equal** to this value
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param eventTimestampGte Select events with `external_timestamp` **greater than or equal** to this value
     * @param eventTimestampLte Select events with `external_timestamp` **less than or equal** to this value
     * @param faceObject Select only events with exact face object id
     * @param limit Number of results to return per page.
     * @param ordering Which field to use when ordering the results. Available fields: `created_date`, `id`
     * @param page Pagination cursor value.
     * @param puppetId Select only events with exact puppet id
     * @returns PaginatedDailySearchEventList
     * @throws ApiError
     */
    public static puppeteerDailyEventsList(
        camera?: string,
        card?: number,
        confidenceGte?: number,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        eventTimestampGte?: string,
        eventTimestampLte?: string,
        faceObject?: string,
        limit?: string,
        ordering?: string,
        page?: string,
        puppetId?: string,
    ): CancelablePromise<PaginatedDailySearchEventList> {
        return __request({
            method: 'GET',
            path: `/puppeteer/daily/events/`,
            query: {
                'camera': camera,
                'card': card,
                'confidence_gte': confidenceGte,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'event_timestamp_gte': eventTimestampGte,
                'event_timestamp_lte': eventTimestampLte,
                'face_object': faceObject,
                'limit': limit,
                'ordering': ordering,
                'page': page,
                'puppet_id': puppetId,
            },
        });
    }
    /**
     * List remote monitoring records
     * @param card Select monitoring records that card
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param limit Number of results to return per page.
     * @param ordering Which field to use when ordering the results. Available fields: `created_date`, `id`
     * @param page Pagination cursor value.
     * @returns PaginatedRemoteMonitoringRecordList
     * @throws ApiError
     */
    public static puppeteerRemoteMonitoringList(
        card?: number,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        limit?: string,
        ordering?: string,
        page?: string,
    ): CancelablePromise<PaginatedRemoteMonitoringRecordList> {
        return __request({
            method: 'GET',
            path: `/puppeteer/remote-monitoring/`,
            query: {
                'card': card,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'limit': limit,
                'ordering': ordering,
                'page': page,
            },
        });
    }
    /**
     * Add a new remote monitoring record
     * @param requestBody
     * @returns RemoteMonitoringRecord
     * @throws ApiError
     */
    public static puppeteerRemoteMonitoringCreate(
        requestBody: RemoteMonitoringRecordRequest,
    ): CancelablePromise<RemoteMonitoringRecord> {
        return __request({
            method: 'POST',
            path: `/puppeteer/remote-monitoring/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve remote monitoring record by ID
     * @param id A unique integer value identifying this remote monitoring record.
     * @returns RemoteMonitoringRecord
     * @throws ApiError
     */
    public static puppeteerRemoteMonitoringRetrieve(
        id: number,
    ): CancelablePromise<RemoteMonitoringRecord> {
        return __request({
            method: 'GET',
            path: `/puppeteer/remote-monitoring/${id}/`,
        });
    }
    /**
     * Delete remote monitoring record
     * @param id A unique integer value identifying this remote monitoring record.
     * @returns void
     * @throws ApiError
     */
    public static puppeteerRemoteMonitoringDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/puppeteer/remote-monitoring/${id}/`,
        });
    }
    /**
     * List remote monitoring events
     * @param camera Select only events with exact camera
     * @param card Select remote monitoring events associated with the specified card
     * @param confidenceGte Select events with `confidence` **greater than or equal** to this value
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param eventTimestampGte Select events with `external_timestamp` **greater than or equal** to this value
     * @param eventTimestampLte Select events with `external_timestamp` **less than or equal** to this value
     * @param faceObject Select only events with exact face object id
     * @param limit Number of results to return per page.
     * @param monitoringRecords Select events associated with the specified remote monitoring record
     * @param ordering Which field to use when ordering the results. Available fields: `created_date`, `id`
     * @param page Pagination cursor value.
     * @param puppetId Select only events with exact puppet id
     * @returns PaginatedRemoteMonitoringEventList
     * @throws ApiError
     */
    public static puppeteerRemoteMonitoringEventsList(
        camera?: string,
        card?: number,
        confidenceGte?: number,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        eventTimestampGte?: string,
        eventTimestampLte?: string,
        faceObject?: string,
        limit?: string,
        monitoringRecords?: Array<number>,
        ordering?: string,
        page?: string,
        puppetId?: string,
    ): CancelablePromise<PaginatedRemoteMonitoringEventList> {
        return __request({
            method: 'GET',
            path: `/puppeteer/remote-monitoring/events/`,
            query: {
                'camera': camera,
                'card': card,
                'confidence_gte': confidenceGte,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'event_timestamp_gte': eventTimestampGte,
                'event_timestamp_lte': eventTimestampLte,
                'face_object': faceObject,
                'limit': limit,
                'monitoring_records': monitoringRecords,
                'ordering': ordering,
                'page': page,
                'puppet_id': puppetId,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this remote monitoring event.
     * @returns RemoteMonitoringEvent
     * @throws ApiError
     */
    public static puppeteerRemoteMonitoringEventsAcknowledgeCreate(
        id: number,
    ): CancelablePromise<RemoteMonitoringEvent> {
        return __request({
            method: 'POST',
            path: `/puppeteer/remote-monitoring/events/${id}/acknowledge/`,
        });
    }
    /**
     * @returns RemoteMonitoringEvent
     * @throws ApiError
     */
    public static puppeteerRemoteMonitoringEventsAcknowledgeAllCreate(): CancelablePromise<RemoteMonitoringEvent> {
        return __request({
            method: 'POST',
            path: `/puppeteer/remote-monitoring/events/acknowledge_all/`,
        });
    }
    /**
     * List searches
     * @param card Select search request that card
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param limit Number of results to return per page.
     * @param ordering Which field to use when ordering the results. Available fields: `created_date`, `id`
     * @param page Pagination cursor value.
     * @returns PaginatedSearchRequestList
     * @throws ApiError
     */
    public static puppeteerSearchList(
        card?: number,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        limit?: string,
        ordering?: string,
        page?: string,
    ): CancelablePromise<PaginatedSearchRequestList> {
        return __request({
            method: 'GET',
            path: `/puppeteer/search/`,
            query: {
                'card': card,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'limit': limit,
                'ordering': ordering,
                'page': page,
            },
        });
    }
    /**
     * Add a new search
     * @param requestBody
     * @returns SearchRequest
     * @throws ApiError
     */
    public static puppeteerSearchCreate(
        requestBody: SearchRequestRequest,
    ): CancelablePromise<SearchRequest> {
        return __request({
            method: 'POST',
            path: `/puppeteer/search/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve search by ID
     * @param id A unique integer value identifying this search request.
     * @returns SearchRequest
     * @throws ApiError
     */
    public static puppeteerSearchRetrieve(
        id: number,
    ): CancelablePromise<SearchRequest> {
        return __request({
            method: 'GET',
            path: `/puppeteer/search/${id}/`,
        });
    }
    /**
     * Update search properties
     * @param id A unique integer value identifying this search request.
     * @param requestBody
     * @returns PatchSearchRequest
     * @throws ApiError
     */
    public static puppeteerSearchPartialUpdate(
        id: number,
        requestBody?: PatchedPatchSearchRequestRequest,
    ): CancelablePromise<PatchSearchRequest> {
        return __request({
            method: 'PATCH',
            path: `/puppeteer/search/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete search
     * @param id A unique integer value identifying this search request.
     * @returns void
     * @throws ApiError
     */
    public static puppeteerSearchDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/puppeteer/search/${id}/`,
        });
    }
    /**
     * Update search results
     * @param id A unique integer value identifying this search request.
     * @returns SearchRequest
     * @throws ApiError
     */
    public static puppeteerSearchUpdateSearchCreate(
        id: number,
    ): CancelablePromise<SearchRequest> {
        return __request({
            method: 'POST',
            path: `/puppeteer/search/${id}/update_search/`,
        });
    }
    /**
     * List remote monitoring events
     * @param camera Select only events with exact camera
     * @param confidenceGte Select events with `confidence` **greater than or equal** to this value
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param eventTimestampGte Select events with `external_timestamp` **greater than or equal** to this value
     * @param eventTimestampLte Select events with `external_timestamp` **less than or equal** to this value
     * @param faceObject Select only events with exact face object id
     * @param limit Number of results to return per page.
     * @param ordering Which field to use when ordering the results. Available fields: `created_date`, `id`, `confidence`
     * @param page Pagination cursor value.
     * @param puppetId Select only events with exact puppet id
     * @param request Select search result that request
     * @returns PaginatedSearchResultList
     * @throws ApiError
     */
    public static puppeteerSearchEventsList(
        camera?: string,
        confidenceGte?: number,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        eventTimestampGte?: string,
        eventTimestampLte?: string,
        faceObject?: string,
        limit?: string,
        ordering?: string,
        page?: string,
        puppetId?: string,
        request?: number,
    ): CancelablePromise<PaginatedSearchResultList> {
        return __request({
            method: 'GET',
            path: `/puppeteer/search/events/`,
            query: {
                'camera': camera,
                'confidence_gte': confidenceGte,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'event_timestamp_gte': eventTimestampGte,
                'event_timestamp_lte': eventTimestampLte,
                'face_object': faceObject,
                'limit': limit,
                'ordering': ordering,
                'page': page,
                'puppet_id': puppetId,
                'request': request,
            },
        });
    }
}