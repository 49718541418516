
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch, Ref } from 'vue-property-decorator';

@Options({
  name: 'VideoPlayerThumbnail'
})
export default class VideoPlayerThumbnail extends Vue {
  @Prop({ type: String, required: true })
  src!: string;

  get styles() {
    return {
      width: '100%',
      height: '100%',
      backgroundImage: 'url(' + this.src + ')',
      backgroundSize: 'cover',
      borderRadius: '50%'
    };
  }
}
