/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CounterRecord = {
    readonly id: number;
    /**
     * Counter record creation date
     */
    readonly created_date: string;
    readonly counter: number;
    fullframe: string;
    /**
     * The number of counted faces
     */
    readonly faces_count: number;
    /**
     * Array of the bbox coordinates of the counted faces
     */
    readonly faces_bbox: Array<Array<Array<number>>>;
    /**
     * The number of counted silhouettes
     */
    readonly silhouettes_count: number;
    /**
     * Array of the bbox coordinates of the counted silhouettes
     */
    readonly silhouettes_bbox: Array<Array<Array<number>>>;
    thumbnail: string;
    readonly camera: number;
    readonly camera_group: number | null;
    /**
     * Counter service encountered an error while processing camera
     */
    readonly error: boolean;
    /**
     * Encountered error's message
     */
    readonly error_msg: string;
    /**
     * Minimum distance between 2 silhouettes
     */
    readonly proximity_min: number;
    /**
     * Maximum distance between 2 silhouettes
     */
    readonly proximity_max: number;
    /**
     * Average distance between 2 silhouettes
     */
    readonly proximity_avg: number;
    /**
     * Distances matrix
     */
    readonly proximity_distances: Array<Array<number>>;
    /**
     * The number of counted cars
     */
    readonly cars_count: number;
    /**
     * Array of the bbox coordinates of the counted cars
     */
    readonly cars_bbox: Array<Array<Array<number>>>;
};
export const EmptyCounterRecord: CounterRecord = {
    id: 0,
    /**
     * Counter record creation date
     */
    created_date: '',
    counter: 0,
    fullframe: '',
    /**
     * The number of counted faces
     */
    faces_count: 0,
    /**
     * Array of the bbox coordinates of the counted faces
     */
    faces_bbox: [],
    /**
     * The number of counted silhouettes
     */
    silhouettes_count: 0,
    /**
     * Array of the bbox coordinates of the counted silhouettes
     */
    silhouettes_bbox: [],
    thumbnail: '',
    camera: 0,
    camera_group: 0,
    /**
     * Counter service encountered an error while processing camera
     */
    error: false,
    /**
     * Encountered error's message
     */
    error_msg: '',
    /**
     * Minimum distance between 2 silhouettes
     */
    proximity_min: 0,
    /**
     * Maximum distance between 2 silhouettes
     */
    proximity_max: 0,
    /**
     * Average distance between 2 silhouettes
     */
    proximity_avg: 0,
    /**
     * Distances matrix
     */
    proximity_distances: [],
    /**
     * The number of counted cars
     */
    cars_count: 0,
    /**
     * Array of the bbox coordinates of the counted cars
     */
    cars_bbox: [],
};
