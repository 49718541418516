import { startCase } from 'lodash';
import Autolinker from 'autolinker';
import dayjs from 'dayjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import escapeHtml from 'escape-html';

let autolinker = new Autolinker({ urls: true, phone: false, stripPrefix: false, stripTrailingSlash: false });

export type OptionalDate = string | Date | null | undefined;

function isoStringToDate(value: string): Date {
  return new Date(value);
}

function unixToDate(value: number): Date {
  return new Date(value * 1000);
}

function floatToPercent(value: number): string {
  return Math.round(Math.floor(value * 100)) + '%';
}

function internalFormatDateTime(date: OptionalDate, format: string): string {
  return date ? dayjs(date).format(format) : '';
}

function formatTimeDate(date: OptionalDate, format?: string): string {
  return internalFormatDateTime(date, format || 'HH:mm:ss YYYY-MM-DD');
}

function formatDateTime(date: OptionalDate, format?: string): string {
  return internalFormatDateTime(date, format || 'YYYY-MM-DD HH:mm:ss');
}

function formatDateTimeWithoutSs(date: OptionalDate, format?: string): string {
  return internalFormatDateTime(date, format || 'YYYY-MM-DD HH:mm');
}

function formatDate(date: OptionalDate, format?: string): string {
  return internalFormatDateTime(date, format || 'YYYY-MM-DD');
}

function formatTime(date: OptionalDate, format?: string): string {
  return internalFormatDateTime(date, format || 'HH:mm:ss');
}

function formatTimeHHMM(date: OptionalDate, format?: string): string {
  return internalFormatDateTime(date, format || 'HH:mm');
}

function formatTimeHH(date: OptionalDate, format?: string): string {
  return internalFormatDateTime(date, format || 'HH');
}

function formatTimeMM(date: OptionalDate, format?: string): string {
  return internalFormatDateTime(date, format || 'mm');
}

function formatTimeMMSS(date: OptionalDate, format?: string): string {
  return internalFormatDateTime(date, format || 'mm:ss');
}

function toHHMMSS(value: number, isShowSeconds = true, isShowHours = true) {
  let format = 'mm';
  if (isShowHours) {
    format = 'HH:' + format;
  }
  if (isShowSeconds) {
    format += ':ss';
  }
  return formatTime(new Date(value * 1000), format);
}

function datesAreOnSameDay(first: Date, second: Date): boolean {
  return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
}

/**
 * Format bytes as human-readable text.
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * @return Formatted string.
 */
function formatFileSize(bytes: number, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  // TODO: multi language support
  const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

function autolink(v: string): string {
  return autolinker.link(escapeHtml(v));
}

function jsonToString(v: string): string {
  return JSON.stringify(v, null, 2);
}

function jsonToHtml(v: string): string {
  return autolink(jsonToString(v));
}

function firstCase(v: string | null | undefined): string {
  return v && v[0] ? v[0].toLocaleUpperCase() + v.substring(1) : '';
}

function upperCase(v: string | null | undefined): string {
  return v ? v.toLocaleUpperCase() : '';
}

function rectToPolyArray(v: any): number[][] | null {
  return v && v.x > -1e4 && v.y > -1e4 && v.width > 0 && v.height > 0
    ? [
        [v.x, v.y],
        [v.x + v.width, v.y],
        [v.x + v.width, v.y + v.height],
        [v.x, v.y + v.height]
      ]
    : null;
}

function polyArrayToRect(v: number[][]): any {
  return { x: v[0][0], y: v[0][1], width: v[2][0] - v[0][0], height: v[2][1] - v[0][1] };
}

function polyArrayToShortRect(v: number[][]): any {
  return { x: v[0][0], y: v[0][1], w: v[2][0] - v[0][0], h: v[2][1] - v[0][1] };
}

function getDistanceBasePoint(v: number[][]): any {
  return { x: Math.ceil(v[0][0] + (v[2][0] - v[0][0]) / 2) + 2, y: v[2][1] + 2 };
}

function rectObjectToArray(v: any): number[] | null {
  return v && v.x > -1e4 && v.y > -1e4 && v.width > 0 && v.height > 0 ? [Number(v.x), Number(v.y), Number(v.width), Number(v.height)] : null;
}

function rectObjectToArrayWHXY(v: any): number[] | null {
  return v && v.x > -1e4 && v.y > -1e4 && v.width > 0 && v.height > 0 ? [Number(v.width), Number(v.height), Number(v.x), Number(v.y)] : null;
}

function xGeometryToRectObject(v: string | null): any | null {
  let items = v ? [v.split('x')[0], ...v.split('x')[1].split('+')].map((v) => parseInt(v)) : null;
  return items ? rectArrayToObjectWHXY(items) : null;
}

function rectObjectToXGeometry(v: any): string {
  return v && v.x > -1e4 && v.y > -1e4 && v.width > 0 && v.height > 0 ? `${v.width}x${v.height}+${v.x}+${v.y}` : '';
}

function xGeometryToPointsArray(v: string | null): number[][] | null {
  const rectObject = v ? xGeometryToRectObject(v) : null;
  return rectObject
    ? [
        [rectObject.x, rectObject.y],
        [rectObject.x + rectObject.width, rectObject.y],
        [rectObject.x + rectObject.width, rectObject.y + rectObject.height],
        [rectObject.x, rectObject.y + rectObject.height]
      ]
    : null;
}

function pointsArrayToXGeometry(v: number[][]): any {
  const canCompute = v && v.length === 4;
  let result = '';

  if (canCompute) {
    const minPoint = v.reduce((i, m) => [Math.min(m[0], i[0]), Math.min(m[1], i[1])], [...v[0]]);
    const maxPoint = v.reduce((i, m) => [Math.max(m[0], i[0]), Math.max(m[1], i[1])], [...v[0]]);
    const rectObject = {
      x: minPoint[0],
      y: minPoint[1],
      width: maxPoint[0] - minPoint[0],
      height: maxPoint[1] - minPoint[1]
    };
    result = rectObjectToXGeometry(rectObject);
  }

  return result;
}

function rectArrayToObject(v: number[]): any {
  let valid = v && v.length === 4;
  return valid ? { x: v[0], y: v[1], width: v[2], height: v[3] } : null;
}

function rectArrayToObjectWHXY(v: number[]): any {
  let valid = v && v.length === 4;
  return valid ? { x: v[2], y: v[3], width: v[0], height: v[1] } : null;
}

function formatDemoPeriod(v: number[] | string[]): string {
  if (!(v && v.length === 6)) return 'Not valid demo period';
  let [years, months, days] = v;
  return `${years}y ${months}m ${days}d`
    .split(' ')
    .filter((v) => v[0] !== '0')
    .join(' ');
}

function shortString(v?: string | null, MaxSize = 18): string {
  const s = v || '';
  return s.length > MaxSize ? s.substring(0, MaxSize) + '...' : s;
}

function bytesToKb(v: number): string {
  let r;
  if (v > 1e9) {
    r = (v / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
  } else if (v > 1e6) {
    r = (v / (1024 * 1024)).toFixed(2) + 'MB';
  } else {
    r = (v / 1024).toFixed(2) + 'KB';
  }
  return r;
}

function numberToLocaleString(v: number | string): string {
  return typeof v === 'number' ? v.toLocaleString('ru') : v;
}

const CertificateKeys = 'CN,O,OU,L,S,C'.split(',');

function formatCertificate(v: string): string {
  let item = (v || '').split(',').reduce((m: any, i: string) => {
      let p = (i || '').trim().split('=');
      m[p[0]] = p[1];
      return m;
    }, {}),
    keys = CertificateKeys,
    r = keys.map((i) => item[i]).filter((i) => !!i);
  return r.join(', ');
}

function formatConfidence(value: number) {
  const rounded = Math.round(value * 1000) / 1000;
  return String(rounded === 1 ? '1.0' : rounded).padEnd(5, '0');
}

export {
  autolink,
  startCase,
  firstCase,
  upperCase,
  floatToPercent,
  escapeHtml,
  isoStringToDate,
  formatDemoPeriod,
  formatDateTime,
  formatDateTimeWithoutSs,
  formatTimeDate,
  formatDate,
  formatTime,
  formatTimeHHMM,
  formatTimeHH,
  formatTimeMM,
  formatTimeMMSS,
  toHHMMSS,
  datesAreOnSameDay,
  formatFileSize,
  jsonToString,
  jsonToHtml,
  unixToDate,
  rectArrayToObject,
  rectObjectToArray,
  rectObjectToArrayWHXY,
  rectArrayToObjectWHXY,
  xGeometryToRectObject,
  rectObjectToXGeometry,
  shortString,
  bytesToKb,
  numberToLocaleString,
  formatCertificate,
  formatConfidence,
  rectToPolyArray,
  polyArrayToRect,
  polyArrayToShortRect,
  xGeometryToPointsArray,
  pointsArrayToXGeometry,
  getDistanceBasePoint
};
