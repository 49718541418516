export default {
  'area-activations-in-out': 'area activations in/out',
  'area-activations': 'area activations',
  'area-records': 'area records',
  'audit-logs': 'audit logs',
  'body-cluster-events': 'body cluster events',
  'body-clusters': 'body clusters',
  'body-events': 'body events',
  'car-cards': 'vehicle records',
  'car-cluster-events': 'vehicle cluster events',
  'car-clusters': 'vehicle clusters',
  'car-episodes': 'vehicle episodes',
  'car-events': 'vehicle events',
  'face-cluster-events-in-out': 'face cluster events in/out',
  'face-cluster-events': 'face cluster events',
  'face-clusters': 'face clusters',
  'face-events-in-out': 'face events in/out',
  'face-events': 'face events',
  'human-cards': 'records of individuals',
  'human-episodes': 'people episodes',
  lines: 'lines',
  areas: 'areas',
  cameras: 'cameras',
  completed: 'completed',
  counters: 'counters',
  'counter-records': 'counter records',
  csv: 'CSV',
  delete: 'Delete',
  download: 'Download',
  downloading_report: 'Downloading report',
  downloading_reports: 'Downloading reports',
  error: 'error',
  fullframe: 'full frame',
  gb: 'GB',
  id: 'ID',
  json: 'JSON',
  kb: 'KB',
  kyc: 'analytics',
  link: 'link',
  mb: 'MB',
  modified: 'modified',
  name: 'name',
  processing: 'processing',
  records: 'records',
  report_by_area_activations_in_out: 'report on area activations in/out',
  report_by_area_activations: 'report on area activations',
  report_by_area_records: 'report on area records',
  report_by_areas: 'report on areas',
  report_by_audit_logs: 'report on audit logs',
  report_by_body_clusters: 'report on body clusters',
  report_by_body_events: 'report on body events',
  report_by_cameras: 'report on cameras',
  report_by_car_cards: 'report on vehicle records',
  report_by_car_clusters: 'report on vehicle clusters',
  report_by_counter_records: 'report on counter records',
  report_by_car_episodes: 'report on vehicle episodes',
  report_by_car_events: 'report on vehicle events',
  report_by_counters: 'report on counters',
  report_by_face_clusters: 'report on face clusters',
  report_by_face_events_in_out: 'report on face events in/out',
  report_by_face_events: 'report on face events',
  report_by_human_cards: 'report on records of individuals',
  report_by_human_episodes: 'report on people episodes',
  report_by_kyc: 'report on KYC analytics',
  report_by_lines: 'report on line crossing',
  report_format: 'report format',
  report_type: 'report type',
  report: 'report',
  reports_page: 'reports page',
  save_report_images_as: 'save report images as',
  scheduled: 'scheduled',
  size: 'size',
  standard_report: 'standard report',
  work_time_report: 'work time report',
  camera_entry: 'entrance camera',
  camera_exit: 'exit camera',
  status: 'status',
  successful_report_creation: 'Report has been generated. Go to the',
  thumbnail: 'thumbnail',
  type: 'type',
  update: 'Update',
  xls: 'XLS',
  id_areas: 'area ID',
  analytic_select_data: 'select data to include in the report',
  analytic_output_data: 'outcome data',
  analytic_input_data: 'input data used for graphs'
};
