import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "kyc-chart-statistics" }
const _hoisted_2 = { class: "heading-3xl" }
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statistics, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "kyc-chart-statistics-item",
        key: index
      }, [
        _createElementVNode("strong", _hoisted_2, _toDisplayString(_ctx.$filters.floatToPercent(item.percentage)), 1),
        _createElementVNode("span", {
          class: "kyc-chart-statistics-item__description label-2xs",
          textContent: _toDisplayString(item.description)
        }, null, 8, _hoisted_3)
      ]))
    }), 128))
  ]))
}