import { reactive } from 'vue';
import { configModule } from '@/store/config/index';
import { isEmpty } from 'lodash';

export const RequiredMenuItems = ['launcher', 'logout', 'profile'];

class ConfigAclModule {
  get config() {
    return configModule.config;
  }

  hasPlugins(plugins?: Record<string, boolean>): boolean {
    const configPlugins = this.config.plugins;
    if (!plugins || !configPlugins) return true;
    return Object.keys(plugins).reduce<boolean>((m, v) => (m ? configPlugins[v] === plugins[v] : false), true);
  }

  hasServices(services?: Record<string, boolean>): boolean {
    const configServices = this.config.services?.ffsecurity;
    if (!services || !configServices) return true;
    return Object.keys(services).reduce<boolean>((m, v) => (m ? !!configServices[v] === services[v] : false), true);
  }

  getDisabledMenuItem(): string[] {
    const disabledMenuItems = this.config.menu;
    return isEmpty(disabledMenuItems) ? [] : disabledMenuItems.disabled_items.filter((menuItem: string) => !RequiredMenuItems.includes(menuItem));
  }

  isDisabledMenuItem(itemName: string): boolean {
    return this.getDisabledMenuItem().includes(itemName);
  }

  getEnabledAcl(): boolean {
    return this.config.enable_acl !== undefined ? this.config.enable_acl : true;
  }
}

export const configAclModule = reactive(new ConfigAclModule());
