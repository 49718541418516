
import { NButton } from '@/uikit';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LicensePageButtonDownload',
  components: { NButton },
  emits: ['click'],
  methods: {
    dispatchClickEvent(): void {
      this.$emit('click');
    }
  }
});
