import { differenceOf } from '@/definitions/common/utils';
import { cloneDeep } from 'lodash';

function isEmptyValue(v?: any) {
  if (v) {
    return Array.isArray(v) ? !v.length : false;
  } else {
    return v === false;
  }
}

export class Filter<T> {
  public empty: T;
  public current: T;
  public force: T;
  public schema?: any = null;

  public excludedFields = ['page', 'ordering', 'limit'];
  public storageKey!: string; // used for filterManager, dataQa and etc

  constructor(emptyFilter?: T, filterSchema?: any) {
    this.empty = emptyFilter ? cloneDeep(emptyFilter) : ({} as T);
    this.current = emptyFilter ? cloneDeep(emptyFilter) : ({} as T);
    this.force = {} as T;
    this.schema = filterSchema;
  }

  get changes(): string[] {
    const diffObject = differenceOf(this.current, this.empty);
    return Object.keys(diffObject).filter((v) => !(v === 'page' || v === 'limit' || v === 'ordering'));
  }

  get hasChanges(): boolean {
    return !!this.changes.length;
  }

  get final() {
    return { ...this.empty, ...this.current, ...this.force };
  }

  get currentCleanAsString() {
    return JSON.stringify(this.currentClean);
  }

  get currentClean() {
    const entries = Object.entries(this.current)
      .filter(([key, value]) => !this.excludedFields.includes(key) && !isEmptyValue(value))
      .sort((a, b) => (a[0] > b[0] ? 1 : -1));
    return entries.length ? Object.fromEntries(entries) : undefined;
  }

  setCurrentByString(v: string) {
    try {
      const filter = JSON.parse(v ? v : '{}');
      this.current = Object.assign(cloneDeep(this.empty), filter);
    } catch (e) {
      console.warn('[filter] can\'t set filter from string: ', v);
    }
  }

  reset() {
    this.current = cloneDeep(this.empty);
  }
}
