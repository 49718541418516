import NLauncherPage from '@/pages/launcher/LauncherPage.vue';
import { RouteRecordRaw } from 'vue-router';
import LoginPage from '@/pages/login/LoginPage.vue';
import SettingsPage from '@/pages/settings/SettingsPage.vue';
import { PagePaths, PageRoutes } from '@/store/application/page.definitions';

const Main: RouteRecordRaw = {
  name: PageRoutes.Launcher,
  path: PagePaths.Launcher,
  component: NLauncherPage
};

const Login: RouteRecordRaw = {
  name: PageRoutes.Login,
  path: PagePaths.Login,
  component: LoginPage
};

const Settings: RouteRecordRaw = {
  name: PageRoutes.Settings,
  path: PagePaths.Settings,
  component: SettingsPage
};

export const Routes = {
  Main,
  Login,
  Settings
};
