import { DataService } from '@/definitions/services/data.services';
import { BaseListState, DefaultAclPrefix, AutoUpdateIntervalInMs, getPageFromNextPage, IIntervalData, PartialWithId } from '@/definitions/common/base';
import { cloneDeep, debounce } from 'lodash';
import { ItemViewModel } from '@/definitions/view-models/item.view.model';
import { Filter } from '@/definitions/view-models/filter';
import { Debounce } from '@/common/debounce-decorator';

const DefaultUpdateInterval = 5 * 60 * 1000;
const UpdateStatisticsIntervalMs = Math.max(+(localStorage?.debugUpdateInterval || DefaultUpdateInterval), 1000);
let IntervalItems = 0;
let ModuleItems = 0;

setInterval(
  () =>
    localStorage?.debugUpdateInterval &&
    console.log(`Active intervals: ${IntervalItems}, interval ${UpdateStatisticsIntervalMs}ms, total modules ${ModuleItems}`),
  3000
);

export class ListViewModel<T, F> implements BaseListState<T, F> {
  name = 'base';
  apiName = 'api_name';
  aclPrefix = DefaultAclPrefix;
  aclModelName: string | undefined;
  excludedChangeKeys: string[] = [];
  version = 2;
  syncFiltersToRoute = false;

  playing = false;
  loading = false;
  saving = false;
  deleting = false;
  appending = false;
  loaded = false;
  loadError: any | null = null;
  items: T[] = [];
  emptyItem?: T;

  firstLoadingPromise!: Promise<void>;
  protected resolveFirstLoadingPromise!: () => void;
  protected rejectFirstLoadingPromise!: () => void;

  count = 0;
  updateStatisticsInterval = 0;

  protected _dataService: DataService<T, F> | undefined;

  autoUpdate: IIntervalData = {
    enabled: false,
    intervalInMs: AutoUpdateIntervalInMs,
    intervalIndex: 0
  };

  page = '';
  next_page: string | null = null;
  prev_page: string[] = [];
  limits: number[] = [10, 20, 50, 100, 200, 500];

  filter = new Filter<F>();

  constructor() {
    ModuleItems++;
    this.getStatistics = debounce<any>(this.getStatistics, 500);
    this.firstLoadingPromise = new Promise<void>((resolve, reject) => {
      this.resolveFirstLoadingPromise = resolve;
      this.rejectFirstLoadingPromise = reject;
    });
  }

  get filterChanges() {
    return this.filter.changes;
  }

  get hasFilterChanges() {
    return this.filter.hasChanges;
  }

  get dataService(): DataService<T, F> {
    if (!this._dataService) {
      throw new Error('Data Service should be initialized');
    } else {
      return this._dataService;
    }
  }

  set dataService(v: DataService<T, F>) {
    this._dataService = v;
  }

  getItemViewModelByItem(item: T): ItemViewModel<T> {
    const wrappedItem: ItemViewModel<T> = new ItemViewModel();
    wrappedItem.setItemsState(item);
    wrappedItem.dataService = this.dataService;
    wrappedItem.excludedChangeKeys = this.excludedChangeKeys;
    wrappedItem.aclModelName = this.aclModelName;
    return wrappedItem;
  }

  get hasAcl(): boolean {
    return !!this.aclModelName;
  }

  get aclViewPermissionName(): string {
    return `${this.aclPrefix}.view_${this.aclModelName}`;
  }

  get aclAddPermissionName(): string {
    return `${this.aclPrefix}.add_${this.aclModelName}`;
  }

  get aclUpdatePermissionName(): string {
    return `${this.aclPrefix}.change_${this.aclModelName}`;
  }

  get aclDeletePermissionName(): string {
    return `${this.aclPrefix}.delete_${this.aclModelName}`;
  }

  public resetFilters(): void {
    this.filter.reset();
  }

  init(emptyItem: T, emptyFilter: F, filterSchema?: any) {
    this.emptyItem = cloneDeep(emptyItem);
    this.filter = new Filter<F>(emptyFilter, filterSchema);
  }

  @Debounce(700)
  async debouncedGet(options?: any): Promise<boolean> {
    return await this.get(options);
  }

  async get(options = { resetState: false }): Promise<boolean> {
    if (this.loading || this.appending) return false;
    if (options.resetState) this.items = [];
    this.setLoading(true);

    try {
      const responseData = await this.dataService.getList(this.filter.final);
      this.items = responseData.results || [];
      this.next_page = responseData.next_page;
      this.resolveFirstLoadingPromise();
    } catch (e) {
      this.loadError = e;
      this.items = [];
      this.next_page = null;
      this.rejectFirstLoadingPromise();
    }

    this.setLoading(false);
    this.setAutoUpdate(this.autoUpdate.enabled);
    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  setLoading(value: boolean) {
    this.loading = value;

    if (this.loading) {
      this.loadError = null;
    } else {
      this.loaded = true;
    }
  }

  setAutoUpdate(value: boolean, intervalInMs?: number) {
    clearInterval(this.autoUpdate.intervalIndex);
    this.autoUpdate.enabled = value;
    this.autoUpdate.intervalInMs = intervalInMs || this.autoUpdate.intervalInMs;
    if (value) {
      this.autoUpdate.intervalIndex = window.setInterval(() => this.get({ resetState: false }), this.autoUpdate.intervalInMs);
    }
  }

  async append(): Promise<boolean> {
    const nextPage = this.next_page && getPageFromNextPage(this.next_page);
    if (!nextPage) return false;
    if (this.loading || this.appending) return false;

    this.appending = true;
    this.loadError = null;

    try {
      const responseData = await this.dataService.getList({ ...this.filter.final, page: nextPage });
      const itemsIdMap: Record<string, boolean> = this.items.reduce<Record<string, boolean>>((m, v: any) => ((m[String(v.id)] = true), m), {});
      const filteredAppendedItems = responseData.results?.filter((v: any) => !itemsIdMap[v.id]) ?? [];
      this.items = [...this.items, ...filteredAppendedItems];
      this.next_page = responseData.next_page;
    } catch (e) {
      this.loadError = e;
    }

    this.appending = false;
    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  async create(data: Partial<T>): Promise<T> {
    const result = await this.dataService.create(data);
    return result;
  }

  async update(data: PartialWithId<T>): Promise<T> {
    const id: any = data.id;
    const result = await this.dataService.update(id, data);
    return result;
  }

  async delete(id: string | number): Promise<boolean> {
    const result = await this.dataService.delete(id);
    return true;
  }

  reset(): void {
    this.items = [];
    this.next_page = null;
    this.loaded = false;
  }

  async getStatistics() {
    try {
      const responseData = await this.dataService.getStatistics(this.filter.final);
      this.count = responseData.count;
    } catch (e) {
      this.count = 0;
      this.loadError = e;
    }
  }

  startStatisticsSync() {
    this.stopStatisticsSync();
    IntervalItems++;
    this.updateStatisticsInterval = window.setInterval(() => this.getStatistics(), UpdateStatisticsIntervalMs);
  }

  stopStatisticsSync() {
    if (this.updateStatisticsInterval) IntervalItems--;
    clearInterval(this.updateStatisticsInterval);
    this.updateStatisticsInterval = 0;
  }

  async saveItem(item: any): Promise<T | null | undefined> {
    const model = this.getItemViewModelByItem(item);
    return model.save();
  }
}
