
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NTooltip from "@/uikit/hint/NTooltip.vue";

@Options({
  name: 'CommonStatus',
  components: { NTooltip }
})
export default class CommonStatus extends Vue {
  @Prop({ type: String })
  color?: string;

  @Prop({ type: String })
  description?: string;
}
