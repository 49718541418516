
import CardOrUnmatched from '@/pages/external-search/CardOrUnmatched.vue';
import ExternalSearchResultInfo from '@/pages/external-search/results/ExternalSearchResultInfo.vue';
import NTable, { ITableColumn } from '@/uikit/table/NTable.vue';
import { EventDetails, ThumbnailAction, NThumbnail, ThumbnailPositions } from '@/uikit/thumbnail';
import { defineComponent } from 'vue';
import { arrayOf, object } from 'vue-types';
import { EnrichedSearchResult } from './enrich-search-results';

type ExternalSearchResultsTableState = {
  columns: ITableColumn[];
};

const THUMBNAIL_ACTIONS: ThumbnailAction[] = [
  {
    icon: 'eye-open',
    name: EventDetails.ShowFullScreen,
    position: ThumbnailPositions.BottomRight
  }
];

export default defineComponent({
  name: 'ExternalSearchResultsTable',
  components: { NTable },
  props: {
    results: arrayOf(object<EnrichedSearchResult>()).isRequired
  },
  emits: ['display-card-image', 'display-event-image'],
  data(): ExternalSearchResultsTableState {
    return {
      columns: [
        {
          header: {},
          width: '220px',
          content: {
            component: ExternalSearchResultInfo,
            props: (item: EnrichedSearchResult) => ({ item })
          }
        },
        {
          header: {},
          width: '80px',
          content: {
            classes: 'n-table-cell_padding-none',
            component: NThumbnail,
            path: 'thumbnail',
            props: (result: EnrichedSearchResult) => ({
              actions: THUMBNAIL_ACTIONS,
              onAction: (action: string) => this.dispatchDisplayEventImageEvent(action, result),
              size: 'small'
            })
          }
        },
        {
          header: {},
          content: {
            classes: 'n-table-cell_padding-none',
            component: CardOrUnmatched,
            props: ({ cardObject, confidence }: EnrichedSearchResult) => ({
              confidence,
              item: cardObject,
              onDisplayCardImage: this.dispatchDisplayCardImageEvent
            })
          }
        }
      ]
    };
  },
  methods: {
    dispatchDisplayEventImageEvent(action: string, result: EnrichedSearchResult): void {
      action === EventDetails.ShowFullScreen && this.$emit('display-event-image', result.fullframe);
    },
    dispatchDisplayCardImageEvent(src: string): void {
      this.$emit('display-card-image', src);
    }
  }
});
