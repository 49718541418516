import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "multisidebar-menu"
}
const _hoisted_2 = { class: "multisidebar-menu__navigation" }
const _hoisted_3 = { class: "multisidebar-menu__items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_MultisidebarGroup = _resolveComponent("MultisidebarGroup")!
  const _component_MultisidebarItem = _resolveComponent("MultisidebarItem")!

  return (_ctx.module.active)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_NButton, {
            class: "multisidebar-menu__navigation-action",
            type: "text",
            icon: "chevron-left",
            disabled: !_ctx.hasPrev,
            onAction: _ctx.prev
          }, null, 8, ["disabled", "onAction"]),
          _createVNode(_component_NButton, {
            class: "multisidebar-menu__navigation-action",
            type: "text",
            icon: "chevron-right",
            disabled: !_ctx.hasNext,
            onAction: _ctx.next
          }, null, 8, ["disabled", "onAction"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: group.name
            }, [
              _createVNode(_component_MultisidebarGroup, {
                group: group,
                "current-index": i,
                "total-count": _ctx.groups.length,
                actions: _ctx.groupActionsMap[group.name],
                onAction: (v) => _ctx.handleGroupAction(group, v)
              }, null, 8, ["group", "current-index", "total-count", "actions", "onAction"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, ({ item, config }) => {
                return (_openBlock(), _createBlock(_component_MultisidebarItem, {
                  key: item.id,
                  item: item,
                  config: config,
                  selected: item.id === _ctx.currentItem?.id
                }, null, 8, ["item", "config", "selected"]))
              }), 128))
            ], 64))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}