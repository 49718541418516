
import { ListViewModel } from '@/definitions/view-models';
import { CarCard, HumanCard } from '@/api';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { PagePaths } from '@/store/application/page.definitions';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import { dataAssetsModule, DataAssetsModule } from '@/store/application/data.assets.module';
import { CardTypesMap } from '@/store/application/data.assets';
import ListPage from '@/pages/ListPage.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import { applicationModule } from '@/store/application';
import { Options, Vue } from 'vue-class-component';
import Statistics from '@/components/common/Statistics.vue';
import { PageViewModel } from '@/store/application/page.view.model';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { Prop, Watch } from 'vue-property-decorator';
import { AclModelNames, aclModule } from '@/store/acl';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { RouterModule } from '@/store/router';
import { monitoringModule } from './monitoring/MonitoringModule';
import CardMonitoringAddDialog from './monitoring/CardMonitoringAddDialog.vue';
import { cloneDeep, merge } from 'lodash';
import { reactive } from 'vue';
import { pageModule } from '@/store/application/page.module';
import { workspaceModule } from '@/store/application/workspace';
import ExternalSearchRequestCreate from '@/pages/external-search/requests/ExternalSearchRequestCreate.vue';
import { getCardFiltersBuilder } from '@/pages/cards/filters/CardFiltersBuilder';
import { actionsModule } from '@/store/data/ActionsModule';
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import { FilterValue } from '@/components/common/filter/filter-manager';
import NConfirm from '@/uikit/modal-window/NConfirm.vue';
import { multisidebarModule } from '@/store/multisidebar';
import { GlobalEventName, globalEventModule } from '@/store/global-event';
import { GlobalEvent } from '@/store/global-event/types';
import { actionHandler } from '@/store/data/ActionHandler';

import CardGroupTile from '@/components/cards/CardGroupTile.vue';
import CardGroupRow from '@/components/cards/CardGroupRow.vue';
import ClusterItemTile from '@/components/cards/demo/ClusterItemTile.vue';
import EpisodeItemRow from '@/components/cards/demo/EpisodeItemRow.vue';
import CardItemProxy from '@/components/cards/CardItemProxy.vue';
import { IntersectionResultItem } from '@/store/intersection/IntersectionModule';
import { dataModule } from '@/store/data';
import { smallFilterManagerModule } from '@/components/common/filter/filter-manager/SmallFiltersModule';
import DisplayTypes from '@/components/common/DisplayTypes.vue';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { MultisidebarItemType } from '@/store/multisidebar/types';
import FilterSection from '@/components/common/filter/FilterSection.vue';

@Options({
  components: {
    FilterSection,
    DisplayTypes,
    CardItemProxy,
    EpisodeItemRow,
    CardGroupRow,
    ClusterItemTile,
    CardGroupTile,
    NConfirm,
    ExternalSearchRequestCreate,
    CardMonitoringAddDialog,
    NModalWindow,
    NButton,
    Statistics,
    SortDropdown,
    ListPage,
    NButtonGroup
  }
})
export default class CardsPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  pageVM: PageViewModel<any, any> = new PageViewModel<any, any>({ tab: String(Math.random()), path: PagePaths.Cards });
  addToMonitoringItems: any[] = [];
  addToExternalSearchItem: HumanCard | null = null;
  showMonitoringConfirm = false;
  showMonitoringConfirmAction = '';
  intersectionResultItem?: IntersectionResultItem | null = null;

  get selectedItems() {
    return this.sidebarModule.getItemsByType(this.pageSidebarType);
  }

  get pageSidebarType() {
    const { pageType, cardType } = this.state;
    return `${pageType}_${cardType}` as MultisidebarItemType;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get currentItem() {
    return this.sidebarModule.currentItem?.model.item;
  }

  get currentUser() {
    return dataModule.currentUserModule.item;
  }

  get ItemsActionNames() {
    return ItemsActionNames;
  }

  get dataAssetsModule(): DataAssetsModule {
    return dataAssetsModule;
  }

  get debug(): boolean {
    return applicationModule.settings.debug;
  }

  get state() {
    return this.pageVM.state;
  }

  get module(): ListViewModel<any, any> {
    return this.pageVM.module as ListViewModel<any, any>;
  }

  get workspaceModule() {
    return workspaceModule;
  }

  get smallFilterLayout() {
    const { cardType } = this.state;
    return getCardFiltersBuilder({ small: true }).getFilterByType(cardType!);
  }

  get bigFilterLayout() {
    const { cardType } = this.state;
    return getCardFiltersBuilder({ small: false }).getFilterByType(cardType!);
  }

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get classes(): Record<string, boolean> {
    return {
      [`page-items__container_${this.state.displayType}`]: true
    };
  }

  get isCurrentTab() {
    return workspaceModule.current === this.state.tab;
  }

  get isShort() {
    return this.state.displayType === 'short';
  }

  get monitoringModule() {
    return monitoringModule;
  }

  get isPuppeteerEnabled() {
    return this.state.cardType === CardTypesMap.Humans && this.monitoringModule.isPuppeteerEnabled;
  }

  get hasInteractions(): boolean {
    const faceClustersAcl = aclModule.getModelAclByName(AclModelNames.FaceCluster);
    return this.state.cardType === CardTypesMap.Humans && faceClustersAcl.view;
  }

  get smallFilterFields() {
    return smallFilterManagerModule.items[this.pageSidebarType];
  }

  setSmallFilterFields(v: string[]) {
    smallFilterManagerModule.setFiltersBySource(this.pageSidebarType, v, true);
  }

  created() {
    const route = cloneDeep(this.$route);
    route.query.tab = this.tab;
    this.pageVM = reactive(pageModule.getPageViewModel(route)) as PageViewModel<any, any>;
  }

  mounted() {
    this.intersectionResultItem = new IntersectionResultItem({
      container: document.querySelector('.page-content') as HTMLElement,
      itemsQuerySelector: '.card-item-proxy',
      id: this.tab
    });
    this.intersectionResultItem?.reset();
    this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  beforeUnmount() {
    this.intersectionResultItem?.reset();
  }

  get lastPageEvent() {
    return globalEventModule.current?.type === this.pageSidebarType ? globalEventModule.current : null;
  }

  @Watch('module', { deep: false })
  changeModuleHandler(v: any, p: any) {
    this.intersectionResultItem?.reset();
  }

  @Watch('module.loading')
  loadingHandler(v: boolean, p: boolean) {
    if (!v) {
      this.intersectionResultItem?.reset();
      this.intersectionResultItem?.syncObservableTargetsNextTick();
    }
  }

  @Watch('module.appending')
  appendingHandler(v: boolean, p: boolean) {
    if (!v) this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  @Watch('lastPageEvent')
  handleGlobalEvent(signal: GlobalEvent) {
    if (!signal) return;
    switch (signal.name) {
      case GlobalEventName.Create:
      case GlobalEventName.Delete:
        this.module.get();
        this.intersectionResultItem?.reset();
        break;
      case GlobalEventName.Update:
        merge(
          this.module.items.find((v) => v.id === signal.payload.id),
          signal.payload
        );
        break;
    }
  }

  @Watch('isPuppeteerEnabled', { immediate: true })
  loadMonitorings(v: boolean, p: boolean) {
    if (v && !p) this.monitoringModule.load();
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(v: any, p: any) {
    if (!this.isCurrentTab) return;
    if (v?.page !== p?.page) return;
    if (v?.limit !== p?.limit) return;
    this.module.debouncedGet();
    this.pageVM.state.filter = this.module.filter.currentClean;
  }

  hasItemOnMonitoring(id: string | number): boolean {
    return !!this.monitoringModule.getItemsByCardId(Number(id)).length;
  }

  get checkSelectedItemsOnMonitoring() {
    if (this.selectedItems.length) {
      return this.selectedItems.some(({ model }) => {
        return this.hasItemOnMonitoring(model.item.id);
      });
    }
    return false;
  }

  async actionHandler(id: string | number, action: ItemsActionName, payload?: any): Promise<void> {
    const actionItem = (id && this.module.items.find((v) => v?.id === id)) ?? this.currentItem;

    if (action === ItemsActionNames.ShowInteractions) {
      RouterModule.navigateToInteractions({ card: id });
      return;
    }

    if ((action === ItemsActionNames.DeleteAll || action === ItemsActionNames.Delete) && this.checkSelectedItemsOnMonitoring) {
      this.showMonitoringConfirm = true;
      this.showMonitoringConfirmAction = action;
      return;
    }

    switch (action) {
      case ItemsActionNames.AddToMonitoring:
        actionItem && (this.addToMonitoringItems = [actionItem as HumanCard]);
        break;

      case ItemsActionNames.AddAllToMonitoring:
        this.addSelectedToMonitoring();
        break;

      case ItemsActionNames.RemoveFromMonitoring:
      case ItemsActionNames.RemoveAllFromMonitoring:
        this.showMonitoringConfirm = true;
        break;

      case ItemsActionNames.MonitoringEvents:
        this.navigateToMonitoringEvents(id);
        break;

      case ItemsActionNames.ExternalSearch:
        this.addToExternalSearchItem = actionItem as HumanCard;
        break;

      default:
        actionHandler.run(action, payload); // todo: check
    }
  }

  async monitoringConfirmHandler() {
    if (this.showMonitoringConfirmAction) {
      // todo: refactor it for cibr
      // await this.pageVM.actionHandler('', this.showMonitoringConfirmAction, null);
      return;
    }
    if (this.selectedItems.length > 1) {
      await this.removeSelectedFromMonitoring();
    } else {
      this.currentItem?.id && (await this.monitoringModule.deleteByCards([+this.currentItem.id]));
    }
  }

  getIsCardOpened(card: HumanCard | CarCard) {
    return this.sidebarModule.currentItem?.id === generateMultisidebarId(this.pageSidebarType, card.id);
  }

  monitoringCloseHandler() {
    this.showMonitoringConfirm = false;
  }

  addSelectedToMonitoring() {
    let selectedNonMonitoringCards = this.selectedItems
      .filter(({ model }) => !this.monitoringModule.getItemsByCardId(model.item.id).length)
      .map(({ model }) => model.item);
    this.selectedItems.length && (this.addToMonitoringItems = selectedNonMonitoringCards);
  }

  async removeSelectedFromMonitoring() {
    let selectedIds = this.selectedItems.map(({ model }) => +model.item.id);
    selectedIds.length && (await this.monitoringModule.deleteByCards(selectedIds));
  }

  navigateToMonitoringEvents(id: string | number) {
    this.$router.push(
      RouterModule.getRouteLocation({
        path: PagePaths.RemoteMonitoringEvents,
        pageState: { filter: { card: id } }
      })
    );
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      this.module.append();
    }
  }

  get modelAcl() {
    this.module.aclModelName = this.state.cardType === CardTypesMap.Humans ? 'humancard' : 'carcard';
    return aclModule.getModelAcl(this.module);
  }

  addNew() {
    this.sidebarModule.addNewItemByType(this.pageSidebarType);
  }
}
