import { dataServiceFactory } from '@/definitions/services/data.services';
import { Component, createApp } from 'vue';

import App from './App.vue';
import * as filters from './common/filters';
import router from './router';
import store from './store';

import '@/css/index.styl';

import LoadingPage from '@/pages/loading/LoadingPage.vue';
import { applicationModule, initApplicationModule } from '@/store/application';
import { keyboardModule, KeyCodes } from '@/store/application/keyboard';
import { initWorkspaceModule } from '@/store/application/workspace';
import { authModule, initAuthModule } from '@/store/auth';
import { configModule } from '@/store/config';
import { initLanguageModule, languageModule } from '@/store/languages';
import { ExceptionsModule } from './store/application/exceptions';
import { NotificationsModule } from './store/application/notifications';
import { initLicenseNotificationModule } from '@/store/application/license.notification';
import { photoViewerController } from '@/components/photo-viewer/PhotoViewerController';
import { reportsFormController } from '@/components/reports-form/ReportsFormController';
import VideoPlayerController from '@/components/video-player/VideoPlayerController';
import { localStorageModule } from '@/store/application/local.storage';
import { postLoginDataLoad } from '@/store/data/postLoginDataLoad';
import { initNIconSprite } from '@/common/utils';
import { PagePaths } from '@/store/application/page.definitions';
import { customDialogFactory } from '@/store/dialogs/customDialogFactory';
import { dataModule } from '@/store/data';

registerModules();
createApp(LoadingPage).mount('#app');
configModule.load().then(async () => {
  initApp(App);
  document.title = configModule.defaultTitle;
  if (authModule.isTokenValid) {
    try {
      await postLoginDataLoad();
    } catch (e) {
      authModule.token = null;
      console.warn('load error: ', e, 'clear token');
      await router.push({ path: PagePaths.Login });
    }
  } else {
    authModule.token = null;
    await router.push({ path: PagePaths.Login });
  }
});

function registerModules() {
  dataServiceFactory.configModule = configModule;
  dataServiceFactory.languageModule = languageModule;
  dataServiceFactory.authModule = authModule;
  dataServiceFactory.guard.useAuthModule(authModule);
  dataServiceFactory.guard.useRouter(router);
}

function initFavicon() {
  const favicon = document.getElementById('favicon') as HTMLAnchorElement;
  if (favicon) {
    if (configModule.config.themes?.favicon) {
      favicon.href = configModule.config.themes?.favicon;
    } else {
      favicon.href = require('@/assets/icons/favicon.svg');
    }
  }
}

function initApp(component: Component) {
  const app = createApp(component);
  setAppGlobalProps(app.config.globalProperties);
  app.use(store).use(router);
  localStorageModule.setLastKey();
  initFavicon();
  initLanguageModule(configModule.config.language);
  initAuthModule();
  initApplicationModule();
  initWorkspaceModule();
  initNIconSprite();
  initKeyboard();
  initLicenseNotificationModule();
  app.mount('#app');
  customDialogFactory.context = app._context;
  return app;
}

function setAppGlobalProps(props: Record<string, any>) {
  props.$t = languageModule.getTranslatedToken;
  props.$tm = languageModule.getTranslatedMessage;
  props.$filters = filters;
  props.$photoViewer = photoViewerController;
  props.$reportsForm = reportsFormController;
  props.$exceptions = ExceptionsModule.create({
    language: languageModule,
    notifications: NotificationsModule.create()
  });
  props.$videoPlayer = new VideoPlayerController();
}

function initKeyboard() {
  keyboardModule.register({ id: 'theme', keyCode: KeyCodes.V, shiftKey: true, ctrlKey: true, callback: applicationModule.toggleTheme.bind(applicationModule) });
  keyboardModule.register({ id: 'debug', keyCode: KeyCodes.D, shiftKey: true, ctrlKey: true, callback: applicationModule.toggleDebug.bind(applicationModule) });
  keyboardModule.register({ id: 'presaleDebug', keyCode: KeyCodes.D, altKey: true, callback: applicationModule.togglePresaleDebug.bind(applicationModule) });
  keyboardModule.register({
    id: 'language',
    keyCode: KeyCodes.J,
    shiftKey: true,
    ctrlKey: true,
    callback: () => {
      if (!configModule.canLanguageSelect) return;
      languageModule.toggleLocale();
      const hasUser = (dataModule.currentUserModule.item?.id || 0) > 0;
      if (hasUser) dataModule.syncCurrentUserLocale();
    }
  });
}
