
import { Options, Vue } from 'vue-class-component';
import SearchOverlay from '@/pages/search/SearchOverlay.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { DisplayType, PagePaths, PageTypes, SearchPageState } from "@/store/application/page.definitions";
import { pageModule } from '@/store/application/page.module';
import { ListViewModel } from '@/definitions/view-models';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { CardTypesMap, ObjectsMultiToSingle, ObjectsSingleToMulti, ObjectsTypesMap, SearchFromMap } from '@/store/application/data.assets';
import NInput from '@/uikit/input/NInput.vue';
import { Prop, Watch } from 'vue-property-decorator';
import ListPage from '@/pages/ListPage.vue';
import { applicationModule } from '@/store/application';
import ClusterItem from '@/components/clusters/ClusterItem.vue';
import SearchSidebar from './SearchSidebar.vue';
import { NConfidence } from '@/uikit';
import { getClusterFiltersBuilder } from '@/pages/clusters/forms/ClusterFiltersBuilder';
import { getCardFiltersBuilder as getCardFilters } from '@/pages/cards/filters/CardFiltersBuilder';
import ParticipantItem from '@/components/participants/ParticipantItem.vue';
import { viewModelRepository } from '@/api/common';
import ConfidenceLabel from '@/components/common/ConfidenceLabel.vue';
import { BodyCluster, CarCard, CarCluster, FaceCluster, HumanCard, Settings } from '@/api';
import enrichParticipants, { EnrichedParticipant } from '@/components/participants/enrich-participants';
import Statistics from '@/components/common/Statistics.vue';
import { RouterModule } from '@/store/router';
import SortDropdown from '@/components/common/SortDropdown.vue';
import { settingsItemModule } from '@/store/data/SettingsItemModule';
import { getEventFiltersBuilder } from '@/pages/events/forms/EventFiltersBuilder';
import { getParticipantFilters } from '@/components/participants/forms/ParticipantFilters';
import NThemeImage from '@/uikit/image/NThemeImage.vue';
import enrichCards from '@/components/cards/enrich-cards';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import ImageViewer from '@/components/image-viewer/ImageViewer.vue';
import { SearchModule } from '@/pages/search/searchModule';
import { BboxConfig } from '@/uikit/bbox/types';
import SearchFromSelect from '@/pages/search/SearchFromSelect.vue';
import DetectionDialog from '@/components/detection/DetectionDialog.vue';
import { MultisidebarItemType, MultisidebarRawItemPayload } from '@/store/multisidebar/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { multisidebarModule } from '@/store/multisidebar';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { ClusterOpenedItems } from '@/components/clusters/types';
import NAlertBadge from '@/uikit/alert-badge/NAlertBadge.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import { Debounce } from '@/common/debounce-decorator';
import Confidence from '@/components/common/Confidence.vue';
import EventItem from '@/components/events/EventItem.vue';
import { EventDetails } from '@/uikit/thumbnail';
import { AnyEvent, EventOpenedItems, EventOrEpisode } from '@/components/events/types';
import { eventEpisodeAdapter } from '@/components/events/adapter';
import CardItemProxy from '@/components/cards/CardItemProxy.vue';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import EventItemShort from '@/components/events/EventItemShort.vue';
import { aclModule } from '@/store/acl';
import DisplayTypes from '@/components/common/DisplayTypes.vue';
import { DataAssetsModule, dataAssetsModule } from '@/store/application/data.assets.module';
import FilterSection from "@/components/common/filter/FilterSection.vue";
import { GlobalEvent } from '@/store/global-event/types';
import { globalEventModule, GlobalEventName } from '@/store/global-event';
import { merge } from 'lodash';

function removeFilterByPath(path: string) {
  return (filter: Record<string, any>) => filter.path !== path;
}

@Options({
  name: 'SearchPage',
  components: {
    FilterSection,
    DisplayTypes,
    EventItemShort,
    Confidence,
    InfiniteScroll,
    NAlertBadge,
    DetectionDialog,
    SearchFromSelect,
    NThemeImage,
    ImageViewer,
    NModalWindow,
    SortDropdown,
    Statistics,
    ConfidenceLabel,
    ParticipantItem,
    SearchSidebar,
    ClusterItem,
    ListPage,
    NInput,
    NButtonGroup,
    NButton,
    SearchOverlay,
    EventItem,
    CardItemProxy
  }
})
export default class SearchPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  private isDetectionDialogVisible = false;
  private participantItems: EnrichedParticipant[] = [];
  private state = pageModule.getPageStateByTab(PagePaths.Search, this.tab || `tab_${Math.random()}`) as SearchPageState;
  private searchModule = SearchModule.create(this.state);

  get module(): ListViewModel<any, any> {
    if (this.isCasesPage) {
      return viewModelRepository.getCaseParticipantsListViewModel();
    }
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
  }

  get dataAssetsModule(): DataAssetsModule {
    return dataAssetsModule;
  }

  get hasSourceError() {
    return this.searchModule.hasError;
  }

  get showEmpty() {
    return !this.module || !this.module.filter.current.looks_like?.length || (!this.module.loading && this.module.loaded && this.module.items.length === 0);
  }

  get isCasesPage() {
    return this.state.pageType === PageTypes.Cases;
  }

  get isEventsPage() {
    return this.state.pageType === PageTypes.Events;
  }

  get isClustersPage() {
    return this.state.pageType === PageTypes.Clusters;
  }

  get isCardsPage() {
    return this.state.pageType === PageTypes.Cards;
  }

  get searchPageTypes() {
    return this.dataAssetsModule.searchPageTypes.filter((item) => this.state.objectType !== ObjectsTypesMap.Cars || item.value !== PageTypes.Clusters);
  }

  get looksLike(): string[] {
    const id = this.state.detectionId;
    if (!id) return [];
    switch (this.state.searchFrom) {
      case 'file':
        return [`detection:${id}`];
      case 'events':
        return [`${ObjectsMultiToSingle[this.state.objectType]}event:${id}`]; // face, body, car
      case 'cards':
        return this.state.cardLooksLike || [];
      case 'clusters':
        return [`${ObjectsMultiToSingle[this.state.objectType]}cluster:${id}`]; // face, body, car
      case 'cases':
        return [`caseparticipant:${id}`];
      default:
        return [];
    }
  }

  get currentItemId() {
    return this.sidebarModule.currentItem?.id;
  }

  get pageSidebarType() {
    const { pageType, objectType, cardType } = this.state;
    if (pageType === PageTypes.Cards) {
      return `${pageType}_${cardType}`;
    }
    return `${pageType}_${objectType}`;
  }

  get cardsSidebarType() {
    const { cardType } = this.state;
    return `${PageTypes.Cards}_${cardType}`;
  }

  get debugMode() {
    return applicationModule.settings.debug;
  }

  get canCreateReport() {
    return !this.isCasesPage;
  }

  get sortTypes(): any[] {
    if (this.state.pageType === PageTypes.Events) {
      return dataAssetsModule.getSortTypes({ idAsCreatedDate: true, looks_like_confidence: { desc: true } });
    }

    return dataAssetsModule.getSortTypes({ created_date: true, looks_like_confidence: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get attachment() {
    return this.searchModule.attachment;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get getAllowed() {
    return (
      !this.searchModule.loading &&
      !this.hasSourceError &&
      (this.searchModule.item || this.searchModule.bboxThumbnail) &&
      this.looksLike.length &&
      !this.showMultiselectWarning
    );
  }

  get showMultiselectWarning() {
    return this.state.pageType === PageTypes.Events && ['id', '-id'].includes(this.module.filter.current.ordering) && this.looksLike.length > 1;
  }

  get defaultEventAction() {
    return EventDetails.ShowInfo;
  }

  get viewerItems() {
    return this.module.items.map((v: any) => eventEpisodeAdapter(v, this.state.objectType));
  }

  get hasRowView() {
    return this.state.pageType === PageTypes.Cards || this.state.pageType === PageTypes.Events;
  }

  get confidenceClass() {
    return {
      search__confidence: true,
      search__confidence_row: this.hasRowView && this.state.displayType === DisplayType.Full
    };
  }

  get modelAcl() {
    if (this.isCardsPage) {
      this.module.aclModelName = this.state.cardType === CardTypesMap.Humans ? 'humancard' : 'carcard';
      return aclModule.getModelAcl(this.module);
    }
    return {};
  }

  get lastPageEvent() {
    return globalEventModule.current?.type === this.pageSidebarType ? globalEventModule.current : null;
  }

  @Watch('lastPageEvent')
  handleGlobalEvent(signal: GlobalEvent) {
    if (!signal) return;
    switch (signal.name) {
      case GlobalEventName.Create:
      case GlobalEventName.Delete:
        this.module.get();
        break;
      case GlobalEventName.Update:
        merge(
          this.module.items.find((v) => v.id === signal.payload.id),
          signal.payload
        );
        break;
    }
  }

  @Watch('state.id', { immediate: true })
  handleStateIdResetInFileMode(v: string) {
    if (this.state.searchFrom === SearchFromMap.File && (v === '' || !this.searchModule.bboxThumbnail)) {
      this.backToStart();
    }
  }

  @Watch('state.detectionId', { immediate: true })
  loadResultsOnDetectionChange() {
    if (this.state.detectionId) {
      this.updateFilter();
      this.get();
    }
  }

  @Watch('looksLike')
  searchHandler() {
    this.updateFilter();
    this.get(true);
  }

  @Watch('state.pageType')
  pageTypeHandler(value: string) {
    Object.assign(this.state, { pageType: value });
    this.updateFilter();
    this.get(true);
  }

  @Watch('module.items')
  async moduleItemsHandler() {
    if (this.isCasesPage) {
      this.participantItems = await enrichParticipants(this.module.items, this.participantItems);
    }
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(v: any, p: any) {
    if (v?.page !== p.page) return;
    this.get();
  }

  @Watch('attachment', { deep: true })
  showDetectionDialog() {
    this.attachment && (this.isDetectionDialogVisible = true);
  }

  getOpenedTypeByCluster(cluster: BodyCluster | FaceCluster | CarCluster) {
    if (!this.currentItemId) return '';
    if (this.currentItemId === generateMultisidebarId(this.pageSidebarType, cluster.id)) return ClusterOpenedItems.Cluster;
    if (this.currentItemId === generateMultisidebarId(this.cardsSidebarType, cluster.card)) return ClusterOpenedItems.Card;
  }

  getOpenedTypeByEvent(event: AnyEvent) {
    if (!this.currentItemId) return '';
    if (this.currentItemId === generateMultisidebarId(this.pageSidebarType, event.id)) return EventOpenedItems.Item;
    if (event.matched_card && this.currentItemId === generateMultisidebarId(this.cardsSidebarType, event.matched_card)) return EventOpenedItems.Card;
  }

  getIsCardOpened(card: HumanCard | CarCard) {
    return this.sidebarModule.currentItem?.id === generateMultisidebarId(this.pageSidebarType, card.id);
  }

  selectBBOX(bboxes: BboxConfig[]) {
    const bbox = bboxes[0];
    if (bbox.meta?.type && bbox.meta?.id) {
      const type = bbox.meta?.type;
      const types = ObjectsSingleToMulti[type];
      const result: Record<string, any> = {
        detectionId: bbox.meta?.id,
        objectType: types,
        cardType: types === ObjectsTypesMap.Cars ? CardTypesMap.Cars : CardTypesMap.Humans,
        episodeType: types === ObjectsTypesMap.Cars ? CardTypesMap.Cars : CardTypesMap.Humans,
        searchFrom: SearchFromMap.File
      };
      Object.assign(this.state, result);
      this.state.id = this.attachment?.name;
      this.searchModule.bboxThumbnail = bbox.thumbnail;
      this.closeDetectionDialog();
    } else {
      console.warn('[search] Unknown bbox type or id in meta: ' + JSON.stringify(bbox));
    }
  }

  closeDetectionDialog() {
    this.isDetectionDialogVisible = false;
  }

  backToStart() {
    this.state.id = '';
    this.state.detectionId = '';
    this.state.showOverlay = true;
    this.state.searchFrom = SearchFromMap.Events;
  }

  updateFilter() {
    const thresholdProperty = `${ObjectsMultiToSingle[this.state.objectType]}_confidence_threshold` as keyof Settings;
    const filter = {
      looks_like: this.looksLike,
      ordering: '-looks_like_confidence',
      threshold: settingsItemModule.item?.[thresholdProperty]
    };
    Object.assign(this.module.filter.current, filter);
  }

  smallFilterLayout() {
    const { pageType, objectType, episodeType, cardType } = this.state;
    const confidenceField = {
      component: NConfidence,
      i18n_tooltip: 'common.confidence_desc',
      tooltipPlacement: 'bottom',
      path: 'threshold'
    };

    if (pageType === PageTypes.Cases) {
      return [confidenceField];
    }
    if (pageType === PageTypes.Cards && cardType) {
      return [confidenceField, ...getCardFilters({ small: true }).getFilterByType(cardType).filter(removeFilterByPath('has_face_objects'))];
    }
    const cardTypeByObjectType = this.computeCardTypeByObjectType(objectType);
    return [confidenceField, ...getEventFiltersBuilder({ small: true, cardType: cardTypeByObjectType }).getFilterByType(pageType, objectType, episodeType)];
  }

  getBigFilterLayout(): IFormLayoutItem[] {
    const { pageType, objectType, episodeType, cardType } = this.state;
    let result: IFormLayoutItem[] = [];
    if (pageType === PageTypes.Cards && cardType) {
      result = getCardFilters({ small: false }).getFilterByType(cardType).filter(removeFilterByPath('has_face_objects'));
    } else if (pageType === PageTypes.Cases) {
      result = getParticipantFilters({ small: false });
    } else {
      const cardTypeByObjectType = this.computeCardTypeByObjectType(objectType);
      result = getEventFiltersBuilder({ small: false, cardType: cardTypeByObjectType }).getFilterByType(pageType, objectType, episodeType);
    }
    const limitFilter = commonFilterSchemaModule.getLimit({ small: false });
    result.push(limitFilter);
    return result;
  }

  computeCardTypeByObjectType(objectType: string) {
    return objectType === 'cars' ? 'cars' : 'humans';
  }

  getClustersBigFilterLayout() {
    const { pageType, objectType } = this.state;
    const cardTypeByObjectType = this.computeCardTypeByObjectType(objectType);
    const result = getClusterFiltersBuilder({ small: false, cardType: cardTypeByObjectType }).getFilterByType(pageType, objectType);
    const limitFilter = commonFilterSchemaModule.getLimit({ small: false });
    result.push(limitFilter);
    return result;
  }

  getFilterLayoutByPageType() {
    return this.isClustersPage ? this.getClustersBigFilterLayout() : this.getBigFilterLayout();
  }

  // resetFilters() {
  //   this.module.resetFilters();
  //   this.updateFilter();
  // }

  navigateToCard(id: string | number) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: this.cardsSidebarType, rawItem: id });
  }

  openSearchTypeInTab(item: any) {
    const msbPayload: MultisidebarRawItemPayload = { type: `${this.state.pageType}_${this.state.objectType}` as MultisidebarItemType, rawItem: item };
    switch (this.state.pageType) {
      case PageTypes.Cards:
        msbPayload.type = `${this.state.pageType}_${this.state.cardType}` as MultisidebarItemType;
        break;
      case PageTypes.Cases:
        RouterModule.navigateToParticipants(item); // todo: fix when cases will be migrate on MSB
        break;
    }
    actionHandler.run(ItemsActionNames.ShowItem, msbPayload);
  }

  async openFullFrame(item: any) {
    const [enrichedItem] = await enrichCards([item]);
    await actionHandler.run(ItemsActionNames.ShowFullScreen, enrichedItem.object);
  }

  @Debounce(500)
  get(resetState = false) {
    if (this.getAllowed) {
      this.module.get({ resetState });
    }
  }

  async actionHandler(id: string | number, action: string, payload?: any): Promise<void> {
    const event = payload?.best_face_event || payload?.best_body_event || payload?.best_car_event || payload;
    switch (action) {
      case ItemsActionNames.ShowInfo:
      case ItemsActionNames.ShowItem:
        await actionHandler.run(ItemsActionNames.ShowItem, { type: this.pageSidebarType, rawItem: id });
        break;
      case ItemsActionNames.ShowFullScreen:
        this.showItemFullscreen(payload);
        break;
      case ItemsActionNames.ShowPlayer:
        if (event) {
          const timeFrom = new Date(event.created_date).getTime() / 1000;
          this.$videoPlayer.playArchive(event.camera, timeFrom - 3);
        }
        break;
      case ItemsActionNames.NavigateToCard:
        await this.navigateToCard(payload.matched_card);
        break;
      default:
        console.warn('Unsupported action ', id, action, payload);
        break;
    }
  }

  showItemFullscreen(item: EventOrEpisode) {
    const activeItemIndex = this.viewerItems.findIndex((v) => v.id == item.id);
    if (activeItemIndex !== -1) {
      this.$photoViewer.show(this.viewerItems, { activeItemIndex });
    }
  }

  async init() {
    if (this.state.id) {
      await this.searchModule.tryLoadItemOrImage(String(this.state.id));
    } else {
      this.state.showOverlay = true;
    }
  }

  mounted() {
    this.init();
  }

  beforeUnmount() {
    this.searchModule.resetErrors();
  }

  deactivated() {
    this.searchModule.resetErrors();
  }
}
