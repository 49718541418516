import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "data-modal__content label-s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_ctx.dataModal.visible)
    ? (_openBlock(), _createBlock(_component_NModalWindow, {
        key: 0,
        layout: true,
        size: _ctx.size,
        "insert-into-body": true,
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dataModal.visible = false)),
        label: _ctx.$t('common.info', 'f')
      }, {
        content: _withCtx(() => [
          _createElementVNode("pre", _hoisted_1, _toDisplayString(_ctx.dataModal.data), 1)
        ]),
        _: 1
      }, 8, ["size", "label"]))
    : _createCommentVNode("", true)
}