
import { defineComponent, toRefs } from 'vue';

export default defineComponent({
  name: 'LoginLogo',
  props: {
    small: { type: Boolean, default: false }
  },
  setup(props) {
    return {
      ...toRefs(props)
    };
  }
});
