/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EpisodesHumansFilter = {
  /**
   * Select only acknowledged episodes (`true`) or unacknowledged (`false`) episodes
   */
  acknowledged?: boolean;
  /**
   * Select episodes acknowledged by user
   */
  acknowledged_by?: number;
  /**
   * Select only episodes with this acknowledgment reaction
   */
  acknowledged_reaction?: string;
  /**
   * Select episodes where feature `age_group` takes one of these values
   */
  age_group?: Array<string>;
  /**
   * Select episodes where feature`age` is **greater than or equal** to this value
   */
  age_gte?: string;
  /**
   * Select episodes where feature `age` is **lower than or equal** to this value
   */
  age_lte?: string;
  /**
   * Select episodes where feature `bag_back` takes one of these values
   */
  bag_back?: Array<string>;
  /**
   * Select episodes where feature `bag_ground` takes one of these values
   */
  bag_ground?: Array<string>;
  /**
   * Select episodes where feature `bag_hand` takes one of these values
   */
  bag_hand?: Array<string>;
  /**
   * Select episodes where feature `beard` takes one of these values
   */
  beard?: Array<string>;
  /**
   * Select episodes where feature `gender` takes one of these values(body feature)
   */
  body_gender?: Array<string>;
  /**
   * Select episodes where feature `bottom_color` takes one of these values
   */
  bottom_color?: Array<string>;
  /**
   * Select episodes associated with the specified camera groups
   */
  camera_groups?: Array<number>;
  /**
   * Select episodes associated with the specified cameras
   */
  cameras?: Array<number>;
  /**
   * Select episodes related to these cases
   */
  case_in?: Array<number | null>;
  /**
   * Select episodes with `closed_date` **greater than or equal** to this value
   */
  closed_date_gte?: string;
  /**
   * Select objects with `created_date` **greater** than this value
   */
  created_date_gt?: string;
  /**
   * Select objects with `created_date` **greater than or equal** to this value
   */
  created_date_gte?: string;
  /**
   * Select objects with `created_date` in last N days
   */
  created_date_last_n_days?: number;
  /**
   * Select objects with `created_date` **less** than this value
   */
  created_date_lt?: string;
  /**
   * Select objects with `created_date` **less than or equal** to this value
   */
  created_date_lte?: string;
  /**
   * Select objects with `created_date` in last Nth week (including Sunday and Saturday)
   */
  created_date_nth_full_week?: number;
  /**
   * Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
   */
  created_date_nth_work_week?: number;
  /**
   * Select episodes where feature `detailed_upper_clothes` takes one of these values
   */
  detailed_upper_clothes?: Array<string>;
  /**
   * Select episodes where feature `emotions` takes one of these values
   */
  emotions?: Array<string>;
  /**
   * Select episodes with number of events is **greater or equal than or equal** to this value
   */
  events_count_gte?: number;
  /**
   * Select episodes where feature `gender` takes one of these values
   */
  gender?: Array<string>;
  /**
   * Select episodes where feature `glasses` takes one of these values
   */
  glasses?: Array<string>;
  /**
   * Select only episodes with body (`true`) or without body (`false`)
   */
  has_body?: boolean;
  /**
   * `true` - select only episodes relates to case. `false` - select only episodes without case.
   */
  has_case?: boolean;
  /**
   * Select only episodes with face (`true`) or without face (`false`)
   */
  has_face?: boolean;
  /**
   * Select episodes where feature `headwear` takes one of these values
   */
  headwear?: Array<string>;
  /**
   * Select episodes where feature `helmet_type` takes one of these values
   */
  helmet_type?: Array<string>;
  /**
   * Select episodes with provided ids
   */
  id_in?: Array<number>;
  /**
   * Select only the episode with the last body event of the given id
   */
  last_body_event?: string;
  /**
   * Select episodes with `last_body_event_date` **greater than or equal** to this value
   */
  last_body_event_date_gte?: string;
  /**
   * Select only the episode with the last face event of the given id
   */
  last_face_event?: string;
  /**
   * Select episodes with `last_face_event_date` **greater than or equal** to this value
   */
  last_face_event_date_gte?: string;
  /**
   * Number of results to return per page.
   */
  limit?: string;
  /**
   * Select episodes where feature `liveness` takes one of these values
   */
  liveness?: Array<string>;
  /**
   * Select episodes where feature `look` takes one of these values
   */
  look?: Array<string>;
  /**
   * Select episodes where feature `lower_clothes` takes one of these values
   */
  lower_clothes?: Array<string>;
  /**
   * Select episodes that match this card
   */
  matched_card?: number;
  /**
   * Select episodes associated with the specified watch lists
   */
  matched_lists?: Array<number>;
  /**
   * Select episodes where feature `medmask` takes one of these values
   */
  medmask?: Array<string>;
  /**
   * Select only episodes without matched body (`true`) or with matched body (`false`)
   */
  no_body_match?: boolean;
  /**
   * Select only episodes without matched face (`true`) or with matched face (`false`)
   */
  no_face_match?: boolean;
  /**
   * Select only open episodes (`true`) or closed (`false`) episodes
   */
  open?: boolean;
  /**
   * Какое поле использовать для сортировки результатов. Available fields: `created_date`, `id`, `uniform`
   */
  ordering?: string;
  /**
   * Pagination cursor value.
   */
  page?: string;
  /**
   * Select episodes where feature `top_color` takes one of these values
   */
  top_color?: Array<string>;
  /**
   * Select episodes where feature `upper_clothes` takes one of these values
   */
  upper_clothes?: Array<string>;
  /**
   * Select episodes where feature `vest_type` takes one of these values
   */
  vest_type?: Array<string>;
  /**
   * Select episodes associated with the specified video archives
   */
  video_archive?: Array<number>;
}
export const EmptyEpisodesHumansFilter: EpisodesHumansFilter = {
  acknowledged: undefined,
  acknowledged_by: undefined,
  acknowledged_reaction: undefined,
  age_group: [],
  age_gte: undefined,
  age_lte: undefined,
  bag_back: [],
  bag_ground: [],
  bag_hand: [],
  beard: [],
  body_gender: [],
  bottom_color: [],
  camera_groups: [],
  cameras: [],
  case_in: [],
  closed_date_gte: undefined,
  created_date_gt: undefined,
  created_date_gte: undefined,
  created_date_last_n_days: undefined,
  created_date_lt: undefined,
  created_date_lte: undefined,
  created_date_nth_full_week: undefined,
  created_date_nth_work_week: undefined,
  detailed_upper_clothes: [],
  emotions: [],
  events_count_gte: undefined,
  gender: [],
  glasses: [],
  has_body: undefined,
  has_case: undefined,
  has_face: undefined,
  headwear: [],
  helmet_type: [],
  id_in: [],
  last_body_event: undefined,
  last_body_event_date_gte: undefined,
  last_face_event: undefined,
  last_face_event_date_gte: undefined,
  limit: "20",
  liveness: [],
  look: [],
  lower_clothes: [],
  matched_card: undefined,
  matched_lists: [],
  medmask: [],
  no_body_match: undefined,
  no_face_match: undefined,
  open: undefined,
  ordering: undefined,
  page: undefined,
  top_color: [],
  upper_clothes: [],
  vest_type: [],
  video_archive: []
};