import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTable = _resolveComponent("NTable")!

  return (_openBlock(), _createBlock(_component_NTable, {
    "onUpdate:selectedChanges": _ctx.dispatchSelect,
    columns: _ctx.columns,
    content: _ctx.items,
    selected: _ctx.selectedItems,
    "selections-schema": _ctx.createIdTableSelectionColumn,
    selections: "",
    hoverable: ""
  }, null, 8, ["onUpdate:selectedChanges", "columns", "content", "selected", "selections-schema"]))
}