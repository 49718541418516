import { computed } from 'vue';
import NCheckbox from '../checkbox/NCheckbox.vue';
import { SelectChanges } from '../table/SelectChanges';

export class NTableSelectHelper<T extends { id: string | number }> {
  public items: T[];
  public selectedItemsIds: any[];
  public selectChangesModule = SelectChanges.create<number | string>();
  public emit: (name: string, payload: any) => void;

  constructor(items: T[], selectedItemsIds: string[] | number[], emit: (name: string, payload: any) => void) {
    this.items = items;
    this.selectedItemsIds = selectedItemsIds;
    this.emit = emit;
  }

  isPartialSelected = computed(() => {
    return this.realSelectedItemIds.length > 0 && this.realSelectedItemIds.length < this.items.length;
  });

  get realSelectedItemIds() {
    let itemIdsMap = Object.fromEntries(this.items.map((v) => [v.id, true]));
    return this.selectedItemsIds.filter((id) => itemIdsMap[id]);
  }

  get isAllItemsSelected() {
    return this.items.length > 0 && this.realSelectedItemIds.length === this.items.length;
  }

  public selectAll() {
    this.selectChangesModule.setSelected(this.selectedItemsIds);
    this.deselectAll();
    this.selectedItemsIds.push(...this.items.map((v) => v.id));
    this.emitSelect();
  }

  public deselectAll() {
    this.selectChangesModule.setSelected(this.selectedItemsIds);
    this.selectedItemsIds.splice(0, this.selectedItemsIds.length);
    this.emitSelect();
  }

  public selectAllToggle() {
    this.isAllItemsSelected ? this.deselectAll() : this.selectAll();
  }

  public get column() {
    return {
      width: '40px',
      header: {
        classes: ['bottom-border'],
        props: () => ({
          indeterminate: this.isPartialSelected
        }),
        encode: () => {
          this.selectAllToggle();
        },
        decode: () => {
          return this.isAllItemsSelected;
        },
        component: NCheckbox
      },
      content: {
        component: NCheckbox,
        encode: (cell: any) => {
          this.selectChangesModule.setSelected(this.selectedItemsIds);
          let selectedItemNum = this.selectedItemsIds.findIndex((id: string | number) => id === cell.id);
          selectedItemNum === -1 ? this.selectedItemsIds.push(cell.id) : this.selectedItemsIds.splice(selectedItemNum, 1);
          this.emitSelect();
        },
        decode: (cell: any) => {
          return !!this.selectedItemsIds.find((id: string | number) => id === cell.id);
        }
      }
    };
  }

  protected emitSelect() {
    const changes = this.selectChangesModule.getChanges(this.selectedItemsIds);
    this.emit('select:changes', changes);
    this.emit('select', this.selectedItemsIds);
  }
}
