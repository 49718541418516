
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NThumbnail from "@/uikit/thumbnail/NThumbnail.vue";
import { ListViewModel } from "@/definitions/view-models";
import { dataAssetsModule } from "@/store/application/data.assets.module";
import { loadDataModule } from "@/store/data/LoadDataModule";

@Options({
  name: 'ClusterItemTile',
  components: { NThumbnail }
})
export default class ClusterItemTile extends Vue {
  @Prop({ type: Object, required: true })
  item!: any;

  objectsVM: ListViewModel<any, any> | null = null;
  @Watch('item.id', { immediate: true })
  load(id: number) {
    if (!id) return;
    const objectType = dataAssetsModule.getObjectTypeByCardType('humans');
    this.objectsVM = loadDataModule.getObjectsLVMByCardID({ id: this.item.id, limit: 1, type: objectType, ordering: 'id' });
  }
  get thumbnail(): string | null {
    const hasItems = this.objectsVM?.items.length;
    return hasItems ? this.objectsVM?.items[0]?.thumbnail : null;
  }
  get name(): string {
    return this.item.name || this.$t('cards.unknown', 'f');
  }
}
