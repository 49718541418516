import { License } from '@/pages/license/types';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { LicensePageMetaItem } from '../../../meta';
import { i18n } from './types';
import { formatDate } from './utils';

type LicenseFormatters<T extends keyof License> = Record<T, LicenseFieldFormatter>;

type LicenseFieldFormatter = (license: License, i18n: i18n) => LicensePageMetaItem | null;

const formatters: LicenseFormatters<'generated' | 'last_updated' | 'license_id' | 'source' | 'type' | 'valid'> = {
  generated: formatLicenseGeneratedField,
  last_updated: formatLicenseLastUpdatedField,
  license_id: formatLicenseLicenseIdField,
  source: formatLicenseSourceField,
  type: formatLicenseTypeField,
  valid: formatLicenseValidField
};

const properties: Array<keyof typeof formatters> = ['valid', 'type', 'license_id', 'source', 'generated', 'last_updated'];

export function formatLicense(license: License, i18n: i18n): (LicensePageMetaItem | null)[] {
  return license ? properties.map((property) => formatters[property](license, i18n)) : [];
}

function formatLicenseGeneratedField(license: License, i18n: i18n): LicensePageMetaItem | null {
  if (isNumber(license.generated)) {
    return {
      label: i18n('license.generated'),
      content: { type: 'monospaced', content: isNumber(license.generated) ? formatDate(new Date(license.generated * 1000)) : '' }
    };
  }
  return null;
}

function formatLicenseLastUpdatedField(license: License, i18n: i18n): LicensePageMetaItem | null {
  if (isNumber(license.last_updated)) {
    const content = `${license.last_updated} ${i18n('license.second_ago_pf')} (${formatDate(new Date())})`;
    return { label: i18n('license.last_updated'), content: { type: 'monospaced', content } };
  }
  return null;
}

function formatLicenseLicenseIdField(license: License, i18n: i18n): LicensePageMetaItem | null {
  if (isString(license.license_id)) {
    return { label: i18n('license.license_id'), content: { type: 'monospaced', content: license.license_id } };
  }
  return null;
}

function formatLicenseSourceField(license: License, i18n: i18n): LicensePageMetaItem | null {
  if (isString(license.source)) {
    return { label: i18n('license.source'), content: { type: 'monospaced', content: license.source } };
  }
  return null;
}

function formatLicenseTypeField(license: License, i18n: i18n): LicensePageMetaItem | null {
  if (isString(license.type)) {
    return { label: i18n('license.type'), content: { type: 'regular', content: license.type ?? '' } };
  }
  return null;
}

function formatLicenseValidField(license: License, i18n: i18n): LicensePageMetaItem {
  return {
    label: i18n('license.valid'),
    content: {
      type: 'indicator',
      content: {
        disabled: !license.valid.value,
        label: i18n(`license.${license.valid.value ? 'yes' : 'no'}`)
      }
    }
  };
}
