/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AreaTriggerActivation = {
    readonly id: number;
    /**
     * AreaTrigger activation date
     */
    readonly created_date: string;
    /**
     * AreaTrigger that was activated
     */
    readonly area: number;
    /**
     * `true` if the area trigger is active at the moment. It remains active while conditions are met
     */
    readonly active: boolean;
    /**
     * Time in seconds the trigger has been active
     */
    readonly duration: number;
    /**
     * Max people count on the frame during trigger work
     */
    readonly max_body_count: number;
};
export const EmptyAreaTriggerActivation: AreaTriggerActivation = {
    id: 0,
    /**
     * AreaTrigger activation date
     */
    created_date: '',
    /**
     * AreaTrigger that was activated
     */
    area: 0,
    /**
     * `true` if the area trigger is active at the moment. It remains active while conditions are met
     */
    active: false,
    /**
     * Time in seconds the trigger has been active
     */
    duration: 0,
    /**
     * Max people count on the frame during trigger work
     */
    max_body_count: 0,
};
