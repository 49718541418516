
import PageContentLayout from '../PageContentLayout.vue';
import { defineComponent } from 'vue';
import SettingsMenu from '@/components/common/settings/SettingsMenu.vue';
import { multisidebarModule } from '@/store/multisidebar';
import { pageModule } from '@/store/application/page.module';
import { PagePath } from '@/store/application/page.definitions';

export default defineComponent({
  name: 'SettingsPageLayout',
  components: {
    SettingsMenu,
    PageContentLayout
  },
  computed: {
    sidebarModule() {
      return multisidebarModule;
    }
  },
  methods: {
    updatePageRoute() {
      const pageVM = pageModule.getPageViewModel(this.$route);
      pageVM.state.pagePath = this.$route.path as PagePath;
    }
  },
  activated() {
    this.sidebarModule.contentOverlap = true;
  },
  mounted() {
    this.sidebarModule.contentOverlap = true;
  },
  deactivated() {
    this.sidebarModule.contentOverlap = false;
  },
  beforeUnmount() {
    this.sidebarModule.contentOverlap = false;
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.updatePageRoute();
      }
    }
  }
});
