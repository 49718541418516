import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card-item-row__thumbnail" }
const _hoisted_2 = { class: "card-item-row__content" }
const _hoisted_3 = { class: "card-item-row__name_SIDEEFFECT card-item-row__ellipsis heading-m" }
const _hoisted_4 = {
  key: 1,
  class: "card-item-row__ellipsis label-mono-m"
}
const _hoisted_5 = {
  key: 2,
  class: "card-item-row__comment card-item-row__ellipsis label-xs"
}
const _hoisted_6 = {
  key: 0,
  "data-name": "actions",
  class: "card-item-row__actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_CardDate = _resolveComponent("CardDate")!
  const _component_WatchListsGroup = _resolveComponent("WatchListsGroup")!
  const _component_CardAttachments = _resolveComponent("CardAttachments")!
  const _component_CardMetafields = _resolveComponent("CardMetafields")!
  const _component_ActionsDropdown = _resolveComponent("ActionsDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes),
    style: _normalizeStyle(_ctx.styles),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showThumbnail)
        ? (_openBlock(), _createBlock(_component_NThumbnail, {
            key: 0,
            modelValue: _ctx.thumbnail,
            size: "large"
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showDate)
        ? (_openBlock(), _createBlock(_component_CardDate, {
            key: 0,
            modelValue: _ctx.item.created_date
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formattedName), 1),
      (_ctx.item.license_plate_number)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.item.license_plate_number), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_WatchListsGroup, {
        class: "card-item-row__watch-lists",
        items: _ctx.item.watch_lists
      }, null, 8, ["items"]),
      (_ctx.comment)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.formattedComment), 1))
        : _createCommentVNode("", true),
      (_ctx.showAttachments)
        ? (_openBlock(), _createBlock(_component_CardAttachments, {
            key: 3,
            item: _ctx.item,
            type: _ctx.type
          }, null, 8, ["item", "type"]))
        : _createCommentVNode("", true),
      (_ctx.showMetafields)
        ? (_openBlock(), _createBlock(_component_CardMetafields, {
            key: 4,
            item: _ctx.item,
            type: _ctx.type
          }, null, 8, ["item", "type"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showActions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_ActionsDropdown, {
            placement: "left-start",
            actions: _ctx.cardActions,
            onAction: _ctx.actionHandler
          }, null, 8, ["actions", "onAction"])
        ]))
      : _createCommentVNode("", true)
  ], 6))
}