
import { viewModelRepository } from '@/api/common';
import NRadio from '@/uikit/radio/NRadio.vue';
import NTable, { ITableColumn, ITableModel, ITableRow } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import HeaderBar from '@/components/common/HeaderBar.vue';
import NButtonModel from '@/uikit/buttons/NButtonModel.vue';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';

type Permission = Record<number, string | null>;

const permissionOrderedList = [
  { path: 'permission_view', value: 'view' },
  { path: 'permission_edit', value: 'edit' },
  { path: 'permission_delete', value: 'delete' }
];

@Options({
  name: 'Permissions',
  components: { HeaderBar, NRadio, NTable }
})
export default class Permissions extends Vue {
  @Prop({ type: Object, required: true })
  readonly modelValue!: Permission[];

  @Prop({ type: Array, default: () => [] })
  readonly disabledIds!: number[];

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hasDelete!: boolean;

  header: ITableRow = { checked_all: false };
  content: ITableModel = [];
  searchText = '';

  readonly module = viewModelRepository.getGroupsListViewModel();

  get columns(): ITableColumn[] {
    const result = [
      {
        header: {
          classes: ['bottom-border'],
          component: NTableColumnCell,
          props: { i18n_label: 'common.column_name' }
        },
        content: {
          path: 'name',
          component: NButtonModel,
          props: { type: 'link' }
        }
      },
      {
        width: '100px',
        header: {
          classes: ['align-center', 'bottom-border'],
          component: NTableColumnCell,
          props: { i18n_label: 'common.column_view' }
        },
        content: {
          path: 'permission_view',
          classes: ['align-center'],
          component: NCheckbox,
          props: (item: any) => ({ disabled: this.disabledIds.includes(item.id) })
        }
      },
      {
        width: '100px',
        header: {
          classes: ['align-center', 'bottom-border'],
          component: NTableColumnCell,
          props: { i18n_label: 'common.column_change' }
        },
        content: {
          path: 'permission_edit',
          classes: ['align-center'],
          component: NCheckbox,
          props: (item: any) => ({ disabled: this.disabledIds.includes(item.id) })
        }
      }
    ];

    if (this.hasDelete) {
      result.push({
        width: '100px',
        header: {
          classes: ['align-center', 'bottom-border'],
          component: NTableColumnCell,
          props: { i18n_label: 'common.column_delete' }
        },
        content: {
          path: 'permission_delete',
          classes: ['align-center'],
          component: NCheckbox,
          props: (item: any) => ({ disabled: this.disabledIds.includes(item.id) })
        }
      });
    }

    return result;
  }

  get permissions() {
    return this.content.filter((item: any) => item.name.toLowerCase().includes(this.searchText));
  }

  actionHandler({ type, row, path, value }: { type: string; row: any; path: string; value: any }) {
    if (type === 'change') {
      if (path === 'name') {
        this.openGroup(row.id);
        return;
      }

      if (value) {
        permissionOrderedList.forEach((permission) => {
          if (path === permission.path) this.modelValue[row.id] = permission.value;
        });
      } else {
        let value: any = null;
        permissionOrderedList.forEach((permission) => {
          if (path !== permission.path) {
            value = permission.value;
          } else {
            this.modelValue[row.id] = value;
          }
        });
      }
      this.$emit('update:modelValue', this.modelValue);
    }
  }

  @Watch('modelValue', { deep: true })
  recalculateTable() {
    if (!Array.isArray(this.modelValue)) {
      this.content = [];
    }
    const permissionListReversed = [...permissionOrderedList].reverse();
    this.content = this.module.items.map((item) => {
      let flag = false;
      let columns: any = {};
      permissionListReversed.forEach((permission) => {
        if (this.modelValue?.[item.id] === permission.value) flag = true;
        columns[permission.path] = flag;
      });
      return {
        ...item,
        ...columns
      };
    });
  }

  async mounted() {
    this.module.filter.current.limit = 1000;
    await this.module.get();
    this.recalculateTable();
  }

  openGroup(groupId: number) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Groups, rawItem: groupId });
  }
}
