import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["tabindex"]
const _hoisted_2 = { class: "n-tab__item" }
const _hoisted_3 = { class: "n-tab__label" }
const _hoisted_4 = {
  key: 1,
  class: "n-tab__counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_counter = _resolveComponent("n-counter")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["n-tab", _ctx.classes]),
    tabindex: _ctx.tabIndex,
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleActive && _ctx.handleActive(...args))),
    onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.keydownHandler && _ctx.keydownHandler(...args))),
    onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.focused = true)),
    onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.focused = false))
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.prefixIcon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "n-tab__prefix-icon",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handlePrefixAction && _ctx.handlePrefixAction(...args)), ["stop"]))
          }, [
            _createVNode(_component_n_icon, {
              name: _ctx.prefixIcon,
              width: _ctx.NIconDefaultParams.Width,
              height: _ctx.NIconDefaultParams.Height
            }, null, 8, ["name", "width", "height"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1),
      (_ctx.counter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_n_counter, { value: _ctx.counter }, null, 8, ["value"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.suffixIcon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "n-tab__suffix-icon",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSuffixAction && _ctx.handleSuffixAction(...args)), ["stop"]))
          }, [
            _createVNode(_component_n_icon, {
              name: _ctx.suffixIcon,
              width: _ctx.NIconDefaultParams.Width,
              height: _ctx.NIconDefaultParams.Height
            }, null, 8, ["name", "width", "height"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(`n-tab__line size-${_ctx.tabSize}`)
    }, null, 2)
  ], 42, _hoisted_1))
}