import { PageTypes } from '@/store/application/page.definitions';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';

export function getFilterByType(pageType: string): IFormLayoutItem[] {
  const options = { small: true };
  let filterSchema: IFormLayoutItem[] = [];

  if (pageType === PageTypes.BlockList) {
    filterSchema = [
      commonFilterSchemaModule.getUUIDInput(options),
      commonFilterSchemaModule.getReasonInput(options)
    ];
  }

  return filterSchema;
}
