import { OptionalIFormLayout, SimpleFilterOptions } from '@/components/common/filter/filters/types';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';

export function getLineFilters(options: SimpleFilterOptions): IFormLayoutItem[] {
  const filters: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getNameContains(options),
    commonFilterSchemaModule.getId(options),
    commonFilterSchemaModule.getActiveStatus(options),
    commonFilterSchemaModule.getCameraGroups(options),
    commonFilterSchemaModule.getCamerasDependedOnCameraGroups(options),
    commonFilterSchemaModule.getVideoArchive(options)
  ];

  return filters.filter((v) => !!v) as IFormLayoutItem[];
}
