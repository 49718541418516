/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { str_int_float_bool_object_array_null } from './str_int_float_bool_object_array_null';
export type FaceObjectUpdate = {
    readonly id: string;
    /**
     * Object creation date
     */
    readonly created_date: string;
    /**
     * Object modification date
     */
    readonly modified_date: string;
    /**
     * Filename supplied for `source_photo` on object creation
     */
    readonly source_photo_name: string;
    /**
     * Left border of object's bounding box
     */
    frame_coords_left?: number;
    /**
     * Top border of object's bounding box
     */
    frame_coords_top?: number;
    /**
     * Right border of object's bounding box
     */
    frame_coords_right?: number;
    /**
     * Bottom border of object's bounding box
     */
    frame_coords_bottom?: number;
    /**
     * Object thumbnail
     */
    readonly thumbnail: string;
    /**
     * `true` if object is enabled
     */
    active?: boolean;
    readonly features: Record<string, str_int_float_bool_object_array_null>;
    /**
     * Related `card` ID
     */
    card: number;
};
export const EmptyFaceObjectUpdate: FaceObjectUpdate = {
    id: '',
    /**
     * Object creation date
     */
    created_date: '',
    /**
     * Object modification date
     */
    modified_date: '',
    /**
     * Filename supplied for `source_photo` on object creation
     */
    source_photo_name: '',
    /**
     * Left border of object's bounding box
     */
    frame_coords_left: 0,
    /**
     * Top border of object's bounding box
     */
    frame_coords_top: 0,
    /**
     * Right border of object's bounding box
     */
    frame_coords_right: 0,
    /**
     * Bottom border of object's bounding box
     */
    frame_coords_bottom: 0,
    /**
     * Object thumbnail
     */
    thumbnail: '',
    /**
     * `true` if object is enabled
     */
    active: false,
    features: {},
    /**
     * Related `card` ID
     */
    card: 0,
};
