export type NLoaderType = typeof NLoaderTypes[keyof typeof NLoaderTypes];
export const NLoaderTypes = {
  Primary: 'primary',
  Secondary: 'secondary'
};

export type NLoadingDotsType = typeof NLoadingDotsTypes[keyof typeof NLoadingDotsTypes];
export const NLoadingDotsTypes = {
  Default: 'default',
  Inverted: 'inverted'
};
