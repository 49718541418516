/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AreaTriggerRecord = {
    readonly id: number;
    /**
     * AreaTriggerRecord creation date
     */
    readonly created_date: string;
    /**
     * Area for which record is created
     */
    readonly area: number;
    /**
     * Area trigger activation for which record is created
     */
    readonly area_trigger_activation: number;
    /**
     * People count on the frame
     */
    readonly body_count: number;
    /**
     * Array of the bbox coordinates of the counted bodies
     */
    readonly body_bboxes: Array<Array<Array<number>>>;
    readonly camera: number;
    fullframe: string;
    thumbnail: string;
    readonly camera_group: number | null;
    /**
     * Area service encountered an error while processing an area
     */
    readonly error: boolean;
    /**
     * Encountered error's message
     */
    readonly error_msg: string;
    /**
     * ROI points XY coordinates. *Example*: `[[0, 1000], [1000, 1000], [1000, 0], [0, 0]]`
     */
    readonly roi: Array<Array<number>>;
};
export const EmptyAreaTriggerRecord: AreaTriggerRecord = {
    id: 0,
    /**
     * AreaTriggerRecord creation date
     */
    created_date: '',
    /**
     * Area for which record is created
     */
    area: 0,
    /**
     * Area trigger activation for which record is created
     */
    area_trigger_activation: 0,
    /**
     * People count on the frame
     */
    body_count: 0,
    /**
     * Array of the bbox coordinates of the counted bodies
     */
    body_bboxes: [],
    camera: 0,
    fullframe: '',
    thumbnail: '',
    camera_group: 0,
    /**
     * Area service encountered an error while processing an area
     */
    error: false,
    /**
     * Encountered error's message
     */
    error_msg: '',
    /**
     * ROI points XY coordinates. *Example*: `[[0, 1000], [1000, 1000], [1000, 0], [0, 0]]`
     */
    roi: [],
};
