import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "calibration-form" }
const _hoisted_2 = { class: "calibration-form__status" }
const _hoisted_3 = { class: "n-flex" }
const _hoisted_4 = { class: "n-form-pad-10 n-grow" }
const _hoisted_5 = { class: "n-form-pad-10" }
const _hoisted_6 = { class: "n-form-pad-10" }
const _hoisted_7 = {
  key: 0,
  class: "calibration-form__helper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.calibrationStatusI18n), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_NInput, {
          modelValue: _ctx.calibrationTime,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.calibrationTime) = $event)),
          placeholder: "60"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_NButton, {
          onClick: _ctx.startCalibration,
          disabled: !_ctx.canStartCalibration
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('counters.calibrate_counter', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_NButton, {
          type: "secondary",
          onClick: _ctx.stopCalibration
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('counters.stop_calibration', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    (!_ctx.isDetectSilhouettes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('counters.save_changes_helper')), 1))
      : _createCommentVNode("", true)
  ]))
}