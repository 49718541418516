
import { defineComponent, PropType } from 'vue';
import { NLoadingDotsType, NLoadingDotsTypes } from './types';

export default defineComponent({
  name: 'NLoadingDots',
  props: {
    type: {
      type: String as PropType<NLoadingDotsType>,
      default: NLoadingDotsTypes.Default
    }
  }
});
