
import { defineComponent, PropType } from 'vue';
import { ReportsPageTableColumnLabelAlign } from '../types';

export default defineComponent({
  name: 'ReportsPageTableColumnLabel',
  props: {
    align: {
      default: 'left',
      type: String as PropType<ReportsPageTableColumnLabelAlign>
    }
  },
  computed: {
    classes() {
      const root = 'reports-page-table-column-label';
      return {
        [root]: true,
        [`${root}_align_${this.align}`]: true
      };
    }
  }
});
