
import { Options, Vue } from 'vue-class-component';
import { formatMenuItems, getItems, launchMenuItem } from '@/store/menu';
import { workspaceModule } from '@/store/application/workspace';
import { multisidebarModule } from '@/store/multisidebar';
import NInput from '@/uikit/input/NInput.vue';
import { LaunchpadItem } from '@/components/launcher/types';
import NIcon from '@/uikit/icons/NIcon.vue';
import { PagePath } from '@/store/application/page.definitions';
import { MenuItem } from '@/store/menu/menu';
import { configModule } from '@/store/config';

@Options({
  name: 'LauncherPage',
  components: { NIcon, NInput }
})
export default class LauncherPage extends Vue {
  private inputText = '';

  get formattedItems() {
    return formatMenuItems(this.$t, this.items);
  }

  get items() {
    return getItems();
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  getItemClasses(item: LaunchpadItem): Record<string, boolean> {
    const enabled = this.inputText ? (item.title + item.description).toLocaleLowerCase()?.indexOf(this.inputText.toLocaleLowerCase()) > -1 : true;
    return {
      'n-launcher__item': true,
      'n-launcher__item_disabled': !enabled
    };
  }

  generateId(path: string) {
    return `launcher.${path.replace('/', '')}`;
  }

  launcherItemClick(item: MenuItem) {
    launchMenuItem(item);
  }

  activated() {
    this.sidebarModule.contentOverlap = true;
  }

  mounted() {
    this.sidebarModule.contentOverlap = true;
  }

  deactivated() {
    this.sidebarModule.contentOverlap = false;
  }

  beforeUnmount() {
    this.sidebarModule.contentOverlap = false;
  }
}
