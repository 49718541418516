
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NAlertBadge from '@/uikit/alert-badge/NAlertBadge.vue';
import { License } from '@/pages/license';

@Options({
  name: 'LicenseError',
  components: { NAlertBadge }
})
export default class LicenseError extends Vue {
  @Prop({ type: String, default: () => {} })
  private license!: License;

  get isExpired() {
    const timeLimit = this.license?.limits?.find((item) => item.type === 'time');
    return timeLimit ? timeLimit.value <= this.license.time! : false;
  }
}
