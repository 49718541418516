import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "camera-screenshot__status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "camera-screenshot",
    style: _normalizeStyle(_ctx.style),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.refreshScreenshot && _ctx.refreshScreenshot(...args)))
  }, [
    _createElementVNode("img", {
      ref: "img",
      style: _normalizeStyle(_ctx.imageScreenshotStyle),
      alt: ""
    }, null, 4),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_NLoadingCircle, {
            key: 0,
            class: "camera-screenshot__loading"
          }))
        : (_ctx.error)
          ? (_openBlock(), _createBlock(_component_NIcon, {
              key: 1,
              name: "file-broken",
              width: "30",
              height: "30"
            }))
          : _createCommentVNode("", true)
    ]),
    (_ctx.hasPlay)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 0,
          round: "",
          icon: "play",
          class: "camera-screenshot__play",
          onClick: _withModifiers(_ctx.play, ["stop"])
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 4))
}