import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "login-face-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginVideoPreview = _resolveComponent("LoginVideoPreview")!

  return (_openBlock(), _createBlock(_Transition, {
    appear: "",
    name: "login-face-form_fadein"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_LoginVideoPreview, {
          stream: _ctx.stream,
          state: _ctx.authState
        }, null, 8, ["stream", "state"])
      ])
    ]),
    _: 1
  }))
}