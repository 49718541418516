
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '../buttons/NButton.vue';

@Options({
  name: 'NText',
  components: { NButton }
})
export default class NText extends Vue {
  @Prop({ type: [String, Number], default: '' })
  readonly modelValue!: string | number;

  @Prop({ type: String, default: 'break-word' })
  readonly overflowType!: string;

  @Prop({ type: String, default: '' })
  readonly defaultText!: string;

  @Prop({ type: String, default: 'label-m' })
  readonly defaultClass!: string;

  get text() {
    return this.modelValue === '' ? this.defaultText : this.modelValue;
  }

  get classes() {
    return {
      'n-text': true,
      [this.defaultClass]: true,
      'n-text_ellipsis': this.overflowType === 'ellipsis',
      'n-text_break-word': this.overflowType === 'break-word'
    };
  }
}
