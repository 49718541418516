/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ExternalVmsEvent = {
    readonly id: number;
    /**
     * VMS event creation date
     */
    readonly created_date: string;
    /**
     * External VMS event name
     */
    readonly name: string;
    /**
     * External VMS event type
     */
    readonly type: string;
    /**
     * External VMS event meta-information
     */
    readonly meta: Record<string, any>;
    /**
     * Target external VMS
     */
    readonly external_vms: number;
    /**
     * Target external VMS camera
     */
    readonly camera: number;
};
export const EmptyExternalVmsEvent: ExternalVmsEvent = {
    id: 0,
        /**
        * VMS event creation date
        */
    created_date: '',
        /**
        * External VMS event name
        */
    name: '',
        /**
        * External VMS event type
        */
    type: '',
        /**
        * External VMS event meta-information
        */
    meta: {},
        /**
        * Target external VMS
        */
    external_vms: 0,
        /**
        * Target external VMS camera
        */
    camera: 0,
};