
import NButton from '@/uikit/buttons/NButton.vue';
import { Options, Vue } from 'vue-property-decorator';

@Options({
  components: { NButton },
  emits: ['close']
})
export default class VideoWallPageSidebar extends Vue {
  dispatchCloseEvent(): void {
    this.$emit('close');
  }
}
