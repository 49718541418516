import { BodyCluster, CarCluster, FaceCluster } from '@/api';

export interface ListItem {
  label: string;
  name: string;
  icon?: string;
}

export type AnyCluster = BodyCluster | FaceCluster | CarCluster;

export type ClusterOpenedItem = typeof ClusterOpenedItems[keyof typeof ClusterOpenedItems];

export const ClusterOpenedItems = {
  Card: 'card',
  Cluster: 'cluster',
  NotSelected: ''
} as const;
