
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NSelect from '@/uikit/select/NSelect.vue';
import { ISelectItem } from '@/uikit';
import { viewModelRepository } from '@/api/common';
import { CarCard, HumanCard } from '@/api';
import { ListViewModel } from '@/definitions/view-models';

@Options({
  name: 'CardSelect',
  components: { NSelect }
})
export default class CardSelect extends Vue {
  // todo: fix this component; init value don't work on page load
  @Prop({ type: Number, required: true })
  readonly modelValue!: number;

  @Prop({ type: String })
  readonly prefixIcon?: string;

  @Prop({ type: Array })
  readonly idIn?: number[];

  @Prop({ type: String, default: 'human' })
  readonly cardType!: string;

  @Prop({ type: Boolean, default: false })
  readonly multiple!: boolean;

  module(): ListViewModel<any, any> {
    switch (this.cardType) {
      case 'car':
      case 'cars':
        return viewModelRepository.getCardsCarsListViewModel();
      case 'human':
      case 'humans':
      default:
        return viewModelRepository.getCardsHumansListViewModel();
    }
  }

  async loadItems(value: number[]): Promise<ISelectItem[]> {
    const module = this.module();
    module.filter.current.name_contains = '';
    module.filter.current.id_in = value;
    await module.get();
    return module.items.map((item) => ({
      value: item.id,
      label: item.name
    }));
  }

  async searchItems(value: string): Promise<ISelectItem[]> {
    const module = this.module();
    module.filter.current.id_in = this.idIn || [];
    module.filter.current.name_contains = value;
    await module.get();
    return module.items
      .map((item) => ({
        value: item.id,
        label: item.name
      }))
      .filter(({ label }) => label.toLowerCase().includes(value.toLocaleLowerCase()));
  }

  updateValue(value: number) {
    this.$emit('update:modelValue', value);
  }
}
