import { throttle } from 'lodash';
import axios from 'axios';
import { authModule } from '@/store/auth';

type CacheRecord = {
  result: Promise<string>;
  resolve: ResolveFunction;
  reject: RejectFunction;
  finished_at: number;
  status: CacheStatus;
  resetCache: boolean;
};

type ResolveFunction = (objectURL: string) => void;
type RejectFunction = (e: Error) => void;

const CacheStatuses = {
  WaitLoading: 'wait-loading',
  Loading: 'loading',
  Loaded: 'loaded',
  Error: 'error'
} as const;
type CacheStatus = typeof CacheStatuses[keyof typeof CacheStatuses];
const CacheTTLMilliseconds = 60_000;
const DefaultConcurrentCount = 3;

class ImageLoader {
  private cacheStore: Map<string, CacheRecord> = new Map();
  private concurrentCount = DefaultConcurrentCount;
  private loadingQueue: string[] = [];

  private get loadingCount() {
    return [...this.cacheStore.values()].filter((p) => p.status === CacheStatuses.Loading).length;
  }

  public async get(url: string, resetCache = false) {
    return this.cacheStore.has(url) && !resetCache ? this.getCacheResult(url) : this.pushToQueue(url, resetCache);
  }

  private cacheCleanerThrottled = throttle(this.cacheCleaner);

  private async cacheCleaner() {
    const now = Date.now();
    const entriesToDelete = [...this.cacheStore].filter(
      ([_, record]) => record.status !== CacheStatuses.WaitLoading && record.status !== CacheStatuses.Loading && record.finished_at + CacheTTLMilliseconds < now
    );

    for (const [key, record] of entriesToDelete) {
      try {
        const result = await record.result;
        URL.revokeObjectURL(result);
      } finally {
        this.cacheStore.delete(key);
      }
    }
  }

  private setCacheRecord(url: string, payload: Partial<CacheRecord>) {
    const record = (this.cacheStore.get(url) ?? {}) as CacheRecord;
    this.cacheStore.set(url, { ...record, ...payload });
  }

  private getCacheResult(url: string) {
    return this.cacheStore.get(url)!.result;
  }

  private pushToQueue(url: string, resetCache: boolean) {
    let resolve = (url: string) => {};
    let reject = (e: Error) => {};
    const promise = new Promise<string>((r, j) => {
      resolve = r;
      reject = j;
    });

    this.setCacheRecord(url, { result: promise, resolve, reject, resetCache, status: CacheStatuses.WaitLoading });
    setTimeout(this.cacheCleanerThrottled.bind(this), CacheTTLMilliseconds);
    this.loadingQueue.push(url);
    this.loadNextChunk();

    return promise;
  }

  private loadNextChunk() {
    if (this.loadingCount < this.concurrentCount) {
      for (let i = 0; i < this.concurrentCount; i++) {
        const url = this.loadingQueue.pop();
        const cacheRecord = url && this.cacheStore.get(url);
        if (url && cacheRecord) this.load(url, cacheRecord);
      }
    }
  }

  private load(url: string, cacheRecord: CacheRecord) {
    this.setCacheRecord(url, { status: CacheStatuses.Loading });
    axios({
      url,
      method: cacheRecord.resetCache ? 'POST' : 'GET',
      responseType: 'blob',
      headers: {
        Authorization: 'Token ' + encodeURIComponent(authModule.token!.toString())
      }
    })
      .then((response) => {
        cacheRecord.resolve(URL.createObjectURL(response.data));
        this.setCacheRecord(url, { status: CacheStatuses.Loaded });
        this.loadNextChunk();
      })
      .catch((e) => {
        this.setCacheRecord(url, { status: CacheStatuses.Error });
        cacheRecord.reject(e);
        this.loadNextChunk();
      });
  }
}

export const imageLoader = new ImageLoader();
