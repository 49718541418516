/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedSettingsRequest } from '../models/PatchedSettingsRequest';
import type { Settings } from '../models/Settings';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class SettingsService {
    /**
     * Retrieve FF.Multi settings
     * Use this method to retrieve the FindFace Multi settings.
     * @returns Settings
     * @throws ApiError
     */
    public static settingsRetrieve(): CancelablePromise<Settings> {
        return __request({
            method: 'GET',
            path: `/settings`,
        });
    }
    /**
     * Update FF.Multi settings
     * Use this method to update the FindFace Multi settings.
     * @param requestBody
     * @returns Settings
     * @throws ApiError
     */
    public static settingsPartialUpdate(
        requestBody?: PatchedSettingsRequest,
    ): CancelablePromise<Settings> {
        return __request({
            method: 'PATCH',
            path: `/settings`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}