import isFunction from 'lodash/isFunction';
import isObjectLike from 'lodash/isObjectLike';
import { CSSProperties, Ref, VNode } from 'vue';

export interface NTableBodyCell<T extends NTableContent> extends NTableCell {
  readonly model: T;
  readonly y: number;
}

export interface NTableCell {
  readonly key?: NTableColumnKey;
  readonly x: number;
}

export type Fragment = (NTableCellStaticContent | VNode)[];

export type NTableBodyCellSchema<T extends NTableContent> = NTableCellSchema<NTableBodyCell<T>>;

export type NTableBodyRow<T extends NTableContent> = NTableRow<NTableBodyCell<T>>;

export type NTableBodySchema<T extends NTableContent> = NTableSectionSchema<NTableBodyCell<T>>;

export type NTableBodySchemaDecorator<T extends NTableContent> = NTableSectionSchemaDecorator<NTableBodyCell<T>>;

export type NTableCellClass = NTableCellClassDict | NTableCellClassList;

export type NTableCellClassDict = Record<NTableCellClassName, boolean>;

export type NTableCellClassFactory<C extends NTableCell> = (cell: Readonly<C>) => NTableCellClass;

export type NTableCellClassList = (NTableCellClassDict | NTableCellClassList | NTableCellClassName)[];

export type NTableCellClassName = string;

export type NTableCellContent = Fragment | NTableCellStaticContent | VNode;

export type NTableCellContentFactory<C extends NTableCell> = (cell: C) => NTableCellContent;

export type NTableCellContentSchema<C extends NTableCell> = NTableCellContentFactory<C> | NTableCellStaticContent;

export type NTableCellCustomClass<C extends NTableCell> = NTableCellClass | NTableCellClassFactory<C>;

export type NTableCellCustomStyle<C extends NTableCell> = NTableCellStyle | NTableCellStyleFactory<C>;

export type NTableCellSchema<C extends NTableCell> = {
  readonly class?: NTableCellCustomClass<C>;
  readonly content: NTableCellContentSchema<C>;
  readonly style?: NTableCellCustomStyle<C>;
};

export type NTableCellStaticContent = number | string;

export type NTableCellStyle = CSSProperties;

export type NTableCellStyleFactory<C extends NTableCell> = (cell: Readonly<C>) => NTableCellStyle;

export type NTableColumn<T extends NTableContent> = {
  readonly body: NTableBodySchema<T>;
  readonly foot?: NTableFootSchema;
  readonly head?: NTableHeadSchema;
  readonly key?: NTableColumnKey;
  readonly minWidth?: NTableColumnWidth;
  readonly width?: NTableColumnWidth;
};

export type NTableColumnKey = string | symbol;

export type NTableColumnWidth = number | string;

export type NTableContent = TypeSafeObject;

export type NTableEvent = 'body-cell-click' | 'foot-cell-click' | 'head-cell-click' | 'row-click' | 'update:selected' | 'update:selectedChanges';

export type NTableFootCell = NTableCell;

export type NTableFootCellSchema = NTableCellSchema<NTableFootCell>;

export type NTableFootRow = NTableRow<NTableFootCell>;

export type NTableFootSchema = NTableSectionSchema<NTableFootCell>;

export type NTableFootSchemaDecorator = NTableSectionSchemaDecorator<NTableFootCell>;

export type NTableHeadCell = NTableCell;

export type NTableHeadCellSchema = NTableCellSchema<NTableHeadCell>;

export type NTableHeadRow = NTableRow<NTableHeadCell>;

export type NTableHeadSchema = NTableSectionSchema<NTableHeadCell>;

export type NTableHeadSchemaDecorator = NTableSectionSchemaDecorator<NTableHeadCell>;

export type NTablePropDict<T extends NTableContent> = {
  readonly bodyDecorators: NTableBodySchemaDecorator<T>[];
  readonly columns: NTableColumn<T>[];
  readonly content: T[];
  readonly fixedFoot: boolean;
  readonly fixedHead: boolean;
  readonly footDecorators: NTableFootSchemaDecorator[];
  readonly headDecorators: NTableHeadSchemaDecorator[];
  readonly hoverable: boolean;
  readonly selected: T[];
  readonly selections: boolean;
  readonly selectionsSchema: NTableSelectionColumnFactory<T>;
};

export type NTableRow<C extends NTableCell> = C[];

export type NTableSectionSchema<C extends NTableCell> = NTableCellContentSchema<C> | NTableCellSchema<C>;

export type NTableSectionSchemaDecorator<C extends NTableCell> = (schema: NTableSectionSchema<C>) => NTableSectionSchema<C>;

export type NTableSectionSchemaDecoratorFactory<C extends NTableCell> = (props: NTableSectionSchemaDecoratorFactoryProps<C>) => NTableSectionSchemaDecorator<C>;

export type NTableSectionSchemaDecoratorFactoryProps<C extends NTableCell> = Pick<NTableCellSchema<C>, 'class' | 'style'>;

export type NTableSelectionColumnFactory<T extends NTableContent> = (
  selected: Ref<Readonly<T[]>>,
  content: Ref<Readonly<T[]>>,
  update: (selected: T[]) => void,
  updateChanges: (selectedChanges: T[]) => void
) => NTableColumn<T>;

export type Nullable<T> = T | null;

export type Optional<T> = T | undefined;

type TypeSafeObject = Record<string, unknown>;

export function isTableCellClassFactory<C extends NTableCell>(customClass: NTableCellCustomClass<C>): customClass is NTableCellClassFactory<C> {
  return isFunction(customClass);
}

export function isTableCellContentFactory<C extends NTableCell>(schema: NTableCellContentSchema<C>): schema is NTableCellContentFactory<C> {
  return isFunction(schema);
}

export function isTableCellSchema<C extends NTableCell>(schema: NTableSectionSchema<C>): schema is NTableCellSchema<C> {
  return isObjectLike(schema);
}

export function isTableCellStyleFactory<C extends NTableCell>(customStyle: NTableCellCustomStyle<C>): customStyle is NTableCellStyleFactory<C> {
  return isFunction(customStyle);
}
