enum MoveType {
  NoMove = 0,
  LeftBorder = 1,
  RightBorder = 2,
  FullArea = 3
}

export default class VideoPlayerTimelineExport {
  enabled = false;
  timeFrom = 0;
  timeTo = 20;
  private moveType = MoveType.NoMove;
  private mouseTime = 0;
  private thresholdTime = 1;
  private oldExportTime = 0;

  checkLimits() {
    if (this.timeFrom + 10 > this.timeTo) {
      if (this.moveType === MoveType.LeftBorder) {
        this.timeFrom = this.timeTo - 10;
      } else {
        this.timeTo = this.timeFrom + 10;
      }
    }
  }

  setMouseTime(mouseTime: number) {
    this.mouseTime = mouseTime;
  }

  setThresholdTime(thresholdTime: number) {
    this.thresholdTime = thresholdTime;
  }

  isMouseInsideArea() {
    return this.mouseTime > this.timeFrom && this.mouseTime < this.timeTo;
  }

  isMouseOverLeftBorder() {
    return Math.abs(this.mouseTime - this.timeFrom) < this.thresholdTime;
  }

  isMouseOverRightBorder() {
    return Math.abs(this.mouseTime - this.timeTo) < this.thresholdTime;
  }

  mousedown() {
    if (this.isMouseInsideArea()) {
      this.moveType = MoveType.FullArea;
      this.oldExportTime = this.timeFrom;
    }

    if (this.isMouseOverLeftBorder()) {
      this.moveType = MoveType.LeftBorder;
      this.oldExportTime = this.timeFrom;
    }
    if (this.isMouseOverRightBorder()) {
      this.moveType = MoveType.RightBorder;
      this.oldExportTime = this.timeTo;
    }
  }

  mousemove(deltaTime: number) {
    if (this.moveType === MoveType.LeftBorder) {
      this.timeFrom = this.oldExportTime + deltaTime;
      return true;
    }
    if (this.moveType === MoveType.RightBorder) {
      this.timeTo = this.oldExportTime + deltaTime;
      return true;
    }
    if (this.moveType === MoveType.FullArea) {
      const len = this.timeTo - this.timeFrom;
      this.timeFrom = this.oldExportTime + deltaTime;
      this.timeTo = this.timeFrom + len;
      return true;
    }
    return false;
  }

  mouseup() {
    this.moveType = MoveType.NoMove;
  }

  toggleExportMode(enabled: boolean, areaStart: number, areaEnd: number) {
    this.enabled = enabled;
    if (this.enabled) {
      this.timeFrom = (areaStart * 2 + areaEnd) / 3;
      this.timeTo = (areaStart + areaEnd * 2) / 3;
    }
  }

  get cursor() {
    let cursor = '';
    if (this.isMouseInsideArea()) {
      cursor = 'move';
    }
    if (this.isMouseOverLeftBorder()) {
      cursor = 'w-resize';
    }
    if (this.isMouseOverRightBorder()) {
      cursor = 'e-resize';
    }
    return cursor;
  }
}
