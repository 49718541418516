/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * `CameraGroup` is a collection of `Camera`s, set up in same location or used for similar purposes
 */
export type CameraGroup = {
    readonly id: number;
    /**
     * Object creation date
     */
    readonly created_date: string;
    /**
     * Object modification date
     */
    readonly modified_date: string;
    /**
     * Process events from this camera group
     */
    active?: boolean;
    /**
     * Short group name, up to 256 characters
     */
    name: string;
    /**
     * Extended description of the user, up to 2048 chars
     */
    comment?: string;
    /**
     * True if events from this camera group are to be deduplicated
     */
    deduplicate?: boolean;
    /**
     * Event deduplication timeout, default value is 15
     */
    deduplicateDelay?: number;
    /**
     * Labels used to allocate a certain ``findface-video-worker`` instance to process video streams from this camera group
     */
    labels?: Record<string, string>;
    /**
     * Permissions on this camera group
     */
    permissions?: Record<string, string>;
    /**
     * Camera group face confidence threshold
     */
    face_threshold?: number | null;
    /**
     * Camera group body confidence threshold
     */
    body_threshold?: number | null;
    /**
     * Camera group car confidence threshold
     */
    car_threshold?: number | null;
};
export const EmptyCameraGroup: CameraGroup = {
    id: 0,
    /**
     * Object creation date
     */
    created_date: '',
    /**
     * Object modification date
     */
    modified_date: '',
    /**
     * Process events from this camera group
     */
    active: true,
    /**
     * Short group name, up to 256 characters
     */
    name: '',
    /**
     * Extended description of the user, up to 2048 chars
     */
    comment: '',
    /**
     * True if events from this camera group are to be deduplicated
     */
    deduplicate: false,
    /**
     * Event deduplication timeout, default value is 15
     */
    deduplicateDelay: 15,
    /**
     * Labels used to allocate a certain ``findface-video-worker`` instance to process video streams from this camera group
     */
    labels: {},
    /**
     * Permissions on this camera group
     */
    permissions: {},
    /**
     * Camera group face confidence threshold
     */
    face_threshold: 0,
    /**
     * Camera group body confidence threshold
     */
    body_threshold: 0,
    /**
     * Camera group car confidence threshold
     */
    car_threshold: 0,
};
