import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "n-settings-menu" }
const _hoisted_2 = {
  key: 1,
  class: "n-settings-menu__divider label-m"
}
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: item.path
      }, [
        (item.path)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: { path: item.path, query: item.query },
              class: "n-settings-menu__item control-m",
              "active-class": "n-settings-menu_active"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : (!item.external)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(item.title), 1))
            : (_openBlock(), _createElementBlock("a", {
                key: 2,
                href: item.serverPath,
                target: "_blank",
                class: "n-settings-menu__item control-m"
              }, _toDisplayString(item.title), 9, _hoisted_3))
      ], 64))
    }), 128))
  ]))
}