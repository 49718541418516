
import { NCheckbox, NInputNumber, NSwitch } from '@/uikit';
import NForm, { IFormContext, IFormLayout, IFormModel } from '@/uikit/forms/NForm.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NFormBlocks, { IFormLayoutBlocks } from '@/uikit/forms/NFormBlocks.vue';
import NCheckboxGroup from '@/uikit/checkbox/NCheckboxGroup.vue';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import { configModule } from '@/store/config';

type VMSCleanupSettings = {
  cleanup_between_tracks: boolean | null;
  keep_event_types: string[] | null;
  keep_event_before_after: number | null;
  cleanup_threshold: number | null;
  between_tracks_cleanup_start_age: number | null;

  cleanup_archive: boolean | null;
  archive_cleanup_age: number | null;
};

const VMSSettingsKeys: Record<string, keyof VMSCleanupSettings> = {
  CleanupBetweenTracks: 'cleanup_between_tracks',
  CleanupArchive: 'cleanup_archive'
};

export const VMSSettingsType = {
  Settings: 'settings',
  Camera: 'camera'
};

@Options({
  name: 'VMSCleanupForm',
  components: { NFormBlocks, NForm }
})
export default class VMSCleanupForm extends Vue {
  @Prop({ type: Object, required: true })
  modelValue!: VMSCleanupSettings;

  @Prop({ type: String, default: 'settings' })
  type = VMSSettingsType.Settings;

  @Prop({ type: Boolean, default: false })
  disabled = false;

  get hasVmsCleanup() {
    return configModule.services.vms_cleanup;
  }

  get formLayout(): IFormLayoutBlocks {
    return [...this.trackCleanupLayout, ...this.archiveCleanupLayout];
  }

  get archiveCleanupLayout(): IFormLayoutBlocks {
    let layout: IFormLayoutBlocks = [];
    const isSetArchiveCleanup = this.modelValue[VMSSettingsKeys.CleanupArchive] !== null || this.type === VMSSettingsType.Settings;
    const isArchiveCleanupEnabled = this.modelValue[VMSSettingsKeys.CleanupArchive] === true;

    const delimiter = {
      classes: 'n-form-w-6 n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'settings.cleanup_archive_header' }
    };

    const customCleanupArchive = {
      name: 'cleanup_archive',
      classes: 'label-m n-form-w-2 n-form-label-horizontal-200 n-form-pad-10',
      i18n_label: 'settings.custom_cleanup_archive',
      component: NSwitch,
      i18n_tooltip: 'settings.custom_cleanup_archive_desc',
      tooltipPlacement: 'bottom',
      encode: function (this: IFormContext, model: VMSCleanupSettings, value: string) {
        if (value) {
          this.model.cleanup_archive = true;
          this.model.archive_cleanup_age = 30;
        } else {
          this.model.cleanup_archive = null;
          this.model.archive_cleanup_age = null;
        }
      },
      decode: function (this: IFormContext) {
        return this.model.cleanup_archive !== null;
      }
    };

    const cleanupArchive = {
      path: 'cleanup_archive',
      classes: 'label-m n-form-w-2 n-form-label-horizontal-200 n-form-pad-10',
      i18n_label: 'settings.cleanup_archive',
      component: NCheckbox,
      i18n_tooltip: 'settings.cleanup_archive_desc',
      tooltipPlacement: 'bottom'
    };

    const cleanupAge = {
      path: 'archive_cleanup_age',
      classes: 'label-m n-form-w-3 n-form-label-horizontal-200 n-form-pad-10',
      i18n_label: 'settings.archive_cleanup_age',
      component: NInputNumber,
      i18n_tooltip: 'settings.archive_cleanup_age_desc',
      tooltipPlacement: 'bottom',
      props: { i18n_units: 'settings.days', min: 0 }
    };

    layout = [
      {
        layout: [delimiter]
      },
      {
        layout: [customCleanupArchive],
        display: () => this.type === VMSSettingsType.Camera,
        disabled: this.disabled
      },
      {
        layout: [cleanupArchive],
        display: () => isSetArchiveCleanup,
        disabled: this.disabled
      },
      {
        layout: [cleanupAge],
        display: () => isArchiveCleanupEnabled,
        disabled: this.disabled
      }
    ];

    return layout;
  }

  get trackCleanupLayout(): IFormLayoutBlocks {
    let layout: IFormLayoutBlocks = [];
    const isSetBetweenTrackCleanup = this.modelValue[VMSSettingsKeys.CleanupBetweenTracks] !== null || this.type === VMSSettingsType.Settings;
    const isEnabledBetweenTrackCleanup = this.modelValue[VMSSettingsKeys.CleanupBetweenTracks] === true;

    const delimiter = {
      classes: 'n-form-w-6 n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'settings.between_tracks_cleanup_header' }
    };

    const customCleanBetweenTracks = {
      name: 'cleanup_between',
      classes: 'label-m n-form-w-2 n-form-label-horizontal-200 n-form-pad-10',
      i18n_label: 'settings.custom_cleanup_between_tracks',
      component: NSwitch,
      i18n_tooltip: 'settings.custom_cleanup_between_tracks_desc',
      tooltipPlacement: 'bottom',
      encode: function (this: IFormContext, model: VMSCleanupSettings, value: string) {
        if (value) {
          this.model.cleanup_between_tracks = true;
          this.model.keep_event_types = ['face'];
          this.model.keep_event_before_after = 10;
          this.model.cleanup_threshold = 360;
          this.model.between_tracks_cleanup_start_age = 0;
        } else {
          this.model.cleanup_between_tracks = null;
          this.model.keep_event_types = null;
          this.model.keep_event_before_after = null;
          this.model.cleanup_threshold = null;
          this.model.between_tracks_cleanup_start_age = null;
        }
      },
      decode: function (this: IFormContext) {
        return this.model.cleanup_between_tracks !== null;
      }
    };

    const cleanBetweenTracks = {
      path: 'cleanup_between_tracks',
      classes: 'label-m n-form-w-2 n-form-label-horizontal-200 n-form-pad-10',
      i18n_label: 'settings.cleanup_between_tracks',
      component: NCheckbox,
      i18n_tooltip: 'settings.cleanup_between_tracks_desc',
      tooltipPlacement: 'bottom'
    };

    const cleanEventTypes = {
      path: 'keep_event_types',
      classes: 'label-m n-form-w-6 n-form-label-horizontal-200 n-form-pad-10',
      i18n_label: 'settings.keep_event_types',
      i18n_tooltip: 'settings.keep_event_types_desc',
      component: NCheckboxGroup,
      tooltipPlacement: 'bottom',
      props: {
        items: dataAssetsModule.availableObjects.map((v) => ({
          label: this.$t(`common.${v}`, 'f'),
          value: v
        }))
      }
    };

    const keepEvents = {
      path: 'keep_event_before_after',
      classes: 'label-m n-form-w-3 n-form-label-horizontal-200 n-form-pad-10',
      i18n_label: 'settings.keep_event_before_after',
      component: NInputNumber,
      i18n_tooltip: 'settings.keep_event_before_after_desc',
      tooltipPlacement: 'bottom',
      props: { i18n_units: 'settings.seconds', min: 10 }
    };

    const cleanupThreshold = {
      path: 'cleanup_threshold',
      classes: 'label-m n-form-w-3 n-form-label-horizontal-200 n-form-pad-10',
      i18n_label: 'settings.cleanup_threshold',
      component: NInputNumber,
      i18n_tooltip: 'settings.cleanup_threshold_desc',
      tooltipPlacement: 'bottom',
      props: { i18n_units: 'settings.seconds', min: 360 }
    };

    const cleanupAge = {
      path: 'between_tracks_cleanup_start_age',
      classes: 'label-m n-form-w-3 n-form-label-horizontal-200 n-form-pad-10',
      i18n_label: 'settings.between_tracks_cleanup_start_age',
      component: NInputNumber,
      i18n_tooltip: 'settings.between_tracks_cleanup_start_age_desc',
      tooltipPlacement: 'bottom',
      props: { i18n_units: 'settings.days', min: 0 }
    };

    layout = [
      {
        layout: [delimiter]
      },
      {
        layout: [customCleanBetweenTracks],
        display: () => this.type === VMSSettingsType.Camera,
        disabled: this.disabled
      },
      {
        layout: [cleanBetweenTracks],
        display: () => isSetBetweenTrackCleanup,
        disabled: this.disabled
      },
      {
        layout: [cleanEventTypes, keepEvents, cleanupThreshold, cleanupAge],
        display: () => isEnabledBetweenTrackCleanup,
        disabled: this.disabled
      }
    ];

    return layout;
  }
}
