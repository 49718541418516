
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Features from '@/components/common/Features.vue';

@Options({
  name: 'Attributes',
  components: { Features }
})
export default class Attributes extends Vue {
  @Prop({ type: Object, required: true })
  items: any;

  @Prop({ type: String, required: false })
  color?: string;

  @Prop({ type: String, default: '2x-small' })
  size!: string;
}
