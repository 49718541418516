import { ExceptionNotification } from '@/components/exception';

import { LanguageModule } from '@/store/languages';
import { NotificationOptions, NotificationRenderer } from '@/uikit/notification/helpers/types';
import { h, reactive } from 'vue';
import { NotificationsModule } from '../notifications';
import { Exception, ExceptionsModuleDeps, ExceptionSource } from './types';
import { asException, throwError } from './utils';
import { hashCode } from '@/uikit/helpers';

export type ExceptionNotificationOptions = Omit<NotificationOptions & { traceDisabled?: boolean }, 'content'>;

export class ExceptionsModule {
  private readonly language: LanguageModule;
  private readonly notifications: NotificationsModule;

  static create(dependencies: ExceptionsModuleDeps) {
    return reactive(new this(dependencies)) as ExceptionsModule;
  }

  private constructor(dependencies: ExceptionsModuleDeps) {
    this.language = dependencies.language;
    this.notifications = dependencies.notifications;
  }

  notifyThrownException(exception: Exception, options?: ExceptionNotificationOptions): void;
  notifyThrownException(exception: ExceptionSource, options?: ExceptionNotificationOptions): void;
  notifyThrownException(exception: Exception | ExceptionSource, options?: ExceptionNotificationOptions): void {
    const payload = asException(exception);
    payload ? this.notify(payload, options) : throwError('This is not an exception.');
  }

  private notify(exception: Exception, options: ExceptionNotificationOptions = {}): void {
    const { traceDisabled, ...notificationOptions } = options;
    this.notifications.notify({
      type: 'error',
      content: this.computeExceptionRenderer(exception, traceDisabled),
      threadId: this.computeThreadId(exception),
      ...notificationOptions
    });
  }

  private computeThreadId(exception: Exception): string {
    return exception.description ? String(hashCode(exception.description)) : '';
  }

  private computeExceptionRenderer(exception: Exception, traceDisabled: boolean = false): NotificationRenderer {
    return () => h(ExceptionNotification, { language: this.language, exception, traceDisabled });
  }
}
