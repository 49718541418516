import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "n-attachments-list-item-removing-popover" }
const _hoisted_2 = { class: "label-m n-attachments-list-item-removing-popover__content" }
const _hoisted_3 = { class: "n-attachments-list-item-removing-popover__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createBlock(_component_NHint, {
    "is-visible": _ctx.visible,
    "visible-arrow": false,
    "max-width": "159",
    placement: "bottom",
    type: "light",
    "visible-mode": "manual"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.i18n.removing_confirmation), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_NButton, {
            label: _ctx.i18n.cancel,
            onClick: _ctx.dispatchCancelEvent,
            type: "link"
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_NButton, {
            label: _ctx.i18n.yes,
            onClick: _ctx.dispatchRemoveEvent
          }, null, 8, ["label", "onClick"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["is-visible"]))
}