import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "detectors-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NHint, {
      placement: "left",
      delayVisible: 100,
      content: _ctx.$t('lines.data_source_detectors_settings')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", { ref: _ctx.reference }, _toDisplayString(_ctx.names), 513)
      ]),
      _: 1
    }, 8, ["content"])
  ]))
}