/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchRequest = {
    readonly id: number;
    /**
     * Creator ID
     */
    readonly created_by: number;
    /**
     * Search reason
     */
    reason: string;
    /**
     * Search request creation date
     */
    readonly created_date: string;
    /**
     * Search request modification date
     */
    readonly last_search_date: string;
    /**
     * Number of results in last search
     */
    readonly last_results_count: number;
    /**
     * Search card
     */
    card?: number | null;
    search_params: Record<string, any>;
    /**
     * Search result deletion flag
     */
    readonly is_deleted: boolean;
    /**
     * Flag to save search results
     */
    save_results?: boolean;
};
export const EmptySearchRequest: SearchRequest = {
    id: 0,
    /**
     * Creator ID
     */
    created_by: 0,
    /**
     * Search reason
     */
    reason: '',
    /**
     * Search request creation date
     */
    created_date: '',
    /**
     * Search request modification date
     */
    last_search_date: '',
    /**
     * Number of results in last search
     */
    last_results_count: 0,
    /**
     * Search card
     */
    card: null,
    search_params: {},
    /**
     * Search result deletion flag
     */
    is_deleted: false,
    /**
     * Flag to save search results
     */
    save_results: false,
};
