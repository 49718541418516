
import NTabs from '@/uikit/tabs/NTabs.vue';
import { defineComponent } from 'vue';
import { arrayOf, object, string } from 'vue-types';
import { LicensePageTab, LicensePageTabName } from './types';

export default defineComponent({
  name: 'LicensePageTabs',
  components: { NTabs },
  props: {
    modelValue: string<LicensePageTabName>().isRequired,
    tabs: arrayOf(object<LicensePageTab>()).isRequired
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(modelValue: LicensePageTabName) {
        this.$emit('update:modelValue', modelValue);
      }
    }
  }
});
