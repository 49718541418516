
import { computed, defineComponent, nextTick, ref, watch } from 'vue';
import { AuthState } from './login-helpers';

const Messages = {
  [AuthState.USER_FACE_VIDEO_RECORDING]: 'common.video_recording_in_progress',
  [AuthState.USER_FACE_IDENTIFICATION]: 'common.face_recognition_in_progress'
};

export default defineComponent({
  name: 'LoginVideoPreview',
  props: {
    stream: { type: MediaStream, required: true },
    state: { type: String, required: true }
  },
  setup(props) {
    const video = ref<null | HTMLVideoElement>(null);

    watch(() => props.stream, streamHandler, { immediate: true });
    function streamHandler(stream: MediaStream | null) {
      nextTick(() => {
        if (video.value) {
          video.value.srcObject = stream;
        }
      });
    }

    const stateMessage = computed(() => {
      const message = Messages[props.state as string];
      return message || '';
    });

    return {
      video,
      stateMessage
    };
  }
});
