import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "DataSourcesOnvifProfileStreams" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelect = _resolveComponent("NSelect")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NSelect, {
      modelValue: _ctx.profileToken,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.profileToken) = $event)),
      items: _ctx.streams,
      disabled: _ctx.disabled,
      "items-width": "auto"
    }, null, 8, ["modelValue", "items", "disabled"]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_NLoadingCircle, {
          key: 0,
          class: "DataSourcesOnvifProfileStreams__loading"
        }))
      : _createCommentVNode("", true)
  ]))
}