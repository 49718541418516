import { PicturesEnum, ReportFormatEnum, ReportMeta, TypeEnum } from '@/api';
import { languageModule } from '@/store/languages';
import { reactive, ref } from 'vue';
import { getTimezoneString } from '@/store/kyc/helpers';
import { MultisidebarItemType, MultisidebarItemTypes } from '@/store/multisidebar/types';

export type ReportFormModelType = {
  name?: string;
  pictures: PicturesEnum;
  report_format: ReportFormatEnum[];
  type: TypeEnum;
  filters: any;
  meta?: ReportMeta;
};

export class ReportsFormController {
  name = '';
  type = TypeEnum.FaceEvents;
  pictures = PicturesEnum.LINK;
  report_format = [ReportFormatEnum.XLS];
  filters = null;
  cameras_in = [];
  cameras_out = [];

  active = ref<boolean>(false);

  model: ReportFormModelType = reactive({
    name: this.name,
    type: this.type,
    pictures: this.pictures,
    report_format: this.report_format,
    filters: this.filters,
    meta: {
      cameras_in: this.cameras_in,
      cameras_out: this.cameras_out
    }
  });

  show(msbType: MultisidebarItemType, filters: any) {
    this.model.filters = { ...filters };
    this.model.type = this.getType(msbType);
    if (this.model.type === TypeEnum.KYC) {
      this.model.filters.timezone = getTimezoneString();
    }
    const token = this.model.type.replace(/-/g, '_').toLowerCase();
    this.model.name = languageModule.getTranslatedToken(`reports.report_by_${token}`, 'f');
    this.active.value = true;
  }

  hide() {
    this.active.value = false;
  }

  getType(msbType: MultisidebarItemType): TypeEnum {
    let result: TypeEnum = TypeEnum.None;

    switch (msbType) {
      case MultisidebarItemTypes.EpisodesCars:
        result = TypeEnum.CarEpisodes;
        break;
      case MultisidebarItemTypes.EpisodesHumans:
        result = TypeEnum.HumanEpisodes;
        break;

      case MultisidebarItemTypes.EventsFaces:
        result = TypeEnum.FaceEvents;
        break;
      case MultisidebarItemTypes.EventsBodies:
        result = TypeEnum.BodyEvents;
        break;
      case MultisidebarItemTypes.EventsCars:
        result = TypeEnum.CarEvents;
        break;

      case MultisidebarItemTypes.CardsHumans:
        result = TypeEnum.HumanCards;
        break;
      case MultisidebarItemTypes.CardsCars:
        result = TypeEnum.CarCards;
        break;

      case MultisidebarItemTypes.ClustersFaces:
        result = TypeEnum.FaceClusters;
        break;
      case MultisidebarItemTypes.ClustersBodies:
        result = TypeEnum.BodyClusters;
        break;
      case MultisidebarItemTypes.ClustersCars:
        result = TypeEnum.CarClusters;
        break;

      case MultisidebarItemTypes.AuditLogs:
        result = TypeEnum.AuditLogs;
        break;

      // From Analytics
      case MultisidebarItemTypes.KYC:
        result = TypeEnum.KYC;
        break;
      case MultisidebarItemTypes.ClustersFacesEvents:
        result = TypeEnum.FaceClusterEvents;
        break;

      case MultisidebarItemTypes.Cameras:
        result = TypeEnum.Cameras;
        break;

      case MultisidebarItemTypes.Counters:
        result = TypeEnum.Counters;
        break;

      case MultisidebarItemTypes.CounterRecords:
        result = TypeEnum.CounterRecords;
        break;

      case MultisidebarItemTypes.Lines:
        result = TypeEnum.Lines;
        break;
    }

    return result;
  }
}

export const reportsFormController = new ReportsFormController();
