/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ObjectsFacesFilter = {
    /**
     * Select only active objects (`true`) or inactive (`false`)
     */
    active?: boolean;
    /**
     * Get face objects from these `cards`
     */
    card?: Array<number>;
    /**
     * Select objects with provided ids
     */
    id_in?: Array<string>;
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * Which field to use when ordering the results. Available fields: `id`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
}
export const EmptyObjectsFacesFilter: ObjectsFacesFilter = {
    active: undefined,
    card: [],
    id_in: [],
    limit: 20,
    ordering: undefined,
    page: undefined,
}