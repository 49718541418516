/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type VmsPluginCamerasRequest = {
  /**
   * VMS camera ID
   */
  id: number;
  /**
   * VMS camera name
   */
  name: string;
  /**
   * VMS camera meta information
   */
  meta: Record<string, any>;
  /**
   * List of available VMS camera streams
   */
  streams: VmsPluginCameraStream[];
  /**
   * If `true` - the camera is added to the FindFace Multi
   */
  added: boolean;
  /**
   * Screenshot URL
   */
  readonly screenshot: string | null;
};

export type VmsPluginCameraStream = {
  readonly fps: unknown;
  readonly name: unknown;
  readonly resolution: unknown;
  readonly status: VmsPluginCameraStreamStatus;
  readonly type: 'video';
  readonly url: string;
};

export type VmsPluginCameraStreamStatus = 'offline' | 'online';

export const EmptyVmsPluginCamerasRequest: VmsPluginCamerasRequest = {
  /**
   * VMS camera ID
   */
  id: 0,
  /**
   * VMS camera name
   */
  name: '',
  /**
   * VMS camera meta information
   */
  meta: {},
  /**
   * List of available VMS camera streams
   */
  streams: [],
  /**
   * If `true` - the camera is added to the FindFace Multi
   */
  added: false,
  /**
   * Screenshot URL
   */
  screenshot: null
};
