
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref } from 'vue-property-decorator';
import NIcon from '../icons/NIcon.vue';
import NTabItem from './tab-item/NTabItem.vue';

interface TabItem {
  name: string;
  label: string;
  disabled?: boolean;
  [k: string]: any;
}

export const NTabPanelDirection = {
  vertical: 'vertical',
  horizontal: 'horizontal'
};

const SCROLL_STEP = 50;

@Options({
  components: { NTabItem, NIcon }
})
export default class NTabs extends Vue {
  @Prop({ type: String, default: 'normal' })
  size!: string;

  @Prop({ type: String, default: 'horizontal' })
  direction?: string;

  @Prop({ type: String })
  customClass?: string;

  @Prop({ type: Array, required: true })
  items!: TabItem[];

  @Prop({ type: String })
  modelValue!: string;

  @Prop({ type: String, default: undefined })
  postfix?: string;

  @Ref('scrollableEl')
  scrollableEl!: HTMLElement;

  scrollPosition = 0;
  clientWidth = 0;
  widthDiff = 0;

  get isVertical() {
    return this.direction === NTabPanelDirection.vertical;
  }

  get defaultTab() {
    return this.items && this.items[0]?.name;
  }

  setScrollWidthDiff() {
    const maxWidth = this.scrollableEl.clientWidth;
    const scrollWidth = this.scrollableEl.scrollWidth;
    this.widthDiff = scrollWidth - maxWidth;
    this.clientWidth = this.scrollableEl.clientWidth;
  }

  get showScrollArrows() {
    return !this.isVertical && this.widthDiff > 0;
  }

  getTabIndex(index: number) {
    if (this.items[index].disabled) {
      return -1;
    }
    return index + 1;
  }

  isActive(tab: string) {
    return this.modelValue === tab;
  }

  handleRewindScroll(type: string) {
    const scrollMoveInPercents = (SCROLL_STEP * this.clientWidth) / 100;
    switch (type) {
      case 'right':
        this.scrollableEl.scrollLeft += scrollMoveInPercents;
        break;
      case 'left':
        this.scrollableEl.scrollLeft -= scrollMoveInPercents;
        break;
    }
  }

  setScrollPosition() {
    this.scrollPosition = Number(((this.scrollableEl.scrollLeft / this.widthDiff) * 100).toFixed());
  }

  mounted() {
    this.setScrollWidthDiff();
    this.scrollableEl.addEventListener('scroll', this.setScrollPosition);
    if (!this.modelValue && this.defaultTab) {
      this.handleChange(this.defaultTab);
    } else if (!this.items.map((item) => item.name).includes(this.modelValue) && this.defaultTab) {
      this.handleChange(this.defaultTab);
    }
  }

  unmounted() {
    this.scrollableEl.removeEventListener('scroll', this.setScrollPosition);
  }

  handleChange(tab: string) {
    this.$emit('update:modelValue', tab);
  }

  @Emit('prefixAction')
  handlePrefixAction() {}

  @Emit('suffixAction')
  handleSuffixAction() {}
}
