/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CaseParticipantsFilter = {
    /**
     * Select case participants related to these cases
     */
    case_in?: Array<number>;
    /**
     * Select case participants with `face` or `car` clusters
     */
    cluster_type?: 'car' | 'face';
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select case participants with provided ids
     */
    id_in?: Array<number>;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select case participants that contain object similar to: (`detection:<detection id>`, `{face, body or car}event:<event id>`, `{face, body or car}object:<object id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`)
     */
    looks_like?: string;
    /**
     * Select case participants where the SQL condition `name ILIKE name_contains` is true
     */
    name_contains?: string;
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select case participants where `type` takes one of these values
     */
    role?: 'irrelevant' | 'suspect' | 'victim' | 'witness';
    /**
     * Similarity threshold for `looks_like`
     */
    threshold?: number;
}
export const EmptyCaseParticipantsFilter: CaseParticipantsFilter = {
    case_in: [],
    cluster_type: undefined,
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    id_in: [],
    limit: '20',
    looks_like: undefined,
    name_contains: undefined,
    ordering: undefined,
    page: undefined,
    role: undefined,
    threshold: undefined,
}
