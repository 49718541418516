type AttributeExtractor<T> = (item: T) => T | number | string;

export function defaultAttributeExtractor<T>(item: T) {
  return item;
}

export function idAttributeExtractor<T extends { id: number | string }>(item: T) {
  return item.id;
}

export function getItemsMap<T>(items: T[], extractor: AttributeExtractor<T>): Map<ReturnType<AttributeExtractor<T>>, T> {
  return new Map(items.map((v) => [extractor(v), v]));
}

export class SelectChanges<T> {
  public previousSelectedItems: T[] = [];
  protected attributeExtractor: AttributeExtractor<T>;

  constructor(selectedItems: T[] = [], attributeExtractor?: AttributeExtractor<T>) {
    this.previousSelectedItems = [...selectedItems];
    this.attributeExtractor = attributeExtractor || defaultAttributeExtractor;
  }

  public getChanges(items: T[]) {
    const prevItemsMap = getItemsMap(this.previousSelectedItems, this.attributeExtractor);
    const itemsMap = getItemsMap(items, this.attributeExtractor);
    const changes = items.filter((v) => !prevItemsMap.has(this.attributeExtractor(v)));
    changes.push(...this.previousSelectedItems.filter((v) => !itemsMap.has(this.attributeExtractor(v))));
    this.previousSelectedItems = [...items];
    return changes;
  }

  setSelected(items: T[]) {
    this.previousSelectedItems = [...items];
  }

  public static create<T>(selectedItems: T[] = [], attributeExtractor?: AttributeExtractor<T>) {
    return new this<T>(selectedItems, attributeExtractor);
  }
}
