import { configureConfidence, configureConfidenceRange, SimpleFilterOptions } from './types';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import { isDefined } from '@/definitions/services/helpers';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';

export type Position = typeof Positions[keyof typeof Positions];
export const Positions = {
  Start: 'start',
  End: 'end'
} as const;

export function isValidIpV4Address(address: string): boolean {
  return /(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/.test(address);
}

export function isValidIpV6Address(address: string): boolean {
  return /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/.test(
    address
  );
}

export function addRowClasses(options: SimpleFilterOptions, position: Position) {
  const rowClass = `n-form-item__${options.small ? 'small' : 'large'}_${position}`;
  const classes = options.classes ? `${options.classes} ${rowClass}` : rowClass;
  return { ...options, classes };
}

export function decodeScore(value: number | null | undefined) {
  return isNumber(value) ? value / 100 : value;
}

export function entcodeScore(v: number | null) {
  return isNil(v) ? v : Math.round(v * 100);
}

export type ConfidenceOptions = {
  confidenceFieldName: string;
  primaryFieldName: string;
  configPrimaryField: (options: SimpleFilterOptions) => IFormLayoutItem;
};

const nameDelimiter = '__';
export function encodeFormItemName(parts: string[]) {
  return parts.join(nameDelimiter);
}

export function decodeFormItemName(name: string) {
  return name.split(nameDelimiter);
}

export function getListFieldWithConfidence(options: SimpleFilterOptions, confidenceOptions: ConfidenceOptions): IFormLayoutItem[] {
  const { confidenceFieldName, primaryFieldName, configPrimaryField } = confidenceOptions;
  const fieldOptions = {
    ...addRowClasses(options, Positions.Start),
    checkForEmpty: (v: any) => !isDefined(v?.[confidenceFieldName])
  };

  const confidenceFormOptions = {
    path: confidenceFieldName,
    ...addRowClasses(options, Positions.End),
    checkForEmpty: (v: any) => !isDefined(v?.[primaryFieldName]) || !v?.[primaryFieldName].length
  };

  return [configPrimaryField(fieldOptions), configureConfidence(confidenceFormOptions)];
}
