import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-small-range" }
const _hoisted_2 = { class: "filter-small-range__label control-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterRange = _resolveComponent("FilterRange")!
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_FilterRange, _mergeProps({ ref: "range" }, _ctx.$props, {
      "has-clearable": false,
      plain: true
    }), null, 16),
    (_ctx.canClear)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 0,
          class: "filter-small-range__postfix-icon",
          name: "close",
          width: "12",
          height: "12",
          onClick: _ctx.reset
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}