import { getViewModelByName } from '@/api/common';
import { ItemViewModel } from '@/definitions/view-models';
import { getCurrentItemStateFromUrl, getExternalImage } from '@/pages/search/helpers';
import { CardSingleType, CardsSingleToMulti, CardTypesMap, ObjectsSingleToMulti, ObjectsTypesMap, SearchFromMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { PageState, SearchPageState } from '@/store/application/page.definitions';
import type { NAttachment } from '@/uikit/attachments';
import { reactive } from 'vue';

export class SearchModule {
  constructor(public state: SearchPageState) {}

  public bboxThumbnail: any;
  public attachment: NAttachment | null = null;
  private attachmentLoading = false;
  private attachmentError = false;
  private urlParseError = false;
  private modelCache_: [string, ItemViewModel<any> | null] = ['', null];

  public get loading() {
    return this.state.searchFrom === SearchFromMap.File ? this.attachmentLoading : this.model?.loading;
  }

  public get loaded() {
    return this.state.searchFrom === SearchFromMap.File ? !!this.attachment : this.model?.loaded;
  }

  public get hasError() {
    return this.state.searchFrom === SearchFromMap.File ? this.attachmentError : !!this.model?.loadError || this.urlParseError;
  }

  public get item() {
    return this.model?.item;
  }

  public get availableObjects() {
    let availableObject: string[] = [];
    switch (this.state.searchFrom) {
      case SearchFromMap.Events:
        availableObject = dataAssetsModule.availableObjects;
        break;
      case SearchFromMap.Clusters:
        availableObject = dataAssetsModule.availableClusterObjects;
        break;
      case SearchFromMap.Cards:
        availableObject = dataAssetsModule.availableCardTypes;
        break;
    }

    return availableObject.map((v) => CardsSingleToMulti[v as CardSingleType] || ObjectsSingleToMulti[v]);
  }

  public get availableSearchFromItems() {
    return dataAssetsModule.searchFromTypes.filter((v) => v.value !== SearchFromMap.File);
  }

  public async tryLoadItemOrImage(idOrUrl: string) {
    this.resetErrors();
    if (idOrUrl.startsWith('http')) {
      const itemState = getCurrentItemStateFromUrl(idOrUrl);
      if (Object.keys(itemState).length) {
        if (this.availableSearchFromItems.find((v) => v.value === itemState.pageType)) {
          this.setStateByUrlParseResult(itemState);
          await this.loadItem();
        } else {
          this.urlParseError = true;
        }
      } else {
        this.state.searchFrom = SearchFromMap.File;
        await this.externalImageHandler(idOrUrl);
      }
    } else {
      await this.loadItem();
    }
  }

  private setStateByUrlParseResult(itemState: Partial<PageState>) {
    if (itemState.pageType && itemState.objectType && itemState.id && this.availableSearchFromItems.find((v) => v.value === itemState.pageType)) {
      const result: Record<string, any> = {
        id: itemState.id,
        objectType: itemState.objectType,
        cardType: itemState.objectType === ObjectsTypesMap.Cars ? CardTypesMap.Cars : CardTypesMap.Humans,
        episodeType: itemState.objectType === ObjectsTypesMap.Cars ? CardTypesMap.Cars : CardTypesMap.Humans,
        searchFrom: itemState.pageType
      };
      Object.assign(this.state, result);
    }
  }

  private async externalImageHandler(url: string) {
    try {
      this.attachment = null;
      this.attachmentError = false;
      this.attachmentLoading = true;
      const externalImage = await getExternalImage(url);
      this.attachment = {
        id: Math.random(),
        file: new File([externalImage], 'loaded-image.jpg', { type: 'image/jpeg' }),
        name: url,
        status: 'done',
        progress: 0,
        size: 0
      };
    } catch (e) {
      console.warn('[search]: external image loading failed: ' + url);
      this.attachmentError = true;
    } finally {
      this.attachmentLoading = false;
    }
  }

  private get modelType() {
    const { searchFrom, objectType, cardType } = this.state;
    if (!searchFrom || searchFrom === SearchFromMap.File) return null;
    if (searchFrom === SearchFromMap.Events && !objectType) return null;
    if ((searchFrom === SearchFromMap.Cluster || searchFrom === SearchFromMap.Cards) && !cardType) return null;
    return this.state.searchFrom === SearchFromMap.Cards ? `${searchFrom}_${cardType}` : `${searchFrom}_${objectType}`;
  }

  private get model() {
    if (this.modelType) {
      if (this.modelCache_[0] !== this.modelType) {
        this.modelCache_ = [this.modelType, getViewModelByName(this.modelType) as ItemViewModel<any>];
      }
      return this.modelCache_[1];
    }
    return null;
  }

  private async loadItem() {
    if (this.model && this.state.id) {
      try {
        await this.model.get(this.state.id);
        this.state.detectionId = String(this.state.id);
      } catch (e) {
        if (!e.isAxiosError) {
          throw e;
        }
      }
    }

    if (!this.state.id) {
      if (this.modelType) this.modelCache_ = [this.modelType, getViewModelByName(this.modelType) as ItemViewModel<any>];
      this.state.detectionId = '';
    }
  }

  public resetErrors() {
    this.attachmentError = false;
    this.urlParseError = false;
    this.model && (this.model.loadError = null);
  }

  public static create(state: SearchPageState) {
    return reactive(new this(state));
  }
}
