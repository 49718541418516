import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NBboxDistances = _resolveComponent("NBboxDistances")!

  return (_openBlock(), _createElementBlock("div", {
    class: "n-image-viewer-bbox-distances-layer",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createVNode(_component_NBboxDistances, {
      distances: _ctx.distances,
      scale: _ctx.scale
    }, null, 8, ["distances", "scale"])
  ], 4))
}