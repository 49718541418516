/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountResponse } from '../models/CountResponse';
import type { PaginatedUserList } from '../models/PaginatedUserList';
import type { PatchedUserRequest } from '../models/PatchedUserRequest';
import type { User } from '../models/User';
import type { UserRequest } from '../models/UserRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import { UserSavedData } from "@/components/common/filter/filter-manager";
export class UsersService {
    /**
     * List users
     * Use this method to list users.
     * @param active Select only active users (`true`) or inactive (`false`) users
     * @param dateJoinedGt Select objects with `date_joined` **greater** than this value
     * @param dateJoinedGte Select objects with `date_joined` **greater than or equal** to this value
     * @param dateJoinedLt Select objects with `date_joined` **less** than this value
     * @param dateJoinedLte Select objects with `date_joined` **less than or equal** to this value
     * @param groups Select users belonging to this group.
     * @param hasFace Select only users with uploaded image (`true`) or without an image (`false`)
     * @param idIn Select users with provided ids
     * @param language Get users with this language selected
     * @param limit Number of results to return per page.
     * @param ordering Which field to use when ordering the results. Available fields: `id`, `date_joined`, `modified_date`, `username`, `first_name`, `last_name`, `primary_group`, `is_active`
     * @param page Pagination cursor value.
     * @param primaryGroup Select users whose primary group is this group.
     * @param realNameContains Select users where the SQL condition `real_name ILIKE real_name_contains` is true
     * @param usernameContains Select users where the SQL condition `username ILIKE username_contains` is true
     * @returns PaginatedUserList
     * @throws ApiError
     */
    public static usersList(
        active?: boolean,
        dateJoinedGt?: string,
        dateJoinedGte?: string,
        dateJoinedLt?: string,
        dateJoinedLte?: string,
        groups?: Array<number>,
        hasFace?: boolean,
        idIn?: Array<number>,
        language?: 'en-us' | 'es' | 'ru',
        limit?: string,
        ordering?: string,
        page?: string,
        primaryGroup?: Array<number>,
        realNameContains?: string,
        usernameContains?: string,
    ): CancelablePromise<PaginatedUserList> {
        return __request({
            method: 'GET',
            path: `/users/`,
            query: {
                'active': active,
                'date_joined_gt': dateJoinedGt,
                'date_joined_gte': dateJoinedGte,
                'date_joined_lt': dateJoinedLt,
                'date_joined_lte': dateJoinedLte,
                'groups': groups,
                'has_face': hasFace,
                'id_in': idIn,
                'language': language,
                'limit': limit,
                'ordering': ordering,
                'page': page,
                'primary_group': primaryGroup,
                'real_name_contains': realNameContains,
                'username_contains': usernameContains,
            },
        });
    }
    /**
     * Add a new user
     * Use this method to add a new user.
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static usersCreate(
        requestBody: UserRequest,
    ): CancelablePromise<User> {
        return __request({
            method: 'POST',
            path: `/users/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve user by ID
     * Use this method to retrieve information on the current user.
     * @param id A unique integer value identifying this User.
     * @returns User
     * @throws ApiError
     */
    public static usersRetrieve(
        id: number,
    ): CancelablePromise<User> {
        return __request({
            method: 'GET',
            path: `/users/${id}/`,
        });
    }
    /**
     * Update user
     * Use this method to update a user data.
     * @param id A unique integer value identifying this User.
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static usersPartialUpdate(
        id: number,
        requestBody?: PatchedUserRequest,
    ): CancelablePromise<User> {
        return __request({
            method: 'PATCH',
            path: `/users/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete user
     * Use this method to delete a user.
     * @param id A unique integer value identifying this User.
     * @returns void
     * @throws ApiError
     */
    public static usersDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/users/${id}/`,
        });
    }
    /**
     * Objects count
     * This method retrieves count of objects.
     * @param active Select only active users (`true`) or inactive (`false`) users
     * @param dateJoinedGt Select objects with `date_joined` **greater** than this value
     * @param dateJoinedGte Select objects with `date_joined` **greater than or equal** to this value
     * @param dateJoinedLt Select objects with `date_joined` **less** than this value
     * @param dateJoinedLte Select objects with `date_joined` **less than or equal** to this value
     * @param groups Select users belonging to this group.
     * @param hasFace Select only users with uploaded image (`true`) or without an image (`false`)
     * @param idIn Select users with provided ids
     * @param language Get users with this language selected
     * @param primaryGroup Select users whose primary group is this group.
     * @param realNameContains Select users where the SQL condition `real_name ILIKE real_name_contains` is true
     * @param usernameContains Select users where the SQL condition `username ILIKE username_contains` is true
     * @returns CountResponse
     * @throws ApiError
     */
    public static usersCountRetrieve(
        active?: boolean,
        dateJoinedGt?: string,
        dateJoinedGte?: string,
        dateJoinedLt?: string,
        dateJoinedLte?: string,
        groups?: Array<number>,
        hasFace?: boolean,
        idIn?: Array<number>,
        language?: 'en-us' | 'es' | 'ru',
        primaryGroup?: Array<number>,
        realNameContains?: string,
        usernameContains?: string,
    ): CancelablePromise<CountResponse> {
        return __request({
            method: 'GET',
            path: `/users/count/`,
            query: {
                'active': active,
                'date_joined_gt': dateJoinedGt,
                'date_joined_gte': dateJoinedGte,
                'date_joined_lt': dateJoinedLt,
                'date_joined_lte': dateJoinedLte,
                'groups': groups,
                'has_face': hasFace,
                'id_in': idIn,
                'language': language,
                'primary_group': primaryGroup,
                'real_name_contains': realNameContains,
                'username_contains': usernameContains,
            },
        });
    }
    /**
     * Retrieve info on current user
     * @returns User
     * @throws ApiError
     */
    public static usersMeRetrieve(): CancelablePromise<User> {
        return __request({
            method: 'GET',
            path: `/users/me/`,
        });
    }

    /**
     * Retrieve current user saved data
     * @returns Array<UserSavedData>
     * @throws ApiError
     */
    public static usersMeDataList(): CancelablePromise<Array<UserSavedData>> {
        return __request({
            method: 'GET',
            path: `/users/me/data/`
        });
    }
    /**
     * Retrieve current user saved data by key
     * Use this method to retrieve information on the current user saved data field.
     * @param key A unique string identifying current user.
     * @returns UserSavedData
     * @throws ApiError
     */
    public static usersMeDataRetrieve(key: string): CancelablePromise<UserSavedData> {
        return __request({
            method: 'GET',
            path: `/users/me/data/${key}/`
        });
    }
    /**
     * Update current user saved data by key
     * Use this method to update saved data on the current user by key
     * @param key A unique string value identifying current user saved data field.
     * @returns Array<UserSavedData>
     * @throws ApiError
     */
    public static usersMeDataUpdate(  key: string, requestBody: Request): CancelablePromise<Array<UserSavedData>> {
        return __request({
            method: 'PUT',
            path: `/users/me/data/${key}/`,
            body: requestBody,
            mediaType: 'application/json'
        });
    }
    /**
     * Delete current user saved data by key
     * Use this method to delete user saved data.
     * @param key A unique string value identifying current user saved data field
     * @returns void
     * @throws ApiError
     */
    public static usersMeDataDestroy(key: string): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/users/me/data/${key}/`
        });
    }
}

export type Request = {
    key: string;
    value: string;
};
