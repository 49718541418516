
import { defineComponent, PropType } from 'vue';
import { ReportsPageTableColumnLabelAlign } from '../types';
import { ReportsPageTableColumnLabel } from './index';

export default defineComponent({
  name: 'ReportsPageTableColumnLabelMono',
  components: { ReportsPageTableColumnLabel },
  props: {
    align: {
      default: 'left',
      type: String as PropType<ReportsPageTableColumnLabelAlign>
    }
  }
});
