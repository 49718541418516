/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { BodyEventsFeaturesEnum } from './BodyEventsFeaturesEnum';
import { CarEventsFeaturesEnum } from './CarEventsFeaturesEnum';
import { FaceEventsFeaturesEnum } from './FaceEventsFeaturesEnum';
export type Settings = {
  /**
   * Face similarity threshold
   */
  face_confidence_threshold: number;
  /**
   * Body similarity threshold
   */
  body_confidence_threshold: number;
  /**
   * Car similarity threshold
   */
  car_confidence_threshold: number;
  /**
   * Human episodes face threshold
   */
  face_episodes_threshold: number;
  /**
   * Human episodes body threshold
   */
  body_episodes_threshold: number;
  /**
   * Car episodes car threshold
   */
  car_episodes_threshold: number;
  /**
   * Minimum face quality for inclusion in a card
   */
  minimum_face_quality: number;
  /**
   * Minimum car quality for inclusion in a card
   */
  minimum_car_quality: number;
  /**
   * Minimum body quality for inclusion in a card
   */
  minimum_body_quality: number;
  /**
   * If enabled, unmatched events won't be saved to the database
   */
  ignore_unmatched: boolean;
  /**
   * By setting this option to "true", you indicate that you have read and accepted the FindFace Security         license agreement.
   */
  license_accepted: boolean;
  /**
   * Matched face events older than `events_max_age` will be removed every night by the cleanup script
   */
  face_events_max_matched_age: number | null;
  /**
   * Unmatched face events older than `events_max_age` will be removed every night by the cleanup script
   */
  face_events_max_unmatched_age: number;
  /**
   * Fullframes from matched face events older than `events_max_fullframe_matched_age` will be removed every night by the cleanup script
   */
  face_events_max_fullframe_matched_age: number | null;
  /**
   * Fullframes from unmatched face events older than `events_max_fullframe_unmatched_age` will be removed every night by the cleanup script
   */
  face_events_max_fullframe_unmatched_age: number | null;
  /**
   * Face cluster events older than `face_cluster_events_max_age` will be removed every night by the cleanup script
   */
  face_cluster_events_max_age: number | null;
  /**
   * Face cluster events except of best cluster event older than `face_cluster_events_max_age` will be removed every night by the cleanup script
   */
  face_cluster_events_keep_best_max_age: number | null;
  /**
   * Matched car events older than `events_max_age` will be removed every night by the cleanup script
   */
  car_events_max_matched_age: number | null;
  /**
   * Unmatched car events older than `events_max_age` will be removed every night by the cleanup script
   */
  car_events_max_unmatched_age: number;
  /**
   * Fullframes from matched car events older than `events_max_fullframe_matched_age` will be removed every night by the cleanup script
   */
  car_events_max_fullframe_matched_age: number | null;
  /**
   * Fullframes from unmatched car events older than `events_max_fullframe_unmatched_age` will be removed every night by the cleanup script
   */
  car_events_max_fullframe_unmatched_age: number | null;
  /**
   * Car cluster events older than `car_cluster_events_max_age` will be removed every night by the cleanup script
   */
  car_cluster_events_max_age: number | null;
  /**
   * Car cluster events except of best cluster event older than `car_cluster_events_max_age` will be removed every night by the cleanup script
   */
  car_cluster_events_keep_best_max_age: number | null;
  /**
   * Matched body events older than `events_max_age` will be removed every night by the cleanup script
   */
  body_events_max_matched_age: number | null;
  /**
   * Unmatched body events older than `events_max_age` will be removed every night by the cleanup script
   */
  body_events_max_unmatched_age: number;
  /**
   * Fullframes from matched body events older than `events_max_fullframe_matched_age` will be removed every night by the cleanup script
   */
  body_events_max_fullframe_matched_age: number | null;
  /**
   * Fullframes from unmatched body events older than `events_max_fullframe_unmatched_age` will be removed every night by the cleanup script
   */
  body_events_max_fullframe_unmatched_age: number | null;
  /**
   * Body cluster events older than `body_cluster_events_max_age` will be removed every night by the cleanup script
   */
  body_cluster_events_max_age: number | null;
  /**
   * Body cluster events except of best cluster event older than `body_cluster_events_max_age` will be removed every night by the cleanup script
   */
  body_cluster_events_keep_best_max_age: number | null;
  /**
   * Features to extract when creating face events
   */
  face_events_features: Array<FaceEventsFeaturesEnum>;
  /**
   * Features to extract when creating body events
   */
  body_events_features: Array<BodyEventsFeaturesEnum>;
  /**
   * Features to extract when creating car events
   */
  car_events_features: Array<CarEventsFeaturesEnum>;
  /**
   * Liveness classifier threshold (if liveness is enabled)
   */
  liveness_threshold: number;
  /**
   * Thumbnail JPEG quality, default is 75
   */
  thumbnail_jpeg_quality: number;
  /**
   * Delete VMS video chunks which are older than vms_videos_max_age parameter
   */
  vms_videos_max_age: number | null;
};
export const EmptySettings: Settings = {
  /**
   * Face similarity threshold
   */
  face_confidence_threshold: 0,
  /**
   * Body similarity threshold
   */
  body_confidence_threshold: 0,
  /**
   * Car similarity threshold
   */
  car_confidence_threshold: 0,
  /**
   * Human episodes face threshold
   */
  face_episodes_threshold: 0,
  /**
   * Human episodes body threshold
   */
  body_episodes_threshold: 0,
  /**
   * Car episodes car threshold
   */
  car_episodes_threshold: 0,
  /**
   * Minimum face quality for inclusion in a card
   */
  minimum_face_quality: 0,
  /**
   * Minimum car quality for inclusion in a card
   */
  minimum_car_quality: 0,
  /**
   * Minimum body quality for inclusion in a card
   */
  minimum_body_quality: 0,
  /**
   * If enabled, unmatched events won't be saved to the database
   */
  ignore_unmatched: false,
  /**
   * By setting this option to "true", you indicate that you have read and accepted the FindFace Security         license agreement.
   */
  license_accepted: true,
  /**
   * Matched face events older than `events_max_age` will be removed every night by the cleanup script
   */
  face_events_max_matched_age: 0,
  /**
   * Unmatched face events older than `events_max_age` will be removed every night by the cleanup script
   */
  face_events_max_unmatched_age: 0,
  /**
   * Fullframes from matched face events older than `events_max_fullframe_matched_age` will be removed every night by the cleanup script
   */
  face_events_max_fullframe_matched_age: 0,
  /**
   * Fullframes from unmatched face events older than `events_max_fullframe_unmatched_age` will be removed every night by the cleanup script
   */
  face_events_max_fullframe_unmatched_age: 0,
  /**
   * Face cluster events older than `face_cluster_events_max_age` will be removed every night by the cleanup script
   */
  face_cluster_events_max_age: 0,
  /**
   * Face cluster events except of best cluster event older than `face_cluster_events_max_age` will be removed every night by the cleanup script
   */
  face_cluster_events_keep_best_max_age: 0,
  /**
   * Matched car events older than `events_max_age` will be removed every night by the cleanup script
   */
  car_events_max_matched_age: 0,
  /**
   * Unmatched car events older than `events_max_age` will be removed every night by the cleanup script
   */
  car_events_max_unmatched_age: 0,
  /**
   * Fullframes from matched car events older than `events_max_fullframe_matched_age` will be removed every night by the cleanup script
   */
  car_events_max_fullframe_matched_age: 0,
  /**
   * Fullframes from unmatched car events older than `events_max_fullframe_unmatched_age` will be removed every night by the cleanup script
   */
  car_events_max_fullframe_unmatched_age: 0,
  /**
   * Car cluster events older than `car_cluster_events_max_age` will be removed every night by the cleanup script
   */
  car_cluster_events_max_age: 0,
  /**
   * Car cluster events except of best cluster event older than `car_cluster_events_max_age` will be removed every night by the cleanup script
   */
  car_cluster_events_keep_best_max_age: 0,
  /**
   * Matched body events older than `events_max_age` will be removed every night by the cleanup script
   */
  body_events_max_matched_age: 0,
  /**
   * Unmatched body events older than `events_max_age` will be removed every night by the cleanup script
   */
  body_events_max_unmatched_age: 0,
  /**
   * Fullframes from matched body events older than `events_max_fullframe_matched_age` will be removed every night by the cleanup script
   */
  body_events_max_fullframe_matched_age: 0,
  /**
   * Fullframes from unmatched body events older than `events_max_fullframe_unmatched_age` will be removed every night by the cleanup script
   */
  body_events_max_fullframe_unmatched_age: 0,
  /**
   * Body cluster events older than `body_cluster_events_max_age` will be removed every night by the cleanup script
   */
  body_cluster_events_max_age: 0,
  /**
   * Body cluster events except of best cluster event older than `body_cluster_events_max_age` will be removed every night by the cleanup script
   */
  body_cluster_events_keep_best_max_age: 0,
  /**
   * Features to extract when creating face events
   */
  face_events_features: [],
  /**
   * Features to extract when creating body events
   */
  body_events_features: [],
  /**
   * Features to extract when creating car events
   */
  car_events_features: [],
  /**
   * Liveness classifier threshold (if liveness is enabled)
   */
  liveness_threshold: 0,
  /**
   * Thumbnail JPEG quality, default is 75
   */
  thumbnail_jpeg_quality: 0,
  /**
   * Delete VMS video chunks which are older than vms_videos_max_age parameter
   */
  vms_videos_max_age: 0
};
