
import NButton from '@/uikit/buttons/NButton.vue';
import NInput from '@/uikit/input/NInput.vue';
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  components: { NButton, NInput },
  emits: ['cancel', 'save']
})
export default class VideoWallPresetEditor extends Vue {
  @Prop({ required: true, type: String })
  readonly name!: string;
  @Prop({ default: false, type: Boolean })
  readonly startFromEditor!: boolean;

  editing = this.startFromEditor;
  temporaryName = this.name;

  dispatchCancelEvent(): void {
    this.$emit('cancel');
    this.toggleEditorMode();
  }

  dispatchSaveEvent(): void {
    this.$emit('save', this.temporaryName);
    this.toggleEditorMode();
  }

  toggleEditorMode(): void {
    this.editing = !this.editing;
    this.temporaryName = this.name;
  }
}
