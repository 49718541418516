
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'ChartStatistic',
  components: {},
  emits: []
})
export default class ChartStatistic extends Vue {
  @Prop({ type: Array, required: true })
  readonly statistics!: any[];
}
