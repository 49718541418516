
import { defineComponent } from 'vue';
import { arrayOf, object } from 'vue-types';
import type { CameraStatusDetail } from '@/pages/data-sources/forms/components/types';

export default defineComponent({
  name: 'CameraStatusDetails',
  props: {
    details: arrayOf(object<CameraStatusDetail>()).isRequired
  }
});
