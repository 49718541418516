
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  name: 'NTextareaAutosize'
})
export default class NTextareaAutosize extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  @Prop({ type: String, default: '' })
  readonly placeholder!: string;

  @Prop({ type: Number, default: 1 })
  readonly minRows!: number;

  @Prop({ type: Boolean, default: false })
  readonly autofocus!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly mono!: boolean;

  @Prop({ type: Number })
  readonly rows?: number;

  private height = 0;

  get areaValue() {
    return this.modelValue;
  }

  set areaValue(value: string) {
    this.$emit('update:modelValue', value);
  }

  focus() {
    this.$el.focus();
  }

  getMinHeightByRows(rows = 1) {
    if (this.$refs.area) {
      const styles = window.getComputedStyle(this.$refs.area);
      const border = parseFloat(styles.border) * 2;
      const paddings = parseFloat(styles.paddingBlockStart) + parseFloat(styles.paddingBlockEnd);
      const lineHeight = parseFloat(styles.lineHeight);
      return border + paddings + rows * lineHeight;
    }
    return 0;
  }

  @Watch('areaValue', { immediate: true })
  async updateSize() {
    this.height = 0;
    await this.$nextTick();
    const contentHeight = this.$refs.area?.scrollHeight || 0;
    const minHeight = this.getMinHeightByRows(this.minRows);
    this.height = Math.max(contentHeight, minHeight) + 1; // +1 remove scroll

    if (this.rows) {
      this.height = this.getMinHeightByRows(this.rows);
    }
  }
}
