<template>
  <div class="verify-layout">
    <div class="verify-layout__form"><slot name="form"></slot></div>
    <div class="verify-layout__images"><slot name="images"></slot></div>
    <div class="verify-layout__results"><slot name="results"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'VerifyLayout'
};
</script>

<style lang="stylus">
.verify-layout {
  background: var(--color-level-200);
  padding: 20px 20px 15px;
  display: grid;
  grid-template-rows: min-content auto 49px;
  min-height: 100%;
  row-gap: 20px;
  &__results:empty {
    display: none;
  }

  &_two-columns {
    grid-template-columns: repeat(2, 1fr)
  }
  &_without-result {
    grid-template-rows: min-content auto;
  }
}
</style>
