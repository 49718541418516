
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { Counter, ProximityCalibrationStatusEnum } from '@/api';
import CommonStatus from '@/components/common/CommonStatus.vue';
import { CalibrationStatuses, CalibrationStatusesI18n } from '@/components/counters/CalibrationForm.vue';
import NHint from '@/uikit/hint/NHint.vue';
import { printStringValue } from '@/common/utils';
import CameraStatusDetails from '@/components/common/CameraStatusDetails.vue';

type StatisticRecords = {
  success_records: number;
  failed_records: number;
  total_records: number;
  success_percent: number | null;
};

@Options({
  name: 'CounterInfo',
  components: { CameraStatusDetails, NHint, CommonStatus }
})
export default class CounterInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Counter;

  get distanceEnabled() {
    return this.item.detect_proximity;
  }

  get calibrationStatus() {
    return (CalibrationStatuses as any)[this.item.proximity_calibration_status];
  }

  get translatedCalibrationStatus() {
    return this.$t(CalibrationStatusesI18n[this.calibrationStatus]);
  }

  get statistic() {
    const result = Object.assign(
      { total_records: 0, failed_records: 0, success_records: 0, success_percent: null },
      this.item.health_status.statistic
    ) as StatisticRecords;
    result.success_records = result.total_records - result.failed_records;
    result.success_percent = result.total_records ? Math.round((result.success_records * 1e2) / result.total_records) : null;
    return result;
  }

  get fullStatusItems() {
    return [
      {
        label: this.$t('counters.success_records'),
        value: printStringValue(this.statistic, 'success_records')
      },
      {
        label: this.$t('counters.failed_records'),
        value: printStringValue(this.statistic, 'failed_records')
      },
      {
        label: this.$t('counters.total_records'),
        value: printStringValue(this.statistic, 'total_records')
      },
      {
        label: this.$t('counters.success_percent'),
        value: this.statistic.total_records ? this.statistic.success_percent + '%' : '-'
      }
    ];
  }
}
