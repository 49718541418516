import { PointAsArray, PolyLine, RGBColorArray, SingleLine, Size, Sizes } from './types';

export function getLineReversPoints(line: PolyLine) {
  if (line.length < 2) return null;
  const { middleLine, middlePoint } = getMiddlePointOnLine(line);
  return getPerpendicularPoints(middleLine, middlePoint);
}

function getPerpendicularPoints(line: SingleLine, crossPoint: PointAsArray, offset = 40): SingleLine {
  const tgA = (line[1][1] - line[0][1]) / (line[1][0] - line[0][0]);
  const originXDiff = line[1][0] - line[0][0];
  const originYDiff = line[1][1] - line[0][1];
  const swapAB = (originXDiff > 0 && originYDiff > 0) || (originXDiff < 0 && originYDiff > 0);

  // Vertical
  if (Math.abs(tgA) === Infinity) {
    const a: PointAsArray = [crossPoint[0] + offset, crossPoint[1]];
    const b: PointAsArray = [crossPoint[0] - offset, crossPoint[1]];
    return originYDiff < 0 ? [a, b] : [b, a];
  }

  // Horizontal
  if (tgA === 0) {
    const a: PointAsArray = [crossPoint[0], crossPoint[1] + offset];
    const b: PointAsArray = [crossPoint[0], crossPoint[1] - offset];
    return originXDiff > 0 ? [a, b] : [b, a];
  }

  // coefficient of perpendicular line to X-axis
  const tgB = -1 / tgA;
  const tgB2 = Math.pow(tgB, 2);

  const xA = crossPoint[0] - offset / Math.sqrt(1 + tgB2);
  const xB = crossPoint[0] + offset / Math.sqrt(1 + tgB2);
  const yA = tgB * (xA - crossPoint[0]) + crossPoint[1];
  const yB = tgB * (xB - crossPoint[0]) + crossPoint[1];
  const a: PointAsArray = [xA, yA];
  const b: PointAsArray = [xB, yB];

  return swapAB ? [a, b] : [b, a];
}

export function getMiddlePointOnLine(line: PolyLine) {
  let totalDistance = 0;
  const lineDistances = [];
  for (let i = 0; i < line.length - 1; i++) {
    const distance = getPointsDistance(line[i], line[i + 1]);
    lineDistances.push(distance);
    totalDistance += distance;
  }

  const halfDistance = totalDistance / 2;
  let sumBeforeHalf = 0;
  let middleLineIndex = 0;
  for (let i = 0; i < lineDistances.length; i++) {
    if (sumBeforeHalf + lineDistances[i] > halfDistance) {
      middleLineIndex = i;
      break;
    }
    sumBeforeHalf += lineDistances[i];
  }

  const middleLineA = line[middleLineIndex];
  const middleLineB = line[middleLineIndex + 1];
  const leftDistanceCoefficient = (halfDistance - sumBeforeHalf) / lineDistances[middleLineIndex];

  const middlePoint: PointAsArray = [
    middleLineA[0] + leftDistanceCoefficient * (middleLineB[0] - middleLineA[0]),
    middleLineA[1] + leftDistanceCoefficient * (middleLineB[1] - middleLineA[1])
  ];

  return { middlePoint, middleLine: [middleLineA, middleLineB] as SingleLine };
}

export function getMiddlePoint(a: PointAsArray, b: PointAsArray): PointAsArray {
  return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];
}

export function getLineDistance(line: PolyLine) {
  if (line.length < 2) return 0;
  let distance = 0;
  for (let i = 0; i < line.length - 1; i++) {
    distance += getPointsDistance(line[i], line[i + 1]);
  }
  return distance;
}

export function interpolateColor(colorA: RGBColorArray, colorB: RGBColorArray, coefficient: number) {
  const result: RGBColorArray = [...colorA];
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + coefficient * (colorB[i] - colorA[i]));
  }
  return result;
}

export function getPointsDistance(a: PointAsArray, b: PointAsArray) {
  return Math.sqrt(Math.pow(b[0] - a[0], 2) + Math.pow(b[1] - a[1], 2));
}

export function isPointOnLine(currentPoint: PointAsArray, startPoint: PointAsArray, endPoint: PointAsArray) {
  const distanceCurrent = getPointsDistance(startPoint, currentPoint) + getPointsDistance(currentPoint, endPoint),
    distanceLine = getPointsDistance(startPoint, endPoint),
    diffDistance = Math.abs(distanceCurrent - distanceLine),
    DistanceFactor = 0.7;
  return diffDistance < DistanceFactor;
}

export function getLineAngleGrad(line: SingleLine) {
  return getLineAngleRad(line) * (180 / Math.PI);
}

export function getLineAngleRad(line: SingleLine) {
  return Math.atan2(line[1][1] - line[1][0], line[0][1] - line[0][1]);
}

export function isNearPoint(point: PointAsArray, current: PointAsArray, size: Size | null = Sizes.PointSizeNormal) {
  size = size ?? Sizes.PointSizeNormal;
  const distanceX = Math.abs(point[0] - current[0]);
  const distanceY = Math.abs(point[1] - current[1]);
  return distanceX <= size && distanceY <= size;
}

export function getCrossPoint([[x1, y1], [x2, y2]]: SingleLine, [[x3, y3], [x4, y4]]: SingleLine) {
  const x = ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) / ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
  const y = ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) / ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
  const points = [
    [x1, y1],
    [x2, y2],
    [x3, y3],
    [x4, y4]
  ];
  const isConnectPoint = points.find((v) => v[0] === x && v[1] === y);

  if (isNaN(x) || isNaN(y) || isConnectPoint) {
    return false;
  } else {
    if (x1 > x2) {
      if (!(x2 <= x && x <= x1)) {
        return false;
      }
    } else {
      if (!(x1 <= x && x <= x2)) {
        return false;
      }
    }
    if (y1 > y2) {
      if (!(y2 <= y && y <= y1)) {
        return false;
      }
    } else {
      if (!(y1 <= y && y <= y2)) {
        return false;
      }
    }
    if (x3 > x4) {
      if (!(x4 <= x && x <= x3)) {
        return false;
      }
    } else {
      if (!(x3 <= x && x <= x4)) {
        return false;
      }
    }
    if (y3 > y4) {
      if (!(y4 <= y && y <= y3)) {
        return false;
      }
    } else {
      if (!(y3 <= y && y <= y4)) {
        return false;
      }
    }
  }

  return [x, y];
}

export function filterInsignificantPoints(line: PolyLine, significanceThresholdPercent = 1) {
  if (line.length < 3) return line;
  const significantLine = [line[0]];
  const totalDistance = getLineDistance(line);
  let lastSegmentsDistance = 0;
  for (let i = 1; i < line.length - 1; i++) {
    lastSegmentsDistance += getPointsDistance(line[i - 1], line[i]);
    if (lastSegmentsDistance / totalDistance > significanceThresholdPercent / 100) {
      lastSegmentsDistance = 0;
      significantLine.push(line[i]);
    }
  }
  significantLine.push(line[line.length - 1]);
  return significantLine;
}

export function castToMinimumDistance(a: PointAsArray, b: PointAsArray, originDistance: number, toDistance: number) {
  const xMinDistance = ((b[0] - a[0]) / originDistance) * toDistance;
  const yMinDistance = ((b[1] - a[1]) / originDistance) * toDistance;
  const xOffset = (xMinDistance - b[0] + a[0]) / 2;
  const yOffset = (yMinDistance - b[1] + a[1]) / 2;
  a = [a[0] - xOffset, a[1] - yOffset];
  b = [b[0] + xOffset, b[1] + yOffset];

  return [a, b];
}

// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
export function getIntersectionPoint(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number) {
  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false;
  }

  let denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  // Lines are parallel
  if (denominator === 0) {
    return false;
  }

  let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false;
  }

  // Return a object with the x and y coordinates of the intersection
  let x = x1 + ua * (x2 - x1);
  let y = y1 + ua * (y2 - y1);

  return [x, y];
}

export function reduceLine(a: PointAsArray, b: PointAsArray, reduction: number): SingleLine {
  const m = b[0] === a[0] ? 90 : (b[1] - a[1]) / (b[0] - a[0]);
  let dx = Math.sqrt(Math.pow(reduction, 2) / (1 + Math.pow(m, 2)));
  let dy = m * dx;

  if (b[0] > a[0]) {
    dx = -dx;
    dy = -dy;
  }

  const a1: PointAsArray = [a[0] - dx, a[1] - dy];
  const b1: PointAsArray = [b[0] + dx, b[1] + dy];

  return [a1, b1];
}
