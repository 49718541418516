import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-small-checkbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NCheckbox = _resolveComponent("NCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NCheckbox, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("span", {
      class: "filter-small-checkbox__label control-m",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleModel && _ctx.toggleModel(...args)))
    }, _toDisplayString(_ctx.label), 1)
  ]))
}