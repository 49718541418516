
import { CameraStatusColorCode } from '@/api/models/Status';
import { CSSProperties } from 'vue';
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({})
export default class VideoWallCameraStatus extends Vue {
  @Prop({ required: true, type: String })
  readonly status!: CameraStatusColorCode;

  get style(): CSSProperties {
    return { backgroundColor: this.status };
  }
}
