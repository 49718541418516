
import { Options, Vue } from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import { NButton } from '@/uikit';
import { PropType } from 'vue';
import { BodyEvent, CarEpisode, CarEvent, FaceEvent, HumanEpisode } from '@/api';
import { DirectionEnum } from '@/components/common/acknowledge-types';

@Options({
  name: 'Acknowledge',
  components: { NButton }
})
export default class Acknowledge extends Vue {
  @Prop({ type: Object as PropType<FaceEvent | BodyEvent | CarEvent | HumanEpisode | CarEpisode>, required: true })
  readonly item!: FaceEvent | BodyEvent | CarEvent | HumanEpisode | CarEpisode;

  @Prop({ type: String, default: DirectionEnum.Horizontal })
  direction!: DirectionEnum;

  wasAcknowledged = this.item.acknowledged;

  get text() {
    return this.isVertical ? '' : this.$t(`common.${this.wasAcknowledged ? 'acknowledged' : 'acknowledge'}`, 'f');
  }

  get icon() {
    return this.wasAcknowledged ? 'done-all' : this.isVertical ? 'unknown' : '';
  }

  get isVertical() {
    return this.direction === DirectionEnum.Vertical;
  }

  get classes(): Record<string, boolean> {
    return {
      acknowledge: true,
      acknowledge_horizontal: this.direction === DirectionEnum.Horizontal,
      acknowledge_vertical: this.direction === DirectionEnum.Vertical
    };
  }

  toggleAcknowledge() {
    const value = !this.wasAcknowledged;
    this.wasAcknowledged = value;
    this.$emit('action', 'acknowledge', { value });
  }

  @Watch('item.acknowledged')
  handleAcknowledge(v: boolean) {
    this.wasAcknowledged = v;
  }
}
