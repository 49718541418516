
import { ApiError, AuthService, LoginRequestRequest, LoginResult } from '@/api';
import LanguageChanger from '@/components/launcher/LanguageChanger.vue';
import { localStorageModule } from '@/store/application/local.storage';
import { AuthModule, authModule } from '@/store/auth';
import { configModule } from '@/store/config';
import { languageModule } from '@/store/languages';
import notify from '@/uikit/notification/helpers/notification';
import _ from 'lodash';
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { createVideoStream, disposeVideoStream, getHasCameraAccess, getIsUserAgentSupportVideoRecording } from '../../components/common/media-devices-helpers';
import { ICredentials, isApiError } from './common';
import { AuthType, getAuthRequirements, getDefaultAuthPayload, hasSecureConnection } from './login-helpers';
import LoginADForm from './LoginADForm.vue';
import LoginCryptoProForm from './LoginCryptoProForm.vue';
import LoginFaceForm from './LoginFaceForm.vue';
import LoginFaceInvitation from './LoginFaceInvitation.vue';
import LoginLayout from './LoginLayout.vue';
import LoginLogo from './LoginLogo.vue';
import LoginPasswordForm from './LoginPasswordForm.vue';
import LoginWaitFaceAuth from './LoginWaitFaceAuth.vue';
import { postLoginDataLoad } from '@/store/data/postLoginDataLoad';
import { PagePaths } from '@/store/application/page.definitions';
import ThemeChanger from '@/components/launcher/ThemeChanger.vue';

const t = languageModule.getTranslatedToken;

export default defineComponent({
  name: 'LoginPage',
  components: {
    LanguageChanger,
    LoginADForm,
    LoginCryptoProForm,
    LoginFaceForm,
    LoginFaceInvitation,
    LoginLayout,
    LoginLogo,
    ThemeChanger,
    LoginPasswordForm,
    LoginWaitFaceAuth
  },
  setup() {
    const state = reactive({
      stream: null as null | MediaStream,
      isLoading: false,
      credentials: null as null | ICredentials,
      faceLoginResult: null as null | LoginResult,
      hasCredentialsError: false
    });

    const router = useRouter();

    onBeforeMount(redirectOrReset);
    function redirectOrReset() {
      authModule.isTokenValid ? router.push(PagePaths.Launcher) : authModule.reset();
    }

    onMounted(tryAutoCreateVideoStream);
    async function tryAutoCreateVideoStream() {
      const hasCameraAccess = await getHasCameraAccess();
      if (hasCameraAccess) {
        await tryCreateVideoStream();
      }
    }

    onMounted(bindEnterHandler);
    function bindEnterHandler() {
      document.addEventListener('keyup', tryCreateVideoStreamOnEnter);
    }

    onBeforeUnmount(unbindEnterHandler);
    function unbindEnterHandler() {
      document.removeEventListener('keyup', tryCreateVideoStreamOnEnter);
    }

    function tryCreateVideoStreamOnEnter(e: KeyboardEvent) {
      if (e.code === 'Enter') tryCreateVideoStream();
    }

    async function tryCreateVideoStream() {
      try {
        if (authRequirements.faceAllowed && getIsUserAgentSupportVideoRecording()) {
          state.stream = await createVideoStream();
        }
      } catch (e) {
        console.warn(e);
        if (e.toString().includes('Permission denied')) notify({ content: t('errors.webcam.not_allowed_desc') });
      }
    }

    const authType = hasSecureConnection() ? getConfiguredAuthType() : AuthType.PASSWORD;
    const authRequirements = getAuthRequirements(authType);
    const isStreamActive = computed(() => state.stream?.active);
    const isFaceInvitationVisible = computed(() => !isStreamActive.value && authRequirements.faceAllowed);
    const isWaitingFaceAuth = computed(() => authType === AuthType.FACE);
    const isSubmitButtonActive = !authRequirements.faceAllowed;
    const activeDirectoryEnabled = computed(() => configModule.config.services.ffsecurity.active_directory);
    const cryptoProEnabled = computed(() => configModule.config.plugins?.cproauth);

    function getConfiguredAuthType() {
      const authType = configModule.config.auth?.AUTH_TYPE;
      return _.isString(authType) ? authType : AuthType.PASSWORD;
    }

    function handlePasswordFormSubmit(credentials: ICredentials) {
      state.hasCredentialsError = false;
      state.credentials = credentials;
      login();
    }

    function handleFaceLogin(result: LoginResult) {
      state.faceLoginResult = result;
      login();
    }

    async function login() {
      try {
        if (authRequirements.faceRequired && !state.faceLoginResult) return;
        if (authRequirements.passwordRequired && !state.credentials) return;
        let loginResult = authRequirements.faceEnough && state.faceLoginResult ? state.faceLoginResult : await tryToLogin();
        if (loginResult) await handleSuccessLogin(loginResult);
      } catch (e) {
        handleError(e);
      }
    }

    async function handleSuccessLogin(loginResult: LoginResult) {
      authModule.setTokenAuth(loginResult.token, loginResult.token_expiration_datetime);
      const newKey = localStorageModule.setKey(loginResult.user.name);
      localStorageModule.syncToStorageByName(AuthModule.Name);
      if (newKey) localStorageModule.syncAllFromStorage();
      try {
        await postLoginDataLoad();
      } catch (e) {
        state.isLoading = false;
      }
      await router.push(PagePaths.Launcher);
    }

    async function tryToLogin() {
      if (state.isLoading) return;
      if (state.credentials) {
        try {
          state.isLoading = true;
          authModule.setBasicAuth(state.credentials.login, state.credentials.password);
          let payload: LoginRequestRequest = {
            ...getDefaultAuthPayload(),
            video_auth_token: state.faceLoginResult?.token
          };
          return await AuthService.authLoginCreate(payload);
        } catch (e) {
          state.isLoading = false;
          throw e;
        }
      }
    }

    function handleError(e: ApiError) {
      if (isApiError(e) && e.body.code === 'UNAUTHORIZED') {
        return (state.hasCredentialsError = true);
      }
      throw e;
    }

    onBeforeUnmount(disposeStream);
    function disposeStream() {
      if (isStreamActive.value) {
        state.stream = (state.stream && disposeVideoStream(state.stream), null);
      }
    }

    return {
      ...toRefs(state),
      isStreamActive,
      isFaceInvitationVisible,
      isWaitingFaceAuth,
      isSubmitButtonActive,
      handlePasswordFormSubmit,
      handleFaceLogin,
      handleSuccessLogin,
      tryCreateVideoStream,
      cryptoProEnabled,
      activeDirectoryEnabled,
      languageModule,
      configModule
    };
  }
});
