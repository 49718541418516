import { defineComponent, toRefs } from 'vue';
import { object, string } from 'vue-types';
import { License, LicenseUsage } from '../../types';
import { LicensePageTabName } from '../tabs';
import { useLicensePageTabViewRenderer } from './setup';

export const LicensePageTabView = defineComponent({
  name: 'LicensePageTabView',
  props: {
    license: object<License>().isRequired,
    licenseUsage: object<LicenseUsage>().isRequired,
    tabName: string<LicensePageTabName>().isRequired
  },
  setup(props) {
    const { license, licenseUsage, tabName } = toRefs(props);
    return useLicensePageTabViewRenderer(tabName, license, licenseUsage);
  }
});
