
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Case } from '@/api';
import NButton from '@/uikit/buttons/NButton.vue';
import { RouterModule } from '@/store/router';

@Options({
  name: 'CaseDescription',
  components: { NButton }
})
export default class CaseDescription extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Case;

  get classes() {
    return {
      ['case-description_card']: true
    };
  }
}
