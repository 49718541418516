
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { viewModelRepository } from '@/api/common';
import { Case } from '@/api';
import DataSourcesVideosTable from '@/pages/data-sources/DataSourceVideosTable.vue';
import { aclModule } from '@/store/acl';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { multisidebarModule } from '@/store/multisidebar';

@Options({
  name: 'CaseVideos',
  components: { DataSourcesVideosTable },
  emits: []
})
export default class CaseVideos extends Vue {
  @Prop({ required: false })
  readonly item!: Case;

  get modelAcl() {
    return aclModule.getModelAcl(this.module);
  }

  get module() {
    const result = viewModelRepository.getVideosListViewModel();
    result.aclModelName = 'videoarchive';
    result.filter.current.limit = '1000';
    (result.filter.current as any).case_in = this.item?.id;
    return result;
  }

  get sidebarType() {
    return MultisidebarItemTypes.Videos;
  }

  get sidebarSelectedItemIds(): number[] {
    return this.sidebarModule.items.filter((v) => v.type === this.sidebarType).map((v) => v.model.item.id);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  async mounted() {
    await this.module.get();
  }

  handleSelect(changesSelectionIds: number[]) {
    this.module.items
      .filter((v) => changesSelectionIds.includes(v.id))
      .forEach((v) => {
        actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: this.sidebarType, rawItem: v });
      });
  }
}
