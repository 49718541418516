
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';

export interface HTMLDivElementWithFullscreen extends HTMLDivElement {
  msRequestFullscreen: any;
  webkitRequestFullscreen: any;
}

export interface DocumentWithFullscreen extends HTMLDocument {
  exitFullscreen: any;
  fullscreenElement: any;
  msExitFullscreen: any;
  webkitExitFullscreen: any;
  webkitFullscreenElement: any;
}

@Options({
  name: 'FullscreenButton',
  components: { NIcon }
})
export default class FullscreenButton extends Vue {
  @Prop({ type: Object, required: true })
  readonly element!: HTMLDivElementWithFullscreen;

  @Prop({ type: Object, default: false })
  readonly resizeEvent!: boolean;

  isFullscreen = false;

  eventHandler() {
    this.isFullscreen = document.fullscreenElement === this.element;
    this.$emit('fullscreenChange', this.isFullscreen);
    if (this.resizeEvent) {
      window.dispatchEvent(new Event('resize'));
    }
  }

  openFullscreen() {
    if (this.element.requestFullscreen) {
      this.element.requestFullscreen();
    } else if (this.element.webkitRequestFullscreen) {
      /* Safari */
      this.element.webkitRequestFullscreen();
    } else if (this.element.msRequestFullscreen) {
      /* IE11 */
      this.element.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    const typedDocument = document as DocumentWithFullscreen;
    if (typedDocument.exitFullscreen) {
      typedDocument.exitFullscreen();
    } else if (typedDocument.webkitExitFullscreen) {
      /* Safari */
      typedDocument.webkitExitFullscreen();
    } else if (typedDocument.msExitFullscreen) {
      /* IE11 */
      typedDocument.msExitFullscreen();
    }
  }

  toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
    this.isFullscreen ? this.openFullscreen() : this.closeFullscreen();
  }

  mounted() {
    window.addEventListener('fullscreenchange', this.eventHandler);
  }

  beforeUnmount() {
    window.removeEventListener('fullscreenchange', this.eventHandler);
  }
}
