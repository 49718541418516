import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "watch-lists-page__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsBar = _resolveComponent("SettingsBar")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_SettingsBar, {
        "create-label-i18-n": "common.watch_list_create",
        "total-count": _ctx.items.length,
        "has-create": _ctx.modelAcl.add,
        onSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchQuery = $event)),
        onCreate: _ctx.handleCreate
      }, null, 8, ["total-count", "has-create", "onCreate"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_NTable, {
          columns: _ctx.columns,
          content: _ctx.items,
          "selected-items-ids": _ctx.selectedItemsIds,
          "disabled-items-ids": _ctx.disabledItemIds,
          onAction: _ctx.handleTableAction,
          onSort: _ctx.handleTableSort,
          "onSelect:changes": _ctx.handleSelectChanges
        }, null, 8, ["columns", "content", "selected-items-ids", "disabled-items-ids", "onAction", "onSort", "onSelect:changes"])
      ])
    ]),
    _: 1
  }))
}