import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "matched-card"
}
const _hoisted_2 = { class: "matched-card__image" }
const _hoisted_3 = { class: "matched-card__info label-m" }
const _hoisted_4 = {
  key: 0,
  class: "matched-card__comment label-xs"
}
const _hoisted_5 = {
  key: 1,
  class: "matched-card__files"
}
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Confidence = _resolveComponent("Confidence")!
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!

  return (_ctx.card)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.confidence)
            ? (_openBlock(), _createBlock(_component_Confidence, {
                key: 0,
                value: _ctx.confidence,
                objects: _ctx.objectType,
                direction: "vertical",
                align: "center"
              }, null, 8, ["value", "objects"]))
            : _createCommentVNode("", true),
          _createVNode(_component_NThumbnail, {
            "model-value": _ctx.thumbnail,
            size: "extra-large",
            "not-found-image-label": _ctx.$t('errors.card.not_found')
          }, null, 8, ["model-value", "not-found-image-label"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_NButton, {
            type: "link",
            class: "matched-card__name control-m",
            onClick: _ctx.navigateToCard
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.card.name), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.card.comment)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.card.comment), 1))
            : _createCommentVNode("", true),
          (_ctx.attachments)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (attachment, idx) => {
                  return (_openBlock(), _createBlock(_component_NButton, {
                    icon: "attach",
                    label: attachment.name,
                    type: "link",
                    key: idx,
                    onClick: ($event: any) => (_ctx.openFile(attachment.file))
                  }, null, 8, ["label", "onClick"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        _createVNode(_component_NLoadingCircle)
      ]))
}