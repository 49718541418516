/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Schedule } from './Schedule';
export type WatchList = {
    readonly id: number;
    /**
     * Object creation date
     */
    readonly created_date: string;
    /**
     * Object modification date
     */
    readonly modified_date: string;
    /**
     * `true` if a watch list is enabled
     */
    active?: boolean;
    /**
     * Short watch list name, up to 256 characters
     */
    name: string;
    /**
     * Extended description, up to 2048 characters
     */
    comment?: string;
    /**
     * Color of the object label in hex
     */
    color?: string;
    /**
     * `true` if the notifications on the watch list enabled
     */
    notify?: boolean;
    /**
     * Require manual acknowledgment of the events that match with the watch list
     */
    acknowledge?: boolean;
    permissions?: Record<string, string>;
    /**
     * List of the camera groups used to monitor the watch list
     */
    camera_groups?: Array<number>;
    /**
     * Watch list face confidence threshold
     */
    face_threshold?: number | null;
    /**
     * Watch list body confidence threshold
     */
    body_threshold?: number | null;
    /**
     * Watch list car confidence threshold
     */
    car_threshold?: number | null;
    /**
     * Events won't be created if set to True
     */
    ignore_events?: boolean;
    active_after?: string | null;
    active_before?: string | null;
    disable_schedule?: Schedule;
    readonly recount_schedule_on: string;
};
export const EmptyWatchList: WatchList = {
    id: 0,
    /**
     * Object creation date
     */
    created_date: '',
    /**
     * Object modification date
     */
    modified_date: '',
    /**
     * `true` if a watch list is enabled
     */
    active: true,
    /**
     * Short watch list name, up to 256 characters
     */
    name: '',
    /**
     * Extended description, up to 2048 characters
     */
    comment: '',
    /**
     * Color of the object label in hex
     */
    color: 'cccccc',
    /**
     * `true` if the notifications on the watch list enabled
     */
    notify: false,
    /**
     * Require manual acknowledgment of the events that match with the watch list
     */
    acknowledge: false,
    permissions: {},
    /**
     * List of the camera groups used to monitor the watch list
     */
    camera_groups: [],
    /**
     * Watch list face confidence threshold
     */
    face_threshold: 0,
    /**
     * Watch list body confidence threshold
     */
    body_threshold: 0,
    /**
     * Watch list car confidence threshold
     */
    car_threshold: 0,
    /**
     * Events won't be created if set to True
     */
    ignore_events: false,
    active_after: null,
    active_before: null,
    disable_schedule: undefined,
    recount_schedule_on: '',
};
