import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonSelect = _resolveComponent("NButtonSelect")!

  return (_openBlock(), _createBlock(_component_NButtonSelect, {
    items: _ctx.items,
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _ctx.handleUpdate,
    type: "secondary"
  }, null, 8, ["items", "model-value", "onUpdate:modelValue"]))
}