const NumberFormat = new Intl.NumberFormat('ru-RU');

const DateTimeFormat = new Intl.DateTimeFormat('ru-RU', {
  day: '2-digit',
  hour: '2-digit',
  hour12: false,
  minute: '2-digit',
  month: '2-digit',
  second: '2-digit',
  year: 'numeric'
});

export function formatNumber(number: number): string {
  return NumberFormat.format(number);
}

export function formatDate(date: Date): string {
  return DateTimeFormat.format(date);
}
