
import { Options, Vue } from 'vue-class-component';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import DataSourcesDevices, { IDataSourceDevice } from '@/pages/data-sources/add-devices/DataSourcesDevices.vue';
import DataSourcesOnvifDiscovery from '@/pages/data-sources/add-devices/DataSourcesOnvif.vue';
import DataSourcesUploadNext from '@/pages/data-sources/add-devices/DataSourcesUploadNext.vue';
import DataSourcesExternalDetectors from '@/pages/data-sources/add-devices/DataSourcesExternalDetectors.vue';
import DataSourcesDevicesNext from '@/pages/data-sources/add-devices/DataSourcesDevicesNext.vue';
import { Prop, Watch } from 'vue-property-decorator';
import SelectVideoForm from './SelectVideoForm.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import ParticipantItem from '@/components/participants/ParticipantItem.vue';
import DataSourcesOnvifNext from '@/pages/data-sources/add-devices/DataSourcesOnvifNext.vue';
import { aclModule } from '@/store/acl';
import { PageTypes } from '@/store/application/page.definitions';

@Options({
  name: 'AddNewWizard',
  components: {
    SelectVideoForm,
    ParticipantItem,
    NButton,
    NModalWindow,
    DataSourcesOnvifNext,
    DataSourcesDevicesNext,
    DataSourcesExternalDetectors,
    DataSourcesUploadNext,
    DataSourcesOnvifDiscovery,
    DataSourcesDevices,
    NButtonGroup
  },
  emits: ['upload', 'close']
})
export default class AddNewWizard extends Vue {
  @Prop({ type: String, default: 'cameras' })
  modelValue!: string;

  localTab = '';
  currentStep = 1; // TODO use route push history
  currentData: IDataSourceDevice[] = [];
  tabNames = {
    Cameras: PageTypes.Cameras,
    Videos: PageTypes.Videos,
    ExternalDetectors: PageTypes.ExternalDetectors,
    OnvifDiscovery: 'onvif_discovery'
  };

  get currentTab() {
    return this.localTab || this.modelValue;
  }

  set currentTab(val: string) {
    if (val !== this.tabNames.OnvifDiscovery) {
      this.$emit('update:modelValue', val);
    }
    this.localTab = val;
  }

  get tabItems() {
    const items = [
      {
        name: this.tabNames.Cameras,
        label: this.$t('ds.tabs.cameras', 'f'),
        permissions: 'ffsecurity.add_camera'
      },
      {
        name: this.tabNames.OnvifDiscovery,
        label: this.$t('ds.tabs.onvif_discovery', 'f'),
        permissions: 'ffsecurity.add_camera'
      },
      {
        name: this.tabNames.Videos,
        label: this.$t('ds.tabs.videos', 'f'),
        permissions: 'ffsecurity.add_videoarchive'
      },
      {
        name: this.tabNames.ExternalDetectors,
        label: this.$t('ds.tabs.external_detectors', 'f'),
        permissions: 'ffsecurity.add_camera'
      }
    ];

    return items.filter((v) => aclModule.getAccess(v.permissions));
  }

  prevStep() {
    this.currentStep--;
  }

  nextStep(data: IDataSourceDevice[]) {
    this.currentStep++;
    this.currentData = data;
  }

  uploadHandler(v: any) {
    this.$emit('upload', v);
  }

  @Watch('pageType')
  pageTypeWatcher(page: string) {
    this.currentStep = 1;
  }

  @Watch('modelValue')
  currentTabWatcher(page: string) {
    this.currentStep = 1;
  }
}
