
import { BodyEvent, CarEvent, FaceEvent } from '@/api';
import { ObjectsSingleToMulti } from '@/store/application/data.assets';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { FeatureObject } from '../common/types';
import { getFeaturesFormatter } from '../common/utils';

type EventWithType = (FaceEvent | BodyEvent | CarEvent) & { type: string };

@Options({
  name: 'VideoPlayerOverlayMeta'
})
export default class VideoPlayerOverlayMeta extends Vue {
  @Prop({ type: Object, required: true })
  readonly event!: EventWithType;

  get formattedFeatures() {
    return getFeaturesFormatter(ObjectsSingleToMulti[this.event.type] as FeatureObject)(this.event.features, { t: this.$t, tm: this.$tm });
  }

  get formattedFeatureNames(): string[] {
    const features = this.formattedFeatures;
    return Object.keys(features).sort() as (keyof typeof features)[];
  }
}
