export default {
  download: 'Download',
  cancel: 'Cancel',
  downloaded: 'Downloaded',
  time_range: 'Time range',
  video_not_available: 'The video has ended or is not available',
  no_video_camera_removed: 'The camera was removed so the recorded video is no longer available',
  status_waiting: 'Waiting...',
  status_loading: 'Loading...',
  time_working: 'Work duration',
  process_duration: 'Process duration',
  posted_objects: 'objects posted',
  failed_objects: 'objects failed',
  not_posted_objects: 'objects not posted',
  frames_dropped: 'Frames dropped',
  frames_processed: 'Frames processed',
  frames_imotion_skipped: 'Skipped imotion frames',
  resolution: 'Resolution',
  video_quality: 'Video quality',
  brightness: 'Brightness',
  contrast: 'Contrast',
  saturation: 'Saturation',
  faces: 'Faces',
  bodies: 'Bodies',
  cars: 'Vehicles',
  faces_posted: 'faces posted',
  faces_failed: 'faces failed',
  faces_not_posted: 'faces not posted',
  bodies_posted: 'bodies posted',
  bodies_failed: 'bodies failed',
  bodies_not_posted: 'bodies not posted',
  cars_posted: 'vehicles posted',
  cars_failed: 'vehicles failed',
  cars_not_posted: 'vehicles not posted',
  job_starts: 'Job starts',
  decoding_errors: 'Decoding errors',
  objects_posted: 'Objects posted',
  objects_failed: 'Objects failed',
  zoom_helper: 'Zoom in timeline to see events'
};
