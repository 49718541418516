import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NTimePicker = _resolveComponent("NTimePicker")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['n-time-input', { 'n-time-input_seconds': _ctx.secondsEnabled, 'n-time-input_clearable': _ctx.clearable }])
  }, [
    _createVNode(_component_NDropdown, {
      onCommand: _ctx.selectHandler,
      placement: "bottom-start",
      ref: "dropdown"
    }, {
      toggle: _withCtx(({ toggle, hide }) => [
        _createVNode(_component_NInput, {
          ref: "nInput",
          class: "label-mono-m",
          onFocus: ($event: any) => (_ctx.focusHandler($event) || toggle()),
          onBlur: _ctx.blurHandler,
          "onUpdate:modelValue": _ctx.inputHandler,
          onKeydown: [
            _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.autoInsertDelimiter = false), ["backspace"])),
            _withKeys(hide, ["tab"])
          ],
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.autoInsertDelimiter = true), ["backspace"])),
          plain: _ctx.plain,
          modelValue: _ctx.userInput,
          disabled: _ctx.disabled,
          autofocus: _ctx.autofocus,
          accesskey: _ctx.accesskey,
          placeholder: _ctx.placeholder,
          name: _ctx.name,
          clearable: _ctx.clearable,
          "data-qa": `${_ctx.dataQa}.time`
        }, null, 8, ["onFocus", "onBlur", "onUpdate:modelValue", "onKeydown", "plain", "modelValue", "disabled", "autofocus", "accesskey", "placeholder", "name", "clearable", "data-qa"])
      ]),
      body: _withCtx(() => [
        _createVNode(_component_NTimePicker, {
          start: _ctx.start,
          end: _ctx.end,
          step: _ctx.step,
          "seconds-enabled": _ctx.secondsEnabled
        }, null, 8, ["start", "end", "step", "seconds-enabled"])
      ]),
      _: 1
    }, 8, ["onCommand"])
  ], 2))
}