
import { defineComponent, onBeforeUnmount, ref } from 'vue';
import { NButton } from '@/uikit';
import { getCameras } from '@/components/common/media-devices-helpers';

export default defineComponent({
  name: 'LoginFaceInvitation',
  components: {
    NButton
  },
  emits: ['invite'],
  setup(props, { emit }) {
    let hasCamera = ref(true);

    function refreshHasCamera() {
      getCameras().then((v) => (hasCamera.value = Boolean(v.length)));
    }

    refreshHasCamera();

    let intervalId = setInterval(refreshHasCamera, 2000);
    onBeforeUnmount(() => window.clearInterval(intervalId));

    return {
      emit,
      hasCamera
    };
  }
});
