import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "detection-dialog__apply" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetectionImageViewer = _resolveComponent("DetectionImageViewer")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    ref: "modalWindow",
    "has-margins": false,
    layout: true,
    customSize: "90vw",
    closeIcon: false
  }, {
    content: _withCtx(() => [
      _createVNode(_component_DetectionImageViewer, _mergeProps({ class: "detection-dialog" }, _ctx.$props, {
        onClose: _ctx.closeHandler,
        onSelectedBboxes: _ctx.selectBBoxes,
        onNotFound: _ctx.handleNotFound
      }), null, 16, ["onClose", "onSelectedBboxes", "onNotFound"]),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.notFound)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 0,
              onAction: _ctx.closeHandler
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.close_empty_detect_dialog', 'f')), 1)
              ]),
              _: 1
            }, 8, ["onAction"]))
          : (_openBlock(), _createBlock(_component_NButton, {
              key: 1,
              onAction: _ctx.applyBbox,
              disabled: !_ctx.selectedBboxes.length,
              loading: _ctx.loading || _ctx.applying
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.buttonLabel, 'f')), 1)
              ]),
              _: 1
            }, 8, ["onAction", "disabled", "loading"]))
      ])
    ]),
    _: 1
  }, 512))
}