
import { ref, computed, defineComponent } from 'vue';
import { string, bool, oneOfType } from 'vue-types';

export default defineComponent({
  name: 'NRadio',
  props: {
    selectValue: oneOfType([String, Number]).def(''),
    modelValue: oneOfType([String, Number]).def(''),
    name: string(),
    label: string().def(''),
    disabled: bool().def(false),
    hideUnchecked: bool().def(false)
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const focused = ref(false);
    const checked = computed(() => {
      return props.selectValue === props.modelValue;
    });
    const handleChange = () => {
      context.emit('update:modelValue', props.selectValue);
    };
    return { checked, focused, handleChange };
  }
});
