
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { CounterRecord } from '@/api';
import { formatDateTime } from '@/common/filters';
import NHint from '@/uikit/hint/NHint.vue';

@Options({
  name: 'CounterRecordInfo',
  components: { NHint }
})
export default class CounterRecordInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: CounterRecord;

  get createdDateTime() {
    return formatDateTime(this.item.created_date);
  }
}
