
import { IFrameObjects } from '@/components/video-player/VideoPlayerRender.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { websocketModule } from '@/store/ws/websocket.module';
import VideoPlayerOverlayMeta from '@/components/video-player/VideoPlayerOverlayMeta.vue';
import { configModule } from '@/store/config';
import VideoPlayerState from '@/components/video-player/VideoPlayerState';
import NImageViewerLineLayer from '@/uikit/image-viewer/NImageViewerLineLayer.vue';

const BBOXColors = {
  match: '#32D74B',
  noMatch: '#FF375F'
};

export interface OverlayObject {
  bbox: boolean;
  info: boolean;
}

export interface OverlayObjects {
  faces: OverlayObject;
  bodies: OverlayObject;
  cars: OverlayObject;
}

@Options({
  name: 'VideoPlayerOverlay',
  components: { NImageViewerLineLayer, VideoPlayerOverlayMeta }
})
export default class VideoPlayerOverlay extends Vue {
  @Prop({ type: Object, required: true })
  readonly settings!: VideoPlayerState;

  @Prop({ type: Number, default: false })
  readonly scaleFactor!: number;

  @Prop({ type: Object, default: null })
  readonly frameObjects!: IFrameObjects;

  trackIdToEventMap: any = {};

  get overlayObjects(): any {
    // OverlayObjects
    return configModule.overlay_objects as any;
  }

  get bboxes() {
    if (!this.frameObjects) {
      return [];
    }
    return this.computeObjectBboxesFromBufferData(this.frameObjects)
      .map((item) => {
        const { objectsType, bbox, track_id: trackId } = item;
        const isMatched = this.trackIdToEventMap[trackId]?.matched;
        return {
          style: this.getBBoxParams(bbox, isMatched, objectsType),
          isInfo: this.overlayObjects[objectsType]?.info,
          metaInfoStyle: this.getBBoxInfoParams(bbox),
          objectsType,
          trackId,
          event: this.getEvent(trackId)
        };
      })
      .filter((item) => this.overlayObjects[item.objectsType]?.bbox)
      .filter((item) => this.settings.bboxObjects[item.objectsType]);
  }

  get gdpr() {
    return configModule.overlay_gdpr;
  }

  computeObjectBboxesFromBufferData(frameObjects: IFrameObjects) {
    const showObjects = ['faces', 'bodies', 'cars'];
    return showObjects.reduce((allObjects, objectType) => {
      if (frameObjects[objectType] instanceof Array) {
        frameObjects[objectType].forEach((object) => (object.objectsType = objectType));
        // TODO: fix this ts error
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: problem
        return allObjects.concat(frameObjects[objectType]);
      } else {
        return allObjects;
      }
    }, []);
  }

  getBBoxRect(bbox: number[]) {
    const { scaleFactor } = this;
    return {
      x: bbox[0] * scaleFactor,
      y: bbox[1] * scaleFactor,
      width: (bbox[2] - bbox[0]) * scaleFactor,
      height: (bbox[3] - bbox[1]) * scaleFactor
    };
  }

  getBBoxParams(bbox: number[], isMatched: boolean, objectsType: string) {
    const bboxRect = this.getBBoxRect(bbox);
    const bboxBorderColor = isMatched ? BBOXColors.match : BBOXColors.noMatch;
    const isMustBlurred = objectsType === 'faces' && !isMatched && this.gdpr;
    const gdprBBoxStyle = isMustBlurred && {
      'backdrop-filter': 'blur(8px)',
      'border-radius': '1000px',
      'border-width': 0
    };
    return {
      left: `${bboxRect.x}px`,
      top: `${bboxRect.y}px`,
      width: `${bboxRect.width}px`,
      height: `${bboxRect.height}px`,
      'border-color': `${bboxBorderColor}`,
      ...gdprBBoxStyle
    };
  }

  getBBoxInfoParams(bbox: number[]) {
    const { x, y, width } = this.getBBoxRect(bbox);
    const leftCoord = x + width;
    return {
      left: `${leftCoord}px`,
      top: `${y}px`
    };
  }

  getEvent(trackId: string) {
    return this.trackIdToEventMap[trackId];
  }

  get websocketModule() {
    return websocketModule;
  }
  @Watch('websocketModule.event', { immediate: true })
  websocketHandler(event: any) {
    if (event.face) {
      const trackId = event.face?.detector_params?.track?.id;
      if (trackId) {
        this.trackIdToEventMap[trackId] = { ...event.face, type: 'face' };
      }
    }
    if (event.body) {
      const trackId = event.body?.detector_params?.track?.id;
      if (trackId) {
        this.trackIdToEventMap[trackId] = { ...event.body, type: 'body' };
      }
    }
    if (event.car) {
      const trackId = event.car?.detector_params?.track?.id;
      if (trackId) {
        this.trackIdToEventMap[trackId] = { ...event.car, type: 'car' };
      }
    }
  }
}
