import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(_ctx.classes),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClickEvent && _ctx.handleClickEvent(...args)), ["stop"]))
  }, [
    (_ctx.select)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 0,
          name: "select",
          class: "n-dropdown-menu-item__icon-select"
        }))
      : _createCommentVNode("", true),
    (_ctx.add)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 1,
          name: "add"
        }))
      : _createCommentVNode("", true),
    (_ctx.color)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 2,
          name: "circle",
          style: _normalizeStyle({ color: _ctx.color })
        }, null, 8, ["style"]))
      : _createCommentVNode("", true),
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 3,
          name: _ctx.icon
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.expand)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 4,
          name: "chevron-right",
          class: "n-dropdown-menu-item__icon-expand"
        }))
      : _createCommentVNode("", true)
  ], 2))
}