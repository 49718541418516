
import { defineComponent } from 'vue';
import { formatDate } from './formatters/utils';

export default defineComponent({
  name: 'LicensePageTabViewIntervalsListItemDate',
  props: {
    date: {
      required: true,
      type: Date
    }
  },
  computed: {
    content() {
      return formatDate(this.date);
    }
  }
});
