
import { Options, Vue } from 'vue-class-component';
import NModalWindow, { NModalWindowSize } from '@/uikit/modal-window/NModalWindow.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { Prop } from 'vue-property-decorator';
import { informationLayout } from '@/pages/blocklist/forms/blocklist-form-schema';
import NForm from '@/uikit/forms/NForm.vue';
import { dataServiceRepository } from '@/api/common';

export type BlacklistRecordType = {
  __uuid: string;
  reason: string;
  expire_date: string | null;
};

export type SelectedItemType = {
  uuid: string;
  reason: string;
  expire_date: string | null;
};

@Options({
  components: {
    NModalWindow,
    NButton,
    NForm
  }
})
export default class BlocklistForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly selectedItems!: SelectedItemType[];

  model: BlacklistRecordType = {
    __uuid: '',
    reason: '',
    expire_date: null
  };

  get formLayout() {
    return informationLayout;
  }

  get computedBlacklistRecordItems() {
    return this.selectedItems.map((item: SelectedItemType) => {
      const { reason, expire_date } = this.model;
      return { uuid: item.uuid, reason, expire_date };
    });
  }

  get size() {
    return NModalWindowSize.Medium;
  }

  getIsItemFormValid(): boolean {
    const result = this.$refs.itemForm ? this.$refs.itemForm.validate() : true;
    if (!result) {
      this.$refs.itemForm.displayErrors();
    }
    return result;
  }

  async saveHandler() {
    this.resetValidatorsCache();
    if (!this.getIsItemFormValid()) return;
    const promises = this.computedBlacklistRecordItems.map((v: SelectedItemType) => {
      return dataServiceRepository.DeviceBlacklistRecordsService.create(v);
    });
    await Promise.all(promises);
    await this.$emit('close', true);
  }

  // https://ntechlab.atlassian.net/browse/FFSEC-2398
  // Чтобы сделать сброс ошибок в валидаторе добавляем одну секунду ко времени истечения срока блокировки
  resetValidatorsCache() {
    if (this.model.expire_date) {
      const expireDate = new Date(this.model.expire_date);
      this.model.expire_date = new Date(expireDate.getTime() + 1000).toISOString();
    }
  }

  closeHandler() {
    this.$emit('close', false);
  }

  mounted() {
    this.model.__uuid = this.selectedItems.map((v: SelectedItemType) => v.uuid).join(', ');
  }
}
