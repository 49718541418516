
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import NForm, { FormValidatorNames, IFormLayout } from '@/uikit/forms/NForm.vue';
import { NInput } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import NDropdown from '@/uikit/dropdown/NDropdown.vue';
import { OnvifCamerasService } from '@/api';
import NHint from '@/uikit/hint/NHint.vue';

const Errors = {
  Invalid: 'common.login_or_password_invalid',
  Required: 'common.login_and_password_required'
};

@Options({
  name: 'DataSourcesOnvifAuth',
  components: { NHint, NDropdown, NButton, NForm, NModalWindow, NIcon }
})
export default class DataSourcesOnvifAuth extends Vue {
  @Prop({ type: Number, required: true })
  readonly modelValue!: number;

  @Prop({ type: Object, required: true })
  private modelForm!: any;

  private loading = false;

  private authModel = {
    username: '',
    password: ''
  };

  private error = '';

  get schema(): IFormLayout {
    return [
      {
        classes: 'n-form-w-2 n-form-pad-10',
        i18n_label: 'ds.login',
        path: 'username',
        component: NInput,
        props: {
          i18n_placeholder: 'ds.login_enter'
        },
        validators: [{ name: FormValidatorNames.Required }]
      },
      {
        classes: 'n-form-w-2 n-form-pad-10',
        i18n_label: 'ds.password',
        path: 'password',
        component: NInput,
        props: {
          i18n_placeholder: 'ds.password_enter',
          password: true
        },
        validators: [{ name: FormValidatorNames.Required }]
      }
    ];
  }

  async login(closeFn: any) {
    try {
      this.loading = true;
      await OnvifCamerasService.onvifCamerasAuthCreate(this.modelForm.id, this.authModel);
      const refreshResult = await OnvifCamerasService.onvifCamerasRefreshCreate(this.modelForm.id);
      this.$emit('updateFormModel', refreshResult);
      closeFn();
    } catch (e) {
      this.error = Errors.Invalid;
    } finally {
      this.loading = false;
    }
  }
}
