import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "license-page-meta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicensePageMetaLabel = _resolveComponent("LicensePageMetaLabel")!
  const _component_LicensePageMetaContent = _resolveComponent("LicensePageMetaContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, ({ label, content }, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        _createVNode(_component_LicensePageMetaLabel, { label: label }, null, 8, ["label"]),
        _createVNode(_component_LicensePageMetaContent, { content: content }, null, 8, ["content"])
      ], 64))
    }), 128))
  ]))
}