import { IObjectItem } from '@/components/video-player/objects/timeline-object';

export default class MultilineFormatter {
  static format(items: IObjectItem[]) {
    const sortedItems = items.sort((a, b) => {
      return a.timeFrom - b.timeFrom;
    });

    const lineCount = 6;
    const lineTimes = [0, 0, 0, 0, 0, 0];

    sortedItems
      .filter((item) => item.metadata.thumbnail)
      .forEach((item) => {
        for (let i = 0; i < lineCount; i++) {
          if (lineTimes[i] < item.timeFrom) {
            lineTimes[i] = item.timeTo;
            item.line = i + 1;
            return;
          }
        }
        item.line = lineCount;
      });
  }
}
