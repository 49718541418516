export default class ResolveOutsidePromise {
  promise: any = null;
  resolve: any = null;
  reject: any = null;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
