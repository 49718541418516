export type KeyboardCallback = {
  id: string;
  keyCode: number;
  shiftKey?: boolean;
  altKey?: boolean;
  ctrlKey?: boolean;
  callback: any;
};

export class KeyboardModule {
  items: KeyboardCallback[] = [];

  init() {
    this.keydownHandler = this.keydownHandler.bind(this);
    window.addEventListener('keydown', this.keydownHandler);
  }

  dispose() {
    window.removeEventListener('keydown', this.keydownHandler);
  }

  hasBoolean(value: boolean | undefined, current: boolean): boolean {
    return value ? value === current : true;
  }

  keydownHandler(e: KeyboardEvent): void {
    const { ctrlKey, altKey, shiftKey, key, keyCode, code, target } = e;
    const element = target as unknown as HTMLElement;
    const isInputTarget = ['textarea', 'input'].includes(element?.tagName?.toLowerCase());
    if (isInputTarget) return;

    for (let item of this.items) {
      const execute = item.keyCode === keyCode && this.hasBoolean(item.shiftKey, shiftKey) && this.hasBoolean(item.altKey, altKey) && this.hasBoolean(item.ctrlKey, ctrlKey);
      if (execute) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        item.callback();
      }
    }
  }

  register(value: KeyboardCallback): void {
    this.items.push(value);
  }

  unregister(id: string) {
    this.items = this.items.filter((v) => v.id != id);
  }
}

export const KeyCodes = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  PAUSE: 19,
  CAPS_LOCK: 20,
  ESCAPE: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  INSERT: 45,
  DELETE: 46,
  _0: 48,
  _1: 49,
  _2: 50,
  _3: 51,
  _4: 52,
  _5: 53,
  _6: 54,
  _7: 55,
  _8: 56,
  _9: 57,
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  L: 76,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,
  MULTIPLY: 106,
  ADD: 107,
  SUBTRACT: 109,
  DECIMAL_POINT: 110,
  DIVIDE: 111,
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F5: 116,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  F12: 123,
  NUM_LOCK: 144,
  SCROLL_LOCK: 145,
  SEMI_COLON: 186,
  EQUAL_SIGN: 187,
  COMMA: 188,
  DASH: 189,
  PERIOD: 190,
  FORWARD_SLASH: 191,
  GRAVE_ACCENT: 192,
  OPEN_BRACKET: 219,
  BACK_SLASH: 220,
  CLOSE_BRAKET: 221,
  SINGLE_QUOTE: 222
};

export const keyboardModule = new KeyboardModule();
keyboardModule.init();
