import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("span", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dispatchAttachEvent && _ctx.dispatchAttachEvent(...args))),
    class: "control-m n-attachments-picker"
  }, [
    _createVNode(_component_NIcon, {
      height: 17,
      width: 14.5,
      name: "attach"
    }, null, 8, ["width"]),
    _createTextVNode(_toDisplayString(_ctx.attachText), 1)
  ]))
}