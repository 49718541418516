
import { defineComponent } from 'vue';
import { string } from 'vue-types';

export default defineComponent({
  name: 'LicensePageMetaLabel',
  props: {
    label: string().isRequired
  }
});
