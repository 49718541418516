import TimelineObject, { IObjectItem, ObjectItemType } from '../timeline-object';
import { viewModelRepository } from '@/api/common';
import { configModule } from '@/store/config';

export default class TimelineEpisodesHumans extends TimelineObject {
  items: IObjectItem[] = [];
  type = ObjectItemType.Episode;
  model = viewModelRepository.getEpisodesHumansListViewModel();

  protected async load(cameras: number[], timeFrom: number, timeTo: number): Promise<void> {
    this.model.filter.current.limit = String(configModule.timeline_objects?.episodes.humans.limit);
    this.model.filter.current.cameras = cameras;
    this.model.filter.current.created_date_gte = new Date(timeFrom * 1000).toISOString();
    this.model.filter.current.created_date_lte = new Date(timeTo * 1000).toISOString();
    await this.model.get();
    this.isLoadedAll = this.model.next_page === null;
    const newItems = this.model.items.map((item) => {
      const timeFrom = new Date(item.created_date).getTime() / 1000;
      const timeTo = new Date(item.closed_date).getTime() / 1000;
      const best_event = item?.best_face_event || item?.best_body_event;
      return {
        id: item.id,
        line: 0,
        color: item.matched_card ? configModule.timeline_objects?.episodes.humans.matchedColor : configModule.timeline_objects?.episodes.humans.unmatchedColor,
        timeFrom,
        timeTo,
        metadata: {
          quality: best_event?.quality,
          thumbnail: best_event?.thumbnail
        }
      };
    });
    this.mergeById(this.items, newItems);
  }

  protected draw(timeFrom: number, timeTo: number) {
    super.draw(timeFrom, timeTo);
  }
}
