import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["alt", "src"]
const _hoisted_2 = { class: "interaction-cluster-item__content" }
const _hoisted_3 = {
  key: 1,
  class: "interaction-cluster-item__unknown heading-m"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_WatchListGroup = _resolveComponent("WatchListGroup")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["interaction-cluster-item", _ctx.classes])
  }, [
    _createElementVNode("img", {
      class: "interaction-cluster-item__thumbnail",
      alt: _ctx.item.id,
      src: _ctx.thumbnail
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.cardName)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            type: "link",
            onClick: _ctx.showMsbCardItem
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.cardName), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('common.unknown_cluster', 'f')), 1)),
      (_ctx.item.matched_lists.length)
        ? (_openBlock(), _createBlock(_component_WatchListGroup, {
            key: 2,
            type: "short",
            items: _ctx.item.matched_lists
          }, null, 8, ["items"]))
        : _createCommentVNode("", true),
      _createVNode(_component_NButton, {
        type: "link",
        onClick: _ctx.showMsbItem
      }, {
        default: _withCtx(() => [
          _createTextVNode("#" + _toDisplayString(_ctx.item.id), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ], 2))
}