import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.cssClass, "label-mono-l"])
  }, [
    (_ctx.hasDate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.dateLabel), 1))
      : _createCommentVNode("", true),
    (_ctx.hasDate && _ctx.hasTime && !_ctx.multiline)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " "))
      : _createCommentVNode("", true),
    (_ctx.hasTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.timeLabel), 1))
      : _createCommentVNode("", true)
  ], 2))
}