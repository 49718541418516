export const ReportTypesByPageTypeArray = [
  'events',
  'episodes',
  'clusters',
  'cluster_events',
  'cards',
  'kyc',
  'cameras',
  'counter_records',
  'areas',
  'area_activations',
  'area_records',
  'audit_logs',
];
