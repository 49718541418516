export const timePartToZeroLead = (timePart: number): string => {
  return timePart < 10 ? `0${timePart}` : timePart.toString();
};

export const timeToSeconds = (time: string): number => {
  let value = 0;
  let parts = (time || '').split(':');
  let partCoefficients = [3600, 60, 1];
  parts.forEach((part, i) => {
    let partValue = +part;
    if (!Number.isNaN(partValue)) {
      value += partValue * partCoefficients[i];
    } else {
      throw Error(`Can't convert '${time}' value to seconds!`);
    }
  });

  return value;
};

export const secondsToTime = (secondsSource: number, secondsEnabled = false, delimiter = ':'): string => {
  if (secondsSource >= 0) {
    let hours = Math.trunc(secondsSource / 3600),
      minutes = Math.trunc((secondsSource - hours * 3600) / 60),
      seconds = secondsSource - hours * 3600 - minutes * 60,
      timeLabel = [hours, minutes, seconds]
        .slice(0, secondsEnabled ? 3 : 2)
        .map(timePartToZeroLead)
        .join(delimiter);
    return timeLabel;
  }

  return '';
};
