import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickEvent && _ctx.handleClickEvent(...args))),
    class: "label-m video-wall-camera-list-group-header"
  }, [
    _createElementVNode("div", {
      class: "video-wall-camera-list-group-header__label",
      textContent: _toDisplayString(_ctx.label)
    }, null, 8, _hoisted_1),
    _createVNode(_component_NIcon, {
      class: "video-wall-camera-list-group-header__chevron",
      name: _ctx.chevron
    }, null, 8, ["name"]),
    _createVNode(_component_NIcon, {
      class: "video-wall-camera-list-group-header__folder",
      name: "folder"
    })
  ]))
}