
import { applicationModule } from '@/store/application';
import NButton from '@/uikit/buttons/NButton.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: { NButton }
})
export default class ThemeChanger extends Vue {
  private readonly applicationModule = applicationModule;

  @Prop({ default: false, type: Boolean })
  readonly hasLabel!: boolean;

  changeSystemTheme(): void {
    this.applicationModule.toggleTheme();
  }

  get themeName() {
    return this.applicationModule.settings.theme;
  }

  get icon() {
    return this.themeName === 'light' ? 'dark-theme' : 'light-theme';
  }

  get label() {
    return this.themeName && this.hasLabel ? this.$t(`common.${this.themeName}`) : '';
  }
}
