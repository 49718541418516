
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NTableCell, { ITableCell } from './NTableCell.vue';
import { NTableSelectHelper } from './NTableSelectHelper';

export interface ITableColumn {
  width?: string;
  header: ITableCell;
  content: ITableCell;
}

export type ITableRow = Record<string, any>;
export type ITableModel = ITableRow[];

@Options({
  name: 'NTable',
  components: { NTableCell },
  emits: ['action', 'sort', 'cell-click']
})
export default class NTable extends Vue {
  @Prop({ type: Array, required: true })
  readonly columns!: ITableColumn[];

  @Prop({
    type: Object,
    default: () => {}
  })
  readonly header!: ITableRow;

  @Prop({ type: String, default: '' })
  readonly sortValue = '';

  @Prop({ type: Array, required: true })
  readonly content!: ITableModel;

  @Prop({ type: Array })
  readonly selectedItemsIds!: any[];

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Array })
  readonly disabledItemsIds?: number[];

  @Prop({ type: Boolean, default: true })
  readonly hover!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly activeRow!: boolean;

  @Prop({ type: String })
  readonly dataQa?: string;

  protected selectHelper: any = null;

  protected get internalColumns() {
    if (typeof this.selectedItemsIds !== 'undefined') {
      this.selectHelper = new NTableSelectHelper(this.content as any, this.selectedItemsIds as any, this.$emit);
      return [this.selectHelper.column, ...this.columns];
    }

    return this.columns;
  }

  get columnCells(): ITableCell[] {
    return this.internalColumns.map((column) => {
      return {
        ...column.header,
        row: this.header
      };
    });
  }

  get contentCells(): ITableCell[][] {
    const cells: ITableCell[][] = [];
    this.content.forEach((row) => {
      const temp: ITableCell[] = [];
      this.internalColumns.forEach((column: ITableColumn) => {
        temp.push({
          ...column.content,
          row: row
        });
      });
      cells.push(temp);
    });
    return cells;
  }

  get styles() {
    const values = this.internalColumns.map((column) => column.width || 'auto').join(' ');
    return { 'grid-template-columns': values };
  }

  getItemDisabled(item: any, cellIndex: number) {
    const isSelectInternalCell = cellIndex === 0 && !!this.selectedItemsIds;
    return isSelectInternalCell ? false : this.disabled || this.disabledItemsIds?.includes(item.id);
  }

  actionHandler(payload: any) {
    this.$emit('action', payload);
  }

  cellClickHandler(cell: ITableCell, rowIndex: number, cellIndex: number) {
    this.$emit('cell-click', cell.row, cell, rowIndex, cellIndex);
  }

  getSort(cell: ITableCell): string | undefined {
    const name = cell.props?.name;
    const sortValue = this.sortValue;
    return sortValue && name ? (sortValue === name ? '+' : sortValue === '-' + name ? '-' : undefined) : undefined;
  }
}
