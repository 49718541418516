/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { str_int_float_bool_object_array_null } from '../models/str_int_float_bool_object_array_null';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class CarModelsJsonService {
    /**
     * Retrieve Web UI configuration
     * This method retrieves the web UI configuration.
     * @returns str_int_float_bool_object_array_null
     * @throws ApiError
     */
    public static carModelsJsonRetrieve(): CancelablePromise<Record<string, str_int_float_bool_object_array_null>> {
        return __request({
            method: 'GET',
            path: `/car_models.json`,
        });
    }
}