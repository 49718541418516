/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LinesFilter = {
            /**
            * Select only active lines (`true`) or inactive (`false`) lines
            */
        active?: boolean;
            /**
            * Get lines from `cameras` from these `camera_groups`
            */
        camera_groups?: Array<number>;
            /**
            * Get lines for selected `cameras`
            */
        cameras?: Array<number>;
            /**
            * Select objects with `created_date` **greater** than this value
            */
        created_date_gt?: string;
            /**
            * Select objects with `created_date` **greater than or equal** to this value
            */
        created_date_gte?: string;
            /**
            * Select objects with `created_date` in last N days
            */
        created_date_last_n_days?: number;
            /**
            * Select objects with `created_date` **less** than this value
            */
        created_date_lt?: string;
            /**
            * Select objects with `created_date` **less than or equal** to this value
            */
        created_date_lte?: string;
            /**
            * Select objects with `created_date` in last Nth week (including Sunday and Saturday)
            */
        created_date_nth_full_week?: number;
            /**
            * Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
            */
        created_date_nth_work_week?: number;
            /**
            * Select lines with provided ids
            */
        id_in?: Array<number>;
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Select lines where the SQL condition `name ILIKE name_contains` is true
            */
        name_contains?: string;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
            /**
            * Get lines for selected `video_archives`
            */
        video_archives?: Array<number>;
}
export const EmptyLinesFilter: LinesFilter = {
        active: undefined,
        camera_groups: [],
        cameras: [],
        created_date_gt: undefined,
        created_date_gte: undefined,
        created_date_last_n_days: undefined,
        created_date_lt: undefined,
        created_date_lte: undefined,
        created_date_nth_full_week: undefined,
        created_date_nth_work_week: undefined,
        id_in: [],
        limit: '20',
        name_contains: undefined,
        ordering: undefined,
        page: undefined,
        video_archives: [],
}