export enum VisibleMode {
  Auto = 'auto',
  Manual = 'manual'
}

export enum arrowPosition {
  top = 'bottom',
  right = 'left',
  bottom = 'top',
  left = 'right'
}
