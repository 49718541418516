export default {
  objectTypes: {
    line: 'линии',
    camera: 'камера',
    cameragroup: 'группа камер',
    watchlist: 'список наблюдения',
    listevent: 'событие',
    videoarchive: 'видео',
    group: 'роль',
    'face and face': 'сравнение лиц',
    'car and car': 'сравнение ТС',
    'body and body': 'сравнение силуэтов',
    humancard: 'карточки людей',
    carcard: 'карточки ТС',
    facecluster: 'кластеры лиц',
    bodycluster: 'кластеры силуэтов',
    carcluster: 'кластеры ТС',
    humanepisode: 'эпизоды с людьми',
    carepisode: 'эпизоды с ТС',
    counter: 'счетчик',
    faceobject: 'лицо из карточки',
    bodyobject: 'силуэт из карточки',
    carobject: 'ТС из карточки',
    faceevent: 'событие с лицом',
    bodyevent: 'событие с силуэтом',
    carevent: 'событие с ТС',
    license: 'лицензия',
    report: 'отчет',
    settings: 'настройки',
    user: 'пользователь',
    webhook: 'вебхук',
    ffsecauthsession: 'Сессия',
    deviceblacklistrecord: 'Блокировка',
    area: 'область',
    case: 'происшествие',
    caseattachment: 'вложение из происшествия',
    caseparticipant: 'участник происшествия',
    humancardattachment: 'вложение из карточки человека',
    carcardattachment: 'вложение из карточки ТС',
    caseparticipantattachment: 'вложение из записи участника происшествия',
    searchrequest: 'запрос на поиск',
    remotemonitoringrecord: 'запись удаленного мониторинга'
  },
  user: 'пользователь',
  request_ip: 'IP-адрес',
  device_uid: 'ID устройства',
  action_type: 'действие',
  object_type: 'объект',
  object_id: 'ID объекта',
  created_date: 'время',
  details: 'подробности',
  copy: 'копировать',
  clipboard_success: 'скопировано в буфер обмена',
  select_object: 'выберите объект',
  select_action: 'выберите действие',
  input_request_ip: 'введите IP-адрес',
  input_device_uid: 'введите ID устройства',
  input_object_id: 'введите ID объекта',
  input_user: 'введите имя пользователя',
  date_and_time: 'дата и время',
  request_ip_is_invalid: 'Недействительный IP-адрес',
  actionTypes: {
    ad_auth: 'аутентификация через active directory',
    destroy: 'удалить',
    partial_update: 'редактировать',
    auth_upload: 'загрузка видео',
    acknowledge: 'подтвердить все',
    purge_all: 'очистить все',
    upload: 'загрузка',
    basic_auth: 'аутентификация',
    create: 'добавить',
    search: 'поиск',
    purge: 'очистить',
    restart: 'перезапустить',
    video_auth_renew: 'проверка лица',
    verify: 'сравнить',
    update: 'обновить',
    video_auth: 'аутентификация по лицу',
    merge: 'объединить',
    list: 'список',
    close_multiple_sessions: 'закрыть несколько сессий',
    update_search: 'обновить поиск'
  }
};
