
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { EnrichedParticipant } from '@/components/participants/enrich-participants';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import WatchListItem from '@/components/common/WatchListItem.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { CaseRoleItems } from '@/components/participants/sidebar/ParticipantRole.vue';

@Options({
  name: 'ParticipantItemRow',
  components: { NButton, WatchListItem, NThumbnail }
})
export default class ParticipantItemRow extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: EnrichedParticipant;

  @Prop({ type: Boolean, default: true })
  readonly closeable!: boolean;

  get thumbnail() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.item?.faceClusterObject?.best_event.thumbnail;
  }

  computeColor(role: string) {
    return CaseRoleItems.find(({ value }) => value === role)?.color;
  }

  computeName(role: string) {
    return this.$t(`cases.${role}`);
  }
}
