
import { Options, Vue } from 'vue-class-component';
import { NButton } from '@/uikit';

@Options({
  name: 'EmptyCasePage',
  components: { NButton },
  emits: ['create']
})
export default class EmptyCasePage extends Vue {
}
