import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "confidence__item"
}
const _hoisted_2 = {
  key: 1,
  class: "confidence__item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.wrapperClasses)
  }, [
    _createVNode(_component_NHint, {
      content: _ctx.label,
      placement: "right",
      delayVisible: _ctx.hoverDelay,
      "visible-mode": _ctx.mode
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          style: _normalizeStyle(_ctx.style),
          class: _normalizeClass(_ctx.confidenceClasses),
          onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseEnterHandlerWithDebounce && _ctx.mouseEnterHandlerWithDebounce(...args))),
          onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseLeaveHandlerWithDebounce && _ctx.mouseLeaveHandlerWithDebounce(...args)))
        }, [
          (_ctx.isNumber)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.value), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.asterisksNumber, (number) => {
                  return (_openBlock(), _createBlock(_component_NIcon, {
                    name: "star",
                    width: "6",
                    height: "6",
                    key: number
                  }))
                }), 128))
              ]))
        ], 38)
      ]),
      _: 1
    }, 8, ["content", "delayVisible", "visible-mode"])
  ], 2))
}