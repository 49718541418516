import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "heading-l" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartGraph = _resolveComponent("ChartGraph")!
  const _component_ChartPager = _resolveComponent("ChartPager")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createBlock(_component_Chart, {
    loading: _ctx.item.loading,
    active: _ctx.item.active,
    ref: "root"
  }, {
    graph: _withCtx(() => [
      _createVNode(_component_ChartGraph, { options: _ctx.options }, null, 8, ["options"])
    ]),
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.item.i18n_label, 'f')), 1)
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_ChartPager, {
        onChange: _ctx.handlePageChangeEvent,
        "page-size": _ctx.pageSize,
        total: _ctx.yAxisData.length
      }, null, 8, ["onChange", "page-size", "total"]),
      _createVNode(_component_NButton, {
        onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('download', _ctx.$refs.root.$el))),
        icon: "download",
        type: "text"
      }),
      _createVNode(_component_NButton, {
        onAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('show-fullscreen'))),
        icon: _ctx.full ? 'fullscreen-exit' : 'fullscreen',
        type: "text"
      }, null, 8, ["icon"])
    ]),
    _: 1
  }, 8, ["loading", "active"]))
}