import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFormBlocks = _resolveComponent("NFormBlocks")!

  return (_openBlock(), _createBlock(_component_NFormBlocks, {
    ref: "form",
    blocks: _ctx.formLayoutBlocks,
    model: _ctx.model,
    state: _ctx.formState
  }, null, 8, ["blocks", "model", "state"]))
}