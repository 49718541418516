import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-text__label"
}
const _hoisted_2 = {
  key: 1,
  class: "n-text__value"
}
const _hoisted_3 = {
  key: 2,
  class: "n-text__suffix-label label-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["n-text-with-label label-s", _ctx.classes])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.value), 1))
      : _createCommentVNode("", true),
    (_ctx.suffixLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.suffixLabel), 1))
      : _createCommentVNode("", true)
  ], 2))
}