import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataSourcesVideosTable = _resolveComponent("DataSourcesVideosTable")!

  return (_openBlock(), _createBlock(_component_DataSourcesVideosTable, {
    "model-acl": _ctx.modelAcl,
    items: _ctx.module.items,
    "selected-items": _ctx.sidebarSelectedItemIds,
    onSelect: _ctx.handleSelect
  }, null, 8, ["model-acl", "items", "selected-items", "onSelect"]))
}