import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "verify-results" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "heading-2xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confidence = _resolveComponent("confidence")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.canVerify)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('verify.compare_error')), 1))
      : _createCommentVNode("", true),
    (_ctx.confidence && _ctx.canVerify)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.resultLabel), 1),
          _createVNode(_component_confidence, {
            objects: _ctx.object,
            value: _ctx.confidence
          }, null, 8, ["objects", "value"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}