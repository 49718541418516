/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DeviceBlacklistRecordsFilter = {
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select device blacklist records with `expire_date` **greater than or equal** to this value
     */
    expire_date_gte?: string;
    /**
     * Select device blacklist records with `expire_date` **greater than or equal** to this value
     */
    expire_date_lte?: string;
    /**
     * Select device blacklist records with provided ids
     */
    id_in?: Array<number>;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Which field to use when ordering the results. Available fields: `created_date`, `id`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select device blacklist records where the SQL condition `reason ILIKE reason_contains` is true
     */
    reason_contains?: string;
    /**
     * Select device blacklist records where the SQL condition `uuid ILIKE uuid_contains` is true
     */
    uuid_contains?: string;
    /**
     * Select device blacklist records related to one or multiple `uuid`s
     */
    uuid_in?: Array<string>;
}
export const EmptyDeviceBlacklistRecordsFilter: DeviceBlacklistRecordsFilter = {
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    expire_date_gte: undefined,
    expire_date_lte: undefined,
    id_in: [],
    limit: '20',
    ordering: undefined,
    page: undefined,
    reason_contains: undefined,
    uuid_contains: undefined,
    uuid_in: [],
}