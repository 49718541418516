/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Relation = {
    readonly id: number;
    /**
     * Short name, up to 256 characters
     */
    name: string;
    /**
     * Cards relation creation date
     */
    readonly created_date: string;
};
export const EmptyRelation: Relation = {
    id: 0,
    /**
     * Short name, up to 256 characters
     */
    name: '',
    /**
     * Cards relation creation date
     */
    created_date: '',
};
