
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NFilterManager from '@/components/common/filter/manager/filter-manager/NFilterManager.vue';
import { Filter, FilterValue, filterManagerModule, FilterManagerModule } from '@/components/common/filter/filter-manager';
import { isEqual } from 'lodash';

export type Labels = {
  label: string;
  headerLabel: string;
  footerLabel: string;
};

@Options({
  name: 'FilterManager',
  components: { NFilterManager }
})
export default class FilterManager extends Vue {
  @Prop({ type: String, required: true })
  readonly source!: string;

  @Prop({ type: String, required: true })
  readonly filterString!: string;

  filtersList: FilterValue[] = [];

  get labels(): Labels {
    return { label: this.$t('common.more_filters'), headerLabel: this.$t('common.apply_filters'), footerLabel: this.$t('common.save_selected_filters') };
  }

  get filters(): FilterValue[] {
    return this.filterCurrentItems.filters;
  }

  get filterCurrentItems(): Filter {
    return filterManagerModule.filters.find((item) => item.source === this.source) ?? { source: this.source, filters: [] };
  }

  get filterManagerModule(): FilterManagerModule {
    return filterManagerModule;
  }

  get placeholder(): string {
    return this.$t('common.enter_filters_name');
  }

  @Watch('filters')
  changeFiltersHandler(v: FilterValue[]) {
    this.filtersList = v;
  }

  get selectedId(): undefined | string {
    const filter = this.filters.find((v) => v.value === this.filterString);
    return filter?.id;
  }

  async created() {
    this.filtersList = this.filters;
  }

  async addHandler(item: FilterValue) {
    const newItem = { ...item, value: this.filterString };
    const previousItemIndex = this.filtersList.findIndex((v) => v.value === this.filterString);
    if (previousItemIndex > -1) this.filtersList.splice(previousItemIndex, 1);
    this.filtersList.push(newItem);
    this.selectHandler(newItem);
    await filterManagerModule.addFilter({ source: this.source, filters: this.filtersList });
    await filterManagerModule.saveFilters();
  }

  async selectHandler(item?: FilterValue) {
    this.$emit('select', item);
  }

  async deleteHandler(id: string) {
    this.filtersList.map((item) => {
      return item.id === id ? (item.deleted = true) : item.deleted;
    });
    await filterManagerModule.updateFilters({ source: this.source, filters: this.filtersList });
    await filterManagerModule.saveFilters();
  }

  async hideHandler() {
    const remainingItems = this.filtersList.filter((filter) => !filter.deleted);
    if (!isEqual(this.filtersList, remainingItems)) {
      this.filtersList = remainingItems;
      await filterManagerModule.updateFilters({ source: this.source, filters: this.filtersList });
      await filterManagerModule.saveFilters();
    }
  }

  undoHandler(id: string) {
    this.filtersList.map((item) => {
      return item.id === id ? (item.deleted = false) : item.deleted;
    });
  }

  @Watch('filterString')
  async filterStringHandler(v: string) {
    const selectedItem = this.filters.find((v) => v.selected);
    if (selectedItem) {
      selectedItem.selected = v === selectedItem.value;
    }
  }
}
