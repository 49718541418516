/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DetectRequestRequest } from '../models/DetectRequestRequest';
import type { DetectResponse } from '../models/DetectResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class DetectService {
    /**
     * Detect objects on a photo
     * Use this method to detect objects on a photo
     * @param formData
     * @returns DetectResponse
     * @throws ApiError
     */
    public static detectCreate(
        formData: DetectRequestRequest,
    ): CancelablePromise<DetectResponse> {
        return __request({
            method: 'POST',
            path: `/detect`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
}