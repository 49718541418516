import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-page-sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchSidebarCard = _resolveComponent("SearchSidebarCard")!
  const _component_ThumbnailItem = _resolveComponent("ThumbnailItem")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isCard)
        ? (_openBlock(), _createBlock(_component_SearchSidebarCard, {
            "search-module": _ctx.searchModule,
            key: _ctx.searchModule.item.id
          }, null, 8, ["search-module"]))
        : (_openBlock(), _createBlock(_component_ThumbnailItem, {
            key: 1,
            item: _ctx.itemThumbnailAndName
          }, null, 8, ["item"]))
    ]),
    (_ctx.isFile && _ctx.searchModule.attachment)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 0,
          type: "secondary",
          icon: "search-wrapped",
          onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('showDetectDialog')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('search.select_another_object', 'f')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}