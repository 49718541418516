import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "external-search-page" }
const _hoisted_2 = { class: "external-search-page__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExternalSearchRequests = _resolveComponent("ExternalSearchRequests")!
  const _component_ExternalSearchResults = _resolveComponent("ExternalSearchResults")!
  const _component_ImageViewer = _resolveComponent("ImageViewer")!
  const _component_PageContentLayout = _resolveComponent("PageContentLayout")!

  return (_openBlock(), _createBlock(_component_PageContentLayout, {
    "has-viewer": !!_ctx.src
  }, {
    "image-viewer": _withCtx(() => [
      _createVNode(_component_ImageViewer, {
        src: _ctx.src,
        onClose: _ctx.disposeEventImage
      }, null, 8, ["src", "onClose"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createVNode(_component_ExternalSearchRequests, {
            onShowResultsById: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchRequestId = $event)),
            onDisplayCardImage: _ctx.displayImage
          }, null, 8, ["onDisplayCardImage"]), [
            [_vShow, _ctx.searchRequestId === -1]
          ]),
          (_ctx.searchRequestId > -1)
            ? (_openBlock(), _createBlock(_component_ExternalSearchResults, {
                key: 0,
                searchRequestId: _ctx.searchRequestId,
                onBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchRequestId = -1)),
                onDisplayCardImage: _ctx.displayImage,
                onDisplayEventImage: _ctx.displayImage
              }, null, 8, ["searchRequestId", "onDisplayCardImage", "onDisplayEventImage"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["has-viewer"]))
}