import axios from 'axios';
import { RequestExceptionAdapter, RequestWithoutResponseExceptionAdapter, RuntimeExceptionAdapter, ServiceExceptionAdapter } from './adapters';
import { Exception, ExceptionAdapterFactory, Optional, PlainObject, RequestException, RuntimeException, ServiceException } from './types';

const asRequestException: ExceptionAdapterFactory = (source) => {
  return isRequestException(source) ? new RequestExceptionAdapter(source) : null;
};

const asRequestWithoutResponseException: ExceptionAdapterFactory = (source) => {
  return isRequestWithoutResponseException(source) ? new RequestWithoutResponseExceptionAdapter(source) : null;
};

const asRuntimeException: ExceptionAdapterFactory = (source) => {
  return isRuntimeException(source) ? new RuntimeExceptionAdapter(source) : null;
};

const asServiceException: ExceptionAdapterFactory = (source) => {
  return isServiceException(source) ? new ServiceExceptionAdapter(source) : null;
};

export const asException = composeExceptionAdapterFactories([asServiceException, asRequestException, asRequestWithoutResponseException, asRuntimeException]);

export function composeExceptionAdapterFactories(factories: ExceptionAdapterFactory[]): ExceptionAdapterFactory {
  return (source) => factories.reduce<Optional<Exception>>((exception, factory) => exception ?? (exception = factory(source)), null);
}

export function isRequestException(candidate: unknown): candidate is RequestException {
  return axios.isAxiosError(candidate) && isPlainObject(candidate.response);
}

export function isRequestWithoutResponseException(candidate: unknown): candidate is RequestException {
  return axios.isAxiosError(candidate) && candidate.response === undefined;
}

export function isRuntimeException(candidate: unknown): candidate is RuntimeException {
  return isPlainObject(candidate) && candidate instanceof Error;
}

export function isServiceException(candidate: unknown): candidate is ServiceException {
  const response = isRequestException(candidate) ? candidate.response.data : null;
  return isPlainObject(response) && isString(response.code) && isString(response.desc);
}

export function isString(candidate: unknown): candidate is string {
  return typeof candidate === 'string';
}

export function isNumber(candidate: unknown): candidate is number {
  return typeof candidate === 'number';
}

export function isBoolean(candidate: unknown): candidate is boolean {
  return typeof candidate === 'boolean';
}

export function isTrueBoolean(candidate: unknown): candidate is true {
  return isBoolean(candidate) && candidate;
}

export function isFalseBoolean(candidate: unknown): candidate is false {
  return isBoolean(candidate) && !candidate;
}

export function isNonEmptyString(candidate: unknown): candidate is string {
  return isString(candidate) && candidate !== '';
}

export function isPlainObject(candidate: unknown): candidate is PlainObject {
  return typeof candidate === 'object' && candidate !== null;
}

export function throwError(message: string): never {
  throw new Error(message);
}
