import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "camera-status-details" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, ({ label, value }, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        _createElementVNode("div", {
          class: "camera-status-details__label label-xs",
          textContent: _toDisplayString(label)
        }, null, 8, _hoisted_2),
        _createElementVNode("div", {
          class: "camera-status-details__value label-xs",
          textContent: _toDisplayString(value)
        }, null, 8, _hoisted_3)
      ], 64))
    }), 128))
  ]))
}