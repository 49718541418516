
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import MetafieldListItem from '@/pages/cards/content/MetafieldListItem.vue';
import { CarCard, HumanCard } from '@/api';

@Options({
  name: 'CardMetafields',
  components: { MetafieldListItem }
})
export default class CardMetafields extends Vue {
  @Prop({ type: Object, required: true })
  item!: HumanCard | CarCard;

  @Prop({ type: String, required: true })
  type!: string;
}
