export type PointAsArray = [number, number];
export type SingleLine = [PointAsArray, PointAsArray];
export type PolyLine = PointAsArray[];
export type PointAction = {
  type: PointActionType;
  index: number | null;
};

export type RGBColorArray = [number, number, number];
export type SegmentGradientColor = [RGBColorArray, RGBColorArray];

export type CanvasRect = ImageRect & {
  x: number;
  y: number;
};

export type ImageRect = {
  width: number;
  height: number;
};

export type TextStyleOptions = FigureStyleOptions & {
  textAlign?: 'start' | 'end' | 'center';
  textBaseline?: 'top' | 'hanging' | 'middle' | 'alphabetic' | 'ideographic' | 'bottom';
};

export type PolyLineStyleOptions = FigureStyleOptions & {
  strokeStyle?: PolyLineStyleColor;
  fillStyle?: PolyLineStyleColor;
  dotsSize?: number;
};

export type PolyLineStyleColor = CommonStyleColor | SegmentGradientColor;

export type FigureStyleOptions = {
  strokeStyle?: CommonStyleColor;
  fillStyle?: CommonStyleColor;
  lineWidth?: number;
  lineDash?: number[];
  lineCap?: CanvasLineCap;
};

export type CommonStyleColor = string | CanvasGradient | CanvasPattern;

export type Color = typeof Colors[keyof typeof Colors];
export const Colors = {
  Accent: '#3379d9',
  ExcludeFill: 'rgba(0, 0, 0, 0.7)',
  LineActive: 'rgb(169,255,169)',
  LineDefault: 'rgb(0, 200, 0)',
  PointA: 'rgba(50, 215, 75, 1)',
  PointB: 'rgba(255, 55, 95, 1)',
  PointBorder: 'rgb(0, 255, 0)',
  PointDraggedFill: 'rgba(55, 255, 55, 0.9)',
  PointOverFill: 'rgba(255, 55, 55, 0.9)',
  PolygonFill: 'rgba(0, 0, 0, 0.3)',
  Transparent: 'rgba(0, 0, 0, 0)',
  White90: 'rgba(255, 255, 255, 0.9)'
} as const;

export type Size = typeof Sizes[keyof typeof Sizes];
export const Sizes = {
  PointSizeNormal: 12,
  PointSizeSmall: 8,
  PointSizeBig: 20,
  PointTextBig: 13,
  LineNormalWidth: 2,
  LineThinWidth: 1
} as const;

export type PointActionType = typeof PointActionTypes[keyof typeof PointActionTypes];
export const PointActionTypes = {
  None: 'none',
  DrawRect: 'drawRect',
  Close: 'close',
  Move: 'move',
  MoveAll: 'moveAll',
  AddPoint: 'addPoint',
  DeletePoint: 'deletePoint',
  ReversePoints: 'reversePoints'
} as const;

export type DrawMode = typeof DrawModes[keyof typeof DrawModes];
export const DrawModes = {
  Poly: 'poly',
  Rect: 'rect',
  Line: 'line'
};

export function isSegmentGradientColor(color: any): color is SegmentGradientColor {
  return Array.isArray(color);
}
