
import ListPage from '@/pages/ListPage.vue';
import NBaseBar from '@/uikit/bars/NBaseBar.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';
import { ListViewModel } from '@/definitions/view-models';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import EntityBlank from '@/components/common/EntityBlank.vue';
import { PagePaths } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { Line } from '@/api/models/Line';
import { LinesFilter } from '@/api/models/LinesFilter';
import { aclModule } from '@/store/acl';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import LinesTable from '@/pages/lines/LinesTable.vue';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { multisidebarModule } from '@/store/multisidebar';
import { getLineFilters } from '@/pages/lines/LineFilters';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import { GlobalEvent } from '@/store/global-event/types';
import { globalEventModule, GlobalEventName } from '@/store/global-event';
import { merge } from 'lodash';

@Options({
  name: 'LinesPage',
  components: {
    FilterSection,
    LinesTable,
    EntityBlank,
    ListPage,
    NBaseBar,
    NButton,
    NButtonGroup,
    SortDropdown,
    Statistics
  }
})
export default class LinesPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  get smallFilterLayout(): IFormLayoutItem[] {
    return getLineFilters({ small: true });
  }

  get bigFilterLayout(): IFormLayoutItem[] {
    return getLineFilters({ small: false });
  }

  get selectedItemIds(): number[] {
    return this.sidebarModule.items.filter((v) => v.type === MultisidebarItemTypes.Lines).map((v) => v.model.item.id);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get state() {
    return pageModule.getPageStateByTab(PagePaths.Lines, this.tab);
  }

  get module() {
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<Line, LinesFilter>;
  }

  get modelAcl() {
    this.module.aclModelName = 'line';
    return aclModule.getModelAcl(this.module);
  }

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ created_date: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get lastPageEvent() {
    return globalEventModule.current?.type === MultisidebarItemTypes.Lines ? globalEventModule.current : null;
  }

  @Watch('module.filter.current', { deep: true })
  handleFiltersChange() {
    this.module.get();
  }

  @Watch('lastPageEvent')
  handleGlobalEvent(event: GlobalEvent) {
    if (!event) return;
    switch (event.name) {
      case GlobalEventName.Create:
      case GlobalEventName.Delete:
        this.module.get();
        break;
      case GlobalEventName.Update:
        merge(
          this.module.items.find((v) => v.id === event.payload.id),
          event.payload
        );
        break;
    }
  }

  handleCreate() {
    this.sidebarModule.addNewItemByType(MultisidebarItemTypes.Lines);
  }
}
