export default {
  relations: 'Interaction Tracking',
  search: 'Search',
  bi_app: 'BI Analytics',
  bi_app_desc: 'Data visualization and exploration tool',
  alarm_app: 'Alarm Monitor',
  alarm_app_desc: 'Receive notifications about alarms when they occur',
  search_desc: 'Search individuals across record index and recognition results',
  search_with_cars_desc: 'Search individuals and vehicles across record index and recognition results',
  relations_desc: 'Analyze affiliations and relations of an individual by cross-referencing recognized facial images',
  video_wall: 'Video Wall',
  video_wall_desc: 'Tile number of video feeds on a single display',
  cibr: 'Case Files',
  cibr_desc: 'Process CSI footage to boost up digital intelligence',
  counters: 'Counters',
  counters_desc: 'Count attendance of a surveillance area by analyzing recognized images',
  clusters: 'Clusters',
  clusters_desc: 'Analyze unique visitors with automated clustering of similar faces',
  data_sources: 'Video Sources',
  data_sources_desc: 'Cameras, Video Files, External Detectors',
  episodes_and_events: 'Episodes & Events',
  episodes_and_events_desc: 'Watch, analyze and match recognized images in real time',
  analytics: 'Audience analysis',
  analytics_desc: 'Automatic footfall and traffic analysis using object recognition',
  settings: 'Settings',
  settings_desc: 'Set up roles, users, permissions, reference data, license, or read the manual',
  cards: 'Record Index',
  cards_desc: 'Organize and manage data of individuals and/or vehicles under observation',
  cards_with_cars_desc: 'Organize and manage data of individuals and vehicles under observation',
  areas: 'Areas',
  areas_desc: 'Monitor presence of people in a defined areas using rules and schedules',
  player: 'Video Player',
  player_desc: 'Play video files and streams powered with advanced hardware acceleration and subtitle support',
  verify: 'Verify',
  verify_desc: 'Compare objects and find out their similarity',
  audit_logs: 'Audit Log',
  reports: 'Reports',
  reports_desc: 'Download and manage reports you requested',
  remote_monitoring_events: 'Remote Alerting',
  remote_monitoring_events_desc: 'Get notified as an object of interest is recognized by remote FindFace systems',
  external_search: 'Remote Search',
  external_search_desc: 'Search an object of interest across images recognized by remote FindFace systems',
  lines: 'Line Crossing',
  lines_desc: 'Create a virtual line to control its intersection by an object'
};
