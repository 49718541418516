export function deleteByEq(items: any[], predicate: (value: any, index: number) => boolean) {
  const index = items.findIndex(predicate);
  if (index !== -1) items.splice(index, 1);
}

export function deleteById(items: any[], id: string | number) {
  const index = items.findIndex((item) => item.id == id);
  if (index !== -1) items.splice(index, 1);
}

export function deleteByItem(items: any[], item: any) {
  const index = items.indexOf(item);
  if (index !== -1) items.splice(index, 1);
}
