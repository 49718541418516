/**
 * Crop image to particular size and
 * pass callback function with new base64
 * @param imgUri
 * @param width
 * @param height
 */

export default function cropImage(imgUri: string, width = 400, height = 300, xstart = 0, ystart = 0): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      // console.log('Values in crop', width, height);
      let resize_canvas = document.createElement('canvas');
      let orig_src = new Image();
      orig_src.src = imgUri;
      orig_src.onload = function () {
        resize_canvas.width = width;
        resize_canvas.height = height;
        let cnv = resize_canvas.getContext('2d');
        cnv!.drawImage(orig_src, xstart, ystart, width, height, 0, 0, width, height);
        let newimgUri = resize_canvas.toDataURL('image/png').toString();
        resolve(newimgUri);
      };
      orig_src.onerror = function () {
        reject(imgUri);
      };
    } catch (e) {
      console.warn('Could not crop image due to', e);
      reject(imgUri);
    }
  });
}
