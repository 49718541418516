
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { applicationModule } from '@/store/application';

@Options({
  name: 'DisplayTypes',
  components: { NButtonGroup }
})
export default class DisplayTypes extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  displayType = 'short';

  get dataAssetsModule() {
    return dataAssetsModule;
  }

  @Watch('displayType')
  displayTypeHandler(value: any) {
    this.$emit('update:modelValue', value);
  }

  created() {
    this.displayType = applicationModule.settings.ui.list_view;
    this.$emit('update:modelValue', this.displayType);
  }
}
