export type ItemsActionName = typeof ItemsActionNames[keyof typeof ItemsActionNames];

export const ItemsActionNames = {
  Acknowledge: 'acknowledge',
  Activate: 'activate-current',
  ActivateAll: 'activate-all',
  AddAllToMonitoring: 'add-all-to-monitoring',
  AddToMonitoring: 'add-to-monitoring',
  Archive: 'archive',
  ArchiveAll: 'archive-all',
  Block: 'block',
  VideoReset: 'video-reset',
  CameraReset: 'camera-reset',
  CameraResetAdvanced: 'camera-reset-advanced',
  CameraResetAll: 'camera-reset-all',
  CameraResetDetectors: 'camera-reset-detectors',
  CameraResetFull: 'camera-reset-full',
  CameraResetMap: 'camera-reset-map',
  CameraResetZone: 'camera-reset-zone',
  ClusterMergeAll: 'cluster-merge-all',
  Close: 'close',
  CloseGroup: 'close-group',
  CreateCard: 'create-card',
  Deactivate: 'deactivate-current',
  DeactivateAll: 'deactivate-all',
  Delete: 'delete-current',
  DeleteAll: 'delete-all',
  DeleteCards: 'delete-cards-in-current',
  DeleteCardsInAll: 'delete-cards-in-all',
  DeleteVideoFile: 'delete-video-file',
  DeleteVideoFileAll: 'delete-video-file-all',
  Deselect: 'deselect',
  DeselectAll: 'deselect-all',
  Detect: 'detect',
  ExternalSearch: 'external-search',
  FilterCardEvent: 'filter-card-event',
  FilterLineFaceEvents: 'filter-line-face-events',
  FilterLineBodyEvents: 'filter-line-body-events',
  FilterLineCarEvents: 'filter-line-car-events',
  MonitoringEvents: 'monitoring-events',
  NavigateToCard: 'navigate-to-card',
  NavigateToUser: 'navigate-to-user',
  OpenAll: 'open-all',
  Process: 'process',
  ProcessAll: 'process-all',
  Remove: 'remove',
  RemoveAllFromMonitoring: 'remove-all-from-monitoring',
  RemoveFromMonitoring: 'remove-from-monitoring',
  Reset: 'reset-current',
  Restart: 'restart',
  Save: 'save-current',
  SaveAll: 'save-all',
  Search: 'search',
  SelectAll: 'select-all',
  SetCover: 'set-cover',
  ShowFullScreen: 'show-full-screen',
  ShowPlayer: 'show-player',
  StopProcess: 'stop-process',
  StopProcessAll: 'stop-process-all',
  ToggleCurrent: 'toggle-current',
  UpdateActiveField: 'update-active-field',
  Upload: 'upload',
  UploadFile: 'upload-file',

  AddItem: 'add-item',
  CloseCurrentItem: 'close-current-item',
  RemoveCurrentItem: 'remove-current-item',
  ShowInfo: 'show-info', //@todo-remove: this is show-item duplicate in events
  ShowItem: 'show-item',
  ShowInteractions: 'show-interactions',
  ToggleSelectItem: 'toggle-select-item'
} as const;

export const ItemsActionIcons: Partial<Record<ItemsActionName, string>> = {
  [ItemsActionNames.ActivateAll]: 'success',
  [ItemsActionNames.CloseGroup]: 'close',
  [ItemsActionNames.DeactivateAll]: 'failure',
  [ItemsActionNames.DeleteAll]: 'trash',
  [ItemsActionNames.DeleteCardsInAll]: 'trash',
  [ItemsActionNames.DeleteVideoFileAll]: 'trash',
  [ItemsActionNames.ShowPlayer]: 'play',
  [ItemsActionNames.CameraResetAll]: 'refresh',
  [ItemsActionNames.StopProcessAll]: 'stop-processing',
  [ItemsActionNames.ProcessAll]: 'play-processing'
};
