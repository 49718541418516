import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_ctx.sortable)
    ? (_openBlock(), _createBlock(_component_NButton, {
        key: 0,
        class: _normalizeClass(_ctx.classes),
        onClick: _ctx.sort,
        type: "link"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }, 8, ["class", "onClick"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(_ctx.classes)
      }, _toDisplayString(_ctx.label), 3))
}