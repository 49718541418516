/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { CameraStreamSettings } from './CameraStreamSettings';
export type InlineStreamSettings = {
    stream_settings: CameraStreamSettings;
};
export const EmptyInlineStreamSettings: InlineStreamSettings = {
    stream_settings: {},
};
