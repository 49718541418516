
import type { Camera } from '@/api';
import { isDefined } from '@/common/utils';
import VideoPlayer from '@/components/video-player/VideoPlayer.vue';
import { Options, Prop, Vue } from 'vue-property-decorator';
import { Nullable } from '../../types';
import VideoWallPlayerLoader from './VideoWallPlayerLoader.vue';
import VideoWallPlayerStatus from './VideoWallPlayerStatus.vue';

const ONE_SECOND_IN_MILLISECONDS = 1e3;

@Options({
  components: {
    VideoPlayer,
    VideoWallPlayerLoader,
    VideoWallPlayerStatus
  },
  methods: { isDefined }
})
export default class VideoWallPlayer extends Vue {
  @Prop({ required: true })
  readonly camera!: Nullable<Camera>;

  getCurrentTimestampInSeconds(): number {
    return Math.floor(Date.now() / ONE_SECOND_IN_MILLISECONDS) + ONE_SECOND_IN_MILLISECONDS;
  }
}
