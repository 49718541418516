/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Relation } from './Relation';
export type VerboseRelationHumanCardLink = {
    readonly id: number;
    /**
     * Short name, up to 256 characters
     */
    name: string;
    /**
     * RelationLink creation date
     */
    readonly created_date: string;
    card: number;
    readonly relation: Relation;
};
export const EmptyVerboseRelationHumanCardLink: VerboseRelationHumanCardLink = {
    id: 0,
    /**
     * Short name, up to 256 characters
     */
    name: '',
    /**
     * RelationLink creation date
     */
    created_date: '',
    card: 0,
    relation: {} as any as Relation,
};
