import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-dropdown__body",
  ref: "body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['n-dropdown', { 'n-dropdown_disabled': _ctx.disabled }]),
    ref: "root"
  }, [
    _renderSlot(_ctx.$slots, "toggle", {
      toggle: _ctx.toggle,
      hide: _ctx.hide
    }),
    (_openBlock(), _createBlock(_Teleport, {
      to: "body",
      disabled: _ctx.isTeleportDisabled
    }, [
      (_ctx.isShown)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
            _renderSlot(_ctx.$slots, "body", { toggle: _ctx.toggle })
          ], 512))
        : _createCommentVNode("", true)
    ], 8, ["disabled"]))
  ], 2))
}