/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { ClusterLooksLike } from './ClusterLooksLike';
export type FaceCluster = {
    readonly id: number;
    /**
     * Cluster tnt-id
     */
    readonly tnt_id: number;
    /**
     * Cluster creation date
     */
    readonly created_date: string;
    /**
     * Cluster events count
     */
    readonly events_count: number;
    /**
     * Average cluster events quality
     */
    readonly events_quality: number;
    /**
     * Cluster's cameras list
     */
    readonly cameras: Array<number>;
    /**
     * Cluster's camera groups list
     */
    readonly camera_groups: Array<number>;
    /**
     * Best cluster event
     */
    readonly best_event: number;
    /**
     * Best cluster event quality
     */
    readonly best_event_quality: number;
    /**
     * Related card
     */
    readonly card: number;
    /**
     * Card match confidence
     */
    readonly match_confidence: number;
    /**
     * Matched watch lists
     */
    readonly matched_lists: Array<number>;
    /**
     * Whether cluster is pinned or not
     */
    pinned?: boolean;
    readonly looks_like: ClusterLooksLike | null;
    readonly looks_like_confidence: number;
    /**
     * Related case
     */
    readonly case: number;
    /**
     * Related case participant
     */
    readonly case_participant: number;
    /**
     * Face cluster gender feature
     */
    readonly gender: string;
    /**
     * Face cluster age feature
     */
    readonly age: number;
    /**
     * Face cluster glasses feature
     */
    readonly glasses: string;
    /**
     * Face cluster emotions feature
     */
    readonly emotions: string;
    /**
     * Face cluster beard feature
     */
    readonly beard: string;
    /**
     * Face cluster liveness feature
     */
    readonly liveness: string;
    /**
     * Face cluster look feature
     */
    readonly look: string;
    /**
     * Face cluster medmask feature
     */
    readonly medmask: string;
};
export const EmptyFaceCluster: FaceCluster = {
    id: 0,
        /**
        * Cluster tnt-id
        */
    tnt_id: 0,
        /**
        * Cluster creation date
        */
    created_date: '',
        /**
        * Cluster events count
        */
    events_count: 0,
        /**
        * Average cluster events quality
        */
    events_quality: 0,
        /**
        * Cluster's cameras list
        */
    cameras: [],
        /**
        * Cluster's camera groups list
        */
    camera_groups: [],
        /**
        * Best cluster event
        */
    best_event: 0,
        /**
        * Best cluster event quality
        */
    best_event_quality: 0,
        /**
        * Related card
        */
    card: 0,
        /**
        * Card match confidence
        */
    match_confidence: 0,
        /**
        * Matched watch lists
        */
    matched_lists: [],
        /**
        * Whether cluster is pinned or not
        */
    pinned: false,
    looks_like: null,
    looks_like_confidence: 0,
        /**
        * Related case
        */
    case: 0,
        /**
        * Related case participant
        */
    case_participant: 0,
        /**
        * Face cluster gender feature
        */
    gender: '',
        /**
        * Face cluster age feature
        */
    age: 0,
        /**
        * Face cluster glasses feature
        */
    glasses: '',
        /**
        * Face cluster emotions feature
        */
    emotions: '',
        /**
        * Face cluster beard feature
        */
    beard: '',
        /**
        * Face cluster liveness feature
        */
    liveness: '',
        /**
        * Face cluster look feature
        */
    look: '',
        /**
        * Face cluster medmask feature
        */
    medmask: '',
};