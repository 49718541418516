import { RepeaterState, useRepeaterPausedState } from './state';
import { RepeaterContext, RepeaterDelayInMs, RepeaterHandler, RepeaterStateSwitcher } from './types';

export class Repeater {
  private state: RepeaterState;

  constructor(delayInMs: RepeaterDelayInMs, handler: RepeaterHandler) {
    const context: RepeaterContext = { delayInMs, handler };
    const toState: RepeaterStateSwitcher = (state) => (this.state = state);
    this.state = useRepeaterPausedState(context, toState);
  }

  pause(): void {
    this.state.pause();
  }

  start(): void {
    this.state.start();
  }
}
