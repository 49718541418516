export enum EventTypes {
  Action = 'action',
  Select = 'select'
}

export enum EventDetails {
  Remove = 'remove',
  ToggleSelected = 'toggle-selected',
  CloseInfo = 'close-info',
  ShowInfo = 'show-info',
  ShowPlayer = 'show-player',
  ShowFullScreen = 'show-full-screen',
  Search = 'search',
  FilterEvents = 'filter-events',
  ShowCard = 'show-card'
}

export enum ThumbnailPositions {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right'
}
