import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "n-progress" }
const _hoisted_2 = { class: "n-progress__value label-s" }
const _hoisted_3 = { class: "n-progress__progress" }
const _hoisted_4 = { class: "n-progress__name label-s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.floatValue)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.percentValue) + "%", 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "n-progress__progress-content",
              style: _normalizeStyle({ width: _ctx.percentValue + '%' })
            }, null, 4)
          ])
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.label), 1)
  ]))
}