import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "sidebar-header" }
const _hoisted_2 = { class: "sidebar-header__left" }
const _hoisted_3 = {
  key: 0,
  class: "sidebar-header__label header heading-m"
}
const _hoisted_4 = { class: "sidebar-header__label__content" }
const _hoisted_5 = { class: "sidebar-header__sub-label label-xs" }
const _hoisted_6 = {
  key: 1,
  class: "sidebar-header__inactivated label-m"
}
const _hoisted_7 = { class: "sidebar-header__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_MultisidebarHeaderActions = _resolveComponent("MultisidebarHeaderActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "beforeLabel"),
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _renderSlot(_ctx.$slots, "subLabel", {}, () => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.subLabel), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.inactivated)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t(_ctx.i18nInactivatedLabel, 'f')), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "extra-status")
    ]),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "beforeActions"),
      (_ctx.changed)
        ? (_openBlock(), _createBlock(_component_NTag, {
            key: 0,
            class: "sidebar-header__changed",
            type: "focus",
            "label-size": "2xs",
            label: _ctx.$t('common.changed', 'f'),
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actionHandler('reset-current'))),
            onAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actionHandler('reset-current'))),
            closeable: ""
          }, null, 8, ["label"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "actions", {}, () => [
        _createVNode(_component_MultisidebarHeaderActions, {
          actions: _ctx.actions,
          "current-item-acl": _ctx.currentItemAcl,
          "model-acl": _ctx.modelAcl,
          "has-toggle": _ctx.hasToggle,
          "has-deselect": _ctx.hasDeselect,
          onAction: _ctx.actionHandler
        }, null, 8, ["actions", "current-item-acl", "model-acl", "has-toggle", "has-deselect", "onAction"])
      ])
    ])
  ]))
}