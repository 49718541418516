/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExternalVms } from '../models/ExternalVms';
import type { ExternalVmsEvent } from '../models/ExternalVmsEvent';
import type { ExternalVmsEventCreate } from '../models/ExternalVmsEventCreate';
import type { ExternalVmsEventCreateRequest } from '../models/ExternalVmsEventCreateRequest';
import type { ExternalVmsRequest } from '../models/ExternalVmsRequest';
import type { ExternalVmsSendEventStatus } from '../models/ExternalVmsSendEventStatus';
import type { ExternalVmsUpdate } from '../models/ExternalVmsUpdate';
import type { ExternalVmsUpdateRequest } from '../models/ExternalVmsUpdateRequest';
import type { PaginatedExternalVmsEventList } from '../models/PaginatedExternalVmsEventList';
import type { PaginatedExternalVmsList } from '../models/PaginatedExternalVmsList';
import type { PaginatedExternalVmsSendEventStatusList } from '../models/PaginatedExternalVmsSendEventStatusList';
import type { PaginatedVmsPluginCamerasResponseList } from '../models/PaginatedVmsPluginCamerasResponseList';
import type { PatchedExternalVmsUpdateRequest } from '../models/PatchedExternalVmsUpdateRequest';
import type { VmsAvailability } from '../models/VmsAvailability';
import type { VmsPluginInfoRequest } from '../models/VmsPluginInfoRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class ExternalVmsService {
    /**
     * List external-vms integrations
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param limit Number of results to return per page.
     * @param ordering Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
     * @param page Pagination cursor value.
     * @param pluginVmsId Select external-vms entries that matched this `plugin_vms_id`
     * @param verboseName Select external-vms entries with this `verbose_name`
     * @param version Select external-vms entries with this `version`
     * @param vms Select external-vms entries with this `vms`
     * @returns PaginatedExternalVmsList
     * @throws ApiError
     */
    public static externalVmsList(
      createdDateGt?: string,
      createdDateGte?: string,
      createdDateLastNDays?: number,
      createdDateLt?: string,
      createdDateLte?: string,
      createdDateNthFullWeek?: number,
      createdDateNthWorkWeek?: number,
      limit?: string,
      ordering?: string,
      page?: string,
      pluginVmsId?: number,
      verboseName?: string,
      version?: string,
      vms?: string,
    ): CancelablePromise<PaginatedExternalVmsList> {
        return __request({
            method: 'GET',
            path: `/external-vms/`,
            query: {
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'limit': limit,
                'ordering': ordering,
                'page': page,
                'plugin_vms_id': pluginVmsId,
                'verbose_name': verboseName,
                'version': version,
                'vms': vms,
            },
        });
    }
    /**
     * Add a new external-vms integration
     * @param requestBody
     * @returns ExternalVms
     * @throws ApiError
     */
    public static externalVmsCreate(
      requestBody: ExternalVmsRequest,
    ): CancelablePromise<ExternalVms> {
        return __request({
            method: 'POST',
            path: `/external-vms/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve external-vms integration by ID
     * @param id A unique integer value identifying this external vms.
     * @returns ExternalVms
     * @throws ApiError
     */
    public static externalVmsRetrieve(
      id: number,
    ): CancelablePromise<ExternalVms> {
        return __request({
            method: 'GET',
            path: `/external-vms/${id}/`,
        });
    }
    /**
     * @param id A unique integer value identifying this external vms.
     * @param requestBody
     * @returns ExternalVmsUpdate
     * @throws ApiError
     */
    public static externalVmsUpdate(
      id: number,
      requestBody?: ExternalVmsUpdateRequest,
    ): CancelablePromise<ExternalVmsUpdate> {
        return __request({
            method: 'PUT',
            path: `/external-vms/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update external-vms integration options
     * @param id A unique integer value identifying this external vms.
     * @param requestBody
     * @returns ExternalVmsUpdate
     * @throws ApiError
     */
    public static externalVmsPartialUpdate(
      id: number,
      requestBody?: PatchedExternalVmsUpdateRequest,
    ): CancelablePromise<ExternalVmsUpdate> {
        return __request({
            method: 'PATCH',
            path: `/external-vms/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this external vms.
     * @returns void
     * @throws ApiError
     */
    public static externalVmsDestroy(
      id: number,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/external-vms/${id}/`,
        });
    }
    /**
     * Retrieve external vms stream link
     * Use this method to retrieve video archive stream link
     * @param camera camera id
     * @param id A unique integer value identifying this external vms.
     * @param timeFrom Video start date (UTC)
     * @param timeTo Video end date (UTC)
     * @param maxHeight Maximum frame height
     * @param maxWidth Maximum frame width
     * @param quality Video quality
     * @param startTime video-streamer start_time
     * @param timeout video-streamer connection timeout
     * @returns ExternalVms
     * @throws ApiError
     */
    public static externalVmsArchiveRetrieve(
      camera: number,
      id: number,
      timeFrom: string,
      timeTo: string,
      maxHeight: number = 1080,
      maxWidth: number = 1920,
      quality: number = 100,
      startTime: number = 5,
      timeout: number = 10,
    ): CancelablePromise<ExternalVms> {
        return __request({
            method: 'GET',
            path: `/external-vms/${id}/archive/`,
            query: {
                'camera': camera,
                'max_height': maxHeight,
                'max_width': maxWidth,
                'quality': quality,
                'start_time': startTime,
                'time_from': timeFrom,
                'time_to': timeTo,
                'timeout': timeout,
            },
        });
    }
    /**
     * Cameras available in external VMS
     * List of cameras available in external VMS
     * @param id A unique integer value identifying this external vms.
     * @param added If `true` - the camera is added to the FindFace Multi
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param idIn VMS camera id
     * @param limit Number of results to return per page.
     * @param nameContains VMS camera name
     * @param ordering Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
     * @param page Pagination cursor value.
     * @param pluginVmsId Select external-vms entries that matched this `plugin_vms_id`
     * @param verboseName Select external-vms entries with this `verbose_name`
     * @param version Select external-vms entries with this `version`
     * @param vms Select external-vms entries with this `vms`
     * @returns PaginatedVmsPluginCamerasResponseList
     * @throws ApiError
     */
    public static externalVmsCamerasList(
      id: number,
      added?: boolean,
      createdDateGt?: string,
      createdDateGte?: string,
      createdDateLastNDays?: number,
      createdDateLt?: string,
      createdDateLte?: string,
      createdDateNthFullWeek?: number,
      createdDateNthWorkWeek?: number,
      idIn?: Array<string>,
      limit?: string,
      nameContains?: string,
      ordering?: string,
      page?: string,
      pluginVmsId?: number,
      verboseName?: string,
      version?: string,
      vms?: string,
    ): CancelablePromise<PaginatedVmsPluginCamerasResponseList> {
        return __request({
            method: 'GET',
            path: `/external-vms/${id}/cameras/`,
            query: {
                'added': added,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'id_in': idIn,
                'limit': limit,
                'name_contains': nameContains,
                'ordering': ordering,
                'page': page,
                'plugin_vms_id': pluginVmsId,
                'verbose_name': verboseName,
                'version': version,
                'vms': vms,
            },
        });
    }
    /**
     * Available VMS integrations
     * Getting VMS availability status
     * @param id A unique integer value identifying this external vms.
     * @returns VmsAvailability
     * @throws ApiError
     */
    public static externalVmsHealthStatusRetrieve(
      id: number,
    ): CancelablePromise<VmsAvailability> {
        return __request({
            method: 'GET',
            path: `/external-vms/${id}/health_status/`,
        });
    }
    /**
     * Play video vms stream link
     * Use this method to retrieve video archive stream link
     * @param camera camera id
     * @param id A unique integer value identifying this external vms.
     * @param timeFrom Video start date (UTC)
     * @param timeTo Video end date (UTC)
     * @param url external vms archive link
     * @param requestBody
     * @returns ExternalVms
     * @throws ApiError
     */
    public static externalVmsPlayCreate(
      camera: number,
      id: number,
      timeFrom: string,
      timeTo: string,
      url: string,
      requestBody: ExternalVmsRequest,
    ): CancelablePromise<ExternalVms> {
        return __request({
            method: 'POST',
            path: `/external-vms/${id}/play/`,
            query: {
                'camera': camera,
                'time_from': timeFrom,
                'time_to': timeTo,
                'url': url,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List external VMS events
     * @param camera Select events from these camera
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param externalVms Select events from these external vms
     * @param limit Number of results to return per page.
     * @param name Select external-vms events where the SQL condition `name ILIKE name_contains` is true
     * @param ordering Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
     * @param page Pagination cursor value.
     * @param type Select external-vms events entries with this `type`
     * @returns PaginatedExternalVmsEventList
     * @throws ApiError
     */
    public static externalVmsEventsList(
      camera?: Array<number>,
      createdDateGt?: string,
      createdDateGte?: string,
      createdDateLastNDays?: number,
      createdDateLt?: string,
      createdDateLte?: string,
      createdDateNthFullWeek?: number,
      createdDateNthWorkWeek?: number,
      externalVms?: Array<number>,
      limit?: string,
      name?: string,
      ordering?: string,
      page?: string,
      type?: string,
    ): CancelablePromise<PaginatedExternalVmsEventList> {
        return __request({
            method: 'GET',
            path: `/external-vms/events/`,
            query: {
                'camera': camera,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'external_vms': externalVms,
                'limit': limit,
                'name': name,
                'ordering': ordering,
                'page': page,
                'type': type,
            },
        });
    }
    /**
     * Add a new external VMS event
     * @param requestBody
     * @returns ExternalVmsEventCreate
     * @throws ApiError
     */
    public static externalVmsEventsCreate(
      requestBody: ExternalVmsEventCreateRequest,
    ): CancelablePromise<ExternalVmsEventCreate> {
        return __request({
            method: 'POST',
            path: `/external-vms/events/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve external VMS event by ID
     * @param id A unique integer value identifying this external vms event.
     * @returns ExternalVmsEvent
     * @throws ApiError
     */
    public static externalVmsEventsRetrieve(
      id: number,
    ): CancelablePromise<ExternalVmsEvent> {
        return __request({
            method: 'GET',
            path: `/external-vms/events/${id}/`,
        });
    }
    /**
     * Available VMS integrations
     * Getting information about available VMS integrations
     * @returns VmsPluginInfoRequest
     * @throws ApiError
     */
    public static externalVmsInfoRetrieve(): CancelablePromise<VmsPluginInfoRequest> {
        return __request({
            method: 'GET',
            path: `/external-vms/info/`,
        });
    }
    /**
     * List of statuses of events sent to the external VMS
     * @param camera Select send status entries from these camera
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param error Select only events with a sending error (`true`) or sent successfully (`false`) statuses
     * @param eventId Select send status entries with this `event_id`
     * @param eventType Select send status entries with this `event_type
     * @param externalVms Select send status entries from these external vms
     * @param limit Number of results to return per page.
     * @param ordering Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
     * @param page Pagination cursor value.
     * @returns PaginatedExternalVmsSendEventStatusList
     * @throws ApiError
     */
    public static externalVmsSendEventStatusList(
      camera?: Array<number>,
      createdDateGt?: string,
      createdDateGte?: string,
      createdDateLastNDays?: number,
      createdDateLt?: string,
      createdDateLte?: string,
      createdDateNthFullWeek?: number,
      createdDateNthWorkWeek?: number,
      error?: boolean,
      eventId?: string,
      eventType?: Array<'body' | 'car' | 'face'>,
      externalVms?: Array<number>,
      limit?: string,
      ordering?: string,
      page?: string,
    ): CancelablePromise<PaginatedExternalVmsSendEventStatusList> {
        return __request({
            method: 'GET',
            path: `/external-vms/send-event-status/`,
            query: {
                'camera': camera,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'error': error,
                'event_id': eventId,
                'event_type': eventType,
                'external_vms': externalVms,
                'limit': limit,
                'ordering': ordering,
                'page': page,
            },
        });
    }
    /**
     * Retrieve status of events sent by ID
     * @param id A unique integer value identifying this external vms send event status.
     * @returns ExternalVmsSendEventStatus
     * @throws ApiError
     */
    public static externalVmsSendEventStatusRetrieve(
      id: number,
    ): CancelablePromise<ExternalVmsSendEventStatus> {
        return __request({
            method: 'GET',
            path: `/external-vms/send-event-status/${id}/`,
        });
    }
}