import { Placement } from '@floating-ui/dom';

export type NDropdownPlacement = Placement;

export const placement: NDropdownPlacement[] = [
  'bottom',
  'bottom-end',
  'bottom-start',
  'left',
  'left-end',
  'left-start',
  'right',
  'right-end',
  'right-start',
  'top',
  'top-end',
  'top-start'
];

export type NDropdownCommand = string | number | PlainObject;

export type PlainObject = Record<string | number | symbol, unknown>;

export type NDropdownCommandEventHandler = (command?: NDropdownCommand) => void;
