
import { Options, Vue } from 'vue-class-component';
import NDropdown from '@/uikit/dropdown/NDropdown.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NDropdownItem from '@/uikit/dropdown/NDropdownItem.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import { Prop } from 'vue-property-decorator';
import { ListItem } from '@/components/events/types';

@Options({
  name: 'NotificationHeader',
  components: { NIcon, NDropdownItem, NButton, NDropdown },
  emits: ['acknowledge-action', 'show-events-action']
})
export default class NotificationHeader extends Vue {
  @Prop({ type: Boolean, default: false })
  private isPlaying!: false;

  @Prop({ type: Boolean, default: true })
  private muteState!: true;

  get actions(): ListItem[] {
    return [
      { label: this.$t('common.show_events'), name: 'show-events', icon: 'show-events' }
      // { label: this.$t('common.acknowledge_all'), name: 'acknowledge-all', icon: 'done-all' } //  до лучших времен, пока не прояснится что делать с продуктом мульти + папитир
    ];
  }

  get muteIcon() {
    return this.muteState ? 'unmute' : 'mute';
  }

  get muteLabel() {
    const token = this.muteState ? 'common.unmute' : 'common.mute';
    return this.$t(token);
  }

  toggleMute() {
    this.isPlaying && this.$emit('toggle-mute');
  }

  actionHandler(name: string) {
    switch (name) {
      case 'acknowledge-all':
        this.$emit('acknowledge-action');
        break;
      case 'show-events':
        this.$emit('show-events-action');
    }
  }
}
