/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BatchUploadFilter = {
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
}
export const EmptyBatchUploadFilter: BatchUploadFilter = {
        limit: '20',
        page: undefined,
}