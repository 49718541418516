import { CarCluster, CaseParticipant, FaceCluster } from '@/api';
import { viewModelRepository } from '@/api/common';

export interface EnrichedParticipant extends CaseParticipant {
  faceClusterObject?: FaceCluster | null | undefined;
  carClusterObject?: CarCluster | null | undefined;
}

export default async function enrichParticipants(
  participants: CaseParticipant[],
  oldEnrichedParticipants: EnrichedParticipant[] = []
): Promise<EnrichedParticipant[]> {
  let existsFaceClustersMap: Record<number, FaceCluster> = {};
  let existsCarClustersMap: Record<number, CarCluster> = {};
  oldEnrichedParticipants.forEach((item) => {
    if (item.faceClusterObject) existsFaceClustersMap[item.faceClusterObject.id] = item.faceClusterObject;
    if (item.carClusterObject) existsCarClustersMap[item.carClusterObject.id] = item.carClusterObject;
  });

  let faceClusterIds = participants.map((participant) => participant.face_cluster).filter((v) => v && !existsFaceClustersMap[v]);
  faceClusterIds = [...new Set(faceClusterIds)];
  const faceClusterModule = viewModelRepository.getClustersFacesListViewModel();
  if (faceClusterIds.length) {
    faceClusterModule.filter.current.id_in = faceClusterIds as number[];
    faceClusterModule.filter.current.limit = '1000';
    await faceClusterModule.get();
  }

  let carClusterIds = participants.map((participant) => participant.car_cluster).filter((v) => v && !existsCarClustersMap[v]);
  carClusterIds = [...new Set(carClusterIds)];
  const carClusterModule = viewModelRepository.getClustersCarsListViewModel();
  if (carClusterIds.length) {
    carClusterModule.filter.current.id_in = faceClusterIds as number[];
    carClusterModule.filter.current.limit = '1000';
    await carClusterModule.get();
  }

  return participants.map((participant) => {
    const faceCluster = participant.face_cluster
      ? existsFaceClustersMap[participant.face_cluster] || faceClusterModule.items.find((item) => item.id === participant.face_cluster)
      : null;
    const carCluster = participant.car_cluster
      ? existsCarClustersMap[participant.car_cluster] || carClusterModule.items.find((item) => item.id === participant.car_cluster)
      : null;

    return {
      ...participant,
      faceClusterObject: faceCluster,
      carClusterObject: carCluster
    };
  });
}
