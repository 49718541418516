/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FaceEventsFeaturesEnum {
    GENDER = 'gender',
    AGE = 'age',
    EMOTIONS = 'emotions',
    BEARD = 'beard',
    GLASSES = 'glasses',
    LOOK = 'look',
    MEDMASK = 'medmask',
}