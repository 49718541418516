import { DirectiveBinding } from 'vue';

class DragHelper {
  el!: HTMLElement;
  isDown = false;
  startX = 0;
  startY = 0;
  startTop = 0;
  startLeft = 0;
  onMouseDown: (e: MouseEvent) => void;
  onMouseMove: (e: MouseEvent) => void;
  onMouseUp: (e: MouseEvent) => void;
  constructor(el: HTMLElement) {
    this.el = el;
    this.onMouseDown = this.mouseDown.bind(this);
    this.onMouseMove = this.mouseMove.bind(this);
    this.onMouseUp = this.mouseUp.bind(this);
  }
  mouseDown(e: MouseEvent) {
    this.isDown = true;
    this.startX = e.clientX;
    this.startY = e.clientY;
    const style = getComputedStyle(this.el);
    this.startTop = Number(style.top.replace('px', ''));
    this.startLeft = Number(style.left.replace('px', ''));
  }
  mouseMove(e: MouseEvent) {
    if (this.isDown) {
      const top = this.startTop + (e.clientY - this.startY);
      const left = this.startLeft + (e.clientX - this.startX);
      this.el.style.top = top + 'px';
      this.el.style.left = left + 'px';
    }
  }
  mouseUp(e: MouseEvent) {
    this.isDown = false;
  }
}

interface ElementType extends HTMLElement {
  dragHelper: DragHelper;
}

export default {
  mounted(el: ElementType, binding: DirectiveBinding) {
    const dragHelper = new DragHelper(el);
    el.dragHelper = dragHelper;
    el.addEventListener('mousedown', dragHelper.onMouseDown);
    window.addEventListener('mousemove', dragHelper.onMouseMove);
    window.addEventListener('mouseup', dragHelper.onMouseUp);
  },
  unmounted(el: ElementType) {
    const dragHelper = el.dragHelper;
    el.removeEventListener('mousedown', dragHelper.onMouseDown);
    window.removeEventListener('mousemove', dragHelper.onMouseMove);
    window.removeEventListener('mouseup', dragHelper.onMouseUp);
  }
};
