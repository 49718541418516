
import { Prop } from 'vue-property-decorator';
import { NHint, NIcon } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import { ConfidenceDisplayItem, DirectionEnum, TypeEnum, AlignEnum } from './confidence-types';
import { configModule } from '@/store/config';
import { languageModule } from '@/store/languages';
import { debounce } from 'lodash';
import { VisibleMode } from '@/uikit/hint/enums';

@Options({
  name: 'Confidence',
  components: { NHint, NIcon }
})
export default class Confidence extends Vue {
  @Prop({ type: String, default: 'faces' })
  readonly objects!: string;

  @Prop({ required: true, type: Number, default: 0 })
  readonly value!: number;

  @Prop({ type: String, default: DirectionEnum.Horizontal })
  readonly direction!: DirectionEnum;

  @Prop({ type: String, default: TypeEnum.Asterisk })
  readonly type!: TypeEnum;

  @Prop({ type: Boolean, default: true })
  readonly hoverEffect!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasLabel!: boolean;

  @Prop({ type: String, default: AlignEnum.Right })
  readonly align!: AlignEnum;

  hoverDelay = 150;

  hovered = false;

  get mode() {
    return this.hasLabel ? VisibleMode.Auto : VisibleMode.Manual;
  }

  get label() {
    const labelLocales = this.item?.label || { en: 'not set' };
    return labelLocales[languageModule.locale] || labelLocales.en;
  }

  get items(): ConfidenceDisplayItem[] {
    return (configModule.config.objects[this.objects]?.confidence_display as unknown as ConfidenceDisplayItem[]) ?? [];
  }

  get asterisksNumber() {
    return this.computedConfidenceDisplay?.asterisksNumber ?? 0;
  }

  get style() {
    const computedConfidenceDisplayColor = this.computedConfidenceDisplay.color;
    return { borderColor: computedConfidenceDisplayColor, color: computedConfidenceDisplayColor };
  }

  get confidenceClasses(): Record<string, boolean> {
    return {
      confidence: true,
      confidence_horizontal: this.direction === DirectionEnum.Horizontal,
      confidence_vertical: this.direction === DirectionEnum.Vertical,
      'label-xs': true
    };
  }

  get isNumber() {
    return this.type === TypeEnum.Number || this.hovered;
  }

  get wrapperClasses(): Record<string, boolean> {
    return {
      confidence__wrapper: true,
      confidence__wrapper_right: this.align === AlignEnum.Right,
      confidence__wrapper_left: this.align === AlignEnum.Left,
      confidence__wrapper_center: this.align === AlignEnum.Center
    };
  }

  get itemIndex(): number {
    const reversedItems = [...this.items].reverse();
    const reverseIndex = reversedItems.findIndex((v) => this.value > v.confidence);
    return reverseIndex > -1 ? this.items.length - 1 - reverseIndex : -1;
  }

  get item(): ConfidenceDisplayItem | null {
    return this.itemIndex > -1 ? this.items[this.itemIndex] : null;
  }

  get confidenceDisplayArray(): ConfidenceDisplayItem[] {
    return configModule.config.objects[this.objects].confidence_display;
  }

  get defaultComputedConfidenceDisplay(): ConfidenceDisplayItem {
    let item = this.confidenceDisplayArray[this.confidenceDisplayArray.length - 1];
    item.asterisksNumber = this.confidenceDisplayArray.length;
    return item;
  }

  get computedConfidenceDisplay(): ConfidenceDisplayItem {
    return (
      this.confidenceDisplayArray.find((item: any, index: number) => {
        if (this.value <= item.confidence) {
          item.asterisksNumber = index + 1;
          return true;
        }
      }) ?? this.defaultComputedConfidenceDisplay
    );
  }

  mouseEnterHandlerWithDebounce() {
    if (this.hoverEffect) {
      const debouncedMouseEnterHandler = debounce(this.mouseEnterHandler, this.hoverDelay);
      debouncedMouseEnterHandler();
    }
  }

  mouseLeaveHandlerWithDebounce() {
    if (this.hoverEffect) {
      const debouncedMouseLeaveHandler = debounce(this.mouseLeaveHandler, this.hoverDelay);
      debouncedMouseLeaveHandler();
    }
  }

  mouseEnterHandler() {
    this.hovered = true;
  }

  mouseLeaveHandler() {
    this.hovered = false;
  }
}
