import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "permissions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_NTable = _resolveComponent("NTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.content)
      ? (_openBlock(), _createBlock(_component_HeaderBar, {
          key: 0,
          total: _ctx.content.length,
          "current-count": _ctx.permissions.length,
          "search-text": _ctx.searchText,
          "onUpdate:search-text": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event))
        }, null, 8, ["total", "current-count", "search-text"]))
      : _createCommentVNode("", true),
    _createVNode(_component_NTable, {
      columns: _ctx.columns,
      header: _ctx.header,
      content: _ctx.permissions,
      onAction: _ctx.actionHandler,
      disabled: _ctx.disabled
    }, null, 8, ["columns", "header", "content", "onAction", "disabled"])
  ]))
}