import * as filters from '@/common/filters';
import { PuppeteerRemoteMonitoringEventsFilter } from '@/api/models/PuppeteerRemoteMonitoringEventsFilter';
import { RemoteMonitoringEvent } from '@/api/models/RemoteMonitoringEvent';

export function satisfyPuppeteerRemoteMonitoringEvent(currentFilter: PuppeteerRemoteMonitoringEventsFilter, event: RemoteMonitoringEvent): boolean {
  let satisfy = true;

  if (satisfy && currentFilter.card) {
    satisfy = event.card === currentFilter.card;
  }

  if (satisfy && currentFilter.created_date_gte) {
    let isoStringToDate = filters.isoStringToDate,
      filterGTEDate = filters.isoStringToDate(currentFilter.created_date_gte),
      eventDate = filters.isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && currentFilter.created_date_lte) {
    let isoStringToDate = filters.isoStringToDate,
      filterLteDate = isoStringToDate(currentFilter.created_date_lte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() <= filterLteDate.getTime();
  }

  return satisfy;
}
