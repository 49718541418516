
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NSelect from '@/uikit/select/NSelect.vue';

@Options({
  name: 'FilterReturnIntervalSelect',
  components: { NSelect },
  emits: []
})
export default class FilterReturnIntervalSelect extends Vue {
  @Prop({ type: [String, Number], required: true })
  readonly modelValue!: string | number;

  @Prop({ type: Array, required: true })
  readonly items!: any[];

  @Prop({ type: String })
  readonly edgeValue?: string;

  @Prop({ type: String })
  readonly label?: string;

  get availableItems() {
    const edgePrecisionIndex = this.items.findIndex((v) => v.value === this.edgeValue);
    const returnIntervalItemsAvailable = edgePrecisionIndex === -1 ? this.items : this.items.slice(0, edgePrecisionIndex);
    return returnIntervalItemsAvailable;
  }

  get model() {
    return this.modelValue;
  }

  set model(value) {
    this.$emit('update:modelValue', value);
  }

  @Watch('edgeValue')
  setMaxAvailableValueOnOutOfRange() {
    const currentValueIndex = this.items.findIndex((v) => v.value === this.modelValue);
    if (currentValueIndex > this.availableItems.length - 1) {
      const maxValue = this.availableItems[this.availableItems.length - 1].value;
      this.$emit('update:modelValue', maxValue);
    }
  }
}
