import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.modelAcl.update)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 0,
          class: "external-search-request-action",
          type: "outlined",
          icon: "table-action-refresh",
          disabled: _ctx.item.is_deleted,
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
          onAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action', 'refresh')))
        }, null, 8, ["disabled"]))
      : _createCommentVNode("", true),
    (_ctx.modelAcl.update)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 1,
          class: "external-search-request-action",
          type: "outlined",
          icon: "table-action-edit",
          disabled: _ctx.item.is_deleted,
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
          onAction: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('action', 'edit')))
        }, null, 8, ["disabled"]))
      : _createCommentVNode("", true),
    (_ctx.modelAcl.delete)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 2,
          class: "external-search-request-action",
          type: "outlined",
          icon: "table-action-trash",
          disabled: _ctx.item.is_deleted,
          onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
          onAction: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('action', 'delete')))
        }, null, 8, ["disabled"]))
      : _createCommentVNode("", true)
  ]))
}