
import { Report } from '@/api';
import { defineComponent, PropType } from 'vue';
import { ReportsPageTableColumnLabel } from './common';

export default defineComponent({
  name: 'ReportsPageTableColumnName',
  components: { ReportsPageTableColumnLabel },
  props: {
    name: {
      required: true,
      type: String as PropType<Report['name']>
    }
  }
});
