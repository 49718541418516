import { getCurrentInstance } from 'vue';
import { NConfirm } from '@/uikit';
import { render } from 'vue';

export type ConfirmOptions = {
  size?: string; /* NModalWindowSize */
  title?: string;
  text: string;
  confirmLabel?: string;
  cancelLabel?: string;
};

const DefaultOptions: Partial<ConfirmOptions> = {
  size: 'medium',
  confirmLabel: 'Yes',
  cancelLabel: 'Cancel'
};

type ResultFunction = (value: boolean) => void;

export class ConfirmDialogFactory {
  protected getContainer(): HTMLElement {
    return document.body.appendChild(document.createElement('div'));
  }

  protected getConfirmComponent(options: ConfirmOptions, resultHandler: ResultFunction): JSX.Element {
    const props = {
      ...Object.assign(DefaultOptions, options),
      onClose: () => resultHandler(false),
      onConfirm: () => resultHandler(true)
    };
    const component = <NConfirm {...props}></NConfirm>;
    component.appContext = getCurrentInstance()?.appContext ?? null;
    return component;
  }

  create(options: ConfirmOptions): Promise<boolean> {
    const result = new Promise<boolean>((resolve, reject) => {
      const container = this.getContainer();
      const component = this.getConfirmComponent(options, resultHandler);
      render(component, container);

      function resultHandler(value: boolean): void {
        resolve(value);
        render(null, document.body.removeChild(container));
      }
    });
    return result;
  }
}

export const confirmDialogFactory = new ConfirmDialogFactory();
