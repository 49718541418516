/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { AuthSessionStatusEnum } from './AuthSessionStatusEnum';
import { str_int_float_bool_object_array_null } from './str_int_float_bool_object_array_null';
export type AuthSession = {
    id: string;
    /**
     * Session status
     */
    readonly status: AuthSessionStatusEnum;
    /**
     * Token is active
     */
    readonly active: boolean;
    /**
     * Device unique identifier
     */
    readonly uuid: string;
    /**
     * Device is mobile
     */
    readonly mobile: boolean;
    device_info: Record<string, str_int_float_bool_object_array_null>;
    /**
     * Device ip address
     */
    readonly ip: string;
    /**
     * Device last ping date
     */
    readonly last_ping_date: string;
    readonly user__username: string;
    created_date: string;
    readonly user_id: number;
};
export const EmptyAuthSession: AuthSession = {
    id: '',
    /**
     * Session status
     */
    status: AuthSessionStatusEnum.ONLINE,
    /**
     * Token is active
     */
    active: false,
    /**
     * Device unique identifier
     */
    uuid: '',
    /**
     * Device is mobile
     */
    mobile: false,
    device_info: {},
    /**
     * Device ip address
     */
    ip: '',
    /**
     * Device last ping date
     */
    last_ping_date: '',
    user__username: '',
    created_date: '',
    user_id: 0,
};
