
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { EnrichedSearchResult } from '@/pages/external-search/results/enrich-search-results';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';

@Options({
  name: 'ExternalSearchResultInfo',
  components: { NDateTimeLabel }
})
export default class ExternalSearchResultInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: EnrichedSearchResult;
}
