import { transform, isEqual, isObject, isEmpty } from 'lodash';

export function differenceOf(v: any, b: any): Record<string, any> {
  return transform(v, function getDiff(result: any, value: any, key: string) {
    const isEqualValues = isEqual(value, b[key]);
    const isEmptyValues = isAdvancedEmpty(value) && isAdvancedEmpty(b[key]);
    if (!(isEqualValues || isEmptyValues)) {
      result[key] = isObject(value) && isObject(b[key]) && !(Array.isArray(value) || Array.isArray(b[key])) ? differenceOf(value, b[key]) : value;
    }
  });
}

function isAdvancedEmpty(value: any): boolean {
  if (isObject(value)) return isEmpty(value);
  else return !value;
}
