import { onBeforeUnmount } from 'vue';

const defaultColor = '#cccccc';
const defaultColorBox = '#ff0000';
const regRegExp = /^(rgb|RGB)/;
const rgbaRegExp = /(?:rgba|rgb|RGBA|RGB|\(|\))*/g;
const hexRegExp = /^#?([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;

export function useColorPickerOnClickOutsideListener(listener: () => void) {
  document.addEventListener('click', listener);

  onBeforeUnmount(() => document.removeEventListener('click', listener));
}

const extractColorsFromRgb = (rgb: string): string[] => rgb.replace(rgbaRegExp, '').split(',');

const displayedRgb = (color: string) => {
  let [r, g, b] = extractColorsFromRgb(hexToRgb(color));

  return `rgb(${r}, ${g}, ${b})`;
};

const hexToRgb = (hex: string) => {
  let color = hex.toLowerCase();

  if (!hexRegExp.test(color)) return color;

  if (color.charAt(0) !== '#') color = `#${color}`;

  if (color.length === 4) {
    let colorNew = '#';
    for (let i = 1; i < color.length; i += 1) {
      colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
    }
    color = colorNew;
  }

  const colorChange: number[] = [];
  for (let i = 1; i < color.length; i += 2) {
    colorChange.push(parseInt('0x' + color.slice(i, i + 2)));
  }
  return 'rgb(' + colorChange.join(',') + ')';
};

const rgbToHex = (color: string) => {
  if (!regRegExp.test(color)) return color;

  const colorArr = color.replace(rgbaRegExp, '').split(',');
  const alpha = ((colorArr && colorArr[3]) || '').trim();
  let hex = '#' + ((1 << 24) + (parseInt(colorArr[0]) << 16) + (parseInt(colorArr[1]) << 8) + parseInt(colorArr[2])).toString(16).slice(1);

  if (alpha != '' && alpha != '1') {
    const a = ((+alpha * 255) | (1 << 8)).toString(16).slice(1);
    hex = hex + a;
  }
  return hex;
};

export { defaultColor, defaultColorBox, regRegExp, rgbaRegExp, hexRegExp, displayedRgb, hexToRgb, rgbToHex };
