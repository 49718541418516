import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-upload-header"
}
const _hoisted_2 = { class: "label-xs n-upload__droparea-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NAttachmentsAmount = _resolveComponent("NAttachmentsAmount")!
  const _component_NAttachmentsPicker = _resolveComponent("NAttachmentsPicker")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NAttachmentsDropArea = _resolveComponent("NAttachmentsDropArea")!
  const _component_NAttachmentsListItem = _resolveComponent("NAttachmentsListItem")!
  const _component_NAttachmentsList = _resolveComponent("NAttachmentsList")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["n-upload", { disabled: _ctx.disabled }])
  }, [
    (_ctx.isHeaderVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.hideAmount)
            ? (_openBlock(), _createBlock(_component_NAttachmentsAmount, {
                key: 0,
                amount: _ctx.attachments.length,
                i18n: _ctx.computedI18n
              }, null, 8, ["amount", "i18n"]))
            : _createCommentVNode("", true),
          (!_ctx.disallowAttach)
            ? (_openBlock(), _createBlock(_component_NAttachmentsPicker, {
                key: 1,
                amount: _ctx.attachments.length,
                i18n: _ctx.computedI18n,
                onAttach: _ctx.attachFromFs
              }, null, 8, ["amount", "i18n", "onAttach"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.drag)
      ? (_openBlock(), _createBlock(_component_NAttachmentsDropArea, {
          key: 1,
          onAttach: _ctx.attachFromFs,
          onDrop: _ctx.handleAttachEvent
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "droparea", { attachFromFs: _ctx.attachFromFs }, () => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.computedI18n.droparea_label), 1),
              _createVNode(_component_NButton, { type: "link" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.computedI18n.droparea_link_label), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 3
        }, 8, ["onAttach", "onDrop"]))
      : _createCommentVNode("", true),
    (_ctx.isAttachmentsListVisible)
      ? (_openBlock(), _createBlock(_component_NAttachmentsList, {
          key: 2,
          attachments: _ctx.attachments
        }, {
          attachment: _withCtx(({ attachment }) => [
            _createVNode(_component_NAttachmentsListItem, {
              "ask-before-remove": _ctx.askBeforeRemove,
              attachment: attachment,
              "disallow-remove": _ctx.disallowRemove,
              i18n: _ctx.computedI18n,
              onRemove: _ctx.handleRemoveEvent
            }, null, 8, ["ask-before-remove", "attachment", "disallow-remove", "i18n", "onRemove"])
          ]),
          _: 1
        }, 8, ["attachments"]))
      : _createCommentVNode("", true)
  ], 2))
}