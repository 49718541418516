import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createBlock(_component_NHint, {
    placement: "top",
    content: _ctx.$t(_ctx.error),
    "visible-mode": "manual",
    "is-visible": !!_ctx.error
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NButton, {
        type: "link",
        onClick: _ctx.login
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.login_with_ad')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["content", "is-visible"]))
}