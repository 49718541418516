import { ReportSizeInBytes } from '../../../types';
import { ReportFormattedModifiedDate, ReportFormattedSize } from './types';

const BYTES_IN_KB = 1024;
const BYTES_IN_MB = BYTES_IN_KB ** 2;
const BYTES_IN_GB = BYTES_IN_KB ** 3;
const DEFAULT_PRECISION = 2;

export function formatReportSize(size: ReportSizeInBytes, precision = DEFAULT_PRECISION): ReportFormattedSize {
  if (size > BYTES_IN_GB) {
    return { size: (size / BYTES_IN_GB).toFixed(precision), measure: 'gb' };
  } else if (size > BYTES_IN_MB) {
    return { size: (size / BYTES_IN_MB).toFixed(precision), measure: 'mb' };
  } else {
    return { size: (size / BYTES_IN_KB).toFixed(precision), measure: 'kb' };
  }
}

export function formatReportModifiedDate(modifiedDate: Date): ReportFormattedModifiedDate {
  return `${formatReportModifiedDay(modifiedDate)} ${formatReportModifiedTime(modifiedDate)}`;
}

const pad = (part: number) => String(part).padStart(2, '0');

function formatReportModifiedDay(date: Date): string {
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

function formatReportModifiedTime(date: Date): string {
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  return `${hours}:${minutes}:${seconds}`;
}
