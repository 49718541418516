import { AuthModule } from '@/store/auth';
import { AxiosError, AxiosInstance } from 'axios';
import { get } from 'lodash';
import { reactive } from 'vue';
import { Router } from 'vue-router';
import { ExceptionsModule } from '../exceptions';
import { isNonEmptyString } from '../exceptions/utils';
import { AuthGuardModuleDeps, AxiosErrorHandlingRule, AxiosErrorHandlingRuleList } from './types';
import { HttpCodes } from '@/store/application/http.codes';

const LicencePagePath = 'ntls/usage-report.json';
const LoginPagePath = '/login';
const ExternalVmsPagePath = '/external-vms';
const OnvifStartStreamingPath = '/start-streaming';
const VideoAuthPath = '/video-auth';
const StreamScreenshotPath = '/stream-screenshot';

const ImportantRequestMethods = ['post', 'delete'];
const ServerErrorIgnoredPaths = [LicencePagePath, StreamScreenshotPath, OnvifStartStreamingPath];
const ServerLicenseErrorIgnoredPaths = ['/lines'];

const isNotificationAllowed = createAxiosErrorHandlingRuleMatcher([
  {
    path: 'response',
    predicate: (value) => value === undefined
  },
  [
    {
      path: 'response.status',
      predicate: (value) => Number(value) >= HttpCodes.ServerError
    },
    {
      path: 'config.url',
      predicate: (value) => !ServerErrorIgnoredPaths.find((v) => (value as string).includes(v))
    }
  ],
  [
    { path: 'response.status', predicate: (value) => value === HttpCodes.Forbidden },
    { path: 'response.data.code', predicate: (value) => value === 'LICENSE_ERROR' },
    { path: 'config.url', predicate: (value) => !ServerLicenseErrorIgnoredPaths.find((path) => (value as string).includes(path)) }
  ],
  { path: 'response.status', predicate: (value) => value === HttpCodes.Conflict },
  { path: 'response.status', predicate: (value) => value === HttpCodes.BadParam },
  [
    { path: 'response.status', predicate: (value) => value !== HttpCodes.Unauthorized },
    { path: 'config.method', predicate: (value) => ImportantRequestMethods.includes((value as string).toLowerCase()) },
    { path: 'config.url', predicate: (value) => exceptionForImportantRequestMethods(value as string) }
  ]
]);

const exceptionForImportantRequestMethods = (value: string) => {
  return !value.startsWith(ExternalVmsPagePath) && !value.includes(OnvifStartStreamingPath);
};

const isLogoutForbidden = createAxiosErrorHandlingRuleMatcher([
  [
    { path: 'response.status', predicate: (value) => value === HttpCodes.Unauthorized },
    { path: 'response.data.code', predicate: (value) => value === 'ONVIF_UNAUTHORIZED' }
  ],
  [
    { path: 'response.status', predicate: (value) => value === HttpCodes.Unauthorized },
    { path: 'response.data.code', predicate: (value) => (value as string).includes(VideoAuthPath) }
  ]
]);

export class AuthGuardModule {
  private auth: AuthModule | null;
  private readonly exceptions: ExceptionsModule;
  private router: Router | null;

  static create(dependencies: AuthGuardModuleDeps) {
    return reactive(new this(dependencies)) as AuthGuardModule;
  }

  private constructor(dependencies: AuthGuardModuleDeps) {
    this.auth = dependencies.auth;
    this.exceptions = dependencies.exceptions;
    this.router = dependencies.router;
  }

  useAuthModule(module: AuthModule): void {
    this.auth = module;
  }

  useRouter(router: Router): void {
    this.router = router;
  }

  inject(axios: AxiosInstance): void {
    axios.interceptors.response.use(undefined, async (error: AxiosError) => {
      isNotificationAllowed(error) && (await this.handleThrownResponse(error));
      throw error;
    });
  }

  private async handleThrownResponse(error: AxiosError) {
    if (isLogoutForbidden(error)) {
      isNonEmptyString(this.auth?.token) && this.auth?.reset();
      await this.router?.replace(LoginPagePath);
    } else {
      this.exceptions.notifyThrownException(error);
    }
  }
}

function createAxiosErrorHandlingRuleMatcher(rules: AxiosErrorHandlingRuleList): (error: AxiosError) => boolean {
  return (error) => rules.some((rules) => (Array.isArray(rules) ? rules.every((rule) => match(error, rule)) : match(error, rules)));

  function match(error: AxiosError<unknown>, rule: AxiosErrorHandlingRule) {
    return rule.predicate(get(error, rule.path), error);
  }
}
