import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "heading-xl" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "license-agreement_footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NCheckbox = _resolveComponent("NCheckbox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    layout: true,
    size: _ctx.size,
    "insert-into-body": true,
    "close-disabled": true
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('common.license_agreement', 'f')), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", {
        class: "license-agreement label-m",
        innerHTML: _ctx.toHtml(_ctx.$t('texts.license_agreement'))
      }, null, 8, _hoisted_2)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NCheckbox, {
          modelValue: _ctx.licenseAccept,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.licenseAccept) = $event)),
          class: "label-m"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.i_accept_license_agreement')), 1)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_NButton, {
          onClick: _ctx.proceedHandler,
          disabled: !_ctx.licenseAccept
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.proceed', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ])
    ]),
    _: 1
  }, 8, ["size"]))
}