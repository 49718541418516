import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-small-data-time" }
const _hoisted_2 = { class: "filter-small-data-time__label control-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDateInput = _resolveComponent("NDateInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_NDateInput, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      size: _ctx.size,
      clearable: true,
      "emit-type": _ctx.emitType,
      "emit-string-format": _ctx.emitStringFormat,
      "time-enabled": _ctx.timeEnabled,
      plain: true,
      "date-placeholder": _ctx.placeholders.datePlaceholder,
      "time-placeholder": _ctx.placeholders.timePlaceholder
    }, null, 8, ["modelValue", "size", "emit-type", "emit-string-format", "time-enabled", "date-placeholder", "time-placeholder"])
  ]))
}