export type ConfidenceDisplayItem = {
  label: Record<string, string>;
  confidence: number;
  color: string;
  asterisksNumber?: number;
};

export enum DirectionEnum {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum TypeEnum {
  Number = 'number',
  Asterisk = 'asterisk'
}

export enum AlignEnum {
  Right = 'right',
  Left = 'left',
  Center = 'center'
}
