/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { GroupGuid } from './GroupGuid';
export type Group = {
    readonly id: number;
    name: string;
    permissions?: Array<string>;
    ad_group?: GroupGuid;
};
export const EmptyGroup: Group = {
    id: 0,
    name: '',
    permissions: [],
    ad_group: undefined,
};
