import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createBlock(_component_NButton, {
    onAction: _ctx.handleClick,
    label: _ctx.name,
    type: "link"
  }, null, 8, ["onAction", "label"]))
}