import { ref } from 'vue';
import { configModule } from '@/store/config';

export default class VideoPlayerController {
  model = 'cameras';
  cameraId = 0;
  timeFrom = 0;
  showControls = true;
  showRewinds = true;
  showTimeline = true;
  showBottomBar = true;
  visible = ref(false);

  resetParams() {
    this.showControls = true;
    this.showRewinds = configModule.features.vms_enabled;
    this.showTimeline = configModule.features.vms_enabled;
    this.showBottomBar = configModule.features.vms_enabled;
  }

  playCamera(cameraId: number) {
    this.resetParams();
    this.model = 'cameras';
    this.cameraId = cameraId;
    this.timeFrom = new Date().getTime() / 1000 + 10;
    this.visible.value = true;
  }

  playVideo(videoId: number) {
    this.showControls = false;
    this.showTimeline = false;
    this.showBottomBar = false;
    this.model = 'videos';
    this.cameraId = videoId;
    this.timeFrom = 0;
    this.visible.value = true;
  }

  playArchive(cameraId: number, timeFrom: number) {
    this.resetParams();
    this.model = 'cameras';
    this.cameraId = cameraId;
    this.timeFrom = timeFrom;
    this.visible.value = true;
  }
}
