import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "settings-page__controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_VMSCleanupForm = _resolveComponent("VMSCleanupForm")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      (_ctx.hasViewAcl)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_NTabs, {
              items: _ctx.tabs,
              modelValue: _ctx.tabName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tabName) = $event))
            }, null, 8, ["items", "modelValue"]),
            (_ctx.tabName !== _ctx.options.VMSSettings)
              ? (_openBlock(), _createBlock(_component_NForm, {
                  key: 0,
                  class: "settings-page__form",
                  ref: "form",
                  layout: _ctx.formLayout,
                  model: _ctx.newModel,
                  disabled: !_ctx.hasUpdateAcl
                }, null, 8, ["layout", "model", "disabled"]))
              : (_openBlock(), _createBlock(_component_VMSCleanupForm, {
                  key: 1,
                  "model-value": _ctx.newModel.vms_cleanup_settings,
                  type: "settings"
                }, null, 8, ["model-value"])),
            _createElementVNode("div", _hoisted_1, [
              (_ctx.hasChanges)
                ? (_openBlock(), _createBlock(_component_NButton, {
                    key: 0,
                    onClick: _ctx.update
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('settings.update', 'f')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}