import { GlobalEvent } from '@/store/global-event/types';
import { GlobalEventName } from '@/store/global-event';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { DataState } from '@/store/data/index';

export function handleGlobalEventForDataModule(dataModule: DataState, event: GlobalEvent | null) {
  if (!event) return;
  reloadCurrentUser(dataModule, event);
  reloadWatchLists(dataModule, event);
  reloadCameraGroups(dataModule, event);
  reloadCameras(dataModule, event);
  reloadVideos(dataModule, event);
  reloadUsers(dataModule, event);
  reloadGroups(dataModule, event);

  switch (event.type) {
    case 'lines':
      dataModule.linesModule.get();
      break;
  }
}

function reloadCurrentUser(dataModule: any, event: GlobalEvent) {
  if (
    (event.name === GlobalEventName.Create || event.name === GlobalEventName.Update) &&
    (MultisidebarItemTypes.CameraGroups === event.type || MultisidebarItemTypes.WatchLists === event.type)
  )
    dataModule.loadCurrentUserModule();
}

function reloadWatchLists(dataModule: any, event: GlobalEvent) {
  if (isDeleteOrCreate(event) && MultisidebarItemTypes.WatchLists === event.type) dataModule.loadWatchLists();
  else if (isUpdate(event) && MultisidebarItemTypes.WatchLists === event.type) dataModule.updateWatchList(event.payload);
}

function reloadCameraGroups(dataModule: any, event: GlobalEvent) {
  if (isDeleteOrCreate(event) && MultisidebarItemTypes.CameraGroups === event.type) dataModule.loadCameraGroups();
  else if (isUpdate(event) && MultisidebarItemTypes.CameraGroups === event.type) dataModule.updateCameraGroup(event.payload);
}

function reloadCameras(dataModule: any, event: GlobalEvent) {
  if (isDeleteOrCreate(event) && MultisidebarItemTypes.Cameras === event.type) dataModule.loadCameras();
  else if (isUpdate(event) && MultisidebarItemTypes.Cameras === event.type) dataModule.updateCamera(event.payload);
}

function reloadVideos(dataModule: any, event: GlobalEvent) {
  if (isDeleteOrCreate(event) && MultisidebarItemTypes.Videos === event.type) dataModule.loadVideos();
  else if (isUpdate(event) && MultisidebarItemTypes.Videos === event.type) dataModule.updateVideo(event.payload);
}

function reloadGroups(dataModule: any, event: GlobalEvent) {
  if (isDeleteOrCreate(event) && MultisidebarItemTypes.Groups === event.type) dataModule.loadGroups();
}

function reloadUsers(dataModule: any, event: GlobalEvent) {
  if (isDeleteOrCreate(event) && MultisidebarItemTypes.WatchLists === event.type) dataModule.loadWatchLists();
}

function isDeleteOrCreate(event: GlobalEvent) {
  return event.name === GlobalEventName.Create || event.name === GlobalEventName.Delete;
}

function isUpdate(event: GlobalEvent) {
  return event.name === GlobalEventName.Update;
}
