import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createBlock(_component_NButton, {
    type: "secondary",
    onClick: _ctx.dispatchClickEvent
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('license.download_c2v')), 1)
    ]),
    _: 1
  }, 8, ["onClick"]))
}