import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { SimpleFilterOptions } from '@/components/common/filter/filters/types';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';

export function getParticipantFilters(options: SimpleFilterOptions): IFormLayoutItem[] {
  return [
    commonFilterSchemaModule.getCaseRole(options),
    commonFilterSchemaModule.getCaseNameContains(options),
    commonFilterSchemaModule.getCaseDateTime(options)
  ].filter((v) => !!v) as any;
}

export function getParticipantSearchFilters(options: SimpleFilterOptions): IFormLayoutItem[] {
  return [
    commonFilterSchemaModule.getCaseRole(options),
    commonFilterSchemaModule.getCaseNameContains(options),
    commonFilterSchemaModule.getCaseIn(options),
    commonFilterSchemaModule.getCaseDateTime(options)
  ].filter((v) => !!v) as any;
}
