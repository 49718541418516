
import WatchListItem from '@/components/common/WatchListItem.vue';
import { EnrichedParticipant } from '@/components/participants/enrich-participants';
import { CaseRoleItems } from '@/components/participants/sidebar/ParticipantRole.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Case, CasesService } from '@/api';
import CaseDescription from '@/pages/cases/CaseDescription.vue';

@Options({
  name: 'ParticipantItem',
  components: { CaseDescription, WatchListItem, NThumbnail }
})
export default class ParticipantItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: EnrichedParticipant;

  @Prop({ type: Boolean, required: false })
  readonly showFullframe: boolean = false;

  @Prop({ type: Boolean, required: false })
  readonly showCase: boolean = false;

  caseItem: Case | null = null;

  async loadCase() {
    try {
      this.caseItem = await CasesService.casesRetrieve(this.item.case);
    } catch (e) {
      console.warn(`[loadCase] error: ${e}`);
    }
  }

  get thumbnail() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.item?.faceClusterObject?.best_event.thumbnail;
  }

  computeName(role: string) {
    return this.$t(`cases.${role}`);
  }

  created() {
    if (this.showCase) this.loadCase();
  }

  computeColor(role: string) {
    return CaseRoleItems.find(({ value }) => value === role)?.color;
  }

  actionHandler(v: string) {
    this.$emit('action', v);
  }

  thumbnailClickHandler() {
    if (this.showFullframe) {
      this.$photoViewer.show(this.item.faceClusterObject?.best_event);
    } else {
      this.$emit('select');
    }
  }
}
