import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "matched-card__empty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createBlock(_component_NHint, {
    content: _ctx.label,
    type: "medium",
    delayVisible: 100
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["not-found-image", _ctx.classes])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_NIcon, {
            name: "file-broken",
            width: "25",
            height: "25"
          })
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["content"]))
}