export default function labelsStepByScale(pixelsToTime: number) {
  let steps = [
    60, // 1 минута
    3 * 60, // 3 минуты
    5 * 60, // 5 минут
    10 * 60, // 10 минут
    30 * 60, // 30 минут
    60 * 60, // 1 час
    3 * 60 * 60, // 3 часа
    6 * 60 * 60, // 6 часов
    12 * 60 * 60, // 12 часов
    24 * 60 * 60 // 24 часа
  ];
  let labelStep = 60;

  steps.forEach((step: number): void => {
    if (pixelsToTime > step / 100) {
      labelStep = step;
    }
  });
  return labelStep;
}
