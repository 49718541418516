import { BboxConfig } from '@/uikit/bbox/types';
import { DetectService } from '@/api';
import { DetectResult } from '@/components/detection/types';
import { convertDetectItemsToBboxConfigs } from '@/components/detection/helpers';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { authModule } from '@/store/auth';
import { configModule } from '@/store/config';

const ConvertableMimeTypes = ['', 'image/heic', 'image/heic-sequence', 'image/heif'];

export default class DetectionModule {
  imageUrl: string = '';
  options?: any;
  bboxes: BboxConfig[] = [];
  convertationInProgress = false;

  constructor(options?: any) {
    this.options = options;
  }

  get computedOptions() {
    return this.options ?? dataAssetsModule.detectFeatures;
  }

  createDataUrl(file: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsDataURL(file);
    });
  }

  async updateImageUrl(image: Blob) {
    this.imageUrl = await this.createDataUrl(image);
  }

  async updateBboxes(image: Blob) {
    if (Object.keys(this.computedOptions).length > 0) {
      let payload = { photo: image, attributes: this.computedOptions };
      let result = (await DetectService.detectCreate(payload as any)) as DetectResult;
      let bboxesByType = Object.entries(result.objects).map(([type, detectItems]) => convertDetectItemsToBboxConfigs(detectItems, type, this.imageUrl));
      this.bboxes = bboxesByType.flat();
    } else {
      console.warn('[detection-module]: You should configure at least one object type to detect!');
    }
  }

  async tryConvert(image: Blob) {
    console.log('[image.type]', image.type, image);
    if (!ConvertableMimeTypes.includes(image.type)) return image;
    this.convertationInProgress = true;

    const imageKey = 'image';
    const formData = new FormData();
    formData.append(imageKey, image);
    formData.append('conversion_meta', JSON.stringify([{ key: imageKey, to: 'jpeg' }]));

    try {
      const url = (configModule.config.server?.url ?? '/') + 'convert/';
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: { Authorization: 'Token ' + encodeURIComponent(authModule.token!.toString()) }
      });

      const responseFormData = await response.formData();
      const convertedImage = responseFormData.get(imageKey);
      return (convertedImage as File) || image;
    } finally {
      this.convertationInProgress = false;
    }
  }

  async detect(image: Blob) {
    await Promise.all([this.updateImageUrl(image), this.updateBboxes(image)]);
  }
}
