
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '../buttons/NButton.vue';

@Options({
  name: 'NCheckboxIcon',
  components: { NButton }
})
export default class NCheckboxIcon extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly disabled?: boolean;

  @Prop({ type: [String, Number], default: '' })
  readonly label?: string | number;

  @Prop({ type: [String, Number, Boolean], default: false })
  readonly modelValue!: boolean;

  @Prop({ type: String, default: 'check' })
  readonly name!: string;

  @Prop({ type: Number, default: 20 })
  readonly size!: number;

  focused = false;

  handleChange() {
    if (this.disabled) return;
    const newValue = !this.modelValue;
    this.$emit('update:modelValue', newValue);
  }
}
