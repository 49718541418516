
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NHint from '@/uikit/hint/NHint.vue';

@Options({
  name: 'ConfidenceDelimiter',
  components: { NHint },
  emits: []
})
export default class ConfidenceDelimiter extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly confidence!: number;

  get confidenceText() {
    return `${this.$t('common.confidence')}: ${this.confidence.toFixed(3)}`;
  }
}
