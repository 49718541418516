
import { Options, Vue } from 'vue-class-component';
import NIcon from '@/uikit/icons/NIcon.vue';
import NHint from '@/uikit/hint/NHint.vue';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'IconWithHint',
  components: { NHint, NIcon }
})
export default class IconWithHint extends Vue {
  @Prop({ type: String, required: true })
  name!: string;

  @Prop({ type: String, required: true })
  content!: string;

  @Prop({ type: String, default: '' })
  maxWidth!: string;
}
