import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "block-list__form-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    layout: true,
    size: _ctx.size,
    "insert-into-body": true,
    label: _ctx.$t('common.edit_blocklist_record', 'f')
  }, {
    content: _withCtx(() => [
      _createVNode(_component_NForm, {
        ref: "itemForm",
        layout: _ctx.formLayout,
        model: _ctx.model,
        class: "block-list__form"
      }, null, 8, ["layout", "model"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_NButton, {
          type: "link",
          onClick: _ctx.closeHandler
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.cancel', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_NButton, { onClick: _ctx.saveHandler }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.save', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["size", "label"]))
}