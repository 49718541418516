/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { str_int_float_bool_object_array_null } from './str_int_float_bool_object_array_null';
export type OnvifCamera = {
    readonly id: number;
    meta: Record<string, str_int_float_bool_object_array_null>;
    /**
     * Onvif Camera creation date
     */
    readonly created_date: string;
    /**
     * Onvif Camera modification date
     */
    readonly modified_date: string;
    endpoint_reference: string;
    need_reboot?: boolean;
    need_auth?: boolean;
    username?: string | null;
    password?: string | null;
    is_discovering?: boolean;
};
export const EmptyOnvifCamera: OnvifCamera = {
    id: 0,
    meta: {},
        /**
        * Onvif Camera creation date
        */
    created_date: '',
        /**
        * Onvif Camera modification date
        */
    modified_date: '',
    endpoint_reference: '',
    need_reboot: false,
    need_auth: false,
    username: '',
    password: '',
    is_discovering: false,
};