import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsBar = _resolveComponent("SettingsBar")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_SettingsBar, {
        createLabelI18N: "common.camera_group_create",
        totalCount: _ctx.module.items.length,
        hasCreate: _ctx.modelAcl.add,
        onSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchQuery = $event)),
        onCreate: _ctx.handleCreate
      }, null, 8, ["totalCount", "hasCreate", "onCreate"]),
      _createVNode(_component_NTable, {
        columns: _ctx.cameraGroupColumns,
        content: _ctx.items,
        "selected-items-ids": _ctx.selectedItemsIds,
        "disabled-items-ids": _ctx.disabledItemIds,
        disabled: !_ctx.modelAcl.update,
        "onSelect:changes": _ctx.handleSelectChanges,
        onAction: _ctx.handleTableAction,
        onSort: _ctx.handleTableSort
      }, null, 8, ["columns", "content", "selected-items-ids", "disabled-items-ids", "disabled", "onSelect:changes", "onAction", "onSort"])
    ]),
    _: 1
  }))
}