
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';
import NTable from '@/uikit/table/NTable.vue';
import { CameraRequest, CamerasService, OnvifCamera } from '@/api';
import NButton from '@/uikit/buttons/NButton.vue';
import { multisidebarModule } from '@/store/multisidebar';
import NForm, { IFormLayout } from '@/uikit/forms/NForm.vue';
import { formLayoutContent, formLayoutHeaders } from './onvif-form-schema';
import { globalEventModule } from '@/store/global-event';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';

@Options({
  name: 'DataSourcesOnvifNext',
  components: { NForm, NButton, NTable, NIcon }
})
export default class DataSourcesOnvifNext extends Vue {
  @Prop({ type: Array, required: true })
  readonly devices!: OnvifCamera[];

  tableContent = this.devices.map((onvifItem: OnvifCamera) => ({
    name: '',
    group: NaN,
    // url: '',
    ip_address: `${onvifItem.meta.host}:${onvifItem.meta.port}`,
    stream_settings: {
      detectors: {}
    },
    metaOnvif: {
      ...onvifItem
    }
  }));

  get sidebar() {
    return multisidebarModule;
  }

  get deviceCount() {
    return this.devices.length;
  }

  get headers(): IFormLayout {
    return formLayoutHeaders;
  }
  get schema(): IFormLayout {
    return formLayoutContent;
  }

  computedTableContent = this.devices.map((onvifItem: OnvifCamera) => ({
    name: '',
    group: NaN,
    // url: '',
    ip_address: `${onvifItem.meta.host}:${onvifItem.meta.port}`,
    onvif_camera: onvifItem.id,
    stream_settings: {
      detectors: {}
    },
    metaOnvif: {
      ...onvifItem
    }
  }));

  updateFormModel(item: any, val: any) {
    this.computedTableContent.forEach((device) => {
      if (device.metaOnvif.id === item.metaOnvif.id) {
        Object.assign(device.metaOnvif, val);
      }
    });
  }

  isValid() {
    const { form } = this.$refs;
    const result = form.filter((item: any) => {
      !item.validate() && item.displayErrors();
      return item.validate();
    });

    return result.length === form.length;
  }

  async createCameras() {
    const requests = this.computedTableContent.map((cameraItem) => {
      // @ts-ignore
      delete cameraItem['metaOnvif'];
      return CamerasService.camerasCreate(cameraItem as CameraRequest);
    });
    const cameras = await Promise.allSettled(requests);
    globalEventModule.sendCreate(MultisidebarItemTypes.Cameras);
    return cameras;
  }

  onlyCreateCameras() {
    if (this.isValid()) {
      this.createCameras().finally(() => {
        this.$emit('close');
      });
    }
  }

  createAndConfig() {
    if (this.isValid()) {
      this.createCameras()
        .then((res) => {
          const cameras = res.map((camera) => camera.status === 'fulfilled' && camera.value);
          this.sidebar.addItemsAndOpen('cameras', cameras);
        })
        .finally(() => {
          this.$emit('close');
        });
    }
  }
}
