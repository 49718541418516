import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NBbox = _resolveComponent("NBbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: "n-image-viewer-bbox-layer",
    style: _normalizeStyle(_ctx.style)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedBboxes, (bbox, i) => {
      return (_openBlock(), _createBlock(_component_NBbox, _mergeProps(bbox, {
        key: i,
        selectable: _ctx.selectable,
        scale: _ctx.scale,
        onSelect: _ctx.handleSelect
      }), null, 16, ["selectable", "scale", "onSelect"]))
    }), 128))
  ], 4))
}