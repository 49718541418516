import { AuthService } from '@/api';
import { generateV4UUID } from '@/common/uuid';
import { localStorageModule } from '@/store/application/local.storage';
import { dataModule } from '@/store/data';
import { reactive, watch } from 'vue';
import { filterManagerModule } from '@/components/common/filter/filter-manager';
import { multisidebarModule } from '@/store/multisidebar';
import { workspaceModule } from '@/store/application/workspace';
import { websocketModule } from '@/store/ws/websocket.module';
import router from '@/router';
import { PagePaths } from "@/store/application/page.definitions";

export class AuthModule {
  static Name = 'AuthModule';
  static StoredItems = ['uuid', 'token', 'token_expiration_datetime'];

  uuid!: string;
  token?: string | null;
  token_expiration_datetime?: string | null;
  login?: string | null;
  password?: string | null;

  get isTokenValid() {
    return !!(this.token && !this.isTokenExpired);
  }

  get isTokenExpired() {
    if (!this.token_expiration_datetime) return false;
    const expirationDateTime = new Date(this.token_expiration_datetime);
    return expirationDateTime < new Date();
  }

  setBasicAuth(login: string, password: string): void {
    this.token = null;
    this.token_expiration_datetime = null;
    this.login = login;
    this.password = password;
  }

  setTokenAuth(token: string, token_expiration_datetime: string): void {
    this.login = null;
    this.password = null;
    this.token = token;
    this.token_expiration_datetime = token_expiration_datetime;
    localStorageModule.syncToStorageByName(AuthModule.Name);
  }

  setTokenAuthExpires(token_expiration_datetime: string) {
    this.token_expiration_datetime = token_expiration_datetime;
    localStorageModule.syncToStorageByName(AuthModule.Name);
  }

  getAuthorizationValue(): string | null {
    let result = null;
    if (this.token) {
      result = `Token ${encodeURIComponent(this.token)}`;
    } else if (this.login && this.password) {
      const encodedData = btoa(unescape(encodeURIComponent(this.login + ':' + this.password)));
      result = `Basic ${encodedData}`;
    }
    return result;
  }

  reset() {
    this.token = null;
    this.token_expiration_datetime = null;
    this.login = null;
    this.password = null;
  }

  async logout() {
    try {
      await AuthService.authLogoutCreate();
    } catch (e) {
      console.warn('[auth.service]:logout error', e);
    }
    dataModule.currentUserModule.setEmptyItemsState();
    filterManagerModule.reset();
    this.reset();
    localStorageModule.syncToStorageByName(AuthModule.Name);
    localStorageModule.setKey(undefined);
    workspaceModule.reset();
    multisidebarModule.resetAndSave();
  }

  handleWebsocketTokenInvalidation(tokenInvalid: boolean) {
    if (tokenInvalid) {
      this.reset();
      router.push(PagePaths.Login);
    }
  }
}

export const authModule = reactive(new AuthModule());

export function initAuthModule() {
  localStorageModule.registerInstance({ instance: authModule, name: AuthModule.Name, tokens: AuthModule.StoredItems });
  localStorageModule.syncFromStorageByName(AuthModule.Name);
  authModule.uuid = authModule.uuid ?? generateV4UUID();
  localStorageModule.syncToStorageByName(AuthModule.Name);
  watch(() => websocketModule.tokenInvalid, authModule.handleWebsocketTokenInvalidation.bind(authModule));
}
