/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { WebhookFilters } from "./WebhookFilters";
/**
 * `WebHook` is an HTTP URL that is used to notify external services on newly created events
 */
export type Webhook = {
    readonly id: number;
    /**
     * Human-readable name for this webhook
     */
    name: string;
    /**
     * Inactive webhooks will not send any requests
     */
    active: boolean;
    /**
     * Receiving URL
     */
    url: string;
    /**
     * Webhook messages batch size
     */
    batch_size?: number;
    filters: WebhookFilters;
    /**
     * Maximum number of attempts to send a webhook
     */
    send_attempts?: number;
    /**
     * Creator ID
     */
    readonly created_by: number;
    /**
     * Webhook creation date
     */
    readonly created_date: string;
    /**
     * Webhook modification date
     */
    readonly modified_date: string;
};
export const EmptyWebhook: Webhook = {
    id: 0,
        /**
        * Human-readable name for this webhook
        */
    name: '',
        /**
        * Inactive webhooks will not send any requests
        */
    active: true,
        /**
        * Receiving URL
        */
    url: '',
        /**
        * Webhook messages batch size
        */
    batch_size: 1,
    filters: {},
        /**
        * Maximum number of attempts to send a webhook
        */
    send_attempts: 10,
        /**
        * Creator ID
        */
    created_by: 0,
        /**
        * Webhook creation date
        */
    created_date: '',
        /**
        * Webhook modification date
        */
    modified_date: '',
};