
import { Report } from '@/api';
import { defineComponent, PropType } from 'vue';
import { ReportsPageTableColumnLabelMono } from './common';

export default defineComponent({
  name: 'ReportsPageTableColumnRecordsNumber',
  components: { ReportsPageTableColumnLabelMono },
  props: {
    recordsNumber: {
      required: true,
      type: Number as PropType<Report['records_number']>
    }
  }
});
