
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import NInputEye from './NInputEye.vue';
import NIcon from '../icons/NIcon.vue';

export enum NInputSize {
  Big = 'big',
  Normal = 'normal'
}

@Options({
  components: { NInputEye, NIcon },
  emits: ['update:modelValue', 'focus', 'blur']
})
export default class NInput extends Vue {
  @Prop({ type: [String, Number], required: false })
  readonly modelValue?: string | number;

  @Prop({ type: String })
  readonly placeholder?: string;

  @Prop({ type: String, default: NInputSize.Normal })
  readonly size!: NInputSize;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly password!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly plain!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly autofocus!: boolean;

  @Prop({ type: String })
  readonly accesskey?: string;

  @Prop({ type: String })
  readonly suffixIcon?: string;

  @Prop({ type: Boolean, default: false })
  readonly clearable!: boolean;

  @Prop({ type: String })
  readonly dataQa?: string;

  @Ref()
  readonly input!: HTMLInputElement;

  private isPasswordVisible = false;

  get type() {
    return this.password && !this.isPasswordVisible ? 'password' : 'text';
  }

  get model() {
    return this.modelValue ?? '';
  }

  set model(value) {
    this.$emit('update:modelValue', value);
  }

  clear() {
    this.$emit('update:modelValue', '');
    this.$emit('change', '');
  }

  get classes() {
    const root = 'n-input';
    return {
      [root]: true,
      [`${root}__input`]: true,
      [`${root}__input_plain`]: this.plain,
      [`${root}__input_suffix`]: !!this.suffixIcon,
      [`${root}__input_postfix`]: this.clearable,
      [`${root}__input_password`]: this.password,
      [`${root}__size_${this.size}`]: this.size
    };
  }

  private dispatchChangeEvent(): void {
    this.$emit('change', this.input.value);
  }

  private eventProxyHandler(e: Event) {
    this.$emit(e.type, e);
  }

  focus(): void {
    this.input.focus();
  }
}
