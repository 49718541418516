
import { defineComponent } from 'vue';
import { arrayOf, number, object } from 'vue-types';
import LicensePageTabViewIntervalsListItem from './LicensePageTabViewIntervalsListItem.vue';
import { LicensePageTabViewIntervalsListSection } from './types';

export default defineComponent({
  name: 'LicensePageTabViewIntervalsList',
  components: { LicensePageTabViewIntervalsListItem },
  props: {
    modelValue: number().isRequired,
    sections: arrayOf(object<LicensePageTabViewIntervalsListSection>()).isRequired
  },
  emits: ['update:modelValue'],
  methods: {
    isItemSelected(index: number): boolean {
      return this.modelValue === index;
    },
    dispatchUpdateModelValueEvent(modelValue: number): void {
      this.$emit('update:modelValue', modelValue);
    }
  }
});
