import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "license-page-tab-view-intervals-list" }
const _hoisted_2 = { class: "license-page-tab-view-intervals-list__header label-s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicensePageTabViewIntervalsListItem = _resolveComponent("LicensePageTabViewIntervalsListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(section.header), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.intervals, (interval, j) => {
          return (_openBlock(), _createBlock(_component_LicensePageTabViewIntervalsListItem, {
            key: j,
            interval: interval,
            selected: _ctx.isItemSelected(i + j),
            onClick: ($event: any) => (_ctx.dispatchUpdateModelValueEvent(i + j))
          }, null, 8, ["interval", "selected", "onClick"]))
        }), 128))
      ], 64))
    }), 128))
  ]))
}