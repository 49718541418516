/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ExternalVms = {
    readonly id: number;
    /**
     * ExternalVms creation date
     */
    readonly created_date: string;
    /**
     * ExternalVms modification date
     */
    readonly modified_date: string;
    /**
     * Name of VMS
     */
    name: string;
    /**
     * VMS version
     */
    version: string;
    /**
     * VMS configuration options
     */
    options?: Record<string, any>;
    /**
     * Available VMS features
     */
    features?: Record<string, any>;
    /**
     * VMS verbose name
     */
    verbose_name?: string;
};
export const EmptyExternalVms: ExternalVms = {
    id: 0,
    /**
     * ExternalVms creation date
     */
    created_date: '',
    /**
     * ExternalVms modification date
     */
    modified_date: '',
    /**
     * Name of VMS
     */
    name: '',
    /**
     * VMS version
     */
    version: '',
    /**
     * VMS configuration options
     */
    options: {},
    /**
     * Available VMS features
     */
    features: {},
    /**
     * VMS verbose name
     */
    verbose_name: '',
};