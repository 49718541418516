/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NotificationsFilter = {
    /**
     * Select notifications acknowledged by user
     */
    acknowledged_by?: number;
    /**
     * Select notifications with `acknowledged_date` **greater than or equal** to this value
     */
    acknowledged_date_gte?: string;
    /**
     * Select only notifications with this acknowledgement reaction
     */
    acknowledged_reaction?: string;
    /**
     * Select only active (`true`) or closed (`false`) notifications
     */
    active?: boolean;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select notifications on the event with the specified id
     */
    event_id?: string;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Which field to use when ordering the results. Available fields: `id`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select notifications delivered to the specified users
     */
    subscribed_users?: Array<number>;
}
export const EmptyNotificationsFilter: NotificationsFilter = {
    acknowledged_by: undefined,
    acknowledged_date_gte: undefined,
    acknowledged_reaction: undefined,
    active: undefined,
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    event_id: undefined,
    limit: '20',
    ordering: undefined,
    page: undefined,
    subscribed_users: [],
}