export default {
  download: 'Скачать',
  cancel: 'Отменить',
  downloaded: 'Загружено',
  time_range: 'Временной диапазон',
  video_not_available: 'Видео завершилось или недоступно',
  no_video_camera_removed: 'Камера удалена, поэтому записанное видео больше недоступно',
  status_waiting: 'Ожидание...',
  status_loading: 'Загрузка...',
  time_working: 'Время работы',
  process_duration: 'Длительность процесса',
  posted_objects: 'отправлено объектов',
  failed_objects: 'неудачных объектов',
  not_posted_objects: 'не отправлено объектов',
  frames_dropped: 'Потеряно кадров',
  frames_processed: 'Обработано кадров',
  frames_imotion_skipped: 'Пропущено кадров imotion',
  resolution: 'Разрешение',
  video_quality: 'Качество',
  brightness: 'Яркость',
  contrast: 'Контраст',
  saturation: 'Насыщенность',
  faces: 'Лица',
  bodies: 'Силуэты',
  cars: 'Транспортные средства',
  faces_posted: 'отправлено лиц',
  faces_failed: 'неудачных лиц',
  faces_not_posted: 'не отправлено лиц',
  bodies_posted: 'отправлено силуэтов',
  bodies_failed: 'неудачных силуэтов',
  bodies_not_posted: 'не отправлено силуэтов',
  cars_posted: 'отправлено ТС',
  cars_failed: 'неудачных ТС',
  cars_not_posted: 'не отправлено ТС',
  job_starts: 'Перезапусков',
  decoding_errors: 'Неотправлено объектов',
  objects_posted: 'Отправлено объектов',
  objects_failed: 'Неудачных объектов',
  zoom_helper: 'Приблизьте шкалу времени, чтобы увидеть события'
};
