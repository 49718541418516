import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "video-wall-page" }
const _hoisted_2 = {
  key: 0,
  class: "video-wall-page__sidebar"
}
const _hoisted_3 = { class: "video-wall-page__content video-wall-page-content" }
const _hoisted_4 = { class: "video-wall-page-content__head video-wall-page-content-head" }
const _hoisted_5 = {
  key: 0,
  class: "video-wall-page-content-head__sidebar-activator"
}
const _hoisted_6 = { class: "video-wall-page-content-head__preset-editor" }
const _hoisted_7 = { class: "video-wall-page-content-head__fullscreen-activator" }
const _hoisted_8 = {
  class: "video-wall-page-content__body video-wall-page-content-body",
  ref: "pane"
}
const _hoisted_9 = { class: "video-wall-page-content-body__video-wall" }
const _hoisted_10 = { class: "video-wall-page-content-body__episodes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pane = _resolveComponent("pane")!
  const _component_splitpanes = _resolveComponent("splitpanes")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isSidebarSectionVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "sidebar")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.isSidebarSectionVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "sidebar-activator")
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "preset-editor")
        ]),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "fullscreen-activator")
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_splitpanes, {
          horizontal: false,
          class: "video-wall-page-content-body__pane"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_pane, { "min-size": "20" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _renderSlot(_ctx.$slots, "video-wall")
                ])
              ]),
              _: 3
            }),
            (_ctx.isEpisodeSectionVisible)
              ? (_openBlock(), _createBlock(_component_pane, {
                  key: 0,
                  "min-size": "20"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, [
                      _renderSlot(_ctx.$slots, "episodes")
                    ])
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true)
          ]),
          _: 3
        })
      ], 512)
    ])
  ]))
}