import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
  BodyEvent,
  BodyObjectUpdate,
  CarCard,
  CardsService,
  CarEpisode,
  CarEvent,
  CarObjectUpdate,
  FaceEvent,
  FaceObjectUpdate,
  HumanCard,
  HumanEpisode,
  ObjectsService
} from '@/api';
import { EventDetails, EventTypes, ThumbnailPositions } from '@/uikit/thumbnail/helpers/enums';
import { configModule } from '@/store/config';
import { episodeAdapter, eventAdapter, EventView } from './adapter';
import Confidence from '@/components/common/Confidence.vue';
import Acknowledge from '@/components/common/Acknowledge.vue';
import { DirectionEnum as AcknowledgeDirectionEnum } from '@/components/common/acknowledge-types';
import NIcon from '@/uikit/icons/NIcon.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import Features from '@/components/common/Features.vue';
import WatchListsGroup from '@/components/common/WatchListsGroup.vue';
import Attributes from '@/components/common/Attributes.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import NHint from '@/uikit/hint/NHint.vue';
import { RouterModule } from '@/store/router';
import { configAclModule } from '@/store/config/acl';
import CameraLabels from '@/components/common/CameraLabels.vue';
import DoubleThumbnail from '@/components/common/DoubleThumbnail.vue';
import { AnyEpisode, AnyEvent, EventOpenedItem, EventOpenedItems, EventOrEpisode } from '@/components/events/types';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { loadDataModule } from '@/store/data/LoadDataModule';
import { ListViewModel } from '@/definitions/view-models';
import { copyTextToClipboard } from '@/uikit/helpers';
import EventItemAttributeLineCross from './EventItemAttributeLineCross.vue';
import { DisplayType, DisplayTypesMap, EpisodeType, EpisodeTypesMap, EventType, EventTypesMap } from '@/store/application/data.assets';

@Options({
  components: {
    Confidence,
    NThumbnail,
    Acknowledge,
    NButton,
    NIcon,
    CameraLabels,
    Features,
    Attributes,
    WatchListsGroup,
    NHint,
    DoubleThumbnail,
    EventItemAttributeLineCross
  }
})
export default class EventItem extends Vue {
  @Prop({ type: Object, required: true })
  item!: EventOrEpisode;

  @Prop({ type: String, default: DisplayTypesMap.Short })
  displayType!: DisplayType;

  @Prop({ type: String, required: true })
  episodeType!: EpisodeType;

  @Prop({ type: String, default: EventTypesMap.Events })
  type!: EventType;

  @Prop({ type: String, required: true })
  objects!: string;

  @Prop({ type: Boolean, default: false })
  eventSelected!: boolean;

  @Prop({ type: Boolean, default: false })
  cardSelected!: boolean;

  @Prop({ type: Boolean, default: false })
  hideAcknowledge!: boolean;

  @Prop({ type: Boolean, default: true })
  acknowledgeAllowed!: boolean;

  @Prop({ type: String, default: '' })
  openedItem!: EventOpenedItem;

  @Prop({ type: String, required: false })
  defaultAction?: string;

  @Prop({ type: Array })
  iconActions?: [];

  card: HumanCard | CarCard | null = null;
  matched_object: FaceObjectUpdate | BodyObjectUpdate | CarObjectUpdate | null = null;
  objectVM: ListViewModel<any, any> | null = null;

  async mounted() {
    if (this.item.matched_card) this.card = await this.loadCard(this.item.matched_card);
    await this.loadMatchedObject();
  }

  get hasLineCross() {
    return (this.item as AnyEpisode).lines?.length || (this.item as AnyEvent).line;
  }

  get thumbnailActions() {
    const result = [
      { icon: 'check', name: EventTypes.Select, position: ThumbnailPositions.TopLeft, selected: this.eventSelected },
      { icon: 'info', name: EventDetails.ShowInfo, position: ThumbnailPositions.TopRight },
      { icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight }
    ];

    if (configModule.features.vms_enabled) {
      result.push({ icon: 'play', name: EventDetails.ShowPlayer, position: ThumbnailPositions.BottomLeft });
    }
    return this.iconActions || result;
  }

  get isShort() {
    return this.displayType === 'short';
  }

  get isEpisode() {
    return this.type === 'episodes';
  }

  get areCars() {
    return this.isEpisode ? this.episodeType === 'cars' : this.objects === 'cars';
  }

  get thumbnail() {
    return this.isEpisode ? this.event.thumbnail : (this.item as AnyEvent).thumbnail;
  }

  get matchedObjectThumbnail() {
    return this.objectsType === 'cars' ? this.objectVM?.items[0]?.thumbnail ?? this.matched_object?.thumbnail : this.matched_object?.thumbnail;
  }

  get isLiveEpisode() {
    return this.isEpisode && this.eventView.open;
  }

  get fullThumbnailsClasses() {
    return {
      'event-item-full__thumbnails': true,
      'event-item-full__thumbnails_cars': this.areCars
    };
  }

  get counterClasses() {
    return {
      'label-s': true,
      'events-count': true,
      'events-count_live': this.isLiveEpisode
    };
  }

  get hasThumbnail() {
    return !!this.thumbnail;
  }

  get licensePlate() {
    return this.eventView?.license_plate_thumbnail;
  }

  get licensePlateFromCard() {
    return (this.card as CarCard)?.license_plate_number;
  }

  get event() {
    const { last_face_event, last_body_event, best_face_event, best_body_event } = this.item as HumanEpisode;
    const { last_car_event, best_car_event } = this.item as CarEpisode;

    const last_event = last_face_event ?? last_body_event ?? last_car_event ?? '';
    const best_event = best_face_event ?? best_body_event ?? best_car_event;

    return (this.item as HumanEpisode | CarEpisode).open ? last_event : best_event;
  }

  get verticalAcknowledgeDirection() {
    return AcknowledgeDirectionEnum.Vertical;
  }

  get eventView(): EventView {
    return this.isEpisode ? episodeAdapter(this.item as HumanEpisode | CarEpisode) : eventAdapter(this.item as FaceEvent | BodyEvent | CarEvent, this.objects);
  }

  get eventItemContainerClasses() {
    return {
      'event-item__container': true,
      'event-item-short__container': this.isShort,
      'event-item-full__container': !this.isShort,
      'event-item-full__event-container': !this.isShort,
      'event-item__container_contained-in-sidebar': this.eventSelected,
      'event-item__container_selected': this.openedItem === EventOpenedItems.Item
    };
  }

  get cardItemContainerClasses() {
    return {
      'event-item__container': true,
      'event-item-short__container': this.isShort,
      'event-item-full__container': !this.isShort,
      'event-item__container_contained-in-sidebar': this.cardSelected,
      'event-item__container_selected': this.openedItem === EventOpenedItems.Card
    };
  }

  get cardCibrButtonStyle() {
    return { color: this.cardSelected ? 'var(--color-on-color-100)' : 'var(--color-primary-200)' };
  }

  get licencePlateNumber() {
    if (this.isEpisode && this.episodeType === 'cars') {
      return (this.item as CarEpisode).car_features?.license_plate_number;
    } else if (this.objects === 'cars') return (this.item as CarEvent).features?.license_plate_number;
    else return '';
  }

  get configAclModule() {
    return configAclModule;
  }

  get attributesColor() {
    return this.eventSelected ? 'var(--color-on-color-300)' : '';
  }

  get extraAttributesSlot() {
    // order matter
    if (this.eventView.cars_features) return 'cars-extra-attributes';
    if (this.eventView.bodies_features) return 'bodies-extra-attributes';
    return 'faces-extra-attributes';
  }

  get attributesItems() {
    return {
      faces: { display: !!this.eventView.faces_features, features: this.eventView.faces_features },
      bodies: { display: !!this.eventView.bodies_features, features: this.eventView.bodies_features },
      cars: { display: !!this.eventView.cars_features, features: this.eventView.cars_features }
    };
  }

  get watchListsColor() {
    return this.cardSelected ? 'var(--color-on-color-100)' : '';
  }

  get date() {
    return (
      this.$filters.formatDate(this.eventView.created_date) +
      ' ' +
      this.$filters.formatTimeHHMM(this.eventView.created_date) +
      (this.eventView.closed_date ? ' - ' + this.$filters.formatTimeHHMM(this.eventView.closed_date) : '')
    );
  }

  get matchedCard() {
    return this.card ? this.card.name : '';
  }

  get formattedMatchedCard() {
    return this.$filters.shortString(this.matchedCard, 30);
  }

  get objectsType() {
    return (this.isEpisode ? this.eventView?.objectsType : this.objects) ?? this.objects;
  }

  get rootComputedStyle() {
    return window.getComputedStyle(document.documentElement);
  }

  computedCommentHeight() {
    const { card_comment } = this.$refs;
    const comment = card_comment?.offsetTop;
    if (comment) {
      const thumbnailSize = parseFloat(this.rootComputedStyle.getPropertyValue('--thumbnail-large-size'));
      const lineHeightComment = parseFloat(this.rootComputedStyle.getPropertyValue('--line-height-5'));
      return Math.floor((thumbnailSize - comment) / lineHeightComment);
    }
  }

  async loadObjectByCardId() {
    if (this.eventView.matched_card) {
      this.objectVM = await loadDataModule.getObjectsLVMByCardID({
        id: Number(this.eventView.matched_card),
        type: this.objectsType,
        limit: 1
      });
    }
  }

  async loadCard(id: number): Promise<HumanCard | CarCard | null> {
    let result = null;
    if (id) {
      const servicePromise =
        (!this.isEpisode && this.objects === 'cars') || (this.isEpisode && this.episodeType === 'cars')
          ? CardsService.cardsCarsRetrieve(id)
          : CardsService.cardsHumansRetrieve(id);
      result = await (servicePromise as Promise<HumanCard | CarCard>);
    }
    return result;
  }

  async loadMatchedObject() {
    const { matched_object: id } = this.eventView as any;
    if (id) {
      let servicePromise;
      switch (this.objectsType) {
        case 'cars':
          servicePromise = ObjectsService.objectsCarsRetrieve(id);
          break;
        case 'bodies':
          servicePromise = ObjectsService.objectsBodiesRetrieve(id);
          break;
        case 'faces':
          servicePromise = ObjectsService.objectsFacesRetrieve(id);
          break;
      }
      servicePromise?.then(
        (v) => (this.matched_object = v),
        (e) => console.warn('[EventItem:Matched]', e)
      );
    } else {
      switch (this.objectsType) {
        case 'cars':
          await this.loadObjectByCardId();
          break;
      }
    }
  }

  navigateToCase() {
    RouterModule.navigateToCase(this.item.case);
  }

  navigateToCard() {
    if (this.card) {
      this.$emit('action', ItemsActionNames.NavigateToCard, this.card);
    }
  }

  idClickHandler(text: string | number) {
    copyTextToClipboard(String(text));
  }

  @Watch('item.card')
  async changeCardHandler(v: number | null) {
    if (!v) {
      this.card = null;
    } else {
      this.card = await this.loadCard(v);
    }
  }

  @Watch('item.matched_object')
  async changeMatchedObjectHandler(v: FaceObjectUpdate | BodyObjectUpdate | CarObjectUpdate | null) {
    if (!v) {
      this.matched_object = null;
    } else {
      await this.loadMatchedObject();
    }
  }
}
