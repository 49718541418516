/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OnvifCamerasFilter = {
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
}
export const EmptyOnvifCamerasFilter: OnvifCamerasFilter = {
        limit: '10',
        ordering: undefined,
        page: undefined,
}