<template>
  <div class="page page_center">
    <NLoadingCircle />
  </div>
</template>

<script>
import { NLoadingCircle } from '@/uikit';
export default {
  name: 'LoadingPage',
  components: { NLoadingCircle }
};
</script>

<style lang="stylus">

.page {
  width: 100%;
  height: 100%;
  min-width: 640px;
  min-height: 480px;

  &_center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
