import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_SessionsAndBlocklistTabs = _resolveComponent("SessionsAndBlocklistTabs")!
  const _component_FilterSection = _resolveComponent("FilterSection")!
  const _component_BlocklistForm = _resolveComponent("BlocklistForm")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!
  const _component_SettingsMenu = _resolveComponent("SettingsMenu")!
  const _component_ListPage = _resolveComponent("ListPage")!

  return (_openBlock(), _createBlock(_component_ListPage, {
    state: _ctx.state,
    statistics: false,
    "onUpdate:scrollBottom": _ctx.scrollBottomHandler,
    class: "sessions-page",
    ref: "listPage"
  }, {
    filters: _withCtx(() => [
      _createVNode(_component_SessionsAndBlocklistTabs, null, {
        default: _withCtx(() => [
          (_ctx.blockAvailable && _ctx.selectedItems.length)
            ? (_openBlock(), _createBlock(_component_NButton, {
                key: 0,
                onAction: _ctx.blockAll,
                label: _ctx.$t('common.block', 'f'),
                icon: "block",
                type: "secondary"
              }, null, 8, ["onAction", "label"]))
            : _createCommentVNode("", true),
          (_ctx.terminateAvailable && _ctx.selectedItems.length)
            ? (_openBlock(), _createBlock(_component_NButton, {
                key: 1,
                onAction: _ctx.terminateAll,
                label: _ctx.$t('common.terminate', 'f'),
                icon: "close",
                type: "secondary"
              }, null, 8, ["onAction", "label"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_FilterSection, {
        filter: _ctx.module.filter,
        "scheme-small": _ctx.getSmallFilterLayout,
        "has-pinned-fields": ""
      }, null, 8, ["filter", "scheme-small"])
    ]),
    items: _withCtx(() => [
      (_ctx.showModalWindow)
        ? (_openBlock(), _createBlock(_component_BlocklistForm, {
            key: 0,
            "selected-items": _ctx.selectedItems,
            onClose: _ctx.handleModalWindowClose
          }, null, 8, ["selected-items", "onClose"]))
        : _createCommentVNode("", true),
      (_ctx.module.loaded && _ctx.items.length)
        ? (_openBlock(), _createBlock(_component_NTable, {
            key: 1,
            columns: _ctx.columns,
            content: _ctx.items,
            onSort: _ctx.handleTableSort,
            "selected-items-ids": _ctx.selectedItemIds
          }, null, 8, ["columns", "content", "onSort", "selected-items-ids"]))
        : _createCommentVNode("", true),
      _createVNode(_component_InfiniteScroll, {
        filters: _ctx.module.filter.current,
        onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.module.append()))
      }, null, 8, ["filters"])
    ]),
    "left-bar": _withCtx(() => [
      _createVNode(_component_SettingsMenu)
    ]),
    _: 1
  }, 8, ["state", "onUpdate:scrollBottom"]))
}