import { ExternalVmsService } from '@/api';

export default async function enrichExternalVms(vmsListItems: any) {
  const enrichedItems = [];

  for await (const item of vmsListItems) {
    const health_status = await ExternalVmsService.externalVmsHealthStatusRetrieve(item.id);
    Object.assign(item, { health_status });
    enrichedItems.push(item);
  }

  return enrichedItems;
}
