import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "n-attachments-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (attachment) => {
      return _renderSlot(_ctx.$slots, "attachment", {
        key: attachment.id,
        attachment: attachment
      })
    }), 128))
  ]))
}