/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { CameraStreamSettings } from './CameraStreamSettings';
import { Status } from './Status';
export type VideoArchive = {
    readonly id: number;
    camera_group: number;
    name: string | null;
    url?: string | null;
    camera?: number | null;
    /**
     * Video processing start date
     */
    readonly processing_start_date: string;
    /**
     * Processing is active
     */
    readonly active: boolean;
    /**
     * Screenshot URL
     */
    readonly screenshot: string;
    stream_settings?: CameraStreamSettings;
    /**
     * Source length in seconds
     */
    readonly source_len: number;
    readonly health_status: Status;
    /**
     * `true` if the video processing is finished
     */
    finished: boolean;
    /**
     * `true` if the video is in the processing queue
     */
    readonly queued: boolean;
    /**
     * Where to store faces from this video
     */
    save_to?: string;
    /**
     * Number of created faces
     */
    readonly face_count: number;
    /**
     * Number of created face clusters
     */
    readonly face_cluster_count: number;
    /**
     * Video file size in the archive
     */
    readonly file_size: number;
    /**
     * Object creation date
     */
    readonly created_date: string;
    /**
     * Number of created bodies
     */
    readonly body_count: number;
    /**
     * Number of created bodies clusters
     */
    readonly body_cluster_count: number;
    /**
     * Number of created cars
     */
    readonly car_count: number;
    /**
     * Number of created car clusters
     */
    readonly car_cluster_count: number;
    case?: number | null;
};
export const EmptyVideoArchive: VideoArchive = {
    id: 0,
    camera_group: 0,
    name: '',
    url: '',
    camera: 0,
    /**
     * Video processing start date
     */
    processing_start_date: '',
    /**
     * Processing is active
     */
    active: false,
    /**
     * Screenshot URL
     */
    screenshot: '',
    stream_settings: undefined,
    /**
     * Source length in seconds
     */
    source_len: 0,
    health_status: {} as any as Status,
    /**
     * `true` if the video processing is finished
     */
    finished: false,
    /**
     * `true` if the video is in the processing queue
     */
    queued: false,
    /**
     * Where to store faces from this video
     */
    save_to: '',
    /**
     * Number of created faces
     */
    face_count: 0,
    /**
     * Video file size in the archive
     */
    file_size: 0,
    /**
     * Object creation date
     */
    created_date: '',
    /**
     * Number of created bodies
     */
    body_count: 0,
    /**
     * Number of created cars
     */
    car_count: 0,
    case: 0,

    /**
     * Number of created face clusters
     */
    face_cluster_count: 0,
    /**
     * Number of created bodies clusters
     */
    body_cluster_count: 0,
    /**
     * Number of created car clusters
     */
     car_cluster_count: 0
};
