import { Report, ReportFormatEnum } from '@/api';
import { asDefined, exception, isString, isStringWithValue } from '@/common/utils';
import { Nullable, ReportFormat, ReportPage, ReportPageCursor, ReportPageUrl, ReportUrl } from './types';
import { ReportUrlWithReport } from '@/pages/reports/components/downloader/services';

const START_REPORT_PAGE_CURSOR: ReportPageCursor = '';

export function extractReportFormats(report: Report): ReportFormat[] {
  const formats: ReportFormat[] = [];

  isStringWithValue(report.report_file_csv) && formats.push(ReportFormatEnum.CSV);
  isStringWithValue(report.report_file_json) && formats.push(ReportFormatEnum.JSON);
  isStringWithValue(report.report_file_xls) && formats.push(ReportFormatEnum.XLS);

  return formats.sort();
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function extractReportUrls(report: Report): ReportUrlWithReport[] {
  return [report.report_file_csv, report.report_file_json, report.report_file_xls].filter(isStringWithValue).map((url) => ({ url, report }));
}

export function extractReportUrlByFormat(report: Report, format: ReportFormat): ReportUrl {
  switch (format) {
    case ReportFormatEnum.CSV:
      return asDefined(report.report_file_csv);
    case ReportFormatEnum.JSON:
      return asDefined(report.report_file_json);
    case ReportFormatEnum.XLS:
      return asDefined(report.report_file_xls);
    default:
      exception(`The unknown report format: ${format}`);
  }
}

export function lastOf<T>(items: T[]): T {
  return !isEmpty(items) ? items[items.length - 1] : exception('The passed array is empty.');
}

export function isEmpty<T extends { length: number }>(items: T): boolean {
  return items.length === 0;
}

export function computeReportPageCursors(pages: ReportPage[]): ReportPageCursor[] {
  return [START_REPORT_PAGE_CURSOR, ...pages.map(computeReportPageCursor).filter(isString)];
}

export function computeReportPageCursor(page: ReportPage): Nullable<ReportPageCursor> {
  return isStringWithValue(page.next_page) ? computeReportPageCursorFromUrl(page.next_page) : null;
}

export function computeReportPageCursorFromUrl(url: ReportPageUrl): ReportPageCursor {
  const [, cursor] = /page=([\w\d]+)$/.exec(url) ?? [];
  return isStringWithValue(cursor) ? cursor : exception('The passed page URL does not contain cursor.');
}
