/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UsersFilter = {
    /**
     * Select only active users (`true`) or inactive (`false`) users
     */
    active?: boolean;
    /**
     * Select objects with `date_joined` **greater** than this value
     */
    date_joined_gt?: string;
    /**
     * Select objects with `date_joined` **greater than or equal** to this value
     */
    date_joined_gte?: string;
    /**
     * Select objects with `date_joined` **less** than this value
     */
    date_joined_lt?: string;
    /**
     * Select objects with `date_joined` **less than or equal** to this value
     */
    date_joined_lte?: string;
    /**
     * Select users belonging to this group.
     */
    groups?: Array<number>;
    /**
     * Select only users with uploaded image (`true`) or without an image (`false`)
     */
    has_face?: boolean;
    /**
     * Select users with provided ids
     */
    id_in?: Array<number>;
    /**
     * Get users with this language selected
     */
    language?: 'en-us' | 'es' | 'ru';
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Which field to use when ordering the results. Available fields: `id`, `date_joined`, `modified_date`, `username`, `first_name`, `last_name`, `primary_group`, `is_active`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select users whose primary group is this group.
     */
    primary_group?: Array<number>;
    /**
     * Select users where the SQL condition `real_name ILIKE real_name_contains` is true
     */
    real_name_contains?: string;
    /**
     * Select users where the SQL condition `username ILIKE username_contains` is true
     */
    username_contains?: string;
}
export const EmptyUsersFilter: UsersFilter = {
    active: undefined,
    date_joined_gt: undefined,
    date_joined_gte: undefined,
    date_joined_lt: undefined,
    date_joined_lte: undefined,
    groups: [],
    has_face: undefined,
    id_in: [],
    language: undefined,
    limit: '20',
    ordering: undefined,
    page: undefined,
    primary_group: [],
    real_name_contains: undefined,
    username_contains: undefined,
}