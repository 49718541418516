import { Middleware, size } from '@floating-ui/core';
import { autoUpdate, autoPlacement, computePosition, ComputePositionConfig, ComputePositionReturn, offset, shift } from '@floating-ui/dom';
import { ObjectDirective } from 'vue';

export type StickDirective<TFloatingElement extends HTMLElement, TReferenceElement extends Element> = ObjectDirective<TFloatingElement, TReferenceElement>;

export function createStickDirective<TFloatingElement extends HTMLElement, TReferenceElement extends Element>(): StickDirective<
  TFloatingElement,
  TReferenceElement
> {
  type BeforeMountDirectiveHook = ThisDirective['beforeMount'];
  type MountedDirectiveHook = ThisDirective['mounted'];
  type BeforeUnmountDirectiveHook = ThisDirective['beforeUnmount'];
  type ExtendedComputePositionReturn = ComputePositionReturn & { availableHeight: number };
  type FloatingElementAutoPositioningDisposer = ReturnType<typeof autoUpdate>;
  type ThisDirective = StickDirective<TFloatingElement, TReferenceElement>;

  const DistanceFromControlToHelper = 3;
  const DistanceFromElementToWindowBottom = 30;
  const FloatingElementStackIndex = 3000;

  let dispose!: FloatingElementAutoPositioningDisposer;

  const beforeMount: BeforeMountDirectiveHook = (floating, { value: reference }) => {
    dispose = useFloatingElementAutoPositioning(floating, reference);
  };

  const mounted: MountedDirectiveHook = (floating, { value: reference }) => {
    refreshFloatingElementStyle(floating, reference);
  };

  const beforeUnmount: BeforeUnmountDirectiveHook = () => dispose();

  function useFloatingElementAutoPositioning(floating: TFloatingElement, reference: TReferenceElement): FloatingElementAutoPositioningDisposer {
    return autoUpdate(reference, floating, () => refreshFloatingElementStyle(floating, reference));
  }

  async function refreshFloatingElementStyle(floating: TFloatingElement, reference: TReferenceElement): Promise<void> {
    Object.assign(floating.style, await computeFloatingElementStyle(floating, reference));
  }

  async function computeFloatingElementStyle(floating: TFloatingElement, reference: TReferenceElement): Promise<Partial<CSSStyleDeclaration>> {
    const position = await computeFloatingElementPosition(floating, reference);
    const maxHeight = position.availableHeight - DistanceFromElementToWindowBottom;
    return {
      left: `${position.x}px`,
      maxHeight: `${maxHeight}px`,
      position: 'absolute',
      top: `${position.y}px`,
      zIndex: String(FloatingElementStackIndex)
    };
  }

  async function computeFloatingElementPosition(floating: TFloatingElement, reference: TReferenceElement): Promise<ExtendedComputePositionReturn> {
    let availableHeight = 0;
    const middleware: Middleware[] = [
      autoPlacement({ allowedPlacements: ['top', 'bottom'] }),
      offset(DistanceFromControlToHelper),
      shift(),
      size({
        apply: (args) => {
          availableHeight = args.availableHeight;
        }
      })
    ];

    const config: Partial<ComputePositionConfig> = { middleware };
    return { ...(await computePosition(reference, floating, config)), availableHeight };
  }

  return { beforeMount, beforeUnmount, mounted };
}
