/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { EventTypeEnum } from "@/api";
export type ExternalVmsSendEventStatus = {
    readonly id: number;
    /**
     * Record creation date
     */
    readonly created_date: string;
    /**
     * Event type
     */
    readonly event_type?: EventTypeEnum;
    /**
     * Event ID
     */
    readonly event_id: string;
    /**
     * An error occurred while sending the event 
     */
    readonly error: boolean;
    /**
     * Encountered error's message
     */
    readonly error_msg: string;
    /**
     * Target external VMS
     */
    external_vms: number;
    /**
     * Target external VMS camera
     */
    camera?: number | null;
};
export const EmptyExternalVmsSendEventStatus: ExternalVmsSendEventStatus = {
    id: 0,
        /**
        * Record creation date
        */
    created_date: '',
        /**
        * Event type
        */
    event_type: undefined,
        /**
        * Event ID
        */
    event_id: '',
        /**
        * An error occurred while sending the event 
        */
    error: false,
        /**
        * Encountered error's message
        */
    error_msg: '',
        /**
        * Target external VMS
        */
    external_vms: 0,
        /**
        * Target external VMS camera
        */
    camera: null,
};