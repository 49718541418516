import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { computed, CSSProperties, ToRefs, unref } from 'vue';
import {
  NTableBodyCell,
  NTableBodyRow,
  NTableBodySchema,
  NTableBodySchemaDecorator,
  NTableColumn,
  NTableColumnWidth,
  NTableContent,
  NTableEvent,
  NTableFootCell,
  NTableFootRow,
  NTableFootSchema,
  NTableFootSchemaDecorator,
  NTableHeadCell,
  NTableHeadRow,
  NTableHeadSchema,
  NTableHeadSchemaDecorator,
  NTablePropDict,
  Nullable,
  Optional
} from './types';
import { createTableSectionSchemaDecorator, decorate, isDefined } from './utils';

const DEFAULT_GRID_TEMPLATE_COLUMN_WIDTH = 'auto';
const GRID_TEMPLATE_COLUMN_WIDTHS_DELIMITER = '\x20';

export function useTableViewModel<T extends NTableContent>(props: ToRefs<NTablePropDict<T>>, emit: (event: NTableEvent, ...args: unknown[]) => void) {
  const bodyRows = computed(computeBodyRows);
  const bodySchemas = computed(computeBodySchemas);
  const decorateBody = computed(computeBodySchemaDecorator);
  const decorateFoot = computed(computeFootSchemaDecorator);
  const decorateHead = computed(computeHeadSchemaDecorator);
  const footRows = computed(computeFootRows);
  const footSchemas = computed(computeFootSchemas);
  const headRows = computed(computeHeadRows);
  const headSchemas = computed(computeHeadSchemas);
  const isFootVisible = computed(computeFootVisibility);
  const isHeadVisible = computed(computeHeadVisibility);
  const schemas = computed(computeColumnSchemas);
  const template = computed(computeGridTemplateColumns);

  function computeBodyRows(): NTableBodyRow<T>[] {
    return unref(props.content).map((content, y) => {
      return unref(schemas).map<NTableBodyCell<T>>(({ key }, x) => ({ model: content, key, x, y }));
    });
  }

  function computeBodySchemaDecorator(): NTableBodySchemaDecorator<T> {
    return decorate([createBodyCellStyler(), ...unref(props.bodyDecorators)]);
  }

  function computeBodySchemas(): NTableBodySchema<T>[] {
    return unref(schemas).map(({ body }) => unref(decorateBody)(body));
  }

  function computeColumnSchemas(): NTableColumn<T>[] {
    return unref(props.selections) ? [computeSelectionColumnSchema(), ...unref(props.columns)] : unref(props.columns);
  }

  function computeFootRows(): NTableFootRow[] {
    return [unref(schemas).map<NTableFootCell>(({ key }, x) => ({ key, x }))];
  }

  function computeFootSchemaDecorator(): NTableFootSchemaDecorator {
    return decorate([createFootCellStyler(), ...unref(props.footDecorators)]);
  }

  function computeFootSchemas(): Nullable<NTableFootSchema>[] {
    return unref(schemas).map(({ foot }) => (isDefined(foot) ? unref(decorateFoot)(foot) : null));
  }

  function computeFootVisibility(): boolean {
    return unref(footSchemas).some(isDefined);
  }

  function computeGridTemplateColumns(): CSSProperties {
    const widths = unref(schemas).map(({ width }) => computeGridTemplateColumnWidth(width));
    return { gridTemplateColumns: widths.join(GRID_TEMPLATE_COLUMN_WIDTHS_DELIMITER) };
  }

  function computeGridTemplateColumnWidth(width: Optional<NTableColumnWidth>): string {
    return (isNumber(width) && Number.isFinite(width) ? `${width}px` : isString(width) && width) || DEFAULT_GRID_TEMPLATE_COLUMN_WIDTH;
  }

  function computeHeadRows(): NTableHeadRow[] {
    return [unref(schemas).map<NTableHeadCell>(({ key }, x) => ({ key, x }))];
  }

  function computeHeadSchemaDecorator(): NTableHeadSchemaDecorator {
    return decorate([createHeadCellStyler(), ...unref(props.headDecorators)]);
  }

  function computeHeadSchemas(): Nullable<NTableHeadSchema>[] {
    return unref(schemas).map(({ head }) => (isDefined(head) ? unref(decorateHead)(head) : null));
  }

  function computeHeadVisibility(): boolean {
    return unref(headSchemas).some(isDefined);
  }

  function computeSelectionColumnSchema(): NTableColumn<T> {
    return unref(props.selectionsSchema)(props.selected, props.content, dispatchUpdateSelectedEvent, dispatchUpdateSelectedChangesEvent);
  }

  function createBodyCellStyler(): NTableBodySchemaDecorator<T> {
    return createTableSectionSchemaDecorator({
      class: () => ({
        'label-m': true,
        'n-table__body-cell_hoverable': unref(props.hoverable),
        'n-table__body-cell': true
      })
    });
  }

  function createFootCellStyler(): NTableFootSchemaDecorator {
    return createTableSectionSchemaDecorator({
      class: () => ({
        'control-m': true,
        'n-table__foot-cell_fixed': unref(props.fixedFoot),
        'n-table__foot-cell': true
      })
    });
  }

  function createHeadCellStyler(): NTableHeadSchemaDecorator {
    return createTableSectionSchemaDecorator({
      class: () => ({
        'control-m': true,
        'n-table__head-cell_fixed': unref(props.fixedHead),
        'n-table__head-cell': true
      })
    });
  }

  function dispatchBodyCellClickEvent(cell: NTableBodyCell<T>): void {
    emit('body-cell-click', cell);
    emit('row-click', cell.model);
  }

  function dispatchFootCellClickEvent(cell: NTableFootCell): void {
    emit('foot-cell-click', cell);
  }

  function dispatchHeadCellClickEvent(cell: NTableHeadCell): void {
    emit('head-cell-click', cell);
  }

  function dispatchUpdateSelectedEvent(selected: T[]): void {
    emit('update:selected', selected);
  }

  function dispatchUpdateSelectedChangesEvent(selected: T[]): void {
    emit('update:selectedChanges', selected);
  }

  return {
    bodyRows,
    bodySchemas,
    dispatchBodyCellClickEvent,
    dispatchFootCellClickEvent,
    dispatchHeadCellClickEvent,
    footRows,
    footSchemas,
    headRows,
    headSchemas,
    isFootVisible,
    isHeadVisible,
    template
  };
}
