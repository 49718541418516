/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { CameraStreamSettings } from './CameraStreamSettings';
import { ExternalDetectorSettings } from './ExternalDetectorSettings';
import { OnvifCamera } from './OnvifCamera';
import { Status } from './Status';
export type Camera = {
    readonly id: number;
    /**
     * Object creation date
     */
    readonly created_date: string;
    /**
     * Object modification date
     */
    readonly modified_date: string;
    /**
     * Camera group
     */
    group: number;
    /**
     * Processing is active
     */
    active?: boolean;
    /**
     * Short name, up to 256 characters
     */
    name: string;
    /**
     * Extended description, up to 2048 characters
     */
    comment?: string;
    url?: string;
    router_url?: string | null;
    stream_settings?: CameraStreamSettings;
    /**
     * Screenshot URL
     */
    readonly screenshot: string;
    readonly health_status: Status;
    /**
     * Camera latitude
     */
    latitude?: number | null;
    /**
     * Camera longitude
     */
    longitude?: number | null;
    /**
     * Camera azimuth
     */
    azimuth?: number | null;
    /**
     * Number of faces from this camera
     */
    readonly face_count: number;
    /**
     * Number of faces from this camera
     */
    readonly body_count: number;
    /**
     * Number of faces from this camera
     */
    readonly car_count: number;
    /**
     * Onvif camera endpoint reference
     */
    onvif_epr?: string | null;
    /**
     * Onvif camera info
     */
    readonly onvif_camera: OnvifCamera;
    /**
     * Camera face confidence threshold
     */
    face_threshold?: number | null;
    /**
     * Camera body confidence threshold
     */
    body_threshold?: number | null;
    /**
     * Camera car confidence threshold
     */
    car_threshold?: number | null;
    single_pass?: boolean;
    external_detector?: boolean;
    /**
     * Use this token for events creation API
     */
    readonly external_detector_token: string;
    external_detector_settings?: ExternalDetectorSettings;

    external_vms?: number | null;
    external_vms_camera_id?: string | null;

    frame_width: number;
    frame_height: number;
};
export const EmptyCamera: Camera = {
    id: 0,
    /**
     * Object creation date
     */
    created_date: '',
    /**
     * Object modification date
     */
    modified_date: '',
    /**
     * Camera group
     */
    group: 0,
    /**
     * Processing is active
     */
    active: true,
    /**
     * Short name, up to 256 characters
     */
    name: '',
    /**
     * Extended description, up to 2048 characters
     */
    comment: '',
    url: '',
    router_url: '',
    stream_settings: undefined,
    /**
     * Screenshot URL
     */
    screenshot: '',
    health_status: {} as any as Status,
    /**
     * Camera latitude
     */
    latitude: 0,
    /**
     * Camera longitude
     */
    longitude: 0,
    /**
     * Camera azimuth
     */
    azimuth: 0,
    /**
     * Number of faces from this camera
     */
    face_count: 0,
    /**
     * Number of faces from this camera
     */
    body_count: 0,
    /**
     * Number of faces from this camera
     */
    car_count: 0,
    /**
     * Onvif camera endpoint reference
     */
    onvif_epr: '',
    /**
     * Onvif camera info
     */
    onvif_camera: {} as any as OnvifCamera,
    /**
     * Camera face confidence threshold
     */
    face_threshold: 0,
    /**
     * Camera body confidence threshold
     */
    body_threshold: 0,
    /**
     * Camera car confidence threshold
     */
    car_threshold: 0,
    single_pass: false,
    external_detector: false,
    /**
     * Use this token for events creation API
     */
    external_detector_token: '',
    external_detector_settings: undefined,

    external_vms: null,
    external_vms_camera_id: null,

    frame_width: 0,
    frame_height: 0,
};
