
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ColorEnum } from '@/api';

Options({});
export default class ExternalVmsStatus extends Vue {
  @Prop({ type: Object, required: true })
  readonly modelValue!: any;

  get circleColor() {
    switch (this.modelValue?.code) {
      case ColorEnum.GREEN:
        return '--color-positive';
      case ColorEnum.RED:
        return '--color-negative';
    }
    return '';
  }
}
