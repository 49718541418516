
import { defineComponent, ref } from 'vue';
import { Nullable, usePageContentLayoutInfiniteScroll } from './setup';

export default defineComponent({
  name: 'PageContentLayout',
  emits: ['update:scrollBottom', 'fetch'],
  props: {
    hasLeftBar: {
      type: Boolean,
      default: false
    },
    hasViewer: {
      type: Boolean,
      default: false
    },
    hasPlayer: {
      type: Boolean,
      default: false
    },
    hasOverlay: {
      type: Boolean,
      default: false
    },
    hasMainHeader: {
      type: Boolean,
      default: false
    },
    decreaseContent: {
      type: Boolean,
      default: true
    }
  },
  data(): any {
    return {
      scrollBottom: null,
      updateScrollTimeout: 0
    };
  },
  setup(_props, { emit }) {
    const internal = ref<Nullable<HTMLDivElement>>(null);
    const main = ref<Nullable<HTMLElement>>(null);
    usePageContentLayoutInfiniteScroll(internal, main, () => emit('fetch'));
    return { internal, main };
  },
  computed: {
    classes(): Record<string, boolean> {
      return {
        'page-content_has-leftbar': this.hasLeftBar,
        'page-content_has-mainheader': this.hasMainHeader
      };
    }
  },
  mounted() {
    clearInterval(this.updateScrollTimeout);
    this.updateScrollTimeout = setInterval(this.updateScrollBottom, 1000);
  },
  beforeUnmount() {
    clearInterval(this.updateScrollTimeout);
  },
  methods: {
    getScrollTop(): number {
      const el = this.$refs.internal;
      return el?.scrollTop ?? 0;
    },
    resetScrollTop(): void {
      const el = this.$refs.internal;
      if (el) el.scrollTop = 0;
    },
    updateScrollBottom(): void {
      const el = this.$refs.internal;
      const scrollBottom = el ? el.scrollHeight - el.clientHeight - el.scrollTop : null;
      this.scrollBottom = scrollBottom;
      const isElementInViewport = el?.clientHeight > 0;
      if (isElementInViewport) this.$emit('update:scrollBottom', scrollBottom);
    },
    scrollHandler(): void {
      this.updateScrollBottom();
    }
  }
});
