import * as filters from '@/common/filters';
import { bodyFeatures, carsFeatures, facesFeatures, getSatisfyEventFeatures } from '@/store/events/features.filter';
import { get, intersection } from 'lodash';
import { doesEventSatisfyHeadposeFilter } from './satisfy.event.filter';

export function satisfyEpisodeFilter(episode: any, filter: any, objects: string): boolean {
  let satisfy = true,
    event = episode.last_body_event || episode.last_face_event;

  if (satisfy && filter.id_in.length) {
    satisfy = filter.id_in === episode.id;
  }

  if (satisfy && filter.matched_card) {
    satisfy = filter.matched_card === (event.matched_card || '').toString();
  }

  if (satisfy && filter.acknowledged) {
    satisfy = filter.acknowledged === 'True' ? event.acknowledged : !event.acknowledged;
  }

  if (satisfy && filter.matched_lists.length) {
    satisfy = intersection(filter.matched_lists, event.matched_lists).length > 0;
  }

  if (satisfy && filter.no_face_match) {
    satisfy = filter.no_face_match === 'True' ? !event.matched_card : !!event.matched_card;
  }

  if (satisfy && filter.no_body_match) {
    satisfy = filter.no_body_match === 'True' ? !event.matched_card : !!event.matched_card;
  }

  if (satisfy && filter.no_car_match) {
    satisfy = filter.no_car_match === 'True' ? !event.matched_card : !!event.matched_card;
  }

  if (satisfy && filter.camera_groups.length) {
    satisfy = filter.camera_groups.find((v: number) => v === event.camera_group);
  }

  if (satisfy && filter.cameras.length) {
    satisfy = !!filter.cameras.find((v: number) => v === event.camera);
  }

  if (satisfy && filter.created_date_gte) {
    let isoStringToDate = filters.isoStringToDate,
      filterGTEDate = isoStringToDate(filter.created_date_gte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.created_date_lte) {
    let isoStringToDate = filters.isoStringToDate,
      filterLteDate = isoStringToDate(filter.created_date_lte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() <= filterLteDate.getTime();
  }

  if (satisfy && filter.gender?.length) {
    let r = get(event, 'features.gender.name'),
      item = filter.gender.find((v: string) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.events_count_gte) {
    satisfy = episode.events_count >= filter.events_count_gte;
  }

  if (satisfy && filter.video_archive?.length) {
    satisfy = Number(filter.video_archive) === event.video_archive;
  }

  if (satisfy && filter.age_gte) {
    satisfy = event.features.age >= filter.age_gte;
  }

  if (satisfy && filter.age_lte) {
    satisfy = event.features.age <= filter.age_lte;
  }

  satisfy = satisfy && getSatisfyEventFeatures(event, facesFeatures, filter);
  satisfy = satisfy && getSatisfyEventFeatures(event, carsFeatures, filter);
  satisfy = satisfy && getSatisfyEventFeatures(event, bodyFeatures, filter);
  satisfy = satisfy && (objects === 'humans' ? doesEventSatisfyHeadposeFilter(event.face_features ?? {}, filter) : true);

  return satisfy;
}
