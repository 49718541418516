
import NDropdown from '../dropdown/NDropdown.vue';
import NDropdownItem from '../dropdown/NDropdownItem.vue';
import NButton from '../buttons/NButton.vue';
import NIcon from '../icons/NIcon.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IDateIntervalItem } from './types';
import dayjs from './dayjs';

enum Direction {
  direct = 'direct',
  reverse = 'reverse'
}

export const getDefaultIntervals = (): IDateIntervalItem[] => {
  return [
    { label: 'Today', interval: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()] },
    { label: '24 hours', offset: { value: 24, unit: 'hours' } },
    { label: '7 days', offset: { value: 7, unit: 'days' } },
    { label: '15 days', offset: { value: 15, unit: 'days' } },
    { label: '1 month', offset: { value: 1, unit: 'month' } },
    { label: '2 months', offset: { value: 2, unit: 'months' } },
    { label: '3 months', offset: { value: 3, unit: 'months' } },
    { label: '1 year', offset: { value: 1, unit: 'year' } }
  ];
};

@Options({
  name: 'NDateIntervalSelect',
  components: { NDropdown, NDropdownItem, NIcon, NButton },
  emits: ['update:modelValue']
})
export default class NDateIntervalSelect extends Vue {
  @Prop({ type: Array, default: getDefaultIntervals })
  readonly intervals!: IDateIntervalItem[];

  @Prop({ type: Array, default: Direction.reverse })
  readonly direction!: Direction;

  get computedIntervals() {
    return this.intervals || getDefaultIntervals();
  }

  get intervalLabels() {
    return this.computedIntervals.map((v) => v.label);
  }

  selectHandler(intervalNum: number) {
    let item = this.computedIntervals[intervalNum];
    if (!item) throw new Error(`Can't get interval config from interval prop by index: ${intervalNum}`);
    let from = null;
    let to = null;
    if (item.interval) {
      from = dayjs(item.interval[0]);
      to = dayjs(item.interval[1]);
    } else if (item.offset) {
      if (this.direction === Direction.direct) {
        from = dayjs();
        to = from.add(item.offset.value, item.offset.unit);
      } else {
        to = dayjs();
        from = to.add(-item.offset.value, item.offset.unit);
      }
    } else {
      throw new Error('Interval item should contain "interval" or "offset" props.');
    }
    this.$emit('update:modelValue', [from.toDate(), to.toDate()]);
  }
}
