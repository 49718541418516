import { dataServiceFactory } from '@/definitions/services/data.services';
import { getListViewModel, ListViewModel } from '@/definitions/view-models';
import type { NAttachment } from '@/uikit/attachments';

export interface AttachmentModuleOptions {
  url: string;
  source_id: string | number;
  source_filter_name: string;
  source_form_field_name: string;
}

export class AttachmentModule<T, F> {
  readonly module: ListViewModel<T, F>;
  readonly options: AttachmentModuleOptions;

  constructor(options: AttachmentModuleOptions) {
    this.options = options;
    this.module = this.createAttachmentModule() as any;
    this.uploadAttachmentHandler = this.uploadAttachmentHandler.bind(this);
    this.deleteAttachmentHandler = this.deleteAttachmentHandler.bind(this);
  }

  createAttachmentModule() {
    const options = {
      url: this.options.url,
      routeName: '',
      emptyItem: { id: 0, name: '', url: '' },
      emptyFilter: { limit: 100 }
    };
    return getListViewModel(options);
  }

  loadAttachments() {
    (this.module.filter.current as any)[this.options.source_filter_name] = this.options.source_id;
    this.module.debouncedGet();
  }

  async uploadAttachmentHandler(attachment: NAttachment) {
    const axios = dataServiceFactory.getAxiosInstance();
    const data = new FormData();
    data.append(this.options.source_form_field_name, String(this.options.source_id));
    data.append('name', attachment.name);
    data.append('file', attachment.file);

    const uploadProgressHandler = ({ loaded, total }: ProgressEvent) => {
      attachment.progress = (loaded / total) * 100;
    };

    try {
      attachment.status = 'uploading';
      await axios.post(this.module.name, data, { onUploadProgress: uploadProgressHandler });
      attachment.status = 'done';
    } catch (e) {
      attachment.status = 'error';
    }

    await this.module.get();
  }

  async deleteAttachmentHandler(value: NAttachment) {
    await this.module.delete(value.id);
    await this.module.get();
  }
}
