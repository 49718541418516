import { DetectItem } from '@/components/detection/types';
import { convertSomeBoxToBox } from '@/uikit/bbox/helpers';

export function convertDetectItemsToBboxConfigs(items: DetectItem[], type: string, imageUrl: string, filterLowQuality = true) {
  return items
    .filter(({ low_quality }) => !filterLowQuality || !low_quality)
    .map((item) => {
      return {
        box: convertSomeBoxToBox(item.bbox),
        meta: { id: item.id, type, imageUrl, detection_score: item.detection_score, features: completeFeatures(item.features) }
      };
    });
}

function completeFeatures(features: any) {
  for (let [key, value] of Object.entries(features)) {
    if (key === 'age') {
      features[key] = { name: value, confidence: 1 };
    }
  }
  return features;
}
