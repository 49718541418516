
import { computed, defineComponent, PropType } from 'vue';
import { BboxConfigMeta, Box } from './types';

function addBoxPaddings(box: Box, padding: number): Box {
  return { x: box.x - padding, y: box.y - padding, w: box.w + padding, h: box.h + padding };
}

function getScaledBox(box: Box, scale = 1) {
  return { x: box.x * scale, y: box.y * scale, w: box.w * scale, h: box.h * scale };
}

export default defineComponent({
  name: 'NBbox',
  props: {
    box: { type: Object as PropType<Box>, required: true },
    scale: { type: Number, default: 1 },
    padding: { type: Number, default: 10 },
    meta: { type: Object as PropType<BboxConfigMeta> },
    selectable: { type: Boolean, default: true },
    selected: { type: Boolean, default: false }
  },
  emits: ['select'],
  setup(props, { emit }) {
    const classes = computed(() => {
      const root = 'n-bbox';
      return {
        [root]: true,
        [`${root}_primary`]: !props.selected,
        [`${root}_selectable`]: props.selectable,
        [`${root}_selected`]: props.selected || !props.selectable
      };
    });

    const style = computed(() => {
      const box = getScaledBox(addBoxPaddings(props.box, props.padding), props.scale);
      return {
        transform: `translate(${box.x}px, ${box.y}px)`,
        width: `${box.w}px`,
        height: `${box.h}px`
      };
    });

    function handleClick(event: MouseEvent) {
      emit('select', props);
    }

    return {
      style,
      classes,
      handleClick
    };
  }
});
