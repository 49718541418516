import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NAlertBadge = _resolveComponent("NAlertBadge")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.license || !_ctx.license.source)
      ? (_openBlock(), _createBlock(_component_NAlertBadge, {
          key: 0,
          type: "negative",
          class: "license-page_alert"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('errors.license.not_found')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.license?.source && !_ctx.license.valid.value && !_ctx.isExpired)
      ? (_openBlock(), _createBlock(_component_NAlertBadge, {
          key: 1,
          type: "negative",
          class: "license-page_alert"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('errors.license.not_valid')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isExpired)
      ? (_openBlock(), _createBlock(_component_NAlertBadge, {
          key: 2,
          type: "negative",
          class: "license-page_alert"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('errors.license.expired')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}