
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { CardModule, CardsType, ObjectType } from './cardModule';
import { getAvailableObjects } from './helpers';
import { NSwitch, NButton } from '@/uikit';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { EventTypes, ThumbnailPositions } from '@/uikit/thumbnail/helpers/enums';
import { applicationModule } from '@/store/application';
import { ObjectsMultiToSingle } from '@/store/application/data.assets';
import { SearchModule } from '@/pages/search/searchModule';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import CardItemTile from '@/components/cards/CardItemTile.vue';
import { PageTypes } from '@/store/application/page.definitions';

@Options({
  name: 'SearchSidebarCard',
  components: { CardItemTile, NThumbnail, NButton, NSwitch, NButtonGroup }
})
export default class SearchSidebarCard extends Vue {
  @Prop({ type: Object, required: true })
  searchModule!: SearchModule;

  cardModule = CardModule.create(this.searchModule.state, this.searchModule.item);
  currentSearchMode = '';

  get state() {
    return this.searchModule.state;
  }

  get availableObjects() {
    return getAvailableObjects();
  }

  get currentObjectType(): ObjectType {
    return (ObjectsMultiToSingle[this.currentSearchMode] as ObjectType) || null;
  }

  get hasChanges() {
    return this.cardModule.hasChanges;
  }

  get availableCluster() {
    return this.currentObjectType && this.cardModule.getClusterByType(this.currentObjectType);
  }

  get selectedCluster() {
    return this.currentObjectType && this.cardModule.state[this.currentObjectType].cluster;
  }

  set selectedCluster(v: null | number | string) {
    this.currentObjectType && this.cardModule.toggleClusterByType(this.currentObjectType);
  }

  get objects() {
    return this.currentObjectType ? this.cardModule.getObjects(this.currentObjectType) : [];
  }

  get selectedObjectIds() {
    return this.currentObjectType ? this.cardModule.getSelectedObjects(this.currentObjectType) : [];
  }

  get debugMode() {
    return applicationModule.settings.debug;
  }

  get isAllObjectsSelected() {
    return this.objects.length === this.selectedObjectIds.length;
  }

  get cardType() {
    return this.state.cardType as CardsType;
  }

  get card() {
    return this.searchModule.item;
  }

  getIsObjectIdSelected(id: string) {
    return this.selectedObjectIds.includes(id);
  }

  get searchModeItems() {
    const modes = this.cardModule.objectsAllowedForPageState;
    return modes.map((value) => ({
      value,
      label: this.$t(`search.${value}`),
      counter: value === this.currentSearchMode ? this.allSelectedItemsCount : undefined
    }));
  }

  get itemActions() {
    return [{ icon: 'check', name: EventTypes.Select, position: ThumbnailPositions.TopLeft }];
  }

  get allSelectedItemsCount() {
    if (this.currentObjectType) {
      let state = this.cardModule.state[this.currentObjectType];
      let clusterLetter = state.cluster ? this.$t('search.cluster_letter') : '';
      let objectsCount = state.objects.length > 0 ? state.objects.length : '';
      return clusterLetter || objectsCount ? `${objectsCount}${clusterLetter}` : 0;
    }
    return '';
  }

  @Watch('currentSearchMode')
  handleModeChange(searchMode: string) {
    this.cardModule.reset();
    let object = ObjectsMultiToSingle[searchMode] as ObjectType;
    this.cardModule.selectObjectsByType(object);
    this.cardModule.selectClusterByType(object);
    this.state.objectType = searchMode;
    this.apply();
  }

  @Watch('availableObjects', { deep: true, immediate: true })
  async initModel(v: Record<string, boolean>) {
    this.cardModule.useAvailableObjects(v);
    await this.cardModule.loadObjects();
    this.resetToFirstAvailableSearchMode();
  }

  @Watch('objects')
  handlerObjects(v: any, o: any) {
    if (v.length) {
      this.firstClusterSearch(v[0]);
    }
  }

  @Watch('state.pageType')
  resetToFirstAvailableSearchMode() {
    let hasSomeAllowedSelected = this.searchModeItems.some(({ value }) => value === this.currentSearchMode);
    if (!hasSomeAllowedSelected && this.searchModeItems.length) {
      this.currentSearchMode = this.searchModeItems[0].value;
    }
  }

  changeObjectTypeOnModeSelect(searchMode: string) {
    if (PageTypes.Clusters === this.state.pageType || PageTypes.Events === this.state.pageType) {
      this.state.objectType = searchMode;
    }
  }

  firstClusterSearch(obj: any) {
    this.toggleObject(obj.id);
    this.apply();
  }

  allCurrentTypeToggle() {
    if (this.currentObjectType) {
      this.isAllObjectsSelected ? this.cardModule.deselectObjectByType(this.currentObjectType) : this.cardModule.selectObjectsByType(this.currentObjectType);
    }
  }

  toggleObject(id: string) {
    this.currentObjectType && this.cardModule.toggleObject({ type: this.currentObjectType, id });
  }

  apply() {
    this.cardModule.apply();
  }

  openCard() {
    actionHandler.run(ItemsActionNames.ShowItem, { type: `cards_${this.cardType}`, rawItem: this.card });
  }

  created() {
    this.resetToFirstAvailableSearchMode();
  }
}
