
import { defineComponent } from 'vue';
import { integer } from 'vue-types';

export default defineComponent({
  name: 'ReportsPageDownloaderProgressBar',
  props: {
    value: integer().isRequired
  },
  computed: {
    styles() {
      return { width: `${this.value}%` };
    }
  }
});
