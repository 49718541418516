function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default async function keyboardNavigationHandler(
  e: KeyboardEvent,
  helperExpanded: boolean,
  updateHelper: (expanded: boolean) => void,
  helperClassName: string
) {
  if (e.key === 'ArrowUp') {
    let items = document.getElementsByClassName(helperClassName);
    let index: number | boolean = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].classList.contains('selected')) {
        index = i;
        break;
      }
    }
    if (index !== false && index > 0) {
      items[index].classList.remove('selected');
      items[index - 1].classList.add('selected');
      items[index - 1].scrollIntoView({ block: 'center' });
    } else if (items.length) {
      // items[0].classList.remove('selected');
      if (helperExpanded) {
        // console.log('upd fals', helperExpanded);
        updateHelper(false);
        return;
      }
    }
  }
  if (e.key === 'ArrowDown') {
    if (!helperExpanded) {
      // console.log('upd true', helperExpanded, updateHelper);
      updateHelper(true);
      await sleep(1);
      // return;
    }
    let items = document.getElementsByClassName(helperClassName);
    let index: number | boolean = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].classList.contains('selected')) {
        index = i;
        break;
      }
    }

    if (index !== false && (index + 1) % items.length < items.length) {
      const sibling = (index + 1) % items.length;
      items[index].classList.remove('selected');
      items[sibling].classList.add('selected');
      items[sibling].scrollIntoView({ block: 'center' });
    } else if (items.length) {
      items[0].classList.add('selected');
    }
  }

  if (e.key === 'Enter') {
    let items = document.getElementsByClassName(helperClassName) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < items.length; i++) {
      if (items[i].classList.contains('selected')) {
        items[i].click();
        break;
      }
    }
  }
}
