
import echarts from 'echarts';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  name: 'ChartGraph'
})
export default class ChartGraph extends Vue {
  protected $graph: any = null;

  @Prop({ type: Object, required: true })
  readonly options!: any;

  mounted() {
    this.$graph = echarts.init(this.$refs.root);
    this.$graph.on('click', this.dispatchClickEvent);
    window.addEventListener('resize', this.resize);
    this.setGraphOptions();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
    this.$graph.dispose();
    this.$graph = null;
  }

  @Watch('options', { deep: true })
  handleOptionsChange() {
    this.setGraphOptions();
  }

  setGraphOptions() {
    this.$graph.setOption(this.options);
    this.resize();
  }

  resize() {
    this.$graph.resize();
  }

  dispatchClickEvent({ dataIndex }: any) {
    this.$emit('click', dataIndex);
  }
}
