import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "group-header-bar control-m" }
const _hoisted_2 = { class: "group-header-bar__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "count", {}, () => [
      _createElementVNode("div", null, _toDisplayString(_ctx.currentCount) + " " + _toDisplayString(_ctx.$t(`common.in_total`)), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NInput, {
        class: "group-header-bar__search",
        placeholder: _ctx.$t('common.search', 'f'),
        suffixIcon: "search",
        "model-value": _ctx.searchText,
        "onUpdate:modelValue": _ctx.dispatchUpdateSearchText
      }, null, 8, ["placeholder", "model-value", "onUpdate:modelValue"]),
      (_ctx.addButton)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            class: "group-header-bar__add",
            icon: "add",
            onClick: _ctx.addNewGroup
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(`common.add_new_role`, 'f')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}