/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ClusterEventsFacesFilter = {
            /**
            * Select objects where feature `age` is greater than or equal than this value
            */
        age_gte?: string;
            /**
            * Select objects where feature `age` is lower than or equal than this value
            */
        age_lte?: string;
            /**
            * Select objects where feature `beard` takes one of these values
            */
        beard?: Array<string>;
            /**
            * Select objects related to this `camera group`
            */
        camera_groups?: Array<number>;
            /**
            * Select objects related to this `camera`.
            */
        cameras?: Array<number>;
            /**
            * Select cluster events related to these `cases`
            */
        case_in?: Array<number | null>;
            /**
            * Select objects where the `cluster` id takes one of these values
            */
        cluster_in?: Array<number>;
            /**
            * Select objects with `created_date` **greater** than this value
            */
        created_date_gt?: string;
            /**
            * Select objects with `created_date` **greater than or equal** to this value
            */
        created_date_gte?: string;
            /**
            * Select objects with `created_date` in last N days
            */
        created_date_last_n_days?: number;
            /**
            * Select objects with `created_date` **less** than this value
            */
        created_date_lt?: string;
            /**
            * Select objects with `created_date` **less than or equal** to this value
            */
        created_date_lte?: string;
            /**
            * Select objects with `created_date` in last Nth week (including Sunday and Saturday)
            */
        created_date_nth_full_week?: number;
            /**
            * Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
            */
        created_date_nth_work_week?: number;
            /**
            * Select objects where feature `emotions` takes one of these values
            */
        emotions?: Array<string>;
            /**
            * Select objects related to this `episode`
            */
        episodes?: Array<number>;
            /**
            * Select objects where feature `gender` takes one of these values
            */
        gender?: Array<string>;
            /**
            * Select objects where feature `glasses` takes one of these values
            */
        glasses?: Array<string>;
            /**
            * `true` - select only cluster events relates to case. `false` - select only cluster events without case.
            */
        has_case?: boolean;
            /**
            * `true` - select only cluster events with matches. `false` - select only cluster events without matches.
            */
        has_matches?: boolean;
            /**
            * Select cluster events with provided ids
            */
        id_in?: Array<number>;
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Select objects where feature `liveness` takes one of these values
            */
        liveness?: Array<string>;
            /**
            * Select objects where feature `look` takes one of these values
            */
        look?: Array<string>;
            /**
            * Select objects that match this card
            */
        matched_card?: number;
            /**
            * Select objects that match a card included in these watch lists
            */
        matched_lists?: Array<number>;
            /**
            * Select objects where feature `medmask` takes one of these values
            */
        medmask?: Array<string>;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
}
export const EmptyClusterEventsFacesFilter: ClusterEventsFacesFilter = {
        age_gte: undefined,
        age_lte: undefined,
        beard: [],
        camera_groups: [],
        cameras: [],
        case_in: [],
        cluster_in: [],
        created_date_gt: undefined,
        created_date_gte: undefined,
        created_date_last_n_days: undefined,
        created_date_lt: undefined,
        created_date_lte: undefined,
        created_date_nth_full_week: undefined,
        created_date_nth_work_week: undefined,
        emotions: [],
        episodes: [],
        gender: [],
        glasses: [],
        has_case: undefined,
        has_matches: undefined,
        id_in: [],
        limit: '20',
        liveness: [],
        look: [],
        matched_card: undefined,
        matched_lists: [],
        medmask: [],
        ordering: undefined,
        page: undefined,
}