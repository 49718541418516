
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export enum BadgeTypes {
  Warning = 'warning',
  Negative = 'negative'
}

@Options({
  name: 'NAlertBadge'
})
export default class NAlertBadge extends Vue {
  @Prop({ type: String, default: BadgeTypes.Warning })
  private type?: BadgeTypes;

  get typeClass() {
    return `alert-badge_${this.type}`;
  }
}
