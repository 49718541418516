import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterSection = _resolveComponent("FilterSection")!
  const _component_PageContentLayout = _resolveComponent("PageContentLayout")!

  return (_openBlock(), _createBlock(_component_PageContentLayout, null, {
    header: _withCtx(() => [
      _createVNode(_component_FilterSection, {
        filter: _ctx.module.filter,
        "scheme-small": _ctx.smallFilterLayout,
        "scheme-large": _ctx.bigFilterLayout,
        "has-report": "",
        "has-filter-manager": "",
        "has-pinned-fields": ""
      }, null, 8, ["filter", "scheme-small", "scheme-large"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['kyc-chart-container', { 'kyc-chart-container_has-full': _ctx.fullscreenItemType }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentNameByItem(item)), {
            key: `${item.type}-${_ctx.fullscreenItemType}-${_ctx.theme}`,
            item: item,
            filter: _ctx.module.filter.current,
            full: _ctx.fullscreenItemType === item.type,
            onActivate: ($event: any) => (_ctx.activateItem(item)),
            onShowFullscreen: ($event: any) => (_ctx.fullscreenToggle(item)),
            onDownload: ($event: any) => (_ctx.downloadChartImage(item, $event))
          }, null, 8, ["item", "filter", "full", "onActivate", "onShowFullscreen", "onDownload"]))
        }), 128))
      ], 2)
    ]),
    _: 1
  }))
}