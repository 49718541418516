import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "reports-page-download-dialog__content" }
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportsPageDownloaderProgressBar = _resolveComponent("ReportsPageDownloaderProgressBar")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_ctx.isModalVisible)
    ? (_openBlock(), _createBlock(_component_NModalWindow, {
        key: 0,
        class: "reports-page-download-dialog",
        label: _ctx.header,
        layout: "",
        "close-disabled": "",
        "close-icon": false
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "reports-page-download-dialog__status label-s",
              textContent: _toDisplayString(_ctx.status)
            }, null, 8, _hoisted_2),
            _createVNode(_component_ReportsPageDownloaderProgressBar, {
              value: _ctx.progress.percentage
            }, null, 8, ["value"])
          ])
        ]),
        _: 1
      }, 8, ["label"]))
    : _createCommentVNode("", true)
}