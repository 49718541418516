/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type VideosFilter = {
    /**
     * Select videos included in these cases
     */
    case_in?: Array<number | null>;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select video archives with this `name` field
     */
    name?: string;
    /**
     * Which field to use when ordering the results. Available fields: `id`, `created_date`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select video archives with this `save_to` field
     */
    save_to?: string;
    name_contains?: string;
}
export const EmptyVideosFilter: VideosFilter = {
    case_in: [],
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    limit: '20',
    name: undefined,
    ordering: '-id',
    page: undefined,
    save_to: undefined,
}