
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { SearchModule } from '@/pages/search/searchModule';
import NHint from '@/uikit/hint/NHint.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NInput from '@/uikit/input/NInput.vue';
import { SearchFromMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';

@Options({
  name: 'SearchFromSelect',
  components: { NInput, NButtonGroup, NHint }
})
export default class SearchFromSelect extends Vue {
  @Prop({ type: Object, required: true })
  readonly searchModule!: SearchModule;

  private loadTimeoutId = 0;

  public get state() {
    return this.searchModule.state;
  }

  public get hasError() {
    return this.searchModule.hasError;
  }

  public get availableSearchFromItems() {
    return this.searchModule.availableSearchFromItems;
  }

  public get availableObjectsItems() {
    return this.searchModule.availableObjects
      .map((value) => ({ value, label: this.$t(`search.${value}`) }))
      .filter((item) => (this.state.searchFrom === SearchFromMap.Clusters ? item.value !== 'cars' : item));
  }

  public get isFile() {
    return this.state.searchFrom === SearchFromMap.File;
  }

  public get objectType() {
    switch (this.state.searchFrom) {
      case SearchFromMap.Cards:
        return this.state.cardType;
      default:
        return this.state.objectType;
    }
  }

  public set objectType(v: string) {
    switch (this.state.searchFrom) {
      case SearchFromMap.Cards:
        this.state.cardType = v;
        break;
      default:
        this.state.cardType = dataAssetsModule.getCardTypeByObjectType(v);
        this.state.objectType = v;
    }
  }

  @Watch('availableObjectsItems')
  availableObjectsItemsWatcher(newItems: { label: any; value: string }[]) {
    const availableValues = newItems.map((item) => item.value);
    if (!availableValues.includes(this.state.objectType) && availableValues.length) {
      this.objectType = availableValues[0];
    }
  }

  @Watch('state.id')
  @Watch('state.searchFrom')
  @Watch('state.objectType')
  @Watch('state.cardType')
  tryLoadItemOrImage(v: string) {
    clearTimeout(this.loadTimeoutId);
    this.loadTimeoutId = window.setTimeout(() => this.searchModule.tryLoadItemOrImage(v), 300);
  }
}
