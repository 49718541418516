export const HttpCodes = {
  BadParam: 400,
  NotFound: 404,
  Unauthorized: 401,
  Forbidden: 403,
  Conflict: 409,
  UnprocessableEntity: 422,
  ServerError: 500,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504
};
