import { isFunction, isObject } from '../../pages/login/common';

const SupportedVideoTypes = ['video/mp4', 'video/webm;codecs=vp8', 'video/webm;codecs=vp9', 'video/webm;codecs=h264'];
const VideoInputConstraints = {
  width: { max: 1920 },
  height: { max: 1080 },
  frameRate: { min: 10, max: 29 }
};

export async function createVideoStream() {
  return navigator.mediaDevices.getUserMedia({ video: VideoInputConstraints });
}

export function disposeVideoStream(stream: MediaStream) {
  stream.getVideoTracks().forEach((track) => track.stop());
}

export function recordVideo(stream: MediaStream, length: number) {
  const recorder = new MediaRecorder(stream, {
    mimeType: computeSupportedVideoType()
  });
  setTimeout(() => recorder.state !== 'inactive' && recorder.stop(), length);
  return new Promise<Blob>((resolve, reject) => {
    recorder.ondataavailable = ({ data }) => resolve(data);
    recorder.onerror = reject;
    recorder.start();
  });
}

export function getIsUserAgentSupportVideoRecording() {
  return isMediaDevicesApiAvailable() && isMediaRecorderApiAvailable() && !!computeSupportedVideoType();
}

function isMediaDevicesApiAvailable() {
  return isObject(navigator.mediaDevices) && isFunction(navigator.mediaDevices.getUserMedia);
}

function isMediaRecorderApiAvailable() {
  return typeof MediaRecorder === 'function';
}

function computeSupportedVideoType() {
  return SupportedVideoTypes.find((codec) => MediaRecorder.isTypeSupported(codec));
}

export async function getCameras(): Promise<MediaDeviceInfo[]> {
  if (!isMediaDevicesApiAvailable()) return [];
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter((device) => device.kind === 'videoinput');
}

export async function getHasCameraAccess(): Promise<boolean> {
  const cameras = await getCameras();
  return !!cameras.find((camera) => camera.label !== '');
}
