import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "video-player-render" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.app.settings.debug)
      ? (_openBlock(), _createElementBlock("div", {
          key: _ctx.logsCount + '_logs',
          class: "video-player-render__debug"
        }, [
          _createElementVNode("div", null, "Current time: " + _toDisplayString(_ctx.video?.currentTime?.toFixed(2)), 1),
          _createElementVNode("div", null, "Source buffer: " + _toDisplayString(_ctx.getSourceBufferLength()?.toFixed(2)), 1),
          _createElementVNode("div", null, "Active buffer: " + _toDisplayString(_ctx.getActiveSourceBufferLength()?.toFixed(2)), 1),
          _createElementVNode("div", null, "Difference time: " + _toDisplayString(_ctx.getDiffTime()?.toFixed(2)), 1),
          _createElementVNode("div", null, "Quality: drops " + _toDisplayString(_ctx.getQuality()?.droppedVideoFrames) + ", total " + _toDisplayString(_ctx.getQuality()?.totalVideoFrames) + " frames", 1)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("video", {
      ref: "video",
      autoplay: "",
      muted: "",
      onTimeupdate: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.timeupdate && _ctx.timeupdate(...args)))
    }, null, 544), [
      [_vShow, this.wsUrl]
    ])
  ]))
}