/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VerifyResult } from '../models/VerifyResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class VerifyService {
    /**
     * Compare two objects or object with object inside card and return similarity
     * Compare two objects from different sources or object with a object within the same card and return the similarity between them.
     * @param object1 This field can contain one of the following references:
     * - `detection:<detection id>` - use a `Detection` object
     * - `faceevent:<event id>` - use `FaceEvent`
     * - `carevent:<event id>` - use `CarEvent`
     * - `bodyevent:<event id>` - use `BodyEvent`
     * - `faceobject:<face id>` - use `FaceObject`
     * - `carobject:<face id>` - use `CarObject`
     * - `bodyobject:<face id>` - use `BodyObject`
     *
     * @param cardId This field accepts the id of the card that holds object to compare.
     * @param object2 Same as object1
     * @returns VerifyResult
     * @throws ApiError
     */
    public static verifyRetrieve(
        object1: string,
        cardId?: string,
        object2?: string,
    ): CancelablePromise<VerifyResult> {
        return __request({
            method: 'GET',
            path: `/verify`,
            query: {
                'card_id': cardId,
                'object1': object1,
                'object2': object2,
            },
        });
    }
}