import { isStringWithValue } from '@/common/utils';
import { computed, reactive } from 'vue';
import { Report, ReportArchiveFileName, ReportDownloader, ReportDownloadProgress, ReportFormat } from './services';

export function useReportsPageDownloaderViewModel(downloader: ReportDownloader) {
  const isModalVisible = computed(isDownloadingInProgress);
  const progress = reactive<ReportDownloadProgress>(createDefaultReportDownloadProgress());

  function download(report: Report, format: ReportFormat): Promise<void> {
    return downloader.download(report, format, { onProgress: handleReportDownloadProgress }).finally(clear);
  }

  function downloadAllInArchive(reports: Report[], name: ReportArchiveFileName): Promise<void> {
    return downloader.downloadAllInArchive(reports, name, { onProgress: handleReportDownloadProgress }).finally(clear);
  }

  function handleReportDownloadProgress(update: ReportDownloadProgress): void {
    Object.assign(progress, update);
  }

  function isDownloadingInProgress(): boolean {
    return isStringWithValue(progress.name);
  }

  function clear(): void {
    handleReportDownloadProgress(createDefaultReportDownloadProgress());
  }

  function createDefaultReportDownloadProgress(): ReportDownloadProgress {
    return { name: '', percentage: 0, position: 0, summary: 0 };
  }

  return {
    download,
    downloadAllInArchive,
    isModalVisible,
    progress
  };
}
