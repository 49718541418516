
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NDateTimeLabel, NButton } from '@/uikit';
import { RemoteMonitoringEvent } from '@/api/models/RemoteMonitoringEvent';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { viewModelRepository } from '@/api/common';
import CardItemTile from '@/components/cards/CardItemTile.vue';
import { PuppeteerService } from '@/api/services/PuppeteerService';
import { convertSomeBoxToBox } from '@/uikit/bbox/helpers';
import { SomeBox } from '@/uikit/bbox/types';
import ConfidenceDelimiter from '@/components/common/ConfidenceDelimiter.vue';
import { RouterModule } from '@/store/router';
import { aclModule } from '@/store/acl';

@Options({
  name: 'RemoteMonitoringEventItem',
  components: { ConfidenceDelimiter, CardItemTile, NThumbnail, NButton, NDateTimeLabel },
  emits: ['showImage']
})
export default class RemoteMonitoringEventItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: RemoteMonitoringEvent;

  @Prop({ type: String, default: '' })
  reasons!: string;

  private cardModule = viewModelRepository.getCardsHumansItemViewModel();
  private hasCard = true;

  get card() {
    return this.cardModule.item;
  }

  get modelAcl() {
    return aclModule.getModelAclByName('humancard');
  }

  navigateToCard() {
    this.card?.id && RouterModule.navigateToCard(this.card.id, 'humans');
  }

  mounted() {
    this.loadCard();
  }

  showItemImage() {
    this.$emit('showImage', this.computeViewerItem(this.item));
  }

  showCardImage() {
    let objectsVM = this.$refs.card.objectsVM;
    if (objectsVM && objectsVM.items?.length) {
      this.$emit('showImage', this.computeViewerItemByObject(objectsVM.items[0]));
    }
  }

  loadCard() {
    if (this.item.card) {
      try {
        this.cardModule.get(this.item.card);
      } catch (e) {
        this.hasCard = false;
      }
    } else {
      this.hasCard = false;
    }
  }

  async acknowledge() {
    await PuppeteerService.puppeteerRemoteMonitoringEventsAcknowledgeCreate(this.item.id);
    Object.assign(this.item, { acknowledged: true });
  }

  computeViewerItem(item: RemoteMonitoringEvent) {
    return {
      src: item.fullframe,
      bboxes: [{ box: convertSomeBoxToBox(item.bbox as SomeBox), meta: {}, selected: true }]
    };
  }

  computeViewerItemByObject(item: any) {
    return {
      src: item.source_photo,
      bboxes: [{ box: convertSomeBoxToBox(item), meta: {}, selected: true }]
    };
  }
}
