/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CardsCarsFilter = {
    /**
     * Select only active (`true`) or disabled (`false`) cards
     */
    active?: boolean;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select only filled (`true`) or infilled (`false`) cards
     */
    filled?: boolean;
    /**
     * Select only cards with linked car objects (`true`) or without them (`false`)
     */
    has_car_objects?: string;
    /**
     * Select cards with provided ids
     */
    id_in?: Array<number>;
    /**
     * Select cards where the SQL condition `license_plate_number ILIKE license_plate_number_contains` is true
     */
    license_plate_number_contains?: string;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select cards that contain object similar to: (`detection:<detection id>`, `{face, body, or car}event:<event id>` `{face, body, or car}object:<object id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`)
     */
    looks_like?: string;
    /**
     * Select cards where the SQL condition `name ILIKE name_contains` is true
     */
    name_contains?: string;
    /**
     * Which field to use when ordering the results. Available fields: `created_date`, `id`, `looks_like_confidence`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select cards included in these relations
     */
    relation?: Array<number>;
    /**
     * Similarity threshold for `looks_like`
     */
    threshold?: number;
    /**
     * Select cards included in these watch lists
     */
    watch_lists?: Array<number>;
}
export const EmptyCardsCarsFilter: CardsCarsFilter = {
    active: undefined,
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    filled: undefined,
    has_car_objects: undefined,
    id_in: [],
    license_plate_number_contains: undefined,
    limit: '20',
    looks_like: undefined,
    name_contains: undefined,
    ordering: undefined,
    page: undefined,
    relation: [],
    threshold: undefined,
    watch_lists: [],
}
