
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AnyEpisode, AnyEvent, EventOrEpisode } from '@/components/events/types';
import { LineAttributePayload, LineDirection } from '@/components/common/types';
import AttributeLineCross from '@/components/common/AttributeLineCross.vue';

function getEpisodeLineAttributes(episode: AnyEpisode): LineAttributePayload[] {
  return episode.lines.map((v) => ({
    line: v.line as number,
    backward_line_crossing: v.backward_line_crossing,
    line_crossing_direction: v.line_crossing_direction as LineDirection
  }));
}

function getEventLineAttributes(event: AnyEvent): LineAttributePayload[] {
  return [
    {
      line: event.line as number,
      backward_line_crossing: event.backward_line_crossing,
      line_crossing_direction: event.line_crossing_direction as LineDirection
    }
  ];
}

@Options({
  name: 'EventItemAttributeLineCross',
  components: { AttributeLineCross },
  emits: []
})
export default class EventItemAttributeLineCross extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: EventOrEpisode;

  @Prop({ type: String, required: true })
  readonly classes!: string;

  @Prop({ type: Number, default: 0 })
  readonly maxCount!: number;

  @Prop({ type: Boolean, default: true })
  readonly showMaxCountOverflow!: boolean;

  get attributeItems() {
    if ((this.item as AnyEpisode).lines) {
      return getEpisodeLineAttributes(this.item as AnyEpisode);
    }
    return getEventLineAttributes(this.item as AnyEvent);
  }

  get maxAttributeItems() {
    if (this.maxCount > 0) {
      return this.attributeItems.slice(0, this.maxCount);
    }
    return this.attributeItems;
  }

  get overflowItemsCount() {
    return this.attributeItems.length - this.maxAttributeItems.length;
  }

  get computedShowMaxCountOverflow() {
    return this.showMaxCountOverflow && this.overflowItemsCount > 0;
  }

  get computedItemClasses() {
    if (this.computedShowMaxCountOverflow) {
      return `event-attribute-line-cross__item_with-count ${this.classes}`;
    }
    return this.classes;
  }
}
