
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import ImageViewer from '@/components/image-viewer/ImageViewer.vue';
import DetectionModule from './DetectionModule';
import NButton from '@/uikit/buttons/NButton.vue';
import { deleteByItem } from '@/common/deleteFromArray';
import { BboxConfig } from '@/uikit/bbox/types';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';

@Options({
  name: 'DetectionImageViewer',
  components: { NLoadingCircle, NButton, ImageViewer, NModalWindow },
  emits: ['selectedBboxes', 'close', 'notFound']
})
export default class DetectionImageViewer extends Vue {
  @Prop({ type: Object, required: true })
  readonly imageFile!: any;

  @Prop({ type: Object })
  readonly options?: any;

  @Prop({ type: Boolean, default: false })
  readonly multiple!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly selectByDefault!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly enableLoading!: boolean;

  @Prop({ type: Array })
  readonly actions?: string[];

  private loading = false;

  private selectedIds: string[] = [];

  private detectionModule;

  constructor(props: any) {
    super(props);
    this.detectionModule = new DetectionModule(this.options);
  }

  get convertationInProgress() {
    return this.detectionModule.convertationInProgress;
  }

  get selectableBboxes(): BboxConfig[] {
    return this.detectionModule.bboxes.map((item: BboxConfig) => {
      return {
        ...item,
        selected: Boolean(item.meta?.id && this.selectedIds.includes(item.meta.id as string))
      };
    });
  }

  get selectedBboxes() {
    return this.selectableBboxes.filter((item: BboxConfig) => item.selected);
  }

  setSelectedBBoxByDefault() {
    if (this.selectByDefault) {
      this.selectedIds = [this.selectableBboxes[0].meta.id as any];
      this.$emit('selectedBboxes', [this.selectedBboxes[0]]);
    }
  }

  selectBbox(value: BboxConfig) {
    let id = value.meta.id && String(value.meta.id);
    if (!id) {
      console.warn('Can\'t select bbox without meta id.');
      return;
    }
    if (this.selectedIds.includes(id)) {
      deleteByItem(this.selectedIds, id);
    } else {
      if (!this.multiple) {
        this.selectedIds = [];
      }
      this.selectedIds.push(id);
    }
    this.$emit('selectedBboxes', this.selectedBboxes);
  }

  closeHandler() {
    this.$emit('close');
  }

  @Watch('imageFile')
  async detect() {
    this.detectionModule.bboxes = [];
    try {
      this.loading = this.enableLoading;
      const image = await this.detectionModule.tryConvert(this.imageFile);
      await this.detectionModule.detect(image);
      if (this.selectableBboxes.length) {
        this.setSelectedBBoxByDefault();
      } else {
        this.$emit('notFound');
      }
    } catch (e) {
      this.$emit('notFound');
      console.warn('[detect error]', e);
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    await this.detect();
  }
}
