export default {
  date_multiselect_warning: 'Сортировка по дате не поддерживается, если выбрано несколько объектов.',
  drag_and_drop_files: 'перетащите файл для загрузки или',
  select_files: 'выберите файл',
  search: 'поиск',
  cases: 'происшествие',
  cards: 'карточка',
  events: 'событие',
  clusters: 'кластер',
  define_search_source: 'Задайте источник поиска',
  enter_search_objects: 'Введите URL фото в Интернете или ID/URL внутренней сущности',
  enter_search_objects_holder: 'введите ID или URL',
  upload_text: 'или загрузите медиафайл. Поддерживаемые форматы: JPG, PNG, GIF',
  input_error: 'Совпадения не найдены',
  search_all: 'Искать всё',
  faces: 'Лица',
  bodies: 'Силуэты',
  cars: 'Транспортные средства',
  humans: 'Люди',
  include_cluster: 'Поиск по событиям кластера',
  select_all: 'Выбрать все',
  deselect_all: 'Убрать все',
  apply: 'Применить',
  cluster_letter: 'К',
  upload: 'загрузить',
  select_another_object: 'выбрать другой объект'
};
