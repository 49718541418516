import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "data-sources-onvif-discovery" }
const _hoisted_2 = { class: "data-sources-onvif-discovery__header" }
const _hoisted_3 = {
  key: 0,
  class: "heading-m"
}
const _hoisted_4 = { class: "data-sources_next-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NTable = _resolveComponent("NTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (this.items.length)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.counter), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_NButton, {
        type: "link",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.load())),
        icon: "table-action-refresh",
        class: _normalizeClass({ 'data-sources-onvif-discovery_loading': _ctx.loading })
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('ds.discover_again')), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createVNode(_component_NTable, {
      columns: _ctx.columns,
      content: _ctx.items,
      selected: _ctx.selectedItems,
      "onUpdate:selected": _ctx.handleSelect,
      selections: "",
      hoverable: ""
    }, null, 8, ["columns", "content", "selected", "onUpdate:selected"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_NButton, {
        suffixIcon: "data-sources-next",
        onClick: _ctx.next,
        disabled: _ctx.selectedItems.length === 0
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.ds_next')), 1)
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ])
  ]))
}