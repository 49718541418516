import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-qa"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([`n-tag n-tag_${_ctx.type}`, { 'n-tag_closeable': _ctx.closeable }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)), ["stop"])),
    "data-qa": _ctx.dataQa
  }, [
    (_ctx.color)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "n-tag__color",
          style: _normalizeStyle({ backgroundColor: _ctx.color })
        }, null, 4))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`n-tag__label label-${_ctx.labelSize}`)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ])
    ], 2),
    (_ctx.closeable)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 1,
          class: "n-tag__close",
          name: "close_small",
          onClick: _withModifiers(_ctx.handleClose, ["stop"]),
          width: "8",
          height: "20",
          "data-qa": `${_ctx.dataQa}.close`
        }, null, 8, ["onClick", "data-qa"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}