import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "cluster-wizard-item" }
const _hoisted_2 = {
  key: 0,
  class: "cluster-wizard-item__looks-like label-xs"
}
const _hoisted_3 = {
  key: 1,
  class: "cluster-wizard-item__hover"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.confidence)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.formatedConfidence), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.canAdd)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_NButton, {
            icon: "participant-add",
            type: "secondary",
            widen: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('action', 'add')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('cases.add')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}