
import { computed, defineComponent, reactive, toRefs, PropType } from 'vue';
import dayjs from './dayjs';
import { NButton } from './..';
import NYearPicker from './NYearPicker.vue';
import NMonthPicker from './NMonthPicker.vue';
import { IPickerDate, IWeek } from './types';
import { capitalize } from 'lodash';

export default defineComponent({
  components: { NMonthPicker, NYearPicker, NButton },
  props: {
    modelValue: {
      type: Object as PropType<Date>
    },
    firstDayOfWeek: {
      type: [String, Number],
      default: 1,
      validator: (v: string | number) => {
        if (typeof v === 'string') {
          v = Number(v);
        }

        return v >= 0 && v <= 6;
      }
    },
    selectedDates: {
      type: Array as PropType<(string | Date)[]>,
      default: () => []
    },
    min: {
      type: [Number, String, Date]
    },
    max: {
      type: [Number, String, Date]
    },
    isDateDisabled: {
      type: Function
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const state = reactive({
      date: props.modelValue && props.modelValue instanceof Date ? dayjs(props.modelValue) : dayjs(),
      currentDate: dayjs().startOf('day').valueOf()
    });

    const orderedWeekDayIndexes = computed(() => {
      let i = 7;
      let days: number[] = [];
      let num = +props.firstDayOfWeek;
      while (i--) {
        if (num > 6) num = 0;
        days.push(num++);
      }

      return days;
    });

    const startDate = computed(() => {
      let date = state.date.startOf('month'),
        day = date.day(),
        dayOffset = orderedWeekDayIndexes.value.findIndex((v) => v === day);
      return state.date.startOf('month').subtract(dayOffset, 'day');
    });

    const month = computed(() => state.date.month());

    const year = computed(() => state.date.year());

    const weeks = computed(() => {
      let date = startDate.value;
      let weeks: IWeek[] = [];
      for (let i = 0; i < 6; i++) {
        let weekDates: IWeek = [];
        for (let j = 0; j < 7; j++) {
          weekDates.push({ timestamp: date.valueOf(), label: date.date().toString() });
          date = date.add(1, 'day');
        }
        weeks.push(weekDates);
      }
      return weeks;
    });

    const weekDays = computed(() => dayjs.localeData().weekdaysMin().map(capitalize));

    const maxNumber = computed(() => (props.max ? dayjs(props.max).endOf('day').valueOf() : 0));
    const minNumber = computed(() => (props.min ? dayjs(props.min).startOf('day').valueOf() : 0));
    const selectedDatesMap = computed(() => {
      let datesMap: { [key: number]: boolean } = {};
      if (props.selectedDates && props.selectedDates.length) {
        for (let i in props.selectedDates) {
          let date = dayjs(props.selectedDates[i]);
          if (date.isValid()) datesMap[date.startOf('day').valueOf()] = true;
        }
      }
      return datesMap;
    });

    const checkSelected = (date: IPickerDate) => date.timestamp in selectedDatesMap.value;

    const checkDisabled = (date: IPickerDate) => {
      const isDisabledByCallback = props.isDateDisabled ? props.isDateDisabled(date.timestamp) : false;
      return isDisabledByCallback || (minNumber.value > 0 && date.timestamp < minNumber.value) || (maxNumber.value > 0 && date.timestamp > maxNumber.value);
    };

    const monthEdges = computed<[number, number]>(() => {
      return [state.date.startOf('month').valueOf(), state.date.endOf('month').valueOf()];
    });

    const getDateClass = (date: IPickerDate) => {
      let base = 'n-date-picker__month-date';
      return {
        [`${base} label-mono-xs`]: true,
        [`${base}_current_date`]: date.timestamp === state.currentDate,
        [`${base}_current_month`]: date.timestamp >= monthEdges.value[0] && date.timestamp <= monthEdges.value[1],
        [`${base}_selected`]: checkSelected(date),
        [`${base}_disabled`]: checkDisabled(date)
      };
    };

    const selectHandler = (date: IPickerDate) => !checkDisabled(date) && emit('update:modelValue', new Date().setTime(date.timestamp));
    const setNextYear = () => (state.date = state.date.add(1, 'year'));
    const setYear = (year: number) => (state.date = state.date.year(year));
    const setPrevYear = () => (state.date = state.date.subtract(1, 'year'));
    const setNextMonth = () => (state.date = state.date.add(1, 'month'));
    const setMonth = (month: number) => (state.date = state.date.month(month));
    const setPrevMonth = () => (state.date = state.date.subtract(1, 'month'));

    return {
      ...toRefs(state),
      ...toRefs(props),
      startDate,
      month,
      year,
      weeks,
      weekDays,
      orderedWeekDayIndexes,
      getDateClass,
      checkDisabled,
      selectHandler,
      setNextYear,
      setYear,
      setPrevYear,
      setNextMonth,
      setMonth,
      setPrevMonth
    };
  }
});
