
import { computed, defineComponent, PropType, reactive, toRefs, watch } from 'vue';
import dayjs, { Dayjs } from './dayjs';
import NDateInput from './NDateInput.vue';
import NDateIntervalSelect from './NDateIntervalSelect.vue';
import NTimeInput from './NTimeInput.vue';
import { IDateIntervalItem, IDateRangeSource, IDateSource, IDateTimePlaceholder } from './types';
import NButton from '../buttons/NButton.vue';

export enum DateTimeRangeMode {
  Date = 'date',
  Time = 'time',
  DateTime = 'dateTime'
}

export default defineComponent({
  components: { NButton, NDateIntervalSelect, NDateInput, NTimeInput },
  props: {
    modelValue: { type: [Number, String, Date, Array] as PropType<IDateRangeSource> },
    min: { type: [Number, String, Date] },
    max: { type: [Number, String, Date] },
    secondsEnabled: { type: Boolean, default: false },
    dateFormat: { type: String, default: 'DD.MM.YYYY' },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    accesskey: { type: String },
    placeholders: { type: Array as PropType<IDateTimePlaceholder[]>, default: () => [{}, {}] },
    clearable: { type: Boolean, default: false },
    clearButton: { type: Boolean, default: true },
    plain: { type: Boolean },
    mode: {
      type: String as PropType<DateTimeRangeMode>,
      default: DateTimeRangeMode.Date
    },
    intervals: {
      type: Array as PropType<IDateIntervalItem[]>,
      default: () => []
    },
    dataQa: { type: String }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const state = reactive({
      from: null as Dayjs | null,
      to: null as Dayjs | null
    });

    watch(() => props.modelValue, valueHandler, { immediate: true });
    function valueHandler(v: IDateRangeSource | undefined) {
      const parseValue = (v: IDateSource | undefined): Dayjs | null => {
        if (v === undefined || v === '') return null;
        let date = dayjs(v);
        return date.isValid() ? date : null;
      };

      if (Array.isArray(v)) {
        let [from, to] = [...v.slice(0, 2).map(parseValue)];
        state.from = from;
        state.to = to;
      } else {
        state.from = parseValue(v);
        state.to = parseValue(v);
      }
    }

    const timeEnabled = computed(() => props.mode === DateTimeRangeMode.DateTime);

    const componentName = computed(() => {
      switch (props.mode) {
        case DateTimeRangeMode.Time:
          return 'NTimeInput';
        case DateTimeRangeMode.Date:
        case DateTimeRangeMode.DateTime:
        default:
          return 'NDateInput';
      }
    });

    const minDate = computed(computeMinDate);
    const maxDate = computed(computeMaxDate);

    function computeMinDate() {
      return Math.max(...computeMinDateSet()) || null;
    }

    function computeMaxDate() {
      return Math.max(...computeMaxDateSet()) || null;
    }

    function computeMinDateSet() {
      const dates = [props.min ?? null, state.from];
      return dates.map((date) => dayjs(date).valueOf() || 0);
    }

    function computeMaxDateSet() {
      const dates = [props.max ?? null, state.to];
      return dates.map((date) => dayjs(date).valueOf() || 0);
    }

    function reset() {
      state.from = state.to = null;
      emitChange();
    }

    const result = computed(() => {
      let { from, to } = state;
      return [from, to].map((v) => v?.toDate() || null);
    });

    const isValid = computed(() => {
      let { to, from } = state;
      if (from && to) {
        return from.isValid() && to.isValid() && from.isBefore(to);
      }
      return (from?.isValid() || from === null) && (to?.isValid() || to === null);
    });

    const intervalSelectHandler = (interval: [Date, Date]) => {
      state.from = dayjs(interval[0]);
      state.to = dayjs(interval[1]);
      emitChange();
    };

    const fromChangeHandler = (date: Date | null) => {
      state.from = date ? dayjs(date) : null;
      emitChange();
    };

    const toChangeHandler = (date: Date | null) => {
      let resetToEndOfDay = !state.to && date && props.mode !== DateTimeRangeMode.Time;
      state.to = date ? dayjs(date) : null;
      resetToEndOfDay && (state.to = state.to!.endOf('day'));
      emitChange();
    };

    function emitChange() {
      isValid.value && emit('update:modelValue', result.value);
    }

    return {
      ...toRefs(props),
      ...toRefs(state),
      componentName,
      fromChangeHandler,
      intervalSelectHandler,
      reset,
      maxDate,
      minDate,
      timeEnabled,
      toChangeHandler
    };
  }
});
