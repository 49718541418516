import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "data-sources-devices" }
const _hoisted_2 = { class: "data-sources-devices__title" }
const _hoisted_3 = { class: "data-sources_next-button data-sources-devices-next_buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('ds.connection_external', 'f')), 1),
    _createVNode(_component_NForm, {
      layout: _ctx.layout,
      model: _ctx.model,
      ref: "form"
    }, null, 8, ["layout", "model"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NButton, {
        type: "primary",
        suffixIcon: "data-sources-next",
        onClick: _ctx.createAndConfig
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('ds.add_and_config')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_NButton, {
        type: "secondary",
        suffixIcon: "data-sources-next",
        onClick: _ctx.onlyCreateCameras
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('ds.add')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}