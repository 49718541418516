import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTooltip = _resolveComponent("NTooltip")!

  return (!!_ctx.error)
    ? (_openBlock(), _createBlock(_component_NTooltip, {
        key: 0,
        reference: _ctx.reference,
        "auto-placement": "",
        "allowed-auto-placement": _ctx.allowedPlacements
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }, 8, ["reference", "allowed-auto-placement"]))
    : _createCommentVNode("", true)
}