import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "cluster-item-proxy__empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClusterItem = _resolveComponent("ClusterItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cluster-item-proxy", _ctx.classes])
  }, [
    (_ctx.display)
      ? (_openBlock(), _createBlock(_component_ClusterItem, _mergeProps({ key: 0 }, _ctx.$props, {
          onAction: _cache[0] || (_cache[0] = (p) => _ctx.$emit('action', p)),
          onSelect: _cache[1] || (_cache[1] = (p) => _ctx.$emit('select', p)),
          onSelectCard: _cache[2] || (_cache[2] = (p) => _ctx.$emit('select-card', p))
        }), null, 16))
      : (_openBlock(), _createElementBlock("div", _hoisted_1))
  ], 2))
}