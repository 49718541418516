import { IFormLayout } from '@/uikit/forms/NForm.vue';
import { NDateInput, NInput } from '@/uikit';
import Permissions from '@/components/permissions/deprecated/Permissions.vue';

export const informationLayout: IFormLayout = [
  [
    {
      path: 'name',
      classes: 'n-form-w-4 n-form-pad-10 control-m',
      i18n_label: 'common.name',
      component: NInput,
      validators: [{ name: 'required' }]
    },
    {
      path: 'incident_date',
      classes: 'n-form-w-2 n-form-pad-10 n-form-ml-1 control-m',
      i18n_label: 'cases.incident_date',
      component: NDateInput,
      validators: [{ name: 'required' }]
    }
  ],
  {
    path: 'comment',
    classes: 'n-form-w-6 n-form-pad-10 control-m',
    i18n_label: 'common.comment',
    component: NInput
  },
  [
    {
      path: 'record_id',
      classes: 'n-form-w-4 n-form-pad-10 control-m',
      i18n_label: 'cases.record_id',
      component: NInput,
      // validators: [{ name: 'required' }] // @todo waiting server
    },
    {
      path: 'record_created_date',
      classes: 'n-form-w-2 n-form-pad-10 n-form-ml-1 control-m',
      i18n_label: 'cases.record_date',
      component: NDateInput,
      // validators: [{ name: 'required' }] // @todo waiting server
    }
  ]
];

export const permissionsLayout: IFormLayout = [
  {
    path: 'permissions',
    classes: 'n-form-w-6',
    component: Permissions,
    props: {
      hasDelete: true,
      disabledIds: [1]
    }
  }
];
