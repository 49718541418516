
import { Camera, CameraGroup } from '@/api';
import { Options, Prop, Vue } from 'vue-property-decorator';
import VideoWallCameraListGroup from './VideoWallCameraListGroup.vue';

@Options({
  components: { VideoWallCameraListGroup }
})
export default class VideoWallCameraList extends Vue {
  @Prop({ required: true, type: Array })
  readonly cameras!: Camera[];
  @Prop({ required: true, type: Array })
  readonly groups!: CameraGroup[];

  selectCamerasByGroup(group: CameraGroup): Camera[] {
    return this.cameras.filter((camera) => camera.group === group.id);
  }
}
