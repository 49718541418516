
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { dataModule } from '@/store/data';
import NImageViewerLineLayer from '@/uikit/image-viewer/NImageViewerLineLayer.vue';
import VideoPlayerState from '@/components/video-player/VideoPlayerState';

@Options({
  name: 'VideoPlayerLineLayer',
  components: { NImageViewerLineLayer }
})
export default class VideoPlayerLineLayer extends Vue {
  @Prop({ type: Object, required: true })
  readonly state!: VideoPlayerState;

  @Prop({ type: Number, required: true })
  readonly containerWidth!: number;

  lines: any = [];

  get scale() {
    if (this.cameraWidth) {
      return this.containerWidth / this.cameraWidth;
    }
    return 0;
  }

  get cameraWidth() {
    if (!this.state.camera) {
      return 0;
    }
    return this.state.camera.frame_width;
  }

  get cameraHeight() {
    if (!this.state.camera) {
      return 0;
    }
    return this.state.camera.frame_height;
  }

  updateLinePoints() {
    const lines = dataModule.linesModule.items.filter((item) => {
      return (
        item.active &&
        ((this.state.model === 'cameras' && item.camera === this.state.cameraId) ||
          (this.state.model === 'videos' && item.video_archive === this.state.cameraId))
      );
    });
    if (lines) {
      this.lines = lines;
    } else {
      console.warn('Lines not found!');
    }
  }

  async mounted() {
    await dataModule.linesModule.firstLoadingPromise;
    this.updateLinePoints();
  }
}
