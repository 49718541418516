import { dataModule } from '@/store/data';
import NSelect from '@/uikit/select/NSelect.vue';

export const sessionsFilters = [
  {
    component: NSelect,
    path: 'user_name_in',
    value: undefined,
    props() {
      return {
        clearIcon: true,
        hideChevron: true,
        multiple: true,
        multiselect: true,
        multiline: true,
        i18n_placeholder: 'common.user',
        items: dataModule.usersModule.items.map((item) => ({ value: item?.name, label: item?.name }))
      };
    }
  },
  {
    component: NSelect,
    path: 'mobile',
    value: undefined,
    props() {
      return {
        clearIcon: true,
        hideChevron: true,
        i18n_placeholder: 'common.device',
        items: [
          { value: 'false', i18n_label: 'common.computer' },
          { value: 'true', i18n_label: 'common.phone' },
          { value: undefined, i18n_label: 'sessions.any_device' }
        ]
      };
    }
  },
  {
    component: NSelect,
    path: 'status',
    value: undefined,
    props() {
      return {
        clearIcon: true,
        hideChevron: true,
        i18n_placeholder: 'common.status',
        items: [
          { value: 'online', i18n_label: 'common.online' },
          { value: 'blocked', i18n_label: 'sessions.blocked' },
          { value: 'offline', i18n_label: 'common.offline' },
          { value: undefined, i18n_label: 'common.any' }
        ]
      };
    }
  }
];
