import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "control-s case-description__date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["case-description", _ctx.classes])
  }, [
    _createElementVNode("div", {
      class: "control-m case-description__link",
      title: _ctx.item.name
    }, _toDisplayString(_ctx.item.name), 9, _hoisted_1),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$filters.formatDate(_ctx.item.incident_date)), 1)
  ], 2))
}