import { SimpleFilterOptions } from '@/components/common/filter/filters/types';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';

export function getCounterFilters(options: SimpleFilterOptions = {}) {
  return [
    commonFilterSchemaModule.getNameContains(options),
    commonFilterSchemaModule.getId({
      ...options,
      i18n_label: 'counters.counter_id',
      i18n_placeholder: 'counters.enter_counter_id'
    }),
    commonFilterSchemaModule.getCameraGroups(options),
    commonFilterSchemaModule.getCamerasDependedOnCameraGroups(options),
    commonFilterSchemaModule.getDistanceType(options),
    commonFilterSchemaModule.getCounterStatus(options)
  ];
}

export function getCounterRecordFilters(options: SimpleFilterOptions = {}, cameraItems: any = [], isWebhook = false) {
  const cameraOptions = cameraItems.length ? { ...options, items: cameraItems } : options;
  return [
    isWebhook ? commonFilterSchemaModule.getCameraGroups(options) : undefined,
    commonFilterSchemaModule.getCameras(cameraOptions),
    isWebhook ? undefined : commonFilterSchemaModule.getCreatedDateTimeRange(options),
    isWebhook ? undefined : commonFilterSchemaModule.getHasError(options),
    commonFilterSchemaModule.getCountFaces(options),
    commonFilterSchemaModule.getCountSilhouettes(options),
    commonFilterSchemaModule.getCountCars(options),
    isWebhook
      ? commonFilterSchemaModule.getId({ ...options, path: 'counter', i18n_label: 'counters.counter_id', i18n_placeholder: 'counters.enter_counter_id' })
      : commonFilterSchemaModule.getId({ ...options, i18n_label: 'counters.counter_record_id', i18n_placeholder: 'counters.enter_counter_record_id' }),
    commonFilterSchemaModule.getMinimumDistance(options),
    commonFilterSchemaModule.getMaximumDistance(options),
    commonFilterSchemaModule.getAverageDistance(options)
  ].filter((v) => !!v);
}
