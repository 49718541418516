import { parseMultisidebarType } from '@/store/multisidebar/helpers';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { ItemViewModel } from '@/definitions/view-models';
import { EpisodesMultiToSingle, ObjectsMultiToSingle } from '@/store/application/data.assets';
import { PageTypes } from '@/store/application/page.definitions';
import { AnyEpisode, AnyEvent } from '@/components/events/types';

export const EpisodeUpdatableProperties = [
  'acknowledged',
  'acknowledged_by',
  'acknowledged_date',
  'acknowledged_reaction',
  'closed_date',
  'open',
  'events_count'
];
export function fillExistsItem(existsItem: any, newItem: any, updatableProperties: string[] = []) {
  const newItemPart = updatableProperties.length ? Object.fromEntries(updatableProperties.map((v) => [v, newItem[v]])) : newItem;
  // console.log('>> ', { ...newItemPart }, { ...existsItem }, Object.assign(existsItem, newItemPart));
  return Object.assign(existsItem, newItemPart);
}

export function episodeOrEventWebsocketUpdateHandler(item: MultisidebarItem<ItemViewModel<AnyEpisode | AnyEvent>>, messageProperty: string, messageData: any) {
  switch (messageProperty) {
    case 'event':
    case 'episode':
      itemUpdateHandler(item, messageData);
      break;
    case 'acknowledgedAll':
      acknowledgedAllHandler(item, messageData);
      break;
  }
}

function acknowledgedAllHandler(item: MultisidebarItem<ItemViewModel<AnyEpisode | AnyEvent>>, acknowledged: boolean) {
  if (acknowledged && item.model.originalItem && item.model.item && item.model.item.acknowledged === false) {
    const data = { acknowledged: true, acknowledged_date: new Date().toISOString() };
    Object.assign(item.model.item, data);
    Object.assign(item.model.originalItem, data);
  }
}

function itemUpdateHandler(item: MultisidebarItem<ItemViewModel<AnyEpisode | AnyEvent>>, typedData: Record<any, any>) {
  const [pageType, objectTypes] = parseMultisidebarType(item.type);
  const isEpisode = pageType === PageTypes.Episodes;
  if (!objectTypes) return;
  const objectType = isEpisode ? EpisodesMultiToSingle[objectTypes] : ObjectsMultiToSingle[objectTypes];
  if (!objectType) {
    throw new Error('Unknown object type from websocket message: ' + objectTypes);
  }
  const updatedDataItem = typedData[objectType];
  if (!updatedDataItem) return;
  if (updatedDataItem.id === item.model.item?.id) {
    const updateFields = isEpisode ? EpisodeUpdatableProperties : [];
    fillExistsItem(item.model.item, updatedDataItem, updateFields);
    fillExistsItem(item.model.originalItem, updatedDataItem, updateFields);
  }
}
