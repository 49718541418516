import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "kyc-chart-pager" }
const _hoisted_2 = { class: "label-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.page) + "/" + _toDisplayString(_ctx.pages), 1),
    _createVNode(_component_NButton, {
      type: "text",
      icon: "chevron-left",
      disabled: _ctx.isPrevPageButtonDisabled,
      onAction: _ctx.gotoPrevPage
    }, null, 8, ["disabled", "onAction"]),
    _createVNode(_component_NButton, {
      type: "text",
      icon: "chevron-right",
      disabled: _ctx.isNextPageButtonDisabled,
      onAction: _ctx.gotoNextPage
    }, null, 8, ["disabled", "onAction"])
  ]))
}