import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_checkbox = _resolveComponent("n-checkbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'n-checkbox-group',
      {
        [`n-checkbox-group_` + _ctx.direction]: true,
        [_ctx.customClass]: _ctx.customClass
      }
    ])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_n_checkbox, {
        key: _ctx.getKey(item, index),
        label: item.label,
        modelValue: _ctx.isItemSelected(item),
        disabled: item.disabled || _ctx.disabled,
        "onUpdate:modelValue": (v) => _ctx.handleChange(v, item)
      }, null, 8, ["label", "modelValue", "disabled", "onUpdate:modelValue"]))
    }), 128))
  ], 2))
}