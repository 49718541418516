
import { Options, Prop, Ref, Vue } from 'vue-property-decorator';
import type { VideoWallPageLayoutState } from '../../types';
import { isEpisodeSectionVisible, isSidebarSectionVisible } from './utils';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import { openFullscreen } from '@/common/utils';

@Options({
  components: { Splitpanes, Pane }
})
export default class VideoWallPageLayout extends Vue {
  @Ref('')
  pane!: HTMLDivElement;


  @Prop({ required: true, type: String })
  readonly layoutState!: VideoWallPageLayoutState;

  get isEpisodeSectionVisible(): boolean {
    return isEpisodeSectionVisible(this.layoutState);
  }

  get isSidebarSectionVisible(): boolean {
    return isSidebarSectionVisible(this.layoutState);
  }

  requestFullScreen(): Promise<void> {
    return openFullscreen(this.pane);
  }
}
