
import { Options, Vue } from 'vue-class-component';
import { NButton } from '@/uikit';
import { PropType } from 'vue';
import { FaceEvent, HumanEpisode } from '@/api';
import { Prop } from 'vue-property-decorator';
import { ItemsActionNames } from "@/definitions/app/item.actions.name";

@Options({
  name: 'SimpleReaction',
  components: { NButton }
})
export default class SimpleReaction extends Vue {
  @Prop({ type: Object as PropType<FaceEvent | HumanEpisode>, required: true })
  readonly item!: FaceEvent | HumanEpisode;

  wasAcknowledged = false;

  get showCancel() {
    return this.item.acknowledged;
  }

  toggleAcknowledge() {
    const value = !this.item.acknowledged;
    this.wasAcknowledged = value;
    this.$emit('action', ItemsActionNames.Acknowledge, { value });
  }
}
