
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { CounterRequest, CountersService } from '@/api';
import { viewModelRepository } from '@/api/common';
import NInput from '@/uikit/input/NInput.vue';
import { NButton } from '@/uikit';

// import TypedTimer from 'typedtimer'; // npm i typedtimer
import SimpleTimer from '@/components/video-player/utils/simple-timer';

export const CalibrationStatuses = {
  NOT_CALIBRATED: 'NOT_CALIBRATED',
  AWAITING_SYNC: 'AWAITING_SYNC',
  COLLECTING_CALIBRATION_DATA: 'COLLECTING_CALIBRATION_DATA',
  AWAITING_PROXIMITY_MODEL: 'AWAITING_PROXIMITY_MODEL',
  CALIBRATED: 'CALIBRATED',
  CALIBRATION_FAILED: 'CALIBRATION_FAILED'
};

export const CalibrationStatusesI18n: Record<string, string> = {
  NOT_CALIBRATED: 'counters.calibration_status__not_calibrated',
  AWAITING_SYNC: 'counters.calibration_status__awaiting_sync',
  COLLECTING_CALIBRATION_DATA: 'counters.calibration_status__collecting_calibration_data',
  AWAITING_PROXIMITY_MODEL: 'counters.calibration_status__awaiting_proximity_model',
  CALIBRATED: 'counters.calibration_status__calibrated',
  CALIBRATION_FAILED: 'counters.calibration_status__calibration_failed'
};

@Options({
  name: 'CalibrationForm',
  components: { NButton, NInput }
})
export default class CalibrationForm extends Vue {
  @Prop({ type: Number, required: true })
  readonly id!: number;

  @Prop({ type: String, required: true })
  readonly status!: string;

  public calibrationTime = 60; // InSeconds
  public calibrationStatus = '';
  readonly counterModel = viewModelRepository.getCountersItemViewModel();
  private updateInterval = new SimpleTimer();

  get isDetectSilhouettes() {
    return this.counterModel.originalItem?.detect_silhouettes;
  }

  get canStartCalibration() {
    return (
      this.isDetectSilhouettes &&
      this.calibrationTime &&
      (this.calibrationStatus === CalibrationStatuses.NOT_CALIBRATED || this.calibrationStatus === CalibrationStatuses.CALIBRATED)
    );
  }

  get calibrationStatusI18n() {
    return this.$t(CalibrationStatusesI18n[this.calibrationStatus], 'f');
  }

  async updateStatus() {
    await this.counterModel.get(this.id);
    this.calibrationStatus = String(this.counterModel.item?.proximity_calibration_status);
  }

  async startCalibration() {
    this.calibrationStatus = CalibrationStatuses.AWAITING_SYNC;
    await this.counterModel.get(this.id);
    await CountersService.countersProximityCalibrationCreate(this.calibrationTime, this.id, {} as CounterRequest);
    this.updateInterval.setInterval(() => this.updateStatus(), 5000);
  }

  async stopCalibration() {
    await CountersService.countersProximityCalibrationDestroy(this.id);
    await this.updateStatus();
    this.updateInterval.clear();
  }

  mounted() {
    this.calibrationStatus = this.status;
    if (!this.canStartCalibration) {
      this.updateInterval.setInterval(() => this.updateStatus(), 5000);
    }
  }

  beforeUnmount() {
    this.updateInterval.clear();
  }
}
