/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DeviceBlacklistRecord = {
    readonly id: number;
    /**
     * Record creation date
     */
    readonly created_date: string;
    /**
     * Record expiration date
     */
    expire_date?: string | null;
    /**
     * Device unique identifier
     */
    uuid: string;
    /**
     * Blacklisting reason, up to 512 characters
     */
    reason: string;
};
export const EmptyDeviceBlacklistRecord: DeviceBlacklistRecord = {
    id: 0,
    /**
     * Record creation date
     */
    created_date: '',
    /**
     * Record expiration date
     */
    expire_date: '',
    /**
     * Device unique identifier
     */
    uuid: '',
    /**
     * Blacklisting reason, up to 512 characters
     */
    reason: '',
};
