
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { BodyObjectUpdate, CarCard, CardsService, CarObjectUpdate, FaceObjectUpdate, HumanCard, ObjectsService } from '@/api';
import { BodyCluster } from '@/api/models/BodyCluster';
import { CarCluster } from '@/api/models/CarCluster';
import { FaceCluster } from '@/api/models/FaceCluster';
import { ClusterView } from '@/components/clusters/adapter';
import WatchListLabelsShort from '@/components/common/WatchListsGroup.vue';
import { EventDetails, EventTypes, ThumbnailPositions } from '@/uikit/thumbnail/helpers/enums';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { actions } from '@storybook/addon-actions';
import { ThumbnailAction } from '@/uikit/thumbnail';
import { ClusterOpenedItem, ClusterOpenedItems, ListItem } from '@/components/clusters/types';
import NButton from '@/uikit/buttons/NButton.vue';
import Confidence from '@/components/common/Confidence.vue';
import { ItemsActionName } from '@/definitions/app/item.actions.name';

@Options({
  name: 'ClusterItem',
  components: { NButton, WatchListLabelsShort, NThumbnail, Confidence }
})
export default class ClusterItem extends Vue {
  @Prop({ type: Object, required: true })
  item!: FaceCluster | BodyCluster | CarCluster;

  @Prop({ type: Number })
  index?: number;

  @Prop({ type: String, required: true })
  objects!: string;

  @Prop({ type: String, default: 'clusters' })
  type!: string;

  @Prop({ type: String, default: 'short' })
  displayType!: string;

  @Prop({ type: Boolean, default: false })
  useDefaultActions!: boolean;

  @Prop({ type: Array, default: () => [] })
  actions!: ThumbnailAction[];

  @Prop({ type: String })
  defaultAction?: ItemsActionName;

  @Prop({ type: Boolean, default: false })
  clusterSelected!: boolean;

  @Prop({ type: Boolean, default: false })
  cardSelected!: boolean;

  @Prop({ type: String, default: '' })
  openedItem!: ClusterOpenedItem;

  card: HumanCard | CarCard | null = null;
  matched_object: FaceObjectUpdate | BodyObjectUpdate | CarObjectUpdate | null = null;

  async mounted() {
    if (this.item.card) this.card = await this.loadCard(this.item.card);
    await this.loadMatchedObject();
  }

  get additionalActions(): ListItem[] {
    return [
      { label: this.$t('common.search', 'f'), name: EventDetails.Search },
      { label: this.$t('common.filter_events', 'f'), name: EventDetails.FilterEvents }
    ];
  }

  get EventDetails() {
    return EventDetails;
  }

  get clusterClasses() {
    return {
      'cluster-item_sidebar': this.clusterSelected,
      'cluster-item_selected': this.openedItem === ClusterOpenedItems.Cluster
    };
  }

  get cardClasses() {
    return {
      'cluster-item__card_sidebar': this.cardSelected,
      'cluster-item__card_selected': this.openedItem === ClusterOpenedItems.Card
    };
  }

  get isShort() {
    return this.displayType === 'short';
  }

  get clusterView(): ClusterView {
    return this.item;
  }

  get cardName() {
    return this.card?.name ?? '';
  }

  get computedActions() {
    const defaultActions = [
      { icon: 'check', name: EventTypes.Select, selected: this.clusterSelected, position: ThumbnailPositions.TopLeft },
      { icon: 'info', name: EventDetails.ShowInfo, position: ThumbnailPositions.TopRight },
      { icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight }
    ];
    return this.useDefaultActions ? defaultActions : actions;
  }

  actionHandler(v: string) {
    this.$emit('action', v);
  }

  async loadCard(id: number): Promise<HumanCard | CarCard | null> {
    let result = null;
    if (id) {
      const servicePromise = this.objects === 'cars' ? CardsService.cardsCarsRetrieve(id) : CardsService.cardsHumansRetrieve(id);
      result = await (servicePromise as Promise<HumanCard | CarCard>);
    }
    return result;
  }

  loadMatchedObject() {
    const { matched_object: id } = this.clusterView as any;
    if (id) {
      let servicePromise;
      switch (this.objects) {
        case 'cars':
          servicePromise = ObjectsService.objectsCarsRetrieve(id);
          break;
        case 'bodies':
          servicePromise = ObjectsService.objectsBodiesRetrieve(id);
          break;
        case 'faces':
          servicePromise = ObjectsService.objectsFacesRetrieve(id);
          break;
      }
      servicePromise?.then(
        (v) => (this.matched_object = v),
        (e) => console.warn('[ClusterItem:Matched]', e)
      );
    }
  }

  @Watch('item.card')
  async changeCardHandler(v: number | null) {
    if (!v) {
      this.card = null;
    } else {
      this.card = await this.loadCard(v);
    }
  }

  @Watch('item.matched_object')
  changeMatchedObjectHandler(v: FaceObjectUpdate | BodyObjectUpdate | CarObjectUpdate | null) {
    if (!v) {
      this.matched_object = null;
    } else {
      this.loadMatchedObject();
    }
  }

  selectCard() {
    if (this.card) this.$emit('select-card', this.card?.id);
  }
}
