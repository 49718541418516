export const NColorPickerColorFormats = {
  hex: '#'
};

export enum Events {
  Change = 'change',
  Clear = 'clear',
  Confirm = 'confirm',
  ActiveChange = 'active-change'
}
