import * as _ from 'lodash';
import logger from 'loglevel';
import { reactive, watch } from 'vue';
import { getCleanLangObject, ILanguageData } from '@/store/config/languages';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import * as en from 'i18n/en';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import * as ru from 'i18n/ru';
import { firstCase } from '@/common/filters';
import dayjs from '@/uikit/datetime/dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/pt';
import 'dayjs/locale/es';
import { localStorageModule } from '@/store/application/local.storage';
import { syncWorkspaceModuleToStorage, workspaceModule } from '@/store/application/workspace';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { convertPOTextToJSObject, convertJSObjectToPOText } from '@/common/po';
import { configModule } from '@/store/config';
import { ILanguage } from '@/definitions/config/languages';
import { applicationModule } from '@/store/application';
import { userStorageModule } from '@/store/application/user.storage';
import { IntlMessageFormat } from 'intl-messageformat';

interface ILanguageModuleState {
  items: readonly ILanguageData[];
  locale: string;
  defaultLocale: string;
}

const messageFormats: Record<string, Record<string, IntlMessageFormat>> = {};
const messageFormatCompilers: Record<string, any> = {};

export class LanguageModule implements ILanguageModuleState {
  static readonly Name = 'LanguageModule';

  items: ILanguageData[] = [
    { name: 'en', data: en.default },
    { name: 'ru', data: ru.default }
  ];

  lastStats: any;
  locale = 'en';
  readonly defaultLocale = 'en';

  constructor() {
    this.getTranslatedToken = this.getTranslatedToken.bind(this);
    this.getTranslatedMessage = this.getTranslatedMessage.bind(this);
    dayjs.locale(this.locale);
  }

  convertJsToPot() {
    const language = this.locale;
    const data = this.items.find((v) => v.name === language);
    const text = convertJSObjectToPOText(getCleanLangObject(language, data, {}), data, { Language: language });
    const content = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text);

    const link = document.createElement('a');
    link.setAttribute('href', content);
    link.setAttribute('download', 'i18n_ffmulti.pot');
    document.body.appendChild(link);
    link.click();
  }

  get nextLocale() {
    const currentLocaleIndex = this.items.findIndex((v) => v.name === this.locale);
    const nextLocaleIndex = this.items[currentLocaleIndex + 1] ? currentLocaleIndex + 1 : 0;
    const nextLocale = this.items[nextLocaleIndex];
    return nextLocale;
  }

  toggleLocale(): void {
    const nextLocale = this.nextLocale;
    this.locale = nextLocale.name;
    dayjs.locale(this.locale);
  }

  getMessageFormatCompiler(value: string): IntlMessageFormat {
    const locale = this.locale;
    const compilerKey = `${locale}:${value}`;
    const storageValue = this.getTranslatedToken(value);
    messageFormats[locale] = messageFormats[locale] ?? {};
    messageFormatCompilers[compilerKey] = messageFormatCompilers[compilerKey] ?? new IntlMessageFormat(storageValue, locale);
    return messageFormatCompilers[compilerKey];
  }

  get currentLocale() {
    return this.locale;
  }

  getTranslatedMessage(value: string, data: Record<string, any>): string {
    const compiler = this.getMessageFormatCompiler(value);
    let result;
    if (compiler) {
      try {
        result = compiler.format(data);
      } catch (e) {
        result = `[[error:${value}]]`;
        logger.warn(`[i18n]:error: can't compile "${value}":: ${this.getTranslatedToken(value)}`, e);
      }
    } else {
      result = `[[${value}]]`;
      console.warn(`[i18n]:error: not found translation for "${value}"`);
    }
    return result;
  }

  get itemsMap(): Record<string, ILanguageData> {
    return this.items.reduce((m: Record<string, ILanguageData>, v) => {
      m[v.name] = v;
      return m;
    }, {});
  }

  getTranslatedToken(token: string, filterOptions?: string) {
    const languageTokens = this.itemsMap[this.locale] ?? this.itemsMap[this.defaultLocale];
    const productToken = token + (applicationModule.isDefaultProduct ? '' : `__product_${applicationModule.product}`);
    const productTokenResult = applicationModule.isDefaultProduct ? null : _.get(languageTokens?.data, productToken, null);
    const tokenResult = _.get(languageTokens?.data, token, `[i18n:notfound]:${token}`);
    let result = productTokenResult || tokenResult;
    switch (filterOptions) {
      case 'f':
        result = firstCase(tokenResult);
        break;
      case 'u':
        result = tokenResult.toLocaleUpperCase();
        break;
    }
    return result;
  }

  setLocale(language: string) {
    const localeArray = language.split('-');
    this.locale = localeArray[0] || this.defaultLocale;
  }

  get languages() {
    const items = configModule.config.languages?.items;
    const langMap = items?.reduce<Record<string, string>>((m: Record<string, string>, v: ILanguage) => {
      m[v.name] = v.label;
      return m;
    }, {});

    return {
      en: 'English',
      ru: 'Русский',
      ...langMap
    };
  }
}

export const languageModule = reactive(new LanguageModule());
export function initLanguageModule(language?: string) {
  if (language) languageModule.setLocale(language);
  localStorageModule.registerInstance({ instance: languageModule, name: LanguageModule.Name, tokens: ['locale'] });
  // userStorageModule.registerInstance({ instance: languageModule, name: LanguageModule.Name, tokens: ['locale'] });
  watch(
    () => languageModule.locale,
    () => {
      localStorageModule.syncToStorageByName(LanguageModule.Name);
      // userStorageModule.syncToStorageByName(LanguageModule.Name);
    }
  );
}
