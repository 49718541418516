
import { PropType } from 'vue';
import { defineComponent } from 'vue';
import { NLoaderType, NLoaderTypes } from './types';

export default defineComponent({
  name: 'NLoadingCircle',
  props: {
    type: {
      type: String as PropType<NLoaderType>,
      default: NLoaderTypes.Primary
    }
  },
  computed: {
    cssClass(): Record<string, boolean> {
      return {
        'n-loading-circle': true,
        'n-loading-circle_primary': this.type === NLoaderTypes.Primary,
        'n-loading-circle_secondary': this.type === NLoaderTypes.Secondary
      };
    }
  }
});
