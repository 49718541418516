import { isDefined } from '@/common/utils';
import { LicenseUsageInterval, LicenseUsageIntervalCounter } from '@/pages/license/types';
import { computeLicenseUsageIntervalCounterLimitSpending, computeLicenseUsageIntervalCounterRelativeLimitSpending } from '@/pages/license/utils';
import startCase from 'lodash/startCase';
import { LicensePageMetaItem } from '../../../meta';
import { LicensePageMetaContentMonospaced } from '../../../meta/components';

export function formatLicenseUsageInterval(interval: LicenseUsageInterval): LicensePageMetaItem[] {
  return Object.entries(interval.counters).map<LicensePageMetaItem>(([name, counter]) => {
    const classes = computeLicenseUsageIntervalCounterClassName(counter);
    const content = `${counter.used}/${counter?.available ?? '-'}`;
    return { label: startCase(name), content: { type: 'custom', content: () => <LicensePageMetaContentMonospaced class={classes} content={content} /> } };
  });
}

export function computeLicenseUsageIntervalCounterClassName(interval: LicenseUsageIntervalCounter): string[] {
  const spending = computeLicenseUsageIntervalCounterLimitSpending(interval);
  return isDefined(spending) ? [`license-page-tab-view-intervals_${computeLicenseUsageIntervalCounterRelativeLimitSpending(spending)}`] : [];
}
