/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import type { BodyEvent } from '../models/BodyEvent';
import type { CarEvent } from '../models/CarEvent';
import type { CountResponse } from '../models/CountResponse';
import type { CreateBodyEventsRequestRequest } from '../models/CreateBodyEventsRequestRequest';
import type { CreateCarEventsRequestRequest } from '../models/CreateCarEventsRequestRequest';
import type { CreateEventsResponse } from '../models/CreateEventsResponse';
import type { CreateFaceEventsRequestRequest } from '../models/CreateFaceEventsRequestRequest';
import type { FaceEvent } from '../models/FaceEvent';
import type { PaginatedBodyEventList } from '../models/PaginatedBodyEventList';
import type { PaginatedCarEventList } from '../models/PaginatedCarEventList';
import type { PaginatedFaceEventList } from '../models/PaginatedFaceEventList';
import type { PatchedBodyEventRequest } from '../models/PatchedBodyEventRequest';
import type { PatchedCarEventRequest } from '../models/PatchedCarEventRequest';
import type { PatchedFaceEventRequest } from '../models/PatchedFaceEventRequest';
export class EventsService {
  /**
   * List body events
   * Use this method to list body events.
   * @param acknowledged `true` - select only acknowledged events (do not require acknowledgement), `false` - select only unacknowledged events
   * @param bagBack Select events where `features.bag_back.name` takes one of these values
   * @param bagBackScoreGte Select events where `features.bag_back.confidence` is **greater than or equal** to this value
   * @param bagBackScoreLte Select events where `features.bag_back.confidence` is **less than or equal** to this value
   * @param bagGround Select events where `features.bag_ground.name` takes one of these values
   * @param bagGroundScoreGte Select events where `features.bag_ground.confidence` is **greater than or equal** to this value
   * @param bagGroundScoreLte Select events where `features.bag_ground.confidence` is **less than or equal** to this value
   * @param bagHand Select events where `features.bag_hand.name` takes one of these values
   * @param bagHandScoreGte Select events where `features.bag_hand.confidence` is **greater than or equal** to this value
   * @param bagHandScoreLte Select events where `features.bag_hand.confidence` is **less than or equal** to this value
   * @param bottomColor Select events where `features.top_color.name` takes one of these values
   * @param bottomColorScoreGte Select events where `features.bottom_color.confidence` is **greater than or equal** to this value
   * @param bottomColorScoreLte Select events where `features.bottom_color.confidence` is **less than or equal** to this value
   * @param bsType Select objects where `bs_type` takes one of these values
   * @param cameraGroups Get events from these `camera groups`
   * @param cameras Get events from these `cameras`
   * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
   * @param createdDateLastNDays Select objects with created in last N days
   * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
   * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
   * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
   * @param detailedUpperClothes Select events where `features.detailed_upper_clothes.name` takes one of these values
   * @param detailedUpperClothesScoreGte Select events where `features.detailed_upper_clothes.confidence` is **greater than or equal** to this value
   * @param detailedUpperClothesScoreLte Select events where `features.detailed_upper_clothes.confidence` is **less than or equal** to this value
   * @param episode Select events that matched this episode
   * @param externalDetector Select events that created by external detectors
   * @param headwear Select events where `features.headwear.name` takes one of these values
   * @param headwearScoreGte Select events where `features.headwear.confidence` is **greater than or equal** to this value
   * @param headwearScoreLte Select events where `features.headwear.confidence` is **less than or equal** to this value
   * @param id Exact filter by ID
   * @param idGte Select objects with `id` **greater than or equal** to this value
   * @param idLte Select objects with `id` **less than or equal** to this value
   * @param limit Number of results to return per page.
   * @param looksLike Select events with faces similar to: (`detection:<detection id>`, `{face, body or car}object:<object id>`, `{face, body or car}event:<event id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`). You can pass several search sources to search for. Pagination works if there is only one face to search for and if the sorting order is id. In all other cases, only the first page of results is returned
   * @param lowerClothes Select events where `features.lower_clothes.name` takes one of these values
   * @param lowerClothesScoreGte Select events where `features.lower_clothes.confidence` is **greater than or equal** to this value
   * @param lowerClothesScoreLte Select events where `features.lower_clothes.confidence` is **less than or equal** to this value
   * @param matchedCard Select events that matched this card
   * @param matchedLists Select events that match a card included in these watch lists
   * @param noMatch `true` - select only events without matches. `false` - select events only with matches.
   * @param ordering Which field to use for ordering. Available values: id, -id. Available values for looks_like: -confidence, -looks_like_confidence, id, -id, confidence and looks_like_confidence ordering are equivalents
   * @param page Pagination cursor value.
   * @param threshold Similarity threshold for `looks_like`
   * @param topColor Select events where `features.top_color.name` takes one of these values
   * @param topColorScoreGte Select events where `features.top_color.confidence` is **greater than or equal** to this value
   * @param topColorScoreLte Select events where `features.top_color.confidence` is **less than or equal** to this value
   * @param upperClothes Select events where `features.upper_clothes.name` takes one of these values
   * @param upperClothesScoreGte Select events where `features.upper_clothes.confidence` is **greater than or equal** to this value
   * @param upperClothesScoreLte Select events where `features.upper_clothes.confidence` is **less than or equal** to this value
   * @param videoArchive Get events from these video archives
   * @returns PaginatedBodyEventList
   * @throws ApiError
   */
  public static eventsBodiesList(
    acknowledged?: boolean,
    bagBack?: Array<string>,
    bagBackScoreGte?: number,
    bagBackScoreLte?: number,
    bagGround?: Array<string>,
    bagGroundScoreGte?: number,
    bagGroundScoreLte?: number,
    bagHand?: Array<string>,
    bagHandScoreGte?: number,
    bagHandScoreLte?: number,
    bottomColor?: Array<string>,
    bottomColorScoreGte?: number,
    bottomColorScoreLte?: number,
    bsType?: 'overall' | 'realtime',
    cameraGroups?: Array<number>,
    cameras?: Array<number>,
    createdDateGte?: string,
    createdDateLastNDays?: number,
    createdDateLte?: string,
    createdDateNthFullWeek?: number,
    createdDateNthWorkWeek?: number,
    detailedUpperClothes?: Array<string>,
    detailedUpperClothesScoreGte?: number,
    detailedUpperClothesScoreLte?: number,
    episode?: number,
    externalDetector?: boolean,
    headwear?: Array<string>,
    headwearScoreGte?: number,
    headwearScoreLte?: number,
    id?: number,
    idGte?: number,
    idLte?: number,
    limit?: number,
    looksLike?: string,
    lowerClothes?: Array<string>,
    lowerClothesScoreGte?: number,
    lowerClothesScoreLte?: number,
    matchedCard?: number,
    matchedLists?: Array<number>,
    noMatch?: boolean,
    ordering?: string,
    page?: string,
    threshold?: number,
    topColor?: Array<string>,
    topColorScoreGte?: number,
    topColorScoreLte?: number,
    upperClothes?: Array<string>,
    upperClothesScoreGte?: number,
    upperClothesScoreLte?: number,
    videoArchive?: Array<number>
  ): CancelablePromise<PaginatedBodyEventList> {
    return __request({
      method: 'GET',
      path: `/events/bodies/`,
      query: {
        acknowledged: acknowledged,
        bag_back: bagBack,
        bag_back_score_gte: bagBackScoreGte,
        bag_back_score_lte: bagBackScoreLte,
        bag_ground: bagGround,
        bag_ground_score_gte: bagGroundScoreGte,
        bag_ground_score_lte: bagGroundScoreLte,
        bag_hand: bagHand,
        bag_hand_score_gte: bagHandScoreGte,
        bag_hand_score_lte: bagHandScoreLte,
        bottom_color: bottomColor,
        bottom_color_score_gte: bottomColorScoreGte,
        bottom_color_score_lte: bottomColorScoreLte,
        bs_type: bsType,
        camera_groups: cameraGroups,
        cameras: cameras,
        created_date_gte: createdDateGte,
        created_date_last_n_days: createdDateLastNDays,
        created_date_lte: createdDateLte,
        created_date_nth_full_week: createdDateNthFullWeek,
        created_date_nth_work_week: createdDateNthWorkWeek,
        detailed_upper_clothes: detailedUpperClothes,
        detailed_upper_clothes_score_gte: detailedUpperClothesScoreGte,
        detailed_upper_clothes_score_lte: detailedUpperClothesScoreLte,
        episode: episode,
        external_detector: externalDetector,
        headwear: headwear,
        headwear_score_gte: headwearScoreGte,
        headwear_score_lte: headwearScoreLte,
        id: id,
        id_gte: idGte,
        id_lte: idLte,
        limit: limit,
        looks_like: looksLike,
        lower_clothes: lowerClothes,
        lower_clothes_score_gte: lowerClothesScoreGte,
        lower_clothes_score_lte: lowerClothesScoreLte,
        matched_card: matchedCard,
        matched_lists: matchedLists,
        no_match: noMatch,
        ordering: ordering,
        page: page,
        threshold: threshold,
        top_color: topColor,
        top_color_score_gte: topColorScoreGte,
        top_color_score_lte: topColorScoreLte,
        upper_clothes: upperClothes,
        upper_clothes_score_gte: upperClothesScoreGte,
        upper_clothes_score_lte: upperClothesScoreLte,
        video_archive: videoArchive
      }
    });
  }

  /**
   * Retrieve body event by ID
   * Use this method to retrieve body event.
   * @param id
   * @returns BodyEvent
   * @throws ApiError
   */
  public static eventsBodiesRetrieve(id: string): CancelablePromise<BodyEvent> {
    return __request({
      method: 'GET',
      path: `/events/bodies/${id}/`
    });
  }

  /**
   * Update body event properties
   * Use this method to update body event properties.
   * @param id
   * @param requestBody
   * @returns BodyEvent
   * @throws ApiError
   */
  public static eventsBodiesPartialUpdate(id: string, requestBody?: PatchedBodyEventRequest): CancelablePromise<BodyEvent> {
    return __request({
      method: 'PATCH',
      path: `/events/bodies/${id}/`,
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * Acknowledge all events of this type accessible for the current user
   * Use this method to acknowledge all events of this object type accessible for the current user.
   * @returns any No response body
   * @throws ApiError
   */
  public static eventsBodiesAcknowledgeCreate(): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/events/bodies/acknowledge/`
    });
  }

  /**
   * Create body events from provided image
   * Use this method to create new body events.
   * @param formData
   * @returns CreateEventsResponse
   * @throws ApiError
   */
  public static eventsBodiesAddCreate(formData: CreateBodyEventsRequestRequest): CancelablePromise<CreateEventsResponse> {
    return __request({
      method: 'POST',
      path: `/events/bodies/add/`,
      formData: formData,
      mediaType: 'multipart/form-data'
    });
  }

  /**
   * Body events count
   * This method retrieves a count of body events.
   * @param acknowledged `true` - select only acknowledged events (do not require acknowledgement), `false` - select only unacknowledged events
   * @param bagBack Select events where `features.bag_back.name` takes one of these values
   * @param bagBackScoreGte Select events where `features.bag_back.confidence` is **greater than or equal** to this value
   * @param bagBackScoreLte Select events where `features.bag_back.confidence` is **less than or equal** to this value
   * @param bagGround Select events where `features.bag_ground.name` takes one of these values
   * @param bagGroundScoreGte Select events where `features.bag_ground.confidence` is **greater than or equal** to this value
   * @param bagGroundScoreLte Select events where `features.bag_ground.confidence` is **less than or equal** to this value
   * @param bagHand Select events where `features.bag_hand.name` takes one of these values
   * @param bagHandScoreGte Select events where `features.bag_hand.confidence` is **greater than or equal** to this value
   * @param bagHandScoreLte Select events where `features.bag_hand.confidence` is **less than or equal** to this value
   * @param bottomColor Select events where `features.top_color.name` takes one of these values
   * @param bottomColorScoreGte Select events where `features.bottom_color.confidence` is **greater than or equal** to this value
   * @param bottomColorScoreLte Select events where `features.bottom_color.confidence` is **less than or equal** to this value
   * @param bsType Select objects where `bs_type` takes one of these values
   * @param cameraGroups Get events from these `camera groups`
   * @param cameras Get events from these `cameras`
   * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
   * @param createdDateLastNDays Select objects with created in last N days
   * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
   * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
   * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
   * @param detailedUpperClothes Select events where `features.detailed_upper_clothes.name` takes one of these values
   * @param detailedUpperClothesScoreGte Select events where `features.detailed_upper_clothes.confidence` is **greater than or equal** to this value
   * @param detailedUpperClothesScoreLte Select events where `features.detailed_upper_clothes.confidence` is **less than or equal** to this value
   * @param episode Select events that matched this episode
   * @param externalDetector Select events that created by external detectors
   * @param headwear Select events where `features.headwear.name` takes one of these values
   * @param headwearScoreGte Select events where `features.headwear.confidence` is **greater than or equal** to this value
   * @param headwearScoreLte Select events where `features.headwear.confidence` is **less than or equal** to this value
   * @param id Exact filter by ID
   * @param idGte Select objects with `id` **greater than or equal** to this value
   * @param idLte Select objects with `id` **less than or equal** to this value
   * @param lowerClothes Select events where `features.lower_clothes.name` takes one of these values
   * @param lowerClothesScoreGte Select events where `features.lower_clothes.confidence` is **greater than or equal** to this value
   * @param lowerClothesScoreLte Select events where `features.lower_clothes.confidence` is **less than or equal** to this value
   * @param matchedCard Select events that matched this card
   * @param matchedLists Select events that match a card included in these watch lists
   * @param noMatch `true` - select only events without matches. `false` - select events only with matches.
   * @param topColor Select events where `features.top_color.name` takes one of these values
   * @param topColorScoreGte Select events where `features.top_color.confidence` is **greater than or equal** to this value
   * @param topColorScoreLte Select events where `features.top_color.confidence` is **less than or equal** to this value
   * @param upperClothes Select events where `features.upper_clothes.name` takes one of these values
   * @param upperClothesScoreGte Select events where `features.upper_clothes.confidence` is **greater than or equal** to this value
   * @param upperClothesScoreLte Select events where `features.upper_clothes.confidence` is **less than or equal** to this value
   * @param videoArchive Get events from these video archives
   * @returns CountResponse
   * @throws ApiError
   */
  public static eventsBodiesCountRetrieve(
    acknowledged?: boolean,
    bagBack?: Array<string>,
    bagBackScoreGte?: number,
    bagBackScoreLte?: number,
    bagGround?: Array<string>,
    bagGroundScoreGte?: number,
    bagGroundScoreLte?: number,
    bagHand?: Array<string>,
    bagHandScoreGte?: number,
    bagHandScoreLte?: number,
    bottomColor?: Array<string>,
    bottomColorScoreGte?: number,
    bottomColorScoreLte?: number,
    bsType?: 'overall' | 'realtime',
    cameraGroups?: Array<number>,
    cameras?: Array<number>,
    createdDateGte?: string,
    createdDateLastNDays?: number,
    createdDateLte?: string,
    createdDateNthFullWeek?: number,
    createdDateNthWorkWeek?: number,
    detailedUpperClothes?: Array<string>,
    detailedUpperClothesScoreGte?: number,
    detailedUpperClothesScoreLte?: number,
    episode?: number,
    externalDetector?: boolean,
    headwear?: Array<string>,
    headwearScoreGte?: number,
    headwearScoreLte?: number,
    id?: number,
    idGte?: number,
    idLte?: number,
    lowerClothes?: Array<string>,
    lowerClothesScoreGte?: number,
    lowerClothesScoreLte?: number,
    matchedCard?: number,
    matchedLists?: Array<number>,
    noMatch?: boolean,
    topColor?: Array<string>,
    topColorScoreGte?: number,
    topColorScoreLte?: number,
    upperClothes?: Array<string>,
    upperClothesScoreGte?: number,
    upperClothesScoreLte?: number,
    videoArchive?: Array<number>
  ): CancelablePromise<CountResponse> {
    return __request({
      method: 'GET',
      path: `/events/bodies/count/`,
      query: {
        acknowledged: acknowledged,
        bag_back: bagBack,
        bag_back_score_gte: bagBackScoreGte,
        bag_back_score_lte: bagBackScoreLte,
        bag_ground: bagGround,
        bag_ground_score_gte: bagGroundScoreGte,
        bag_ground_score_lte: bagGroundScoreLte,
        bag_hand: bagHand,
        bag_hand_score_gte: bagHandScoreGte,
        bag_hand_score_lte: bagHandScoreLte,
        bottom_color: bottomColor,
        bottom_color_score_gte: bottomColorScoreGte,
        bottom_color_score_lte: bottomColorScoreLte,
        bs_type: bsType,
        camera_groups: cameraGroups,
        cameras: cameras,
        created_date_gte: createdDateGte,
        created_date_last_n_days: createdDateLastNDays,
        created_date_lte: createdDateLte,
        created_date_nth_full_week: createdDateNthFullWeek,
        created_date_nth_work_week: createdDateNthWorkWeek,
        detailed_upper_clothes: detailedUpperClothes,
        detailed_upper_clothes_score_gte: detailedUpperClothesScoreGte,
        detailed_upper_clothes_score_lte: detailedUpperClothesScoreLte,
        episode: episode,
        external_detector: externalDetector,
        headwear: headwear,
        headwear_score_gte: headwearScoreGte,
        headwear_score_lte: headwearScoreLte,
        id: id,
        id_gte: idGte,
        id_lte: idLte,
        lower_clothes: lowerClothes,
        lower_clothes_score_gte: lowerClothesScoreGte,
        lower_clothes_score_lte: lowerClothesScoreLte,
        matched_card: matchedCard,
        matched_lists: matchedLists,
        no_match: noMatch,
        top_color: topColor,
        top_color_score_gte: topColorScoreGte,
        top_color_score_lte: topColorScoreLte,
        upper_clothes: upperClothes,
        upper_clothes_score_gte: upperClothesScoreGte,
        upper_clothes_score_lte: upperClothesScoreLte,
        video_archive: videoArchive
      }
    });
  }

  /**
   * List car events
   * Use this method to list car events.
   * @param acknowledged `true` - select only acknowledged events (do not require acknowledgement), `false` - select only unacknowledged events
   * @param body Select events where `features.body.name` takes one of these values
   * @param bodyScoreGte Select events where `features.body.confidence` is **greater than or equal** to this value
   * @param bodyScoreLte Select events where `features.body.confidence` is **less than or equal** to this value
   * @param bsType Select objects where `bs_type` takes one of these values
   * @param cameraGroups Get events from these `camera groups`
   * @param cameras Get events from these `cameras`
   * @param color Select events where `features.color.name` takes one of these values
   * @param colorScoreGte Select events where `features.color.confidence` is **greater than or equal** to this value
   * @param colorScoreLte Select events where `features.color.confidence` is **less than or equal** to this value
   * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
   * @param createdDateLastNDays Select objects with created in last N days
   * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
   * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
   * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
   * @param episode Select events that matched this episode
   * @param externalDetector Select events that created by external detectors
   * @param id Exact filter by ID
   * @param idGte Select objects with `id` **greater than or equal** to this value
   * @param idLte Select objects with `id` **less than or equal** to this value
   * @param licensePlateCountry Select events where `features.license_plate_country.name` takes one of these values
   * @param licensePlateCountryScoreGte Select events where `features.license_plate_country.confidence` is **greater than or equal** to this value
   * @param licensePlateCountryScoreLte Select events where `features.license_plate_country.confidence` is **less than or equal** to this value
   * @param licensePlateNumber Select events where `features.model.license_plate_number` contains provided string(case insensitive)
   * @param licensePlateNumberScoreGte Select events where `features.license_plate_number.confidence` is **greater than or equal** to this value
   * @param licensePlateNumberScoreLte Select events where `features.license_plate_number.confidence` is **less than or equal** to this value
   * @param licensePlateRegion Select events where `features.license_plate_region.name` takes one of these values
   * @param licensePlateRegionScoreGte Select events where `features.license_plate_region.confidence` is **greater than or equal** to this value
   * @param licensePlateRegionScoreLte Select events where `features.license_plate_region.confidence` is **less than or equal** to this value
   * @param limit Number of results to return per page.
   * @param looksLike Select events with faces similar to: (`detection:<detection id>`, `{face, body or car}object:<object id>`, `{face, body or car}event:<event id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`). You can pass several search sources to search for. Pagination works if there is only one face to search for and if the sorting order is id. In all other cases, only the first page of results is returned
   * @param make Select events where `features.make.name` takes one of these values
   * @param makeScoreGte Select events where `features.make.confidence` is **greater than or equal** to this value
   * @param makeScoreLte Select events where `features.make.confidence` is **less than or equal** to this value
   * @param matchedCard Select events that matched this card
   * @param matchedLists Select events that match a card included in these watch lists
   * @param model Select events where `features.model.name` contains provided string(case insensitive)
   * @param modelScoreGte Select events where `features.model.confidence` is **greater than or equal** to this value
   * @param modelScoreLte Select events where `features.model.confidence` is **less than or equal** to this value
   * @param noMatch `true` - select only events without matches. `false` - select events only with matches.
   * @param ordering Which field to use for ordering. Available values: id, -id. Available values for looks_like: -confidence, -looks_like_confidence, id, -id, confidence and looks_like_confidence ordering are equivalents
   * @param page Pagination cursor value.
   * @param specialVehicleType Select events where `features.special_vehicle_type.name` contains provided string(case insensitive)
   * @param specialVehicleTypeScoreGte Select events where `features.special_vehicle_type.confidence` is **greater than or equal** to this value
   * @param specialVehicleTypeScoreLte Select events where `features.special_vehicle_type.confidence` is **less than or equal** to this value
   * @param threshold Similarity threshold for `looks_like`
   * @param videoArchive Get events from these video archives
   * @returns PaginatedCarEventList
   * @throws ApiError
   */
  public static eventsCarsList(
    acknowledged?: boolean,
    body?: Array<string>,
    bodyScoreGte?: number,
    bodyScoreLte?: number,
    bsType?: 'overall' | 'realtime',
    cameraGroups?: Array<number>,
    cameras?: Array<number>,
    color?: Array<string>,
    colorScoreGte?: number,
    colorScoreLte?: number,
    createdDateGte?: string,
    createdDateLastNDays?: number,
    createdDateLte?: string,
    createdDateNthFullWeek?: number,
    createdDateNthWorkWeek?: number,
    episode?: number,
    externalDetector?: boolean,
    id?: number,
    idGte?: number,
    idLte?: number,
    licensePlateCountry?: Array<string>,
    licensePlateCountryScoreGte?: number,
    licensePlateCountryScoreLte?: number,
    licensePlateNumber?: Array<string>,
    licensePlateNumberScoreGte?: number,
    licensePlateNumberScoreLte?: number,
    licensePlateRegion?: Array<string>,
    licensePlateRegionScoreGte?: number,
    licensePlateRegionScoreLte?: number,
    limit?: number,
    looksLike?: string,
    make?: Array<string>,
    makeScoreGte?: number,
    makeScoreLte?: number,
    matchedCard?: number,
    matchedLists?: Array<number>,
    model?: Array<string>,
    modelScoreGte?: number,
    modelScoreLte?: number,
    noMatch?: boolean,
    ordering?: string,
    page?: string,
    specialVehicleType?: Array<string>,
    specialVehicleTypeScoreGte?: number,
    specialVehicleTypeScoreLte?: number,
    threshold?: number,
    videoArchive?: Array<number>
  ): CancelablePromise<PaginatedCarEventList> {
    return __request({
      method: 'GET',
      path: `/events/cars/`,
      query: {
        acknowledged: acknowledged,
        body: body,
        body_score_gte: bodyScoreGte,
        body_score_lte: bodyScoreLte,
        bs_type: bsType,
        camera_groups: cameraGroups,
        cameras: cameras,
        color: color,
        color_score_gte: colorScoreGte,
        color_score_lte: colorScoreLte,
        created_date_gte: createdDateGte,
        created_date_last_n_days: createdDateLastNDays,
        created_date_lte: createdDateLte,
        created_date_nth_full_week: createdDateNthFullWeek,
        created_date_nth_work_week: createdDateNthWorkWeek,
        episode: episode,
        external_detector: externalDetector,
        id: id,
        id_gte: idGte,
        id_lte: idLte,
        license_plate_country: licensePlateCountry,
        license_plate_country_score_gte: licensePlateCountryScoreGte,
        license_plate_country_score_lte: licensePlateCountryScoreLte,
        license_plate_number: licensePlateNumber,
        license_plate_number_score_gte: licensePlateNumberScoreGte,
        license_plate_number_score_lte: licensePlateNumberScoreLte,
        license_plate_region: licensePlateRegion,
        license_plate_region_score_gte: licensePlateRegionScoreGte,
        license_plate_region_score_lte: licensePlateRegionScoreLte,
        limit: limit,
        looks_like: looksLike,
        make: make,
        make_score_gte: makeScoreGte,
        make_score_lte: makeScoreLte,
        matched_card: matchedCard,
        matched_lists: matchedLists,
        model: model,
        model_score_gte: modelScoreGte,
        model_score_lte: modelScoreLte,
        no_match: noMatch,
        ordering: ordering,
        page: page,
        special_vehicle_type: specialVehicleType,
        special_vehicle_type_score_gte: specialVehicleTypeScoreGte,
        special_vehicle_type_score_lte: specialVehicleTypeScoreLte,
        threshold: threshold,
        video_archive: videoArchive
      }
    });
  }

  /**
   * Retrieve car event by ID
   * Use this method to retrieve car event.
   * @param id
   * @returns CarEvent
   * @throws ApiError
   */
  public static eventsCarsRetrieve(id: string): CancelablePromise<CarEvent> {
    return __request({
      method: 'GET',
      path: `/events/cars/${id}/`
    });
  }

  /**
   * Update car event properties
   * Use this method to update car event properties.
   * @param id
   * @param requestBody
   * @returns CarEvent
   * @throws ApiError
   */
  public static eventsCarsPartialUpdate(id: string, requestBody?: PatchedCarEventRequest): CancelablePromise<CarEvent> {
    return __request({
      method: 'PATCH',
      path: `/events/cars/${id}/`,
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * Acknowledge all events of this type accessible for the current user
   * Use this method to acknowledge all events of this object type accessible for the current user.
   * @returns any No response body
   * @throws ApiError
   */
  public static eventsCarsAcknowledgeCreate(): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/events/cars/acknowledge/`
    });
  }

  /**
   * Create car events from provided image
   * Use this method to create new car events.
   * @param formData
   * @returns CreateEventsResponse
   * @throws ApiError
   */
  public static eventsCarsAddCreate(formData: CreateCarEventsRequestRequest): CancelablePromise<CreateEventsResponse> {
    return __request({
      method: 'POST',
      path: `/events/cars/add/`,
      formData: formData,
      mediaType: 'multipart/form-data'
    });
  }

  /**
   * Car events count
   * This method retrieves a count of car events.
   * @param acknowledged `true` - select only acknowledged events (do not require acknowledgement), `false` - select only unacknowledged events
   * @param body Select events where `features.body.name` takes one of these values
   * @param bodyScoreGte Select events where `features.body.confidence` is **greater than or equal** to this value
   * @param bodyScoreLte Select events where `features.body.confidence` is **less than or equal** to this value
   * @param bsType Select objects where `bs_type` takes one of these values
   * @param cameraGroups Get events from these `camera groups`
   * @param cameras Get events from these `cameras`
   * @param color Select events where `features.color.name` takes one of these values
   * @param colorScoreGte Select events where `features.color.confidence` is **greater than or equal** to this value
   * @param colorScoreLte Select events where `features.color.confidence` is **less than or equal** to this value
   * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
   * @param createdDateLastNDays Select objects with created in last N days
   * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
   * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
   * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
   * @param episode Select events that matched this episode
   * @param externalDetector Select events that created by external detectors
   * @param id Exact filter by ID
   * @param idGte Select objects with `id` **greater than or equal** to this value
   * @param idLte Select objects with `id` **less than or equal** to this value
   * @param licensePlateCountry Select events where `features.license_plate_country.name` takes one of these values
   * @param licensePlateCountryScoreGte Select events where `features.license_plate_country.confidence` is **greater than or equal** to this value
   * @param licensePlateCountryScoreLte Select events where `features.license_plate_country.confidence` is **less than or equal** to this value
   * @param licensePlateNumber Select events where `features.model.license_plate_number` contains provided string(case insensitive)
   * @param licensePlateNumberScoreGte Select events where `features.license_plate_number.confidence` is **greater than or equal** to this value
   * @param licensePlateNumberScoreLte Select events where `features.license_plate_number.confidence` is **less than or equal** to this value
   * @param licensePlateRegion Select events where `features.license_plate_region.name` takes one of these values
   * @param licensePlateRegionScoreGte Select events where `features.license_plate_region.confidence` is **greater than or equal** to this value
   * @param licensePlateRegionScoreLte Select events where `features.license_plate_region.confidence` is **less than or equal** to this value
   * @param make Select events where `features.make.name` takes one of these values
   * @param makeScoreGte Select events where `features.make.confidence` is **greater than or equal** to this value
   * @param makeScoreLte Select events where `features.make.confidence` is **less than or equal** to this value
   * @param matchedCard Select events that matched this card
   * @param matchedLists Select events that match a card included in these watch lists
   * @param model Select events where `features.model.name` contains provided string(case insensitive)
   * @param modelScoreGte Select events where `features.model.confidence` is **greater than or equal** to this value
   * @param modelScoreLte Select events where `features.model.confidence` is **less than or equal** to this value
   * @param noMatch `true` - select only events without matches. `false` - select events only with matches.
   * @param specialVehicleType Select events where `features.special_vehicle_type.name` contains provided string(case insensitive)
   * @param specialVehicleTypeScoreGte Select events where `features.special_vehicle_type.confidence` is **greater than or equal** to this value
   * @param specialVehicleTypeScoreLte Select events where `features.special_vehicle_type.confidence` is **less than or equal** to this value
   * @param videoArchive Get events from these video archives
   * @returns CountResponse
   * @throws ApiError
   */
  public static eventsCarsCountRetrieve(
    acknowledged?: boolean,
    body?: Array<string>,
    bodyScoreGte?: number,
    bodyScoreLte?: number,
    bsType?: 'overall' | 'realtime',
    cameraGroups?: Array<number>,
    cameras?: Array<number>,
    color?: Array<string>,
    colorScoreGte?: number,
    colorScoreLte?: number,
    createdDateGte?: string,
    createdDateLastNDays?: number,
    createdDateLte?: string,
    createdDateNthFullWeek?: number,
    createdDateNthWorkWeek?: number,
    episode?: number,
    externalDetector?: boolean,
    id?: number,
    idGte?: number,
    idLte?: number,
    licensePlateCountry?: Array<string>,
    licensePlateCountryScoreGte?: number,
    licensePlateCountryScoreLte?: number,
    licensePlateNumber?: Array<string>,
    licensePlateNumberScoreGte?: number,
    licensePlateNumberScoreLte?: number,
    licensePlateRegion?: Array<string>,
    licensePlateRegionScoreGte?: number,
    licensePlateRegionScoreLte?: number,
    make?: Array<string>,
    makeScoreGte?: number,
    makeScoreLte?: number,
    matchedCard?: number,
    matchedLists?: Array<number>,
    model?: Array<string>,
    modelScoreGte?: number,
    modelScoreLte?: number,
    noMatch?: boolean,
    specialVehicleType?: Array<string>,
    specialVehicleTypeScoreGte?: number,
    specialVehicleTypeScoreLte?: number,
    videoArchive?: Array<number>
  ): CancelablePromise<CountResponse> {
    return __request({
      method: 'GET',
      path: `/events/cars/count/`,
      query: {
        acknowledged: acknowledged,
        body: body,
        body_score_gte: bodyScoreGte,
        body_score_lte: bodyScoreLte,
        bs_type: bsType,
        camera_groups: cameraGroups,
        cameras: cameras,
        color: color,
        color_score_gte: colorScoreGte,
        color_score_lte: colorScoreLte,
        created_date_gte: createdDateGte,
        created_date_last_n_days: createdDateLastNDays,
        created_date_lte: createdDateLte,
        created_date_nth_full_week: createdDateNthFullWeek,
        created_date_nth_work_week: createdDateNthWorkWeek,
        episode: episode,
        external_detector: externalDetector,
        id: id,
        id_gte: idGte,
        id_lte: idLte,
        license_plate_country: licensePlateCountry,
        license_plate_country_score_gte: licensePlateCountryScoreGte,
        license_plate_country_score_lte: licensePlateCountryScoreLte,
        license_plate_number: licensePlateNumber,
        license_plate_number_score_gte: licensePlateNumberScoreGte,
        license_plate_number_score_lte: licensePlateNumberScoreLte,
        license_plate_region: licensePlateRegion,
        license_plate_region_score_gte: licensePlateRegionScoreGte,
        license_plate_region_score_lte: licensePlateRegionScoreLte,
        make: make,
        make_score_gte: makeScoreGte,
        make_score_lte: makeScoreLte,
        matched_card: matchedCard,
        matched_lists: matchedLists,
        model: model,
        model_score_gte: modelScoreGte,
        model_score_lte: modelScoreLte,
        no_match: noMatch,
        special_vehicle_type: specialVehicleType,
        special_vehicle_type_score_gte: specialVehicleTypeScoreGte,
        special_vehicle_type_score_lte: specialVehicleTypeScoreLte,
        video_archive: videoArchive
      }
    });
  }

  /**
   * List face events
   * Use this method to list face events.
   * @param acknowledged `true` - select only acknowledged events (do not require acknowledgement), `false` - select only unacknowledged events
   * @param ageGte Select events where `features.age` is **greater than or equal** to this value
   * @param ageLte Select events where `features.age` is **less than or equal** to this value
   * @param beard Select events where `features.beard.name` takes one of these values
   * @param beardScoreGte Select events where `features.beard.confidence` is **greater than or equal** to this value
   * @param beardScoreLte Select events where `features.beard.confidence` is **less than or equal** to this value
   * @param bsType Select objects where `bs_type` takes one of these values
   * @param cameraGroups Get events from these `camera groups`
   * @param cameras Get events from these `cameras`
   * @param caseIn Select events related to these `cases`
   * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
   * @param createdDateLastNDays Select objects with created in last N days
   * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
   * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
   * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
   * @param emotions Select events where `features.emotions.name` takes one of these values
   * @param emotionsScoreGte Select events where `features.emotions.confidence` is **greater than or equal** to this value
   * @param emotionsScoreLte Select events where `features.emotions.confidence` is **less than or equal** to this value
   * @param episode Select events that matched this episode
   * @param externalDetector Select events that created by external detectors
   * @param gender Select events where `features.gender.name` takes one of these values
   * @param genderScoreGte Select events where `features.gender.confidence` is **greater than or equal** to this value
   * @param genderScoreLte Select events where `features.gender.confidence` is **less than or equal** to this value
   * @param glasses Select events where `features.glasses.name` takes one of these values
   * @param glassesScoreGte Select events where `features.glasses.confidence` is **greater than or equal** to this value
   * @param glassesScoreLte Select events where `features.glasses.confidence` is **less than or equal** to this value
   * @param hasCase `true` - select only events relates to case. `false` - select only events without case.
   * @param id Exact filter by ID
   * @param idGte Select objects with `id` **greater than or equal** to this value
   * @param idLte Select objects with `id` **less than or equal** to this value
   * @param limit Number of results to return per page.
   * @param liveness Select events where `features.liveness.name` takes one of these values
   * @param livenessScoreGte Select events where `features.liveness.confidence` is **greater than or equal** to this value
   * @param livenessScoreLte Select events where `features.liveness.confidence` is **less than or equal** to this value
   * @param look Select events where `features.look.name` takes one of these values
   * @param lookScoreGte Select events where `features.look.confidence` is **greater than or equal** to this value
   * @param lookScoreLte Select events where `features.look.confidence` is **less than or equal** to this value
   * @param looksLike Select events with faces similar to: (`detection:<detection id>`, `{face, body or car}object:<object id>`, `{face, body or car}event:<event id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`). You can pass several search sources to search for. Pagination works if there is only one face to search for and if the sorting order is id. In all other cases, only the first page of results is returned
   * @param matchedCard Select events that matched this card
   * @param matchedLists Select events that match a card included in these watch lists
   * @param medmask Select events where `features.medmask.name` takes one of these values
   * @param medmaskScoreGte Select events where `features.medmask.confidence` is **greater than or equal** to this value
   * @param medmaskScoreLte Select events where `features.medmask.confidence` is **less than or equal** to this value
   * @param noMatch `true` - select only events without matches. `false` - select events only with matches.
   * @param ordering Which field to use for ordering. Available values: id, -id. Available values for looks_like: -confidence, -looks_like_confidence, id, -id, confidence and looks_like_confidence ordering are equivalents
   * @param page Pagination cursor value.
   * @param pitchAngleGte Select events where `features.headpose.pitch` is **greater than or equal** to this value
   * @param pitchAngleLte Select events where `features.headpose.pitch` is **less than or equal** to this value
   * @param rollAngleGte Select events where `features.headpose.roll` is **greater than or equal** to this value
   * @param rollAngleLte Select events where `features.headpose.roll` is **less than or equal** to this value
   * @param temperatureGte Select events where `temperature` is **greater than or equal** to this value
   * @param temperatureLte Select events where `temperature` is **less than or equal** to this value
   * @param threshold Similarity threshold for `looks_like`
   * @param videoArchive Get events from these video archives
   * @param yawAngleGte Select events where `features.headpose.yaw` is **greater than or equal** to this value
   * @param yawAngleLte Select events where `features.headpose.yaw` is **less than or equal** to this value
   * @returns PaginatedFaceEventList
   * @throws ApiError
   */
  public static eventsFacesList(
    acknowledged?: boolean,
    ageGte?: number,
    ageLte?: number,
    beard?: Array<string>,
    beardScoreGte?: number,
    beardScoreLte?: number,
    bsType?: 'overall' | 'realtime',
    cameraGroups?: Array<number>,
    cameras?: Array<number>,
    caseIn?: Array<number>,
    createdDateGte?: string,
    createdDateLastNDays?: number,
    createdDateLte?: string,
    createdDateNthFullWeek?: number,
    createdDateNthWorkWeek?: number,
    emotions?: Array<string>,
    emotionsScoreGte?: number,
    emotionsScoreLte?: number,
    episode?: number,
    externalDetector?: boolean,
    gender?: Array<string>,
    genderScoreGte?: number,
    genderScoreLte?: number,
    glasses?: Array<string>,
    glassesScoreGte?: number,
    glassesScoreLte?: number,
    hasCase?: boolean,
    id?: number,
    idGte?: number,
    idLte?: number,
    limit?: number,
    liveness?: Array<string>,
    livenessScoreGte?: number,
    livenessScoreLte?: number,
    look?: Array<string>,
    lookScoreGte?: number,
    lookScoreLte?: number,
    looksLike?: string,
    matchedCard?: number,
    matchedLists?: Array<number>,
    medmask?: Array<string>,
    medmaskScoreGte?: number,
    medmaskScoreLte?: number,
    noMatch?: boolean,
    ordering?: string,
    page?: string,
    pitchAngleGte?: number,
    pitchAngleLte?: number,
    rollAngleGte?: number,
    rollAngleLte?: number,
    temperatureGte?: number,
    temperatureLte?: number,
    threshold?: number,
    videoArchive?: Array<number>,
    yawAngleGte?: number,
    yawAngleLte?: number
  ): CancelablePromise<PaginatedFaceEventList> {
    return __request({
      method: 'GET',
      path: `/events/faces/`,
      query: {
        acknowledged: acknowledged,
        age_gte: ageGte,
        age_lte: ageLte,
        beard: beard,
        beard_score_gte: beardScoreGte,
        beard_score_lte: beardScoreLte,
        bs_type: bsType,
        camera_groups: cameraGroups,
        cameras: cameras,
        case_in: caseIn,
        created_date_gte: createdDateGte,
        created_date_last_n_days: createdDateLastNDays,
        created_date_lte: createdDateLte,
        created_date_nth_full_week: createdDateNthFullWeek,
        created_date_nth_work_week: createdDateNthWorkWeek,
        emotions: emotions,
        emotions_score_gte: emotionsScoreGte,
        emotions_score_lte: emotionsScoreLte,
        episode: episode,
        external_detector: externalDetector,
        gender: gender,
        gender_score_gte: genderScoreGte,
        gender_score_lte: genderScoreLte,
        glasses: glasses,
        glasses_score_gte: glassesScoreGte,
        glasses_score_lte: glassesScoreLte,
        has_case: hasCase,
        id: id,
        id_gte: idGte,
        id_lte: idLte,
        limit: limit,
        liveness: liveness,
        liveness_score_gte: livenessScoreGte,
        liveness_score_lte: livenessScoreLte,
        look: look,
        look_score_gte: lookScoreGte,
        look_score_lte: lookScoreLte,
        looks_like: looksLike,
        matched_card: matchedCard,
        matched_lists: matchedLists,
        medmask: medmask,
        medmask_score_gte: medmaskScoreGte,
        medmask_score_lte: medmaskScoreLte,
        no_match: noMatch,
        ordering: ordering,
        page: page,
        headpose_pitch_angle_gte: pitchAngleGte,
        headpose_pitch_angle_lte: pitchAngleLte,
        roll_angle_gte: rollAngleGte,
        roll_angle_lte: rollAngleLte,
        temperature_gte: temperatureGte,
        temperature_lte: temperatureLte,
        threshold: threshold,
        video_archive: videoArchive,
        headpose_yaw_angle_gte: yawAngleGte,
        headpose_yaw_angle_lte: yawAngleLte
      }
    });
  }

  /**
   * Retrieve face event by ID
   * Use this method to retrieve face event.
   * @param id
   * @returns FaceEvent
   * @throws ApiError
   */
  public static eventsFacesRetrieve(id: string): CancelablePromise<FaceEvent> {
    return __request({
      method: 'GET',
      path: `/events/faces/${id}/`
    });
  }

  /**
   * Update face event properties
   * Use this method to update face event properties.
   * @param id
   * @param requestBody
   * @returns FaceEvent
   * @throws ApiError
   */
  public static eventsFacesPartialUpdate(id: string, requestBody?: PatchedFaceEventRequest): CancelablePromise<FaceEvent> {
    return __request({
      method: 'PATCH',
      path: `/events/faces/${id}/`,
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * Acknowledge all events of this type accessible for the current user
   * Use this method to acknowledge all events of this object type accessible for the current user.
   * @returns any No response body
   * @throws ApiError
   */
  public static eventsFacesAcknowledgeCreate(): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/events/faces/acknowledge/`
    });
  }

  /**
   * Create face events from provided image
   * Use this method to create new face events.
   * @param formData
   * @returns CreateEventsResponse
   * @throws ApiError
   */
  public static eventsFacesAddCreate(formData: CreateFaceEventsRequestRequest): CancelablePromise<CreateEventsResponse> {
    return __request({
      method: 'POST',
      path: `/events/faces/add/`,
      formData: formData,
      mediaType: 'multipart/form-data'
    });
  }

  /**
   * Face events count
   * This method retrieves a count of face events.
   * @param acknowledged `true` - select only acknowledged events (do not require acknowledgement), `false` - select only unacknowledged events
   * @param ageGte Select events where `features.age` is **greater than or equal** to this value
   * @param ageLte Select events where `features.age` is **less than or equal** to this value
   * @param beard Select events where `features.beard.name` takes one of these values
   * @param beardScoreGte Select events where `features.beard.confidence` is **greater than or equal** to this value
   * @param beardScoreLte Select events where `features.beard.confidence` is **less than or equal** to this value
   * @param bsType Select objects where `bs_type` takes one of these values
   * @param cameraGroups Get events from these `camera groups`
   * @param cameras Get events from these `cameras`
   * @param caseIn Select events related to these `cases`
   * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
   * @param createdDateLastNDays Select objects with created in last N days
   * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
   * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
   * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
   * @param emotions Select events where `features.emotions.name` takes one of these values
   * @param emotionsScoreGte Select events where `features.emotions.confidence` is **greater than or equal** to this value
   * @param emotionsScoreLte Select events where `features.emotions.confidence` is **less than or equal** to this value
   * @param episode Select events that matched this episode
   * @param externalDetector Select events that created by external detectors
   * @param gender Select events where `features.gender.name` takes one of these values
   * @param genderScoreGte Select events where `features.gender.confidence` is **greater than or equal** to this value
   * @param genderScoreLte Select events where `features.gender.confidence` is **less than or equal** to this value
   * @param glasses Select events where `features.glasses.name` takes one of these values
   * @param glassesScoreGte Select events where `features.glasses.confidence` is **greater than or equal** to this value
   * @param glassesScoreLte Select events where `features.glasses.confidence` is **less than or equal** to this value
   * @param hasCase `true` - select only events relates to case. `false` - select only events without case.
   * @param id Exact filter by ID
   * @param idGte Select objects with `id` **greater than or equal** to this value
   * @param idLte Select objects with `id` **less than or equal** to this value
   * @param liveness Select events where `features.liveness.name` takes one of these values
   * @param livenessScoreGte Select events where `features.liveness.confidence` is **greater than or equal** to this value
   * @param livenessScoreLte Select events where `features.liveness.confidence` is **less than or equal** to this value
   * @param look Select events where `features.look.name` takes one of these values
   * @param lookScoreGte Select events where `features.look.confidence` is **greater than or equal** to this value
   * @param lookScoreLte Select events where `features.look.confidence` is **less than or equal** to this value
   * @param matchedCard Select events that matched this card
   * @param matchedLists Select events that match a card included in these watch lists
   * @param medmask Select events where `features.medmask.name` takes one of these values
   * @param medmaskScoreGte Select events where `features.medmask.confidence` is **greater than or equal** to this value
   * @param medmaskScoreLte Select events where `features.medmask.confidence` is **less than or equal** to this value
   * @param noMatch `true` - select only events without matches. `false` - select events only with matches.
   * @param pitchAngleGte Select events where `features.headpose.pitch` is **greater than or equal** to this value
   * @param pitchAngleLte Select events where `features.headpose.pitch` is **less than or equal** to this value
   * @param rollAngleGte Select events where `features.headpose.roll` is **greater than or equal** to this value
   * @param rollAngleLte Select events where `features.headpose.roll` is **less than or equal** to this value
   * @param temperatureGte Select events where `temperature` is **greater than or equal** to this value
   * @param temperatureLte Select events where `temperature` is **less than or equal** to this value
   * @param videoArchive Get events from these video archives
   * @param yawAngleGte Select events where `features.headpose.yaw` is **greater than or equal** to this value
   * @param yawAngleLte Select events where `features.headpose.yaw` is **less than or equal** to this value
   * @returns CountResponse
   * @throws ApiError
   */
  public static eventsFacesCountRetrieve(
    acknowledged?: boolean,
    ageGte?: number,
    ageLte?: number,
    beard?: Array<string>,
    beardScoreGte?: number,
    beardScoreLte?: number,
    bsType?: 'overall' | 'realtime',
    cameraGroups?: Array<number>,
    cameras?: Array<number>,
    caseIn?: Array<number>,
    createdDateGte?: string,
    createdDateLastNDays?: number,
    createdDateLte?: string,
    createdDateNthFullWeek?: number,
    createdDateNthWorkWeek?: number,
    emotions?: Array<string>,
    emotionsScoreGte?: number,
    emotionsScoreLte?: number,
    episode?: number,
    externalDetector?: boolean,
    gender?: Array<string>,
    genderScoreGte?: number,
    genderScoreLte?: number,
    glasses?: Array<string>,
    glassesScoreGte?: number,
    glassesScoreLte?: number,
    hasCase?: boolean,
    id?: number,
    idGte?: number,
    idLte?: number,
    liveness?: Array<string>,
    livenessScoreGte?: number,
    livenessScoreLte?: number,
    look?: Array<string>,
    lookScoreGte?: number,
    lookScoreLte?: number,
    matchedCard?: number,
    matchedLists?: Array<number>,
    medmask?: Array<string>,
    medmaskScoreGte?: number,
    medmaskScoreLte?: number,
    noMatch?: boolean,
    pitchAngleGte?: number,
    pitchAngleLte?: number,
    rollAngleGte?: number,
    rollAngleLte?: number,
    temperatureGte?: number,
    temperatureLte?: number,
    videoArchive?: Array<number>,
    yawAngleGte?: number,
    yawAngleLte?: number
  ): CancelablePromise<CountResponse> {
    return __request({
      method: 'GET',
      path: `/events/faces/count/`,
      query: {
        acknowledged: acknowledged,
        age_gte: ageGte,
        age_lte: ageLte,
        beard: beard,
        beard_score_gte: beardScoreGte,
        beard_score_lte: beardScoreLte,
        bs_type: bsType,
        camera_groups: cameraGroups,
        cameras: cameras,
        case_in: caseIn,
        created_date_gte: createdDateGte,
        created_date_last_n_days: createdDateLastNDays,
        created_date_lte: createdDateLte,
        created_date_nth_full_week: createdDateNthFullWeek,
        created_date_nth_work_week: createdDateNthWorkWeek,
        emotions: emotions,
        emotions_score_gte: emotionsScoreGte,
        emotions_score_lte: emotionsScoreLte,
        episode: episode,
        external_detector: externalDetector,
        gender: gender,
        gender_score_gte: genderScoreGte,
        gender_score_lte: genderScoreLte,
        glasses: glasses,
        glasses_score_gte: glassesScoreGte,
        glasses_score_lte: glassesScoreLte,
        has_case: hasCase,
        id: id,
        id_gte: idGte,
        id_lte: idLte,
        liveness: liveness,
        liveness_score_gte: livenessScoreGte,
        liveness_score_lte: livenessScoreLte,
        look: look,
        look_score_gte: lookScoreGte,
        look_score_lte: lookScoreLte,
        matched_card: matchedCard,
        matched_lists: matchedLists,
        medmask: medmask,
        medmask_score_gte: medmaskScoreGte,
        medmask_score_lte: medmaskScoreLte,
        no_match: noMatch,
        headpose_pitch_angle_gte: pitchAngleGte,
        headpose_pitch_angle_lte: pitchAngleLte,
        roll_angle_gte: rollAngleGte,
        roll_angle_lte: rollAngleLte,
        temperature_gte: temperatureGte,
        temperature_lte: temperatureLte,
        video_archive: videoArchive,
        headpose_yaw_angle_gte: yawAngleGte,
        headpose_yaw_angle_lte: yawAngleLte
      }
    });
  }
}
