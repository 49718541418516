/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CamerasFilter = {
    /**
     * Select only active cameras (`true`) or inactive cameras (`false`)
     */
    active?: boolean;
    /**
     * Select cameras included in these camera groups
     */
    camera_groups?: Array<number>;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select only external detectors (`true`) or cameras (`false`)
     */
    external_detector?: boolean;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select cameras where the SQL condition `name ILIKE name_contains` is true
     */
    name_contains?: string;
    /**
     * Select cameras that support a given type of object: face, body or car
     */
    object_type?: string;
    /**
     * Which field to use when ordering the results. Available fields: `id`, `created_date`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select cameras by status (`gray`, `red`, `yellow` or `green`)
     */
    state_color?: string;
}
export const EmptyCamerasFilter: CamerasFilter = {
    active: undefined,
    camera_groups: [],
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    external_detector: undefined,
    limit: '20',
    name_contains: undefined,
    object_type: undefined,
    ordering: '-id',
    page: undefined,
    state_color: undefined,
}