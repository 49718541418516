import { Status } from '@/api';
import { CameraStatusDetailValue } from './types';

const DEFAULT_CAMERA_STATUS_DETAIL_VALUE = '-';

export function computeCameraStatusDetailFromFramesDropped(status: Status): CameraStatusDetailValue {
  return status.statistic.frames_dropped ?? DEFAULT_CAMERA_STATUS_DETAIL_VALUE;
}

export function computeCameraStatusDetailFromJobStarts(status: Status): CameraStatusDetailValue {
  return status.statistic.job_starts ?? DEFAULT_CAMERA_STATUS_DETAIL_VALUE;
}

export function computeCameraStatusDetailFromObjectsNotPosted(status: Status): CameraStatusDetailValue {
  return String((status.statistic.bodies_not_posted ?? 0) + (status.statistic.cars_not_posted ?? 0) + (status.statistic.faces_not_posted ?? 0));
}

export function computeCameraStatusDetailFromObjectsPosted(status: Status): CameraStatusDetailValue {
  return String((status.statistic.bodies_posted ?? 0) + (status.statistic.cars_posted ?? 0) + (status.statistic.faces_posted ?? 0));
}

export function computeCameraPostedObjectsByType(status: Status, type: string): CameraStatusDetailValue {
  return String((status?.statistic?.[type + '_posted'] ?? 0));
}

export function computeCameraNotPostedObjectsByType(status: Status, type: string): CameraStatusDetailValue {
  return String((status?.statistic?.[type + '_not_posted'] ?? 0));
}

export function computeCameraStatusDetailFromProcessDuration(status: Status): CameraStatusDetailValue {
  return stringifyCameraProcessDuration(status.statistic.processed_duration);
}

export function computeCameraStatusDetailFromStatus(status: Status): CameraStatusDetailValue {
  return status.status;
}

const SECONDS_IN_M = 60;
const SECONDS_IN_H = 60 * SECONDS_IN_M;

function stringifyCameraProcessDuration(duration: number): string {
  const h = Math.trunc(duration / SECONDS_IN_H);
  const s = Math.trunc(h && duration % h);
  const m = Math.trunc((duration - h * SECONDS_IN_H - s) / SECONDS_IN_M);
  return `${pad(h)}:${pad(m)}:${pad(s)}`;
}

function pad(value: number): string {
  return String(value).padStart(2, '0');
}
