import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["accept", "multiple"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NButton, {
      type: _ctx.type,
      icon: _ctx.icon,
      "suffix-icon": _ctx.suffixIcon,
      round: _ctx.round,
      disabled: _ctx.disabled,
      onClick: _withModifiers(_ctx.handlerClick, ["stop"])
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ])
      ]),
      _: 3
    }, 8, ["type", "icon", "suffix-icon", "round", "disabled", "onClick"]),
    _createElementVNode("input", {
      class: "button-file-select_hide",
      accept: _ctx.accept,
      type: "file",
      ref: "inputFile",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeFiles && _ctx.changeFiles(...args))),
      multiple: _ctx.multiple
    }, null, 40, _hoisted_1)
  ], 64))
}