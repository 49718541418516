import ItemCommonFields from '@/components/common/ItemCommonFields.vue';

export function getItemCommonFields() {
  return [
    {
      component: ItemCommonFields,
      props: (item: any) => ({ item })
    }
  ];
}
