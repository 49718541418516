import axios, { AxiosInstance } from 'axios';

export class AxiosInstanceConfig {
  private instance: AxiosInstance = axios.create({});

  setInstance(v: AxiosInstance) {
    this.instance = v;
  }

  getInstance(): AxiosInstance {
    return this.instance;
  }
}

export const axiosInstanceConfig = new AxiosInstanceConfig();