import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "verify-images verify-images_half-w" }
const _hoisted_2 = {
  key: 0,
  class: "verify-images__info"
}
const _hoisted_3 = { class: "verify-images__params" }
const _hoisted_4 = { class: "label-s" }
const _hoisted_5 = { class: "label-s" }
const _hoisted_6 = {
  key: 2,
  class: "verify-images__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NAttachments = _resolveComponent("NAttachments")!
  const _component_DetectionImageViewer = _resolveComponent("DetectionImageViewer")!
  const _component_Features = _resolveComponent("Features")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.file)
      ? (_openBlock(), _createBlock(_component_NAttachments, {
          key: 0,
          i18n: _ctx.attachmentsI18n,
          onAttach: _ctx.uploadHandler,
          "disallow-attach": "",
          drag: "",
          "hide-amount": "",
          "hide-attachments": ""
        }, null, 8, ["i18n", "onAttach"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_DetectionImageViewer, {
            class: "verify-images__viewer",
            actions: [],
            "image-file": _ctx.file,
            zoomable: "",
            "enable-loading": "",
            onSelectedBboxes: _ctx.selectBBox
          }, null, 8, ["image-file", "onSelectedBboxes"]),
          (_ctx.features)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (Object.keys(_ctx.features).length)
                  ? (_openBlock(), _createBlock(_component_Features, {
                      key: "first-features",
                      features: _ctx.features,
                      objects: _ctx.objects,
                      size: "small",
                      columns: ""
                    }, null, 8, ["features", "objects"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.sizeParams), 1),
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('verify.quality')) + ": " + _toDisplayString(_ctx.bboxInfo.quality), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64)),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_NLoadingCircle)
        ]))
      : _createCommentVNode("", true)
  ]))
}