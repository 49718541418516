import { Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CarCard, HumanCard } from '@/api';
import { ListViewModel } from '@/definitions/view-models';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { loadDataModule } from '@/store/data/LoadDataModule';

export default class CardItem extends Vue {
  @Prop({ type: Object, required: true })
  item!: HumanCard | CarCard;

  @Prop({ type: Object })
  modelAcl!: any;

  @Prop({ type: String, default: 'short' })
  displayType!: string;

  @Prop({ type: String, required: true })
  type!: string;

  @Prop({ type: Boolean, default: false })
  selected!: boolean;

  @Prop({ type: Boolean, default: false })
  opened!: boolean;

  @Prop({ type: Boolean, default: true })
  showThumbnail!: boolean;

  @Prop({ type: Boolean, default: true })
  showDate!: boolean;

  @Prop({ type: Boolean, default: true })
  showAttachments!: boolean;

  @Prop({ type: Boolean, default: true })
  showMetafields!: boolean;

  @Prop({ type: Boolean, default: true })
  showInteractions!: boolean;

  @Prop({ type: Boolean, default: true })
  showActions!: boolean;

  objectsVM: ListViewModel<any, any> | null = null;

  get thumbnailLoading() {
    return this.objectsVM?.loading || false;
  }

  @Watch('item.id', { immediate: true })
  load(id: number) {
    if (!id) return;
    const objectType = dataAssetsModule.getObjectTypeByCardType(this.type);
    this.objectsVM = loadDataModule.getObjectsLVMByCardID({ id: this.item.id, limit: 1, type: objectType, ordering: 'id' });
  }

  get thumbnail(): string | null {
    const hasItems = this.objectsVM?.items.length;
    return hasItems ? this.objectsVM?.items[0]?.thumbnail : null;
  }

  get name(): string {
    return this.item.name || this.$t('cards.unknown', 'f');
  }

  get comment() {
    return this.item.comment;
  }

  get styles() {
    const opacity = this.item.active ? 1 : 0.5;
    return { opacity };
  }
}
