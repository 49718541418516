export interface IObjectItem {
  id: string;
  line: number;
  color: string;
  timeFrom: number;
  timeTo: number;
  metadata?: any;
}

export enum ObjectItemType {
  Chunk = 0,
  Event = 1,
  Episode = 2,
  Export = 104
}

export default class TimelineObject {
  public mouseDown = () => {};
  public mouseUp = () => {};
  public mouseMove = () => {};

  public type = 0;
  public isLoadedAll = false;
  public items: IObjectItem[] = [];

  protected async load(cameras: number[], timeFrom: number, timeTo: number) {}
  protected draw(timeFrom: number, timeTo: number) {}

  public async loadWrap(cameras: number[], timeFrom: number, timeTo: number) {
    return await this.load(cameras, timeFrom, timeTo);
  }

  mergeById(oldItems: any[], newItems: any[]) {
    newItems.forEach((newItem) => {
      const index = oldItems.findIndex((item) => item.id === newItem.id);
      if (index !== -1) {
        oldItems[index] = newItem;
      } else {
        oldItems.push(newItem);
      }
    });
  }
}
