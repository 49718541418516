export default {
  name: 'Name',
  ip_address: 'IP address',
  version: 'Version',
  status: 'Status',
  created: 'Date added',
  create_new: 'Add new VMS',
  info: 'Information',
  list_cameras: 'Cameras',
  receive_events: 'Received messages',
  send_events: 'Sending messages',
  vms: 'VMS',
  vms_select: 'External VMS ID',
  camera_select: 'External VMS camera ID',
  stream_select: 'URL stream',
  configure: 'Configure',
  add_selected_cameras: 'Add selected cameras',
  added: 'Added',
  any_camera_group: 'Any camera group',
  any_status: 'Any status',
  not_added: 'Not added',
  select_all_streams: 'Select all streams'
};
