import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "event-attribute-line-cross" }
const _hoisted_2 = {
  key: 0,
  class: "event-attribute-line-cross__count label-2xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AttributeLineCross = _resolveComponent("AttributeLineCross")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maxAttributeItems, (attributeItem, i) => {
      return (_openBlock(), _createBlock(_component_AttributeLineCross, {
        item: attributeItem,
        classes: _ctx.computedItemClasses,
        key: i
      }, null, 8, ["item", "classes"]))
    }), 128)),
    (_ctx.computedShowMaxCountOverflow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "+" + _toDisplayString(_ctx.overflowItemsCount), 1))
      : _createCommentVNode("", true)
  ]))
}