import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Features = _resolveComponent("Features")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.items.faces.display)
      ? (_openBlock(), _createBlock(_component_Features, {
          key: 0,
          objects: "faces",
          features: _ctx.items.faces.features,
          color: _ctx.color,
          size: _ctx.size,
          columns: ""
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "faces-extra-attributes")
          ]),
          _: 3
        }, 8, ["features", "color", "size"]))
      : _createCommentVNode("", true),
    (_ctx.items.bodies.display)
      ? (_openBlock(), _createBlock(_component_Features, {
          key: 1,
          objects: "bodies",
          features: _ctx.items.bodies.features,
          color: _ctx.color,
          size: _ctx.size,
          columns: ""
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "bodies-extra-attributes")
          ]),
          _: 3
        }, 8, ["features", "color", "size"]))
      : _createCommentVNode("", true),
    (_ctx.items.cars.display)
      ? (_openBlock(), _createBlock(_component_Features, {
          key: 2,
          objects: "cars",
          features: _ctx.items.cars.features,
          color: _ctx.color,
          size: _ctx.size,
          columns: ""
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "cars-extra-attributes")
          ]),
          _: 3
        }, 8, ["features", "color", "size"]))
      : _createCommentVNode("", true)
  ], 64))
}