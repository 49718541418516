import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-qa"]
const _hoisted_2 = { class: "n-filters-small__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NForm = _resolveComponent("NForm")!

  return (_openBlock(), _createElementBlock("div", {
    class: "n-filters-small",
    "data-qa": _ctx.dataQa,
    ref: "filters"
  }, [
    _renderSlot(_ctx.$slots, "pre"),
    _createVNode(_component_NForm, {
      "float-form": "",
      layout: _ctx.layout,
      model: _ctx.model,
      class: "n-filters-small__form",
      ref: "form",
      "data-qa": _ctx.dataQa
    }, {
      "float-right": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "actions", {}, () => [
            (_ctx.supportsSearch)
              ? (_openBlock(), _createBlock(_component_NInput, {
                  key: 0,
                  class: "n-filters-small__search",
                  modelValue: _ctx.searchValue,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
                  placeholder: _ctx.$t('common.search', 'f'),
                  style: {"flex-grow":"1"},
                  suffixIcon: "search"
                }, null, 8, ["modelValue", "placeholder"]))
              : _createCommentVNode("", true),
            (_ctx.hasChanges)
              ? (_openBlock(), _createBlock(_component_NButton, {
                  key: 1,
                  type: "secondary",
                  icon: "close",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('resetFilters'))),
                  "data-qa": `${_ctx.dataQa}.reset`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.reset', 'f')), 1)
                  ]),
                  _: 1
                }, 8, ["data-qa"]))
              : _createCommentVNode("", true)
          ]),
          _renderSlot(_ctx.$slots, "before-filter-manager"),
          (_ctx.canCreateReport)
            ? (_openBlock(), _createBlock(_component_NButton, {
                key: 0,
                onClick: _ctx.showReportForm,
                icon: "create-report",
                type: "secondary",
                "data-qa": `${_ctx.dataQa}.report`
              }, null, 8, ["onClick", "data-qa"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "filter-manager")
        ])
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["layout", "model", "data-qa"])
  ], 8, _hoisted_1))
}