
import { computed, nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'SelectableItem',
  components: { NButton }
})
export default class SelectableItem extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly selected!: boolean;

  get buttonType() {
    return this.selected ? 'primary' : 'secondary';
  }

  get buttonIcon() {
    return this.selected ? 'select' : '';
  }

  toggleSelect() {
    this.$emit('select');
  }
}
