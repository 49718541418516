import { VNode } from 'vue';

export type LicensePageMetaItem = {
  content: LicensePageMetaItemContent;
  label: string;
};

export type LicensePageMetaItemContent =
  | LicensePageMetaItemCustomContent
  | LicensePageMetaItemIndicatorContent
  | LicensePageMetaItemMonospacedContent
  | LicensePageMetaItemRegularContent;

export type LicensePageMetaItemCustomContent = {
  content: () => VNode;
  type: 'custom';
};

export type LicensePageMetaItemIndicatorContent = {
  content: {
    disabled: boolean;
    label: string;
  };
  type: 'indicator';
};

export type LicensePageMetaItemMonospacedContent = {
  content: string;
  type: 'monospaced';
};

export type LicensePageMetaItemRegularContent = {
  content: string;
  type: 'regular';
};

export function isLicensePageMetaItemCustomContent(content: LicensePageMetaItemContent): content is LicensePageMetaItemCustomContent {
  return content.type === 'custom';
}

export function isLicensePageMetaItemIndicatorContent(content: LicensePageMetaItemContent): content is LicensePageMetaItemIndicatorContent {
  return content.type === 'indicator';
}

export function isLicensePageMetaItemMonospacedContent(content: LicensePageMetaItemContent): content is LicensePageMetaItemMonospacedContent {
  return content.type === 'monospaced';
}

export function isLicensePageMetaItemRegularContent(content: LicensePageMetaItemContent): content is LicensePageMetaItemRegularContent {
  return content.type === 'regular';
}
