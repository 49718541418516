
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NForm, { FormValidatorNames, IFormContext, IFormModel } from '@/uikit/forms/NForm.vue';
import CardItemRow from '@/components/cards/CardItemRow.vue';
import { NConfidence, NInputNumber } from '@/uikit';
import NDateTimeRange from '@/uikit/datetime/NDateTimeRange.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { viewModelRepository } from '@/api/common';
import { EnrichedSearchRequest } from '@/pages/external-search/requests/enrich-search-requests';
import { EnrichedCard } from '@/components/cards/enrich-cards';
import { getStringLengthValidator } from '@/uikit/forms/validators';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';

@Options({
  name: 'ExternalSearchRequestForm',
  components: { NButton, NForm }
})
export default class ExternalSearchRequestForm extends Vue {
  @Prop({ type: Object, required: false })
  readonly card!: EnrichedCard;

  @Prop({ type: Object, required: false })
  readonly item!: EnrichedSearchRequest;

  get formLayout() {
    return [
      {
        classes: 'n-form-w-4 n-form-pad-10 control-m',
        component: CardItemRow,
        props: (item: any) => ({ item: this.card || this.item.cardObject, closeable: false, type: 'humans' })
      },
      {
        path: 'reason',
        classes: 'n-form-w-6 n-form-pad-10 control-m',
        i18n_label: 'external_search.search_reason',
        component: NTextareaAutosize,
        props: { i18n_placeholder: 'external_search.enter_reason_for_search', disabled: !this.card, minRows: 3, autofocus: true },
        validators: [{ name: FormValidatorNames.Required }, getStringLengthValidator({ required: true, min: 3, max: 512 })]
      },
      {
        path: 'search_params.threshold',
        classes: 'n-form-w-6 n-form-pad-10 control-m',
        i18n_label: 'external_search.confidence_edge',
        component: NConfidence,
        props: { min: 0.65 }
      },
      {
        classes: 'n-form-w-6 n-form-pad-10 control-m',
        i18n_label: 'external_search.interval',
        component: NDateTimeRange,
        props: { mode: 'dateTime', clearable: true },
        encode: function (this: IFormContext, model: IFormModel, value: any) {
          value[0] ? (this.model.search_params.created_date_gte = value[0]) : delete this.model.search_params.created_date_gte;
          value[1] ? (this.model.search_params.created_date_lte = value[1]) : delete this.model.search_params.created_date_lte;
        },
        decode: function (this: IFormContext) {
          return [this.model.search_params.created_date_gte, this.model.search_params.created_date_lte];
        }
      },
      {
        path: 'search_params.limit',
        classes: 'n-form-w-1 n-form-pad-10 control-m',
        i18n_label: 'external_search.results_limit',
        component: NInputNumber,
        props: {}
      }
    ];
  }

  get module() {
    return viewModelRepository.getPuppeteerSearchItemViewModel();
  }

  mounted() {
    if (this.card) {
      this.module.setItemsState({
        id: -1000,
        card: this.card.id,
        reason: '',
        search_params: { threshold: 0.65, limit: 120 },
        save_results: true,
        created_by: 0,
        created_date: '',
        last_search_date: '',
        last_results_count: 0,
        is_deleted: false
      });
    } else if (this.item) {
      this.module.setItemsState(this.item);
    }

    this.$refs.form?.focus();
  }

  async save() {
    if (!this.$refs.form?.validate()) {
      this.$refs.form?.displayErrors();
      return;
    }
    await this.module.save();
    this.$emit('close', this.module.item);
  }
}
