import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video-wall-preset-select-item" }
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoWallLayoutButton = _resolveComponent("VideoWallLayoutButton")!
  const _component_VideoWallPresetSelectItemDeleteButton = _resolveComponent("VideoWallPresetSelectItemDeleteButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VideoWallLayoutButton, {
      layout: _ctx.preset.layout,
      onClick: _ctx.dispatchClickEvent
    }, {
      name: _withCtx(() => [
        _createElementVNode("div", {
          class: "label-2xs video-wall-preset-select-item__name",
          textContent: _toDisplayString(_ctx.preset.name)
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["layout", "onClick"]),
    _createVNode(_component_VideoWallPresetSelectItemDeleteButton, {
      onClick: _ctx.dispatchDeleteEvent,
      class: "video-wall-preset-select-item__delete"
    }, null, 8, ["onClick"])
  ]))
}