import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives(_createElementVNode("div", {
      class: "n-tooltip",
      ref: "floating",
      style: _normalizeStyle(_ctx.wrapStyles)
    }, [
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("div", {
        class: "n-tooltip__arrow",
        ref: "arrow",
        style: _normalizeStyle(_ctx.arrowStyles)
      }, null, 4)
    ], 4), [
      [_vShow, _ctx.shown]
    ])
  ]))
}