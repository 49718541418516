import { BboxConfig, BboxDistance } from '@/uikit/bbox/types';
import { PolyLine } from '@/uikit/draw/types';

export type LineTrackProp = PolyLine | (() => Promise<PolyLine>);

export type ImageViewerAction = typeof ImageViewerActions[keyof typeof ImageViewerActions];
export const ImageViewerActions = {
  Zoom: 'zoom',
  Copy: 'copy',
  Download: 'download',
  Fullscreen: 'fullscreen',
  Close: 'close',
  Detect: 'detect'
} as const;

export type ImageViewerSrc = string | Blob;
export type ImageGalleryItem = {
  src: ImageViewerSrc;
  bboxes?: BboxConfig[];
  distances?: BboxDistance[];
  line?: LineTrackProp;
  track?: LineTrackProp;
};

export type ArrowType = typeof ArrowTypes[keyof typeof ArrowTypes];
export const ArrowTypes = {
  Middle: 'middle',
  TopLeft: 'top-left'
} as const;

export type ScaleOptions = {
  default: number;
  fit: number;
  fill: number;
  min: number;
  max: number;
  wheelStep: number;
};

export type EventBinding = {
  target: Element;
  eventName: string;
  callback: (...args: any[]) => void;
  options?: boolean | AddEventListenerOptions;
};

export type FitFillResult = {
  fit: number;
  fill: number;
  min: number;
  wheelStep: number;
};

export type LayerStyle = {
  transform: string;
  width: string;
  height: string;
};
