
import type { Camera } from '@/api';
import { Options, Prop, Vue } from 'vue-property-decorator';
import { VideoWallCameraStatus } from '../VideoWallCameraStatus';

@Options({
  components: { VideoWallCameraStatus }
})
export default class VideoWallPlayerStatus extends Vue {
  @Prop({ required: true, type: Object })
  readonly camera!: Camera;
}
