/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CaseParticipantAttachmentsFilter = {
    /**
     * Select attachments related to `case_participant`
     */
    case_participant?: number;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Number of results to return.
     */
    limit?: number;
}
export const EmptyCaseParticipantAttachmentsFilter: CaseParticipantAttachmentsFilter = {
    case_participant: undefined,
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    limit: 20,
}