
import { computed, defineComponent } from 'vue';
import NDropdownItem from '../dropdown/NDropdownItem.vue';

import { secondsToTime, timeToSeconds } from './time-convert';

export default defineComponent({
  components: { NDropdownItem },
  props: {
    start: { type: String, default: '00:00' },
    end: { type: String, default: '23:59' },
    step: { type: String, default: '00:30' },
    secondsEnabled: { type: Boolean, default: true }
  },
  emits: ['select'],
  setup(props, { emit }) {
    const items = computed(() => {
      const items: string[] = [];
      const endSeconds = timeToSeconds(props.end);
      const startSeconds = timeToSeconds(props.start);
      const stepSeconds = timeToSeconds(props.step);
      if (stepSeconds > 0 && endSeconds > stepSeconds) {
        let value = startSeconds;
        while (value < endSeconds) {
          let time = secondsToTime(value, props.secondsEnabled);
          items.push(time);
          value += stepSeconds;
        }
        items.push(secondsToTime(endSeconds, props.secondsEnabled));
      }
      return items;
    });

    const selectHandler = (time: string) => emit('select', time);

    return {
      items,
      selectHandler
    };
  }
});
