import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-not-found" }
const _hoisted_2 = { class: "heading-l" }
const _hoisted_3 = { class: "label-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThemeImage = _resolveComponent("NThemeImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('errors.sorry')) + "!", 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('errors.page_notfound')), 1),
    _createVNode(_component_NThemeImage, {
      class: "page-not-found__image",
      "css-var": "--image-not-found-mountain"
    })
  ]))
}