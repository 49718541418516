import CounterDistance from '@/components/counters/CounterDistance.vue';
import CounterRecordInfo from '@/components/counters/CounterRecordInfo.vue';
import NText from '@/uikit/text/NText.vue';
import { ITableColumn } from '@/uikit/table/NTable.vue';
import { NThumbnail } from '@/uikit/thumbnail';

const columns: ITableColumn[] = [
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.frame' }
    },
    content: {
      path: 'thumbnail',
      component: NThumbnail,
      props: { size: 'medium' }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.info' }
    },
    content: {
      component: CounterRecordInfo,
      props: (item: any) => ({ item })
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.faces' }
    },
    content: {
      path: 'faces_count',
      component: NText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.bodies' }
    },
    content: {
      path: 'silhouettes_count',
      component: NText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.cars' }
    },
    content: {
      path: 'cars_count',
      component: NText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'counters.distance' }
    },
    content: {
      component: CounterDistance,
      props: (item: any) => ({ item })
    }
  }
];

export default columns;
