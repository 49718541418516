/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { CaseParticipantLooksLike } from "./CaseParticipantLooksLike";
import { RoleEnum } from "./RoleEnum";

export type CaseParticipant = {
    readonly id: number;
    /**
     * Case participant creation date
     */
    readonly created_date: string;
    /**
     * Case participant modification date
     */
    readonly modified_date: string;
    /**
     * Case participant name, up to 256 characters
     */
    name: string;
    /**
     * Comment, up to 2048 characters
     */
    comment?: string;
    /**
     * Related case
     */
    case: number;
    role: RoleEnum;
    face_cluster?: number | null;
    car_cluster?: number | null;
    readonly looks_like: CaseParticipantLooksLike | null;
    readonly looks_like_confidence: number;
    related_car_cards?: Array<number>;
    related_human_cards?: Array<number>;
    related_participants?: Array<number>;
};
export const EmptyCaseParticipant: CaseParticipant = {
    id: 0,
    /**
     * Case participant creation date
     */
    created_date: '',
    /**
     * Case participant modification date
     */
    modified_date: '',
    /**
     * Case participant name, up to 256 characters
     */
    name: '',
    /**
     * Comment, up to 2048 characters
     */
    comment: '',
    /**
     * Related case
     */
    case: 0,
    role: RoleEnum.IRRELEVANT,
    face_cluster: 0,
    car_cluster: 0,
    looks_like: null,
    looks_like_confidence: 0,
    related_car_cards: [],
    related_human_cards: [],
    related_participants: [],
};
