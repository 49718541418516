
import { isDefined, isStringWithValue } from '@/common/utils';
import { computeLicenseUsageIntervalCounterRelativeLimitSpending, computeLicenseUsageIntervalLimitSpending } from '@/pages/license/utils';
import { defineComponent } from 'vue';
import { bool, object } from 'vue-types';
import { LicenseUsageInterval, LicenseUsageIntervalCounterRelativeLimitSpending } from '../../../types';
import LicensePageTabViewIntervalsListItemDate from './LicensePageTabViewIntervalsListItemDate.vue';
import LicensePageTabViewIntervalsListItemIndicator from './LicensePageTabViewIntervalsListItemIndicator.vue';

export default defineComponent({
  name: 'LicensePageTabViewIntervalsListItem',
  components: {
    LicensePageTabViewIntervalsListItemDate,
    LicensePageTabViewIntervalsListItemIndicator
  },
  props: {
    interval: object<LicenseUsageInterval>().isRequired,
    selected: bool().def(false)
  },
  emits: ['click'],
  computed: {
    classes() {
      const root = 'license-page-tab-view-intervals-list-item';
      return {
        [root]: true,
        [`${root}_selected`]: this.selected
      };
    },
    since() {
      return isStringWithValue(this.interval.since) ? new Date(this.interval.since) : null;
    },
    till() {
      return isStringWithValue(this.interval.till) ? new Date(this.interval.till) : null;
    },
    spending(): LicenseUsageIntervalCounterRelativeLimitSpending {
      const spending = computeLicenseUsageIntervalLimitSpending(this.interval);
      return isDefined(spending) ? computeLicenseUsageIntervalCounterRelativeLimitSpending(spending) : 'exceed';
    }
  },
  methods: {
    dispatchClickEvent(): void {
      this.$emit('click');
    }
  }
});
