import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "fullscreen-button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleFullscreen && _ctx.toggleFullscreen(...args)))
  }, [
    (_ctx.isFullscreen)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 0,
          name: "vp_fullscreen_off",
          width: "100%",
          height: "100%"
        }))
      : (_openBlock(), _createBlock(_component_NIcon, {
          key: 1,
          name: "vp_fullscreen_enter",
          width: "100%",
          height: "100%"
        }))
  ]))
}