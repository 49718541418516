import { toPng } from 'html-to-image/lib/index';
import { KycTimeToken } from '@/store/kyc/types';

export const AreaColorsByType = {
  visitors: [['#eee580', '#8ac3a8']],
  gender: [['#4a90e2', '#95bbe8'], '#f23cac'],
  visits: ['#dade88', '#f9636d'],
  age: [['#CD9372', '#886EDD']]
};

export const GradientColors = {
  all: [
    { offset: 1, color: '#EDE388' },
    { offset: 0, color: '#EDE388' }
  ],
  unique: [
    { offset: 1, color: '#8CC3A8' },
    { offset: 0, color: '#8CC3A8' }
  ],
  default: [
    { offset: 1, color: '#555555' },
    { offset: 0, color: '#bbbbbb' }
  ]
};

export const LineColorToken = '--color-primary-100';
export const TextColorToken = '--color-primary-400';
export const TooltipColorToken = '--color-on-color-100';
export const TooltipBackgroundColorToken = '--color-still';
export const GraphImageBackgroundColorToken = '--color-level-100';

export const AxisPointerOpacity = 0.4;

export const DefaultColors = ['blue', 'red', 'blue'];

export const StatisticsDescriptions = {
  gender: ['kyc.male', 'kyc.female'],
  visits: ['kyc.new', 'kyc.returning'],
  age: undefined,
  visitors: undefined
};

export const PrecisionFormatters: Record<KycTimeToken, string> = {
  '30s': 'YYYY-MM-DD HH:mm',
  '1m': 'YYYY-MM-DD HH:mm',
  '30m': 'YYYY-MM-DD HH:mm',
  '3h': 'YYYY-MM-DD HH:mm',
  '6h': 'YYYY-MM-DD HH:mm',
  '12h': 'YYYY-MM-DD HH:mm',
  '1h': 'YYYY-MM-DD HH:mm',
  '1d': 'YYYY-MM-DD',
  '7d': 'YYYY-MM-DD',
  '1M': 'YYYY-MM',
  '3M': 'YYYY-MM',
  '1y': 'YYYY-MM'
};

export const SeriesNameToValueToken: Record<any, any> = {
  male: 'kyc.male_pf',
  female: 'kyc.female_pf',
  age: 'kyc.age_pf',
  visitors: 'kyc.visitors_pf',
  visits: 'kyc.visits_pf',
  new: 'kyc.new_visits_pf',
  returning: 'kyc.returning_visits_pf'
};

export function getThemeTokenValue(token: string) {
  return window.getComputedStyle(document.documentElement).getPropertyValue(token);
}

const ForbiddenElementsClassName = 'kyc-chart-actions';

export function createGraphImage(graphRootElement: HTMLElement) {
  return toPng(graphRootElement, {
    backgroundColor: getThemeTokenValue(GraphImageBackgroundColorToken),
    filter: shouldElementBeRendered
  });
}

export function downloadGraphImageByObjectUrl(objectUrl: string, imageName: string) {
  const a = document.createElement('a');
  a.download = imageName;
  a.href = objectUrl;
  a.click();
}

function shouldElementBeRendered(node: HTMLElement) {
  return isHTMLElement(node) ? !node.classList.contains(ForbiddenElementsClassName) : true;
}

function isHTMLElement(node: HTMLElement) {
  return node.nodeType === Node.ELEMENT_NODE;
}
