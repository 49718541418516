import { AuditLogsService } from '@/api';
import { AuditLogsFilter } from '@/api/models/AuditLogsFilter';

const FORBIDDEN_ACTION_TYPES: Set<AuditLogsActionType> = new Set(['list']);

export type AuditLogsActionType = InferedArrayGenericType<AuditLogsFilter['action_type']>;

export type AuditLogsObjectActionsMap = Record<AuditLogsObjectType, AuditLogsActionType[]>;

export type AuditLogsObjectType = InferedArrayGenericType<AuditLogsFilter['object_type']>;

type InferedArrayGenericType<T> = T extends Array<infer K> ? K : never;

export async function fetchAuditLogsObjectTypes(): Promise<AuditLogsObjectType[]> {
  return Object.keys(await fetchAuditLogsObjectActionsMap()) as AuditLogsObjectType[];
}

export function fetchAuditLogsObjectActionsMap(): Promise<AuditLogsObjectActionsMap> {
  return AuditLogsService.auditLogsMapRetrieve() as unknown as Promise<AuditLogsObjectActionsMap>;
}

export function matchAuditLogsObjectTypesWithActions(map: AuditLogsObjectActionsMap, objects: AuditLogsObjectType[]): AuditLogsActionType[] {
  const actions = objects.length ? objects.map((object) => map[object]).flat() : Object.values(map).flat();
  const matchedActionsSet = new Set(actions);

  return [...matchedActionsSet].filter(
    (action) => !FORBIDDEN_ACTION_TYPES.has(action)
  );
}
