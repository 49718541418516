/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ExternalVmsEventsFilter = {
            /**
            * Select events from these camera
            */
        camera?: Array<number>;
            /**
            * Select objects with `created_date` **greater** than this value
            */
        created_date_gt?: string;
            /**
            * Select objects with `created_date` **greater than or equal** to this value
            */
        created_date_gte?: string;
            /**
            * Select objects with `created_date` **less** than this value
            */
        created_date_lt?: string;
            /**
            * Select objects with `created_date` **less than or equal** to this value
            */
        created_date_lte?: string;
            /**
            * Select events from these external vms
            */
        external_vms?: Array<number>;
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Select external-vms events where the SQL condition `name ILIKE name_contains` is true
            */
        name?: string;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
            /**
            * Select external-vms events entries with this `type`
            */
        type?: string;
}
export const EmptyExternalVmsEventsFilter: ExternalVmsEventsFilter = {
        camera: [],
        created_date_gt: undefined,
        created_date_gte: undefined,
        created_date_lt: undefined,
        created_date_lte: undefined,
        external_vms: [],
        limit: '20',
        name: undefined,
        ordering: undefined,
        page: undefined,
        type: undefined,
}