import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (!_ctx.item.acknowledged)
    ? (_openBlock(), _createBlock(_component_NButton, {
        key: 0,
        type: "secondary",
        onClick: _withModifiers(_ctx.toggleAcknowledge, ["stop"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.acknowledge', 'f')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]))
    : (_ctx.showCancel)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 1,
          type: "secondary",
          onClick: _withModifiers(_ctx.toggleAcknowledge, ["stop"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.cancel', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
}