
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { dataModule } from '@/store/data';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  components: { NButton }
})
export default class UserGroupCell extends Vue {
  @Prop({ type: Number, required: true })
  readonly modelValue!: number;

  get groups() {
    return dataModule.groupsModule.items;
  }

  get name() {
    let group = this.groups.find((v) => v.id === this.modelValue);
    return group ? group.name : '';
  }

  handleClick() {
    this.$emit('update:modelValue', this.modelValue);
  }
}
