import SimpleTimer from "@/components/video-player/utils/simple-timer";

export default class AutoUpdateTimer {
  private callback: any;
  private timeout = 0;
  private timer = new SimpleTimer();

  setCallback(callback: any) {
    this.callback = callback;
  }
  setAutoUpdate(timeout: number) {
    this.clear();
    if (timeout) {
      this.timeout = timeout;
      this.timer.setInterval(this.callback, timeout);
    }
  }
  clear() {
    this.timer.clear();
  }
}
