
import { asDefined, isDefined } from '@/common/utils';
import { Options, Prop, Ref, Vue } from 'vue-property-decorator';
import type { CameraId, Nullable } from '../../types';
import { dragCameraId, dropCameraId } from '../../utils';
import VideoWallArea from './VideoWallArea.vue';

@Options({
  components: { VideoWallArea },
  emits: ['update:cameraIds'],
  methods: { isDefined }
})
export default class VideoWall extends Vue {
  @Prop({ required: true, type: Array })
  readonly cameraIds!: Nullable<CameraId>[];
  @Ref()
  readonly wall!: HTMLDivElement;

  start: Nullable<number> = null;

  mounted(): void {
    this.attachGlobalThisEventListeners();
  }

  beforeUnmount(): void {
    this.detachGlobalThisEventListeners();
  }

  attachGlobalThisEventListeners(): void {
    addEventListener('dragover', this.dragover);
    addEventListener('drop', this.drop);
  }

  detachGlobalThisEventListeners(): void {
    removeEventListener('dragover', this.dragover);
    removeEventListener('drop', this.drop);
  }

  dragend(): void {
    this.start = null;
  }

  dragover(event: DragEvent): void {
    event.preventDefault();
  }

  dragstart(event: DragEvent, index: number): void {
    dragCameraId(event, asDefined(this.cameraIds[(this.start = index)]));
  }

  drop(event: DragEvent, index: Nullable<number> = null): void {
    const cameraId = dropCameraId(event);

    if (this.shouldCameraBeAdded(cameraId, index)) {
      this.add(asDefined(cameraId), asDefined(index));
    } else if (this.shouldCameraBeMoved(cameraId, index)) {
      event.stopPropagation();
      this.move(asDefined(this.start), asDefined(index));
    } else if (this.shouldCameraBeRemoved(cameraId, index)) {
      this.remove(asDefined(this.start));
    }

    event.preventDefault();
  }

  shouldCameraBeAdded(cameraId: Nullable<CameraId>, index: Nullable<number>): boolean {
    return !isDefined(this.start) && isDefined(cameraId) && isDefined(index);
  }

  shouldCameraBeMoved(cameraId: Nullable<CameraId>, index: Nullable<number>): boolean {
    return isDefined(this.start) && isDefined(cameraId) && isDefined(index);
  }

  shouldCameraBeRemoved(cameraId: Nullable<CameraId>, index: Nullable<number>): boolean {
    return isDefined(this.start) && isDefined(cameraId) && !isDefined(index);
  }

  add(cameraId: CameraId, index: number): void {
    this.dispatchUpdateCameraIdsEvent(this.cameraIds.map((id, i) => (i !== index ? id : cameraId)));
  }

  move(start: number, end: number): void {
    const ids = [...this.cameraIds];
    this.dispatchUpdateCameraIdsEvent((([ids[start], ids[end]] = [ids[end], ids[start]]), ids));
  }

  remove(index: number): void {
    this.dispatchUpdateCameraIdsEvent(this.cameraIds.map((id, i) => (i !== index ? id : null)));
  }

  requestFullScreen(): Promise<void> {
    return this.wall.requestFullscreen();
  }

  private dispatchUpdateCameraIdsEvent(cameraIds: Nullable<CameraId>[]): void {
    this.$emit('update:cameraIds', cameraIds);
  }
}
