
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NDateInput } from '@/uikit';
import { formatDate, formatTimeHHMM } from '@/common/filters';
import { DateTimeFormat, DateTimeFormats, EmitType, EmitTypes } from '@/uikit/datetime/types';

@Options({
  name: 'NFilterSmallDateTime',
  components: {
    NDateInput
  },
  emits: ['update:modelValue']
})
export default class NFilterSmallDateTime extends Vue {
  @Prop({ type: String })
  label: string = '';

  @Prop({ type: Object, required: true })
  modelValue?: any;

  @Prop({ type: Boolean, default: true })
  timeEnabled!: boolean;

  @Prop({ type: String, default: EmitTypes.DateObject })
  readonly emitType!: EmitType;

  @Prop({ type: String, default: DateTimeFormats.ISO })
  readonly emitStringFormat!: DateTimeFormat;

  @Prop({ type: String })
  readonly size?: string;

  get model() {
    return this.modelValue;
  }

  set model(value) {
    this.$emit('update:modelValue', value);
  }

  get placeholders() {
    return {
      datePlaceholder: formatDate(new Date(0), 'DD.MM.YYYY'),
      timePlaceholder: formatTimeHHMM(new Date(0))
    };
  }
}
