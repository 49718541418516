/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SearchResult = {
    readonly id: number;
    /**
     * Search request
     */
    request: number;
    /**
     * External face ID
     */
    external_face_id: string;
    /**
     * Search result face object
     */
    face_object_id?: string | null;
    /**
     * Search result puppet ID
     */
    puppet_id: string;
    /**
     * Search result camera
     */
    camera?: string | null;
    /**
     * Search result matched threshold
     */
    confidence: number;
    /**
     * Search result bbox
     */
    bbox: Record<string, any>;
    /**
     * Search result labels
     */
    labels: Record<string, any>;
    /**
     * Search result timestamp
     */
    external_timestamp: string;
    /**
     * Search result created date
     */
    readonly created_date: string;
    /**
     * Search result fullframe image
     */
    fullframe?: string | null;
    /**
     * Search result thumbnail image
     */
    thumbnail: string;
    readonly card: string;
};
export const EmptySearchResult: SearchResult = {
    id: 0,
    /**
     * Search request
     */
    request: 0,
    /**
     * External face ID
     */
    external_face_id: '',
    /**
     * Search result face object
     */
    face_object_id: null,
    /**
     * Search result puppet ID
     */
    puppet_id: '',
    /**
     * Search result camera
     */
    camera: null,
    /**
     * Search result matched threshold
     */
    confidence: 0,
    /**
     * Search result bbox
     */
    bbox: {},
    /**
     * Search result labels
     */
    labels: {},
    /**
     * Search result timestamp
     */
    external_timestamp: '',
    /**
     * Search result created date
     */
    created_date: '',
    /**
     * Search result fullframe image
     */
    fullframe: null,
    /**
     * Search result thumbnail image
     */
    thumbnail: '',
    card: '',
};
