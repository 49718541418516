import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card-item-tile__content" }
const _hoisted_2 = { class: "card-item-tile__name-container" }
const _hoisted_3 = {
  key: 0,
  class: "card-item-tile__ellipsis label-mono-m"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_WatchListsGroup = _resolveComponent("WatchListsGroup")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes),
    style: _normalizeStyle(_ctx.styles),
    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('select')), ["stop"]))
  }, [
    (_ctx.showThumbnail)
      ? (_openBlock(), _createBlock(_component_NThumbnail, {
          key: 0,
          loading: _ctx.thumbnailLoading,
          modelValue: _ctx.thumbnail
        }, null, 8, ["loading", "modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "card-item-tile__name heading-m",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-on-name')))
        }, _toDisplayString(_ctx.name), 1),
        (_ctx.showInteractions)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 0,
              type: _ctx.selected ? 'text' : 'link',
              icon: "relations",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action', 'show-interactions', _ctx.item)))
            }, null, 8, ["type"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.item.license_plate_number)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.item.license_plate_number), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_WatchListsGroup, {
        items: _ctx.item.watch_lists,
        type: _ctx.displayType
      }, null, 8, ["items", "type"])
    ])
  ], 6))
}