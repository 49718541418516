
import { Camera, CameraGroup } from '@/api';
import { asDefined, isDefined } from '@/common/utils';
import { PagePaths } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import NButton from '@/uikit/buttons/NButton.vue';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Options, Prop, Ref, Vue } from 'vue-property-decorator';
import { Repeater } from '../reports/repeater';
import {
  VideoWall,
  VideoWallCameraList,
  VideoWallEpisodeList,
  VideoWallLayoutSelect,
  VideoWallPageLayout,
  VideoWallPageSidebar,
  VideoWallPageSidebarSection,
  VideoWallPlayer,
  VideoWallPresetEditor,
  VideoWallPresetSaver,
  VideoWallPresetSelect
} from './components';
import { isSidebarSectionVisible } from './components/VideoWallPageLayout/utils';
import { getVideoWallPresetsModuleInstance } from './modules';
import { CameraId, Nullable, VideoWallLayout, VideoWallPageLayoutState, VideoWallPageState, VideoWallPreset } from './types';
import { fetchCameraGroups, fetchCameras } from './utils';

@Options({
  components: {
    NButton,
    VideoWall,
    VideoWallCameraList,
    VideoWallEpisodeList,
    VideoWallLayoutSelect,
    VideoWallPageLayout,
    VideoWallPageSidebar,
    VideoWallPageSidebarSection,
    VideoWallPlayer,
    VideoWallPresetEditor,
    VideoWallPresetSaver,
    VideoWallPresetSelect
  }
})
export default class VideoWallPage extends Vue {
  @Prop({ required: true, type: String })
  readonly tab!: string;
  @Ref()
  readonly wall!: VideoWall;
  @Ref()
  readonly layout!: VideoWallPageLayout;

  cameraGroups: CameraGroup[] = [];
  cameras: Camera[] = [];
  presetsModule = getVideoWallPresetsModuleInstance();
  repeater = new Repeater(3e4, () => this.fetch());

  activated(): void {
    this.repeater.start();
  }

  beforeUnmount(): void {
    this.repeater.pause();
  }

  deactivated(): void {
    this.repeater.pause();
  }

  mounted(): void {
    this.repeater.start();
  }

  get state(): VideoWallPageState {
    return pageModule.getPageStateByTab(PagePaths.VideoWall, this.tab) as VideoWallPageState;
  }

  get preset(): VideoWallPreset {
    return this.state.preset;
  }

  get presetPrototype(): Nullable<VideoWallPreset> {
    return this.presetsModule.presets.find(({ id }) => this.state.preset.id === id) ?? null;
  }

  get hasVideoWallPresetBeenModified(): boolean {
    return !isEqual(this.state.preset, this.presetPrototype);
  }

  get arePresetsSectionVisible(): boolean {
    return this.presetsModule.presets.length > 0;
  }

  get areEpisodesVisible(): boolean {
    return this.enabledCameraIds.some(isDefined);
  }

  get enabledCameraIds(): CameraId[] {
    return this.preset.cameraIds.filter(isDefined).filter((id) => Boolean(this.getCameraById(id)?.active));
  }

  handleSidebarToggle(): void {
    this.state.layout = computeVideoWallPageLayoutStateAfterSidebarToggle(this.state.layout);
  }

  handleVideoWallPresetDeleteEvent(preset: VideoWallPreset): void {
    this.presetsModule.remove(preset.id);
  }

  handleVideoWallPresetSelectEvent(preset: VideoWallPreset): void {
    this.state.preset = cloneDeep(preset);
    this.state.layout = computeVideoWallPageLayoutStateAfterLayoutChange(this.state.preset.layout, this.state.layout);
  }

  handleVideoWallPresetSaveEvent(name: string): void {
    if (this.hasVideoWallPresetBeenModified) {
      const preset = this.presetsModule.create({ cameraIds: this.preset.cameraIds, layout: this.preset.layout }, name);
      this.state.preset = cloneDeep(preset);
    } else {
      this.preset.name = asDefined(this.presetPrototype).name = name;
    }
  }

  handleVideoWallLayoutChange(layout: VideoWallLayout): void {
    this.state.preset = this.computeModifiedVideoWallPreset(layout);
    this.state.layout = computeVideoWallPageLayoutStateAfterLayoutChange(layout, this.state.layout);
  }

  requestFullScreen(): void {
    this.layout.requestFullScreen();
  }

  getCameraById(id: CameraId): Nullable<Camera> {
    return this.cameras.find((camera) => camera.id === id) ?? null;
  }

  private async fetch(): Promise<void> {
    await this.fetchCameraGroups();
    await this.fetchCameras();
  }

  private async fetchCameraGroups(): Promise<void> {
    this.cameraGroups = await fetchCameraGroups().catch(() => []);
  }

  private async fetchCameras(): Promise<void> {
    this.cameras = await fetchCameras().catch(() => []);
  }

  private computeModifiedVideoWallPreset(layout: VideoWallLayout): VideoWallPreset {
    const amount = computeVideoWallPresetCameraIdsAmount(layout);
    const cameraIds = this.preset.cameraIds.filter(isDefined);
    const ids = amount > cameraIds.length ? [...cameraIds, ...new Array(amount - cameraIds.length).fill(null)] : cameraIds.slice(0, amount);
    return { ...this.preset, cameraIds: ids as any, layout };
  }
}

function computeVideoWallPageLayoutStateAfterSidebarToggle(state: VideoWallPageLayoutState): VideoWallPageLayoutState {
  switch (state) {
    case 'videowall-and-episodes':
      return 'videowall-with-episodes-and-sidebar';
    case 'videowall-and-sidebar':
      return 'videowall-only';
    case 'videowall-only':
      return 'videowall-and-sidebar';
    case 'videowall-with-episodes-and-sidebar':
      return 'videowall-and-episodes';
  }
}

function computeVideoWallPresetCameraIdsAmount(layout: VideoWallLayout): number {
  switch (layout) {
    case '1_camera_and_episodes':
    case '1_camera':
      return 1;
    case '2_cameras_and_episodes':
      return 2;
    case '3_cameras_and_episodes':
      return 3;
    case '4_cameras_and_episodes':
    case '4_cameras':
      return 4;
    case '6_cameras':
      return 6;
    case '9_cameras':
      return 9;
  }
}

function computeVideoWallPageLayoutStateAfterLayoutChange(layout: VideoWallLayout, state: VideoWallPageLayoutState): VideoWallPageLayoutState {
  switch (layout) {
    case '1_camera_and_episodes':
    case '2_cameras_and_episodes':
    case '3_cameras_and_episodes':
    case '4_cameras_and_episodes':
      return isSidebarSectionVisible(state) ? 'videowall-with-episodes-and-sidebar' : 'videowall-and-episodes';
    case '1_camera':
    case '4_cameras':
    case '6_cameras':
    case '9_cameras':
      return isSidebarSectionVisible(state) ? 'videowall-and-sidebar' : 'videowall-only';
  }
}
