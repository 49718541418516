import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sessions-and-blocklist-tabs__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTabs = _resolveComponent("NTabs")!

  return (_openBlock(), _createBlock(_component_NTabs, {
    items: _ctx.enabledTabs,
    modelValue: _ctx.tabName,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tabName) = $event)),
    class: "sessions-and-blocklist-tabs",
    postfix: true
  }, {
    postfix: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["items", "modelValue"]))
}