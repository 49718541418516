import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageContentLayout = _resolveComponent("PageContentLayout")!

  return (_openBlock(), _createBlock(_component_PageContentLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, "Empty Page: " + _toDisplayString(_ctx.$route.name), 1)
    ]),
    _: 1
  }))
}