/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PermissionsWatchListsFilter = {
            /**
            * Number of results to return.
            */
        limit?: number;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `id`
            */
        ordering?: string;
}
export const EmptyPermissionsWatchListsFilter: PermissionsWatchListsFilter = {
        limit: 20,
        ordering: undefined,
}