/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Permission = {
    readonly id: number;
    readonly name: string;
    readonly codename: string;
    readonly permission_id: string;
};
export const EmptyPermission: Permission = {
    id: 0,
    name: '',
    codename: '',
    permission_id: '',
};
