import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFilterManager = _resolveComponent("NFilterManager")!

  return (_openBlock(), _createBlock(_component_NFilterManager, _mergeProps(_ctx.labels, {
    items: _ctx.filtersList,
    placeholder: _ctx.placeholder,
    selected: _ctx.selectedId,
    onAdd: _ctx.addHandler,
    onSelect: _ctx.selectHandler,
    onDelete: _ctx.deleteHandler,
    onUndo: _ctx.undoHandler,
    onHide: _ctx.hideHandler
  }), {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.labels.label), 1)
    ]),
    _: 1
  }, 16, ["items", "placeholder", "selected", "onAdd", "onSelect", "onDelete", "onUndo", "onHide"]))
}