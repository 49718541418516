import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createBlock(_component_NHint, { maxWidth: _ctx.maxWidth }, {
    default: _withCtx(() => [
      _createVNode(_component_NIcon, { name: _ctx.name }, null, 8, ["name"])
    ]),
    content: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.content), 1)
    ]),
    _: 1
  }, 8, ["maxWidth"]))
}