export type WatchListItemTextType = typeof WatchListItemTextTypes[keyof typeof WatchListItemTextTypes];

export const WatchListItemTextTypes = {
  Link: 'link',
  Common: 'common'
} as const;

export type WatchListItemType = typeof WatchListItemTypes[keyof typeof WatchListItemTypes];

export const WatchListItemTypes = {
  Short: 'short',
  Full: 'full'
} as const;

export type WatchListItemMarker = typeof WatchListItemMarkers[keyof typeof WatchListItemMarkers];

export const WatchListItemMarkers = {
  Circle: 'circle',
  Stick: 'stick'
} as const;

export type WatchListTextSize = typeof WatchListTextSizes[keyof typeof WatchListTextSizes];

export const WatchListTextSizes = {
  Medium: 'medium',
  Normal: 'normal',
  Small: 'small'
} as const;
