
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoginLayout',
  props: {
    startWide: {
      default: false,
      type: Boolean
    }
  }
});
