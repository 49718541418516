/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SessionsFilter = {
    /**
     * Select only active (`true`) or inactive (`false`) sessions. Inactive sessions appear only if `face_and_password` authentication is set
     */
    active?: boolean;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select sessions with provided ids
     */
    id_in?: Array<string>;
    /**
     * Select sessions with `ip` in these **subnets** / **IP addresses** (both supported)
     */
    ip_in?: Array<string>;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select only mobile (`true`) or desktop (`false`) sessions
     */
    mobile?: boolean;
    /**
     * Which field to use when ordering the results. Available fields: `created`, `last_ping_date`, `mobile`, `status`, `user__username`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Session status
     */
    status?: Array<'blocked' | 'offline' | 'online'>;
    /**
     * Select sessions related to users with provided `id`s
     */
    user_id_in?: Array<number>;
    /**
     * Select sessions related to users with provided `user_name`s
     */
    user_name_in?: Array<string>;
    /**
     * Select sessions related to one or multiple `uuid`s
     */
    uuid_in?: Array<string>;
}
export const EmptySessionsFilter: SessionsFilter = {
    active: undefined,
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    id_in: [],
    ip_in: [],
    limit: '20',
    mobile: undefined,
    ordering: undefined,
    page: undefined,
    status: [],
    user_id_in: [],
    user_name_in: [],
    uuid_in: [],
}