import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "participant-item" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "participant-item__thumbnail" }
const _hoisted_4 = { class: "participant-item__role" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaseDescription = _resolveComponent("CaseDescription")!
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_WatchListItem = _resolveComponent("WatchListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.caseItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CaseDescription, { item: _ctx.caseItem }, null, 8, ["item"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NThumbnail, {
        modelValue: _ctx.thumbnail,
        corners: "circle",
        onAction: _ctx.actionHandler,
        onClick: _withModifiers(_ctx.thumbnailClickHandler, ["stop"])
      }, null, 8, ["modelValue", "onAction", "onClick"])
    ]),
    _createElementVNode("div", {
      class: "participant-item__name heading-s",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select')))
    }, _toDisplayString(_ctx.item.name), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_WatchListItem, {
        name: _ctx.computeName(_ctx.item.role),
        color: _ctx.computeColor(_ctx.item.role)
      }, null, 8, ["name", "color"])
    ])
  ]))
}