import { asDefined } from '@/common/utils';
import { getCurrentItemStateFromUrl } from '@/pages/search/helpers';
import { ObjectsTypesMap } from '@/store/application/data.assets';
import { EventsService } from '@/api';

export function fetchImageAsBlob(src: string): Promise<File> {
  return fetch(src)
    .then((response) => response.blob())
    .then((blob) => new File([blob], extractFileNameFromUrl(src)));
}

export function extractFileNameFromUrl(url: string): string {
  const regexp = /^.+\/(.+\..+$)/i;
  const [, name] = asDefined(regexp.exec(url), 'The target URL does not contain a filename.');
  return name;
}

export function parseUrl(url: string) {
  const itemState = getCurrentItemStateFromUrl(url);
  if (itemState.pageType !== 'events') return '';
  return {
    eventId: itemState.id,
    object: itemState.objectType
  };
}

export async function extractFullFrameFromEvent(parsedUrl: any): Promise<File> {
  const { eventId, object } = parsedUrl;
  let fullframe = '';

  switch (object) {
    case ObjectsTypesMap.Faces:
      fullframe = await EventsService.eventsFacesRetrieve(eventId).then(({ fullframe }) => fullframe);
      break;
    case ObjectsTypesMap.Bodies:
      fullframe = await EventsService.eventsBodiesRetrieve(eventId).then(({ fullframe }) => fullframe);
      break;
    case ObjectsTypesMap.Cars:
      fullframe = await EventsService.eventsCarsRetrieve(eventId).then(({ fullframe }) => fullframe);
      break;
  }

  return fetchImageAsBlob(fullframe);
}
