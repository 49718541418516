
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { BodyObjectUpdate, CarCard, CardsService, CarObjectUpdate, FaceObjectUpdate, HumanCard, ObjectsService } from '@/api';
import { BodyCluster } from '@/api/models/BodyCluster';
import { CarCluster } from '@/api/models/CarCluster';
import { FaceCluster } from '@/api/models/FaceCluster';
import { ClusterView } from '@/components/clusters/adapter';
import WatchListGroup from '@/components/common/WatchListsGroup.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { loadDataModule } from '@/store/data/LoadDataModule';
import NButton from '@/uikit/buttons/NButton.vue';
import { multisidebarModule } from '@/store/multisidebar';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { actionHandler } from '@/store/data/ActionHandler';

@Options({
  name: 'ClusterItem',
  components: { NButton, WatchListGroup, NThumbnail }
})
export default class InteractionClusterItem extends Vue {
  @Prop({ type: Object, required: true })
  item!: FaceCluster | BodyCluster | CarCluster;

  @Prop({ type: Number })
  index?: number;

  @Prop({ type: Boolean, default: false })
  selected!: boolean;

  @Prop({ type: String, required: true })
  objects!: string;

  type: string = 'clusters';

  card: HumanCard | CarCard | null = null;

  async mounted() {
    this.setCard(this.item.card);
  }

  get thumbnail() {
    return (this.item.best_event as any)?.thumbnail;
  }

  get clusterView(): ClusterView {
    return this.item;
  }

  get cardName() {
    return this.card?.name;
  }

  get classes() {
    return {
      'interaction-cluster-item_selected': this.selected
    };
  }

  @Watch('item.card')
  async setCard(id?: number): Promise<void> {
    this.card = id ? await loadDataModule.getCardByID({ id, type: 'humans' }) : undefined;
  }

  showMsbItem() {
    actionHandler.run(ItemsActionNames.ShowItem, { type: 'clusters_faces', rawItem: this.item });
  }

  showMsbCardItem() {
    actionHandler.run(ItemsActionNames.ShowItem, { type: 'cards_humans', rawItem: this.card });
  }
}
