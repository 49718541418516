import { AbsoluteBox } from '@/uikit/bbox/types';

export type DetectItem = {
  id: string;
  bbox: AbsoluteBox;
  detection_score: number;
  features: Record<string, any>;
  low_quality: boolean;
};

export type DetectResult = {
  orientation: number;
  objects: {
    [key in DetectType]: DetectItem[];
  };
};

export enum ObjectType {
  UserFace = 'user-face',
  Car = 'car',
  Body = 'body',
  Face = 'face'
}

export enum DetectType {
  Face = 'face',
  Body = 'body',
  Car = 'car'
}
