import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "DataSourcesOnvifAuth" }
const _hoisted_2 = { class: "DataSourcesOnvifAuth__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NHint = _resolveComponent("NHint")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createBlock(_component_NDropdown, { placement: "bottom-end" }, {
    toggle: _withCtx(({ toggle }) => [
      _createVNode(_component_NIcon, {
        ref: "icon",
        name: "authenticate",
        onClick: toggle
      }, null, 8, ["onClick"])
    ]),
    body: _withCtx(({ toggle }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_NForm, {
          layout: _ctx.schema,
          model: _ctx.authModel,
          "inline-form": "",
          ref: "form"
        }, null, 8, ["layout", "model"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_NHint, {
            placement: "bottom",
            content: _ctx.$t(_ctx.error),
            "visible-mode": "manual",
            "is-visible": !!_ctx.error
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NButton, {
                type: "primary",
                loading: _ctx.loading,
                onClick: ($event: any) => (_ctx.login(toggle))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('ds.save_and_check')), 1)
                ]),
                _: 2
              }, 1032, ["loading", "onClick"])
            ]),
            _: 2
          }, 1032, ["content", "is-visible"]),
          _createVNode(_component_NButton, {
            type: "secondary",
            onClick: toggle
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('ds.discard')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }))
}