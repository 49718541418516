import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dispatchClickEvent && _ctx.dispatchClickEvent(...args)), ["stop"])),
    class: "video-wall-preset-select-item-delete-button"
  }, [
    _createVNode(_component_NIcon, {
      height: 10,
      width: 10,
      name: "close"
    })
  ]))
}