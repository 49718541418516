import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "empty-case-page" }
const _hoisted_2 = { class: "heading-4xl" }
const _hoisted_3 = { class: "empty-case-page__description label-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('cases.new_case', 'f')), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('cases.new_case_description')), 1),
    _createVNode(_component_NButton, {
      size: "big",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('create'))),
      icon: "add"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('cases.create_first_case', 'f')), 1)
      ]),
      _: 1
    })
  ]))
}