import { RepeaterContext, RepeaterStateSwitcher, RepeaterTimeoutDescriptor } from './types';

const pass = () => {};

export interface RepeaterState {
  pause(): void;
  start(): void;
}

export function useRepeaterPausedState(context: RepeaterContext, toState: RepeaterStateSwitcher): RepeaterState {
  function start(): void {
    toState(useRepeaterStartingState(context, toState));
  }

  return { pause: pass, start };
}

export function useRepeaterRunningState(context: RepeaterContext, toState: RepeaterStateSwitcher): RepeaterState {
  let descriptor = timeout();

  function pause(): void {
    clearTimeout(descriptor);
    toState(useRepeaterPausedState(context, toState));
  }

  function timeout(): RepeaterTimeoutDescriptor {
    return setTimeout(execute, context.delayInMs);
  }

  function execute(): void {
    const promise = context.handler();
    promise.then(() => (descriptor = timeout())).catch(pass);
  }

  return { pause, start: pass };
}

export function useRepeaterStartingState(context: RepeaterContext, toState: RepeaterStateSwitcher): RepeaterState {
  const promise = context.handler();
  promise.then(() => toState(useRepeaterRunningState(context, toState))).catch(() => toState(useRepeaterPausedState(context, toState)));

  return { pause: pass, start: pass };
}
