import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes),
    style: _normalizeStyle(_ctx.style),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)), ["left"])),
    onDblclick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["left","stop"]))
  }, null, 38))
}