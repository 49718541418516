export type NButtonGroupItem =
  | {
      value: string | number;
      name?: string;
      label: string;
      icon?: string;
      color?: string;
      suffixIcon?: string;
      handler?: () => void;
      counter?: number;
      checked?: boolean;
    }
  | {
      value: string | number;
      name?: string;
      label?: string;
      icon: string;
      color?: string;
      suffixIcon?: string;
      handler?: () => void;
      counter?: number;
      checked?: boolean;
    };

export type NButtonGroupType = typeof NButtonGroupTypes[keyof typeof NButtonGroupTypes];
export const NButtonGroupTypes = {
  Default: 'default',
  Separated: 'separated'
} as const;

export type NButtonSize = typeof NButtonSizes[keyof typeof NButtonSizes];
export const NButtonSizes = {
  Big: 'big',
  Normal: 'normal',
  Small: 'small'
} as const;

export type NButtonType = typeof NButtonTypes[keyof typeof NButtonTypes];
export const NButtonTypes = {
  Primary: 'primary',
  Secondary: 'secondary',
  SecondaryAlt: 'secondary-alt',
  Text: 'text',
  Link: 'link',
  Red: 'red',
  Outline: 'outline'
} as const;

export type NButtonIconSize = typeof NButtonIconSizes[keyof typeof NButtonIconSizes];
export const NButtonIconSizes = {
  Big: '30',
  Normal: '20',
  Small: '16',
  XSmall: '12'
} as const;

export type NButtonLoader = typeof NButtonLoaders[keyof typeof NButtonLoaders];
export const NButtonLoaders = {
  Dots: 'dots',
  Circle: 'circle'
} as const;
