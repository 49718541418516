
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  name: 'InfiniteScroll',
  emit: ['loadMore']
})
export default class InfiniteScroll extends Vue {
  @Prop({ type: [Object, Array], default: () => {} })
  readonly filters!: any;

  @Prop({ type: String, default: '' })
  readonly nextPage!: string;

  enabled = false;
  observer: IntersectionObserver | null = null;

  @Watch('filters', { deep: true })
  scrollToTop() {
    for (let node = this.$el; node !== null; node = node.parentNode) {
      if (node.scrollHeight > node.clientHeight) {
        node.scrollTop = 0;
        break;
      }
    }
  }

  @Watch('nextPage', { immediate: true })
  autoEmitOnPageChange() {
    this.nextPage && this.emitLoading();
  }

  mounted() {
    const options = {
      threshold: [0, 1]
    };
    this.clear();
    this.observer = new IntersectionObserver(this.intersect, options);
    this.observer.observe(this.$el);
  }

  beforeUnmount() {
    this.clear();
  }

  intersect(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      this.enabled = entry.isIntersecting;
      this.emitLoading();
    });
  }

  emitLoading() {
    this.enabled && this.$emit('loadMore');
  }

  clear() {
    this.observer?.disconnect();
  }
}
