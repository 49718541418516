import { localStorageModule } from '@/store/application/local.storage';
import max from 'lodash/max';
import memoize from 'lodash/memoize';
import { reactive, watch } from 'vue';
import { VideoWallPreset, VideoWallPresetId } from '../types';

export class VideoWallPresetsModule {
  static readonly NAME = 'VideoWallPresetsModule';

  presets: Readonly<VideoWallPreset>[] = [];

  create(payload: Omit<VideoWallPreset, 'id' | 'name'>, name: string): VideoWallPreset {
    const preset = { ...payload, name, id: this.computeNextVideoWallPresetId() } as VideoWallPreset;
    return this.presets.push(preset), preset;
  }

  remove(id: VideoWallPresetId): void {
    this.presets = this.presets.filter((preset) => preset.id !== id);
  }

  private computeNextVideoWallPresetId(): NonNullable<VideoWallPresetId> {
    return (max(this.presets.map(({ id }) => id ?? 0)) ?? 0) + 1;
  }
}

export const getVideoWallPresetsModuleInstance = memoize((): VideoWallPresetsModule => {
  const instance = reactive(new VideoWallPresetsModule()) as VideoWallPresetsModule;
  localStorageModule.registerInstance({ instance, name: VideoWallPresetsModule.NAME, tokens: ['presets'] });
  watch(
    () => instance.presets,
    () => localStorageModule.syncToStorageByName(VideoWallPresetsModule.NAME),
    { deep: true }
  );
  return instance;
});
