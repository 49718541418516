/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BatchUploadEntryFilter = {
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
            /**
            * Response code greater than
            */
        resp_code_gt?: number;
            /**
            * Upload list id is
            */
        upload_list?: number;
}
export const EmptyBatchUploadEntryFilter: BatchUploadEntryFilter = {
        limit: '20',
        page: undefined,
        resp_code_gt: undefined,
        upload_list: undefined,
}