
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { Counter, CounterRecord } from '@/api';

@Options({
  name: 'CounterDistance'
})
export default class CounterDistance extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Counter & CounterRecord;

  get distance() {
    if (this.item.now_proximity_min && this.item.now_proximity_avg && this.item.now_proximity_max) {
      return this.item.now_proximity_min.toFixed(2) + ' / ' + this.item.now_proximity_avg.toFixed(2) + ' / ' + this.item.now_proximity_max.toFixed(2);
    }
    if (this.item.proximity_min && this.item.proximity_avg && this.item.proximity_max) {
      return this.item.proximity_min.toFixed(2) + ' / ' + this.item.proximity_avg.toFixed(2) + ' / ' + this.item.proximity_max.toFixed(2);
    }
    return '- / - / -';
  }
}
