/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CounterRecordsFilter = {
    /**
     * Select counter records related to `camera_group`.
     */
    camera_groups?: Array<number>;
    /**
     * Select counter records related to `camera`.
     */
    cameras?: Array<number>;
    /**
     * Select counter records with `cars_count` **greater than or equal** to this value
     */
    cars_count_gte?: number;
    /**
     * Select counter records with `cars_count` **less than or equal** to this value
     */
    cars_count_lte?: number;
    /**
     * Select counter records related to one or multiple `counter`s
     */
    counter?: Array<number>;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select counter records with `faces_count` **greater than or equal** to this value
     */
    faces_count_gte?: number;
    /**
     * Select counter records with `faces_count` **less than or equal** to this value
     */
    faces_count_lte?: number;
    /**
     * Select counters with provided ids
     */
    id_in?: Array<number>;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Which field to use when ordering the results. Available fields: `created_date`, `id`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
    /**
     * Select counter records with `proximity_avg` **greater than or equal** to this value
     */
    proximity_avg_gte?: number;
    /**
     * Select counter records with `proximity_avg` **less than or equal** to this value
     */
    proximity_avg_lte?: number;
    /**
     * Select counter records with `proximity_max` **greater than or equal** to this value
     */
    proximity_max_gte?: number;
    /**
     * Select counter records with `proximity_max` **less than or equal** to this value
     */
    proximity_max_lte?: number;
    /**
     * Select counter records with `proximity_min` **greater than or equal** to this value
     */
    proximity_min_gte?: number;
    /**
     * Select counter records with `proximity_min` **less than or equal** to this value
     */
    proximity_min_lte?: number;
    /**
     * Select counter records with `silhouettes_count` **greater than or equal** to this value
     */
    silhouettes_count_gte?: number;
    /**
     * Select counter records with `silhouettes_count` **less than or equal** to this value
     */
    silhouettes_count_lte?: number;
}
export const EmptyCounterRecordsFilter: CounterRecordsFilter = {
    camera_groups: [],
    cameras: [],
    cars_count_gte: undefined,
    cars_count_lte: undefined,
    counter: [],
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    faces_count_gte: undefined,
    faces_count_lte: undefined,
    id_in: [],
    limit: '20',
    ordering: undefined,
    page: undefined,
    proximity_avg_gte: undefined,
    proximity_avg_lte: undefined,
    proximity_max_gte: undefined,
    proximity_max_lte: undefined,
    proximity_min_gte: undefined,
    proximity_min_lte: undefined,
    silhouettes_count_gte: undefined,
    silhouettes_count_lte: undefined,
}