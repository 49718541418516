
import NButton from '@/uikit/buttons/NButton.vue';
import { Options, Prop, Vue } from 'vue-property-decorator';
import { VideoWallPresetEditor } from '../VideoWallPresetEditor';

@Options({
  components: { NButton, VideoWallPresetEditor },
  emits: ['save']
})
export default class VideoWallPresetSaver extends Vue {
  @Prop({ required: true, type: String })
  readonly name!: string;

  editing: boolean = false;

  dispatchSaveEvent(name: string): void {
    this.$emit('save', name);
    this.toggleEditingMode();
  }

  toggleEditingMode(): void {
    this.editing = !this.editing;
  }
}
