
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/definitions/view-models';
import { Debounce } from '@/common/debounce-decorator';

@Options({
  name: 'Statistics'
})
export default class Statistics extends Vue {
  @Prop({ type: Object, required: true })
  module!: ListViewModel<any, any>;

  async created() {
    await this.get();
  }

  @Watch('module.loading')
  async changeModuleNameHandler(v: boolean, p: boolean) {
    if (v) await this.get();
  }

  @Debounce(500)
  async get() {
    await this.module.getStatistics();
  }
}
