
import type { LicenseUsageIntervalCounterRelativeLimitSpending } from '@/pages/license/types';
import { defineComponent } from 'vue';
import { string } from 'vue-types';

export default defineComponent({
  name: 'LicensePageTabViewIntervalsListItemIndicator',
  props: {
    spending: string<LicenseUsageIntervalCounterRelativeLimitSpending>().isRequired
  },
  computed: {
    classes() {
      const root = 'license-page-tab-view-intervals-list-item-indicator';
      return {
        [root]: true,
        [`${root}_${this.spending}`]: true
      };
    }
  }
});
