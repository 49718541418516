
import { defineComponent } from 'vue';
import { object } from 'vue-types';
import { LicensePageMetaItemContent } from '../types';
import { LicensePageMetaContentView } from './types';
import { selectLicensePageMetaContentView } from './utils';

export default defineComponent({
  name: 'LicensePageMetaContent',
  props: {
    content: object<LicensePageMetaItemContent>().isRequired
  },
  computed: {
    contentView(): LicensePageMetaContentView {
      return selectLicensePageMetaContentView(this.content);
    }
  }
});
