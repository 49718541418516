
import { Options, Vue } from 'vue-class-component';
import NModalWindow, { NModalWindowSize } from '@/uikit/modal-window/NModalWindow.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { Prop } from 'vue-property-decorator';
import { informationLayout } from '@/pages/blocklist/forms/blocklist-form-schema';
import NForm, { IFormModel } from '@/uikit/forms/NForm.vue';
import { dataServiceRepository } from "@/api/common";
import { SelectedItemType } from "@/pages/blocklist/forms/BlocklistForm.vue";

@Options({
  components: {
    NModalWindow,
    NButton,
    NForm
  }
})
export default class EditBlocklistForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly model!: IFormModel;

  get formLayout() {
    return informationLayout;
  }

  get size() {
    return NModalWindowSize.Medium;
  }

  getIsItemFormValid(): boolean {
    const result = this.$refs.itemForm ? this.$refs.itemForm.validate() : true;
    if (!result) {
      this.$refs.itemForm.displayErrors();
    }
    return result;
  }

  saveHandler() {
    if (!this.getIsItemFormValid()) return;
    this.$emit('save');
  }

  closeHandler() {
    this.$emit('close');
  }

  mounted() {
    this.model.__uuid = this.model.uuid;
  }
}
