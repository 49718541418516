import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: "n-icon",
    width: _ctx.width,
    height: _ctx.height,
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("use", {
      href: `#n-icon-${_ctx.name}`
    }, null, 8, _hoisted_2)
  ], 12, _hoisted_1))
}