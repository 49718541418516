import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "attribute-line-cross__forward-letter" }
const _hoisted_2 = { class: "attribute-line-cross__backward-letter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("li", {
      class: _normalizeClass(_ctx.computedClasses)
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.directionLetter), 1),
      _createTextVNode(" " + _toDisplayString(_ctx.line?.name), 1)
    ], 2),
    (_ctx.item.backward_line_crossing)
      ? (_openBlock(), _createElementBlock("li", {
          key: 0,
          class: _normalizeClass(_ctx.computedClasses)
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.backwardDirectionLetter), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.line?.name), 1)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}