
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CaseParticipant, FaceCluster } from '@/api';

import { viewModelRepository } from '@/api/common';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';

import ClusterItem from '@/components/clusters/ClusterItem.vue';
import ClusterItemRow from '@/components/clusters/ClusterItemRow.vue';

import ParticipantItem from '@/components/participants/ParticipantItem.vue';
import ParticipantItemRow from '@/components/participants/ParticipantItemRow.vue';
import enrichParticipants, { EnrichedParticipant } from '@/components/participants/enrich-participants';

import CardItemTile from '@/components/cards/CardItemTile.vue';
import CardItemRow from '@/components/cards/CardItemRow.vue';
import enrichCards, { EnrichedCard } from '@/components/cards/enrich-cards';

import ClusterWizardItem from '@/components/cases/ClusterWizardItem.vue';
import NForm from '@/uikit/forms/NForm.vue';
import { informationLayout } from '@/components/participants/sidebar/schema';

import { deleteById } from '@/common/deleteFromArray';
import { request as __request } from '@/api/core/request';
import ScrollHorizontally from '@/uikit/directives/scroll-horizontally';
import { ModelAclResult } from '@/store/acl/types';

@Options({
  name: 'ClusterWizard',
  components: { NForm, ClusterWizardItem, ClusterItemRow, CardItemRow, ParticipantItemRow, NButton, CardItemTile, ParticipantItem, ClusterItem, NModalWindow },
  directives: { ScrollHorizontally }
})
export default class ClusterWizard extends Vue {
  @Prop({ type: Number, required: true })
  readonly caseId!: number;

  @Prop({ type: Object, required: true })
  readonly cluster: any;

  @Prop({ type: Object, required: false })
  readonly participant?: CaseParticipant | undefined;

  @Prop({ type: Object, required: true })
  readonly participantsAcl!: ModelAclResult;

  showCreateForm = false;
  newParticipant = {
    name: '',
    role: '',
    comment: ''
  };

  readonly clusterModule = viewModelRepository.getClustersFacesListViewModel();
  readonly clusterEventsModule = viewModelRepository.getClusterEventsFacesListViewModel();
  readonly participantsModule = viewModelRepository.getCaseParticipantsListViewModel();
  readonly cardsModule = viewModelRepository.getCardsHumansListViewModel();

  participantsItems: EnrichedParticipant[] = [];
  otherParticipantsItems: EnrichedParticipant[] = [];
  cardsItems: EnrichedCard[] = [];

  selectedClusters: FaceCluster[] = [];
  selectedParticipants: CaseParticipant[] = [];
  selectedOtherParticipants: CaseParticipant[] = [];
  selectedCards: EnrichedCard[] = [];

  informationLayout = informationLayout;

  get mainClusterAndSelectedClusters() {
    return [this.cluster, ...this.selectedClusters];
  }

  get clusterItemsFiltered() {
    return this.clusterModule.items.filter((item) => !this.mainClusterAndSelectedClusters.map((item) => item.id).includes(item.id));
  }

  get allSelectedParticipants() {
    return [...this.selectedParticipants, ...this.selectedOtherParticipants];
  }

  get participantsItemsFiltered() {
    return this.participantsItems.filter((item) => !this.allSelectedParticipants.map((item) => item.id).includes(item.id) && item.id !== this.participant?.id);
  }

  get otherParticipantsItemsFiltered() {
    return this.otherParticipantsItems
      .filter((item) => !this.participantsItemsFiltered.map((item) => item.id).includes(item.id) && item.id !== this.participant?.id)
      .filter((item) => !this.allSelectedParticipants.map((item) => item.id).includes(item.id));
  }

  get cardsItemsFiltered() {
    return this.cardsItems.filter((item) => !this.selectedCards.map((item) => item.id).includes(item.id));
  }

  async mounted() {
    if (this.participant) {
      if (this.participant.related_participants?.length) {
        this.participantsModule.filter.current.id_in = this.participant.related_participants;
        await this.participantsModule.get();
        this.selectedParticipants = await enrichParticipants(this.participantsModule.items);
        this.participantsModule.filter.current.id_in = [];
      }
      if (this.participant.related_human_cards?.length) {
        this.cardsModule.filter.current.id_in = this.participant.related_human_cards;
        await this.cardsModule.get();
        this.selectedCards = await enrichCards(this.cardsModule.items);
        this.cardsModule.filter.current.id_in = [];
      }
    }

    const clusterId = this.cluster.id;
    this.clusterModule.filter.current.limit = '50';
    this.clusterModule.filter.current.case_in = [this.caseId];
    this.clusterModule.filter.current.looks_like = `facecluster:${clusterId}`;
    this.clusterModule.filter.current.ordering = '-looks_like_confidence';
    this.clusterModule.filter.current.threshold = 0.5;
    await this.clusterModule.get();

    this.participantsModule.filter.current.limit = '50';
    this.participantsModule.filter.current.case_in = [this.caseId];
    this.participantsModule.filter.current.looks_like = `facecluster:${clusterId}`;
    this.participantsModule.filter.current.ordering = '-looks_like_confidence';
    this.participantsModule.filter.current.threshold = 0.5;
    await this.participantsModule.get();
    this.participantsItems = await enrichParticipants(this.participantsModule.items);

    this.participantsModule.filter.current.limit = '100';
    this.participantsModule.filter.current.case_in = [];
    await this.participantsModule.get();
    this.otherParticipantsItems = await enrichParticipants(this.participantsModule.items);

    this.cardsModule.filter.current.limit = '50';
    this.cardsModule.filter.current.looks_like = `facecluster:${clusterId}`;
    this.cardsModule.filter.current.ordering = '-looks_like_confidence';
    this.cardsModule.filter.current.threshold = 0.5;

    await this.cardsModule.get();
    this.cardsItems = await enrichCards(this.cardsModule.items);
  }

  actionHandler(type: string, item: any) {
    switch (type) {
      case 'cluster_add':
        this.selectedClusters.push(item);
        break;
      case 'cluster_close':
        deleteById(this.selectedClusters, item.id);
        break;
      case 'cluster_click':
        this.clusterItemAction(item.id);
        break;
      case 'participant_add':
        this.selectedParticipants.push(item);
        break;
      case 'other_participant_add':
        this.selectedOtherParticipants.push(item);
        break;
      case 'any_participant_close':
        deleteById(this.selectedParticipants, item.id);
        deleteById(this.selectedOtherParticipants, item.id);
        break;
      case 'card_add':
        this.selectedCards.push(item);
        break;
      case 'card_close':
        deleteById(this.selectedCards, item.id);
        break;
    }
  }

  async mergeClusters() {
    // http://172.17.47.236/api-docs/#post-/clusters/faces/-id-/merge/
    const clusterId = this.cluster.id;
    if (this.selectedClusters.length) {
      await __request({
        method: 'POST',
        path: `/clusters/faces/${clusterId}/merge/`,
        query: {
          clusters: [...this.selectedClusters.map((item) => item.id)]
        }
      });
    }
  }

  // http://172.17.47.236/api-docs/#post-/case-participants/
  async createHandler() {
    try {
      const result = this.$refs.form.validate();
      if (!result) {
        this.$refs.form.displayErrors();
        return;
      }

      await this.mergeClusters();
      await __request({
        method: 'POST',
        path: '/case-participants/',
        body: {
          name: this.newParticipant.name,
          comment: this.newParticipant.comment,
          case: this.caseId,
          role: this.newParticipant.role, // Allowed: witness┃victim┃suspect┃irrelevant
          face_cluster: this.cluster.id,
          car_cluster: null,
          related_car_cards: [],
          related_human_cards: [...this.selectedCards.map((item) => item.id)],
          related_participants: [...this.allSelectedParticipants.map((item) => item.id)]
        }
      });
      this.showCreateForm = false;
      this.$emit('close');
    } catch (e) {
      console.log(e.toString());
    }
  }

  async saveHandler() {
    try {
      await this.mergeClusters();
      await __request({
        method: 'PATCH',
        path: `/case-participants/${this.participant?.id}/`,
        body: {
          related_human_cards: [...this.selectedCards.map((item) => item.id)],
          related_participants: [...this.allSelectedParticipants.map((item) => item.id)]
        }
      });
      this.showCreateForm = false;
      this.$emit('close');
    } catch (e) {
      console.log(e.toString());
    }
  }

  async clusterItemAction(id: any) {
    this.clusterEventsModule.filter.current.cluster_in = [id];
    await this.clusterEventsModule.get();
    this.$photoViewer.show(this.clusterEventsModule.items);
  }
}
