
import { defineComponent, reactive, onMounted, onBeforeUnmount, toRefs } from 'vue';
import LoginVideoPreview from './LoginVideoPreview.vue';
import { AuthState } from './login-helpers';
import { recordVideo } from '@/components/common/media-devices-helpers';
import { AuthService, VideoAuthRequestRequest } from '@/api';
import { isApiError } from '@/pages/login/common';
import { authModule } from '@/store/auth';

const LoginDelayTimeout = 2500;
const DefaultVideoLength = 2500;

export default defineComponent({
  name: 'LoginFaceForm',
  components: { LoginVideoPreview },
  props: {
    stream: { type: MediaStream, required: true },
    videoLength: { type: Number, default: DefaultVideoLength }
  },
  emits: ['submit', 'login'],
  setup(props, { emit }) {
    let attemptLoginTimer = 0;
    let enabled = true;

    const state = reactive({
      authState: AuthState.IDLE
    });

    onMounted(login);
    onBeforeUnmount(() => {
      clearTimeout(attemptLoginTimer);
      enabled = false;
    });

    async function login() {
      try {
        const response = await tryToLogin();
        emit('login', response);
      } catch (e) {
        if (!enabled) return;
        if (isApiError(e)) {
          handleError();
        } else {
          throw e;
        }
      }
    }

    function handleError() {
      clearTimeout(attemptLoginTimer);
      attemptLoginTimer = window.setTimeout(login, LoginDelayTimeout);
      state.authState = AuthState.IDLE;
    }

    async function tryToLogin() {
      state.authState = AuthState.USER_FACE_VIDEO_RECORDING;
      const video = await recordUserFaceVideo();
      state.authState = AuthState.USER_FACE_IDENTIFICATION;
      const loginResult = await loginByRecordedUserFaceVideo(video);
      state.authState = AuthState.USER_FACE_IDENTIFIED;
      return loginResult;
    }

    function recordUserFaceVideo() {
      return recordVideo(props.stream, props.videoLength);
    }

    function loginByRecordedUserFaceVideo(video: Blob) {
      if (!enabled) return;
      let payload: VideoAuthRequestRequest = {
        video,
        uuid: authModule.uuid,
        mobile: false,
        device_info: { user_agent: window.navigator.userAgent }
      };
      return AuthService.authVideoAuthCreate(payload);
    }

    return {
      ...toRefs(state)
    };
  }
});
