import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelect = _resolveComponent("NSelect")!

  return (_openBlock(), _createBlock(_component_NSelect, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    items: _ctx.filteredItems,
    "hide-chevron": _ctx.hideChevron,
    "close-icon": _ctx.closeIcon,
    label: _ctx.label,
    placeholder: _ctx.placeholder,
    multiple: "",
    multiselect: "",
    contains: "",
    i18n_label: "features.cars_model",
    onClose: _ctx.clear
  }, null, 8, ["modelValue", "items", "hide-chevron", "close-icon", "label", "placeholder", "onClose"]))
}