
import { dataModule } from '@/store/data';
import { languageModule } from '@/store/languages';
import { NButton } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: { NButton }
})
export default class LanguageChanger extends Vue {
  @Prop({ default: true, type: Boolean })
  readonly syncCurrentUserLocale!: boolean;

  @Prop({ default: false, type: Boolean })
  readonly hasLabel!: boolean;

  get languages(): Record<string, string> {
    return languageModule.languages;
  }

  get nextLocaleName() {
    return languageModule.nextLocale.name;
  }

  get languageName() {
    return this.hasLabel ? this.languages[this.nextLocaleName] : '';
  }

  changeLang() {
    languageModule.toggleLocale();
    this.syncCurrentUserLocale && dataModule.syncCurrentUserLocale();
  }
}
