
import { cardAssetsModule, IMetaFieldItem } from '@/store/cards/CardAssetsModule';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

@Options({
  name: 'metafield-list-item'
})
export default class MetaFieldListItem extends Vue {
  @Prop({ required: true })
  item!: any;

  @Prop({ required: true })
  type!: string;

  get metaFields() {
    return cardAssetsModule.getMetaListItems(this.type);
  }

  get computedMetaFields() {
    const result = this.metaFields
      .map((v) => {
        const label = v.label || v.name;
        const value = this.getMetaValue(v);
        return { label, value };
      })
      .filter((v) => !!v.value);
    return result;
  }

  getMetaValue(metaField: IMetaFieldItem) {
    const EmptyValue = ''; // this.$t('not_set');
    let r = '',
      metaValue = this.item.meta && this.item.meta[metaField.name];

    switch (metaField.type) {
      case 'list':
        if (this.hasValue(metaValue)) {
          r = Array.isArray(metaValue) ? metaValue.join(', ') : metaValue;
          /* @todo: check the logic
          r = metaField.multiple
            ? metaValue.map((v: any) => this.getDictItemLabel(v, metaField.items, metaField.custom_id)).join(', ')
            : this.getDictItemLabel(metaValue, metaField.items, metaField.custom_id);
          */
        } else {
          r = EmptyValue;
        }
        break;
      case 'datetime':
        r = metaValue ? this.$filters.formatDateTime(metaValue) : EmptyValue;
        break;
      case 'date':
        r = metaValue ? this.$filters.formatDate(metaValue) : EmptyValue;
        break;
      case 'boolean':
        r = metaValue === true ? this.$t('meta_item_enabled') : metaValue === false ? this.$t('meta_item_disabled') : EmptyValue;
        break;
      default:
        r = Array.isArray(metaValue) ? metaValue.join(', ') : metaValue; // type = string, valuelist
        break;
    }
    return r;
  }

  getDictItemLabel(id: string, dictName: string, field = 'id') {
    const dict = cardAssetsModule.dicts.items[dictName],
      item = dict && dict.find((v: any) => v[field] === id);
    return item ? item.label || item.name : dict ? this.$t('meta_item_not_found') : this.$t('meta_item_not_found');
  }

  hasValue(v: any) {
    return v !== null && v !== undefined;
  }
}
