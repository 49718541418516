
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import WatchListsGroup from '@/components/common/WatchListsGroup.vue';

@Options({
  name: 'ThumbnailItem',
  components: { WatchListsGroup, NThumbnail }
})
export default class ThumbnailItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: any;
}
