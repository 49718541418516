import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "control-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NBaseBar = _resolveComponent("NBaseBar")!

  return (_openBlock(), _createBlock(_component_NBaseBar, { class: "settings-bar" }, {
    start: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.totalCount) + " " + _toDisplayString(_ctx.$t('common.in_total')), 1),
      _renderSlot(_ctx.$slots, "start")
    ]),
    end: _withCtx(() => [
      _createVNode(_component_NInput, {
        class: "settings-bar__search",
        "suffix-icon": "search",
        "model-value": _ctx.searchQuery,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit('search', $event))),
        placeholder: _ctx.$t('common.search', 'f')
      }, null, 8, ["model-value", "placeholder"]),
      (_ctx.hasCreate && _ctx.createLabelI18N)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            icon: "add",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit('create')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.createLabelI18N)), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}