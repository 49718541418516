import { CancelablePromise } from '@/api';
import { asDefined, exception } from '@/uikit/utils';
import get from 'lodash/get';

export * from '@/uikit/utils';

type ResponseBodyStreamChunk = Uint8Array;

type ResponseBodyStreamChunkLength = Uint8Array['length'];

type ResponseBodyStreamChunkReceivedHandler = (length: ResponseBodyStreamChunkLength) => void;

type ResponseBodyStreamLength = number;

type ResponseBodyStreamReader = ReadableStreamDefaultReader<ResponseBodyStreamChunk>;

export function downloadAsBlob(url: string, progress?: (percentage: number) => void): CancelablePromise<Blob> {
  return new CancelablePromise<Blob>((resolve, reject, onCancel) => {
    const controller = new AbortController();
    fetch(url, { signal: (onCancel(() => controller.abort()), controller.signal) })
      .then(tryResponseBodyStreamReading)
      .then(resolve)
      .catch(reject);
  });

  async function readResponseBodyStream(reader: ResponseBodyStreamReader, onChunkReceived: ResponseBodyStreamChunkReceivedHandler) {
    const chunks: ResponseBodyStreamChunk[] = [];
    let received = 0;

    async function read(): Promise<void> {
      const chunk = await reader.read();
      !chunk.done && (save(chunk.value), await read());
    }

    function save(chunk: ResponseBodyStreamChunk): void {
      chunks.push(chunk);
      onChunkReceived((received += chunk.length));
    }

    return await read(), chunks;
  }

  async function tryResponseBodyStreamReading(response: Response): Promise<Blob> {
    responseIsOk(response);
    const length = getResponseBodyStreamLength(response);
    const reader = getResponseBodyStreamReader(response);
    return new Blob(await readResponseBodyStream(reader, (received) => progress?.(received / length)));
  }

  function getResponseBodyStreamLength(response: Response): ResponseBodyStreamLength {
    const length = Number(response.headers.get('Content-Length'));
    return Number.isFinite(length) ? length : 0;
  }

  function getResponseBodyStreamReader(response: Response): ResponseBodyStreamReader {
    return asDefined(response.body?.getReader(), 'Unable to read the response body stream.');
  }

  function responseIsOk(response: Response): void {
    !response.ok && exception(`Unable to download the file from ${response.url}.`);
  }
}

export function initNIconSprite() {
  const icons = require.context('@/uikit/icons/assets', true, /\.svg$/);
  const requireAll = (requireContext: any) => requireContext.keys().map(requireContext);
  requireAll(icons);
}


export function printStringValue(object:any, path: string) {
  return String(get(object, path) || '-');
}
