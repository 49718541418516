/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type WatchListPermissions = {
    readonly id: number;
    /**
     * Short watch list name, up to 256 characters
     */
    readonly name: string;
    permissions?: Record<string, string>;
};
export const EmptyWatchListPermissions: WatchListPermissions = {
    id: 0,
        /**
        * Short watch list name, up to 256 characters
        */
    name: '',
    permissions: {},
};