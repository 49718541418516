import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoWallEpisodeList = _resolveComponent("VideoWallEpisodeList")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_VideoWallPresetSaver = _resolveComponent("VideoWallPresetSaver")!
  const _component_VideoWallPresetEditor = _resolveComponent("VideoWallPresetEditor")!
  const _component_VideoWallLayoutSelect = _resolveComponent("VideoWallLayoutSelect")!
  const _component_VideoWallPageSidebarSection = _resolveComponent("VideoWallPageSidebarSection")!
  const _component_VideoWallPresetSelect = _resolveComponent("VideoWallPresetSelect")!
  const _component_VideoWallCameraList = _resolveComponent("VideoWallCameraList")!
  const _component_VideoWallPageSidebar = _resolveComponent("VideoWallPageSidebar")!
  const _component_VideoWallPlayer = _resolveComponent("VideoWallPlayer")!
  const _component_VideoWall = _resolveComponent("VideoWall")!
  const _component_VideoWallPageLayout = _resolveComponent("VideoWallPageLayout")!

  return (_openBlock(), _createBlock(_component_VideoWallPageLayout, {
    "layout-state": _ctx.state.layout,
    ref: "layout"
  }, {
    episodes: _withCtx(() => [
      (_ctx.areEpisodesVisible)
        ? (_openBlock(), _createBlock(_component_VideoWallEpisodeList, {
            key: 0,
            tab: _ctx.tab,
            "camera-ids": _ctx.enabledCameraIds
          }, null, 8, ["tab", "camera-ids"]))
        : _createCommentVNode("", true)
    ]),
    "fullscreen-activator": _withCtx(() => [
      _createVNode(_component_NButton, {
        onClick: _ctx.requestFullScreen,
        icon: "fullscreen",
        type: "secondary"
      }, null, 8, ["onClick"])
    ]),
    "preset-editor": _withCtx(() => [
      (_ctx.hasVideoWallPresetBeenModified)
        ? (_openBlock(), _createBlock(_component_VideoWallPresetSaver, {
            key: _ctx.preset.id,
            name: _ctx.preset.name,
            onSave: _ctx.handleVideoWallPresetSaveEvent
          }, null, 8, ["name", "onSave"]))
        : (_openBlock(), _createBlock(_component_VideoWallPresetEditor, {
            key: _ctx.preset.id,
            name: _ctx.preset.name,
            onSave: _ctx.handleVideoWallPresetSaveEvent
          }, null, 8, ["name", "onSave"]))
    ]),
    "sidebar-activator": _withCtx(() => [
      _createVNode(_component_NButton, {
        onClick: _ctx.handleSidebarToggle,
        icon: "configure",
        type: "secondary"
      }, null, 8, ["onClick"])
    ]),
    sidebar: _withCtx(() => [
      _createVNode(_component_VideoWallPageSidebar, { onClose: _ctx.handleSidebarToggle }, {
        default: _withCtx(() => [
          _createVNode(_component_VideoWallPageSidebarSection, {
            header: _ctx.$t('videowall.general_layouts')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_VideoWallLayoutSelect, { onClick: _ctx.handleVideoWallLayoutChange }, null, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["header"]),
          (_ctx.arePresetsSectionVisible)
            ? (_openBlock(), _createBlock(_component_VideoWallPageSidebarSection, {
                key: 0,
                header: _ctx.$t('videowall.saved_presets')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_VideoWallPresetSelect, {
                    presets: _ctx.presetsModule.presets,
                    onDelete: _ctx.handleVideoWallPresetDeleteEvent,
                    onSelect: _ctx.handleVideoWallPresetSelectEvent
                  }, null, 8, ["presets", "onDelete", "onSelect"])
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          _createVNode(_component_VideoWallPageSidebarSection, {
            header: _ctx.$t('videowall.cameras')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_VideoWallCameraList, {
                cameras: _ctx.cameras,
                groups: _ctx.cameraGroups
              }, null, 8, ["cameras", "groups"])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["onClose"])
    ]),
    "video-wall": _withCtx(() => [
      _createVNode(_component_VideoWall, {
        ref: "wall",
        "camera-ids": _ctx.preset.cameraIds,
        "onUpdate:camera-ids": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.preset.cameraIds) = $event))
      }, {
        camera: _withCtx(({ id }) => [
          _createVNode(_component_VideoWallPlayer, {
            camera: _ctx.getCameraById(id)
          }, null, 8, ["camera"])
        ]),
        _: 1
      }, 8, ["camera-ids"])
    ]),
    _: 1
  }, 8, ["layout-state"]))
}