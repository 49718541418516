/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * `MetaDictionary` is a collection of values from which you can select a meta value
 */
export type MetaDictionary = {
    id: string;
    /**
     * Object creation date
     */
    readonly created_date: string;
    /**
     * Object modification date
     */
    readonly modified_date: string;
    /**
     * Short dictionary name, up to 256 characters
     */
    name: string;
    /**
     * Extended description, up to 2048 characters
     */
    comment?: string;
};
export const EmptyMetaDictionary: MetaDictionary = {
    id: '',
    /**
     * Object creation date
     */
    created_date: '',
    /**
     * Object modification date
     */
    modified_date: '',
    /**
     * Short dictionary name, up to 256 characters
     */
    name: '',
    /**
     * Extended description, up to 2048 characters
     */
    comment: '',
};
