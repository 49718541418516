import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "external-vms-status" }
const _hoisted_2 = { class: "control-m" }
const _hoisted_3 = {
  key: 1,
  class: "control-m"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: "external-vms-status__circle",
            style: _normalizeStyle({ backgroundColor: `var(${_ctx.circleColor})` })
          }, null, 4),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.modelValue.status), 1)
        ], 64))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, "..."))
  ]))
}