
import { defineComponent } from 'vue';
import { arrayOf, object } from 'vue-types';
import { LicenseService } from '../../../types';
import { LicensePageMeta } from '../../meta';
import { formatLicenseService } from './formatters/services';

export default defineComponent({
  name: 'LicensePageTabViewServices',
  components: { LicensePageMeta },
  props: {
    services: arrayOf(object<LicenseService>()).isRequired
  },
  computed: {
    items() {
      return this.services.map(formatLicenseService);
    }
  }
});
