/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CarEpisode } from '../models/CarEpisode';
import type { CountResponse } from '../models/CountResponse';
import type { HumanEpisode } from '../models/HumanEpisode';
import type { PaginatedCarEpisodeList } from '../models/PaginatedCarEpisodeList';
import type { PaginatedHumanEpisodeList } from '../models/PaginatedHumanEpisodeList';
import type { PatchedCarEpisodeRequest } from '../models/PatchedCarEpisodeRequest';
import type { PatchedHumanEpisodeRequest } from '../models/PatchedHumanEpisodeRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class EpisodesService {
    /**
     * List car episodes
     * Use this method to list car episodes.
     * @param acknowledged Select only acknowledged episodes (`true`) or unacknowledged (`false`) episodes
     * @param acknowledgedBy Select episodes acknowledged by user
     * @param acknowledgedReaction Select only episodes with this acknowledgment reaction
     * @param body Select episodes where feature `body` takes one of these values
     * @param cameraGroups Select episodes associated with the specified camera groups
     * @param cameras Select episodes associated with the specified cameras
     * @param closedDateGte Select episodes with `closed_date` **greater than or equal** to this value
     * @param color Select episodes where feature `color` takes one of these values
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param eventsCountGte Select episodes with number of events is **greater or equal than or equal** to this value
     * @param idIn Select episodes with provided ids
     * @param lastCarEvent Select only the episode with the last car event of the given id
     * @param lastCarEventDateGte Select episodes with `last_car_event_date` **greater than or equal** to this value
     * @param licensePlateCountry Select episodes where feature `license_plate_country` takes one of these values
     * @param licensePlateNumber Select episodes where feature `license_plate_number` contains this value (case-insensitive)
     * @param licensePlateRegion Select episodes where feature `license_plate_region` takes one of these values
     * @param limit Number of results to return per page.
     * @param make Select episodes where feature `make` takes one of these values
     * @param matchedCard Select episodes that match this card
     * @param matchedLists Select episodes associated with the specified watch lists
     * @param model Select episodes where feature `model` takes one of these values
     * @param noCarMatch Select only episodes without matched car (`true`) or with matched car (`false`)
     * @param open Select only open episodes (`true`) or closed (`false`) episodes
     * @param ordering Which field to use when ordering the results. Available fields: `created_date`, `id`
     * @param page Pagination cursor value.
     * @param specialVehicleType Select episodes where feature `special_vehicle_type` takes one of these values
     * @param videoArchive Select episodes associated with the specified video archives
     * @returns PaginatedCarEpisodeList
     * @throws ApiError
     */
    public static episodesCarsList(
        acknowledged?: boolean,
        acknowledgedBy?: number,
        acknowledgedReaction?: string,
        body?: Array<string>,
        cameraGroups?: Array<number>,
        cameras?: Array<number>,
        closedDateGte?: string,
        color?: Array<string>,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        eventsCountGte?: number,
        idIn?: Array<number>,
        lastCarEvent?: string,
        lastCarEventDateGte?: string,
        licensePlateCountry?: Array<string>,
        licensePlateNumber?: Array<string>,
        licensePlateRegion?: Array<string>,
        limit?: string,
        make?: Array<string>,
        matchedCard?: number,
        matchedLists?: Array<number>,
        model?: Array<string>,
        noCarMatch?: string | null,
        open?: boolean,
        ordering?: string,
        page?: string,
        specialVehicleType?: Array<string>,
        videoArchive?: Array<number>,
    ): CancelablePromise<PaginatedCarEpisodeList> {
        return __request({
            method: 'GET',
            path: `/episodes/cars/`,
            query: {
                'acknowledged': acknowledged,
                'acknowledged_by': acknowledgedBy,
                'acknowledged_reaction': acknowledgedReaction,
                'body': body,
                'camera_groups': cameraGroups,
                'cameras': cameras,
                'closed_date_gte': closedDateGte,
                'color': color,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'events_count_gte': eventsCountGte,
                'id_in': idIn,
                'last_car_event': lastCarEvent,
                'last_car_event_date_gte': lastCarEventDateGte,
                'license_plate_country': licensePlateCountry,
                'license_plate_number': licensePlateNumber,
                'license_plate_region': licensePlateRegion,
                'limit': limit,
                'make': make,
                'matched_card': matchedCard,
                'matched_lists': matchedLists,
                'model': model,
                'no_car_match': noCarMatch,
                'open': open,
                'ordering': ordering,
                'page': page,
                'special_vehicle_type': specialVehicleType,
                'video_archive': videoArchive,
            },
        });
    }
    /**
     * Retrieve car episode by ID
     * Use this method to retrieve car episode by its ID.
     * @param id A unique integer value identifying this Car episode.
     * @returns CarEpisode
     * @throws ApiError
     */
    public static episodesCarsRetrieve(
        id: number,
    ): CancelablePromise<CarEpisode> {
        return __request({
            method: 'GET',
            path: `/episodes/cars/${id}/`,
        });
    }
    /**
     * Update car episode properties
     * Use this method to update car episode properties.
     * @param id A unique integer value identifying this Car episode.
     * @param requestBody
     * @returns CarEpisode
     * @throws ApiError
     */
    public static episodesCarsPartialUpdate(
        id: number,
        requestBody?: PatchedCarEpisodeRequest,
    ): CancelablePromise<CarEpisode> {
        return __request({
            method: 'PATCH',
            path: `/episodes/cars/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Objects count
     * This method retrieves count of objects.
     * @param acknowledged Select only acknowledged episodes (`true`) or unacknowledged (`false`) episodes
     * @param acknowledgedBy Select episodes acknowledged by user
     * @param acknowledgedReaction Select only episodes with this acknowledgment reaction
     * @param body Select episodes where feature `body` takes one of these values
     * @param cameraGroups Select episodes associated with the specified camera groups
     * @param cameras Select episodes associated with the specified cameras
     * @param closedDateGte Select episodes with `closed_date` **greater than or equal** to this value
     * @param color Select episodes where feature `color` takes one of these values
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param eventsCountGte Select episodes with number of events is **greater or equal than or equal** to this value
     * @param idIn Select episodes with provided ids
     * @param lastCarEvent Select only the episode with the last car event of the given id
     * @param lastCarEventDateGte Select episodes with `last_car_event_date` **greater than or equal** to this value
     * @param licensePlateCountry Select episodes where feature `license_plate_country` takes one of these values
     * @param licensePlateNumber Select episodes where feature `license_plate_number` contains this value (case-insensitive)
     * @param licensePlateRegion Select episodes where feature `license_plate_region` takes one of these values
     * @param make Select episodes where feature `make` takes one of these values
     * @param matchedCard Select episodes that match this card
     * @param matchedLists Select episodes associated with the specified watch lists
     * @param model Select episodes where feature `model` takes one of these values
     * @param noCarMatch Select only episodes without matched car (`true`) or with matched car (`false`)
     * @param open Select only open episodes (`true`) or closed (`false`) episodes
     * @param specialVehicleType Select episodes where feature `special_vehicle_type` takes one of these values
     * @param videoArchive Select episodes associated with the specified video archives
     * @returns CountResponse
     * @throws ApiError
     */
    public static episodesCarsCountRetrieve(
        acknowledged?: boolean,
        acknowledgedBy?: number,
        acknowledgedReaction?: string,
        body?: Array<string>,
        cameraGroups?: Array<number>,
        cameras?: Array<number>,
        closedDateGte?: string,
        color?: Array<string>,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        eventsCountGte?: number,
        idIn?: Array<number>,
        lastCarEvent?: string,
        lastCarEventDateGte?: string,
        licensePlateCountry?: Array<string>,
        licensePlateNumber?: Array<string>,
        licensePlateRegion?: Array<string>,
        make?: Array<string>,
        matchedCard?: number,
        matchedLists?: Array<number>,
        model?: Array<string>,
        noCarMatch?: string | null,
        open?: boolean,
        specialVehicleType?: Array<string>,
        videoArchive?: Array<number>,
    ): CancelablePromise<CountResponse> {
        return __request({
            method: 'GET',
            path: `/episodes/cars/count/`,
            query: {
                'acknowledged': acknowledged,
                'acknowledged_by': acknowledgedBy,
                'acknowledged_reaction': acknowledgedReaction,
                'body': body,
                'camera_groups': cameraGroups,
                'cameras': cameras,
                'closed_date_gte': closedDateGte,
                'color': color,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'events_count_gte': eventsCountGte,
                'id_in': idIn,
                'last_car_event': lastCarEvent,
                'last_car_event_date_gte': lastCarEventDateGte,
                'license_plate_country': licensePlateCountry,
                'license_plate_number': licensePlateNumber,
                'license_plate_region': licensePlateRegion,
                'make': make,
                'matched_card': matchedCard,
                'matched_lists': matchedLists,
                'model': model,
                'no_car_match': noCarMatch,
                'open': open,
                'special_vehicle_type': specialVehicleType,
                'video_archive': videoArchive,
            },
        });
    }
    /**
     * List human episodes
     * Use this method to list human episodes.
     * @param acknowledged Select only acknowledged episodes (`true`) or unacknowledged (`false`) episodes
     * @param acknowledgedBy Select episodes acknowledged by user
     * @param acknowledgedReaction Select only episodes with this acknowledgment reaction
     * @param ageGroup Select episodes where feature `age_group` takes one of these values
     * @param ageGte Select episodes where feature`age` is **greater than or equal** to this value
     * @param ageLte Select episodes where feature `age` is **lower than or equal** to this value
     * @param bagBack Select episodes where feature `bag_back` takes one of these values
     * @param bagGround Select episodes where feature `bag_ground` takes one of these values
     * @param bagHand Select episodes where feature `bag_hand` takes one of these values
     * @param beard Select episodes where feature `beard` takes one of these values
     * @param bodyGender Select episodes where feature `gender` takes one of these values(body feature)
     * @param bottomColor Select episodes where feature `bottom_color` takes one of these values
     * @param cameraGroups Select episodes associated with the specified camera groups
     * @param cameras Select episodes associated with the specified cameras
     * @param caseIn Select episodes related to these cases
     * @param closedDateGte Select episodes with `closed_date` **greater than or equal** to this value
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param detailedUpperClothes Select episodes where feature `detailed_upper_clothes` takes one of these values
     * @param emotions Select episodes where feature `emotions` takes one of these values
     * @param eventsCountGte Select episodes with number of events is **greater or equal than or equal** to this value
     * @param gender Select episodes where feature `gender` takes one of these values
     * @param glasses Select episodes where feature `glasses` takes one of these values
     * @param hasBody Select only episodes with body (`true`) or without body (`false`)
     * @param hasCase `true` - select only episodes relates to case. `false` - select only episodes without case.
     * @param hasFace Select only episodes with face (`true`) or without face (`false`)
     * @param headwear Select episodes where feature `headwear` takes one of these values
     * @param helmetType Select episodes where feature `helmet_type` takes one of these values
     * @param idIn Select episodes with provided ids
     * @param lastBodyEvent Select only the episode with the last body event of the given id
     * @param lastBodyEventDateGte Select episodes with `last_body_event_date` **greater than or equal** to this value
     * @param lastFaceEvent Select only the episode with the last face event of the given id
     * @param lastFaceEventDateGte Select episodes with `last_face_event_date` **greater than or equal** to this value
     * @param limit Number of results to return per page.
     * @param liveness Select episodes where feature `liveness` takes one of these values
     * @param look Select episodes where feature `look` takes one of these values
     * @param lowerClothes Select episodes where feature `lower_clothes` takes one of these values
     * @param matchedCard Select episodes that match this card
     * @param matchedLists Select episodes associated with the specified watch lists
     * @param medmask Select episodes where feature `medmask` takes one of these values
     * @param noBodyMatch Select only episodes without matched body (`true`) or with matched body (`false`)
     * @param noFaceMatch Select only episodes without matched face (`true`) or with matched face (`false`)
     * @param open Select only open episodes (`true`) or closed (`false`) episodes
     * @param ordering Какое поле использовать для сортировки результатов. Available fields: `created_date`, `id`, `uniform`
     * @param page Pagination cursor value.
     * @param topColor Select episodes where feature `top_color` takes one of these values
     * @param upperClothes Select episodes where feature `upper_clothes` takes one of these values
     * @param vestType Select episodes where feature `vest_type` takes one of these values
     * @param videoArchive Select episodes associated with the specified video archives
     * @returns PaginatedHumanEpisodeList
     * @throws ApiError
     */
    public static episodesHumansList(
      acknowledged?: boolean,
      acknowledgedBy?: number,
      acknowledgedReaction?: string,
      ageGroup?: Array<string>,
      ageGte?: string,
      ageLte?: string,
      bagBack?: Array<string>,
      bagGround?: Array<string>,
      bagHand?: Array<string>,
      beard?: Array<string>,
      bodyGender?: Array<string>,
      bottomColor?: Array<string>,
      cameraGroups?: Array<number>,
      cameras?: Array<number>,
      caseIn?: Array<number | null>,
      closedDateGte?: string,
      createdDateGt?: string,
      createdDateGte?: string,
      createdDateLastNDays?: number,
      createdDateLt?: string,
      createdDateLte?: string,
      createdDateNthFullWeek?: number,
      createdDateNthWorkWeek?: number,
      detailedUpperClothes?: Array<string>,
      emotions?: Array<string>,
      eventsCountGte?: number,
      gender?: Array<string>,
      glasses?: Array<string>,
      hasBody?: boolean,
      hasCase?: boolean,
      hasFace?: boolean,
      headwear?: Array<string>,
      helmetType?: Array<string>,
      idIn?: Array<number>,
      lastBodyEvent?: string,
      lastBodyEventDateGte?: string,
      lastFaceEvent?: string,
      lastFaceEventDateGte?: string,
      limit?: string,
      liveness?: Array<string>,
      look?: Array<string>,
      lowerClothes?: Array<string>,
      matchedCard?: number,
      matchedLists?: Array<number>,
      medmask?: Array<string>,
      noBodyMatch?: boolean,
      noFaceMatch?: boolean,
      open?: boolean,
      ordering?: string,
      page?: string,
      topColor?: Array<string>,
      upperClothes?: Array<string>,
      vestType?: Array<string>,
      videoArchive?: Array<number>,
    ): CancelablePromise<PaginatedHumanEpisodeList> {
        return __request({
            method: 'GET',
            path: `/episodes/humans/`,
            query: {
                'acknowledged': acknowledged,
                'acknowledged_by': acknowledgedBy,
                'acknowledged_reaction': acknowledgedReaction,
                'age_group': ageGroup,
                'age_gte': ageGte,
                'age_lte': ageLte,
                'bag_back': bagBack,
                'bag_ground': bagGround,
                'bag_hand': bagHand,
                'beard': beard,
                'body_gender': bodyGender,
                'bottom_color': bottomColor,
                'camera_groups': cameraGroups,
                'cameras': cameras,
                'case_in': caseIn,
                'closed_date_gte': closedDateGte,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'detailed_upper_clothes': detailedUpperClothes,
                'emotions': emotions,
                'events_count_gte': eventsCountGte,
                'gender': gender,
                'glasses': glasses,
                'has_body': hasBody,
                'has_case': hasCase,
                'has_face': hasFace,
                'headwear': headwear,
                'helmet_type': helmetType,
                'id_in': idIn,
                'last_body_event': lastBodyEvent,
                'last_body_event_date_gte': lastBodyEventDateGte,
                'last_face_event': lastFaceEvent,
                'last_face_event_date_gte': lastFaceEventDateGte,
                'limit': limit,
                'liveness': liveness,
                'look': look,
                'lower_clothes': lowerClothes,
                'matched_card': matchedCard,
                'matched_lists': matchedLists,
                'medmask': medmask,
                'no_body_match': noBodyMatch,
                'no_face_match': noFaceMatch,
                'open': open,
                'ordering': ordering,
                'page': page,
                'top_color': topColor,
                'upper_clothes': upperClothes,
                'vest_type': vestType,
                'video_archive': videoArchive,
            },
        });
    }
    /**
     * Retrieve human episode by ID
     * Use this method to retrieve human episode by its ID.
     * @param id A unique integer value identifying this Human episode.
     * @returns HumanEpisode
     * @throws ApiError
     */
    public static episodesHumansRetrieve(
        id: number,
    ): CancelablePromise<HumanEpisode> {
        return __request({
            method: 'GET',
            path: `/episodes/humans/${id}/`,
        });
    }
    /**
     * Update human episode properties
     * Use this method to update human episode properties.
     * @param id A unique integer value identifying this Human episode.
     * @param requestBody
     * @returns HumanEpisode
     * @throws ApiError
     */
    public static episodesHumansPartialUpdate(
        id: number,
        requestBody?: PatchedHumanEpisodeRequest,
    ): CancelablePromise<HumanEpisode> {
        return __request({
            method: 'PATCH',
            path: `/episodes/humans/${id}/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Objects count
     * This method retrieves count of objects.
     * @param acknowledged Select only acknowledged episodes (`true`) or unacknowledged (`false`) episodes
     * @param acknowledgedBy Select episodes acknowledged by user
     * @param acknowledgedReaction Select only episodes with this acknowledgment reaction
     * @param ageGroup Select episodes where feature `age_group` takes one of these values
     * @param ageGte Select episodes where feature`age` is **greater than or equal** to this value
     * @param ageLte Select episodes where feature `age` is **lower than or equal** to this value
     * @param bagBack Select episodes where feature `bag_back` takes one of these values
     * @param bagGround Select episodes where feature `bag_ground` takes one of these values
     * @param bagHand Select episodes where feature `bag_hand` takes one of these values
     * @param beard Select episodes where feature `beard` takes one of these values
     * @param bodyGender Select episodes where feature `gender` takes one of these values(body feature)
     * @param bottomColor Select episodes where feature `bottom_color` takes one of these values
     * @param cameraGroups Select episodes associated with the specified camera groups
     * @param cameras Select episodes associated with the specified cameras
     * @param caseIn Select episodes related to these cases
     * @param closedDateGte Select episodes with `closed_date` **greater than or equal** to this value
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param detailedUpperClothes Select episodes where feature `detailed_upper_clothes` takes one of these values
     * @param emotions Select episodes where feature `emotions` takes one of these values
     * @param eventsCountGte Select episodes with number of events is **greater or equal than or equal** to this value
     * @param gender Select episodes where feature `gender` takes one of these values
     * @param glasses Select episodes where feature `glasses` takes one of these values
     * @param hasBody Select only episodes with body (`true`) or without body (`false`)
     * @param hasCase `true` - select only episodes relates to case. `false` - select only episodes without case.
     * @param hasFace Select only episodes with face (`true`) or without face (`false`)
     * @param headwear Select episodes where feature `headwear` takes one of these values
     * @param helmetType Select episodes where feature `helmet_type` takes one of these values
     * @param idIn Select episodes with provided ids
     * @param lastBodyEvent Select only the episode with the last body event of the given id
     * @param lastBodyEventDateGte Select episodes with `last_body_event_date` **greater than or equal** to this value
     * @param lastFaceEvent Select only the episode with the last face event of the given id
     * @param lastFaceEventDateGte Select episodes with `last_face_event_date` **greater than or equal** to this value
     * @param liveness Select episodes where feature `liveness` takes one of these values
     * @param look Select episodes where feature `look` takes one of these values
     * @param lowerClothes Select episodes where feature `lower_clothes` takes one of these values
     * @param matchedCard Select episodes that match this card
     * @param matchedLists Select episodes associated with the specified watch lists
     * @param medmask Select episodes where feature `medmask` takes one of these values
     * @param noBodyMatch Select only episodes without matched body (`true`) or with matched body (`false`)
     * @param noFaceMatch Select only episodes without matched face (`true`) or with matched face (`false`)
     * @param open Select only open episodes (`true`) or closed (`false`) episodes
     * @param topColor Select episodes where feature `top_color` takes one of these values
     * @param upperClothes Select episodes where feature `upper_clothes` takes one of these values
     * @param vestType Select episodes where feature `vest_type` takes one of these values
     * @param videoArchive Select episodes associated with the specified video archives
     * @returns CountResponse
     * @throws ApiError
     */
    public static episodesHumansCountRetrieve(
      acknowledged?: boolean,
      acknowledgedBy?: number,
      acknowledgedReaction?: string,
      ageGroup?: Array<string>,
      ageGte?: string,
      ageLte?: string,
      bagBack?: Array<string>,
      bagGround?: Array<string>,
      bagHand?: Array<string>,
      beard?: Array<string>,
      bodyGender?: Array<string>,
      bottomColor?: Array<string>,
      cameraGroups?: Array<number>,
      cameras?: Array<number>,
      caseIn?: Array<number | null>,
      closedDateGte?: string,
      createdDateGt?: string,
      createdDateGte?: string,
      createdDateLastNDays?: number,
      createdDateLt?: string,
      createdDateLte?: string,
      createdDateNthFullWeek?: number,
      createdDateNthWorkWeek?: number,
      detailedUpperClothes?: Array<string>,
      emotions?: Array<string>,
      eventsCountGte?: number,
      gender?: Array<string>,
      glasses?: Array<string>,
      hasBody?: boolean,
      hasCase?: boolean,
      hasFace?: boolean,
      headwear?: Array<string>,
      helmetType?: Array<string>,
      idIn?: Array<number>,
      lastBodyEvent?: string,
      lastBodyEventDateGte?: string,
      lastFaceEvent?: string,
      lastFaceEventDateGte?: string,
      liveness?: Array<string>,
      look?: Array<string>,
      lowerClothes?: Array<string>,
      matchedCard?: number,
      matchedLists?: Array<number>,
      medmask?: Array<string>,
      noBodyMatch?: boolean,
      noFaceMatch?: boolean,
      open?: boolean,
      topColor?: Array<string>,
      upperClothes?: Array<string>,
      vestType?: Array<string>,
      videoArchive?: Array<number>,
    ): CancelablePromise<CountResponse> {
        return __request({
            method: 'GET',
            path: `/episodes/humans/count/`,
            query: {
                'acknowledged': acknowledged,
                'acknowledged_by': acknowledgedBy,
                'acknowledged_reaction': acknowledgedReaction,
                'age_group': ageGroup,
                'age_gte': ageGte,
                'age_lte': ageLte,
                'bag_back': bagBack,
                'bag_ground': bagGround,
                'bag_hand': bagHand,
                'beard': beard,
                'body_gender': bodyGender,
                'bottom_color': bottomColor,
                'camera_groups': cameraGroups,
                'cameras': cameras,
                'case_in': caseIn,
                'closed_date_gte': closedDateGte,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'detailed_upper_clothes': detailedUpperClothes,
                'emotions': emotions,
                'events_count_gte': eventsCountGte,
                'gender': gender,
                'glasses': glasses,
                'has_body': hasBody,
                'has_case': hasCase,
                'has_face': hasFace,
                'headwear': headwear,
                'helmet_type': helmetType,
                'id_in': idIn,
                'last_body_event': lastBodyEvent,
                'last_body_event_date_gte': lastBodyEventDateGte,
                'last_face_event': lastFaceEvent,
                'last_face_event_date_gte': lastFaceEventDateGte,
                'liveness': liveness,
                'look': look,
                'lower_clothes': lowerClothes,
                'matched_card': matchedCard,
                'matched_lists': matchedLists,
                'medmask': medmask,
                'no_body_match': noBodyMatch,
                'no_face_match': noFaceMatch,
                'open': open,
                'top_color': topColor,
                'upper_clothes': upperClothes,
                'vest_type': vestType,
                'video_archive': videoArchive,
            },
        });
    }
}