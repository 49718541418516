import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["draggable"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'video-wall-area_draggable': _ctx.draggable,
      'video-wall-area_enter': _ctx.enter,
      'video-wall-area': true
    }),
    draggable: String(_ctx.draggable),
    onDragend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dragend && _ctx.dragend(...args))),
    onDragenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragenter && _ctx.dragenter(...args))),
    onDragleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dragleave && _ctx.dragleave(...args))),
    onDragstart: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.dragstart && _ctx.dragstart(...args))),
    onDrop: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.drop && _ctx.drop(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 42, _hoisted_1))
}