import { HealthStatus } from '@/definitions/app/health-status';

export type DataSourceVideoStatusInfo = {
  label: string;
  i18n_label?: string;
  floatValue?: number;
};

export class DataSourceVideosModule {

  getStatusInfo(status: string, progress?: number, __upload_progress?: number, finished?: boolean): DataSourceVideoStatusInfo {
    const translatedStatusNames = ['DISABLED', 'NO_SOURCE', 'AWAITING', 'WAITING_FOR_SYNC', 'STARTING', 'INPROGRESS', 'MISCONFIGURED', 'TOO_MANY_CAMERAS', 'NOT_STARTED', 'LICENSE_ERROR', 'COMPLETED', 'INTERRUPTED'];
    const isStatusTranslated = translatedStatusNames.includes(status);
    let result: any = { label: status };

    if (status === HealthStatus.InProgress && progress && progress !== 1) {
      result = { i18n_label: 'videos.status__processing', floatValue: progress };
    } else if (__upload_progress && __upload_progress !== 100) {
      result = { i18n_label: 'videos.status__uploading', floatValue: __upload_progress / 100 };
    } else if (status === 'DISABLED' && finished) {
      result = { i18n_label: 'videos.status__completed' };
    } else if (isStatusTranslated) {
      result = { i18n_label: 'videos.status__' + status.toLowerCase() };
    }
    return result;
  }

}

export const dataSourceVideosModule = new DataSourceVideosModule();
