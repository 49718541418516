/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditLog } from '../models/AuditLog';
import type { PaginatedAuditLogList } from '../models/PaginatedAuditLogList';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class AuditLogsService {
    /**
     * List audit-logs
     * @param actionType Select audit-log entries with this `action_type`
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with created in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with created in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param deviceUid Select audit-log entries with this `device_uid`
     * @param limit Number of results to return per page.
     * @param objectId Select audit-log entries with this `object_id`
     * @param objectType Select audit-log entries with this `object_type`
     * @param ordering Which field to use when ordering the results. Available fields: `id`, `created_date`
     * @param page Pagination cursor value.
     * @param requestIp Select audit-log entries with this `request_ip`
     * @param userId Select audit-log entries that matched this `user_id`
     * @param userLogin Select audit-log entries with this `user_login`
     * @returns PaginatedAuditLogList
     * @throws ApiError
     */
    public static auditLogsList(
        actionType?: Array<'acknowledge' | 'auth_upload' | 'basic_auth' | 'close_multiple_sessions' | 'create' | 'destroy' | 'list' | 'merge' | 'partial_update' | 'purge' | 'purge_all' | 'restart' | 'search' | 'update' | 'upload' | 'verify' | 'video_auth' | 'video_auth_renew'>,
        createdDateGt?: string,
        createdDateGte?: string,
        createdDateLastNDays?: number,
        createdDateLt?: string,
        createdDateLte?: string,
        createdDateNthFullWeek?: number,
        createdDateNthWorkWeek?: number,
        deviceUid?: string,
        limit?: string,
        objectId?: number,
        objectType?: Array<'area' | 'body and body' | 'body and car' | 'body and face' | 'bodycluster' | 'bodyevent' | 'bodyobject' | 'camera' | 'cameragroup' | 'car and body' | 'car and car' | 'car and face' | 'carcard' | 'carcardattachment' | 'carcluster' | 'carepisode' | 'carevent' | 'carobject' | 'case' | 'caseattachment' | 'caseparticipant' | 'caseparticipantattachment' | 'counter' | 'deviceblacklistrecord' | 'face and body' | 'face and car' | 'face and face' | 'facecluster' | 'faceevent' | 'faceobject' | 'ffsecauthsession' | 'group' | 'humancard' | 'humancardattachment' | 'humanepisode' | 'license' | 'report' | 'settings' | 'user' | 'videoarchive' | 'watchlist' | 'webhook'>,
        ordering?: string,
        page?: string,
        requestIp?: string,
        userId?: number,
        userLogin?: string,
    ): CancelablePromise<PaginatedAuditLogList> {
        return __request({
            method: 'GET',
            path: `/audit-logs/`,
            query: {
                'action_type': actionType,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'device_uid': deviceUid,
                'limit': limit,
                'object_id': objectId,
                'object_type': objectType,
                'ordering': ordering,
                'page': page,
                'request_ip': requestIp,
                'user_id': userId,
                'user_login': userLogin,
            },
        });
    }
    /**
     * Retrieve audit-log entry by ID
     * @param id A unique integer value identifying this Audit log.
     * @returns AuditLog
     * @throws ApiError
     */
    public static auditLogsRetrieve(
        id: number,
    ): CancelablePromise<AuditLog> {
        return __request({
            method: 'GET',
            path: `/audit-logs/${id}/`,
        });
    }
    /**
     * @returns AuditLog
     * @throws ApiError
     */
    public static auditLogsMapRetrieve(): CancelablePromise<AuditLog> {
        return __request({
            method: 'GET',
            path: `/audit-logs/map/`,
        });
    }
}