
import NButton from '../buttons/NButton.vue';
import NIcon from '../icons/NIcon.vue';
import { CSSProperties } from '@vue/runtime-dom';
import { Options, Prop, Vue } from 'vue-property-decorator';
import NAttachmentsListItemRemovingPopover from './NAttachmentsListItemRemovingPopover.vue';
import type { NAttachment, NAttachmentsI18n } from './types';

const GIGABYTE = 1e9;
const MEGABYTE = 1e6;
const KILOBYTE = 1e3;

@Options({
  components: { NAttachmentsListItemRemovingPopover, NButton, NIcon },
  emits: ['remove']
})
export default class NAttachmentsListItem extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly askBeforeRemove!: boolean;
  @Prop({ required: true, type: Object })
  readonly attachment!: Readonly<NAttachment>;
  @Prop({ required: true, type: Boolean })
  readonly disallowRemove!: boolean;
  @Prop({ required: true, type: Object })
  readonly i18n!: Readonly<Required<NAttachmentsI18n>>;

  isPopoverVisible = false;

  get classes() {
    const root = 'n-attachments-list-item';
    return { [root + '_error']: this.isError };
  }

  get deleteClasses() {
    const root = 'n-attachments-list-item__delete';
    return { [root + '_error']: this.isError };
  }

  get formattedSize() {
    if (this.attachment.size >= GIGABYTE) {
      return (this.attachment.size / GIGABYTE).toFixed(2) + this.i18n.gb;
    }
    if (this.attachment.size >= MEGABYTE) {
      return (this.attachment.size / MEGABYTE).toFixed(2) + this.i18n.mb;
    }
    if (this.attachment.size >= KILOBYTE) {
      return (this.attachment.size / KILOBYTE).toFixed(2) + this.i18n.kb;
    }
    return this.attachment.size.toFixed(2) + this.i18n.b;
  }

  get isError() {
    return this.attachment.status === 'error';
  }

  get isRemovable() {
    return !this.disallowRemove && !this.isUploading;
  }

  get isUploading() {
    return this.attachment.status === 'uploading';
  }

  get styles() {
    return this.isUploading && computeUploadingProgressStyle(this.attachment.progress);
  }

  dispatchRemoveEvent(): void {
    this.hidePopover(), this.$emit('remove', this.attachment);
  }

  handleRemoveEvent(): void {
    this.isRemovable && (this.askBeforeRemove ? this.showPopover() : this.dispatchRemoveEvent());
  }

  hidePopover(): void {
    this.isPopoverVisible = false;
  }

  showPopover(): void {
    this.isPopoverVisible = true;
  }
}

function computeUploadingProgressStyle(progress: number): CSSProperties {
  return { 'mask-image': `linear-gradient(to right, white 0 ${progress}%, #ffffff80 ${progress}% 100%)` };
}
