
import { isDefined } from '@/common/utils';
import { dataServiceFactory } from '@/definitions/services/data.services';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import { fromFs } from '@/uikit/attachments';
import isObject from 'lodash/isObject';
import { defineComponent } from 'vue';
import {
  LicensePageButtonDownload,
  LicensePageButtonUpdate,
  LicensePageButtonUpload,
  LicensePageHeader,
  LicensePageTab,
  LicensePageTabName,
  LicensePageTabs,
  LicensePageTabView
} from './components';
import { LicenseServiceImpl } from './service';
import { useLicensePageViewModel } from './setup';
import { isSoftwareOfflineLicense, LICENSE_UPDATE_URL } from './utils';

type LicensePageState = {
  tabName: LicensePageTabName;
};

export default defineComponent({
  name: 'LicensePage',
  components: {
    LicensePageButtonDownload,
    LicensePageButtonUpdate,
    LicensePageButtonUpload,
    LicensePageHeader,
    LicensePageTabs,
    LicensePageTabView,
    SettingsPageLayout
  },
  data: (): LicensePageState => ({
    tabName: 'common'
  }),
  setup: () => {
    const axios = dataServiceFactory.getAxiosInstance();
    return useLicensePageViewModel(new LicenseServiceImpl(axios));
  },
  computed: {
    tabs(): LicensePageTab[] {
      return [
        { name: 'common', label: this.$t('license.common') },
        { name: 'limits', label: this.$t('license.limits') },
        { name: 'intervals', label: this.$t('license.intervals') },
        { name: 'services', label: this.$t('license.services') }
      ];
    },
    enabledTabs(): LicensePageTab[] {
      return this.tabs.filter(this.shouldTabBeEnabled);
    }
  },
  methods: {
    shouldTabBeEnabled(tab: LicensePageTab): boolean {
      return tab.name === 'intervals'
        ? !!this.licenseUsage?.by_interval.length
        : tab.name === 'limits'
        ? isDefined(this.license) && isSoftwareOfflineLicense(this.license)
          ? true
          : isObject(this.licenseUsage)
        : true;
    },
    handleDownloadEvent(): void {
      this.downloadC2V();
    },
    handleUpdateEvent(): void {
      open(LICENSE_UPDATE_URL, '_blank');
    },
    async handleUploadEvent() {
      const [file] = await fromFs('*/*', false);
      await this.importLicense(file);
    }
  }
});
